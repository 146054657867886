import { useState, useEffect, useRef } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { useTheme } from "../../../contexts/theme"
import ViewBy from "../../ViewBy"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CircularProgress from "@mui/material/CircularProgress"
import InfoToolTip from "../../InfoToolTip"
import { postData } from "../../../api/api"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"
import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import DashLineChartPresentation from "./DashLineChartPresentation"
import { transformReportData } from "./services/transformServices"
import {
  chartContainerDimentionsFallBack,
  DashLineTooltip_data,
} from "./constant/configFallBack"
import { fetchData } from "./services/dataServices"
import dayjs from "dayjs"
import {
  DashLineChartData,
  DashLineChartParameters,
} from "./constant/dataFallBack"
import { generateCacheKey } from "../../../utils/utils"

const DashLineChartContainer = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showAiDropdown = true,
  setAnchorEl,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    viewBy,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const extractedPara = extractKeyValuePairs(parameters)

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const tooltip_data = extractedParameters.tooltip_data || DashLineTooltip_data

  function formatDate(date) {
    const dateFormat = getDateFormatFromLocalStorage()
    return dayjs(date).format(dateFormat)
  }
  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = chartContainerDimentionsFallBack)

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      postData,
      postUrl,
      combinedState,
      reportUid,
      viewBy,
      setApiResponse,
      setError,
      dispatch,
      setChartData,
      formatDate,
      tooltip_data,
      setLoading,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })
  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const chartInput = loading ? [] : response ?? DashLineChartData

  let maxSales = 0

  if (Array.isArray(chartInput)) {
    chartInput.forEach((dataset) => {
      dataset.data.forEach((entry) => {
        const adjustedSales = Math.round(entry.y + entry.y * 0.2)
        if (adjustedSales > maxSales) {
          maxSales = adjustedSales
        }
      })
    })
  } else {
    console.error("chartInput is not an array:", chartInput)
  }

  const legendData = [
    { label: "Actual", color: "#F19004" },
    {
      label: "Forecasted",
      color: theme.palette.mode === "light" ? "#1D54C8" : "#A4D0E9",
    },
  ]

  const tableData = transformReportData(reduxApiResponse, tooltip_data)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"15px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>
      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <DashLineChartPresentation
            chartInput={chartInput}
            parameters={DashLineChartParameters}
            legendsData={legendData}
            maxSales={maxSales}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <DashLineChartPresentation
            chartInput={chartInput}
            parameters={DashLineChartParameters}
            legendsData={legendData}
            maxSales={maxSales}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default DashLineChartContainer
