import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"

export function transformData(response, viewBy, formatDate, tooltip_data) {
  let transformedResponse = response.map((item) => {
    return {
      ...item,
      data: JSON.parse(item.data),
    }
  })

  let actualData =
    transformedResponse.filter((item) => item.id === "Actual")[0] || {}
  let forecastData =
    transformedResponse.filter((item) => item.id === "Forecast")[0] || {}

  let actualDataWithTooltip = []

  actualData.data.forEach((actualItem, idx) => {
    const mappedItem = forecastData.data[idx]
    let eachElement = {
      ...actualItem,
      reportType: "DashLine",
      actualDate:
        viewBy === "Day" ? formatDate(actualItem.daymonth) : actualItem.x,
      forcastDate:
        viewBy === "Day" ? formatDate(actualItem.daymonth) : actualItem.x,
      y: actualItem.Sales,
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: actualItem[tooltipDataItem.name],
        format: tooltipDataItem.format,
      }

      eachElement.tooltipData.previous[tooltipDataItem.name] = {
        value: mappedItem[tooltipDataItem.name],
        format: tooltipDataItem.format,
      }
    })

    actualDataWithTooltip.push(eachElement)
  })

  actualData.data = actualDataWithTooltip

  let forecastDataWithTooltip = []

  forecastData.data.forEach((forecastItem, idx) => {
    const mappedItem = actualData.data[idx]

    let eachElement = {
      ...forecastItem,
      y: forecastItem.Sales,
      reportType: "DashLine",
      actualDate: formatDate(forecastItem.daymonth),
      forcastDate: formatDate(forecastItem.daymonth),
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: forecastItem[tooltipDataItem.name],
        format: tooltipDataItem.format,
      }

      eachElement.tooltipData.previous[tooltipDataItem.name] = {
        value: mappedItem[tooltipDataItem.name],
        format: tooltipDataItem.format,
      }
    })

    forecastDataWithTooltip.push(eachElement)
  })

  forecastData.data = forecastDataWithTooltip

  return transformedResponse
}


export function transformReportData(apiResponse, tooltip_data) {
  const transformedData = []
  // Parse the data from "Actual" and "Forecast" sections
  const actualData = apiResponse?.find((item) => item.id === "Actual")
  const forecastData = apiResponse?.find((item) => item.id === "Forecast")
  const currentData = actualData ? JSON.parse(actualData.data) : []
  const previousData = forecastData ? JSON.parse(forecastData.data) : []
  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }
  const currentLength = currentData.length

  const dateFormat = getDateFormatFromLocalStorage()
  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}
    const transformedWeek = {
      date: current["daymonth"]
        ? dayjs(current["daymonth"]).format(dateFormat)
        : current["datekey"] || current["x"],
    }
    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0
      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })
    transformedData.push(transformedWeek)
  }
  return transformedData
}