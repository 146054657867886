export const DashLineChartData = [
  {
    id: "Actual",
    data: [
      {
        x: 1,
        y: 3283.68,
        tooltipData: {
          current: {
            Sales: {
              value: 3283.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3772.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3327.99,
        tooltipData: {
          current: {
            Sales: {
              value: 3327.99,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4199.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 4899.86,
        tooltipData: {
          current: {
            Sales: {
              value: 4899.86,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3814.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3773.12,
        tooltipData: {
          current: {
            Sales: {
              value: 3773.12,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3911.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3015.48,
        tooltipData: {
          current: {
            Sales: {
              value: 3015.48,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4715.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 2325.96,
        tooltipData: {
          current: {
            Sales: {
              value: 2325.96,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 3783.66,
        tooltipData: {
          current: {
            Sales: {
              value: 3783.66,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 3047.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3047.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 333.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3449.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3449.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4238.91,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 5128.68,
        tooltipData: {
          current: {
            Sales: {
              value: 5128.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3369.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3541.7,
        tooltipData: {
          current: {
            Sales: {
              value: 3541.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4078,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3417.72,
        tooltipData: {
          current: {
            Sales: {
              value: 3417.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3412.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3497.3,
        tooltipData: {
          current: {
            Sales: {
              value: 3497.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2718.32,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 5293.04,
        tooltipData: {
          current: {
            Sales: {
              value: 5293.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3471.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 3807.56,
        tooltipData: {
          current: {
            Sales: {
              value: 3807.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3920.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3173.44,
        tooltipData: {
          current: {
            Sales: {
              value: 3173.44,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3797.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3562.6,
        tooltipData: {
          current: {
            Sales: {
              value: 3562.6,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3941.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 4804,
        tooltipData: {
          current: {
            Sales: {
              value: 4804,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3977.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 2375.58,
        tooltipData: {
          current: {
            Sales: {
              value: 2375.58,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3767.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 2670.94,
        tooltipData: {
          current: {
            Sales: {
              value: 2670.94,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2795.23,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 3953.26,
        tooltipData: {
          current: {
            Sales: {
              value: 3953.26,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2854.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 3804.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3804.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3719.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 4098.72,
        tooltipData: {
          current: {
            Sales: {
              value: 4098.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2455.66,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3621.33,
        tooltipData: {
          current: {
            Sales: {
              value: 3621.33,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3815.25,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 4160.49,
        tooltipData: {
          current: {
            Sales: {
              value: 4160.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4496.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 2718.7,
        tooltipData: {
          current: {
            Sales: {
              value: 2718.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4459.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 2485.69,
        tooltipData: {
          current: {
            Sales: {
              value: 2485.69,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3166.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 3350.77,
        tooltipData: {
          current: {
            Sales: {
              value: 3350.77,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2405.79,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 3300.89,
        tooltipData: {
          current: {
            Sales: {
              value: 3300.89,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3600.15,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Forecast",
    data: [
      {
        x: 1,
        y: 2628.32,
        tooltipData: {
          current: {
            Sales: {
              value: 2628.32,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3731.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3596.17,
        tooltipData: {
          current: {
            Sales: {
              value: 3596.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3405.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 3563.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3563.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3668.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3664.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3664.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4169.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3817.15,
        tooltipData: {
          current: {
            Sales: {
              value: 3817.15,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4272.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 3820.46,
        tooltipData: {
          current: {
            Sales: {
              value: 3820.46,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2521.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 2531.42,
        tooltipData: {
          current: {
            Sales: {
              value: 2531.42,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2624.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 2636.97,
        tooltipData: {
          current: {
            Sales: {
              value: 2636.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3636.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3561.18,
        tooltipData: {
          current: {
            Sales: {
              value: 3561.18,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3404.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 3477.93,
        tooltipData: {
          current: {
            Sales: {
              value: 3477.93,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3652.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3586.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3586.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4200.31,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3842.28,
        tooltipData: {
          current: {
            Sales: {
              value: 3842.28,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4252.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3901.04,
        tooltipData: {
          current: {
            Sales: {
              value: 3901.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2500.98,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 2514.56,
        tooltipData: {
          current: {
            Sales: {
              value: 2514.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2603.51,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 2616.3,
        tooltipData: {
          current: {
            Sales: {
              value: 2616.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3537.4,
        tooltipData: {
          current: {
            Sales: {
              value: 3537.4,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3385.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3450.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3450.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3632.11,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 3559.97,
        tooltipData: {
          current: {
            Sales: {
              value: 3559.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4182.85,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 3822.79,
        tooltipData: {
          current: {
            Sales: {
              value: 3822.79,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4232.44,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 3885.49,
        tooltipData: {
          current: {
            Sales: {
              value: 3885.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2480.64,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 2492.07,
        tooltipData: {
          current: {
            Sales: {
              value: 2492.07,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2583.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 2593.53,
        tooltipData: {
          current: {
            Sales: {
              value: 2593.53,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3591.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 3514.41,
        tooltipData: {
          current: {
            Sales: {
              value: 3514.41,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3365.46,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3427.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3427.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.82,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 3536.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3536.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4162.71,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 3800.1,
        tooltipData: {
          current: {
            Sales: {
              value: 3800.1,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4212.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 3863.09,
        tooltipData: {
          current: {
            Sales: {
              value: 3863.09,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2460.33,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 2469.17,
        tooltipData: {
          current: {
            Sales: {
              value: 2469.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2562.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 2570.62,
        tooltipData: {
          current: {
            Sales: {
              value: 2570.62,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2469.26,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]


export const DashLineChartParameters = {
  margin: { top: 40, right: 20, bottom: 50, left: 60 },
  xScale: {
    type: "point",
    min: 0,
    max: "auto",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  lineWidth: 3,

  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Value",
    legendOffset: -55,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: true,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgb(251, 101, 29)", "rgb(0, 155, 230)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}