export function convertValues(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      convertValues(obj[key])
    } else if (typeof obj[key] === "string") {
      if (!isNaN(obj[key])) {
        obj[key] = Number(obj[key])
      } else if (obj[key].toLowerCase() === "true") {
        obj[key] = true
      } else if (obj[key].toLowerCase() === "false") {
        obj[key] = false
      }
    }
  }
  return obj
}
