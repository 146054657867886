import Box from "@mui/material/Box"
import Primary from "./primary/Primary"
import Secondary from "./secondary/Secondary"

const headerStyle = {
  position: "sticky",
  fixed: "top",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 10,
}

const Header = ({
  handleLogout,
  handleSideBarOpen,
  handleSideBarClose,
  handleSideBarToggle,
  handleSideBarUpdate,
}) => {
  return (
    <Box style={headerStyle}>
      <Primary
        handleLogout={handleLogout}
        handleSideBarOpen={handleSideBarOpen}
        handleSideBarClose={handleSideBarClose}
        handleSideBarToggle={handleSideBarToggle}
        handleSideBarUpdate={handleSideBarUpdate}
      />
      <Secondary />
    </Box>
  )
}

export default Header
