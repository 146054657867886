import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import { colorScheme } from "../constant/fallbackConfigs"
import { getFormattedValue } from "../../../../utils/utils"

const parseNumber = (value) => {
  try {
    return typeof value === "number"
      ? value
      : parseFloat(value.replace(/[^0-9.]/g, ""))
  } catch (error) {
    return undefined
  }
}

export const transfromChartData = (input, tooltip_data) => {
  const reportDataCurrent = input?.reportDataCurrent || input
  const reportDataPrevious = input?.reportDataPrevious

  if (!reportDataCurrent) {
    console.error("Invalid input data structure:", input)
    return []
  }

  const sum = reportDataCurrent
    .map((item) => item.value || 0)
    .reduce((total, value) => total + value, 0)

  let data = []

  reportDataCurrent.forEach((currentItem) => {
    const previousItem =
      reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

    let eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      percentage: getFormattedValue(
        (currentItem.value / sum) * 100 || 0,
        "Percentage"
      ),
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]),
        format: tooltipDataItem.format,
      }

      if (reportDataPrevious) {
        eachElement.tooltipData.previous =
          eachElement.tooltipData.previous || {}
        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: parseNumber(previousItem[tooltipDataItem.name]),
          format: tooltipDataItem.format,
        }
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformDataComparedBy = (input, tooltip_data) => {
  if (!input) {
    console.error("Invalid input data structure:", input)
    return []
  }

  const sum = input
    .map((item) => item.value || 0)
    .reduce((total, value) => total + value, 0)

  const data = input.map((currentItem) => {
    const eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      percentage: getFormattedValue(
        (currentItem.value / sum) * 100 || 0,
        "Percentage"
      ),
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      if (currentItem[tooltipDataItem.name] !== undefined) {
        const value = parseNumber(currentItem[tooltipDataItem.name])
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: value,
          format: tooltipDataItem.format,
        }
      } else {
        console.warn(
          `Field ${tooltipDataItem.name} not found in currentItem:`,
          currentItem
        )
      }
    })

    return eachElement
  })

  return data
}

export const LegendData = (response) => {
  return Array.isArray(response.transformedChartData)
    ? response.transformedChartData?.map((item, idx) => ({
        label: item.id,
        color: colorScheme[idx],
      }))
    : []
}

export const transformDataPDFExport = (apiResponse, pdfFields) => {
  const transformedExportData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedExportData
  }

  const currentLength = currentData.length
  const dateFormat = getDateFormatFromLocalStorage()

  const parseCurrency = (currencyString) => {
    const numericString = currencyString.toString().replace(/[^0-9.-]+/g, "")
    return parseFloat(numericString)
  }

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"]),
    }

    pdfFields?.forEach((tooltip) => {
      const name = tooltip.name

      if (current.hasOwnProperty(name) && previous.hasOwnProperty(name)) {
        const currentVal = parseCurrency(current[name] || current["value"] || 0)
        const previousVal = parseCurrency(previous[name] || 0)

        transformedWeek[name] = {
          current:
            pdfFields.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous: `$${previousVal.toLocaleString()}`,
          changePercent:
            isNaN(previousVal) || previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      }
    })

    transformedExportData.push(transformedWeek)
  }

  return transformedExportData
}

export const transformDataSheetExport = (data) => {
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          const format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )
          if (value.previous && value.previous[subKey]) {
            flatItem[`previous ${subKey}`] = getFormattedValue(
              value.previous[subKey]?.value,
              format
            )
          }
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        flatItem[key] = value
      }
    })

    return flatItem
  })
}
