import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import dayjs from "dayjs"

export const parseNumber = (value) => {
  try {
    return typeof value === "number"
      ? value
      : parseFloat(value.replace(/[^0-9.]/g, ""))
  } catch (error) {
    return undefined
  }
}

export function transfromChartData(input, tooltip_data) {
  if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
    console.error("Invalid input data structure:", input)
    return []
  }

  // Create a map of current data by datekey
  const currentDataMap = input?.reportDataCurrent.reduce((map, item) => {
    if (item.datekey) {
      map[item.datekey] = item
    }
    return map
  }, {})

  // Helper function to adjust datekey by a fixed offset
  const adjustDatekey = (datekey, yearsOffset) => {
    const dateStr = datekey.toString()
    const year = parseInt(dateStr.substring(0, 4), 10)
    const newYear = year - yearsOffset
    return parseInt(`${newYear}${dateStr.substring(4)}`, 10)
  }

  let data = []

  input.reportDataCurrent.forEach((currentItem) => {
    // Adjust the datekey for matching
    const adjustedDatekey = adjustDatekey(currentItem.datekey, 1) // Assuming a 1-year offset

    // Find the corresponding current item
    const previousItem = currentDataMap[adjustedDatekey] || {}

    let eachElement = {
      day: currentItem.day,
      value: currentItem.value,
      reportType: "Calendar",
      previousDate: previousItem.day,
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data.forEach((tooltipDataItem) => {
      const tooltipName = tooltipDataItem.name

      // Handle current data
      eachElement.tooltipData.current[tooltipName] = {
        value: parseNumber(currentItem[tooltipName]) || 0,
        format: tooltipDataItem.format,
      }

      // Handle previous data
      eachElement.tooltipData.previous[tooltipName] = {
        value: parseNumber(previousItem[tooltipName]) || 0,
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export function transformExportData(apiResponse, extractedParameters = {}) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "value",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Currency",
      color: "",
    },
    {
      name: "Discounts",
      format: "Currency",
      color: "",
    },
  ]

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date: dayjs(current["day"]).format(dateFormat),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}
