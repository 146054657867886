import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import CustomLegend from "../../../../CustomLegend_re"
import AiInsights from "../../../../AiInsights"

const FooterSection = ({ title, apiResponse, legendsData }) => {
  return (
    <Stack display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        rowGap={1}
        columnGap={2}
        flexWrap="wrap"
      >
        <Box />
        <CustomLegend legendsData={legendsData} />
      </Box>
      <AiInsights reportTitle={title} reportData={apiResponse} />
    </Stack>
  )
}

export default FooterSection
