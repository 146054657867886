import { ComparisonofExpensesandRevenueData } from "../../../../constants/ComparisonofExpensesandRevenueData"
import { CustomerDetailsBarChartData } from "../../../../constants/CustomerDetailsBarChartData"
import { MostWastedProducts } from "../../../../constants/MostWastedProducts"

export const chartDataMap = {
  "Comparison of Expenses and Revenue": ComparisonofExpensesandRevenueData,
  "Most Wasted Products": MostWastedProducts,
  "Customer-Details-Page": CustomerDetailsBarChartData,
}

export const BarChartData = [
  {
    id: "New",
    "Gross Sales": 4285.47,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4285.47,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.83,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2290.04,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 82,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.93,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Repeat",
    "Gross Sales": 92528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
]

export const BarChartParameters = {
  keys: ["value"],
  indexBy: "id",
  margin: { top: 30, right: 10, bottom: 50, left: 60 },
  padding: 0.6,
  colors: "rgb(0, 108, 255), rgb(251, 101, 29)",
  borderColor: { from: "color", modifiers: [["darker", 1.6]] },
  borderRadius: 10,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -42,

    legendPosition: "middle",
    legendOffset: 32,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: "middle",
    legendOffset: -40,
  },
  labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
  enableLabel: false,
  isInteractive: false,
  layout: "horizantal",
}
