import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loadData } from "../../redux/slices/businessSynopsisSlice"
import { useTheme } from "../../contexts/theme"
import {
  getData,
  postData,
  aiFeedbackCreate,
  aiFeedbackUpdate,
  liveAssistanceApi,
} from "../../api/api"

import CloseIcon from "@mui/icons-material/Close"
import ThumbsDown from "@mui/icons-material/ThumbDownAlt"
import ThumbsUp from "@mui/icons-material/ThumbUpAlt"
import CircularProgress from "@mui/material/CircularProgress"
import Collapse from "@mui/material/Collapse"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import SendIcon from "@mui/icons-material/Send"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import DatePicker from "../../components/DatePicker"
import KPICard from "../../components/KPICard"
import { calculatePreviousPeriod } from "../../utils/dateUtils"
import MagicOverlay from "../authentication/MagicOverlay"
import { useNavigate } from "react-router-dom"

const BusinessSynopsis = (isOverlayVisible) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { theme } = useTheme()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const dateRangeData = useSelector((state) => state.calendar)
  const businessSynopsisData = useSelector((state) => state.businessSynopsis)
  const [positiveKPIs, setPositiveKPIs] = useState([])
  const [negativeKPIs, setNegativeKPIs] = useState([])

  const fetchReports = async () => {
    let dashboardReports = {
      dashboard: "Business Synopsis",
      dashboard_uid: "0",
      reports: [],
    }

    try {
      const response = await getData("businesssynopsis", {})

      response.forEach((element) => {
        element.reports.forEach((report) => {
          dashboardReports.reports.push(report)
        })
      })
      dispatch(loadData(dashboardReports))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    }
  }

  const fetchCountReportData = async () => {
    try {
      setPositiveKPIs([])
      setNegativeKPIs([])
      let data

      // default value if compared by is null
      const initialComparisonPeriod = calculatePreviousPeriod(
        dateRangeData.fromDate,
        dateRangeData.fromDate
      )
      countReports.forEach(async (report) => {
        var reportFormat =
          report?.reportTypes?.[0]?.reportParameterTypes?.[0]
            ?.reportParameters?.[0]?.parameterValue === undefined
            ? false
            : JSON.parse(
              report?.reportTypes?.[0]?.reportParameterTypes?.[0]
                ?.reportParameters?.[0]?.parameterValue
            ).format

        data = await postData("sales/data", {
          entityDb: localStorage.getItem("entityDb"),
          comparedBy:
            {
              previousFromDate:
                comparisonPeriod?.previousFromDate ||
                initialComparisonPeriod.previousFromDate,
              previousToDate:
                comparisonPeriod?.previousToDate ||
                initialComparisonPeriod.previousToDate,
            } || "",
          fromDate: dateRangeData.fromDate,
          toDate: dateRangeData.toDate,
          reportInstanceId: report.report_uid,
        })
        var reportDetail = {
          title: report.reportName,
          currentValue: data?.reportDataCurrent?.[0]?.value,
          previousValue: data?.reportDataPrevious?.[0]?.value,
          format: reportFormat,
        }
        if (reportDetail.currentValue > reportDetail.previousValue) {
          setPositiveKPIs((prev) => [...prev, reportDetail])
        } else if (reportDetail.currentValue < reportDetail.previousValue) {
          setNegativeKPIs((prev) => [...prev, reportDetail])
        }
      })
    } catch (error) {
      console.warn(error.message)
    }
  }

  const [aiSalesLoading, setAISalesLoading] = useState(true)
  const [createSaleFeedbackResponse, setCreateSaleFeedbackResponse] =
    useState(false)
  const [salesAnalysis, setSalesAnalysis] = useState([])
  const [salesRecommendations, setSalesRecommendations] = useState([])
  const [salesAnnomalies, setSalesAnnomalies] = useState([])
  // sales-analysis-start
  const [showSalesAnalysisText, setSalesAnalysisShowText] = useState(false)
  const [salesAnalysisFeedback, setSalesAnalysisFeedback] = useState(null)
  const [salesAnalysisFeedbackText, setSalesAnalysisFeedbackText] = useState("")
  const [salesAnalysisLoading, setSalesAnalysisLoading] = useState(false)
  const [salesAnalysisFeedbackMessage, setSalesAnalysisFeedbackMessage] =
    useState(false)
  const [salesAnalysisAlertOpen, setSalesAnalysisAlertOpen] = useState(false)

  const handleSalesAnalysisFeedback = (event, value) => {
    setSalesAnalysisFeedback(value)
    setSalesAnalysisShowText(!!value)
  }
  // sales-analysis-end
  // sales-recommendation-start
  const [showSalesRecommendationText, setSalesRecommendationShowText] =
    useState(false)
  const [salesRecommendationFeedback, setSalesRecommendationFeedback] =
    useState(null)
  const [salesRecommendationFeedbackText, setSalesRecommendationFeedbackText] =
    useState("")
  const [salesRecommendationLoading, setSalesRecommendationLoading] =
    useState(false)
  const [
    salesRecommendationFeedbackMessage,
    setSalesRecommendationFeedbackMessage,
  ] = useState(false)
  const [salesRecommendationAlertOpen, setSalesRecommendationAlertOpen] =
    useState(false)

  const handleSalesRecommendationFeedback = (event, value) => {
    setSalesRecommendationFeedback(value)
    setSalesRecommendationShowText(!!value)
  }
  // sales-recommendation-end
  // sales-anomaly-start
  const [showSalesAnomalyText, setSalesAnomalyShowText] = useState(false)
  const [salesAnomalyFeedback, setSalesAnomalyFeedback] = useState(null)
  const [salesAnomalyFeedbackText, setSalesAnomalyFeedbackText] = useState("")
  const [salesAnomalyLoading, setSalesAnomalyLoading] = useState(false)
  const [salesAnomalyFeedbackMessage, setSalesAnomalyFeedbackMessage] =
    useState(false)
  const [salesAnomalyAlertOpen, setSalesAnomalyAlertOpen] = useState(false)

  const handleSalesAnomalyFeedback = (event, value) => {
    setSalesAnomalyFeedback(value)
    setSalesAnomalyShowText(!!value)
  }
  // sales-anomaly-end
  const fetchSalesAIData = async () => {
    setAISalesLoading(true)
    try {
      const data = await postData("sales/data", {
        entityDb: localStorage.getItem("entityDb"),
        comparedBy:
          {
            previousFromDate: comparisonPeriod?.previousFromDate,
            previousToDate: comparisonPeriod?.previousToDate,
          } || "",
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: "FF13F014-D110-4E82-A68C-61A95AEFED3B",
        viewBy: "Day",
      })

      const userPrompt =
        "Summarize the key points from this sales data report, highlighting total sales, and any notable trends or insights."
      const aiResponseData = await liveAssistanceApi(userPrompt, data)

      const analysisData = aiResponseData?.msg?.[0].Analysis?.trim() || ""
      const recommendationData =
        aiResponseData?.msg?.[1].Recommendations?.trim() || ""
      const anomaliesData = aiResponseData?.msg?.[2].Anomalies?.trim() || ""
      setSalesAnalysis(analysisData)
      setSalesRecommendations(recommendationData)
      setSalesAnnomalies(anomaliesData)

      const fname = localStorage.getItem("fname") || ""
      const lname = localStorage.getItem("lname") || ""
      const fullName = `${fname} ${lname}`
      const userData = JSON.parse(localStorage.getItem("user"))
      const feedbackCreationData = {
        userID: userData?.userId || 1,
        entityID: localStorage.getItem("entityId"),
        parentURL: window.location.href,
        question: userPrompt,
        fullAnswer: aiResponseData?.msg,
        answerInsights: analysisData?.replace(/'/g, ""),
        answerRecommendations: recommendationData?.replace(/'/g, ""),
        answerAnomalies: anomaliesData?.replace(/'/g, ""),
        userName: fullName,
      }

      aiFeedbackCreate(feedbackCreationData).then((feedbackCreationResponse) =>
        setCreateSaleFeedbackResponse(feedbackCreationResponse)
      )
    } finally {
      setAISalesLoading(false)
    }
  }
  const handleSalesFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setSalesAnalysisLoading(true)
        setSalesAnalysisFeedbackMessage(false)
      },
      2: () => {
        setSalesRecommendationLoading(true)
        setSalesRecommendationFeedbackMessage(false)
      },
      3: () => {
        setSalesAnomalyLoading(true)
        setSalesAnomalyFeedbackMessage(false)
      },
    }

    const disableLoading = {
      1: (success) => {
        setSalesAnalysisLoading(false)
        setSalesAnalysisShowText(false)
        setSalesAnalysisFeedback(null)
        setSalesAnalysisFeedbackText("")
        setSalesAnalysisFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setSalesAnalysisAlertOpen(true)
      },
      2: (success) => {
        setSalesRecommendationLoading(false)
        setSalesRecommendationShowText(false)
        setSalesRecommendationFeedback(null)
        setSalesRecommendationFeedbackText("")
        setSalesRecommendationFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setSalesRecommendationAlertOpen(true)
      },
      3: (success) => {
        setSalesAnomalyLoading(false)
        setSalesAnomalyShowText(false)
        setSalesAnomalyFeedback(null)
        setSalesAnomalyFeedbackText("")
        setSalesAnomalyFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setSalesAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createSaleFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(() => {
          disableLoading[type](true)
        })
        .catch(() => {
          disableLoading[type](false)
        })
    } else {
      console.log("Error while submitting sales feedback")
    }
  }

  const [aiCustomersLoading, setAICustomersLoading] = useState(true)
  const [createCustomerFeedbackResponse, setCreateCustomerFeedbackResponse] =
    useState(false)
  const [customerAnalysis, setCustomerAnalysis] = useState([])
  const [customerRecommendations, setCustomerRecommendations] = useState([])
  const [customerAnnomalies, setCustomerAnnomalies] = useState([])
  // customer-analysis-start
  const [showCustomerAnalysisText, setCustomerAnalysisShowText] =
    useState(false)
  const [customerAnalysisFeedback, setCustomerAnalysisFeedback] = useState(null)
  const [customerAnalysisFeedbackText, setCustomerAnalysisFeedbackText] =
    useState("")
  const [customerAnalysisLoading, setCustomerAnalysisLoading] = useState(false)
  const [customerAnalysisFeedbackMessage, setCustomerAnalysisFeedbackMessage] =
    useState(false)
  const [customerAnalysisAlertOpen, setCustomerAnalysisAlertOpen] =
    useState(false)

  const handleCustomerAnalysisFeedback = (event, value) => {
    setCustomerAnalysisFeedback(value)
    setCustomerAnalysisShowText(!!value)
  }
  // customer-analysis-end
  // customer-recommendation-start
  const [showCustomerRecommendationText, setCustomerRecommendationShowText] =
    useState(false)
  const [customerRecommendationFeedback, setCustomerRecommendationFeedback] =
    useState(null)
  const [
    customerRecommendationFeedbackText,
    setCustomerRecommendationFeedbackText,
  ] = useState("")
  const [customerRecommendationLoading, setCustomerRecommendationLoading] =
    useState(false)
  const [
    customerRecommendationFeedbackMessage,
    setCustomerRecommendationFeedbackMessage,
  ] = useState(false)
  const [customerRecommendationAlertOpen, setCustomerRecommendationAlertOpen] =
    useState(false)

  const handleCustomerRecommendationFeedback = (event, value) => {
    setCustomerRecommendationFeedback(value)
    setCustomerRecommendationShowText(!!value)
  }
  // customer-recommendation-end
  // customer-anomaly-start
  const [showCustomerAnomalyText, setCustomerAnomalyShowText] = useState(false)
  const [customerAnomalyFeedback, setCustomerAnomalyFeedback] = useState(null)
  const [customerAnomalyFeedbackText, setCustomerAnomalyFeedbackText] =
    useState("")
  const [customerAnomalyLoading, setCustomerAnomalyLoading] = useState(false)
  const [customerAnomalyFeedbackMessage, setCustomerAnomalyFeedbackMessage] =
    useState(false)
  const [customerAnomalyAlertOpen, setCustomerAnomalyAlertOpen] =
    useState(false)

  const handleCustomerAnomalyFeedback = (event, value) => {
    setCustomerAnomalyFeedback(value)
    setCustomerAnomalyShowText(!!value)
  }
  // customer-anomaly-end
  const fetchCustomerAIData = async () => {
    setAICustomersLoading(true)
    try {
      const data = await postData("customer/data", {
        entityDb: localStorage.getItem("entityDb"),
        comparedBy:
          {
            previousFromDate: comparisonPeriod?.previousFromDate,
            previousToDate: comparisonPeriod?.previousToDate,
          } || "",
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: "08856963-D5CC-4B39-962E-EDBE77E0C3F3",
        viewBy: "Day",
      })

      const userPrompt =
        "Summarize the customer data report, focusing on customer demographics, purchasing patterns, and any key trends in customer behavior."
      const aiResponseData = await liveAssistanceApi(userPrompt, data)

      const analysisData = aiResponseData?.msg?.[0].Analysis?.trim() || ""
      const recommendationData =
        aiResponseData?.msg?.[1].Recommendations?.trim() || ""
      const anomaliesData = aiResponseData?.msg?.[2].Anomalies?.trim() || ""

      setCustomerAnalysis(analysisData)
      setCustomerRecommendations(recommendationData)
      setCustomerAnnomalies(anomaliesData)

      const fname = localStorage.getItem("fname") || ""
      const lname = localStorage.getItem("lname") || ""
      const fullName = `${fname} ${lname}`
      const userData = JSON.parse(localStorage.getItem("user"))
      const feedbackCreationData = {
        userID: userData?.userId || 1,
        entityID: localStorage.getItem("entityId"),
        parentURL: window.location.href,
        question: userPrompt,
        fullAnswer: aiResponseData?.msg,
        answerInsights: analysisData?.replace(/'/g, ""),
        answerRecommendations: recommendationData?.replace(/'/g, ""),
        answerAnomalies: anomaliesData?.replace(/'/g, ""),
        userName: fullName,
      }

      aiFeedbackCreate(feedbackCreationData).then((feedbackCreationResponse) =>
        setCreateCustomerFeedbackResponse(feedbackCreationResponse)
      )
    } finally {
      setAICustomersLoading(false)
    }
  }
  const handleCustomerFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setCustomerAnalysisLoading(true)
        setCustomerAnalysisFeedbackMessage(false)
      },
      2: () => {
        setCustomerRecommendationLoading(true)
        setCustomerRecommendationFeedbackMessage(false)
      },
      3: () => {
        setCustomerAnomalyLoading(true)
        setCustomerAnomalyFeedbackMessage(false)
      },
    }

    const disableLoading = {
      1: (success) => {
        setCustomerAnalysisLoading(false)
        setCustomerAnalysisShowText(false)
        setCustomerAnalysisFeedback(null)
        setCustomerAnalysisFeedbackText("")
        setCustomerAnalysisFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setCustomerAnalysisAlertOpen(true)
      },
      2: (success) => {
        setCustomerRecommendationLoading(false)
        setCustomerRecommendationShowText(false)
        setCustomerRecommendationFeedback(null)
        setCustomerRecommendationFeedbackText("")
        setCustomerRecommendationFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setCustomerRecommendationAlertOpen(true)
      },
      3: (success) => {
        setCustomerAnomalyLoading(false)
        setCustomerAnomalyShowText(false)
        setCustomerAnomalyFeedback(null)
        setCustomerAnomalyFeedbackText("")
        setCustomerAnomalyFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setCustomerAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createCustomerFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(() => {
          disableLoading[type](true)
        })
        .catch(() => {
          disableLoading[type](false)
        })
    } else {
      console.log("Error while submitting customer feedback")
    }
  }

  const [aiProductsLoading, setAIProductsLoading] = useState(true)
  const [createProductFeedbackResponse, setCreateProductFeedbackResponse] =
    useState(false)
  const [productAnalysis, setProductAnalysis] = useState([])
  const [productRecommendations, setProductRecommendations] = useState([])
  const [productAnnomalies, setProductAnnomalies] = useState([])
  // product-analysis-start
  const [showProductAnalysisText, setProductAnalysisShowText] = useState(false)
  const [productAnalysisFeedback, setProductAnalysisFeedback] = useState(null)
  const [productAnalysisFeedbackText, setProductAnalysisFeedbackText] =
    useState("")
  const [productAnalysisLoading, setProductAnalysisLoading] = useState(false)
  const [productAnalysisFeedbackMessage, setProductAnalysisFeedbackMessage] =
    useState(false)
  const [productAnalysisAlertOpen, setProductAnalysisAlertOpen] =
    useState(false)

  const handleProductAnalysisFeedback = (event, value) => {
    setProductAnalysisFeedback(value)
    setProductAnalysisShowText(!!value)
  }
  // product-analysis-end
  // product-recommendation-start
  const [showProductRecommendationText, setProductRecommendationShowText] =
    useState(false)
  const [productRecommendationFeedback, setProductRecommendationFeedback] =
    useState(null)
  const [
    productRecommendationFeedbackText,
    setProductRecommendationFeedbackText,
  ] = useState("")
  const [productRecommendationLoading, setProductRecommendationLoading] =
    useState(false)
  const [
    productRecommendationFeedbackMessage,
    setProductRecommendationFeedbackMessage,
  ] = useState(false)
  const [productRecommendationAlertOpen, setProductRecommendationAlertOpen] =
    useState(false)

  const handleProductRecommendationFeedback = (event, value) => {
    setProductRecommendationFeedback(value)
    setProductRecommendationShowText(!!value)
  }
  // product-recommendation-end
  // product-anomaly-start
  const [showProductAnomalyText, setProductAnomalyShowText] = useState(false)
  const [productAnomalyFeedback, setProductAnomalyFeedback] = useState(null)
  const [productAnomalyFeedbackText, setProductAnomalyFeedbackText] =
    useState("")
  const [productAnomalyLoading, setProductAnomalyLoading] = useState(false)
  const [productAnomalyFeedbackMessage, setProductAnomalyFeedbackMessage] =
    useState(false)
  const [productAnomalyAlertOpen, setProductAnomalyAlertOpen] = useState(false)

  const handleProductAnomalyFeedback = (event, value) => {
    setProductAnomalyFeedback(value)
    setProductAnomalyShowText(!!value)
  }
  // product-anomaly-end

  const fetchProductAIData = async () => {
    setAIProductsLoading(true)
    try {
      const data = await postData("inventory/data", {
        entityDb: localStorage.getItem("entityDb"),
        comparedBy:
          {
            previousFromDate: comparisonPeriod?.previousFromDate,
            previousToDate: comparisonPeriod?.previousToDate,
          } || "",
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: "BB78FBAC-D095-4BAB-BBF0-3EC376D25F4C",
      })

      const userPrompt =
        "Summarize the product report, highlighting the top-performing products, sales trends, any underperforming items, and key insights related to product categories."
      const aiResponseData = await liveAssistanceApi(userPrompt, data)

      const analysisData = aiResponseData?.msg?.[0].Analysis?.trim() || ""
      const recommendationData =
        aiResponseData?.msg?.[1].Recommendations?.trim() || ""
      const anomaliesData = aiResponseData?.msg?.[2].Anomalies?.trim() || ""

      setProductAnalysis(analysisData)
      setProductRecommendations(recommendationData)
      setProductAnnomalies(anomaliesData)

      const fname = localStorage.getItem("fname") || ""
      const lname = localStorage.getItem("lname") || ""
      const fullName = `${fname} ${lname}`
      const userData = JSON.parse(localStorage.getItem("user"))
      const feedbackCreationData = {
        userID: userData?.userId || 1,
        entityID: localStorage.getItem("entityId"),
        parentURL: window.location.href,
        question: userPrompt,
        fullAnswer: aiResponseData?.msg,
        answerInsights: analysisData?.replace(/'/g, ""),
        answerRecommendations: recommendationData?.replace(/'/g, ""),
        answerAnomalies: anomaliesData?.replace(/'/g, ""),
        userName: fullName,
      }

      aiFeedbackCreate(feedbackCreationData).then((feedbackCreationResponse) =>
        setCreateProductFeedbackResponse(feedbackCreationResponse)
      )
    } finally {
      setAIProductsLoading(false)
    }
  }
  const handleProductFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setProductAnalysisLoading(true)
        setProductAnalysisFeedbackMessage(false)
      },
      2: () => {
        setProductRecommendationLoading(true)
        setProductRecommendationFeedbackMessage(false)
      },
      3: () => {
        setProductAnomalyLoading(true)
        setProductAnomalyFeedbackMessage(false)
      },
    }

    const disableLoading = {
      1: (success) => {
        setProductAnalysisLoading(false)
        setProductAnalysisShowText(false)
        setProductAnalysisFeedback(null)
        setProductAnalysisFeedbackText("")
        setProductAnalysisFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setProductAnalysisAlertOpen(true)
      },
      2: (success) => {
        setProductRecommendationLoading(false)
        setProductRecommendationShowText(false)
        setProductRecommendationFeedback(null)
        setProductRecommendationFeedbackText("")
        setProductRecommendationFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setProductRecommendationAlertOpen(true)
      },
      3: (success) => {
        setProductAnomalyLoading(false)
        setProductAnomalyShowText(false)
        setProductAnomalyFeedback(null)
        setProductAnomalyFeedbackText("")
        setProductAnomalyFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setProductAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createProductFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(() => {
          disableLoading[type](true)
        })
        .catch(() => {
          disableLoading[type](false)
        })
    } else {
      console.log("Error while submitting product feedback")
    }
  }

  const [aiEmployeeLoading, setAIEmployeeLoading] = useState(true)
  const [createEmployeeFeedbackResponse, setCreateEmployeeFeedbackResponse] =
    useState(false)
  const [employeeAnalysis, setEmployeeAnalysis] = useState([])
  const [employeeRecommendations, setEmployeeRecommendations] = useState([])
  const [employeeAnnomalies, setEmployeeAnnomalies] = useState([])
  // employee-analysis-start
  const [showEmployeeAnalysisText, setEmployeeAnalysisShowText] =
    useState(false)
  const [employeeAnalysisFeedback, setEmployeeAnalysisFeedback] = useState(null)
  const [employeeAnalysisFeedbackText, setEmployeeAnalysisFeedbackText] =
    useState("")
  const [employeeAnalysisLoading, setEmployeeAnalysisLoading] = useState(false)
  const [employeeAnalysisFeedbackMessage, setEmployeeAnalysisFeedbackMessage] =
    useState(false)
  const [employeeAnalysisAlertOpen, setEmployeeAnalysisAlertOpen] =
    useState(false)

  const handleEmployeeAnalysisFeedback = (event, value) => {
    setEmployeeAnalysisFeedback(value)
    setEmployeeAnalysisShowText(!!value)
  }
  // employee-analysis-end
  // employee-recommendation-start
  const [showEmployeeRecommendationText, setEmployeeRecommendationShowText] =
    useState(false)
  const [employeeRecommendationFeedback, setEmployeeRecommendationFeedback] =
    useState(null)
  const [
    employeeRecommendationFeedbackText,
    setEmployeeRecommendationFeedbackText,
  ] = useState("")
  const [employeeRecommendationLoading, setEmployeeRecommendationLoading] =
    useState(false)
  const [
    employeeRecommendationFeedbackMessage,
    setEmployeeRecommendationFeedbackMessage,
  ] = useState(false)
  const [employeeRecommendationAlertOpen, setEmployeeRecommendationAlertOpen] =
    useState(false)

  const handleEmployeeRecommendationFeedback = (event, value) => {
    setEmployeeRecommendationFeedback(value)
    setEmployeeRecommendationShowText(!!value)
  }
  // employee-recommendation-end
  // employee-anomaly-start
  const [showEmployeeAnomalyText, setEmployeeAnomalyShowText] = useState(false)
  const [employeeAnomalyFeedback, setEmployeeAnomalyFeedback] = useState(null)
  const [employeeAnomalyFeedbackText, setEmployeeAnomalyFeedbackText] =
    useState("")
  const [employeeAnomalyLoading, setEmployeeAnomalyLoading] = useState(false)
  const [employeeAnomalyFeedbackMessage, setEmployeeAnomalyFeedbackMessage] =
    useState(false)
  const [employeeAnomalyAlertOpen, setEmployeeAnomalyAlertOpen] =
    useState(false)

  const handleEmployeeAnomalyFeedback = (event, value) => {
    setEmployeeAnomalyFeedback(value)
    setEmployeeAnomalyShowText(!!value)
  }
  // employee-anomaly-end
  const fetchEmployeeAIData = async () => {
    setAIEmployeeLoading(true)
    try {
      const data = await postData("employee/data", {
        entityDb: localStorage.getItem("entityDb"),
        comparedBy:
          {
            previousFromDate: comparisonPeriod?.previousFromDate,
            previousToDate: comparisonPeriod?.previousToDate,
          } || "",
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: "206F9265-DDD2-4306-9B1D-30FEFEF35228",
        viewBy: "Day",
      })
      const userPrompt =
        "Summarize the employee performance report, emphasizing top-performing employees, sales contributions, and any significant trends or observations related to employee productivity."
      const aiResponseData = await liveAssistanceApi(userPrompt, data)

      const analysisData = aiResponseData?.msg?.[0].Analysis?.trim() || ""
      const recommendationData =
        aiResponseData?.msg?.[1].Recommendations?.trim() || ""
      const anomaliesData = aiResponseData?.msg?.[2].Anomalies?.trim() || ""

      setEmployeeAnalysis(analysisData)
      setEmployeeRecommendations(recommendationData)
      setEmployeeAnnomalies(anomaliesData)

      const fname = localStorage.getItem("fname") || ""
      const lname = localStorage.getItem("lname") || ""
      const fullName = `${fname} ${lname}`
      const userData = JSON.parse(localStorage.getItem("user"))
      const feedbackCreationData = {
        userID: userData?.userId || 1,
        entityID: localStorage.getItem("entityId"),
        parentURL: window.location.href,
        question: userPrompt,
        fullAnswer: aiResponseData?.msg,
        answerInsights: analysisData?.replace(/'/g, ""),
        answerRecommendations: recommendationData?.replace(/'/g, ""),
        answerAnomalies: anomaliesData?.replace(/'/g, ""),
        userName: fullName,
      }

      aiFeedbackCreate(feedbackCreationData).then((feedbackCreationResponse) =>
        setCreateEmployeeFeedbackResponse(feedbackCreationResponse)
      )
    } finally {
      setAIEmployeeLoading(false)
    }
  }
  const handleEmployeeFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setEmployeeAnalysisLoading(true)
        setEmployeeAnalysisFeedbackMessage(false)
      },
      2: () => {
        setEmployeeRecommendationLoading(true)
        setEmployeeRecommendationFeedbackMessage(false)
      },
      3: () => {
        setEmployeeAnomalyLoading(true)
        setEmployeeAnomalyFeedbackMessage(false)
      },
    }

    const disableLoading = {
      1: (success) => {
        setEmployeeAnalysisLoading(false)
        setEmployeeAnalysisShowText(false)
        setEmployeeAnalysisFeedback(null)
        setEmployeeAnalysisFeedbackText("")
        setEmployeeAnalysisFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setEmployeeAnalysisAlertOpen(true)
      },
      2: (success) => {
        setEmployeeRecommendationLoading(false)
        setEmployeeRecommendationShowText(false)
        setEmployeeRecommendationFeedback(null)
        setEmployeeRecommendationFeedbackText("")
        setEmployeeRecommendationFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setEmployeeRecommendationAlertOpen(true)
      },
      3: (success) => {
        setEmployeeAnomalyLoading(false)
        setEmployeeAnomalyShowText(false)
        setEmployeeAnomalyFeedback(null)
        setEmployeeAnomalyFeedbackText("")
        setEmployeeAnomalyFeedbackMessage({
          severity: success ? "success" : "warning",
          message: success
            ? "Your Feedback was submitted successfully! Thank you!"
            : "Something went wrong while submitting this feedback...",
        })
        setEmployeeAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createEmployeeFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(() => {
          disableLoading[type](true)
        })
        .catch(() => {
          disableLoading[type](false)
        })
    } else {
      console.log("Error while submitting employee feedback")
    }
  }

  useEffect(() => {
    if (localStorage.getItem("dataStatus")?.trim() !== "Ready") {
      navigate("/businesssynopsis/in-process")
      return
    }
    fetchSalesAIData()
    fetchCustomerAIData()
    fetchProductAIData()
    fetchEmployeeAIData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData])

  useEffect(() => {
    if (localStorage.getItem("dataStatus")?.trim() !== "Ready") {
      navigate("/businesssynopsis/in-process")
      return
    }
    if (businessSynopsisData?.reports?.length === 0) fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessSynopsisData?.reports?.length])

  const countReports = businessSynopsisData?.reports?.filter((report) =>
    report.reportTypes.some((type) => type.ReportType === "Count")
  )

  useEffect(() => {
    fetchCountReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countReports?.length,
    dateRangeData,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
  ])

  const isValidValue = (value) => {
    return value && value !== "null" && value !== "undefined" // Check for falsy, "null", or "undefined" string
  }

  const userInfoKeys = {
    businessName: "Business Name",
    addressLine1: "Address",
    addressLine2: "",
    postalCode: "Postal Code",
    businessPhone: "Business Phone",
  }

  return (
    <>
      <RouteTitle>
        <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
          <Typography
            variant="subtitle1"
            color="inherit"
            fontWeight="fontWeightMedium"
          >
            {`Good to see you again, ${localStorage.getItem("fname")}!`}
          </Typography>
          <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
            {Object.entries(userInfoKeys).map(([key, label], index, array) => {
              let value = localStorage.getItem(key)

              // Only render if the value is valid
              if (!isValidValue(value)) return null

              return (
                <>
                  {index !== 0 && (
                    <Typography variant="body2" sx={{ opacity: 0.6 }}>
                      |
                    </Typography>
                  )}

                  <Typography
                    key={key}
                    variant="body2"
                    color="inherit"
                    fontWeight="fontWeightLight"
                    sx={{ fontSize: "0.8rem", opacity: 0.9 }}
                  >
                    {`${label}: ${value}`}
                  </Typography>
                </>
              )
            })}
          </Box>
        </Stack>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <DatePicker />
        </Box>
      </RouteTitle>
      <Stack padding="15px 30px 15px 30px" gap={2}>
        <Box>
          <Typography
            variant="h6"
            color="inherit"
            mb={2}
            fontWeight="fontWeightSemiBold"
          >
            Actionable Insights & Analysis
          </Typography>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomPaper
                  customStyles={{
                    border:
                      theme.palette.mode === "dark" ? "2px solid #0C4126" : "",
                    background:
                      theme.palette.mode === "dark"
                        ? "linear-gradient(120deg, #23BF6F -28.02%, #0D2266 41.73%)"
                        : "linear-gradient(120deg, #23BF6F -28.02%, #E9EDFC 41.73%)",
                  }}
                  innerCustomStyles={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Positive Trends
                  </Typography>

                  {positiveKPIs.length === 0 ? (
                    <CircularProgress disableShrink color="info" size={30} />
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {positiveKPIs?.map((report, index) => (
                          <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                            <CustomPaper
                              key={index}
                              customStyles={{ height: "100%" }}
                              watermarkBg={true}
                              CustomDrawerComponent={true}
                            >
                              <KPICard
                                title={report.title}
                                star={report.star}
                                format={report.format}
                                parameters={[
                                  {
                                    currentValue: report.currentValue,
                                    previousValue: report.previousValue,
                                  },
                                ]}
                                reportUid="static-kpi"
                                postUrl=""
                                dateRangeData={{}}
                                comparisonPeriod={{}}
                                description=""
                              />

                              {/* <KPICard
                                key={report.report_uid}
                                title={report.title}
                                parameters={[
                                  {
                                    currentValue: report.currentValue,
                                    previousValue: report.previousValue,
                                  },
                                ]}
                                dateRangeData={dateRangeData}
                                description={report.reportDescription}
                                postUrl="businesssynopsis/data"
                                reportUid={report.report_uid}
                                width="100%"
                                height="6rem"
                                comparisonPeriod={comparisonPeriod}
                                format={report.format}
                                showPrevious={true}
                              /> */}
                            </CustomPaper>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomPaper
                  customStyles={{
                    border:
                      theme.palette.mode === "dark" ? "2px solid #460607" : "",
                    background:
                      theme.palette.mode === "dark"
                        ? "linear-gradient(120deg, #EE000C -28.02%, #0E256D 41.73%)"
                        : "linear-gradient(120deg, #EE000C -28.02%, #E9EDFC 41.73%)",
                  }}
                  innerCustomStyles={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Negative Trends
                  </Typography>
                  {negativeKPIs.length === 0 ? (
                    <CircularProgress disableShrink color="info" size={30} />
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        {negativeKPIs?.map((report, index) => (
                          <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                            <CustomPaper
                              key={index}
                              customStyles={{ height: "100%" }}
                              watermarkBg={true}
                              CustomDrawerComponent={true}
                            >
                              <KPICard
                                title={report.title}
                                star={report.star}
                                format={report.format}
                                parameters={[
                                  {
                                    currentValue: report.currentValue,
                                    previousValue: report.previousValue,
                                  },
                                ]}
                                postUrl=""
                                reportUid="static-kpi"
                                dateRangeData={{}}
                                comparisonPeriod={{}}
                              />
                              {/* <KPICard
                                key={report.report_uid}
                                title={report.title}
                                parameters={[
                                  {
                                    currentValue: report.currentValue,
                                    previousValue: report.previousValue,
                                  },
                                ]}
                                dateRangeData={dateRangeData}
                                description={report.reportDescription}
                                postUrl="businesssynopsis/data"
                                reportUid={report.report_uid}
                                width="100%"
                                height="6rem"
                                comparisonPeriod={comparisonPeriod}
                                format={report.format}
                                showPrevious={true}
                              /> */}
                            </CustomPaper>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#FCBF49"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Analysis – Patterns
            </Typography>
          </Box>
          <CustomPaper>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Sales
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FCBF49",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#191207" : "#FFF4E5",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiSalesLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {salesAnalysis}
                        </Typography>
                        {createSaleFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!salesAnalysisFeedbackMessage ? (
                                <Collapse in={salesAnalysisAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setSalesAnalysisAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      salesAnalysisFeedbackMessage?.severity
                                    }
                                  >
                                    {salesAnalysisFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={salesAnalysisFeedback}
                                exclusive
                                onChange={handleSalesAnalysisFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showSalesAnalysisText && (
                              <TextField
                                disabled={salesAnalysisLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={salesAnalysisFeedbackText}
                                onChange={(e) =>
                                  setSalesAnalysisFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this response."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          salesAnalysisFeedback === "dislike" &&
                                          ["", null, undefined].includes(
                                            salesAnalysisFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleSalesFeedbackUpdate(
                                            1,
                                            salesAnalysisFeedback,
                                            salesAnalysisFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {salesAnalysisLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Customers
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FCBF49",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#191207" : "#FFF4E5",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiCustomersLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {customerAnalysis}
                        </Typography>
                        {createCustomerFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!customerAnalysisFeedbackMessage ? (
                                <Collapse in={customerAnalysisAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setCustomerAnalysisAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      customerAnalysisFeedbackMessage?.severity
                                    }
                                  >
                                    {customerAnalysisFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={customerAnalysisFeedback}
                                exclusive
                                onChange={handleCustomerAnalysisFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showCustomerAnalysisText && (
                              <TextField
                                disabled={customerAnalysisLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={customerAnalysisFeedbackText}
                                onChange={(e) =>
                                  setCustomerAnalysisFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this response."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          customerAnalysisFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            customerAnalysisFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleCustomerFeedbackUpdate(
                                            1,
                                            customerAnalysisFeedback,
                                            customerAnalysisFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {customerAnalysisLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Products
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FCBF49",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#191207" : "#FFF4E5",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiProductsLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {productAnalysis}
                        </Typography>
                        {createProductFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!productAnalysisFeedbackMessage ? (
                                <Collapse in={productAnalysisAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setProductAnalysisAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      productAnalysisFeedbackMessage?.severity
                                    }
                                  >
                                    {productAnalysisFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={productAnalysisFeedback}
                                exclusive
                                onChange={handleProductAnalysisFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showProductAnalysisText && (
                              <TextField
                                disabled={productAnalysisLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={productAnalysisFeedbackText}
                                onChange={(e) =>
                                  setProductAnalysisFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this response."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          productAnalysisFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            productAnalysisFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleProductFeedbackUpdate(
                                            1,
                                            productAnalysisFeedback,
                                            productAnalysisFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {productAnalysisLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Employees
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FCBF49",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#191207" : "#FFF4E5",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiEmployeeLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {employeeAnalysis}
                        </Typography>
                        {createEmployeeFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!employeeAnalysisFeedbackMessage ? (
                                <Collapse in={employeeAnalysisAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setEmployeeAnalysisAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      employeeAnalysisFeedbackMessage?.severity
                                    }
                                  >
                                    {employeeAnalysisFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={employeeAnalysisFeedback}
                                exclusive
                                onChange={handleEmployeeAnalysisFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showEmployeeAnalysisText && (
                              <TextField
                                disabled={employeeAnalysisLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={employeeAnalysisFeedbackText}
                                onChange={(e) =>
                                  setEmployeeAnalysisFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this response."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          employeeAnalysisFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            employeeAnalysisFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleEmployeeFeedbackUpdate(
                                            1,
                                            employeeAnalysisFeedback,
                                            employeeAnalysisFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {employeeAnalysisLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
            </Stack>
          </CustomPaper>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#50CD89"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Recommendations
            </Typography>
          </Box>
          <CustomPaper>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Sales
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #50CD89",

                      backgroundColor:
                        theme.palette.mode === "dark" ? "#0C130D" : "#EDF7ED",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiSalesLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {salesRecommendations}
                        </Typography>
                        {createSaleFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!salesRecommendationFeedbackMessage ? (
                                <Collapse in={salesRecommendationAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setSalesRecommendationAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      salesRecommendationFeedbackMessage?.severity
                                    }
                                  >
                                    {
                                      salesRecommendationFeedbackMessage?.message
                                    }
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={salesRecommendationFeedback}
                                exclusive
                                onChange={handleSalesRecommendationFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showSalesRecommendationText && (
                              <TextField
                                disabled={salesRecommendationLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={salesRecommendationFeedbackText}
                                onChange={(e) =>
                                  setSalesRecommendationFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this recommendation."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          salesRecommendationFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            salesRecommendationFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleSalesFeedbackUpdate(
                                            2,
                                            salesRecommendationFeedback,
                                            salesRecommendationFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {salesRecommendationLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Customers
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #50CD89",

                      backgroundColor:
                        theme.palette.mode === "dark" ? "#0C130D" : "#EDF7ED",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiCustomersLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {customerRecommendations}
                        </Typography>
                        {createCustomerFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!customerRecommendationFeedbackMessage ? (
                                <Collapse in={customerRecommendationAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setCustomerRecommendationAlertOpen(
                                            false
                                          )
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      customerRecommendationFeedbackMessage?.severity
                                    }
                                  >
                                    {
                                      customerRecommendationFeedbackMessage?.message
                                    }
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={customerRecommendationFeedback}
                                exclusive
                                onChange={handleCustomerRecommendationFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showCustomerRecommendationText && (
                              <TextField
                                disabled={customerRecommendationLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={customerRecommendationFeedbackText}
                                onChange={(e) =>
                                  setCustomerRecommendationFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this recommendation."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          customerRecommendationFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            customerRecommendationFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleCustomerFeedbackUpdate(
                                            2,
                                            customerRecommendationFeedback,
                                            customerRecommendationFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {customerRecommendationLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Products
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #50CD89",

                      backgroundColor:
                        theme.palette.mode === "dark" ? "#0C130D" : "#EDF7ED",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiProductsLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {productRecommendations}
                        </Typography>
                        {createProductFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!productRecommendationFeedbackMessage ? (
                                <Collapse in={productRecommendationAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setProductRecommendationAlertOpen(
                                            false
                                          )
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      productRecommendationFeedbackMessage?.severity
                                    }
                                  >
                                    {
                                      productRecommendationFeedbackMessage?.message
                                    }
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={productRecommendationFeedback}
                                exclusive
                                onChange={handleProductRecommendationFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showProductRecommendationText && (
                              <TextField
                                disabled={productRecommendationLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={productRecommendationFeedbackText}
                                onChange={(e) =>
                                  setProductRecommendationFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this recommendation."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          productRecommendationFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            productRecommendationFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleProductFeedbackUpdate(
                                            2,
                                            productRecommendationFeedback,
                                            productRecommendationFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {productRecommendationLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Employees
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #50CD89",

                      backgroundColor:
                        theme.palette.mode === "dark" ? "#0C130D" : "#EDF7ED",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiEmployeeLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {employeeRecommendations}
                        </Typography>
                        {createEmployeeFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!employeeRecommendationFeedbackMessage ? (
                                <Collapse in={employeeRecommendationAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setEmployeeRecommendationAlertOpen(
                                            false
                                          )
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      employeeRecommendationFeedbackMessage?.severity
                                    }
                                  >
                                    {
                                      employeeRecommendationFeedbackMessage?.message
                                    }
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={employeeRecommendationFeedback}
                                exclusive
                                onChange={handleEmployeeRecommendationFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showEmployeeRecommendationText && (
                              <TextField
                                disabled={employeeRecommendationLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={employeeRecommendationFeedbackText}
                                onChange={(e) =>
                                  setEmployeeRecommendationFeedbackText(
                                    e.target.value
                                  )
                                }
                                placeholder="Please let us know your feedback on this recommendation."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          employeeRecommendationFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            employeeRecommendationFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleEmployeeFeedbackUpdate(
                                            2,
                                            employeeRecommendationFeedback,
                                            employeeRecommendationFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {employeeRecommendationLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
            </Stack>
          </CustomPaper>
        </Box>

        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#FF0000"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Anomalies
            </Typography>
          </Box>
          <CustomPaper>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Sales
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FF0000",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#160b0b" : "#fdeded",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiSalesLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {salesAnnomalies}
                        </Typography>
                        {createSaleFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!salesAnomalyFeedbackMessage ? (
                                <Collapse in={salesAnomalyAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setSalesAnomalyAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      salesAnomalyFeedbackMessage?.severity
                                    }
                                  >
                                    {salesAnomalyFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={salesAnomalyFeedback}
                                exclusive
                                onChange={handleSalesAnomalyFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showSalesAnomalyText && (
                              <TextField
                                disabled={salesAnomalyLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={salesAnomalyFeedbackText}
                                onChange={(e) =>
                                  setSalesAnomalyFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this anomaly."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          salesAnomalyFeedback === "dislike" &&
                                          ["", null, undefined].includes(
                                            salesAnomalyFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleSalesFeedbackUpdate(
                                            3,
                                            salesAnomalyFeedback,
                                            salesAnomalyFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {salesAnomalyLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Customers
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FF0000",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#160b0b" : "#fdeded",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiCustomersLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {customerAnnomalies}
                        </Typography>
                        {createCustomerFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!customerAnomalyFeedbackMessage ? (
                                <Collapse in={customerAnomalyAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setCustomerAnomalyAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      customerAnomalyFeedbackMessage?.severity
                                    }
                                  >
                                    {customerAnomalyFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={customerAnomalyFeedback}
                                exclusive
                                onChange={handleCustomerAnomalyFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showCustomerAnomalyText && (
                              <TextField
                                disabled={customerAnomalyLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={customerAnomalyFeedbackText}
                                onChange={(e) =>
                                  setCustomerAnomalyFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this anomaly."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          customerAnomalyFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            customerAnomalyFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleCustomerFeedbackUpdate(
                                            3,
                                            customerAnomalyFeedback,
                                            customerAnomalyFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {customerAnomalyLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Products
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FF0000",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#160b0b" : "#fdeded",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiProductsLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {productAnnomalies}
                        </Typography>
                        {createProductFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!productAnomalyFeedbackMessage ? (
                                <Collapse in={productAnomalyAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setProductAnomalyAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      productAnomalyFeedbackMessage?.severity
                                    }
                                  >
                                    {productAnomalyFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={productAnomalyFeedback}
                                exclusive
                                onChange={handleProductAnomalyFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showProductAnomalyText && (
                              <TextField
                                disabled={productAnomalyLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={productAnomalyFeedbackText}
                                onChange={(e) =>
                                  setProductAnomalyFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this anomaly."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          productAnomalyFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            productAnomalyFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleProductFeedbackUpdate(
                                            3,
                                            productAnomalyFeedback,
                                            productAnomalyFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {productAnomalyLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Employees
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      overflow: "visible",
                      borderLeft: "2px solid #FF0000",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#160b0b" : "#fdeded",
                    }}
                    innerCustomStyles={{
                      border: 0,
                    }}
                  >
                    {aiEmployeeLoading ? (
                      <CircularProgress disableShrink color="info" size={30} />
                    ) : (
                      <Stack display="flex" flexDirection="column" gap={1}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          sx={{ whiteSpace: "pre-wrap" }}
                        >
                          {employeeAnnomalies}
                        </Typography>
                        {createEmployeeFeedbackResponse && (
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              gap={1}
                            >
                              {!!employeeAnomalyFeedbackMessage ? (
                                <Collapse in={employeeAnomalyAlertOpen}>
                                  <Alert
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setEmployeeAnomalyAlertOpen(false)
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                    variant="outlined"
                                    severity={
                                      employeeAnomalyFeedbackMessage?.severity
                                    }
                                  >
                                    {employeeAnomalyFeedbackMessage?.message}
                                  </Alert>
                                </Collapse>
                              ) : (
                                <div />
                              )}
                              <ToggleButtonGroup
                                value={employeeAnomalyFeedback}
                                exclusive
                                onChange={handleEmployeeAnomalyFeedback}
                                aria-label="feedback buttons"
                              >
                                <ToggleButton
                                  value="like"
                                  aria-label="good feedback"
                                >
                                  <ThumbsUp fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                  value="dislike"
                                  aria-label="bad feedback"
                                >
                                  <ThumbsDown fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>

                            {showEmployeeAnomalyText && (
                              <TextField
                                disabled={employeeAnomalyLoading}
                                hiddenLabel
                                size="small"
                                multiline
                                rows={2}
                                value={employeeAnomalyFeedbackText}
                                onChange={(e) =>
                                  setEmployeeAnomalyFeedbackText(e.target.value)
                                }
                                placeholder="Please let us know your feedback on this anomaly."
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "0.8rem",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "0.8rem",
                                  },
                                  minWidth: "300px",
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        disabled={
                                          employeeAnomalyFeedback ===
                                          "dislike" &&
                                          ["", null, undefined].includes(
                                            employeeAnomalyFeedbackText
                                          )
                                        }
                                        onClick={() =>
                                          handleEmployeeFeedbackUpdate(
                                            3,
                                            employeeAnomalyFeedback,
                                            employeeAnomalyFeedbackText
                                          )
                                        }
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        {employeeAnomalyLoading ? (
                                          <CircularProgress
                                            disableShrink
                                            size="20px"
                                            sx={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "#FFFFFF"
                                                  : "#707070",
                                            }}
                                          />
                                        ) : (
                                          <SendIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </CustomPaper>
                </Grid>
              </Grid>
            </Stack>
          </CustomPaper>
        </Box>
      </Stack>
    </>
  )
}

export default BusinessSynopsis
