import dayjs from "dayjs"

export const calculateDaysInRange = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    console.error("Invalid date range: fromDate or toDate is null or undefined")
    return 0
  }

  const from = dayjs(fromDate, "YYYYMMDD")
  const to = dayjs(toDate, "YYYYMMDD")

  return to.diff(from, "day") + 1
}

export const getDefaultOption = (days) => {
  if (days > 0 && days <= 31) return "Day"
  if (days >= 32 && days < 180) return "Week"
  if (days >= 180) return "Month"
  return "Day"
}

export const rangeOptions = [
  { min: 0, max: 7, options: ["Day"] },
  { min: 8, max: 31, options: ["Day", "Week"] },
  { min: 32, max: 363, options: ["Week", "Month"] },
  { min: 364, max: Infinity, options: ["Month", "Quarter", "Year"] },
]
