import { useState } from "react"
import { useTheme } from "../../contexts/theme"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import Table from "../../components/datagrid/Table"
import DatePicker from "../../components/DatePicker"
import Filter from "./Filter"
import InfoToolTip from "../../components/InfoToolTip"
import { setChartData } from "../../redux/slices/customerSlice"
import ArrowRise from "../../assets/svgs/ArrowRise"
import InfoLogoSvg from "../../assets/svgs/InfoLogoSvg"
import {
  DraftsData,
  DraftsParameters,
  TargetedCustomersData,
  TargetedCustomersParameters,
  PastCampaignData,
  PastCampaignParameters,
} from "../../constants/chartsData"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import MarketingKPICard from "./MarketingKPICard"

import { CustomerBarChartParameters } from "./CustomerBarChartParameters"
import { ReviewClassificationData } from "./ReviewClassficationData"
import CompareBy from "../../components/CompareBy"
import BlurEffect from "../../components/BlurEffect"
import { PieChartParameters } from "../../components/charts/pie/constant/dataFallback"
import PieChartPresentation from "../../components/charts/pie/PieChartPresentation"
import BarChartContainer from "../../components/charts/bar/BarChartContainer"

const Marketing = () => {
  const navigate = useNavigate()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const colorScheme = [
    "rgba(238, 0, 12, 1)",
    "rgba(252, 191, 73, 1)",
    "rgba(61, 80, 245, 1)",
    "rgba(80, 205, 137, 1)",
    "rgba(161, 123, 200, 1)",
    "rgba(222, 76, 8, 1)",
    "rgba(241, 144, 4, 1)",
    "rgba(252, 185, 50, 1)",
    "rgba(0, 123, 255, 1)",
    "rgba(40, 167, 69, 1)",
    "rgba(255, 193, 7, 1)",
    "rgba(23, 162, 184, 1)",
    "rgba(108, 117, 125, 1)",
    "rgba(233, 236, 239, 1)",
    "rgba(52, 58, 64, 1)",
    "rgba(255, 99, 132, 1)",
  ]

  const orderDetails = [
    {
      title: "No. of Campaigns",
      status: "53",
      numberrise: "Overall",
      icon: null,
    },
    {
      title: "Total Reach",
      status: "10,000",
      numberrise: "Customers",
      icon: "ArrowRise",
    },
    {
      title: "Effectiveness",
      status: "20%",
      numberrise: "Increase in sales",
      icon: "ArrowRise",
    },
    {
      title: "Conversion Matrix",
      status: "10%",
      numberrise: "1k  Customers",
      icon: "ArrowFall",
    },
    {
      title: "Sales Generated",
      status: "$10,000",
      numberrise: "in sales revenue",
      icon: "ArrowRise",
    },
  ]

  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab)
  }
  const handleCreateCampgain = () => {
    navigate("/submenu/marketing/createcampaign")
  }

  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  const palette = theme.palette

  const tabStyles = {
    textTransform: "none",
    color: "inherit",
    "&:hover": {
      color: palette.accent,
    },
    "&.Mui-selected": {
      color: palette.accent,
    },
  }

  const tableProps = (activeTab) => {
    return [
      {
        title: "Targeted Customers",
        buttonName: "+ Create Campagin",
        showButton: handleCreateCampgain,
        showExportButton: false,
        StaticData: TargetedCustomersData,
        StaticParameters: TargetedCustomersParameters,
        onRowClick: () => {
          navigate("/submenu/marketing/campaigncustomerdetails/1")
        },
        checkboxSelection: true,
      },
      {
        title: "Past Campaigns",
        showExportButton: false,
        // onRowClick: handleCreateCampgain,
        StaticData: PastCampaignData,
        StaticParameters: PastCampaignParameters,
        onRowClick: () => {
          navigate("/submenu/marketing/campaign/1")
        },
      },
      {
        title: "Drafts",
        onRowClick: handleCreateCampgain,
        showExportButton: false,
        StaticData: DraftsData,
        StaticParameters: DraftsParameters,
      },
    ][activeTab]
  }
  const dateRangeData = useSelector((state) => state.calendar)
  return (
    <>
      <BlurEffect showBlur={true} />
      <RouteTitle title="Marketing Portal">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <CompareBy dateRangeData={dateRangeData} />
          <DatePicker />
        </Box>
      </RouteTitle>
      <Stack padding="15px 30px 15px 30px" gap="20px">
        <Grid container spacing={2} justifyContent="space-between">
          {orderDetails.map((orderDetail) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={1.71428571429}
              key={orderDetail.title}
            >
              <CustomPaper watermarkBg={true} customStyles={{ height: "100%" }}>
                <MarketingKPICard
                  title={orderDetail.title}
                  status={orderDetail.status}
                  numberrise={orderDetail.numberrise}
                  comparisonPeriod={comparisonPeriod}
                  icon={<ArrowRise />}
                />
              </CustomPaper>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3.42857142858}>
            <CustomPaper
              customStyles={{
                height: "100%",
                background:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(94deg, #FF7935 -30%, #0E256D 62.46%)"
                    : "var(--Grade-blue, linear-gradient(76deg, #D7E0FF 1.45%, #FFF 95.77%))",
              }}
              watermarkBg={true}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent={{ xs: "center", lg: "space-evenly" }}
                alignItems="center"
                height="100%"
              >
                <Typography
                  variant="h5"
                  textAlign={{ xs: "center", lg: "left" }}
                >
                  Boost Your Business
                </Typography>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    mx: 2,
                    borderLeftWidth: 1,
                    borderColor: theme.palette.divider,
                    display: {
                      xs: "none",
                      md: "none",
                      lg: "block",
                    },
                  }}
                />

                <Stack gap={1}>
                  <Typography variant="body2">Create Campaign</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleCreateCampgain}
                    sx={{
                      color: "white",
                      background: theme.palette.accent,
                      textTransform: "none",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 100, 0, 1)"
                            : "rgba(29, 71, 226, 1)",
                      },
                    }}
                  >
                    Create
                  </Button>
                </Stack>
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
              <BarChartContainer
                route="sales"
                showLegend={true}
                setChartData={setChartData}
                comparisonPeriod={comparisonPeriod}
                title={"Comparison of Expenses and Revenue"}
                description={"Spend Trend"}
                postUrl="sales/data"
                reportUid={"static-barchart"}
                parameters={CustomerBarChartParameters}
                dateRangeData={dateRangeData}
              />
            </CustomPaper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              sx={{ height: "100%" }}
              gap={{ xs: 2, md: 2, lg: 0 }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                sx={{ marginBottom: "-20px" }}
              >
                <CustomPaper customStyles={{ height: "100%" }}>
                  <Box display="flex" alignItems="center">
                    <Typography color="inherit">
                      Return on Investment (ROI)
                    </Typography>
                    <InfoToolTip title="Return on Investment (ROI)">
                      <span style={{ height: "16px", cursor: "pointer" }}>
                        <InfoLogoSvg svgColor={svgColor} />
                      </span>
                    </InfoToolTip>
                  </Box>
                  <Stack gap={2} height="100%" justifyContent="space-evenly">
                    <Stack gap={1}>
                      <Typography
                        variant="body1"
                        color="inherit"
                        sx={{ opacity: "70%" }}
                      >
                        Total Revenue:
                      </Typography>
                      <Typography
                        className="hoverEffect"
                        variant="h4"
                        color="inherit"
                      >
                        $15,000
                      </Typography>
                      <Typography
                        className="hoverEffect"
                        variant="body3"
                        color="inherit"
                        sx={{ opacity: "80%" }}
                      >
                        +11.01% <ArrowRise />
                      </Typography>
                    </Stack>
                    <Stack gap={1}>
                      <Typography
                        variant="body1"
                        color="inherit"
                        sx={{ opacity: "70%" }}
                      >
                        Campaign Budget:
                      </Typography>
                      <Typography
                        className="hoverEffect"
                        variant="h4"
                        color="inherit"
                      >
                        $5,000
                      </Typography>
                      <Typography
                        className="hoverEffect"
                        variant="body3"
                        color="inherit"
                        sx={{ opacity: "80%" }}
                      >
                        +11.01% <ArrowRise />
                      </Typography>
                    </Stack>
                  </Stack>
                </CustomPaper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                sx={{ marginBottom: "-20px" }}
              >
                <CustomPaper
                  customStyles={{ height: "100%", overflow: "visible" }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography color="inherit">
                      Review Classification
                    </Typography>
                    <InfoToolTip title="Review Classification">
                      <span style={{ height: "16px", cursor: "pointer" }}>
                        <InfoLogoSvg svgColor={svgColor} />
                      </span>
                    </InfoToolTip>
                  </Box>
                  {/* <Box
                    display="flex"
                    flexDirection="column"
                    padding="10px"
                    rowGap={{ md: "10px", lg: "5px" }}
                  >
                    {PieChartData?.map((chartInput, index) => (
                      <CustomLegend
                        id={chartInput?.id}
                        value={chartInput?.value}
                        color={colorScheme[index]}
                        chartConfig={PieChartParameters}
                      />
                    ))}
                  </Box> */}
                  <Box sx={{ mt: 6 }}>
                    <PieChartPresentation
                      chartConfig={PieChartParameters}
                      chartInput={ReviewClassificationData}
                      colorScheme={colorScheme}
                    />
                  </Box>
                </CustomPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="sub-menu"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: palette.accent,
            },
          }}
        >
          <Tab label="Targeted Customers" sx={tabStyles} />
          <Tab label="Post Campaigns" sx={tabStyles} />
          <Tab label="Drafts" sx={tabStyles} />
        </Tabs>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={4} lg={3}>
            <CustomPaper customStyles={{ paddingX: "unset" }}>
              <Filter />
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Table {...tableProps(activeTab)} />
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default Marketing
