import { ResponsiveLine } from "@nivo/line"
import Box from "@mui/material/Box"
import { useTheme } from "../../../contexts/theme"
import CustomLegend from "../../CustomLegend"
import {
  customLayers,
  customTooltip,
  preprocessData,
} from "./services/configService"

const LineChartPresentation = ({
  chartInput,
  parameters,
  legendsData,
  reportTitle,
  reportData,
  showAiDropdown,
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const {
    margin,
    xScale,
    yScale,
    curve,
    axisTop,
    axisRight,
    axisBottom,
    axisLeft,
    enablePoints,
    enableGridX,
    enableGridY,
    gridYValues,
    enableArea,
    colors,
    pointSize,
    pointBorderColor,
    pointBorderWidth,
    useMesh,
  } = parameters

  const customTheme = {
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineColor: "transparent",
        },
      },
    },
  }

  const processedData = preprocessData(chartInput)

  return (
    <Box
      height="350px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
    >
      <ResponsiveLine
        data={processedData}
        tooltip={customTooltip}
        theme={customTheme}
        layers={customLayers}
        margin={margin}
        xScale={xScale}
        yScale={yScale}
        curve={curve}
        axisTop={axisTop}
        axisRight={axisRight}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        enablePoints={enablePoints}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        gridYValues={gridYValues}
        enableArea={enableArea}
        colors={colors}
        pointSize={pointSize}
        pointBorderColor={pointBorderColor}
        pointBorderWidth={pointBorderWidth}
        useMesh={useMesh}
      />
      <CustomLegend
        legendsData={legendsData}
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default LineChartPresentation
