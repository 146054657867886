import { LineChartData } from "../constant/configFallback"

export const fetchData = async (
  setLoading,
  setApiResponse,
  setError,
  dispatch,
  reportUid,
  setChartData
) => {
  try {
    // const response = await postData(postUrl, {
    //   entityDb: localStorage.getItem("entityDb"),
    //    fromDate: combinedState.dateRangeData.fromDate,
    // toDate: combinedState.dateRangeData.toDate,
    //   reportInstanceId: reportUid,
    //   comparedBy: {
    // previousFromDate: comparisonPeriod?.previousFromDate,
    // previousToDate: comparisonPeriod?.previousToDate,
    //   },
    //   viewBy: viewBy || "Day",
    // })
    const response = false
    setApiResponse(response)
    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: LineChartData,
        })
      )
    } else {
      dispatch(
        setChartData({
          reportUid,
          response,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
    dispatch(
      setChartData({
        reportUid,
        response: LineChartData,
      })
    )
  } finally {
    setLoading(false)
  }
}
