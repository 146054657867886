import { DashLineChartData } from "../constant/dataFallBack"
import { transformData } from "./transformServices"

export const fetchData = async (
  postData,
  postUrl,
  combinedState,
  reportUid,
  viewBy,
  setApiResponse,
  setError,
  dispatch,
  setChartData,
  formatDate,
  tooltip_data,
  setLoading,
  cacheKey
) => {
  try {
    const response = await postData(postUrl, {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: combinedState.dateRangeData.fromDate,
      toDate: combinedState.dateRangeData.toDate,
      reportInstanceId: reportUid,
      viewBy: viewBy || "Day",
    })
    setApiResponse(response)

    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: DashLineChartData,
          cacheKey: cacheKey,
        })
      )
    } else {
      dispatch(
        setChartData({
          reportUid,
          response: transformData(response, viewBy, formatDate, tooltip_data),
          apiResponse: response,
          cacheKey: cacheKey,
          viewBy,
        })
      )
    }
  } catch (error) {
    console.log(error)
    setError("Inadequate data points to generate a report")
    dispatch(
      setChartData({
        reportUid,
        response: DashLineChartData,
      })
    )
  } finally {
    setLoading(false)
  }
}
