import { useState, useEffect, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { fetchData } from "./services/dataService.js"
import { generateCacheKey } from "../../../utils/utils.js"
import BodySection from "./components/widget/BodySection.jsx"
import FooterSection from "./components/widget/FooterSection.jsx"
import HeaderSection from "./components/widget/HeaderSection.jsx"
import { Stack } from "@mui/material"
import {
  chartContainerDimentionsFallback,
  PDFFieldsFallbackConfig,
  tooltipDataFallbackConfig,
} from "./constant/fallbackConfigs.js"
import {
  transformDataComparedBy,
  transformDataPDFExport,
  transformDataSheetExport,
  transfromChartData,
} from "./services/transformServices.js"
import Message from "../../Message.jsx"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils.js"
import { useOrdinalColorScale } from "@nivo/colors"
const FunnelChartContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
}) => {
  const dispatch = useDispatch()
  const chartRef = useRef(null)

  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  let transformedConfigs = extractKeyValuePairs(configurations)

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.viewBy
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const configOverrides = [
    {
      key: "chartContainerDimensions",
      fallback: chartContainerDimentionsFallback,
    },
    { key: "tooltip_data", fallback: tooltipDataFallbackConfig },
    { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
    {
      key: "showTooltipPrevious",
      fallback:
        transformedConfigs.showTooltipPrevious === undefined
          ? true
          : transformedConfigs.showTooltipPrevious,
    },
  ]
  configOverrides.forEach(({ key, fallback }) => {
    transformedConfigs[key] = transformedConfigs[key] || fallback
  })

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const visibleViewByOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  const defaultViewByOption = getDefaultOption(daysInRange)

  const viewBy = visibleViewByOptions.includes(reportState.viewBy)
    ? reportState.viewBy
    : defaultViewByOption

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) return
    const payload = {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: dateRangeData.fromDate,
      toDate: dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: comparisonPeriod.previousFromDate,
        previousToDate: comparisonPeriod.previousToDate,
      },
    }
    if (!!transformedConfigs.view_by_period) {
      payload.viewBy = viewBy
    }
    setLoading(true)
    fetchData(postUrl, payload)
      .then((apiResponse) => {
        dispatch(
          setChartData({
            reportUid,
            response: apiResponse,
            apiResponse,
            transformedChartData:
              comparisonPeriod === true
                ? transformDataComparedBy(
                    apiResponse,
                    transformedConfigs.tooltip_data
                  )
                : transfromChartData(
                    apiResponse,
                    transformedConfigs.tooltip_data
                  ),
            viewBy,
            cacheKey,
          })
        )
        setLoading(false)
      })
      .catch((err) => {
        console.warn(err)
        setError("Failed to fetch data")
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.cacheKey,
    reportState.viewBy,
  ])

  const getColor = useOrdinalColorScale({ scheme: "set3" }, "id")

  const legendsData = useMemo(() => {
    return (
      reportState.transformedChartData?.map((item) => ({
        label: item.id,
        color: getColor(item),
      })) || []
    )
  }, [reportState.transformedChartData, getColor])

  return (
    <Stack spacing={1} ref={chartRef}>
      <HeaderSection
        route={route}
        reportUid={reportUid}
        title={title}
        description={description}
        setChartData={setChartData}
        children={null}
        config={transformedConfigs}
        reportStateViewBy={reportState.viewBy}
        dateRangeData={dateRangeData}
        parentLoading={loading}
        parentError={error}
        transformedChartData={reportState.transformedChartData}
        apiResponse={reportState.apiResponse}
        chartRef={chartRef}
        pdfFields={transformedConfigs.pdfFields}
        pdfTransformFunction={transformDataPDFExport}
        transformDataSheetExport={transformDataSheetExport}
        visibleViewByOptions={visibleViewByOptions}
        defaultViewByOption={defaultViewByOption}
      />

      {loading ? (
        <Message type="loading" />
      ) : error ? (
        <Message message={error} type="error" />
      ) : (
        <>
          <BodySection
            config={transformedConfigs}
            data={reportState.transformedChartData}
          />
          <FooterSection
            legendsData={legendsData}
            title={title}
            apiResponse={reportState.apiResponse}
          />
        </>
      )}
    </Stack>
  )
}

export default FunnelChartContainer
