import { ResponsiveBar } from "@nivo/bar"
import { useTheme } from "../../../../../contexts/theme"
import CustomTooltip from "../../../../CustomTooltip"
import Box from "@mui/material/Box"
import { useMemo } from "react"
import { colorScheme } from "../../constants/fallbackConfigs"

const BodySection = ({ data = [], reportUid, config }) => {
  const {
    showTooltipPrevious,
    chartContainerDimensions = {
      height: "350px",
    },
  } = config

  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  let axisLeft = config.defaultGroupBarLegendLabel.axisLeft
  let axisBottom = config.defaultGroupBarLegendLabel.axisBottom

  const keys = useMemo(() => {
    const allKeys = new Set()
    data?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (key !== "day" && key !== "tooltipFields") {
          allKeys.add(key)
        }
      })
    })

    return Array.from(allKeys)
  }, [data])

  const tooltip =
    reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
      ? ({ id, data }) => {
          const tooltipData = data?.tooltipFields?.[id] || {}
          return (
            <CustomTooltip
              data={tooltipData}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }
      : ({ id, data }) => {
          const index = { a: 0, b: 1, c: 2, d: 3, e: 4 }[id]
          const tooltipFields = data?.tooltipFields?.[index]
          if (!tooltipFields) return null
          const {
            day,
            currentDate,
            previousDate,
            reportType,
            tooltipData = {},
          } = tooltipFields

          const customTooltipData = {
            day,
            currentDate,
            previousDate,
            reportType,
            tooltipData: {
              current: tooltipData.current,
              ...(tooltipData.previous && { previous: tooltipData.previous }),
            },
          }

          return (
            <CustomTooltip
              data={customTooltipData}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="day"
        theme={customTheme}
        margin={{ top: 30, right: 40, bottom: 40, left: 80 }}
        padding={0.3}
        innerPadding={2}
        enableLabel={false}
        borderRadius={2}
        groupMode="grouped"
        colors={colorScheme}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: axisBottom,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeft,
          legendPosition: "middle",
          legendOffset: -50,
        }}
        tooltip={tooltip}
      />
    </Box>
  )
}

export default BodySection
