export const staticExtraDetails = {
  "Jenna Nguyen": {
    email: "jenna.nguyen@livelytics.ai",
    phoneNumber: "555-123-4567",
    streetAddress: "123 Elm Street",
    aptSuitNo: "Apt 4B",
    city: "Springfield",
    state: "IL",
    zip: "62704",
    totalNoOfItemsBought: "35",
    allTimeSpend: "$3,546.43",
    allTimeAverageSpend: "$101.32",
    allTimeVisits: "45",
    address: "123 Elm Street, Apt 4B, Springfield, IL, 62704",
    amountSpent: "$3,546.43",
    mostBoughtItem: "Sukiyaki",
    comment:
      "The service was impeccable. The staff was attentive, friendly, and made us feel welcome.",
    totalVisits: "45",
    totalSales: "$4,567.89",
    averageSales: "$101.32",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "16th August 2024",
  },
  "Erika Sanchez": {
    email: "erika.sanchez@livelytics.ai",
    phoneNumber: "555-234-5678",
    streetAddress: "456 Oak Avenue",
    aptSuitNo: "Suite 301",
    city: "Lincoln",
    state: "NE",
    zip: "68508",
    totalNoOfItemsBought: "20",
    allTimeSpend: "$1,234.56",
    allTimeAverageSpend: "$61.73",
    allTimeVisits: "25",
    address: "456 Oak Avenue, Suite 301, Lincoln, NE, 68508",
    amountSpent: "$1,234.56",
    mostBoughtItem: "Tempura",
    comment: "Great atmosphere and delicious food. Will definitely return!",
    totalVisits: "25",
    totalSales: "$1,678.90",
    averageSales: "$67.16",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "28th July 2024",
  },
  "Devon Scott": {
    email: "devon.scott@livelytics.ai",
    phoneNumber: "555-345-6789",
    streetAddress: "789 Pine Road",
    aptSuitNo: "",
    city: "Madison",
    state: "WI",
    zip: "53703",
    totalNoOfItemsBought: "15",
    allTimeSpend: "$654.32",
    allTimeAverageSpend: "$43.62",
    allTimeVisits: "18",
    address: "789 Pine Road, Madison, WI, 53703",
    amountSpent: "$654.32",
    mostBoughtItem: "Ramen",
    comment:
      "Food is consistently good and staff is friendly. A solid choice for a meal.",
    totalVisits: "18",
    totalSales: "$872.45",
    averageSales: "$48.47",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "25th August 2024",
  },
  "Tiffany Bryan": {
    email: "tiffany.bryan@livelytics.ai",
    phoneNumber: "555-456-7890",
    streetAddress: "321 Maple Street",
    aptSuitNo: "Apt 2A",
    city: "Columbus",
    state: "OH",
    zip: "43215",
    totalNoOfItemsBought: "10",
    allTimeSpend: "$789.10",
    allTimeAverageSpend: "$78.91",
    allTimeVisits: "12",
    address: "321 Maple Street, Apt 2A, Columbus, OH, 43215",
    amountSpent: "$789.10",
    mostBoughtItem: "Sushi",
    comment: "Fresh and tasty sushi every time. Highly recommended!",
    totalVisits: "12",
    totalSales: "$945.25",
    averageSales: "$78.77",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "16th August 2024",
  },
  "Michael Patrick": {
    email: "michael.patrick@livelytics.ai",
    phoneNumber: "555-567-8901",
    streetAddress: "654 Birch Lane",
    aptSuitNo: "Unit 5",
    city: "Denver",
    state: "CO",
    zip: "80203",
    totalNoOfItemsBought: "22",
    allTimeSpend: "$2,345.67",
    allTimeAverageSpend: "$106.63",
    allTimeVisits: "30",
    address: "654 Birch Lane, Unit 5, Denver, CO, 80203",
    amountSpent: "$2,345.67",
    mostBoughtItem: "Yakitori",
    comment:
      "A must-visit place for amazing Japanese cuisine. Friendly staff and great service.",
    totalVisits: "30",
    totalSales: "$3,210.89",
    averageSales: "$107.03",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "12th July 2024",
  },
}

// Define color arrays as constants
export const Array1 = [
  "#95C75B", // Light Green
  "#BBC954", // Yellow Green
  "#D2CB50", // Yellow
  "#D2B250", // Light Gold
  "#D2A250", // Gold
  "#D28F50", // Amber
  "#D27C50", // Orange
  "#D26750", // Light Orange
  "#D25067", // Coral
  "#D2508B", // Light Pink
  "#D250A1", // Pink
]

export const Array2 = [
  "#3D50F5",
  "#A17BC8",
  "#007BFF",
  "#17A2B8",
  "#E900EF",
  "#343AFF",
  "#8067E3",
  "#A96FFF",
  "#7282FF",
  "#D084E4",
]
