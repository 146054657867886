import { postData } from "../../../../api/api"
import { BarChartData } from "../constant/dataFallback"
import { transformDataComparedBy, transfromChartData } from "./transformService"

export const fetchData = async (
  setLoading,
  setChartData,
  postUrl,
  reportUid,
  combinedState,
  setApiResponse,
  setError,
  tooltip_data,
  dispatch,
  comparisonPeriod,
  extractedParameters,
  viewBy,
  cacheKey
) => {
  try {
    let response

    if (reportUid === "static-barchart") {
      response = false
    } else {
      extractedParameters.view_by_period
        ? (response = await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
            comparedBy: {
              previousFromDate: combinedState.comparisonPeriod.previousFromDate,
              previousToDate: combinedState.comparisonPeriod.previousToDate,
            },
            viewBy: viewBy || "Day",
          }))
        : (response = await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
            comparedBy: {
              previousFromDate: combinedState.comparisonPeriod.previousFromDate,
              previousToDate: combinedState.comparisonPeriod.previousToDate,
            },
            // viewBy: viewBy || "Day",
          }))
    }

    setApiResponse(response)

    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response:
            comparisonPeriod?.selectedValue === "Compared By"
              ? transformDataComparedBy(BarChartData, tooltip_data)
              : transfromChartData(BarChartData, tooltip_data),
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    } else {
      dispatch(
        setChartData({
          reportUid,
          response:
            comparisonPeriod?.selectedValue === "Compared By"
              ? transformDataComparedBy(response, tooltip_data)
              : transfromChartData(response, tooltip_data),
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
    dispatch(
      setChartData({
        reportUid,
        response: BarChartData,
        cacheKey: cacheKey,
      })
    )
  } finally {
    setLoading(false)
  }
}
