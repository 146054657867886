import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Logo from "../../../assets/svgs/Logo"
import { useTheme } from "../../../contexts/theme"

const ComingSoon = () => {
  const { theme } = useTheme()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          gap: 1,
        }}
      >
        <Typography variant="h4">Coming soon..</Typography>
        <Logo
          fill={theme.palette.text.primary}
          yfill={
            theme.palette.mode === "light" ? theme.palette.accent : "white"
          }
        />
      </Box>
    </Box>
  )
}

export default ComingSoon
