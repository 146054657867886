import { postData } from "../../../../api/api"

export const fetchData = async (
  postUrl,
  combinedState,
  reportUid,
  setApiResponse,
  setError,
  setChartData,
  dispatch,
  setLoading,
  cacheKey
) => {
  try {
    setLoading(true)

    const response = await postData(postUrl, {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: combinedState.dateRangeData.fromDate,
      toDate: combinedState.dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: combinedState.comparisonPeriod.previousFromDate,
        previousToDate: combinedState.comparisonPeriod.previousToDate,
      },
    })

    setApiResponse(response)

    if (!response || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
    } else {
      dispatch(
        setChartData({
          reportUid,
          response: response[0],
          cacheKey: cacheKey,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
  } finally {
    setLoading(false)
  }
}
