import { configureStore } from "@reduxjs/toolkit"
import overviewReducer from "./slices/overviewSlice"
import calendarReducer from "./slices/calendarSlice"
import navigationReducer from "./slices/navigationSlice"
import customerReducer from "./slices/customerSlice"
import inventoryReducer from "./slices/inventorySlice"
import salesReducer from "./slices/salesSlice"
import vendorReducer from "./slices/vendorSlice"
import employeeReducer from "./slices/employeeSlice"
import filtersReducer from "./slices/filtersSlice"
import periodComparisonSlice from "./slices/periodComparisonSlice"
import businessSynopsisSlice from "./slices/businessSynopsisSlice"
import sidebarSlice from "./slices/sidebarSlice"

export default configureStore({
  reducer: {
    overview: overviewReducer,
    customer: customerReducer,
    sales: salesReducer,
    navigation: navigationReducer,
    calendar: calendarReducer,
    inventory: inventoryReducer,
    vendors: vendorReducer,
    employee: employeeReducer,
    filters: filtersReducer,
    period: periodComparisonSlice,
    businessSynopsis: businessSynopsisSlice,
    sidebar: sidebarSlice,
  },
})
