import React, { useState, useEffect } from "react"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import { useTheme } from "../../../contexts/theme"
import { useNavigate } from "react-router-dom"
import MUIProgressTooltip from "./component/MUIProgressTooltip"

const formatNumber = (value) => {
  return new Intl.NumberFormat().format(value)
}

const ProgressBarPresentation = ({
  item,
  defaultValue,
  maxValue,
  beforePointerColor,
  extractedParameters = {},
  tooltipData,
  enableNavigation,
  navigationPath,
  showBubble,
  showTooltipPrevious = true,
}) => {
  const { theme } = useTheme()
  const { margin } = extractedParameters

  const [sliderValue, setSliderValue] = useState(defaultValue)

  useEffect(() => {
    setSliderValue(defaultValue)
  }, [defaultValue])

  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  const handleTouchStart = (event) => {
    event.preventDefault()
  }
  const navigate = useNavigate()

  const bubbleStyles = showBubble
    ? {}
    : {
        border: "none",
        height: "8px",
        width: "8px",
        boxShadow: "none",
      }

  return (
    <>
      <Typography
        variant="body2"
        sx={{ opacity: 0.7, cursor: enableNavigation ? "pointer" : "default" }}
        color={theme.palette.mode === "dark" ? "#fff" : "#333333"}
        onClick={() => {
          if (enableNavigation) {
            navigate(navigationPath, {
              state: { item },
            })
          }
        }}
      >
        {item?.title}
      </Typography>

      <Slider
        value={sliderValue}
        max={maxValue}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        aria-labelledby="progress-bar-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => formatNumber(value)}
        sx={{
          marginTop: "-5px",
          marginBottom: "5px",
          margin: { margin },
          height: "8px",
          "& .MuiSlider-track": {
            backgroundColor: beforePointerColor,
            border: "none",
          },
          "& .MuiSlider-thumb": {
            border: "4px solid #ffffffd1",
            backgroundColor: beforePointerColor,
            "&:hover": {
              boxShadow: `0px 0px 0px 6px ${beforePointerColor}30`,
            },
            ...bubbleStyles,
          },
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#333333",
          },
        }}
        components={{
          ValueLabel: (props) => (
            <MUIProgressTooltip
              {...props}
              item={item}
              showTooltipPrevious={showTooltipPrevious}
              tooltipData={tooltipData}
            />
          ),
        }}
      />
    </>
  )
}

export default ProgressBarPresentation
