import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import StarIcon from "@mui/icons-material/Star"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import DeleteModal from "../../components/DeleteModal"
import CustomPaper from "../../components/CustomPaper"
import OrderDetailsDrawer from "./Components/OrderDetailsDrawer"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import { useSelector } from "react-redux"
import Table from "../../components/datagrid/Table"
// import BarChart from "../../components/charts/bar/BarChart"
// import { BarChartData } from "../../constants/chartsData"
import { setChartData } from "../../redux/slices/vendorSlice"

const VendorDetails = () => {
  const navigate = useNavigate()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const dateRangeData = useSelector((state) => state.calendar)

  const handleRowClick = (id) => {
    handleDrawerOpen()
  }

  const handleDelete = (event, id) => {
    event.stopPropagation()
    setOpen(true)
  }

  const renderRatingColumn = (rating) => {
    return (
      <Box display="flex" alignItems="center" gap="5px">
        {rating}
        <StarIcon
          sx={{
            color: "rgba(242, 205, 74, 1)",
          }}
        />
      </Box>
    )
  }

  const vendorDetails = {
    personal: {
      companyName: {
        label: "Company Name",
        value: "Umbrella Corporation",
      },
      vendorType: {
        label: "Vendor Type",
        value: "Bio-tech Corp",
      },
      poc: {
        label: "Point of Contact",
        value: "Alice Abernathy",
      },
      rating: {
        label: "Rating",
        value: renderRatingColumn("5.0"),
      },
      phone: {
        label: "Phone Number",
        value: "+1 (555) 123-4567",
      },
      email: {
        label: "Email Address",
        value: "alice@umbrellacorp.com",
      },
      streetAddress: {
        label: "Street Address",
        value: "123 Raccoon City Road",
      },
      aptAddress: {
        label: "Apartment or Suite Number",
        value: "Apt 101",
      },
      city: {
        label: "City",
        value: "Raccoon City",
      },
      state: {
        label: "State",
        value: "Nevada",
      },
      zip: {
        label: "ZIP Code",
        value: "12345",
      },
    },
    contract: {
      contractTerm: {
        label: "Contract Term",
        value: "Fixed term Contract",
      },
      contractStartDate: {
        label: "Contract Start Date",
        value: "02 Oct 2021",
      },
      contractEndDate: {
        label: "Contract End Date",
        value: "02 Nov 2024",
      },
      contractPeriod: {
        label: "Contract Period",
        value: "3 Years",
      },
      paymentTerm: {
        label: "Payment Term",
        value: "Payment by Invoice",
      },
      paymentMethod: {
        label: "Payment Method",
        value: "Online Transaction",
      },
      totalPurchasedAmount: {
        label: "Total Purchased Amount",
        value: "$15,35,332.53",
      },
    },
    orderDetails: {
      orderNumber: {
        label: "Order Number",
        value: "#42346723",
      },
      orderStatus: {
        label: "Delivered",
        value: "Delivered",
      },
      orderDate: {
        label: "Order Date",
        value: "10th Oct 2023",
      },
      expectedDeliveryDate: {
        label: "Expected Delivery Date",
        value: "10th Oct 2023",
      },
      customerDetails: {
        name: {
          label: "Name",
          value: "Jenny Wilson",
        },
        contact: {
          label: "Contact",
          value: "(219) 555-0114",
        },
        address: {
          label: "Address",
          value: "2118 Thornridge Cir. Syracuse, Connecticut 35624",
        },
      },
      orderItems: [
        {
          itemName: {
            label: "Onions",
          },
          itemPrice: {
            label: "Price",
            value: "$20.00",
          },
          itemQuantity: {
            label: "Quantity",
            value: "10kg",
          },
        },
        {
          itemName: {
            label: "Tomato",
          },
          itemPrice: {
            label: "Price",
            value: "$24.00",
          },
          itemQuantity: {
            label: "Quantity",
            value: "5kg",
          },
        },
      ],
      totalAmount: {
        label: "Total Amount",
        value: "$54.00",
      },
      paymentStatus: {
        label: "Payment Status",
        value: "Paid",
      },
    },
    expenses: {},
  }

  const renderDetails = (details) =>
    Object.entries(details).map(([key, value]) => (
      <Stack
        key={key}
        rowGap="5px"
        minWidth={{
          sx: "200px",
          sm: "200px",
          md: "200px",
          lg: "280px",
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          sx={{
            opacity: "70%",
            fontSize: "0.85rem",
          }}
        >
          {value.label}
        </Typography>
        <Typography
          variant="body1"
          color={
            value.label === "Total Purchased Amount" ? "#50CD89" : "inherit"
          }
          sx={{ fontSize: "1.1rem" }}
        >
          {value.value}
        </Typography>
      </Stack>
    ))

  const [open, setOpen] = useState(false)

  const deleteRecord = (id = 0) => {
    setOpen(false)
  }

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={() => deleteRecord()}
      />

      <OrderDetailsDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        renderDetails={renderDetails}
        orderDetails={vendorDetails.orderDetails}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="30px 30px 15px 30px"
      >
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Vendors",
              // isActive: false,
              clickHandler: () => {
                navigate("/vendors/allvendors")
              },
            },
            {
              text: "Vendors Details",
              isActive: true,
            },
          ]}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="15px 30px 15px 30px"
      >
        <Typography variant="h6" color="inherit">
          Vendor Details
        </Typography>
        <Box>
          <IconButton
            size="small"
            // onClick={(event) =>
            //   onEdit && onEdit(event, row.id)
            // }
          >
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>

      <Stack padding="15px 30px 15px 30px" rowGap="20px">
        <CustomPaper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            borderBottom="2px solid #091743"
            mb="15px"
          >
            <Typography mb="10px" color="inherit">
              Personal Information
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="45px">
            {renderDetails(vendorDetails.personal)}
          </Box>
        </CustomPaper>
        <CustomPaper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            borderBottom="2px solid #091743"
            mb="15px"
          >
            <Typography mb="10px" color="inherit">
              Contract Information
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="45px">
            {renderDetails(vendorDetails.contract)}
          </Box>
        </CustomPaper>

        <Table
          title="Orders"
          onRowClick={handleRowClick}
          dateRangeData={dateRangeData}
          reportUid="ABBA18A5-EFB8-4068-B07B-1172FB37F539"
          postUrl="overview/list"
        />

        <CustomPaper customStyles={{ mt: "1rem" }}>
          {/* <BarChart
            showLegend={false}
            comparisonPeriod={comparisonPeriod}
            setChartData={setChartData}
            title="Expenses"
          /> */}
        </CustomPaper>
      </Stack>
    </>
  )
}

export default VendorDetails
