export const tooltipDataFallbackConfig = [
  {
    name: "Walk In",
    format: "Number",
    color: "",
  },
  {
    name: "Other",
    format: "Number",
    color: "",
  },
  {
    name: "Online-CaryOut",
    format: "Number",
    color: "",
  },
]
export const tooltipDataFallbackConfigCustomerPage = (reportUid) => [
  ["16B1F146-AE15-4622-8D58-FEEF9B8AEEF0"].includes(reportUid)
    ? null
    : {
        name: "value",
        format: "Number",
        color: "",
      },
]

export const chartContainerDimentionsFallbackConfig = {
  height: "250px",
  minHeight: "250px",
  maxHeight: "unset",
}

export const colorScheme = [
  "#2271B4",
  "#6E8B3D",
  "#C75D8A",
  "#DB7093",
  "#FF8C00",
  "#9370DB",
  "#FFE4B5",
  "#BA55D3",
  "#B0E0E6",
  "#8B4513",
  "#00CED1",
  "#CD5C5C",
  "#3CB371",
  "#A7CEFA",
  "#BDB76B",
  "#D2691E",
  "#00FA9A",
  "#F4A460",
  "#FF69B4",
  "#8A2BE2",
  "#2E8B57",
]

export const PDFFieldsFallbackConfig = [
  {
    name: "Walk In",
    format: "Number",
    color: "",
  },
  {
    name: "Other",
    format: "Number",
    color: "",
  },
  {
    name: "Online-CaryOut",
    format: "Number",
    color: "",
  },
]
