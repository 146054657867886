// CustomTooltipComponent.js
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import CustomTooltip from "../../../CustomTooltip"

const CustomTooltips = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "transparent",
    border: "none",
    padding: 0,
    fontSize: "inherit",
    boxShadow: "none",
    width: "auto",
    maxWidth: "none",
    overflowX: "visible",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "transparent",
    backgroundColor: "transparent",
    border: "none",
    margin: "0 auto",
    transform: "translateY(-50%)",
  },
})

const CustomSliderTooltip = ({ children, open, item, showTooltipPrevious }) => {
  const hasTooltipData = item && Object.keys(item).length > 0
  return hasTooltipData ? (
    <CustomTooltips
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <CustomTooltip
          data={item}
          showCurrentPrevDate
          showTooltipPrevious={showTooltipPrevious}
        />
      }
      arrow
    >
      {children}
    </CustomTooltips>
  ) : (
    children
  )
}

export default CustomSliderTooltip
