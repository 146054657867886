import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
  title: null,
  data: null,
}

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      const { open, title, data } = action.payload

      if (open !== undefined) state.open = open
      if (title !== undefined) state.title = title
      if (data !== undefined) state.data = data
    },
  },
})

export const { setSidebar } = sidebarSlice.actions

export default sidebarSlice.reducer
