import * as yup from "yup"

export const registrationSchema = yup.object({
  mobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Invalid mobile number")
    .required("Mobile Number is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
})

export const signUpDetailSchema = yup.object({
  firstName: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("First Name is required"),
  lastName: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("Last Name is required"),
  mobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Invalid mobile number")
    .required("Mobile Number is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/,
      "Invalid email"
    )
    .required("Email is Required"),

  company: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("company is required"),
  companyadd: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed")
    .required("company Address is required"),
  role: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("Role is required"),
  industry: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("industry is required"),
})

const existingUsers = [
  { email: "operations@livelytics.ai", password: "Livelytics001@" },
]

export const loginSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/,
      "Invalid email"
    )
    .required("Email is Required"),

  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
})

export const ovviloginSchema = yup.object({
  email: yup
    .string()
    // .email("Invalid email address")
    // .matches(
    //   /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/,
    //   "Invalid email"
    // )
    .required("Email is Required"),

  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
})

export const OntheFlyloginSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/,
      "Invalid email"
    )
    .required("Email is Required"),

  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
})

export const forgotpasswordSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/,
      "Invalid email"
    )
    .required("Email is Required"),
})

export const verificationSchema = yup.object({
  mobileValidation: yup
    .string()
    .required("Verification code is required")
    .length(6, "Verification code must be 6 characters long")
    .matches(/^\d+$/, "Verification code must contain only digits"),

  emailValidation: yup
    .string()
    .required("Verification code is required")
    .length(6, "Verification code must be 6 characters long")
    .matches(/^\d+$/, "Verification code must contain only digits"),

  subscription: yup
    .string()
    .max(40, "Must be 40 characters or less")
    .matches(/^[a-zA-Z ]*$/, "No special characters allowed")
    .required("subscription is required"),
})

export const newPasswordSchema = yup.object({
  tempPassword: yup.string().required("Temperory Password is required"),
  newPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
})
