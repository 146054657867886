import { useEffect } from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useTheme } from "../../contexts/theme"
import dayjs from "dayjs"
import { useDispatch } from "react-redux"

const ViewBy = ({
  reportUid,
  config,
  viewByValue = "Day",
  setChartData,
  dateRangeData,
  placeholder = "View By",
}) => {
  const dispatch = useDispatch()

  const { theme } = useTheme()
  const calculateDaysInRange = (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      console.error(
        "Invalid date range: fromDate or toDate is null or undefined"
      )
      return 0
    }

    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    return to.diff(from, "day") + 1
  }

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const getDefaultOption = (days) => {
    if (days > 0 && days <= 31) return "Day"
    if (days >= 32 && days < 180) return "Week"
    if (days >= 180) return "Month"
    return "Day"
  }

  // useEffect(() => {
  //   dispatch(
  //     setChartData({
  //       reportUid,
  //       viewBy: getDefaultOption(daysInRange),
  //     })
  //   )
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [daysInRange])

  const rangeOptions = [
    { min: 0, max: 7, options: ["Day"] },
    { min: 8, max: 31, options: ["Day", "Week"] },
    { min: 32, max: 364, options: ["Week", "Month"] },
    { min: 365, max: Infinity, options: ["Month", "Quarter", "Year"] },
  ]

  const applicableOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  // const visibleOptions = config.filter((param) =>
  //   applicableOptions.includes(param)
  // )

  const handleChange = (event, applicableOptions) => {
    const newValue = event.target.value

    dispatch(
      setChartData({
        reportUid,
        viewBy: applicableOptions.includes(newValue)
          ? newValue
          : getDefaultOption(daysInRange),
      })
    )
  }

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: "6.2rem",
      }}
    >
      <Select
        value={viewByValue}
        onChange={(event) => handleChange(event, applicableOptions)}
        disableUnderline={true}
        defaultValue="Day"
        renderValue={(selected) => {
          if (!selected) {
            return placeholder
          }
          return selected
        }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#071339" : "#e7ecff",
          borderRadius: "10px",
          fontSize: "0.8rem",
          "& .MuiSelect-iconOpen, & .MuiSelect-icon": {
            color: theme.palette.accent,
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
      >
        <MenuItem disabled value={null} sx={{ fontSize: "0.8rem" }}>
          View By
        </MenuItem>
        {applicableOptions.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ fontSize: "0.8rem" }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ViewBy
