export const customTooltip = ({ point }) => {
  if (point) {
    const formattedDate = new Date(point.data.xFormatted).toLocaleDateString()

    return (
      <div
        style={{
          background: "black",
          borderRadius: "4px",
          padding: "12px",
          color: "white",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            marginBottom: "6px",
            color:
              point.serieId === "Forecasted"
                ? "rgba(80, 205, 137, 1)"
                : "rgba(0, 158, 247, 1)",
          }}
        >
          {point.serieId}
        </div>
        <div>
          <strong>Date : </strong> {formattedDate}
        </div>
        <div>
          <strong>Value : </strong> {point.data.yFormatted}
        </div>
      </div>
    )
  }
  return null
}

export const preprocessData = (data) => {
  const forecastedData = data.find((d) => d.id === "Forecasted")
  const actualData = data.find((d) => d.id === "Actual")

  const adjustedActualData = actualData.data.filter((d) => {
    const month = new Date(d.x).getMonth() + 1
    return month <= 9
  })

  return [
    {
      ...forecastedData,
      area: true,
    },
    {
      ...actualData,
      data: adjustedActualData,
      area: false,
    },
  ]
}

export const customLayers = [
  "grid",
  "markers",
  "axes",
  "lines",
  "crosshair",
  "slices",
  "points",
  "legends",
  "mesh",
  "annotations",
  ({ series, innerHeight, xScale, yScale }) => {
    const forecastedSerie = series.find((serie) => serie.id === "Forecasted")
    const actualSerie = series.find((serie) => serie.id === "Actual")

    if (forecastedSerie && actualSerie) {
      const forecastedData = forecastedSerie.data
      const actualData = actualSerie.data

      // Get the end of actual data range
      const actualEndX = actualData[actualData.length - 1].data.x

      // Create path for the filled area in forecasted data up to actual range
      const path = `
          M${xScale(forecastedData[0].data.x)},${yScale(
        forecastedData[0].data.y
      )}
          ${forecastedData
            .filter((d) => new Date(d.data.x) <= new Date(actualEndX))
            .map((d) => `L${xScale(d.data.x)},${yScale(d.data.y)}`)
            .join(" ")}
          L${xScale(actualEndX)},${innerHeight}
          L${xScale(forecastedData[0].data.x)},${innerHeight}
          Z
        `

      return (
        <path
          key="forecasted"
          d={path}
          fill="rgba(80, 205, 137, 0.2)"
          stroke="none"
        />
      )
    }

    return null
  },
]
