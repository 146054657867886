import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// types: loading, info, error
const placeholders = {
  loading: "Fetching data, please wait... ",
  info: "",
  error: "Something went wrong while fetching the data...",
}

const Message = ({ message, type = "info", color = "inherit" }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    alignContent="center"
    gap="10px"
    minHeight="350px"
    height="100%"
    width="100%"
  >
    {type === "loading" && (
      <CircularProgress disableShrink size={45} color="info" />
    )}
    <Typography variant="body2" color={color}>
      {message || placeholders[type]}
    </Typography>
  </Box>
)

export default Message
