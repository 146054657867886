export function convertValues(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      convertValues(obj[key])
    } else if (typeof obj[key] === "string") {
      if (!isNaN(obj[key])) {
        obj[key] = Number(obj[key])
      } else if (obj[key].toLowerCase() === "true") {
        obj[key] = true
      } else if (obj[key].toLowerCase() === "false") {
        obj[key] = false
      }
    }
  }
  return obj
}

export const dynamicLegendRanges = (min, max, steps = 4, colors) => {
  const stepSize = Math.ceil((max - min) / steps)
  const ranges = []
  let rangeStart = Math.floor(min)
  let rangeEnd

  for (let i = 0; i < steps; i++) {
    rangeEnd = Math.floor(rangeStart + stepSize)
    ranges.push({
      label: `${rangeStart}-${rangeEnd}`,
      color: colors[i] || colors[colors.length - 1],
    })
    rangeStart = rangeEnd
  }

  ranges.push({
    label: `${rangeStart}+`,
    color: colors[steps] || colors[colors.length - 1],
  })

  return ranges
}

// Function to find top and low performers
export const findTopAndLowPerformer = (data, fromDate, toDate) => {
  if (!data || data.length === 0)
    return { StarPerformer: null, lowPerformer: null }

  const from = new Date(fromDate)
  const to = new Date(toDate)

  // Filter data within the date range
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.day)
    return itemDate >= from && itemDate <= to
  })

  if (filteredData.length === 0)
    return { StarPerformer: null, lowPerformer: null }

  // Find highest and lowest values
  const highestValue = Math.max(...filteredData.map((d) => d.value))
  const nonZeroData = filteredData.filter((d) => d.value !== 0)
  const lowestValue =
    nonZeroData.length > 0
      ? Math.min(...nonZeroData.map((d) => d.value))
      : Infinity

  // Find corresponding items
  const StarPerformer = filteredData.find((item) => item.value === highestValue)
  const lowPerformer =
    nonZeroData.find((item) => item.value === lowestValue) ||
    filteredData.find((item) => item.value === lowestValue)

  return { StarPerformer, lowPerformer }
}
