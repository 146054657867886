export const PieTooltipData = [
  {
    name: "Walk In",
    format: "Number",
    color: "",
  },
  {
    name: "Other",
    format: "Number",
    color: "",
  },
  {
    name: "Online-CaryOut",
    format: "Number",
    color: "",
  },
]
