export const calculateAggregate = (data, targetField, operation) => {
  if (!Array.isArray(data) || data.length === 0) return null

  // Filter and map the data to ensure the targetField contains numbers
  const validData = data.filter((item) => typeof item[targetField] === "number")
  const ActualData = data[0]?.data?.map((item) => item[targetField])
  const ForecastedData = data[1]?.data?.map((item) => item[targetField])

  // Extract just the values for calculations
  const values = validData.map((item) => item[targetField])

  switch (operation) {
    case "SUM":
      return {
        result: values.reduce((acc, curr) => acc + curr, 0),
        relatedObjects: null, // No specific object for SUM, just an empty array
      }

    case "MAX":
      const maxValue = Math.max(...values)
      const maxObject = validData.find((item) => item[targetField] === maxValue)
      return {
        result: maxValue,
        relatedObjects: maxObject, // Return the full object related to max value
      }

    case "MIN":
      const minValue = Math.min(...values)
      const minObject = validData.find((item) => item[targetField] === minValue)
      return {
        result: minValue,
        relatedObjects: minObject, // Return the full object related to min value
      }

    case "AVG":
      return {
        result: values.reduce((acc, curr) => acc + curr, 0) / values.length,
        relatedObjects: null, // No specific object for AVG
      }

    case "MINMAX":
      const maxVal = Math.max(...values)
      const minVal = Math.min(...values)

      const percentageDifference = ((maxVal - minVal) / maxVal) * 100

      const maxObjectForMaxMin = validData.find(
        (item) => item[targetField] === maxVal
      )

      const minObjectForMaxMin = validData.find(
        (item) => item[targetField] === minVal
      )

      return {
        result: percentageDifference,
        maxVal,
        minVal,
        relatedObjects: [maxObjectForMaxMin, minObjectForMaxMin], // Return both objects
      }
    case "Actual":
      return {
        result: ActualData.reduce((acc, curr) => acc + curr, 0),
        relatedObjects: null, // No specific object for SUM, just an empty array
      }
    case "Forecasted":
      return {
        result: ForecastedData.reduce((acc, curr) => acc + curr, 0),
        relatedObjects: null, // No specific object for SUM, just an empty array
      }

    case "%Change": {
      const actualSum = ActualData.reduce((acc, curr) => acc + curr, 0)
      const forecastedSum = ForecastedData.reduce((acc, curr) => acc + curr, 0)
      // Helper function to calculate the percentage difference correctly
      const calculatePercentage = (current, previous) => {
        if (previous === 0) return current === 0 ? 0 : 100
        return ((current - previous) / previous) * 100
      }
      const percentageDifference = calculatePercentage(actualSum, forecastedSum)
      return {
        result: percentageDifference.toFixed(2),
        relatedObjects: null,
      }
    }

    default:
      return {
        result: null,
        message: "Unsupported operation!",
      }
  }
}
