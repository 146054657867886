import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import NivoScatterPlot from "./ScatterPlotPresentation"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useState } from "react"
import TopCust from "../../../assets/svgs/TopCust"
import YaxisUpArrow from "../../../assets/svgs/YaxisUpArrow"
import CustLinear from "../../../assets/svgs/CustLinear"
import XaxisCustomer from "../../../assets/svgs/XaxisCustomer"
import InfoToolTip from "../../InfoToolTip"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import ScatterPlotPresentation from "./ScatterPlotPresentation"
import {
  ScatterPlotChartData,
  ScatterPlotChartParameters,
} from "./constants/dataFallBack"
import { fetchData } from "./services/dataServices"
import { generateCacheKey } from "../../../utils/utils"

const ScatterPlotContainer = ({
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  route,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      postUrl,
      combinedState,
      reportUid,
      setApiResponse,
      setError,
      setChartData,
      dispatch,
      setLoading,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="ScatterPlot"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="350px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Box display="flex" alignItems="center" gap={"10px"}>
                <TopCust />
                <Typography color="inherit">Avg Number of Visits</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="center"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                flexWrap={"wrap"}
                gap={"10px"}
                height={"300px"}
              >
                <Typography component="div" variant="body1">
                  High CLTV
                </Typography>
                <Box
                  sx={{
                    marginTop: "30px",
                    marginBottom: "50px",
                  }}
                >
                  <YaxisUpArrow />
                </Box>
                <Typography component="div" variant="body1">
                  Low CLTV
                </Typography>
              </Box>

              <Box width={"450px"}>
                <NivoScatterPlot
                  ScatterPlotChartParameters={ScatterPlotChartParameters}
                  ScatterPlotChartData={ScatterPlotChartData}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                flexWrap={"wrap"}
                gap={"10px"}
                height={"300px"}
              >
                <Typography component="div" variant="body1">
                  Max
                </Typography>
                <Box style={{ marginTop: "10px" }}>
                  <CustLinear />
                </Box>
                <Typography component="div" variant="body1">
                  Min
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems="center"
              justifyContent="center"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Typography component="div" variant="body1">
                Tenure
              </Typography>
              <XaxisCustomer />
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Box display="flex" alignItems="center" gap={"10px"}>
                <TopCust />
                <Typography color="inherit">Avg Number of Visits</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="center"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                flexWrap={"wrap"}
                gap={"10px"}
                height={"300px"}
              >
                <Typography component="div" variant="body1">
                  High CLTV
                </Typography>
                <Box
                  sx={{
                    marginTop: "30px",
                    marginBottom: "50px",
                  }}
                >
                  <YaxisUpArrow />
                </Box>
                <Typography component="div" variant="body1">
                  Low CLTV
                </Typography>
              </Box>

              <Box width={"450px"}>
                <ScatterPlotPresentation
                  ScatterPlotChartParameters={response}
                  ScatterPlotChartData={parameters}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                flexWrap={"wrap"}
                gap={"10px"}
                height={"300px"}
              >
                <Typography component="div" variant="body1">
                  Max
                </Typography>
                <Box style={{ marginTop: "10px" }}>
                  <CustLinear />
                </Box>
                <Typography component="div" variant="body1">
                  Min
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems="center"
              justifyContent="center"
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Typography component="div" variant="body1">
                Tenure
              </Typography>
              <XaxisCustomer />
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default ScatterPlotContainer
