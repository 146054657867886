const FileIcon = ({ strokeColor }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="-2 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 2.78247V9.66671C18.3337 10.6001 18.3337 11.0668 18.5153 11.4234C18.6751 11.737 18.9301 11.9919 19.2437 12.1517C19.6002 12.3334 20.0669 12.3334 21.0003 12.3334H27.8846M21.667 20.6666H8.33366M21.667 27.3333H8.33366M11.667 13.9999H8.33366M18.3337 2.33325H9.66699C6.86673 2.33325 5.4666 2.33325 4.39704 2.87822C3.45623 3.35759 2.69133 4.12249 2.21196 5.0633C1.66699 6.13286 1.66699 7.53299 1.66699 10.3333V27.6666C1.66699 30.4668 1.66699 31.867 2.21196 32.9365C2.69133 33.8773 3.45623 34.6423 4.39704 35.1216C5.4666 35.6666 6.86673 35.6666 9.66699 35.6666H20.3337C23.1339 35.6666 24.5341 35.6666 25.6036 35.1216C26.5444 34.6423 27.3093 33.8773 27.7887 32.9365C28.3337 31.867 28.3337 30.4668 28.3337 27.6666V12.3333L18.3337 2.33325Z"
        stroke={strokeColor}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FileIcon
