import { postData } from "../../../../api/api"

export const fetchData = async (postUrl, payload) => {
  return await postData(postUrl, payload)
}

export const getMaxValue = (data) => {
  if (!Array.isArray(data)) {
    console.error("Input must be an array.")
    return 5000
  }

  let maxValue = 0

  for (const item of data) {
    if (item) {
      const barValue = parseFloat(item.barValue)
      const lineValue = parseFloat(item.lineValue)

      if (Number.isFinite(barValue)) {
        maxValue = Math.max(maxValue, barValue)
      }
      if (Number.isFinite(lineValue)) {
        maxValue = Math.max(maxValue, lineValue)
      }
    }
  }

  return maxValue
}
