export const HeatmapChartData = [
  {
    id: "Sunday",
    data: [
      {
        x: "9AM",
        y: 25,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 446.66,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.5,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 382.81,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.15,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 48,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 949.7,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.97,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1168.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 71,
        previousY: 71,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1963.86,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.66,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2040.91,
              format: "Currency",
            },
            Customers: {
              value: 57,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.75,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 91,
        previousY: 66,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2617.65,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.77,
              format: "Currency",
            },
            "Return Amount": {
              value: 2,
              format: "Currency",
            },
            "Discount Amount": {
              value: 27.45,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1637.54,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 62,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1773.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1570.15,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.67,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1386.95,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2274.08,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 44.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 156.48,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 44,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.48,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.12,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1416.17,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 36.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 37,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 710.38,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1026.23,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 63,
        previousY: 53,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1216.48,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 25,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1288.87,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 13.53,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 46,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.28,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 19,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1404.09,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.97,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 59,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1278.77,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 39.35,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1119.02,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 61,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1457.24,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.89,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.16,
              format: "Currency",
            },
            "Discount Amount": {
              value: 31.94,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1021.3,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.84,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 7,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 121.29,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Monday",
    data: [
      {
        x: "9AM",
        y: 5,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 101.02,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 18,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 413.76,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 376.06,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 28,
        previousY: 26,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.71,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.35,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 973.4,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.44,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 656.77,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.77,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 484.7,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 28,
        previousY: 29,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 752.51,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 674.67,
              format: "Currency",
            },
            Customers: {
              value: 20,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.77,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 24,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1176.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.34,
              format: "Currency",
            },
            "Discount Amount": {
              value: 18.11,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 701.74,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 28,
        previousY: 32,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1130.77,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 520.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1043.14,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 389.77,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.73,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 37,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1079.03,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.7,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1186.84,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.08,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.68,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.11,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1222.7,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 61,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1853.49,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.37,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 847.77,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.2,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 55,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1125.64,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.87,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1219.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 9,
        previousY: 4,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 366.37,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 84.18,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Tuesday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 9,
        previousY: 7,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 157.81,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 85.25,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 12.18,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 946.71,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.84,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 619.41,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.6,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.41,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 890.4,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 32,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 970.13,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.63,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1069.31,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.73,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.08,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 980.4,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.54,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.26,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 388.62,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.8,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 27,
        previousY: 25,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 736.13,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.13,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 672.07,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 26,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 617.17,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1099.29,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 982.33,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 827.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 32.58,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 62,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1778.5,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.69,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1304.23,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.64,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 63,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1695.61,
              format: "Currency",
            },
            Customers: {
              value: 51,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.91,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 11.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 41,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 875.63,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.33,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1058.21,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 5,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 232.68,
              format: "Currency",
            },
            Customers: {
              value: 5,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.54,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 7.47,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 7.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Wednesday",
    data: [
      {
        x: "9AM",
        y: 2,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 12.52,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 6.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 15.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 17,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 274.77,
              format: "Currency",
            },
            Customers: {
              value: 14,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 443.19,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 763.56,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1733.73,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 45.62,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 166.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2335.66,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 64.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 222.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 913.12,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 47,
        previousY: 47,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 3941.84,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 83.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 480.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1037.14,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 33,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1049.7,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 16.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 561.52,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.02,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.92,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 31,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.38,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1071.58,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 41,
        previousY: 36,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2096.79,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 51.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 225.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 3175.25,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 88.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 392.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 48,
        previousY: 58,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1178.81,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.33,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1502.4,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.06,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 67,
        previousY: 79,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1473.83,
              format: "Currency",
            },
            Customers: {
              value: 53,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1891.12,
              format: "Currency",
            },
            Customers: {
              value: 56,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 54,
        previousY: 63,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1387.99,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 10.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1612.32,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 56,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1123.73,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 18,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.73,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1234.01,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 92.24,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 8.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.91,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 30.18,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Thursday",
    data: [
      {
        x: "9AM",
        y: 1,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 43.98,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 43.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 35,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 725.21,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.96,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 527.27,
              format: "Currency",
            },
            Customers: {
              value: 17,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.15,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 44,
        previousY: 35,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1011.15,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 787.88,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.51,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.55,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 41,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1455.48,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1243.98,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.09,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.42,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 42,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1766.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 42.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2063.6,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 138.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 49,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1377.13,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.38,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1328.67,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 39,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1544.7,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.79,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2611.77,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 63.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 305.65,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 51,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1708.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 946.2,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 70,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1428.37,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 44,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1346.7,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.49,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 81,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2109.23,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 50.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.26,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 88,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1947.88,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.13,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.99,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1672.93,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.43,
              format: "Currency",
            },
            "Discount Amount": {
              value: 55.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 105,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2095.95,
              format: "Currency",
            },
            Customers: {
              value: 75,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 879.22,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.42,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 3,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 29.48,
              format: "Currency",
            },
            Customers: {
              value: 3,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 9.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 20.29,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 10.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.65,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Friday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 33,
        previousY: 22,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.97,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.12,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 310.38,
              format: "Currency",
            },
            Customers: {
              value: 21,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 14.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 35,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 896.96,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.31,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1376.01,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 35.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 46,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 921.77,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1421.68,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 18.9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 48,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1417.32,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.03,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1163.21,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 43,
        previousY: 56,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1032.4,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.34,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1853.27,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 29.49,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 37,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1449.25,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0.63,
              format: "Currency",
            },
            "Discount Amount": {
              value: 76.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 54,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1472.99,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1709.78,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 38.38,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 58,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1246.04,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.39,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1802.9,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1600.25,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.23,
              format: "Currency",
            },
            "Return Amount": {
              value: 24.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.18,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1285.98,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 55,
        previousY: 68,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1725.81,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1064.07,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.5,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 63,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1839.42,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 52.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1249.3,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 6,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 118.06,
              format: "Currency",
            },
            Customers: {
              value: 6,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.68,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 4.74,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 4.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Saturday",
    data: [
      {
        x: "9AM",
        y: 22,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.8,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.21,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.86,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 36,
        previousY: 27,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.98,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 970.1,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.93,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 56,
        previousY: 54,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1574.19,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1818.14,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.03,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1919.24,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1260.28,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 5.95,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.16,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 39,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1084.25,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2383.16,
              format: "Currency",
            },
            Customers: {
              value: 55,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.57,
              format: "Currency",
            },
            "Return Amount": {
              value: 4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 162.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 59,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1594.25,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1851.9,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.06,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 35,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1027.46,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1625.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 55,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1740.51,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.98,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.55,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 28.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 57,
        previousY: 42,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1624.56,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 580.64,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 13.82,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 68,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1786.36,
              format: "Currency",
            },
            Customers: {
              value: 49,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 15,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1443.66,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.46,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 68,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1831.97,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1498.97,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1105.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1050.71,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.43,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 411.13,
              format: "Currency",
            },
            Customers: {
              value: 10,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]

export const HeatmapChartParameters = [
  {
    parameterType: "LABELS",
    reportParameters: [
      {
        parameterName: "meta_info",
        parameterValue: '{"format":"Currency"}',
      },
    ],
  },
  {
    parameterType: "GRID & AXES",
    reportParameters: [
      {
        parameterName: "axisRight",
        parameterValue:
          '{tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Day of Week", legendPosition: "middle", legendOffset: 80, truncateTickAt: 0}',
      },
      {
        parameterName: "axisTop",
        parameterValue:
          '{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Time of Day", legendOffset: -40, legendPosition: "middle", truncateTickAt: 10}',
      },
      {
        parameterName: "axisLeft",
        parameterValue:
          '{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Day of Week", legendPosition: "middle", legendOffset: -80, truncateTickAt: 0 }',
      },
    ],
  },
  {
    parameterType: "Style",
    reportParameters: [
      {
        parameterName: "theme",
        parameterValue: "customTheme",
      },
      {
        parameterName: "colors",
        parameterValue:
          '{ type: "diverging", scheme: "blues", divergeAt: 0.75 }',
      },
      {
        parameterName: "emptyColor",
        parameterValue: "#555555",
      },
    ],
  },
  {
    parameterType: "LEGENDS",
    reportParameters: [
      {
        parameterName: "legends",
        parameterValue:
          '[ { anchor: "bottom", translateX: 0, translateY: 30, length: 400, thickness: 8, direction: "row", tickPosition: "after", tickSize: 3, tickSpacing: 4, tickOverlap: false, tickFormat: ">-.2s", title: "Average Sale($)", titleAlign: "start", titleOffset: 4, }, ]',
      },
    ],
  },
  {
    parameterType: "Base",
    reportParameters: [
      {
        parameterName: "margin",
        parameterValue: "{ top: 60, right: 90, bottom: 60, left: 90 }",
      },
    ],
  },
  {
    parameterType: "CUSTOMIZATION",
    reportParameters: [
      {
        parameterName: "tooltip_fields",
        parameterValue:
          "'Gross Sales','Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
      },
      {
        parameterName: "performerFlag",
        parameterValue: '{"icon": "false","legend":"true"}',
      },
    ],
  },
]
