import Slider from "@mui/material/Slider"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useTheme } from "../../../../contexts/theme"
import { useNavigate } from "react-router-dom"
import CustomSliderTooltip from "./CustomSliderTooltip"

const LabelledSlider = ({
  item,
  defaultValue,
  maxValue,
  beforePointerColor,
  tooltipData,
  enableNavigation,
  navigationPath,
  showBubble,
  showTooltipPrevious = true,
}) => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  const handleTouchStart = (event) => {
    event.preventDefault()
  }

  const bubbleStyles = showBubble
    ? {}
    : {
        border: "none",
        height: "8px",
        width: "8px",
        boxShadow: "none",
      }

  const handleTitleClick = () => {
    if (enableNavigation) {
      navigate(navigationPath, {
        state: { item },
      })
    }
  }

  return (
    <Stack display="flex" flexDirection="column">
      <Typography
        variant="body2"
        sx={{ opacity: 0.7, cursor: enableNavigation ? "pointer" : "default" }}
        color={theme.palette.mode === "dark" ? "#fff" : "#333333"}
        onClick={handleTitleClick}
      >
        {item?.title}
      </Typography>

      <Slider
        value={defaultValue}
        max={maxValue}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        aria-labelledby="progress-bar-slider"
        valueLabelDisplay="auto"
        sx={{
          height: "8px",
          padding: "8px 0px",
          "& .MuiSlider-track": {
            backgroundColor: beforePointerColor,
            border: "none",
          },
          "& .MuiSlider-thumb": {
            border: "4px solid #ffffffd1",
            backgroundColor: beforePointerColor,
            "&:hover": {
              boxShadow: `0px 0px 0px 6px ${beforePointerColor}30`,
            },
            ...bubbleStyles,
          },
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#333333",
          },
        }}
        slots={{
          valueLabel: (props) => (
            <CustomSliderTooltip
              {...props}
              item={item}
              showTooltipPrevious={showTooltipPrevious}
              tooltipData={tooltipData}
            />
          ),
        }}
      />
    </Stack>
  )
}

export default LabelledSlider
