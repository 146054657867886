import { createSlice } from "@reduxjs/toolkit"

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
    customer_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      return action.payload
    },
    setChartData: (state, action) => {
      const {
        reportUid,
        response,
        apiResponse,
        aiResponse,
        transformedChartData,
        transformedExportData,
        comparisonPeriod,
        viewBy,
        cacheKey,
        transformedPDFExportData,
      } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )

      if (selectedReport) {
        if (response) selectedReport.reportData = response
        if (apiResponse) selectedReport.apiResponse = apiResponse
        if (aiResponse) selectedReport.aiResponse = aiResponse
        if (transformedChartData)
          selectedReport.transformedChartData = transformedChartData
        if (transformedExportData)
          selectedReport.transformedExportData = transformedExportData
        if (transformedPDFExportData)
          selectedReport.transformedPDFExportData = transformedPDFExportData
        if (cacheKey) selectedReport.cacheKey = cacheKey
        if (comparisonPeriod) {
          selectedReport.comparisonPeriod = comparisonPeriod
        }
        if (viewBy) {
          selectedReport.viewBy = viewBy
        }
      }
    },
  },
})

export const { loadData, setChartData } = customerSlice.actions

export default customerSlice.reducer
