import { useState } from "react"
import Stack from "@mui/material/Stack"
import LabelledSlider from "../LabelledSlider"
import TablePagination from "@mui/material/TablePagination"
import CustomPaginationActions from "../CustomPaginationActions"

const BodySection = ({ config, data = [] }) => {
  const {
    showTooltipPrevious = true,
    enableNavigation,
    showBubble,
    beforePointerColors,
    afterPointerColor,
    navigationPath,
    defaultRowsPerPage,
    showPagination = true,
  } = config

  const roundToNearest10 = (value) => Math.ceil(value / 10) * 10

  function getMaxValue(arr) {
    if (!Array.isArray(arr) || arr.length === 0) return null
    return Math.max(...arr.map((obj) => obj.defaultValue))
  }

  const roundedMaxValue = roundToNearest10(getMaxValue(data))

  // Pagination state
  const [page, setPage] = useState(0)
  const rowsPerPage = defaultRowsPerPage || 10

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  // Calculate the current data slice
  const visibleItems = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <Stack display="flex" flexDirection="column" gap={1}>
      {visibleItems.map((item, index) => (
        <LabelledSlider
          showTooltipPrevious={showTooltipPrevious}
          key={index}
          item={item}
          defaultValue={item.defaultValue}
          maxValue={roundedMaxValue}
          tooltipData={item.tooltipData}
          beforePointerColor={
            beforePointerColors[index % beforePointerColors.length]
          }
          afterPointerColor={afterPointerColor}
          enableNavigation={enableNavigation}
          navigationPath={navigationPath}
          showBubble={showBubble}
        />
      ))}
      {showPagination && data.length > rowsPerPage && (
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={() => {}}
          labelRowsPerPage=""
          rowsPerPageOptions={[defaultRowsPerPage]}
          sx={{ "& .MuiToolbar-root": { p: 0 } }}
          // To hide built in row text - eg. Page: 11-25
          labelDisplayedRows={() => null}
          ActionsComponent={CustomPaginationActions}
        />
      )}
    </Stack>
  )
}

export default BodySection
