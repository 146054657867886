import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import { BarTooltipData } from "../constant/configFallback"

const parseNumber = (value) => {
  if (typeof value === "number") return value
  if (typeof value === "string") {
    const cleanedValue = value.replace(/[^0-9.]/g, "")
    return cleanedValue ? parseFloat(cleanedValue) || 0 : value
  }
  return 0
}

export const transfromChartData = (input, tooltip_data) => {
  // TODO: Combine compared by case here itself
  if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  input.reportDataCurrent?.forEach((currentItem) => {
    const previousItem =
      input?.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

    let eachElement = {
      id: currentItem.id,
      ...currentItem,
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }

      eachElement.tooltipData.previous[tooltipDataItem.name] = {
        value: parseNumber(previousItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformDataComparedBy = (input, tooltip_data) => {
  if (!input || !Array.isArray(input)) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  input?.forEach((currentItem) => {
    let eachElement = {
      id: currentItem.id,
      ...currentItem,
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformExportData = (
  apiResponse,
  reportUid,
  extractedParameters = {}
) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || BarTooltipData
  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"] || current["EmployeeName"]),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name

      // Skip if the name is "Product"
      if (
        name === "Product Name" ||
        name === "EmployeeName" ||
        name === "Wastage Reason"
      )
        return

      let currentVal

      if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
        currentVal = current[name]
      } else {
        currentVal =
          current[name] && typeof current[name] === "string"
            ? Number(Number(current[name].replace(/[^0-9.-]+/g, "")).toFixed(2))
            : Number(Number(current[name] || 0).toFixed(2))
      }

      if (Object.keys(previous).length > 0) {
        let previousVal

        if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
          previousVal = previous[name]
        } else {
          previousVal =
            previous[name] && typeof previous[name] === "string"
              ? Number(previous[name].replace(/[^0-9.-]+/g, "")).toFixed(2)
              : Number(previous[name] || 0).toFixed(2)
        }

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0 ||
            !previousVal ||
            (currentVal - previousVal) / previousVal === Infinity ||
            (currentVal === 0 && previousVal === 0)
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}
