import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import dayjs from "dayjs"
import { getFormattedValue } from "../../../../utils/utils"

export const formatDateKey = (dateKey) => {
  const year = Math.floor(dateKey / 10000)
  const month = Math.floor((dateKey % 10000) / 100)
  const day = dateKey % 100

  const dateStr = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`

  const dateFormat = getDateFormatFromLocalStorage() || "MM-DD-YYYY"

  return dayjs(dateStr).format(dateFormat)
}
export function transfromChartData(data, tooltip_data, reportUid) {
  let reportDataCurrent = []
  let reportDataPrevious = []

  if (Array.isArray(data)) {
    reportDataCurrent = data
  } else if (data && typeof data === "object") {
    reportDataCurrent = data.reportDataCurrent || []
    reportDataPrevious = data.reportDataPrevious || []
  }

  const result = []

  // Group previous data by day, if provided
  const previousGrouped = reportDataPrevious.reduce((acc, item) => {
    const day = item.day // Group by day
    if (!acc[day]) {
      acc[day] = []
    }
    acc[day].push(item) // Add to the day's array
    return acc
  }, {})

  // Group current data and map previous data if available
  const groupedData = reportDataCurrent.reduce((acc, item) => {
    const day = item.day

    if (!acc[day]) {
      acc[day] = { day: day, tooltipFields: [] }
    }

    // Create tooltip field for current data
    const previousItems = previousGrouped[day] || []
    const currentIndexx = acc[day].tooltipFields.length
    const previousDateKey =
      previousItems.length > currentIndexx &&
      previousItems[currentIndexx]?.datekey
        ? previousItems[currentIndexx].datekey
        : "" // Keep empty if no matching previous datekey

    const tooltipField = {
      reportType: "GroupBar",
      currentDate: formatDateKey(item.datekey),
      day: item.day,
      previousDate: previousDateKey ? formatDateKey(previousDateKey) : null,
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    // Fill current tooltip data based on tooltip_data
    tooltip_data.forEach((field) => {
      const fieldName = field.name
      const value = item[fieldName] // Get the value from the current item
      const format = field.format

      // Update condition to check for `undefined` and `null` only (allow `0` as a valid value)
      if (value !== undefined && value !== null) {
        tooltipField.tooltipData.current[fieldName] = {
          value: value,
          format: format,
        }
      }
    })

    // Fill previous data if previous data is provided
    if (reportDataPrevious.length > 0) {
      const previousItems = previousGrouped[day] || []
      const currentIndex = acc[day].tooltipFields.length // Current index of tooltipFields

      if (currentIndex < previousItems.length) {
        const previousItem = previousItems[currentIndex]
        tooltip_data.forEach((field) => {
          const fieldName = field.name
          const previousValue = previousItem[fieldName] // Get the value from the previous item
          const previousFormat = field.format

          if (previousValue !== undefined && previousValue !== null) {
            tooltipField.tooltipData.previous[fieldName] = {
              value: previousValue,
              format: previousFormat,
            }
          }
        })
      }
    }

    acc[day].tooltipFields.push(tooltipField)

    // Remove previous key if it's empty
    if (Object.keys(tooltipField.tooltipData.previous).length === 0) {
      delete tooltipField.tooltipData.previous // Only keep current if previous is empty
    }

    // Extract Gross Sales values for a, b, c, d
    const indexKey =
      reportUid === "67187936-81CE-44B0-9416-5D52B308EE84"
        ? item["Customers"]
        : item["Gross Sales"] || null

    // Ensure there is a fixed limit of 4 gross sales
    const currentIndex = acc[day].tooltipFields.length - 1
    if (currentIndex < 4) {
      acc[day][String.fromCharCode(97 + currentIndex)] = indexKey // a, b, c, d
    } else if (currentIndex === 4) {
      acc[day]["e"] = indexKey // For the 5th entry, add it to 'e'
    }

    return acc
  }, {})

  // Convert grouped data into an array
  for (const day in groupedData) {
    if (groupedData.hasOwnProperty(day)) {
      result.push(groupedData[day])
    }
  }

  return result
}

export function transformDataProductName(data, tooltip_data, reportUid) {
  const result = []

  // Determine if the input is in object format or array format
  const reportDataCurrent = Array.isArray(data) ? data : data.reportDataCurrent
  const reportDataPrevious = Array.isArray(data)
    ? null
    : data.reportDataPrevious

  // Create a map for quick access to previous data by ProductName if reportDataPrevious exists
  const previousDataMap = {}
  if (reportDataPrevious) {
    reportDataPrevious.forEach((item) => {
      const key = item.ProductName
      if (!previousDataMap[item.day]) {
        previousDataMap[item.day] = {}
      }
      previousDataMap[item.day][key] = item
    })
  }

  // Helper function to extract fields based on tooltip_data
  const extractTooltipFields = (item) => {
    const tooltipFields = {}
    tooltip_data.forEach((field) => {
      const fieldName = field.name
      if (item[fieldName] !== undefined) {
        tooltipFields[fieldName] = {
          value: item[fieldName],
          format: field.format,
        }
      }
    })
    return tooltipFields
  }

  // Iterate through the current report data
  reportDataCurrent.forEach((currentItem) => {
    const day = currentItem.day
    const productName = currentItem.ProductName

    // Check if the day already exists in the result
    let dayEntry = result.find((entry) => entry.day === day)
    if (!dayEntry) {
      dayEntry = { day: day }
      result.push(dayEntry)
    }

    // Set the sales quantity for the current product
    dayEntry[productName] = currentItem.SalesQuantity

    // Prepare the tooltip data for the product
    if (!dayEntry.tooltipFields) {
      dayEntry.tooltipFields = {}
    }

    const currentTooltipFields = extractTooltipFields(currentItem)

    let tooltipData
    // Conditionally add previous data only if reportDataPrevious is provided
    if (reportDataPrevious) {
      const previousItem = previousDataMap[day]?.[productName] || {}
      tooltipData = {
        current: currentTooltipFields,
        previous: extractTooltipFields(previousItem),
      }
    } else {
      // Only include current data in array format if previous data doesn't exist
      tooltipData = {
        current: currentTooltipFields,
      }
    }

    dayEntry.tooltipFields[productName] = { tooltipData }
  })

  return result
}

export function transformDataPDFExport(apiResponse, extractedParameters = {}) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Walk In",
      format: "Number",
      color: "",
    },
    {
      name: "Other",
      format: "Number",
      color: "",
    },
    {
      name: "Online-CaryOut",
      format: "Number",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Gross Sales",
      format: "Number",
      color: "",
    },
    {
      name: "Discounts",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Number",
      color: "",
    },
  ]

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date: current["ProductName"] || formatDateKey(current["datekey"]),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name

      // Skip irrelevant keys
      if (
        name === "WeekdayNumber" ||
        name === "day" ||
        name === "ProductName"
      ) {
        return
      }

      // Only process if current data has the key
      if (current.hasOwnProperty(name)) {
        const currentVal = current[name] || 0

        if (Object.keys(previous).length > 0) {
          const previousVal = previous[name] || 0

          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
            previous:
              previousVal > 0
                ? tooltip.format === "Currency"
                  ? `$${previousVal.toLocaleString()}`
                  : previousVal.toString()
                : tooltip.format === "Currency"
                ? "$0"
                : "0",
            changePercent:
              previousVal === 0
                ? "-"
                : (((currentVal - previousVal) / previousVal) * 100).toFixed(
                    2
                  ) + "%",
          }
        } else {
          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
          }
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const transformDataSheetExport = (data) => {
  // IMP-NOTE: It takes the output of transformChartData and not the raw API Data
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      // Check if the value is an object and specifically handle "tooltipData"
      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          let format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )

          flatItem[`previous ${subKey}`] = getFormattedValue(
            value.previous[subKey]?.value,
            format
          )
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        // Handle primitive types directly
        flatItem[key] = value
      }
    })

    return flatItem
  })
}