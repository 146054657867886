import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg.jsx"
import { useTheme } from "../../../contexts/theme.jsx"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState, useRef } from "react"
import { HeatmapChartParameters } from "../../../constants/chartsData.js"
import InfoToolTip from "../../InfoToolTip.jsx"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs.js"
import ViewBy from "../../ViewBy.jsx"
import Analytica from "../../Analytica.jsx"
import HeatMapChartDetails from "./components/HeatMapChartDetails.jsx"
import ExportChartsData from "../../ExportChartsData.jsx"
import ExportMenuButton from "../../ExportMenuButton.jsx"
import HeatmapPresentation from "./HeatmapPresentation.jsx"
import { convertValues } from "./services/configServices.js"
import { fetchData } from "./services/dataServices.js"
import {
  chartContainerDimensionsFallBack,
  tooltip_dataFallBack,
} from "./constants/configFallBack.js"
import { HeatmapChartData } from "./constants/dataFallBack.js"
import { transformExportData } from "./services/transformServices.js"
import { generateCacheKey } from "../../../utils/utils.js"

const HeatmapContainer = ({
  route,
  setChartData,
  title,
  comparisonPeriod,
  description,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
  defaultColor = { type: "diverging", scheme: "blues", divergeAt: 0.75 },
  setAnchorEl,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions =
      chartContainerDimensionsFallBack)

  // Function to convert values to appropriate types
  const params = convertValues(extractedParameters)

  const handleClose = () => {
    setOpen(false)
  }

  const tooltip_data = extractedParameters.tooltip_data || tooltip_dataFallBack

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      postUrl,
      combinedState,
      reportUid,
      tooltip_data,
      setChartData,
      dispatch,
      setLoading,
      setApiResponse,
      setError,
      cacheKey
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })
  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const config = parameters.length === 1 ? HeatmapChartParameters : params

  const tableData = transformExportData(reduxApiResponse, extractedParameters)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={"20px"}
          >
            {/* <Button
              variant="text"
              sx={{
                fontSize: "0.8rem",
                fontWeight: 700,
                color: theme.palette.accent,
                cursor: "pointer",
                textTransform: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleClickOpen}
            >
              + View More
            </Button> */}
            {extractedParameters.view_by_period && (
              <ViewBy
                route={route}
                parameters={
                  extractedParameters && extractedParameters.view_by_period
                }
                viewBy={viewBy}
                setViewBy={setViewBy}
                reportUid={reportUid}
                dateRangeData={dateRangeData}
              />
            )}
          </Box>

          <HeatMapChartDetails
            open={open}
            handleClose={handleClose}
            dateRangeData={dateRangeData}
            comparisonPeriod={comparisonPeriod}
            error={error}
            loading={loading}
            staticParameters={config}
            chartInput={response}
          />
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              reportType="Heatmap"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}

          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <HeatmapPresentation
            chartConfig={config}
            chartInput={HeatmapChartData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <HeatmapPresentation
            chartConfig={config}
            chartInput={response}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default HeatmapContainer
