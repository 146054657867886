import { useOrdinalColorScale } from "@nivo/colors"

export const tooltipDataFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Profit Percent",
    format: "Percentage",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
]

export const useColorScale = () => {
  const getColor = useOrdinalColorScale({ scheme: "set3" }, "id")
  return getColor
}

export const chartContainerDimentionsFallback = {
  height: "600px",
  minHeight: "600px",
  maxHeight: "unset",
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Profit Percent",
    format: "Percentage",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
]
