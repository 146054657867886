import HeaderSection from "./components/widget/HeaderSection"
import { useSelector } from "react-redux"
import { useRef, useState } from "react"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { Stack } from "@mui/material"
import Message from "../../Message"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import { Bumpchartdata, BumpChartParameters } from "./constant/fallbackData"

const BumpChartContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
}) => {
  const chartRef = useRef(null)

  const dateRangeData = useSelector((state) => state.calendar)
  let transformedConfigs = extractKeyValuePairs(configurations) || {}

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  return (
    <>
      <Stack spacing={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          dateRangeData={dateRangeData}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs.pdfFields}
        />

        {loading ? (
          <Message type="loading" />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <>
            <BodySection
              config={BumpChartParameters}
              data={Bumpchartdata}
              TableLegendData={reportState.apiResponse}
            />
            <FooterSection
              // legendsData={Bumpchartdata}
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    </>
  )
}

export default BumpChartContainer
