import { useEffect, useRef, useState } from "react"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { useDispatch, useSelector } from "react-redux"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import SwarmPlotPresentation from "./SwarmPlotPresentation"
import {
  transformExportData,
  transfromChartData,
} from "./services/transformServices"
import { SwarmPlotData, SwarmPlotParameters } from "./constants/dataFallBack"
import { fetchData } from "./services/dataServices"
import { generateCacheKey } from "../../../utils/utils"

const SwarmPlotContainer = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  focus_mode = true,
  showAiDropdown = true,
  setAnchorEl,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const [forpdftabledata, setForpdftabledata] = useState([])
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "Test",
    Recommendations: "Test",
    Anomalies: "Test",
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    fetchData(
      postUrl,
      combinedState,
      reportUid,
      dispatch,
      transfromChartData,
      setApiResponse,
      setForpdftabledata,
      setChartData,
      setLoading,
      setError,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const chartInput = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport?.reportData
  })

  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const tableData = transformExportData(reduxApiResponse, extractedParameters)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box display="flex" gap={"2px"}>
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={extractedParameters.view_by_period}
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}

          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="350px"
            width="100%"
            gap="10px"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <SwarmPlotPresentation
            chartConfig={SwarmPlotParameters}
            chartInput={SwarmPlotData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
          />
        ) : (
          <SwarmPlotPresentation
            chartConfig={SwarmPlotParameters}
            chartInput={chartInput}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
          />
        )}
      </Box>
    </>
  )
}

export default SwarmPlotContainer
