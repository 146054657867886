export const chartContainerDimentionsFallBack = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const DashLineTooltip_data = [
  {
    name: "Sales",
    format: "Currency",
    color: "",
  },
]

