import { postData } from "../../../../api/api"
import { PieChartData } from "../constant/dataFallback"
import {
  transformDataComparedBy,
  transfromChartData,
} from "./transformServices"

export const fetchData = async (
  postUrl,
  reportUid,
  combinedState,
  setReportData,
  setApiResponse,
  setError,
  setChartData,
  tooltip_data,
  dispatch,
  comparisonPeriod,
  setLoading,
  cacheKey
) => {
  try {
    let response

    if (reportUid === "static-PieChart") {
      response = false
    } else {
      response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
        // viewBy: viewBy || "Day",
      })
    }

    setReportData(response)
    setApiResponse(response)
    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      setApiResponse(PieChartData)
      dispatch(
        setChartData({
          reportUid,
          response: PieChartData,
        })
      )
    } else {
      const result =
        comparisonPeriod?.selectedValue === "Compared By"
          ? transformDataComparedBy(response, tooltip_data)
          : transfromChartData(response, tooltip_data)
      dispatch(
        setChartData({
          reportUid,
          response: result,
          apiResponse: response,
          cacheKey: cacheKey,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
    setApiResponse(PieChartData)
    dispatch(
      setChartData({
        reportUid,
        response: PieChartData,
      })
    )
  } finally {
    setLoading(false)
  }
}
