import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import NivoLineBarChart from "./NivoLineBarChart"
import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { postData } from "../../../api/api"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import LineBarChartDetails from "./LineBarChartDetails"
import dayjs from "dayjs"
import {
  formatDate,
  getDateFormatFromLocalStorage,
} from "../../../utils/dateUtils"

import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import { generateCacheKey } from "../../../utils/utils"

const LineBarChart = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters,
  legendsData,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
  setAnchorEl,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [dateRange, setDateRange] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const svgColor = theme.palette.accentSecondary

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
    {
      name: "Return Amount",
      format: "Currency",
      color: "",
    },
    {
      name: "Discount Amount",
      format: "Currency",
      color: "",
    },
  ]

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    viewBy,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  const handleClose = () => {
    setOpen(false)
  }

  const fetchData = async () => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    try {
      setLoading(true)
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
        viewBy: viewBy || "Day",
      })

      setApiResponse(response)

      const dateFormat = getDateFormatFromLocalStorage()

      const data = []

      if (["Day", "", undefined].includes(viewBy)) {
        comparisonPeriod.selectedValue === "Compared By"
          ? response?.forEach((element) => {
              let eachElement = {
                day: element["Day"] || 0,
                barValue: element[extractedParameters.keys]?.toFixed(2) || 0,
                date: element["DayMonth"] || 0,
                tooltipData: {
                  // currentDate: element["DayMonth"],
                  currentDate: dayjs(element["DayMonth"]).format(dateFormat),
                  current: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
            })
          : response.reportDataPrevious?.forEach((element, index) => {
              let eachElement = {
                day: response.reportDataCurrent[index]?.["Day"] || 0,
                date: response.reportDataCurrent[index]?.["DayMonth"],
                barValue:
                  response.reportDataCurrent[index]?.[
                    extractedParameters.keys
                  ] || 0,
                lineValue: element[extractedParameters.keys] || 0,
                tooltipData: {
                  currentDate: dayjs(
                    response.reportDataCurrent[index]?.["DayMonth"]
                  ).format(dateFormat),
                  previousDate: dayjs(element["DayMonth"]).format(dateFormat),
                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachElement.tooltipData.previous[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
            })
      } else if (viewBy === "Week") {
        if (comparisonPeriod.selectedValue === "Compared By") {
          response?.forEach((element) => {
            let startOfWeek = dayjs(
              element["WeekMonthYear"].split(" to ")[0]
            ).format(dateFormat)
            let endOfWeek = dayjs(
              element["WeekMonthYear"].split(" to ")[1]
            ).format(dateFormat)

            let eachElement = {
              day: `${startOfWeek} to ${endOfWeek}` || 0,
              barValue: element[extractedParameters.keys]?.toFixed(2) || 0,
              date: element["WeekMonthYear"] || 0,
              tooltipData: {
                currentDate: `${startOfWeek} to ${endOfWeek}`,
                current: {},
              },
            }

            tooltip_data?.forEach((tooltipDataItem) => {
              eachElement.tooltipData.current[tooltipDataItem.name] = {
                value: element[tooltipDataItem.name],
                format: tooltipDataItem.format,
              }
            })

            data.push(eachElement)
          })
        } else {
          response.reportDataPrevious?.forEach((element, index) => {
            let currentStart = dayjs(
              response.reportDataCurrent[index]?.["WeekMonthYear"].split(
                " to "
              )[0]
            ).format(dateFormat)
            let currentEnd = dayjs(
              response.reportDataCurrent[index]?.["WeekMonthYear"].split(
                " to "
              )[1]
            ).format(dateFormat)

            let previousStart = dayjs(
              element?.["WeekMonthYear"].split(" to ")[0]
            ).format(dateFormat)
            let previousEnd = dayjs(
              element?.["WeekMonthYear"].split(" to ")[1]
            ).format(dateFormat)

            let eachElement = {
              day: response.reportDataCurrent[index]?.["WeekMonthYear1"] || 0,
              starDay: `${currentStart} to ${currentEnd}` || 0,
              currentDay: element?.["WeekMonthYear"],
              barValue:
                response.reportDataCurrent[index]?.[extractedParameters.keys] ||
                0,
              lineValue: element[extractedParameters.keys] || 0,
              tooltipData: {
                currentDate: `${currentStart} to ${currentEnd}`,
                previousDate: `${previousStart} to ${previousEnd}`,
                current: {},
                previous: {},
              },
            }

            // Populate tooltip data for both current and previous periods
            tooltip_data?.forEach((tooltipDataItem) => {
              eachElement.tooltipData.current[tooltipDataItem.name] = {
                value:
                  response.reportDataCurrent[index]?.[tooltipDataItem.name],
                format: tooltipDataItem.format,
              }

              eachElement.tooltipData.previous[tooltipDataItem.name] = {
                value: element[tooltipDataItem.name],
                format: tooltipDataItem.format,
              }
            })

            data.push(eachElement)
          })
        }
      } else if (viewBy === "Month") {
        comparisonPeriod.selectedValue === "Compared By"
          ? response?.forEach((element) => {
              let eachElement = {
                day: element["MonthYear"] || 0,
                barValue: element[extractedParameters.keys]?.toFixed(2) || 0,
                date: element["MonthYear"] || 0,
                tooltipData: {
                  currentDate: dayjs(element["MonthYear"]).format(dateFormat),
                  current: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
            })
          : response.reportDataPrevious.length > 1
          ? response.reportDataPrevious?.forEach((element, index) => {
              let eachElement = {
                day: response.reportDataCurrent[index]?.["MonthYear"] || 0,
                currentDay: element?.["MonthYear"],
                barValue:
                  response.reportDataCurrent[index]?.[
                    extractedParameters.keys
                  ] || 0,
                lineValue: element[extractedParameters.keys] || 0,
                tooltipData: {
                  currentDate: dayjs(
                    response.reportDataCurrent[index]?.["MonthYear"]
                  ).format(dateFormat),
                  previousDate: dayjs(element["MonthYear"]).format(dateFormat),
                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachElement.tooltipData.previous[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
              setDateRange(0)
            })
          : response.reportDataPrevious?.forEach((element, index) => {
              let eachElement = {
                day: element?.["MonthYear"] || 0,
                date: element?.["MonthYear"] || 0,
                barValue: element?.[extractedParameters.keys] || 0,
                tooltipData: {
                  currentDate: dayjs(
                    response.reportDataCurrent[index]?.["MonthYear"]
                  ).format(dateFormat),
                  previousDate: dayjs(element["MonthYear"]).format(dateFormat),
                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachElement.tooltipData.previous[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)

              let eachCurrentElement = {
                day: response.reportDataCurrent[index]?.["MonthYear"] || 0,
                date: response?.reportDataCurrent[index]?.["MonthYear"] || 0,
                barValue:
                  response.reportDataCurrent[index]?.[
                    extractedParameters.keys
                  ] || 0,
                tooltipData: {
                  currentDate: dayjs(
                    response.reportDataCurrent[index]?.["MonthYear"]
                  ).format(dateFormat),
                  previousDate: dayjs(element["MonthYear"]).format(dateFormat),
                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachCurrentElement.tooltipData.previous[tooltipDataItem.name] =
                  {
                    value: element[tooltipDataItem.name],
                    format: tooltipDataItem.format,
                  }
              })

              data.push(eachCurrentElement)
              setDateRange(1)
            })
      } else if (viewBy === "Quarter") {
        comparisonPeriod.selectedValue === "Compared By"
          ? response?.forEach((element) => {
              let eachElement = {
                day: element["QuarterYear"] || 0,
                barValue: element[extractedParameters.keys]?.toFixed(2) || 0,
                date: element["QuarterYear"] || 0,
                tooltipData: {
                  currentDate: element["QuarterYear"],
                  current: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
            })
          : response.reportDataPrevious?.forEach((element, index) => {
              let eachElement = {
                day: element?.["QuarterYear"] || 0,
                date: element?.["QuarterYear"] || 0,
                barValue: element?.[extractedParameters.keys] || 0,
                tooltipData: {
                  currentDate:
                    response.reportDataCurrent[index]?.["QuarterYear"],
                  previousDate: element["QuarterYear"],

                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachElement.tooltipData.previous[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)

              let eachCurrentElement = {
                day: response.reportDataCurrent[index]?.["QuarterYear"] || 0,
                // todo check QuarterYear
                date: response.reportDataCurrent[index]?.["QuarterYear"] || 0,
                barValue:
                  response.reportDataCurrent[index]?.[
                    extractedParameters.keys
                  ] || 0,
                tooltipData: {
                  currentDate:
                    response.reportDataCurrent[index]?.["QuarterYear"],
                  previousDate: element["QuarterYear"],

                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachCurrentElement.tooltipData.previous[tooltipDataItem.name] =
                  {
                    value: element[tooltipDataItem.name],
                    format: tooltipDataItem.format,
                  }
              })

              data.push(eachCurrentElement)
            })
      } else if (viewBy === "Year") {
        comparisonPeriod.selectedValue === "Compared By"
          ? response?.forEach((element) => {
              let eachElement = {
                day: element["Year"] || 0,
                barValue: element[extractedParameters.keys] || 0,
                date: element["Year"] || 0,
                tooltipData: {
                  currentDate: element["Year"],
                  current: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)
            })
          : response.reportDataPrevious?.forEach((element, index) => {
              let eachElement = {
                day: element?.["Year"] || 0,
                date: element?.["Year"] || 0,
                barValue: element?.[extractedParameters.keys] || 0,
                tooltipData: {
                  currentDate: response.reportDataCurrent[index]?.["Year"],
                  previousDate: element["Year"],
                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachElement.tooltipData.previous[tooltipDataItem.name] = {
                  value: element[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }
              })

              data.push(eachElement)

              let eachCurrentElement = {
                day: response.reportDataCurrent[index]?.["Year"] || 0,
                date: response?.reportDataCurrent[index]?.["Year"] || 0,
                barValue:
                  response.reportDataCurrent[index]?.[
                    extractedParameters.keys
                  ] || 0,
                tooltipData: {
                  currentDate: response.reportDataCurrent[index]?.["Year"],
                  previousDate: element["Year"],

                  current: {},
                  previous: {},
                },
              }

              tooltip_data?.forEach((tooltipDataItem) => {
                eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
                  value:
                    response.reportDataCurrent[index]?.[tooltipDataItem.name],
                  format: tooltipDataItem.format,
                }

                eachCurrentElement.tooltipData.previous[tooltipDataItem.name] =
                  {
                    value: element[tooltipDataItem.name],
                    format: tooltipDataItem.format,
                  }
              })

              data.push(eachCurrentElement)
            })
      }

      dispatch(
        setChartData({
          reportUid,
          response: data,
          apiResponse: response,
          cacheKey,
          viewBy: viewBy,
          // response: tooltipData,
        })
      )
      setLoading(false)
    } catch (error) {
      //setError("Inadequate data points to generate a report")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    cacheKey,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const extractedPara = extractKeyValuePairs(parameters)

  extractedPara.chart_colors ||
    (extractedPara.chart_colors = {
      line: {
        dark: "#fb651d",
        light: "#fb651d",
      },
      lineCircle: {
        dark: {
          fill: "#fb651d",
          stroke: "#ffffff",
        },
        light: {
          fill: "#ffffff",
          stroke: "#fb651d",
        },
      },
      bar: {
        dark: "#2647d9",
        light: "#2647d9",
      },
    })

  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = {
      height: "345px",
      minHeight: "345px",
      maxHeight: "unset",
    })

  const getMaxValue = (data) => {
    let maxValue = 0
    data.forEach((item) => {
      if (parseFloat(item.barValue) > maxValue)
        maxValue = parseFloat(item.barValue)
      if (parseFloat(item.lineValue) && parseFloat(item.lineValue) > maxValue)
        maxValue = parseFloat(item.lineValue)
    })
    return maxValue
  }

  const maxValue = getMaxValue(response || [])
  const legendData =
    comparisonPeriod.selectedValue === "Compared By"
      ? [
          {
            label: "Current",
            color:
              extractedPara?.chart_colors?.bar?.[theme.palette.mode || "dark"],
          },
        ]
      : [
          {
            label: "Current",
            color:
              extractedPara?.chart_colors?.bar?.[theme.palette.mode || "dark"],
          },
          {
            label: "Previous",
            color:
              extractedPara?.chart_colors?.line?.[theme.palette.mode || "dark"],
          },
        ]

  function transformReportData(data) {
    const transformedData = []

    const currentData = data?.reportDataCurrent || data
    const previousData = data?.reportDataPrevious || []

    if (!Array.isArray(currentData)) {
      console.warn("Current data is missing or not an array")
      return transformedData
    }

    const currentLength = currentData.length

    const tooltip_data = extractedParameters?.tooltip_data
    const dateFormat = getDateFormatFromLocalStorage()

    for (let i = 0; i < currentLength; i++) {
      const current = currentData[i]
      const previous = previousData[i] || {}

      const transformedWeek = {
        date:
          current["WeekMonthYear1"] ||
          dayjs(current["DayMonth"]).format(dateFormat),
      }

      tooltip_data.forEach((tooltip) => {
        const name = tooltip.name
        const currentVal = current[name] || 0

        if (Object.keys(previous).length > 0) {
          const previousVal = previous[name] || 0

          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
            previous:
              previousVal > 0
                ? tooltip.format === "Currency"
                  ? `$${previousVal.toLocaleString()}`
                  : previousVal.toString()
                : tooltip.format === "Currency"
                ? "$0"
                : "0",
            changePercent:
              previousVal === 0
                ? "-"
                : (((currentVal - previousVal) / previousVal) * 100).toFixed(
                    2
                  ) + "%",
          }
        } else {
          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
          }
        }
      })

      transformedData.push(transformedWeek)
    }

    return transformedData
  }

  const tableData = transformReportData(reportState?.apiResponse)
  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={reportState?.apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="20px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
          >
            {/* <Button
              variant="text"
              sx={{
                fontSize: "0.8rem",
                fontWeight: 700,
                color: theme.palette.accent,
                cursor: "pointer",
                textTransform: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleClickOpen}
            >
              + View More
            </Button> */}

            {extractedParameters.view_by_period && (
              <ViewBy
                route={route}
                parameters={
                  extractedParameters && extractedParameters.view_by_period
                }
                viewBy={viewBy}
                setViewBy={setViewBy}
                reportUid={reportUid}
                dateRangeData={dateRangeData}
              />
            )}
          </Box>
          <LineBarChartDetails
            open={open}
            handleClose={handleClose}
            maxValue={maxValue}
            error={error}
            loading={loading}
            response={response}
            extractedPara={extractedPara}
            comparisonPeriod={comparisonPeriod}
            chartInput={response}
            parameters={{
              ...extractedPara,
              viewBy: viewBy || "Day",
            }}
            dateRange={dateRange}
            legendsData={legendData}
          />

          {!loading && (
            <ExportMenuButton
              apiResponse={reportState?.apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <Typography variant="body2" color="inherit">
              {error}
            </Typography>
          </Box>
        ) : response && extractedPara ? (
          comparisonPeriod.selectedValue === "Compared By" ? (
            <NivoLineBarChart
              showAiDropdown={showAiDropdown}
              title={title}
              chartInput={response}
              parameters={extractedPara}
              legendsData={legendData}
              reportTitle={title}
              reportData={reportState?.apiResponse}
              showTooltipPrevious={showTooltipPrevious}
            />
          ) : (
            <NivoLineBarChart
              showAiDropdown={showAiDropdown}
              title={title}
              chartInput={response}
              parameters={{
                ...extractedPara,
                viewBy: viewBy || "Day",
              }}
              maxValue={maxValue}
              dateRange={dateRange}
              legendsData={legendData}
              reportTitle={title}
              reportData={reportState?.apiResponse}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            minHeight="350px"
            width="100%"
          >
            <Typography variant="body2" color="inherit">
              An error occurred while fetching the data, or no data is available
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export default LineBarChart
