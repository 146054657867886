import { postData } from "../../../../api/api"
import { ProgressBarData } from "../constant/configFallback"
import { transfromChartData } from "./transformServices"

export const fetchData = async (
  setLoading,
  setError,
  postUrl,
  combinedState,
  reportUid,
  dispatch,
  setChartData,
  setApiResponse,
  setMaxSliderValue,
  roundToNearest10,
  tooltip_data,
  fieldMap,
  cacheKey
) => {
  try {
    const response = await postData(postUrl, {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: combinedState.dateRangeData.fromDate,
      toDate: combinedState.dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: combinedState.comparisonPeriod.previousFromDate,
        previousToDate: combinedState.comparisonPeriod.previousToDate,
      },
    })
    setApiResponse(response)
    if (!response || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: ProgressBarData,
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    } else {
      const transformedData = transfromChartData(
        response,
        tooltip_data,
        fieldMap
      )
      const maxValue = Math.max(
        ...transformedData.map((item) => item.defaultValue || 0)
      )
      const roundedMaxValue = roundToNearest10(maxValue)

      setMaxSliderValue(roundedMaxValue)
      dispatch(
        setChartData({
          reportUid,
          response: transformedData,
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
    dispatch(
      setChartData({
        reportUid,
        response: ProgressBarData,
      })
    )
  } finally {
    setLoading(false)
  }
}
