export const tooltipDataFallbackConfig = [
  { name: "ProductName", format: false, color: "" },
  { name: "SalesQuantity", format: "Number", color: "" },
  { name: "TotalOrders", format: "Number", color: "" },
]
export const tooltipDataFallbackConfigCustomers = [
  { name: "Customers", format: "Number", color: "" },
  { name: "Discounts", format: "Currency", color: "" },
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Returns", format: "Number", color: "" },
]
export const tooltip_dataFallBackProduct = [
  { name: "Customers", format: "Number", color: "" },
  { name: "Discounts", format: "Currency", color: "" },
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Returns", format: "Number", color: "" },
]
export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const defaultGroupBarLegendLabel = {
  axisLeft: "",
  axisBottom: "",
}
export const ProductGroupBarLegendLabel = {
  axisLeft: "Sales - Quantity",
  axisBottom: "Day's",
}
export const CustomerGroupBarLegendLabel = {
  axisLeft: "Customers - Visits",
  axisBottom: "Day's",
}

export const colorScheme = [
  "rgba(54, 162, 235, 1)",
  "rgba(161, 123, 200, 1)",
  "rgba(23, 162, 184, 1)",
  "rgba(0, 123, 255, 1)",
  "rgba(108, 117, 125, 1)",
  "rgba(255, 99, 132, 1)",
  "#FF85E1",

  "rgba(52, 58, 64, 1)",
  "rgba(233, 236, 239, 1)",
]
// colorPalette.js

export const macroMetricsFallbackConfig = (reportUid) => {
  const targetField =
    reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
      ? "SalesQuantity"
      : "Gross Sales"

  const secondRowValue =
    reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
      ? "{day} - {ProductName}"
      : "{day}"
  return [
    {
      name: "highest",
      helpText: "Highest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      secondRow: { value: secondRowValue, operation: "templateString" },
      thirdRow: { value: "Star Performer", operation: null },
      aggregateFunc: "MAX",
      targetField: targetField,
      format: "Currency",
      color: "#23BF6F",
      icon: "VerifiedIcon",
    },
    {
      name: "lowest",
      helpText: "Lowest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      secondRow: {
        value: secondRowValue,
        operation: "templateString",
      },
      thirdRow: { value: "Low Performer", operation: null },
      aggregateFunc: "MIN",
      targetField: targetField,
      format: "Currency",
      color: "#EC2D30",
      icon: "NewReleasesIcon",
    },
  ]
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]
