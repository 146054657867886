// fetch.js
import { postData } from "../../../../api/api"
import { HeatmapChartData } from "../constants/dataFallBack"
import { transfromChartData } from "./transformServices"

export const fetchData = async (
  postUrl,
  combinedState,
  reportUid,
  tooltip_data,
  setChartData,
  dispatch,
  setLoading,
  setApiResponse,
  setError,
  cacheKey
) => {
  try {
    setLoading(true)
    const response = await postData(postUrl, {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: combinedState.dateRangeData.fromDate,
      toDate: combinedState.dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: combinedState.comparisonPeriod.previousFromDate,
        previousToDate: combinedState.comparisonPeriod.previousToDate,
      },
    })

    setApiResponse(response)

    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: HeatmapChartData,
          cacheKey: cacheKey,
        })
      )
    } else {
      dispatch(
        setChartData({
          reportUid,
          response: transfromChartData(response, tooltip_data),
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
    dispatch(
      setChartData({
        reportUid,
        response: HeatmapChartData,
      })
    )
  } finally {
    setLoading(false)
  }
}
