import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"
import "./Style.scss"
import { useTheme } from "../../contexts/theme"
import Analytica from "../../components/Analytica"
import { getFormattedValue } from "../../utils/utils"

const MarketingKPICard = ({
  title,
  currentValue,
  previousValue,
  numberrise,
  status,
  formatParams,
  isLoading,
  description,
}) => {
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode

  return (
    <>
      <Stack
        display="flex"
        justifyContent="space-between"
        height="100%"
        gap="5px"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="2px"
        >
          <Typography
            variant="subtitle2"
            component="div"
            style={{
              marginBottom: "8px",
              overflow: "visible",
              overflowWrap: "break-word",
              textOverflow: "none",
              color: ThemeMode === "dark" ? "#ffffff" : "#737373",
              fontWeight: "700",
            }}
            textAlign="left"
          >
            {title}
          </Typography>

          <Box display="flex">
            {description && <Analytica description={description} />}
          </Box>
        </Box>
        <Stack display="flex" gap="5px">
          {isLoading ? (
            <CircularProgress disableShrink color="info" size={30} />
          ) : (
            <>
              <Typography
                variant="h5"
                color={currentValue !== null ? "inherit" : "#ff000080"}
              >
                {currentValue !== null ? (
                  <span
                    className="KPICardhoverEffect"
                    style={{ color: "inherit", fontWeight: "bold" }}
                  >
                    {status}
                  </span>
                ) : (
                  "No Data!"
                )}
              </Typography>
              {previousValue !== null && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Box
                    className="KPICardhoverEffect"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="400"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      color={ThemeMode === "dark" ? "#ffffff" : "#404040"}
                    >
                      {numberrise}
                    </Typography>
                  </Box>
                  <Typography
                    color="textSecondary"
                    fontWeight="400"
                    fontSize="0.9rem"
                    sx={{
                      color: ThemeMode === "dark" ? "#ffffff" : "#404040",
                    }}
                    className="KPICardhoverEffect"
                  >
                    {getFormattedValue(previousValue, formatParams)}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default MarketingKPICard
