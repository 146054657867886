import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import {
  Bumpchartdata,
  BumpChartParameters,
} from "../../../constants/chartsData"
import BumpChartPresentation from "../bump/BumpChartPresentation"

const BumpChartContainer = ({ title, description, showAiDropdown = true }) => {
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
      </Box>

      <Box minHeight="350px">
        <BumpChartPresentation
          chartInput={Bumpchartdata}
          parameters={BumpChartParameters}
          reportTitle={title}
          reportData={Bumpchartdata}
          showAiDropdown={showAiDropdown}
        />
      </Box>
    </>
  )
}

export default BumpChartContainer
