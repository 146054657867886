import { postData } from "../../../../api/api"

export const fetchData = async (postUrl, payload) => {
  return await postData(postUrl, payload)
}

export const getMaxValue = (data) => {
  let maxSales = 0

  if (Array.isArray(data)) {
    data.forEach((dataset) => {
      dataset.data.forEach((entry) => {
        const adjustedSales = Math.round(entry.y + entry.y * 0.2)
        if (adjustedSales > maxSales) {
          maxSales = adjustedSales
        }
      })
    })
  } else {
    console.error("data is not an array:", data)
  }

  return maxSales
}
