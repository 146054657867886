import { ResponsiveRadar } from "@nivo/radar"
import Box from "@mui/material/Box"
import CustomTooltip from "../../CustomTooltip"
import { useTheme } from "../../../contexts/theme"

const RadarChartPresentation = ({
  chartInput,
  chartConfig,
  reportTitle,
  reportData,
  showTooltipPrevious,
}) => {
  const {
    keys,
    indexBy,
    maxValue,
    margin,
    curve,
    gridShape,
    borderWidth,
    borderColor,
    gridLevels,
    gridLabelOffset,
    enableDots,
    dotSize,
    dotColor,
    dotBorderWidth,
    dotBorderColor,
    enableDotLabel,
    dotLabel,
    dotLabelYOffset,
    colors,
    fillOpacity,
    blendMode,
    animate,
    motionConfig,
    isInteractive,
    legends,
  } = chartConfig
  const { theme } = useTheme()

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    // tooltip: {
    //   container: {
    //     background: "rgba(0, 0, 0, 0.8)",
    //     color: "#fff",
    //   },
    // },
    grid: {
      line: {
        stroke: theme.palette.mode === "dark" ? "#3551AA" : "#000000",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    // axis: {
    //   domain: {
    //     line: {
    //       stroke: "rgba(29, 71, 206, 0.3)",
    //       strokeWidth: 1,
    //     },
    //   },
    //   ticks: {
    //     text: {
    //       fontSize: 10,
    //       fill: itemTextColor,
    //       outlineWidth: 0,
    //       outlineColor: "transparent",
    //     },
    //   },
    // },
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "400px", width: "100%" }}
    >
      <ResponsiveRadar
        data={chartInput}
        theme={customTheme}
        keys={keys}
        indexBy={indexBy}
        maxValue={maxValue}
        margin={margin}
        curve={curve}
        gridShape={gridShape}
        borderWidth={borderWidth}
        borderColor={borderColor}
        gridLevels={gridLevels}
        gridLabelOffset={gridLabelOffset}
        enableDots={enableDots}
        dotSize={dotSize}
        dotColor={dotColor}
        dotBorderWidth={dotBorderWidth}
        dotBorderColor={dotBorderColor}
        enableDotLabel={enableDotLabel}
        dotLabel={dotLabel}
        dotLabelYOffset={dotLabelYOffset}
        colors={colors}
        fillOpacity={fillOpacity}
        blendMode={blendMode}
        animate={animate}
        motionConfig={motionConfig}
        isInteractive={isInteractive}
        // sliceTooltip={(chartInput) =>
        //   // <CustomTooltip data={chartInput.data} showCurrentPrevDate={true} />
        // }
      />
    </Box>
  )
}

export default RadarChartPresentation
