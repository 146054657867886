import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  transformChartData,
  transformDataPDFExport,
  transformDataSheetExport,
} from "./services/tansformService"
import Stack from "@mui/material/Stack"
import { generateCacheKey } from "../../../utils/utils"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import HeaderSection from "./components/widget/HeaderSection"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import Message from "../../Message"
import { fetchData } from "./services/dataService"
import { ColorsArray1, ColorsArray2 } from "./constant/configFallback"
import { staticApiResponse, staticTransformedChartData } from "./constant/dataFallback"

const ProgressBarContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
    extApiResponse,
    extLoading = false,
    extError = null
  }) => {
    const dispatch = useDispatch()
    const chartRef = useRef(null)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    const isComparedBy = comparisonPeriod.selectedValue === "Compared By"
    let transformedConfigs = extractKeyValuePairs(configurations)

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // Override / calculated configs
    const configOverrides = [
      {
        key: "tooltip_data",
        fallback: [
          { name: "Name", format: false, color: "" },
          { name: "TotalOrders", format: "Number", color: "" },
          { name: "Customer Lifetime Value", format: "Currency", color: "" },
        ],
      },
      {
        key: "pdfFields",
        fallback: [
          { name: "Name", format: false, color: "" },
          { name: "TotalOrders", format: "Number", color: "" },
          { name: "Customer Lifetime Value", format: "Currency", color: "" },
        ],
      },
      {
        key: "fieldMap",
        fallback: { name: "Name", key: "Customer Lifetime Value" },
      },
      {
        key: "showTooltipPrevious",
        fallback:
          transformedConfigs.showTooltipPrevious === undefined
            ? true
            : transformedConfigs.showTooltipPrevious,
      },
      {
        key: "enableNavigation",
        fallback:
          transformedConfigs.enableNavigation === undefined
            ? true
            : transformedConfigs.enableNavigation,
      },
      {
        key: "showBubble",
        fallback:
          transformedConfigs.showBubble === undefined
            ? true
            : transformedConfigs.showBubble,
      },
      {
        key: "beforePointerColors",
        fallback: [
          "Most Popular Brands",
          "Top 10 products bought",
          "Popular Items with Repeat Customers",
        ].includes(title)
          ? ColorsArray1
          : ColorsArray2,
      },
      {
        key: "afterPointerColor",
        fallback: "rgba(7, 19, 57, 1)",
      },
      //   const getNavigationPath = (item) => {
      //   console.log("getNavigationPath", item)

      //   const navigationPaths = {
      //     customer: "/customers/allcustomers/customer/1",
      //     employee: "/employees/allemployees/employee/1",
      //     inventory: "/products/allproducts/product/1",
      //   }

      //   return item
      // }
      {
        key: "navigationPath",
        fallback: {
          pathInfo: {
            customer: {
              path: "/customers/allcustomers/customer/",
              idKey: "CustomerID",
            },
            employee: {
              path: "/employees/allemployees/employee/",
              idKey: "employeesId",
            },
            inventory: {
              path: "/products/allproducts/product/",
              idKey: "ProductID",
            },
          }[route] || {
            path: "/customers/allcustomers/customer/",
            idKey: "CustomerID",
          },
          getPathWithId: (path, item) => {
            return path + item
          },
        },
      },
      {
        key: "defaultRowsPerPage",
        fallback: 10,
      },
    ]

    configOverrides.forEach(({ key, fallback }) => {
      transformedConfigs[key] = transformedConfigs[key] || fallback
    })

    useEffect(() => {
      if (['static', 'external'].includes(reportUid)) return
      if (reportState.cacheKey === cacheKey) return
      const payload = {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: comparisonPeriod.previousFromDate,
          previousToDate: comparisonPeriod.previousToDate,
        },
      }

      setLoading(true)
      fetchData(postUrl, payload)
        .then((apiResponse) => {
          dispatch(
            setChartData({
              reportUid,
              response: apiResponse,
              apiResponse,
              transformedChartData: transformChartData(
                apiResponse,
                isComparedBy,
                transformedConfigs
              ),
              cacheKey,
            })
          )
          setLoading(false)
        })
        .catch((err) => {
          console.warn(err)
          setError("Failed to fetch data")
          setLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.cacheKey,
    ])

    if (reportUid === "static") {
      reportState.apiResponse = staticApiResponse
      reportState.transformedChartData = staticTransformedChartData
    }

    if (reportUid === "external") {
      reportState.apiResponse = extApiResponse
      reportState.transformedChartData = transformChartData(
        extApiResponse,
        true,
        transformedConfigs
      )
    }

    return (
      <Stack gap={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          parentLoading={loading}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs}
          pdfTransformFunction={transformDataPDFExport}
          transformDataSheetExport={transformDataSheetExport}
        />

        {extLoading || loading ? (
          <Message type="loading" />
        ) : extError || error ? (
          <Message message={extError || error} type="error" />
        ) : (
          <>
            <BodySection
              config={transformedConfigs}
              data={reportState.transformedChartData}
            />
            <FooterSection
              legendsData={[]}
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    )
  }
)

export default ProgressBarContainer
