import React, { useState } from "react"
import { useFormik } from "formik"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import InputAdornment from "@mui/material/InputAdornment"
import { postData } from "../../api/api"
import { forgotpasswordSchema } from "./Validation"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({ header: "", message: "" })
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const { email } = values
      localStorage.setItem("user", JSON.stringify({ email }))
      try {
        await forgotpasswordSchema.validate(values, { abortEarly: false })
        const response = await postData("auth/forgot-password", {
          email,
        }).catch(function (error) {
          if (error.response) {
            return error.response.data
          }
        })
        setIsLoading(false)

        // const simulateSuccess = true
        // const response = {
        //   true: {
        //     success: true,
        //     msg: `Password reset successfully! - asdfa`,
        //   },
        //   false: { success: false, msg: "Internal Server Error" },
        // }[simulateSuccess]

        if (response?.success) {
          setError(false)
          setModalMessage({ header: "Success", message: response?.msg })
          setShowModal(true)
        } else {
          setError(true)
          setModalMessage({ header: "Failure", message: response?.msg })
          setShowModal(true)
        }
      } catch (error) {
        setModalMessage({
          header: "Failure",
          message: error,
        })
        setShowModal(true)
      }
    },
  })

  const closeModal = () => {
    setShowModal(false)
    if (!error) {
      navigate("/login")
    }
  }

  return (
    <>
      <Stack display="flex" gap={2}>
        <Typography variant="h5" color="inherit" textAlign="center">
          Forgot Password
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="body1" color="textSecondary" textAlign="center">
            Please enter your registered email.
          </Typography>
          <TextField
            sx={{
              display: "block",
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-input": { color: "#000" },
            }}
            required
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              endAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <Box width="100%" display="flex" justifyContent="center">
            <LoadingButton
              type="submit"
              loading={isLoading}
              sx={{
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                width: "8rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
                "&:disabled": {
                  backgroundColor: "#2642a8a3",
                },
                "& .MuiCircularProgress-colorInherit": {
                  color: "white",
                },
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </Stack>

      <Modal open={showModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">{modalMessage?.header}</Typography>
          <Typography variant="body1">{modalMessage?.message}</Typography>
        </Box>
      </Modal>
    </>
  )
}

export default ForgotPassword
