/**
 * Formats a number according to specified options.
 *
 * @param {number} number - The number to format.
 * @param {Object} [options={}] - Optional formatting options.
 * @returns {string} - The formatted number.
 *
 * @example
 * console.log(formatNumber(1234567890)); // "1,234,567,890"
 * console.log(formatNumber(1234567.89)); // "1,234,567.89"
 * console.log(formatNumber(1234567.89, { style: "currency", currency: "USD" })); // "$1,234,567.89"
 * console.log(formatNumber(0.123, { style: "percent" })); // "12%"
 * console.log(formatNumber(1234567.8, { minimumFractionDigits: 2, maximumFractionDigits: 2 })); // "1,234,567.80"
 * console.log(formatNumber(1234567, { minimumFractionDigits: 2, maximumFractionDigits: 2 })); // "1,234,567.00"
 */
export function formatNumber(number, options = {}) {
  const formatter = new Intl.NumberFormat("en-US", options)
  return isNaN(number) ? number : formatter.format(number)
}

/**
 * Formats a value based on the specified format parameter.
 *
 * @param {number} value - The value to format.
 * @param {string} format - The format type (e.g., "Number", "Currency", "Percentage", "Time").
 * @returns {string} - The formatted value.
 */
export function getFormattedValue(value, format) {
  const formatValue =
    {
      Number: (value) => formatNumber(value),
      Currency: (value) =>
        formatNumber(value, { style: "currency", currency: "USD" }),
      Percentage: (value) =>
        formatNumber(value / 100, {
          style: "percent",
          minimumFractionDigits: 1,
          maximumFractionDigits: 2,
        }),
      Time: (value) => formatNumber(value, { style: "unit", unit: "hour" }),
    }[format] || ((value) => value) // Default function if format is not defined

  return formatValue(value)
}

export function getFiltersString(filters) {
  if (!Array.isArray(filters) || filters.length === 0) {
    return ""
  }

  return filters
    .map((filter, index) => {
      if (!filter) return null

      const { logicOperator, columnField, operator, value } = filter
      if (!columnField || !operator || !value) return null

      let operatorString = {
        "=": "equals",
        "!=": "notEquals",
        "<": "lessThan",
        ">": "greaterThan",
        "<=": "lessThanOrEqual",
        ">=": "greaterThanOrEqual",
        contains: "contains",
        startsWith: "startsWith",
        endsWith: "endsWith",
        doesNotContain: "notContains",
        doesNotStartWith: "notStartsWith",
        doesNotEndWith: "notEndsWith",
        null: "isNull",
        notNull: "isNotNull",
        in: "in",
        notIn: "notIn",
        between: "between",
        notBetween: "notBetween",
        undefined: "",
      }[operator]

      const filterString = `${columnField} ${operatorString} "${value}"`

      return index === 0
        ? `> ${filterString}`
        : `${logicOperator} ${filterString}`
    })
    .filter(Boolean)
    .join(" ")
}

export function getFiltersSQL(filters) {
  if (!Array.isArray(filters) || filters.length === 0) {
    return ""
  }

  return filters
    .map((filter, index) => {
      if (!filter) return null

      const { logicOperator, columnField, operator, value } = filter
      if (!columnField || !operator || !value) return null

      const condition = {
        "=": `${columnField} = '${value}'`,
        "!=": `${columnField} != '${value}'`,
        "<": `${columnField} < '${value}'`,
        ">": `${columnField} > '${value}'`,
        "<=": `${columnField} <= '${value}'`,
        ">=": `${columnField} >= '${value}'`,
        contains: `${columnField} LIKE '%${value}%'`,
        startsWith: `${columnField} LIKE '${value}%'`,
        endsWith: `${columnField} LIKE '%${value}'`,
        doesNotContain: `${columnField} NOT LIKE '%${value}%'`,
        doesNotStartWith: `${columnField} NOT LIKE '${value}%'`,
        doesNotEndWith: `${columnField} NOT LIKE '%${value}'`,
        null: `${columnField} IS NULL`,
        notNull: `${columnField} IS NOT NULL`,
        // in: `${columnField} IN (${value.map((val) => `'${val}'`).join(", ")})`,
        in: `${columnField} IN (${value.split(",")?.join(", ")})`,
        // notIn: `${columnField} NOT IN (${value
        //   .map((val) => `'${val}'`)
        //   .join(", ")})`,
        notIn: `${columnField} NOT IN (${value.split(",")?.join(", ")})`,
        // between: `${columnField} BETWEEN '${value[0]}' AND '${value[1]}'`,
        between: `${columnField} BETWEEN ${value.split(",")?.join(" AND ")}`,
        // notBetween: `${columnField} NOT BETWEEN '${value[0]}' AND '${value[1]}'`,
        notBetween: `${columnField} NOT BETWEEN ${value
          .split(",")
          ?.join(" AND ")}`,
        undefined: "",
      }[operator]

      return index === 0 ? `(${condition}` : `${logicOperator} ${condition}`
    })
    .filter(Boolean)
    .join(" ")
    .concat(")")
}

// calculate values current nd previous
export function calculatePercentageChange(current, previous) {
  const parseValue = (value) => {
    if (typeof value === "string" && value.includes("$")) {
      return parseFloat(value.replace("$", "").replace(",", ""))
    }
    return parseFloat(value)
  }

  const currentParsed = parseValue(current)
  const previousParsed = parseValue(previous)

  if (previousParsed === 0) {
    return currentParsed === 0 ? 0 : 100 * currentParsed
  }

  return ((currentParsed - previousParsed) / previousParsed) * 100
}

export function formatPreviousPeriodHeatmapData(data) {
  if (!data || !Array.isArray(data)) {
    return []
  }

  // Define the desired hour range
  const desiredHours = [
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
  ]

  // Step 1: Organize data by day
  const organizedData = data.reduce((acc, entry) => {
    if (!acc[entry.id]) {
      acc[entry.id] = []
    }
    acc[entry.id].push(entry)
    return acc
  }, {})

  // Step 2: Calculate average y value for each hour for each day, ensuring all desired hours are present
  const transformedData = Object.entries(organizedData).map(
    ([day, dayData]) => {
      const hourMap = desiredHours.reduce((acc, hour) => {
        acc[hour] = { sumY: 0, count: 0, data: [] }
        return acc
      }, {})

      // Populate hourMap with actual data
      dayData.forEach((entry) => {
        const hourKey = entry.x
        if (hourMap[hourKey]) {
          hourMap[hourKey].sumY += entry.y
          hourMap[hourKey].count += 1

          // Use Object.keys to dynamically add all properties from entry
          const dataEntry = Object.keys(entry).reduce((acc, key) => {
            acc[key] = entry[key]
            return acc
          }, {})

          hourMap[hourKey].data.push(dataEntry)
        }
      })

      // Calculate average y values, ensuring all desired hours are included
      const averagedData = desiredHours.map((hour) => {
        const { sumY, count, data } = hourMap[hour]
        const avgY = count > 0 ? sumY / count : 0
        return { x: hour, y: avgY, data }
      })

      return { id: day, data: averagedData }
    }
  )

  return transformedData
}

export function formatDataComparedBy(data) {
  if (!data || !Array.isArray(data)) {
    return []
  }

  // Define the desired hour range
  const desiredHours = [
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
  ]

  // Step 1: Organize data by day
  const organizedData = {}
  data.forEach((entry) => {
    if (!organizedData[entry.id]) {
      organizedData[entry.id] = []
    }
    organizedData[entry.id].push(entry)
  })

  // Step 2: Calculate average y value for each hour for each day, ensuring all desired hours are present
  const transformedData = []
  for (const day in organizedData) {
    const dayData = organizedData[day]
    const hourMap = {}

    // Initialize hourMap with all desired hours, setting default values
    desiredHours.forEach((hour) => {
      hourMap[hour] = { sumY: 0, count: 0, data: [] }
    })

    // Populate hourMap with actual data
    dayData.forEach((entry) => {
      const hourKey = entry.x
      if (hourMap[hourKey]) {
        hourMap[hourKey].sumY += entry.y
        hourMap[hourKey].count += 1

        const dataEntry = Object.keys(entry).reduce((acc, key) => {
          acc[key] = entry[key]
          return acc
        }, {})

        hourMap[hourKey].data.push(dataEntry)
      }
    })

    // Calculate average y values, ensuring all desired hours are included
    const averagedData = []
    desiredHours.forEach((hour) => {
      const avgY =
        hourMap[hour].count > 0 ? hourMap[hour].sumY / hourMap[hour].count : 0
      averagedData.push({ x: hour, y: avgY, data: hourMap[hour].data })
    })

    transformedData.push({ id: day, data: averagedData })
  }

  return transformedData
}

export function mergeHeatmapComparisonData(currentData, previousData) {
  return currentData?.map((currentItem) => {
    // Find the matching previous item
    const matchingPreviousItem = previousData.find(
      (prevItem) =>
        prevItem.Weekday === currentItem.Weekday &&
        prevItem.id === currentItem.id &&
        prevItem.x === currentItem.x
    )

    // If there's a matching previous item, nest it within the current item
    if (matchingPreviousItem) {
      return {
        ...currentItem,
        reportDataPrevious: [matchingPreviousItem],
      }
    } else {
      // If no matching previous item, just return the current item as is
      return currentItem
    }
  })
}

export const generateCacheKey = (...args) => {
  const filteredArgs = args.filter((arg) => arg != null)
  return `cache_${filteredArgs.join("_")}`
}

export const convertToSnakeCase = (input) => {
  return input
    .trim() // Remove leading and trailing whitespace
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "_") // Replace spaces with underscores
}
