export const Bumpchartdata = [
  {
    id: "KC GONZALEZ",
    data: [
      {
        x: "Jan",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4461.25, format: "Currency" } },
          previous: { Sales: { value: 3350.77, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "BREE RUBENZER",
    data: [
      {
        x: "Jan",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.1, format: "Currency" } },
          previous: { Sales: { value: 4200.45, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5300.75, format: "Currency" } },
          previous: { Sales: { value: 5000.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 3000.4, format: "Currency" } },
          previous: { Sales: { value: 2750.33, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "CHRISTINE SMOCZYK",
    data: [
      {
        x: "Jan",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3300.75, format: "Currency" } },
          previous: { Sales: { value: 3100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.2, format: "Currency" } },
          previous: { Sales: { value: 4700.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2200.55, format: "Currency" } },
          previous: { Sales: { value: 2000.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4850.3, format: "Currency" } },
          previous: { Sales: { value: 4600.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5100.45, format: "Currency" } },
          previous: { Sales: { value: 4900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "KIM POPP",
    data: [
      {
        x: "Jan",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.7, format: "Currency" } },
          previous: { Sales: { value: 4200.9, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "Maria Silva",
    data: [
      {
        x: "Jan",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 2100.9, format: "Currency" } },
          previous: { Sales: { value: 1900.4, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3300.2, format: "Currency" } },
          previous: { Sales: { value: 3100.55, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MARIA RAMOS",
    data: [
      {
        x: "Jan",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.75, format: "Currency" } },
          previous: { Sales: { value: 3900.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.3, format: "Currency" } },
          previous: { Sales: { value: 3900.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.9, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4400.2, format: "Currency" } },
          previous: { Sales: { value: 4200.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.5, format: "Currency" } },
          previous: { Sales: { value: 3500.3, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "PAMELA M",
    data: [
      {
        x: "Jan",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.3, format: "Currency" } },
          previous: { Sales: { value: 2700.2, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MAKENA E",
    data: [
      {
        x: "Jan",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5000.25, format: "Currency" } },
          previous: { Sales: { value: 4800.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1500.5, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4000.8, format: "Currency" } },
          previous: { Sales: { value: 3800.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.3, format: "Currency" } },
          previous: { Sales: { value: 1100.2, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.6, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JESSICA K",
    data: [
      {
        x: "Jan",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 800.3, format: "Currency" } },
          previous: { Sales: { value: 600.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1000.7, format: "Currency" } },
          previous: { Sales: { value: 800.6, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JOHNA SH",
    data: [
      {
        x: "Jan",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.6, format: "Currency" } },
          previous: { Sales: { value: 2600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.7, format: "Currency" } },
          previous: { Sales: { value: 1100.4, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "ALEXA P",
    data: [
      {
        x: "Jan",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.8, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "AVA ARONSON",
    data: [
      {
        x: "Jan",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.6, format: "Currency" } },
          previous: { Sales: { value: 2900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
    ],
  },
]

export const BumpChartParameters = {
  margin: {
    top: 40,
    right: 100,
    bottom: 40,
    left: 60,
  },
  lineWidth: 3,
  activeLineWidth: 6,
  inactiveLineWidth: 3,
  inactiveOpacity: 0.15,
  pointSize: 10,
  activePointSize: 16,
  inactivePointSize: 0,
  pointColor: { theme: "background" },
  pointBorderWidth: 3,
  activePointBorderWidth: 3,
  pointBorderColor: { from: "serie.color" },
  axisTop: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -36,
    truncateTickAt: 0,
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: 32,
    truncateTickAt: 0,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Ranking",
    legendPosition: "middle",
    legendOffset: -40,
    truncateTickAt: 0,
  },
  axisRight: null,
}
