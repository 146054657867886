import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import dayjs from "dayjs"

export const transfromChartData = (data) => {
  const dataSource = Array.isArray(data?.reportDataCurrent)
    ? data.reportDataCurrent
    : data
  return dataSource.map((item, index) => {
    const { Employee, ...rest } = item

    const current = {}
    const previous = {}

    for (const key in rest) {
      if (key.startsWith("previous")) {
        const newKey = key.replace("previous", "").toLowerCase()
        previous[newKey] = {
          value: rest[key],
          format: newKey === "price" ? "Currency" : "Number",
        }
      } else {
        current[key.toLowerCase()] = {
          value: rest[key],
          format: key === "price" ? "Currency" : "Number",
        }
      }
    }

    return {
      id: `0.${index}`,
      group: Employee,
      tooltipData: {
        current,
      },
      ...rest,
    }
  })
}

export function transformExportData(apiResponse, extractedParameters = {}) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || [
    { name: "GrossSales", format: "Currency", color: "" },
    { name: "SalesQuantity", format: "Number", color: "" },
    { name: "SEQRank", format: "Number", color: "" },
  ]

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"]),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}
