import { ResponsiveCalendar } from "@nivo/calendar"
import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { useTheme } from "../../../../../contexts/theme"

const BodySection = ({ data = [], config }) => {
  const { theme } = useTheme()
  const {
    chartContainerDimensions,
    defaultColor,
    showTooltipPrevious,
    colors,
  } = config

  const fromDate = "2022-01-01"
  const toDate = "2024-12-01"

  const itemTextColor = theme?.palette?.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    labels: {
      text: {
        fill: itemTextColor,
      },
    },
    legends: {
      text: {
        fontSize: 12,
      },
    },
  }

  return (
    <Box sx={{ ...chartContainerDimensions, height: "900px" }}>
      <ResponsiveCalendar
        data={data}
        margin={{ top: 40, right: 0, bottom: 60, left: 20 }}
        theme={customTheme}
        from={fromDate}
        to={toDate}
        emptyColor="#eeeeee"
        colors={defaultColor ? defaultColor : colors}
        yearSpacing={-350}
        monthSpacing={10}
        yearLegendOffset={10}
        monthLegendOffset={18}
        monthLegendPosition={"after"}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        tooltip={(n) => (
          <CustomTooltip
            data={n.data}
            showCurrentPrevDate={true}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      />
    </Box>
  )
}

export default BodySection
