import NivoStackedBarChart from "./NivoStackedBarchart"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { postData } from "../../../api/api"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { TimeLineData } from "./TimeLineData"
import { TimeLineParameters } from "./TimeLineParameters"
import { AgeAnalysisData } from "./AgeAnalysisData"
import { AgeAnalysisParameters } from "./AgeAnalysisParameters"

const StackedBarChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showLegend = false,
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()

  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const svgColor = theme.palette.accentSecondary
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  function convertValues(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        convertValues(obj[key])
      } else if (typeof obj[key] === "string") {
        if (!isNaN(obj[key])) {
          obj[key] = Number(obj[key])
        } else if (obj[key].toLowerCase() === "true") {
          obj[key] = true
        } else if (obj[key].toLowerCase() === "false") {
          obj[key] = false
        }
      }
    }
    return obj
  }
  const params = convertValues(extractedParameters)
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const fetchData = async () => {
    try {
      let response

      if (reportUid === "static-StackedBarchart") {
        response = false
      } else {
        response = await postData(postUrl, {
          entityDb: localStorage.getItem("entityDb"),
          fromDate: combinedState.dateRangeData.fromDate,
          toDate: combinedState.dateRangeData.toDate,
          reportInstanceId: reportUid,
          comparedBy: {
            previousFromDate: combinedState.comparisonPeriod.previousFromDate,
            previousToDate: combinedState.comparisonPeriod.previousToDate,
          },
          // viewBy: viewBy || "Day",
        })
      }
      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: response,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const configMap = {
    "Age Analysis": AgeAnalysisParameters,
    Timeline: TimeLineParameters,
  }

  const Dynamicconfig = parameters.length === 1 ? TimeLineParameters : params

  const config =
    reportUid === "static-StackedBarchart" ? configMap[title] : Dynamicconfig

  const chartDataMap = {
    "Age Analysis": AgeAnalysisData,
    Timeline: TimeLineData,
  }

  const chartInput = Array.isArray(response)
    ? response
    : chartDataMap[title] || TimeLineData

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="StackedBar"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                  showLegend,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="350px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoStackedBarChart
            chartInput={chartInput}
            parameters={config}
            showlegend={showLegend}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoStackedBarChart
            chartInput={chartInput}
            parameters={config}
            showlegend={showLegend}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default StackedBarChart
