import { createSlice } from "@reduxjs/toolkit"
import { calculatePreviousPeriod, formatDate } from "../../utils/dateUtils"
import dayjs from "dayjs"

const today = dayjs()
const startOfMonth = today.startOf("month")
const isBefore7th = today.date() <= 7

const isBefore2PM =
  today.hour() < 14 || (today.hour() === 14 && today.minute() < 0)

let fromDate, toDate, label

if (isBefore7th) {
  // If today is between the 1st and 7th of the month, take the last 30 days
  fromDate = today.subtract(30, "days")
  toDate = isBefore2PM ? today.subtract(2, "day") : today.subtract(1, "day")
  label = {
    shortcutLabel: "Last 30 Days",
    formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
      toDate,
      "MMM D"
    )}`,
  }
} else {
  // If today is after the 7th, take the range from 1st of this month to today
  fromDate = startOfMonth
  toDate = isBefore2PM ? today.subtract(2, "day") : today.subtract(1, "day")
  label = {
    shortcutLabel: "This Month",
    formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
      toDate,
      "MMM D"
    )}`,
  }
}

const initialState = {
  fromDate: formatDate(fromDate, "YYYYMMDD"),
  toDate: formatDate(toDate, "YYYYMMDD"),
  label,
}

const initialComparisonPeriod = calculatePreviousPeriod(
  initialState.fromDate,
  initialState.toDate
)

const periodComparisonSlice = createSlice({
  name: "period",
  initialState: {
    selectedValue: {
      selectedValue: "Previous Period",
      previousFromDate: initialComparisonPeriod.previousFromDate,
      previousToDate: initialComparisonPeriod.previousToDate,
    },
  },
  reducers: {
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload
    },
  },
})

export const { setSelectedValue } = periodComparisonSlice.actions

export default periodComparisonSlice.reducer
