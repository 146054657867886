import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import { tooltipDataFallbackConfig } from "../constant/Constants"
import { getFormattedValue } from "../../../../utils/utils"

export function transformChartData(
  input,
  chart,
  comparisonPeriod,
  viewBy,
  tooltip_data
) {
  const data = []
  if (comparisonPeriod === true) {
    if (viewBy === "Day") {
      if (!input || !Array.isArray(input)) {
        console.error("Expected input to be an array but received:", input)
        return []
      }
      input?.forEach((currentItem) => {
        let eachElement = {
          idMonth:
            chart === "left"
              ? dayjs(currentItem.idMonth).format("MM-DD-YYYY") || 0
              : currentItem.idMonth || 0,
          [chart === "left" ? "orderCount" : "grossSale"]:
            chart === "left"
              ? parseFloat(currentItem["Order Count"])
              : parseFloat(currentItem["Gross Sales"]),
          tooltipData: {
            current: {},
          },
        }

        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: Math.abs(currentItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else if (
      viewBy === "Week" ||
      viewBy === "Month" ||
      viewBy === "Quarter" ||
      viewBy === "Year"
    ) {
      if (!input || !Array.isArray(input)) {
        console.error("Expected input to be an array but received:", input)
        return []
      }
      input.forEach((currentItem) => {
        let eachElement = {
          idMonth: currentItem.id || 0,
          [chart === "left" ? "orderCount" : "grossSale"]:
            chart === "left"
              ? parseFloat(currentItem["Order Count"])
              : parseFloat(currentItem["Gross Sales"]),
          tooltipData: {
            current: {},
          },
        }

        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: Math.abs(currentItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    }
  } else {
    if (!input || typeof input !== "object") {
      console.error("Expected input to be an object but received:", input)
      return []
    }
    if (viewBy === "Day") {
      input?.reportDataCurrent?.forEach((currentItem, idx) => {
        let previousItem = input?.reportDataPrevious[idx] || {}

        let eachElement = {
          idMonth:
            chart === "left"
              ? dayjs(currentItem.idMonth).format("MM-DD-YYYY") || 0
              : currentItem.idMonth || 0,
          [chart === "left" ? "orderCount" : "grossSale"]:
            chart === "left"
              ? parseFloat(currentItem["Order Count"])
              : parseFloat(currentItem["Gross Sales"]),
          tooltipData: {
            current: {},
            previous: {},
          },
        }

        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: Math.abs(currentItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: Math.abs(previousItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else if (
      viewBy === "Week" ||
      viewBy === "Month" ||
      viewBy === "Quarter" ||
      viewBy === "Year"
    ) {
      if (!input || typeof input !== "object") {
        console.error("Expected input to be an object but received:", input)
        return []
      }
      input?.reportDataCurrent?.forEach((currentItem, idx) => {
        let previousItem = input?.reportDataPrevious[idx] || {}

        let eachElement = {
          idMonth: currentItem.WeekNumber || 0,
          [chart === "left" ? "orderCount" : "grossSale"]:
            chart === "left"
              ? parseFloat(currentItem["Order Count"])
              : parseFloat(currentItem["Gross Sales"]),
          tooltipData: {
            current: {},
            previous: {},
          },
        }

        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: Math.abs(currentItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: Math.abs(previousItem[tooltipDataItem.name]),
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    }
  }

  return data
}

export function transformDataPDFExport(apiResponse, extractedParameters) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data =
    extractedParameters?.tooltip_data || tooltipDataFallbackConfig

  const dateFormat = getDateFormatFromLocalStorage() || "MM-DD-YYYY"

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        dayjs(current["idMonth"]).format(dateFormat),
    }

    // Ensure tooltip_data is defined and is an array
    if (Array.isArray(tooltip_data)) {
      tooltip_data.forEach((tooltip) => {
        const name = tooltip.name
        const currentVal = current[name] || 0

        if (Object.keys(previous).length > 0) {
          const previousVal = previous[name] || 0

          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
            previous:
              tooltip.format === "Currency"
                ? `$${Math.abs(previousVal).toLocaleString()}`
                : Math.abs(previousVal).toString(),

            changePercent:
              previousVal === 0
                ? "-"
                : (((currentVal - previousVal) / previousVal) * 100).toFixed(
                    2
                  ) + "%",
          }
        } else {
          transformedWeek[name] = {
            current:
              tooltip.format === "Currency"
                ? `$${currentVal.toLocaleString()}`
                : currentVal.toString(),
          }
        }
      })
    } else {
      console.warn("tooltip_data is missing or not an array")
    }

    transformedData.push(transformedWeek)
  }

  return transformedData
}



export const transformDataSheetExport = (dataArray) =>
  dataArray?.map((item) => {
    const { tooltipData, ...rest } = item // Separate tooltipData from other properties

    let flattenedTooltipData = {}

    // Flatten 'current' and 'previous' data in tooltipData
    if (tooltipData) {
      Object.entries(tooltipData).forEach(([key, value]) => {
        Object.entries(value).forEach(([subKey, subValue]) => {
          // Remove spaces from key names dynamically and format them as valid identifiers
          const newKey = `${key} ${subKey} `.replace(/\s+/g, "") // Remove all spaces from keys
          flattenedTooltipData[newKey] = subValue.value // Assign the value only
        })
      })
    }

    // Return the flattened structure
    return {
      ...rest, // Include original data without tooltipData
      ...flattenedTooltipData, // Include flattened tooltipData
    }
  })
