import { getFormattedValue } from "../../../../utils/utils"
import { tooltipDataFallbackConfig } from "../constant/fallbackConfigs"

const parseNumber = (value) => {
  if (typeof value === "number") return value
  if (typeof value === "string")
    return parseFloat(value.replace(/[^0-9.]/g, "")) || 0
  return 0
}

export const transfromChartData = (input = [{}], tooltip_data) => {
  const reportDataCurrent = input?.reportDataCurrent || input
  const reportDataPrevious = input?.reportDataPrevious

  if (!reportDataCurrent) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  reportDataCurrent.forEach((currentItem) => {
    const previousItem =
      reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

    let eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]),
        format: tooltipDataItem.format,
      }

      if (reportDataPrevious) {
        eachElement.tooltipData.previous =
          eachElement.tooltipData.previous || {}
        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: parseNumber(previousItem[tooltipDataItem.name]),
          format: tooltipDataItem.format,
        }
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformDataComparedBy = (input, tooltip_data) => {
  if (!input) {
    console.error("Invalid input data structure:", input)
    return []
  }

  const data = input.map((currentItem) => {
    const eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      if (currentItem[tooltipDataItem.name] !== undefined) {
        const value = parseNumber(currentItem[tooltipDataItem.name])
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: value,
          format: tooltipDataItem.format,
        }
      } else {
        console.warn(
          `Field ${tooltipDataItem.name} not found in currentItem:`,
          currentItem
        )
      }
    })

    return eachElement
  })

  return data
}

export const transformDataPDFExport = (apiResponse) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse || []
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const tooltip_data = tooltipDataFallbackConfig

  if (previousData.length === 0) {
    currentData.forEach((current) => {
      if (!current || !current.id) {
        console.warn("Invalid data structure in current data")
        return
      }

      const transformedWeek = {
        date: `${current.id}`,
      }

      tooltip_data.forEach((tooltip) => {
        const name = tooltip?.name
        if (!name) return

        const format = tooltip?.format
        if (!format) {
          console.warn("Tooltip data is missing or improperly structured")
          return
        }

        const currentVal = current[name] ?? 0

        transformedWeek[name] = {
          current:
            format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      })

      transformedData.push(transformedWeek)
    })
    return transformedData
  }

  currentData.forEach((current) => {
    if (!current || !current.id) {
      console.warn("Invalid data structure in current data")
      return
    }

    const previous = previousData.find((p) => p?.id === current.id) || {}

    const transformedWeek = {
      date: `${current.id}`,
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  })

  return transformedData
}

export const transformDataSheetExport = (data) => {
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          const format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )
          if (value.previous && value.previous[subKey]) {
            flatItem[`previous ${subKey}`] = getFormattedValue(
              value.previous[subKey]?.value,
              format
            )
          }
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        flatItem[key] = value
      }
    })

    return flatItem
  })
}
