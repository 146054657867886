import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchData, getMaxValue } from "./services/dataService"
import {
  transformChartData,
  transformDataPDFExport,
  transformDataSheetExport,
} from "./services/tansformService"
import Stack from "@mui/material/Stack"
import { generateCacheKey } from "../../../utils/utils"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import HeaderSection from "./components/widget/HeaderSection"
import MacroMetricsSection from "./components/widget/MacroMetricsSection"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import { useTheme } from "../../../contexts/theme"
import {
  tooltipDataFallbackConfig,
  chartColorsFallbackConfig,
  PDFFieldsFallbackConfig,
  macroMetricsFallbackConfig,
  getCustomTheme,
} from "./constants/fallbackConfigs"
import Message from "../../Message"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils"

const LineBarContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
  }) => {
    const { theme } = useTheme()
    const dispatch = useDispatch()
    const chartRef = useRef(null)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    let transformedConfigs = extractKeyValuePairs(configurations)

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // Override / calculated configs
    const configOverrides = [
      { key: "chart_colors", fallback: chartColorsFallbackConfig },
      { key: "macro-metrics", fallback: macroMetricsFallbackConfig(reportUid) },
      { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
      {
        key: "customTheme",
        fallback: getCustomTheme(theme.palette.mode),
      },
    ]

    configOverrides.forEach(({ key, fallback }) => {
      transformedConfigs[key] = transformedConfigs[key] || fallback
    })

    const legendData = () => {
      const colors = transformedConfigs.chart_colors

      return comparisonPeriod.selectedValue === "Compared By"
        ? [
            {
              label: "Current",
              color: colors.bar?.[theme.palette.mode || "dark"],
            },
          ]
        : [
            {
              label: "Current",
              color: colors.bar?.[theme.palette.mode || "dark"],
            },
            {
              label: "Previous",
              color: colors.line?.[theme.palette.mode || "dark"],
            },
          ]
    }

    const highestDataPoint = getMaxValue(reportState.transformedChartData)

    const daysInRange = dateRangeData
      ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
      : 0

    const visibleViewByOptions =
      rangeOptions.find(
        (range) => daysInRange >= range.min && daysInRange <= range.max
      )?.options || []

    const defaultViewByOption = getDefaultOption(daysInRange)

    const viewBy = visibleViewByOptions.includes(reportState.viewBy)
      ? reportState.viewBy
      : defaultViewByOption

    useEffect(() => {
      if (reportState.cacheKey === cacheKey) return
      const isComparedBy = comparisonPeriod.selectedValue === "Compared By"
      const payload = {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: comparisonPeriod.previousFromDate,
          previousToDate: comparisonPeriod.previousToDate,
        },
      }
      if (!!transformedConfigs.view_by_period) {
        payload.viewBy = viewBy
      }
      setLoading(true)
      fetchData(postUrl, payload)
        .then((apiResponse) => {
          dispatch(
            setChartData({
              reportUid,
              response: apiResponse,
              apiResponse,
              transformedChartData: transformChartData(
                apiResponse,
                isComparedBy,
                viewBy,
                transformedConfigs
              ),
              viewBy,
              cacheKey,
            })
          )
          setLoading(false)
        })
        .catch((err) => {
          console.log("LineBarContainer API Error", err)
          setError("Something went wrong while fetching the data...")
          setLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.cacheKey,
      reportState.viewBy,
    ])

    return (
      <Stack gap={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs.pdfFields}
          pdfTransformFunction={transformDataPDFExport}
          transformDataSheetExport={transformDataSheetExport}
          visibleViewByOptions={visibleViewByOptions}
          defaultViewByOption={defaultViewByOption}
        />

        {loading ? (
          <Message type="loading" />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <>
            <MacroMetricsSection
              config={transformedConfigs["macro-metrics"]}
              data={
                comparisonPeriod.selectedValue === "Compared By"
                  ? reportState?.apiResponse
                  : reportState?.apiResponse?.reportDataCurrent
              }
            />
            <BodySection
              config={transformedConfigs}
              data={reportState.transformedChartData}
              maxValue={highestDataPoint}
            />
            <FooterSection
              legendsData={legendData()}
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    )
  }
)

export default LineBarContainer
