import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const CustomLegend = ({ legendsData = [], alignment = "flex-end" }) => {
  return (
    <Box
      display="flex"
      justifyContent={alignment}
      rowGap={1}
      columnGap={2}
      flexWrap="wrap"
    >
      {legendsData.map((legend, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Box
            sx={{
              width: "12px",
              height: "12px",
              backgroundColor: legend.color || "grey",
              borderRadius: "50%",
            }}
          />
          <Typography
            variant="subtitle2"
            color="inherit"
            sx={{ fontSize: "0.8rem" }}
          >
            {legend.label || "N/A"}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default CustomLegend
