import dayjs from "dayjs"
import { getFormattedValue } from "../../../../utils/utils"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"

export const transformChartData = (
  apiResponse,
  isComparedBy,
  viewBy,
  config
) => {
  const dateFormat = getDateFormatFromLocalStorage()
  const data = []

  if (["Day", "", undefined].includes(viewBy)) {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let eachElement = {
          day: element["Day"] || 0,
          barValue: element[config.keys]?.toFixed(2) || 0,
          date: element["DayMonth"] || 0,
          tooltipData: {
            currentDate: dayjs(element["DayMonth"]).format(dateFormat),
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      const currentLength = apiResponse.reportDataCurrent?.length || 0
      const previousLength = apiResponse.reportDataPrevious?.length || 0

      const maxLength = Math.max(currentLength, previousLength)

      for (let index = 0; index < maxLength; index++) {
        let currentElement = apiResponse.reportDataCurrent[index] || {}
        let previousElement = apiResponse.reportDataPrevious[index] || {}

        let eachElement = {
          day: currentElement?.["Day"] || 0,
          date: currentElement?.["DayMonth"],
          barValue: currentElement?.[config.keys] || 0,
          lineValue: previousElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: dayjs(currentElement?.["DayMonth"]).format(dateFormat),
            previousDate: dayjs(previousElement?.["DayMonth"]).format(
              dateFormat
            ),
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      }
    }
  } else if (viewBy === "Week") {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let startOfWeek = dayjs(
          element["WeekMonthYear"]?.split(" to ")[0]
        )?.format(dateFormat)
        let endOfWeek = dayjs(
          element["WeekMonthYear"]?.split(" to ")[1]
        )?.format(dateFormat)

        let eachElement = {
          day: `${startOfWeek} to ${endOfWeek}` || 0,
          barValue: element[config.keys]?.toFixed(2) || 0,
          date: element["WeekMonthYear"] || 0,
          tooltipData: {
            currentDate: `${startOfWeek} to ${endOfWeek}`,
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      const currentLength = apiResponse.reportDataCurrent?.length || 0
      const previousLength = apiResponse.reportDataPrevious?.length || 0

      const maxLength = Math.max(currentLength, previousLength)

      for (let index = 0; index < maxLength; index++) {
        let currentElement = apiResponse.reportDataCurrent[index] || {}
        let previousElement = apiResponse.reportDataPrevious[index] || {}

        let currentStart = dayjs(
          currentElement?.["WeekMonthYear"]?.split(" to ")[0]
        )?.format(dateFormat)
        let currentEnd = dayjs(
          currentElement?.["WeekMonthYear"]?.split(" to ")[1]
        )?.format(dateFormat)

        let previousStart = dayjs(
          previousElement?.["WeekMonthYear"]?.split(" to ")[0]
        )?.format(dateFormat)
        let previousEnd = dayjs(
          previousElement?.["WeekMonthYear"]?.split(" to ")[1]
        )?.format(dateFormat)

        let eachElement = {
          day: currentElement?.["WeekMonthYear1"] || 0,
          starDay: `${currentStart} to ${currentEnd}` || 0,
          currentDay: previousElement?.["WeekMonthYear"],
          barValue: currentElement?.[config.keys] || 0,
          lineValue: previousElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: `${currentStart} to ${currentEnd}`,
            previousDate: `${previousStart} to ${previousEnd}`,
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      }
    }
  } else if (viewBy === "Month") {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let eachElement = {
          day: element["MonthYear"] || 0,
          barValue: element[config.keys]?.toFixed(2) || 0,
          date: element["MonthYear"] || 0,
          tooltipData: {
            currentDate: dayjs(element["MonthYear"]).format(dateFormat),
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      const currentLength = apiResponse.reportDataCurrent?.length || 0
      const previousLength = apiResponse.reportDataPrevious?.length || 0

      const maxLength = Math.max(currentLength, previousLength)

      for (let index = 0; index < maxLength; index++) {
        let currentElement = apiResponse.reportDataCurrent[index] || {}
        let previousElement = apiResponse.reportDataPrevious[index] || {}

        let eachElement = {
          day: currentElement?.["MonthYear"] || 0,
          currentDay: previousElement?.["MonthYear"],
          barValue: currentElement?.[config.keys] || 0,
          lineValue: previousElement[config.keys] || 0,
          tooltipData: {
            currentDate: dayjs(currentElement?.["MonthYear"]).format(
              dateFormat
            ),
            previousDate: dayjs(previousElement?.["MonthYear"]).format(
              dateFormat
            ),
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      }
    }
  } else if (viewBy === "Quarter") {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let eachElement = {
          day: element["QuarterYear"] || 0,
          barValue: element[config.keys]?.toFixed(2) || 0,
          date: element["QuarterYear"] || 0,
          tooltipData: {
            currentDate: element["QuarterYear"],
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      const currentLength = apiResponse.reportDataCurrent?.length || 0
      const previousLength = apiResponse.reportDataPrevious?.length || 0

      const maxLength = Math.max(currentLength, previousLength)

      for (let index = 0; index < maxLength; index++) {
        let currentElement = apiResponse.reportDataCurrent[index] || {}
        let previousElement = apiResponse.reportDataPrevious[index] || {}

        let eachElement = {
          day: previousElement?.["QuarterYear"] || 0,
          date: previousElement?.["QuarterYear"] || 0,
          barValue: previousElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: currentElement?.["QuarterYear"],
            previousDate: previousElement["QuarterYear"],
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)

        let eachCurrentElement = {
          day: currentElement?.["QuarterYear"] || 0,
          date: currentElement?.["QuarterYear"] || 0,
          barValue: currentElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: currentElement?.["QuarterYear"],
            previousDate: previousElement?.["QuarterYear"],
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachCurrentElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachCurrentElement)
      }
    }
  } else if (viewBy === "Year") {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let eachElement = {
          day: element["Year"] || 0,
          barValue: element[config.keys] || 0,
          date: element["Year"] || 0,
          tooltipData: {
            currentDate: element["Year"],
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      const currentLength = apiResponse.reportDataCurrent?.length || 0
      const previousLength = apiResponse.reportDataPrevious?.length || 0

      const maxLength = Math.max(currentLength, previousLength)

      for (let index = 0; index < maxLength; index++) {
        let currentElement = apiResponse.reportDataCurrent[index] || {}
        let previousElement = apiResponse.reportDataPrevious[index] || {}

        let eachElement = {
          day: previousElement?.["Year"] || 0,
          date: previousElement?.["Year"] || 0,
          barValue: previousElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: currentElement?.["Year"],
            previousDate: previousElement?.["Year"],
            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)

        let eachCurrentElement = {
          day: currentElement?.["Year"] || 0,
          date: apiResponse?.reportDataCurrent[index]?.["Year"] || 0,
          barValue: currentElement?.[config.keys] || 0,
          tooltipData: {
            currentDate: currentElement?.["Year"],
            previousDate: previousElement?.["Year"],

            current: {},
            previous: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachCurrentElement.tooltipData.previous[tooltipDataItem.name] = {
            value: previousElement?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachCurrentElement)
      }
    }
  }

  return data
}

export const transformDataPDFExport = (apiResponse, pdfFields) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const dateFormat = localStorage.getItem("dateFormat") || "MM-DD-YYYY"

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        dayjs(current["DayMonth"]).format(dateFormat),
    }

    pdfFields.forEach((pdfField) => {
      const name = pdfField.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            pdfField.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? pdfField.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : pdfField.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            pdfField.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const transformDataSheetExport = (data) => {
  // IMP-NOTE: It takes the output of transformChartData and not the raw API Data
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          const format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )

          if (value.previous && value.previous[subKey]) {
            flatItem[`previous ${subKey}`] = getFormattedValue(
              value.previous[subKey]?.value,
              format
            )
          }
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        flatItem[key] = value
      }
    })

    return flatItem
  })
}
