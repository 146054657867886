import App, { ProtectedRoute } from "./App"
import RouteLoader from "./components/loader/RouteLoader.jsx"
import AuthLayout from "./routes/authentication/AuthLayout"
import NotFound from "./routes/NotFound"
import { lazy, Suspense } from "react"

// Lazy load all components
const Overview = lazy(() => import("./routes/overview/Overview"))
const Sales = lazy(() => import("./routes/sales/Sales"))
const BusinessSynopsis = lazy(() =>
  import("./routes/businesssynopsis/BusinessSynopsis")
)
const BusinessSynopsisFallback = lazy(() =>
  import("./routes/businesssynopsis/BusinessSynopsisFallback")
)
const Customers = lazy(() => import("./routes/customers/Customers"))
const CustomersList = lazy(() => import("./routes/customers/CustomersList"))
const CustomerDetails = lazy(() => import("./routes/customers/CustomerDetails"))
const CustomerSentiment = lazy(() =>
  import("./routes/customers/CustomerSentiment")
)

const Vendors = lazy(() => import("./routes/vendors/Vendors"))
const VendorDetails = lazy(() => import("./routes/vendors/VendorDetails"))
const VendorsList = lazy(() => import("./routes/vendors/VendorsList"))
const Inventory = lazy(() => import("./routes/inventory/Inventory"))
const InventoryList = lazy(() => import("./routes/inventory/InventoryList"))
const InventoryDetails = lazy(() =>
  import("./routes/inventory/inventoryDetails/InventoryDetails")
)
const Employees = lazy(() => import("./routes/employees/Employees"))
const EmployeesList = lazy(() => import("./routes/employees/EmployeesList"))
const EmployeeDetails = lazy(() =>
  import("./routes/employees/employeeDetails/EmployeeDetails")
)
const Management = lazy(() => import("./routes/management/Management"))
const UserDetails = lazy(() =>
  import("./routes/management/userManagement/userDetails/UserDetails")
)
const CreateUser = lazy(() =>
  import("./routes/management/userManagement/CreateUser")
)
const EditUser = lazy(() =>
  import("./routes/management/userManagement/EditUser")
)
const GroupDetails = lazy(() =>
  import("./routes/management/groupManagement/groupDetails/GroupDetails")
)
const CreateGroup = lazy(() =>
  import("./routes/management/groupManagement/CreateGroup")
)
const Configuration = lazy(() => import("./routes/configuration/Configuration"))
const Setting = lazy(() =>
  import("../src/components/header/primary/profile/Setting")
)
const Subscription = lazy(() =>
  import(
    "../src/components/header/primary/profile/subscriptionPlan/Subscription"
  )
)
const PaymentSuccess = lazy(() =>
  import(
    "../src/components/header/primary/profile/subscriptionPlan/PaymentSuccess.jsx"
  )
)
const PaymentFailure = lazy(() =>
  import(
    "../src/components/header/primary/profile/subscriptionPlan/PaymentFailure.jsx"
  )
)
const AllPackages = lazy(() =>
  import(
    "../src/components/header/primary/profile/subscriptionPlan/AllPackages"
  )
)
const InvoicesTable = lazy(() =>
  import(
    "../src/components/header/primary/profile/subscriptionPlan/InvoicesTable"
  )
)
const Integration = lazy(() => import("./routes/integration/Integration"))
const UserProfileDetails = lazy(() =>
  import("../src/components/header/primary/profile/UserProfileDetails")
)
const Marketing = lazy(() => import("./routes/marketingportal/Marketing"))
const Summary = lazy(() =>
  import("./routes/marketingportal/marketingMenus/Summary.jsx")
)
const TargetAudience = lazy(() =>
  import("./routes/marketingportal/marketingMenus/TargetAudience.jsx")
)
const ViewCampaigns = lazy(() =>
  import("./routes/marketingportal/marketingMenus/Campaigns/ViewCampaigns.jsx")
)
const ViewTemplates = lazy(() =>
  import("./routes/marketingportal/marketingMenus/Campaigns/ViewTemplates.jsx")
)
const CreateCampaignNew = lazy(() =>
  import("./routes/marketingportal/CreateCampaignNew.jsx")
)
const CampaignDetails = lazy(() =>
  import("./routes/marketingportal/CampaignDetails")
)
const CampaignCustomerDetails = lazy(() =>
  import("./routes/marketingportal/CampaignCustomerDetails")
)
const Configurations = lazy(() =>
  import(
    "./routes/marketingportal/marketingMenus/configurations/Configuration.jsx"
  )
)
const ConfigurationAuth = lazy(() =>
  import("./routes/authentication/configAuth/ConfigurationAuth.jsx")
)
const CreateAudience = lazy(() =>
  import("./routes/marketingportal/marketingMenus/audience/CreateAudience.jsx")
)
const AudienceList = lazy(() =>
  import("./routes/marketingportal/marketingMenus/audience/AudienceList.jsx")
)
const EditAudienceDetails = lazy(() =>
  import(
    "./routes/marketingportal/marketingMenus/audience/EditAudienceDetails.jsx"
  )
)
const EditTemplates = lazy(() =>
  import(
    "./routes/marketingportal/marketingMenus/Campaigns/createEditTemplates/EditTemplates.jsx"
  )
)
const CreateTemplates = lazy(() =>
  import(
    "./routes/marketingportal/marketingMenus/Campaigns/createEditTemplates/CreateTemplates.jsx"
  )
)
const Login = lazy(() => import("./routes/authentication/Login"))
const Registration = lazy(() => import("./routes/authentication/Registration"))
const ForgotPassword = lazy(() =>
  import("./routes/authentication/ForgotPassword")
)
const NewPassword = lazy(() => import("./routes/authentication/NewPassword"))
const VerificationCode = lazy(() =>
  import("./routes/authentication/VerificationCode")
)
const WelcomeOvvi = lazy(() => import("./routes/authentication/WelcomeOvvi"))
const SelectPOSPlatform = lazy(() =>
  import("./routes/authentication/SelectPOSPlatform")
)

const Routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/overview",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Overview />
          </Suspense>
        ),
      },
      {
        path: "/sales",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Sales />
          </Suspense>
        ),
      },
      {
        path: "/businesssynopsis",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <BusinessSynopsis />
          </Suspense>
        ),
      },
      {
        path: "/businesssynopsis/in-process",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <BusinessSynopsisFallback />
          </Suspense>
        ),
      },
      {
        path: "/customers/dashboard",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Customers />
          </Suspense>
        ),
      },
      {
        path: "/customers/allcustomers",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CustomersList />
          </Suspense>
        ),
      },
      {
        path: "/customers/dashboard/customerSentiment",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CustomerSentiment />
          </Suspense>
        ),
      },
      {
        path: "/customers/allcustomers/customer/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CustomerDetails />
          </Suspense>
        ),
      },
      {
        path: "/vendors/dashboard",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Vendors />
          </Suspense>
        ),
      },
      {
        path: "/vendors/allvendors/vendor/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <VendorDetails />
          </Suspense>
        ),
      },
      {
        path: "/vendors/allvendors",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <VendorsList />
          </Suspense>
        ),
      },
      {
        path: "/products/dashboard",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Inventory />
          </Suspense>
        ),
      },
      {
        path: "/products/products",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <InventoryList />
          </Suspense>
        ),
      },
      {
        path: "/products/allproducts/product/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <InventoryDetails />
          </Suspense>
        ),
      },
      {
        path: "/employees/dashboard",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Employees />
          </Suspense>
        ),
      },
      {
        path: "/employees/allemployees",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <EmployeesList />
          </Suspense>
        ),
      },
      {
        path: "/employees/allemployees/employee/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <EmployeeDetails />
          </Suspense>
        ),
      },
      {
        path: "/management",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Management />
          </Suspense>
        ),
      },
      {
        path: "/management/users/user/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <UserDetails />
          </Suspense>
        ),
      },
      {
        path: "/management/users/create",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateUser />
          </Suspense>
        ),
      },
      {
        path: "/management/users/edit",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <EditUser />
          </Suspense>
        ),
      },
      {
        path: "/management/groups/group/:id",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <GroupDetails />
          </Suspense>
        ),
      },
      {
        path: "/management/groups/create",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateGroup />
          </Suspense>
        ),
      },
      {
        path: "/management/groups/edit",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateGroup />
          </Suspense>
        ),
      },
      {
        path: "/configuration",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Configuration />
          </Suspense>
        ),
      },
      {
        path: "/setting",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Setting />
          </Suspense>
        ),
      },
      {
        path: "/subscriptionPlan",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Subscription />
          </Suspense>
        ),
      },
      {
        path: "/subscriptionPlan/success",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <PaymentSuccess />
          </Suspense>
        ),
      },
      {
        path: "/subscriptionPlan/failure",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <PaymentFailure />
          </Suspense>
        ),
      },
      {
        path: "/subscription/packages",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <AllPackages />
          </Suspense>
        ),
      },
      {
        path: "/subscription/invoices",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <InvoicesTable />
          </Suspense>
        ),
      },
      {
        path: "/integration",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Integration />
          </Suspense>
        ),
      },
      {
        path: "/profile",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <UserProfileDetails />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Marketing />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/summary",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Summary />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/targetAudience",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <TargetAudience />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/viewCampaigns",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <ViewCampaigns />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/viewTemplates",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <ViewTemplates />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/audienceList",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <AudienceList />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/createAudience",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateAudience />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/createAudience/EditAudienceDetails",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <EditAudienceDetails />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/viewTemplates/EditTemplates",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <EditTemplates />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/viewTemplates/CreateTemplates",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateTemplates />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/createcampaign",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CreateCampaignNew />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/campaign/1",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CampaignDetails />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/campaigncustomerdetails/1",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <CampaignCustomerDetails />
          </Suspense>
        ),
      },
      {
        path: "/submenu/marketing/Configuration",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Configurations />
          </Suspense>
        ),
      },
      {
        path: "/ConfigurationAuth",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <ConfigurationAuth />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "login",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: "/welcome",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <WelcomeOvvi />
          </Suspense>
        ),
      },
      {
        path: "/select-pos-platform",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <SelectPOSPlatform />
          </Suspense>
        ),
      },
      {
        path: "registration",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <Registration />
          </Suspense>
        ),
      },
      {
        path: "forgotpassword",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "newpassword",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <NewPassword />
          </Suspense>
        ),
      },
      {
        path: "verificationCode",
        element: (
          <Suspense fallback={<RouteLoader />}>
            <VerificationCode />
          </Suspense>
        ),
      },
    ],
  },
]

export default Routes
