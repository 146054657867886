import { colorScheme } from "../constant/fallbackConfigs"

export const calculateSalesPercentage = (apiResponse) => {
  let newId = ""
  let repeatId = ""
  let newPercentageChange = "N/A"
  let repeatPercentageChange = "N/A"

  apiResponse.forEach((item) => {
    const currentGrossSales =
      item.tooltipData?.current?.["Gross Sales"]?.value ?? null
    const previousGrossSales =
      item.tooltipData?.previous?.["Gross Sales"]?.value ?? null

    if (currentGrossSales !== null && previousGrossSales !== null) {
      let percentageChange = 0

      if (previousGrossSales === 0) {
        percentageChange = currentGrossSales * 100
      } else {
        percentageChange =
          ((currentGrossSales - previousGrossSales) / previousGrossSales) * 100
      }

      if (item.id === "New") {
        newId = item.id
        newPercentageChange = `${
          percentageChange > 0 ? "+" : ""
        }${percentageChange.toFixed(2)}%`
      } else if (item.id === "Repeat") {
        repeatId = item.id
        repeatPercentageChange = `${
          percentageChange > 0 ? "+" : ""
        }${percentageChange.toFixed(2)}%`
      }
    }
  })

  return { newId, newPercentageChange, repeatId, repeatPercentageChange }
}

export const convertValues = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      convertValues(obj[key])
    } else if (typeof obj[key] === "string") {
      if (!isNaN(obj[key])) {
        obj[key] = Number(obj[key])
      } else if (obj[key].toLowerCase() === "true") {
        obj[key] = true
      } else if (obj[key].toLowerCase() === "false") {
        obj[key] = false
      }
    }
  }
  return obj
}

export const getlegendsData = (data) => {
  if (!data) return []
  const seenNames = new Set()

  if (data && Array.isArray(data)) {
    return data
      .filter((item) => {
        const name = item?.EmployeeName || item?.CustomerProfiles
        if (!name || seenNames.has(name)) {
          return false
        }
        seenNames.add(name)
        return true
      })
      .map((item, idx) => ({
        label: item.EmployeeName || item.CustomerProfiles,
        color: colorScheme[idx],
      }))
  }

  return []
}
