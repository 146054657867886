import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import InfoToolTip from "../../InfoToolTip"
import { useTheme } from "../../../contexts/theme"
import { WordCloudData } from "../../../constants/chartsData"
import WordCloudChartPresentation from "./WordCloudChartPresentation"

const WordCloudChartContainer = ({ title, description }) => {
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
            </span>
          </InfoToolTip>
        </Box>
      </Box>

      <Box minHeight="350px">
        <WordCloudChartPresentation chartInput={WordCloudData} />
      </Box>
    </>
  )
}

export default WordCloudChartContainer
