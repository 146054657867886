export function calculateSalesSums(response) {
  let actualSalesSum = 0
  let forecastSalesSum = 0

  const actualData = response.find((item) => item.id === "Actual").data
  const forecastData = response.find((item) => item.id === "Forecast").data

  actualData.forEach((item) => {
    actualSalesSum += item.Sales
  })

  forecastData.forEach((item) => {
    forecastSalesSum += item.Sales
  })

  return {
    actualSalesSum,
    forecastSalesSum,
  }
}
