export const tooltipDataFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const macroMetricsFallbackConfig = (reportUid) => [
  ["16B1F146-AE15-4622-8D58-FEEF9B8AEEF0"].includes(reportUid)
    ? null
    : {
        icon: "VerifiedIcon",
        name: "highest",
        helpText: "Highest datapoint",
        firstRow: { value: "", operation: "aggregate" },
        secondRow: { value: "{id} at {x}", operation: "templateString" },
        thirdRow: { value: "Star Performer", operation: null },
        aggregateFunc: "MAX",
        targetField: "y",
        format: "Number",
        color: "#23BF6F",
      },
  {
    icon: "NewReleasesIcon",
    name: "lowest",
    helpText: "Lowest datapoint",
    firstRow: { value: "", operation: "aggregate" },
    secondRow: {
      value: "{id} at {x}",
      operation: "templateString",
    },
    thirdRow: { value: "Low Performer", operation: null },
    aggregateFunc: "MIN",
    targetField: "y",
    format: "Number",
    color: "#EC2D30",
  },
]

export const defaultColor = {
  type: "diverging",
  scheme: "blues",
  divergeAt: 0.75,
}
export const customerDefaultColor = {
  type: "diverging",
  scheme: "purples",
  divergeAt: 0.75,
}
export const ProductDefaultColor = {
  type: "diverging",
  scheme: "oranges",
  divergeAt: 0.75,
}
