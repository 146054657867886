import React, { useState, useEffect, useRef, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { fetchData } from "./services/dataServices"
import { generateCacheKey } from "../../../utils/utils"
import {
  chartContainerDimentionsFallbackConfig,
  colorScheme,
  CustomerGroupBarLegendLabel,
  defaultGroupBarLegendLabel,
  PDFFieldsFallbackConfig,
  ProductGroupBarLegendLabel,
  tooltip_dataFallBackProduct,
  tooltipDataFallbackConfig,
  tooltipDataFallbackConfigCustomers,
} from "./constants/fallbackConfigs"

import Loading from "./components/Loading"
import MacroMetricsSection from "./components/widget/MacroMetricsSection"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import HeaderSection from "./components/widget/HeaderSection"
import { Stack } from "@mui/material"
import {
  transformDataPDFExport,
  transformDataProductName,
  transformDataSheetExport,
  transfromChartData,
} from "./services/transformServices"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils"

const GroupBarContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
}) => {
  const transformedConfigs = extractKeyValuePairs(configurations)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const chartRef = useRef(null)

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.viewBy
  )

  const configOverrides = [
    {
      key: "chartContainerDimensions",
      fallback: chartContainerDimentionsFallbackConfig,
    },
    // { key: "tooltip_data", fallback: tooltipDataFallbackConfig },

    {
      key: "tooltip_data",
      fallback: (() => {
        switch (reportUid) {
          case "8D69C58A-3051-4701-968D-07C8CA186C12":
            return tooltip_dataFallBackProduct
          case "67187936-81CE-44B0-9416-5D52B308EE84":
            return tooltipDataFallbackConfigCustomers
          default:
            return tooltipDataFallbackConfig
        }
      })(),
    },
    // TODO: comment this out, as each report has different fallback
    // { key: "macro-metrics", fallback: macroMetricsFallbackConfig },
    { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
    {
      key: "showTooltipPrevious",
      fallback:
        transformedConfigs.showTooltipPrevious === undefined
          ? true
          : transformedConfigs.showTooltipPrevious,
    },
    {
      key: "defaultGroupBarLegendLabel",
      fallback:
        {
          "4C54CC4F-8711-4198-AAD5-FA8D3537C6DB": ProductGroupBarLegendLabel,
          "8D69C58A-3051-4701-968D-07C8CA186C12": ProductGroupBarLegendLabel,
          "67187936-81CE-44B0-9416-5D52B308EE84": CustomerGroupBarLegendLabel,
        }[reportUid] || defaultGroupBarLegendLabel,
    },
    {
      key: "showLegend",
      fallback: {
        "4C54CC4F-8711-4198-AAD5-FA8D3537C6DB": false,
      }[reportUid],
    },
  ]

  configOverrides.forEach(({ key, fallback }) => {
    transformedConfigs[key] = transformedConfigs[key] || fallback
  })

  const keys = useMemo(() => {
    const allKeys = new Set()
    reportState?.transformedChartData?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (key !== "day" && key !== "tooltipFields") {
          allKeys.add(key)
        }
      })
    })

    return Array.from(allKeys)
  }, [reportState?.transformedChartData])

  const legendsData = useMemo(() => {
    if (!transformedConfigs.showLegend) return []

    return (
      keys?.map((item, index) => ({
        label: item,
        color: colorScheme[index % colorScheme.length],
      })) || []
    )
  }, [keys, transformedConfigs.showLegend])

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const visibleViewByOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  const defaultViewByOption = getDefaultOption(daysInRange)

  const viewBy = visibleViewByOptions.includes(reportState.viewBy)
    ? reportState.viewBy
    : defaultViewByOption

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) return
    const payload = {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: dateRangeData.fromDate,
      toDate: dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: comparisonPeriod.previousFromDate,
        previousToDate: comparisonPeriod.previousToDate,
      },
    }
    if (!!transformedConfigs.view_by_period) {
      payload.viewBy = viewBy
    }
    setLoading(true)
    fetchData(postUrl, payload)
      .then((apiResponse) => {
        const transData =
          reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
            ? transformDataProductName(
                apiResponse,
                transformedConfigs.tooltip_data,
                reportUid
              )
            : transfromChartData(
                apiResponse,
                transformedConfigs.tooltip_data,
                reportUid
              )
        dispatch(
          setChartData({
            reportUid,
            response: apiResponse,
            apiResponse,
            transformedChartData: transData,
            viewBy,
            cacheKey,
          })
        )
        setLoading(false)
      })
      .catch((err) => {
        console.warn(err)
        setError("Failed to fetch data")
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.cacheKey,
    reportState.viewBy,
  ])

  return (
    <Stack gap={1} ref={chartRef}>
      <HeaderSection
        route={route}
        reportUid={reportUid}
        title={title}
        description={description}
        setChartData={setChartData}
        children={null}
        config={transformedConfigs}
        reportStateViewBy={reportState.viewBy}
        dateRangeData={dateRangeData}
        parentLoading={loading}
        parentError={error}
        transformedChartData={reportState.transformedChartData}
        apiResponse={reportState.apiResponse}
        chartRef={chartRef}
        pdfFields={transformedConfigs}
        pdfTransformFunction={transformDataPDFExport}
        transformDataSheetExport={transformDataSheetExport}
        visibleViewByOptions={visibleViewByOptions}
        defaultViewByOption={defaultViewByOption}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <MacroMetricsSection
            config={transformedConfigs["macro-metrics"]}
            data={
              comparisonPeriod.selectedValue === "Compared By"
                ? reportState?.apiResponse
                : reportState?.apiResponse?.reportDataCurrent
            }
          />
          <BodySection
            config={transformedConfigs}
            reportUid={reportUid}
            data={reportState.transformedChartData}
          />
          <FooterSection
            legendsData={legendsData}
            title={title}
            apiResponse={reportState.apiResponse || []}
          />
        </>
      )}
    </Stack>
  )
}

export default GroupBarContainer
