import { ResponsivePie } from "@nivo/pie"
import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { colorScheme } from "../../constant/fallbackConfigs"
import { useTheme } from "../../../../../contexts/theme"
import CustomLegendTable from "../CustomLegendTable"

const customTheme = {
  tooltip: {
    container: {
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
    },
  },
}

const BodySection = ({
  data,
  config,
  title,
  transformedConfigs,
  TableLegendData,
}) => {
  let {
    startAngle,
    innerRadius,
    padAngle,
    borderWidth,
    borderColor,
    arcLabelsRadiusOffset,
    arcLabelsSkipAngle,
    arcLabelsTextColor,
    animate,
    chartContainerDimensions = {
      height: "250px",
    },
  } = config
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const showPrevious =
    config.showPrevious === undefined ? true : config.showPrevious
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
        flexDirection="row"
        rowGap={1}
      >
        <Box display="flex" sx={{ ...chartContainerDimensions }} flex={1}>
          <ResponsivePie
            data={data}
            theme={customTheme}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            startAngle={startAngle}
            innerRadius={innerRadius}
            padAngle={padAngle}
            cornerRadius={0}
            activeInnerRadiusOffset={4}
            activeOuterRadiusOffset={5}
            colors={colorScheme}
            borderWidth={borderWidth}
            sortByValue={true}
            borderColor={borderColor}
            enableArcLinkLabels={false}
            arcLinkLabel="id"
            arcLinkLabelsTextOffset={9}
            arcLinkLabelsTextColor={itemTextColor}
            arcLinkLabelsOffset={-20}
            arcLinkLabelsDiagonalLength={25}
            arcLinkLabelsStraightLength={3}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsSkipAngle={0}
            enableArcLabels={false}
            arcLabelsRadiusOffset={arcLabelsRadiusOffset}
            arcLabelsSkipAngle={arcLabelsSkipAngle}
            arcLabelsTextColor={arcLabelsTextColor}
            animate={animate}
            tooltip={({ datum }) => (
              <CustomTooltip
                data={datum.data}
                showCurrentPrevDate={true}
                showLegendName={true}
                dataDatum={datum}
                showPrevious={showPrevious}
              />
            )}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          rowGap={{ md: "10px", lg: "5px" }}
          width="50%"
          flex={1}
        >
          <CustomLegendTable
            data={TableLegendData}
            config={transformedConfigs}
            colorScheme={colorScheme}
            title={title}
            showPrevious={showPrevious}
          />
        </Box>
      </Box>
    </>
  )
}

export default BodySection
