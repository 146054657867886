import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

const Loader = ({ color }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        background: "#00000025",
      }}
    >
      <CircularProgress disableShrink color={color} />
    </Box>
  )
}

export default Loader
