import { useState, useEffect, useRef } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CustomLegend from "../../CustomLegend"
import PieChartPresentation from "./PieChartPresentation"
import InfoToolTip from "../../InfoToolTip"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import { useTheme } from "../../../contexts/theme"
import { CircularProgress } from "@mui/material"
import CustomLegendTable from "./components/CustomLegendTable"
import { colorScheme } from "./constant/constants"
import { fetchData } from "./services/dataServices"
import { PieTooltipData } from "./constant/configFallback"
import { transformExportData } from "./services/transformServices"
import { generateCacheKey } from "../../../utils/utils"

const PieChartContainer = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showAiDropdown = true,
  setAnchorEl,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [reportData, setReportData] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "Test",
    Recommendations: "Test",
    Anomalies: "Test",
  })

  // 1. Fetch data from redux (would be minimal initially)
  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })

  // 2. Generate cacheKey
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const tooltip_data = extractedParameters.tooltip_data || PieTooltipData

  const showPrevious =
    extractedParameters.showPrevious === undefined
      ? true
      : extractedParameters.showPrevious

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      postUrl,
      reportUid,
      combinedState,
      setReportData,
      setApiResponse,
      setError,
      setChartData,
      tooltip_data,
      dispatch,
      comparisonPeriod,
      setLoading,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })
  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const svgColor = theme.palette.accentSecondary

  const legendsData =
    response && Array.isArray(response)
      ? [...response].map((item, idx) => ({
          label: item?.id,
          color: colorScheme[idx],
        }))
      : []

  const tableData = transformExportData(reduxApiResponse, extractedParameters)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box display="flex" flexDirection={"column"} gap={1}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap={"wrap"}
          gap={"10px"}
        >
          <Box display="flex" alignItems="center">
            <Typography color="inherit">{title}</Typography>
            <InfoToolTip title={description}>
              <span style={{ height: "16px", cursor: "pointer" }}>
                <InfoLogoSvg svgColor={svgColor} />
              </span>
            </InfoToolTip>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={"2px"}
          >
            {extractedParameters.view_by_period && (
              <ViewBy
                route={route}
                parameters={
                  extractedParameters && extractedParameters.view_by_period
                }
                viewBy={viewBy}
                setViewBy={setViewBy}
                reportUid={reportUid}
                dateRangeData={dateRangeData}
              />
            )}

            {!loading && (
              <ExportMenuButton
                apiResponse={apiResponse}
                title={title}
                setAiData={setAiData}
                setPdfOpen={setPdfOpen}
                setAnchorEl={setAnchorEl}
                tableData={tableData}
              />
            )}
          </Box>
        </Box>

        <Box
          ref={chartRef}
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          flexWrap="wrap"
          flexDirection="row"
          rowGap={1}
        >
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              gap="10px"
              minHeight="350px"
              width="100%"
            >
              <CircularProgress disableShrink size={45} color="info" />
              <Typography variant="body2" color="inherit">
                Fetching data, please wait...
              </Typography>
            </Box>
          ) : error ? (
            <>
              {response && (
                <PieChartPresentation
                  chartConfig={extractedParameters}
                  chartInput={response}
                  colorScheme={colorScheme}
                />
              )}
              <Box
                display="flex"
                flexDirection="column"
                rowGap={{ md: "10px", lg: "5px" }}
                width="100%"
                flex={1}
              >
                <CustomLegendTable
                  data={reduxApiResponse}
                  chartConfig={extractedParameters}
                  colorScheme={colorScheme}
                  title={title}
                  showPrevious={showPrevious}
                />
              </Box>
            </>
          ) : (
            <>
              {response && (
                <PieChartPresentation
                  chartConfig={extractedParameters}
                  chartInput={response}
                  colorScheme={colorScheme}
                />
              )}
              <Box
                display="flex"
                flexDirection="column"
                rowGap={{ md: "10px", lg: "5px" }}
                width="100%"
                flex={1}
              >
                <CustomLegendTable
                  data={reduxApiResponse}
                  chartConfig={extractedParameters}
                  colorScheme={colorScheme}
                  title={title}
                  showPrevious={showPrevious}
                />
              </Box>
              <Box width="100%">
                <CustomLegend
                  legendsData={legendsData}
                  alignment="flex-end"
                  reportTitle={title}
                  reportData={apiResponse}
                  showAiDropdown={showAiDropdown}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default PieChartContainer
