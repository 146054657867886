import { useEffect } from "react"
import Button from "@mui/material/Button"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useTheme } from "../../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPage } from "../../../../redux/slices/navigationSlice"

const Right = () => {
  const { theme } = useTheme()
  const location = useLocation()
  const activeButton = useSelector((state) => state.navigation.currentPage)
  const dispatch = useDispatch()

  const btnStyles = (activeButton, menuItem) => {
    const baseStyles = {
      textTransform: "none",
      borderRadius: "0px",
      color:
        activeButton === menuItem
          ? theme.palette.accent
          : theme.palette.mode === "dark"
          ? "#CCCCCC"
          : "#000",
    }

    if (activeButton === menuItem) {
      baseStyles.borderBottom = `1px solid ${theme.palette.accent}`
    }

    return baseStyles
  }

  const handleClick = (buttonName) => {
    dispatch(setCurrentPage(buttonName))
  }

  const isMarketingRoute = location.pathname.includes("submenu/marketing")

  useEffect(() => {
    const pathname = location.pathname.substring(1)
    dispatch(setCurrentPage(pathname))
  }, [location.pathname, dispatch])

  return (
    <>
      {/* <NavLink
        to="/management"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "inherit" : "#000",
          ...btnStyles(activeButton, "management"),
        }}
        onClick={() => handleClick("management")}
      >
        <Button
          className="navbar-item"
          startIcon={
            <svg
              width="22"
              height="22"
              viewBox=" 0 0 26 26"
              fill={theme.palette.mode === "dark" ? "white" : "black"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill={btnStyles(activeButton, "management").color}
                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3m-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3m0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5m8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5"
              ></path>
            </svg>
          }
          sx={btnStyles(activeButton, "management")}
          onClick={() => handleClick("management")}
        >
          Management
        </Button>
      </NavLink> */}

      {/* <NavLink
        to="/configuration"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "inherit" : "#000",
          ...btnStyles(activeButton, "configuration"),
        }}
        onClick={() => handleClick("configuration")}
      >
        <Button
          className="navbar-item"
          startIcon={
            <svg
              width="22"
              height="22"
              viewBox=" 0 0 26 26"
              fill={theme.palette.mode === "dark" ? "white" : "black"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill={btnStyles(activeButton, "configuration").color}
                d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6"
              ></path>
            </svg>
          }
          sx={btnStyles(activeButton, "configuration")}
          onClick={() => handleClick("configuration")}
        >
          Configuration
        </Button>
      </NavLink> */}

      {/* <NavLink
        to="/integration"
        style={{
          textDecoration: "none",
          color: theme.palette.mode === "dark" ? "inherit" : "#000",
          ...btnStyles(activeButton, "integration"),
        }}
        onClick={() => handleClick("integration")}
      >
        <Button
          className="navbar-item"
          startIcon={
            <svg
              width="22"
              height="22"
              viewBox=" 0 0 26 26"
              fill={theme.palette.mode === "dark" ? "white" : "black"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill={btnStyles(activeButton, "integration").color}
                d="M4 8h4V4H4zm6 12h4v-4h-4zm-6 0h4v-4H4zm0-6h4v-4H4zm6 0h4v-4h-4zm6-10v4h4V4zm-6 4h4V4h-4zm6 6h4v-4h-4zm0 6h4v-4h-4z"
              ></path>
            </svg>
          }
          sx={btnStyles(activeButton, "integration")}
          onClick={() => handleClick("integration")}
        >
          Integrations
        </Button>
      </NavLink> */}
    </>
  )
}

export default Right
