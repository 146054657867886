import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useTheme } from "../../contexts/theme"
import { useDispatch } from "react-redux"

const ViewBy = ({
  reportUid,
  reportStateViewBy,
  setChartData,
  visibleViewByOptions,
  defaultViewByOption,
}) => {
  const dispatch = useDispatch()

  const { theme } = useTheme()

  const handleChange = (event) => {
    const newValue = event.target.value

    dispatch(
      setChartData({
        reportUid,
        viewBy: visibleViewByOptions.includes(newValue)
          ? newValue
          : defaultViewByOption,
      })
    )
  }

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: "6.2rem",
      }}
    >
      <Select
        value={reportStateViewBy || defaultViewByOption}
        onChange={handleChange}
        disableUnderline={true}
        renderValue={(selected) => {
          if (!selected) {
            return "View By"
          }
          return selected
        }}
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#071339" : "#e7ecff",
          borderRadius: "10px",
          fontSize: "0.8rem",
          "& .MuiSelect-iconOpen, & .MuiSelect-icon": {
            color: theme.palette.accent,
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
      >
        <MenuItem disabled value={null} sx={{ fontSize: "0.8rem" }}>
          View By
        </MenuItem>
        {visibleViewByOptions.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ fontSize: "0.8rem" }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ViewBy
