export const tooltipDataFallbackConfig = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]
export const tooltipDataFallbackConfigCustmers = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]
export const PDFFieldsFallbackConfig = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimensionsFallBack = {
  height: "600px",
  minHeight: "350px",
  maxHeight: "unset",
}
export const defaultColor = ["#eeeeee", "#61cdbb", "#e8c1a0", "#f47560"]
export const defaultColorCustomers = [
  "#e0f7ff",
  "#b3eaff",
  "#80dcff",
  "#4dceff",
  "#1abfff",
  "#0099cc",
]

export const macroMetricsFallbackConfig = (reportUid) => [
  ["16B1F146-AE15-4622-8D58-FEEF9B8AEEF0"].includes(reportUid)
    ? null
    : {
        name: "highest",
        helpText: "Highest datapoint",
        firstRow: { value: "", operation: "aggregate" },
        secondRow: { value: "{day}", operation: "templateString" },
        thirdRow: { value: "Star Performer", operation: null },
        aggregateFunc: "MAX",
        targetField: "value",
        format: "Currency",
        color: "#23BF6F",
        icon: "VerifiedIcon",
      },
  {
    name: "lowest",
    helpText: "Lowest datapoint",
    firstRow: { value: "", operation: "aggregate" },
    secondRow: { value: "{day}", operation: "templateString" },
    thirdRow: { value: "Low Performer", operation: null },
    aggregateFunc: "MIN",
    targetField: "value",
    format: "Currency",
    color: "#EC2D30",
    icon: "NewReleasesIcon",
  },
]
