import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import { getFormattedValue } from "../../../../utils/utils"

export const transformChartData = (
  apiResponse,
  isComparedBy = true,
  transformedConfigs
) => {
  let transformedData = []
  if (isComparedBy) {
    apiResponse.forEach((item) => {
      let eachElement = {
        ...item,
        title: item?.[transformedConfigs.fieldMap.name],
        defaultValue: item?.[transformedConfigs.fieldMap.key],
        tooltipData: {
          current: {},
        },
      }

      if (
        transformedConfigs?.tooltip_data &&
        Array.isArray(transformedConfigs?.tooltip_data)
      ) {
        transformedConfigs?.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: item[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })
      }

      transformedData.push(eachElement)
    })
  } else {
    apiResponse.reportDataCurrent.forEach((item, index) => {
      let eachElement = {
        ...item,
        title: item?.[transformedConfigs.fieldMap.name],
        defaultValue: item?.[transformedConfigs.fieldMap.key],
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      if (
        transformedConfigs?.tooltip_data &&
        Array.isArray(transformedConfigs?.tooltip_data)
      ) {
        transformedConfigs?.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: item[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }
        })

        transformedConfigs?.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value:
              apiResponse.reportDataPrevious[index]?.[tooltipDataItem.name] ||
              0,
            format: tooltipDataItem.format,
          }
        })
      }

      transformedData.push(eachElement)
    })
  }

  return transformedData
}

export const transformDataPDFExport = (
  apiResponse,
  transformedConfigs = {}
) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = transformedConfigs.tooltip_data || []

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"] ||
            current["ProductName"] ||
            current["Name"] ||
            "N/A"),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name

      if (
        name === "idID" ||
        name === "CustomerID" ||
        name === "ProductName" ||
        name === "Phone" ||
        name === "CustomerType" ||
        name === "id" ||
        name === "Name"
      ) {
        return
      }

      const currentVal = current[name] !== undefined ? current[name] : 0

      const formattedCurrentVal =
        tooltip.format === "Currency"
          ? `$${Number(currentVal).toLocaleString()}`
          : String(currentVal)

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] !== undefined ? previous[name] : 0

        const formattedPreviousVal =
          previousVal > 0
            ? tooltip.format === "Currency"
              ? `$${Number(previousVal).toLocaleString()}`
              : String(previousVal)
            : tooltip.format === "Currency"
            ? "$0"
            : "0"

        transformedWeek[name] = {
          current: formattedCurrentVal,
          previous: formattedPreviousVal,
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current: formattedCurrentVal,
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const transformDataSheetExport = (data) => {
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          const format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )
          if (value.previous && value.previous[subKey]) {
            flatItem[`previous ${subKey}`] = getFormattedValue(
              value.previous[subKey]?.value,
              format
            )
          }
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        flatItem[key] = value
      }
    })

    return flatItem
  })
}
