export const RadarChartData = [
  {
    category: "Quality",
    "Team A": 75,

    tooltipData: {
      current: {
        "Team A": {
          value: 75,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 70,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Accessibility",
    "Team A": 90,

    tooltipData: {
      current: {
        "Team A": {
          value: 90,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Product Range",
    "Team A": 80,

    tooltipData: {
      current: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 78,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Healthy",
    "Team A": 85,

    tooltipData: {
      current: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Customer Satisfaction",
    "Team A": 88,

    tooltipData: {
      current: {
        "Team A": {
          value: 88,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Service",
    "Team A": 28,

    tooltipData: {
      current: {
        "Team A": {
          value: 28,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
]

export const RadarChartParameters = {
  keys: ["Team A"],
  indexBy: "category",
  maxValue: 100,
  margin: { top: 20, right: 50, bottom: 20, left: 50 },
  curve: "linearClosed",
  gridShape: "linear",
  borderWidth: 2,
  borderColor: { from: "color", modifiers: [["darker", 6]] },
  gridLevels: 2,
  gridLabelOffset: 10,
  enableDots: false,
  dotSize: 10,
  dotColor: { theme: "background" },
  dotBorderWidth: 2,
  dotBorderColor: { from: "color" },
  enableDotLabel: false,
  dotLabel: "value",
  dotLabelYOffset: -12,
  colors: "#438B53",
  fillOpacity: 0.25,
  blendMode: "multiply",
  animate: true,
  motionConfig: "wobbly",
  isInteractive: true,
  legends: [
    {
      anchor: "top-left",
      direction: "column",
      translateX: -50,
      translateY: -40,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
}
