export const funnelData = [
  {
    id: "Step 1",
    value: 100,
    tooltipData: {
      current: {
        "Step 1": {
          value: 100,
          format: "Units",
        },
      },
      previous: {
        "Step 1": {
          value: 95,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 2",
    value: 80,
    tooltipData: {
      current: {
        "Step 2": {
          value: 80,
          format: "Units",
        },
      },
      previous: {
        "Step 2": {
          value: 85,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 3",
    value: 60,
    tooltipData: {
      current: {
        "Step 3": {
          value: 60,
          format: "Units",
        },
      },
      previous: {
        "Step 3": {
          value: 65,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 4",
    value: 40,
    tooltipData: {
      current: {
        "Step 4": {
          value: 40,
          format: "Units",
        },
      },
      previous: {
        "Step 4": {
          value: 35,
          format: "Units",
        },
      },
    },
  },
  {
    id: "Step 5",
    value: 20,
    tooltipData: {
      current: {
        "Step 5": {
          value: 20,
          format: "Units",
        },
      },
      previous: {
        "Step 5": {
          value: 10,
          format: "Units",
        },
      },
    },
  },
]

export const funnelParameters = {
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  spacing: 10,
  valueFormat: ">-.0s",
  colors: { scheme: "spectral" },
  borderWidth: 20,
  borderColor: { from: "color", modifiers: [["darker", 0.2]] },
  labelColor: { from: "color", modifiers: [["darker", 3]] },
  enableBeforeSeparators: true,
  beforeSeparatorLength: 100,
  beforeSeparatorOffset: 20,
  enableAfterSeparators: true,
  afterSeparatorLength: 100,
  afterSeparatorOffset: 20,
  currentPartSizeExtension: 10,
  currentBorderWidth: 40,
  motionConfig: "gentle",
}
