import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"

export const transfromChartData = (data, tooltip_data, fieldMap) => {
  let transformedData = []

  if (data.reportDataCurrent && data.reportDataPrevious) {
    data.reportDataCurrent.forEach((item, index) => {
      let eachElement = {
        title: item?.[fieldMap.name],
        defaultValue: item?.[fieldMap.key],
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      if (tooltip_data && Array.isArray(tooltip_data)) {
        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: item[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }
        })

        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: data.reportDataPrevious[index]?.[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }
        })
      }

      transformedData.push(eachElement)
    })
  } else {
    data.forEach((item) => {
      let eachElement = {
        title: item?.[fieldMap.name],
        defaultValue: item?.[fieldMap.key],
        tooltipData: {
          current: {},
        },
      }

      if (tooltip_data && Array.isArray(tooltip_data)) {
        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: item[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })
      }

      transformedData.push(eachElement)
    })
  }

  return transformedData
}

export const transformExportData = (apiResponse, extractedParameters = {}) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || []

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"] ||
            current["ProductName"] ||
            current["Name"] ||
            "N/A"),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name

      if (
        name === "idID" ||
        name === "CustomerID" ||
        name === "ProductName" ||
        name === "Phone" ||
        name === "CustomerType" ||
        name === "id" ||
        name === "Name"
      ) {
        return
      }

      const currentVal = current[name] !== undefined ? current[name] : 0

      const formattedCurrentVal =
        tooltip.format === "Currency"
          ? `$${Number(currentVal).toLocaleString()}`
          : String(currentVal)

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] !== undefined ? previous[name] : 0

        const formattedPreviousVal =
          previousVal > 0
            ? tooltip.format === "Currency"
              ? `$${Number(previousVal).toLocaleString()}`
              : String(previousVal)
            : tooltip.format === "Currency"
            ? "$0"
            : "0"

        transformedWeek[name] = {
          current: formattedCurrentVal,
          previous: formattedPreviousVal,
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current: formattedCurrentVal,
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}
