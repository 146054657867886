import { useEffect, useState, useRef, useCallback } from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { useTheme } from "./contexts/theme"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import Container from "@mui/material/Container"
import Header from "./components/header/Header"
import SessionModal from "./routes/authentication/SessionModal"
import { SalesCacheKeyClear } from "./redux/slices/salesSlice"
import { useDispatch, useSelector } from "react-redux"
import { CustomerCacheKeyClear } from "./redux/slices/customerSlice"
import { VendorCacheKeyClear } from "./redux/slices/vendorSlice"
import { InventoryCacheKeyClear } from "./redux/slices/inventorySlice"
import { EmployeeCacheKeyClear } from "./redux/slices/employeeSlice"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { setSidebar } from "./redux/slices/sidebarSlice"
import { Main, SideBar } from "./components/sidebar/SideBar"

const App = () => {
  const { theme, resetColorMode } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"))
  const [showInactiveModal, setShowInactiveModal] = useState(false)
  const inactiveTimerRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn && location.pathname === "/") {
      navigate("/businesssynopsis", { replace: true })
    }
  }, [isLoggedIn, location, navigate])

  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const resetInactiveTimer = useCallback(
    debounce(() => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current)
      }
      inactiveTimerRef.current = setTimeout(() => {
        setShowInactiveModal(true)
      }, 600000)
    }, 700),
    []
  )

  useEffect(() => {
    resetInactiveTimer()
    window.addEventListener("mousemove", resetInactiveTimer)
    window.addEventListener("keydown", resetInactiveTimer)
    window.addEventListener("scroll", resetInactiveTimer)

    return () => {
      window.removeEventListener("mousemove", resetInactiveTimer)
      window.removeEventListener("keydown", resetInactiveTimer)
      window.removeEventListener("scroll", resetInactiveTimer)
      clearTimeout(inactiveTimerRef.current)
    }
  }, [resetInactiveTimer])

  const handleCloseInactiveModal = () => {
    setShowInactiveModal(false)
  }

  useEffect(() => {
    const storedThemeMode = localStorage.getItem("themeMode")
    if (storedThemeMode) {
      resetColorMode(storedThemeMode)
    }
  }, [resetColorMode])

  const handleLogout = () => {
    localStorage.clear()
    dispatch(SalesCacheKeyClear())
    dispatch(CustomerCacheKeyClear())
    dispatch(VendorCacheKeyClear())
    dispatch(InventoryCacheKeyClear())
    dispatch(EmployeeCacheKeyClear())

    navigate("/login")
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    }
  }, [isLoggedIn, navigate])

  const sidebarState = useSelector((state) => state.sidebar)
  const oldTitle = useSelector((state) => state.sidebar.title)
  const handleSideBarOpen = () => dispatch(setSidebar({ open: true }))
  const handleSideBarClose = () => dispatch(setSidebar({ open: false }))
  const handleSideBarToggle = (newTitle) => {
    if (oldTitle !== newTitle) dispatch(setSidebar({ open: true }))
    else dispatch(setSidebar({ open: !sidebarState.open }))
  }
  const handleSideBarUpdate = (title, data) =>
    dispatch(setSidebar({ title, data }))

  return (
    <GoogleOAuthProvider clientId="77310003938-jpm55m56odf2fpajvara8lur7gdlnna5.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main open={sidebarState.open}>
          <SideBar
            sidebarState={sidebarState}
            sidebarOpen={sidebarState.open}
            handleSideBarOpen={handleSideBarOpen}
            handleSideBarToggle={handleSideBarToggle}
            handleSideBarClose={handleSideBarClose}
            handleSideBarUpdate={handleSideBarUpdate}
          />
          <Header
            handleLogout={handleLogout}
            handleSideBarOpen={handleSideBarOpen}
            handleSideBarClose={handleSideBarClose}
            handleSideBarToggle={handleSideBarToggle}
            handleSideBarUpdate={handleSideBarUpdate}
          />
          <Container maxWidth="xl">
            <Outlet />
          </Container>
          <SessionModal
            open={showInactiveModal}
            onClose={handleCloseInactiveModal}
            onContinue={() => setShowInactiveModal(false)}
          />
        </Main>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const isLoggedIn = !!localStorage.getItem("token")

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    }
  }, [isLoggedIn, navigate])

  return isLoggedIn ? children : null
}

export default App
