import { useDispatch, useSelector } from "react-redux"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { loadData, setChartData } from "../../redux/slices/salesSlice"
import { getData } from "../../api/api"
import Box from "@mui/material/Box"
import { Masonry } from "@mui/lab"
import CustomPaper from "../../components/CustomPaper"
import KPICard from "../../components/KPICard"
import "./styles.scss"
import Table from "../../components/datagrid/Table"
import RouteLoader from "../../components/loader/RouteLoader"
// import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import Grid from "@mui/material/Grid"
import CompareBy from "../../components/CompareBy.jsx"
import { useTheme } from "../../contexts/theme.jsx"
import MagicOverlay from "../authentication/MagicOverlay.jsx"
import PieChartContainer from "../../components/charts/pie-re/PieChartContainer.jsx"
import ProgressBarContainer from "../../components/charts/progressBar/ProgressBarContainer.jsx"
import DashLineChartContainer from "../../components/charts/dashLine_re/DashLineContainer.jsx"
import HeatmapContainer from "../../components/charts/heatmap-re/HeatmapContainer.jsx"
import BarChartContainer from "../../components/charts/bar_re/BarChartContainer.jsx"
import FunnelChartContainer from "../../components/charts/funnel/FunnelChartContainer.jsx"
import SwarmPlotContainer from "../../components/charts/swarmPlot/SwarmPlotContainer.jsx"
import ScatterPlotContainer from "../../components/charts/scatterplot/ScatterPlotContainer.jsx"
import LineChartContainer from "../../components/charts/line/LineChartContainer.jsx"
import LineBarContainer from "../../components/charts/linebar_re/LineBarContainer.jsx"
import GroupBarContainer from "../../components/charts/GroupBar_re/GroupBarContainer.jsx"
import CalendarContainer from "../../components/charts/calendar_re/CalendarContainer.jsx"
import RadialBarChartContainer from "../../components/charts/radialBar_re/RadialBarChartContainer.jsx"
import DivergingBarChartContainer from "../../components/charts/divergingBar_re/DivergingBarChartContainer.jsx"

const Sales = (isOverlayVisible) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const salesData = useSelector((state) => state.sales)
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const [isDataSetCondition, setDataSetCondition] = useState(true)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("sales", {})
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (salesData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [salesData?.reports?.length])

  const countReports = salesData?.reports?.filter(
    (report) =>
      report.reportTypes.some((type) => type.ReportType === "Count") &&
      report.reportName !== "Gross Sales"
  )
  // Separate "Gross Sales" report
  const grossSalesReport = salesData?.reports?.find(
    (report) =>
      report.reportName === "Gross Sales" &&
      report.reportTypes.some((type) => type.ReportType === "Count")
  )

  const otherReports = salesData?.reports
    ?.filter(
      (report) =>
        !report.reportTypes.some(
          (type) =>
            type.ReportType === "Count" || type.ReportType === "Calendar"
        ) && report.reportName !== "Sales Summary"
    )
    .sort((a, b) => a.reportOrder - b.reportOrder)

  const calendarReports = salesData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  const groupedReports = salesData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "GroupedBar")
  })

  const salesSummaryReport = salesData?.reports?.filter(
    (report) => report.reportName === "Sales Summary"
  )?.[0]

  const isValidValue = (value) => {
    return value && value !== "null" && value !== "undefined" // Check for falsy, "null", or "undefined" string
  }

  const userInfoKeys = {
    businessName: "Business Name",
    addressLine1: "Address",
    addressLine2: "",
    postalCode: "Postal Code",
    businessPhone: "Business Phone",
  }

  return (
    <>
      <MagicOverlay open={isOverlayVisible} />

      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle>
            <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
              <Typography
                variant="subtitle1"
                color="inherit"
                fontWeight="fontWeightMedium"
              >
                {`Good to see you again, ${localStorage.getItem("fname")}!`}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                gap="5px"
                alignItems="center"
              >
                {Object.entries(userInfoKeys).map(
                  ([key, label], index, array) => {
                    let value = localStorage.getItem(key)

                    // Only render if the value is valid
                    if (!isValidValue(value)) return null

                    return (
                      <>
                        {index !== 0 && (
                          <Typography variant="body2" sx={{ opacity: 0.6 }}>
                            |
                          </Typography>
                        )}

                        <Typography
                          key={key}
                          variant="body2"
                          color="inherit"
                          fontWeight="fontWeightLight"
                          sx={{ fontSize: "0.8rem", opacity: 0.9 }}
                        >
                          {`${label}: ${value}`}
                        </Typography>
                      </>
                    )
                  }
                )}
              </Box>
            </Stack>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              maxWidth="auto"
              gap={2}
            >
              <CompareBy dateRangeData={dateRangeData} />
              <DatePicker />
            </Box>
          </RouteTitle>
          <Box padding="5px 20px 15px 20px">
            <Grid container justifyContent="space-between" p={1}>
              <CustomPaper
                customStyles={{
                  width: "100%",
                  backgroundColor: ThemeMode === "dark" ? "#030816" : "#E9EDFC",
                }}
                watermarkBg={true}
              >
                {grossSalesReport && (
                  <Grid
                    key={grossSalesReport.report_uid}
                    sx={{ width: "15rem", pb: 1 }}
                  >
                    <KPICard
                      title={grossSalesReport.reportName}
                      parameters={
                        grossSalesReport.reportTypes[0].reportParameterTypes
                      }
                      reportUid={grossSalesReport.report_uid}
                      dateRangeData={dateRangeData}
                      description={grossSalesReport.reportDescription}
                      postUrl="sales/data"
                      comparisonPeriod={comparisonPeriod}
                    />
                  </Grid>
                )}

                {/* Render count reports */}
                <Grid container className="sales_grid_container">
                  {countReports?.map((report, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.25}
                      key={report.report_uid}
                    >
                      <CustomPaper
                        customStyles={{ height: "100%" }}
                        watermarkBg={true}
                        CustomDrawerComponent={true}
                      >
                        <KPICard
                          title={report.reportName}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          reportUid={report.report_uid}
                          dateRangeData={dateRangeData}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          comparisonPeriod={comparisonPeriod}
                          width="100%"
                          height="6rem"
                        />
                      </CustomPaper>
                    </Grid>
                  ))}
                </Grid>
              </CustomPaper>
            </Grid>

            {salesSummaryReport && (
              <CustomPaper
                key={salesSummaryReport.report_uid}
                customStyles={{ overflow: "visible", margin: 1 }}
                innerCustomStyles={{ margin: 0 }}
              >
                {/* <LineBarChart
                  route="sales"
                  setChartData={setChartData}
                  title={salesSummaryReport.reportName}
                  description={salesSummaryReport.reportDescription}
                  postUrl="sales/data"
                  reportUid={salesSummaryReport.report_uid}
                  parameters={
                    salesSummaryReport.reportTypes[0].reportParameterTypes
                  }
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                /> */}
                <LineBarContainer
                  title={salesSummaryReport.reportName}
                  description={salesSummaryReport.reportDescription}
                  route="sales"
                  setChartData={setChartData}
                  postUrl="sales/data"
                  reportUid={salesSummaryReport.report_uid}
                  configurations={
                    salesSummaryReport.reportTypes[0].reportParameterTypes
                  }
                />
              </CustomPaper>
            )}

            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px 0px -20px 0px" }}
            >
              {/* <section className="sales_grid_container">
                {countReports?.map((report, index) => (
                  <CustomPaper
                    key={report.report_uid}
                    className={
                      index > 8
                        ? "sales_grid_item_other"
                        : `sales_grid_item_${index + 1}`
                    }
                    watermarkBg={true}
                    CustomDrawerComponent={true}
                  >
                    <KPICard
                      title={report.reportName}
                      parameters={report.reportTypes[0].reportParameterTypes}
                      reportUid={report.report_uid}
                      dateRangeData={dateRangeData}
                      description={report.reportDescription}
                      postUrl="sales/data"
                      width="100%"
                      height="6rem"
                      comparisonPeriod={comparisonPeriod}

                    />
                  </CustomPaper>
                ))}
              </section> */}
              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineChartContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <PieChartContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    List: (
                      <Table
                        route="sales"
                        setChartData={setChartData}
                        title={report.reportName}
                        hidePageNumbers={true}
                        showExportButton={false}
                        dateRangeData={dateRangeData}
                        reportUid={report.report_uid}
                        postUrl="sales/list"
                        onRowClick={(params) =>
                          console.log("rowclicked", params)
                        }
                      />
                    ),
                    Bar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <BarChartContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="sales"
                          setChartData={setChartData}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        {/* <LineBarChart
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        /> */}
                        <LineBarContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="sales"
                          setChartData={setChartData}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HeatmapContainer
                          key={report.report_uid}
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        {/* <ProgressBarContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        /> */}
                        <ProgressBarContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="sales"
                          setChartData={setChartData}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <ScatterPlotContainer
                        route="sales"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="sales/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DivergingBarChartContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChartContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="sales"
                          setChartData={setChartData}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    StackedBar: (
                      <StackedBarChart
                        route="sales"
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        postUrl="sales/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    Funnel: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <FunnelChartContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    RadialBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <RadialBarChartContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    SwarmPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <SwarmPlotContainer
                          route="sales"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="sales/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}

              {/* <CustomPaper>
                <TableChart
                  route="sales"
                  title="Overall Comparison"
                  description="Overall Comparison"
                  setChartData={setChartData}
                  comparisonPeriod={comparisonPeriod}
                  postUrl="sales/data"
                  dateRangeData={dateRangeData}
                />
              </CustomPaper>
              <CustomPaper customStyles={{ overflow: "visible" }}>
                <LineChart
                  route="sales"
                  title="Churn Trend"
                  description="Churn Trend desc"
                  setChartData={setChartData}
                  postUrl="sales/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper> */}
            </Masonry>

            {groupedReports?.map(
              (report) =>
                ({
                  GroupedBar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <GroupBarContainer
                        route="sales"
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        postUrl="sales/data"
                        reportUid={report.report_uid}
                        configurations={
                          report.reportTypes[0].reportParameterTypes
                        }
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <CalendarContainer
                        route="sales"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="sales/data"
                        reportUid={report.report_uid}
                        configurations={
                          report.reportTypes[0].reportParameterTypes
                        }
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default Sales
