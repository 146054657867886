import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { useTheme } from "../../../contexts/theme"
import ViewBy from "../../ViewBy"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CircularProgress from "@mui/material/CircularProgress"

import InfoToolTip from "../../InfoToolTip"
import Analytica from "../../Analytica"
import LineChartPresentation from "./LineChartPresentation"
import { fetchData } from "./services/dataService"
import { LineChartData, LineChartParameters } from "./constant/configFallback"

const LineChartContainer = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const svgColor = theme.palette.accentSecondary
  const dispatch = useDispatch()
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData(
      setLoading,
      setApiResponse,
      setError,
      dispatch,
      reportUid,
      setChartData
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  const transformedData =
    response?.data?.map(({ Date, x }) => ({
      x: Date,
      y: x,
    })) || []

  const chartInput = [
    {
      id: response?.id || "default_id",
      data: transformedData,
    },
  ]
  const legendData = [
    { label: "Forecast", color: "rgba(80, 205, 137, 1)" },
    { label: "Actual", color: "rgba(0, 158, 247, 1)" },
  ]
  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="Line"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="360px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <LineChartPresentation
            chartInput={LineChartData}
            parameters={LineChartParameters}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <LineChartPresentation
            chartInput={chartInput}
            parameters={parameters}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default LineChartContainer
