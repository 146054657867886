import { useState, useEffect, useRef } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import { useDispatch, useSelector } from "react-redux"
import FunnelChartPresentation from "./FunnelChartPresentation"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import ExportMenuButton from "../../ExportMenuButton.jsx"
import ExportChartsData from "../../ExportChartsData.jsx"
import {
  chartContainerDimentionsFallback,
  FunnelTooltip,
} from "./constant/configFallback.js"
import { fetchData } from "./services/dataService.js"
import { transformExportData } from "./services/transformService.js"
import { funnelData, funnelParameters } from "./constant/dataFallback.js"
import { generateCacheKey } from "../../../utils/utils.js"

const FunnelChartContainer = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  showAiDropdown = true,
  setAnchorEl,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  const svgColor = theme.palette.accentSecondary

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions =
      chartContainerDimentionsFallback)

  const tooltip_data = extractedParameters.tooltip_data || FunnelTooltip

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      setLoading,
      setChartData,
      postUrl,
      reportUid,
      combinedState,
      setApiResponse,
      setError,
      tooltip_data,
      dispatch,
      comparisonPeriod,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const tableData = transformExportData(reduxApiResponse, extractedParameters)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef} minHeight="350px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2">
              {" "}
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <FunnelChartPresentation
            chartConfig={funnelParameters}
            chartInput={funnelData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <FunnelChartPresentation
            chartConfig={parameters || funnelParameters}
            chartInput={response}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default FunnelChartContainer
