import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useRef, useState } from "react"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { fetchData } from "./services/dataServices"
import { generateCacheKey } from "../../../utils/utils"
import { Stack } from "@mui/material"
import HeaderSection from "./components/widget/HeaderSection"
import Loading from "./components/Loading"
import MacroMetricsSection from "./components/widget/MacroMetricsSection"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import {
  chartContainerDimensionsFallBack,
  defaultColor,
  defaultColorCustomers,
  getResolvedDefaultColor,
  macroMetricsFallbackConfig,
  PDFFieldsFallbackConfig,
  tooltipDataFallbackConfig,
  tooltipDataFallbackConfigCustmers,
  tooltipDataFallbackConfigCustomerDetails,
  tooltipDataFallbackConfigInventoryDetails,
} from "./constants/fallbackConfigs"
import {
  transformDataPDFExport,
  transformChartData,
  transformDataSheetExport,
  transformExternalApiResponse,
} from "./services/transformServices"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils"
import {
  CalendarChartData,
  CalendarChartParameters,
  staticApiResponse,
} from "./constants/fallbackdata"
import { dynamicLegendRanges } from "../calendar/services/configServices"
import Message from "../../Message"

const CalendarContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
  extApiResponse,
  extLoading = false,
  extError = null,
}) => {
  const chartRef = useRef(null)
  const dispatch = useDispatch()
  const transformedConfigs =
    extractKeyValuePairs(configurations) || CalendarChartParameters
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.viewBy
  )

  const configOverrides = [
    {
      key: "chartContainerDimensions",
      fallback: chartContainerDimensionsFallBack,
    },
    {
      key: "tooltip_data",
      fallback: (() => {
        if (reportUid === "external") {
          switch (route) {
            case "inventoryDetails":
              return tooltipDataFallbackConfigInventoryDetails
            case "customerDetails":
              return tooltipDataFallbackConfigCustomerDetails
            default:
              return tooltipDataFallbackConfigInventoryDetails
          }
        }

        switch (reportUid) {
          case "E45C3D13-0FE7-43E6-8C96-A13346DB66AE":
            return tooltipDataFallbackConfigCustmers
          default:
            return tooltipDataFallbackConfig
        }
      })(),
    },
    {
      key: "defaultColor",
      fallback: (() => {
        switch (reportUid) {
          case "E45C3D13-0FE7-43E6-8C96-A13346DB66AE":
            return defaultColorCustomers
          default:
            return defaultColor
        }
      })(),
    },
    // TODO: comment this out, as each report has different fallback
    {
      key: "macro-metrics",
      fallback: macroMetricsFallbackConfig(reportUid, route),
    },
    { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
    {
      key: "showTooltipPrevious",
      fallback:
        transformedConfigs.showTooltipPrevious === undefined
          ? true
          : transformedConfigs.showTooltipPrevious,
    },
  ]

  configOverrides.forEach(({ key, fallback }) => {
    transformedConfigs[key] = transformedConfigs[key] || fallback
  })

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const visibleViewByOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  const defaultViewByOption = getDefaultOption(daysInRange)

  const viewBy = visibleViewByOptions.includes(reportState.viewBy)
    ? reportState.viewBy
    : defaultViewByOption

  useEffect(() => {
    if (["static", "external"].includes(reportUid)) return
    if (reportState.cacheKey === cacheKey) return
    const payload = {
      entityDb: localStorage.getItem("entityDb"),
      reportInstanceId: reportUid,
      fromDate: "20220101",
      toDate: "20241231",
      comparedBy: {
        previousFromDate: "20220101",
        previousToDate: "20231231",
      },
    }
    if (!!transformedConfigs.view_by_period) {
      payload.viewBy = viewBy
    }
    setLoading(true)
    fetchData(postUrl, payload)
      .then((apiResponse) => {
        dispatch(
          setChartData({
            reportUid,
            response: apiResponse,
            apiResponse,
            transformedChartData: transformChartData(
              apiResponse,
              transformedConfigs.tooltip_data
            ),
            viewBy,
            cacheKey,
          })
        )
        setLoading(false)
      })
      .catch((err) => {
        console.warn(err)
        setError("Failed to fetch data")
        setLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUid])

  if (reportUid === "static") {
    reportState.apiResponse = staticApiResponse
    reportState.transformedChartData = CalendarChartData
  }

  if (reportUid === "external") {
    reportState.apiResponse = extApiResponse
    reportState.transformedChartData = transformExternalApiResponse(
      extApiResponse,
      transformedConfigs.tooltip_data
    )
  }
  const resolvedDefaultColor = getResolvedDefaultColor(reportUid)
  const minValue = Math?.min(
    ...(reportState.transformedChartData?.map((d) => d.value) || [0])
  )
  const maxValue = Math?.max(
    ...(reportState.transformedChartData?.map((d) => d.value) || [0])
  )
  const legendsData = useMemo(() => {
    return dynamicLegendRanges(minValue, maxValue, 4, resolvedDefaultColor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue, resolvedDefaultColor])

  return (
    <Stack gap={1} ref={chartRef}>
      <HeaderSection
        route={route}
        reportUid={reportUid}
        title={title}
        description={description}
        setChartData={setChartData}
        children={null}
        config={transformedConfigs}
        reportStateViewBy={reportState.viewBy}
        dateRangeData={dateRangeData}
        parentLoading={loading}
        parentError={error}
        transformedChartData={reportState.transformedChartData}
        apiResponse={reportState.apiResponse}
        chartRef={chartRef}
        pdfFields={transformedConfigs.pdfFields}
        pdfTransformFunction={transformDataPDFExport}
        transformDataSheetExport={transformDataSheetExport}
        visibleViewByOptions={visibleViewByOptions}
        defaultViewByOption={defaultViewByOption}
      />

      {extLoading || loading ? (
        <Message type="loading" />
      ) : extError || error ? (
        <Message message={extError || error} type="error" />
      ) : (
        <>
          <MacroMetricsSection
            config={transformedConfigs["macro-metrics"]}
            data={reportState.transformedChartData}
          />
          <BodySection
            config={transformedConfigs}
            data={reportState.transformedChartData}
          />
          <FooterSection
            legendsData={legendsData}
            title={title}
            apiResponse={reportState?.apiResponse || []}
          />
        </>
      )}
    </Stack>
  )
}

export default CalendarContainer
