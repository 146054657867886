import { colorScheme } from "../constant/configFallBack"

export function transformChartData(response, config) {

  const dataSource = Array.isArray(response?.reportDataCurrent)
    ? response.reportDataCurrent
    : response

  if (!Array.isArray(dataSource)) {
    console.error("The provided data is not an array.")
    return [{ id: "", data: [] }]
  }

  if (!dataSource.length) return [{ id: "", data: [] }]

  const combinedData = []
  const ids = []

  const formatMapping = config.tooltip_data.reduce((acc, { name, format }) => {
    acc[name] = format || "String"
    return acc
  }, {})

  dataSource.forEach((entry, index) => {
    const previous = response.reportDataPrevious?.[index]
    const keys = Object.keys(entry)

    if (keys.length < 2) {
      console.error("Each entry should have at least two keys.")
      return
    }

    const firstKey = keys[0]
    const secKey = keys[1]

    ids.push(entry[firstKey])

    const tooltipData = {
      current: keys.slice(1).reduce((acc, key) => {
        acc[key] = {
          value: entry[key],
          format: formatMapping[key] || "String",
        }
        return acc
      }, {}),
    }

    if (response.reportDataPrevious) {
      tooltipData.previous = keys.slice(1).reduce((acc, key) => {
        acc[key] = {
          value: previous?.[key] || null,
          format: formatMapping[key] || "String",
        }
        return acc
      }, {})
    }

    combinedData.push({
      x: `${entry[firstKey]}`,
      y: entry[secKey],
      tooltipData,
    })
  })

  return [
    {
      id: ids.filter(Boolean).join(", "),
      data: combinedData,
    },
  ]
}

export function transformDataPDFExport(apiResponse, pdfFields, reportUid) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date: current["id"] || current["Employee"] || current["Product Name"],
    }

    pdfFields.forEach((tooltip) => {
      const name = tooltip.name

      // Skip if the name is "Product Name", "EmployeeName", or "Wastage Reason"
      if (
        name === "Product Name" ||
        name === "EmployeeName" ||
        name === "Wastage Reason"
      )
        return

      // Check if the key exists in current data before processing
      if (!(name in current)) return

      let currentVal

      if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
        currentVal = current[name]
      } else {
        currentVal =
          current[name] && typeof current[name] === "string"
            ? Number(Number(current[name].replace(/[^0-9.-]+/g, "")).toFixed(2))
            : Number(Number(current[name] || 0).toFixed(2))
      }

      if (Object.keys(previous).length > 0) {
        let previousVal

        if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
          previousVal = previous[name]
        } else {
          previousVal =
            previous[name] && typeof previous[name] === "string"
              ? Number(previous[name].replace(/[^0-9.-]+/g, "")).toFixed(2)
              : Number(previous[name] || 0).toFixed(2)
        }

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0 ||
            !previousVal ||
            (currentVal - previousVal) / previousVal === Infinity ||
            (currentVal === 0 && previousVal === 0)
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}
export function transformDataSheetExport(apiResponse, pdfFields, reportUid) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    // Initialize the transformed entry with a date
    const transformedWeek = {
      date: current["id"] || current["Employee"] || current["Product Name"],
    }

    // Iterate through each field defined in pdfFields (tooltip)
    pdfFields.forEach((tooltip) => {
      const name = tooltip.name

      // Skip certain fields like "Product Name", "EmployeeName", "Wastage Reason"
      if (
        name === "Product Name" ||
        name === "EmployeeName" ||
        name === "Wastage Reason"
      )
        return

      // Check if the field exists in the current data
      if (!(name in current)) return

      // Process the current value based on the reportUid
      let currentVal
      if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
        currentVal = current[name]
      } else {
        currentVal =
          current[name] && typeof current[name] === "string"
            ? Number(Number(current[name].replace(/[^0-9.-]+/g, "")).toFixed(2))
            : Number(Number(current[name] || 0).toFixed(2))
      }

      // Handle transformation for the previous value, if available
      if (Object.keys(previous).length > 0) {
        let previousVal
        if (reportUid === "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0") {
          previousVal = previous[name]
        } else {
          previousVal =
            previous[name] && typeof previous[name] === "string"
              ? Number(previous[name].replace(/[^0-9.-]+/g, "")).toFixed(2)
              : Number(previous[name] || 0).toFixed(2)
        }

        // Add the transformed values in the flattened nested format
        transformedWeek[`${name} current`] =
          tooltip.format === "Currency"
            ? `$${currentVal.toLocaleString()}`
            : currentVal.toString()

        transformedWeek[`${name} previous`] =
          previousVal > 0
            ? tooltip.format === "Currency"
              ? `$${previousVal.toLocaleString()}`
              : previousVal.toString()
            : tooltip.format === "Currency"
            ? "$0"
            : "0"

        transformedWeek[`${name} changePercent`] =
          previousVal === 0 ||
          !previousVal ||
          (currentVal - previousVal) / previousVal === Infinity ||
          (currentVal === 0 && previousVal === 0)
            ? "-"
            : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
              "%"
      } else {
        // If no previous data exists, just include the current value
        transformedWeek[`${name} current`] =
          tooltip.format === "Currency"
            ? `$${currentVal.toLocaleString()}`
            : currentVal.toString()
      }
    })

    // Push the transformed data for this entry
    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const LegendData = (reportState) => {
  if (
    reportState.transformedChartData &&
    Array.isArray(reportState.transformedChartData)
  ) {
    return reportState.transformedChartData[0].data.map((item, idx) => ({
      label: item?.x,
      color: colorScheme[idx],
    }))
  }
  return []
}
