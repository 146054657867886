export const fallbackConfig = {
  margin: { top: 40, right: 20, bottom: 50, left: 60 },
  xScale: {
    type: "point",
    min: 0,
    max: "auto",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  lineWidth: 3,
  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Value",
    legendOffset: -55,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: true,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgb(251, 101, 29)", "rgb(0, 155, 230)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}

export const chartContainerDimentionsFallBack = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const tooltipDataFallbackConfig = [
  {
    name: "Sales",
    format: "Currency",
    color: "",
  },
]

export const PDFFieldsFallbackConfig = [
  {
    name: "Sales",
    format: "Currency",
    color: "",
  },
]

export const macroMetricsFallbackConfig = (reportUid) => [
  ["16B1F146-AE15-4622-8D58-FEEF9B8AEEF0"].includes(reportUid)
    ? null
    : {
        name: "Actual",
        helpText: "Sum of datapoint",
        firstRow: { value: "Actual", operation: null },
        secondRow: { value: "", operation: "aggregate" },
        thirdRow: { value: "", operation: null },
        aggregateFunc: "Actual",
        targetField: "Sales",
        format: "Currency",
        color: "#F19004",
        icon: "Pipe",
      },
  {
    name: "Forecasted",
    helpText: "Sum of datapoint",
    firstRow: { value: "Forecasted", operation: null },
    secondRow: { value: "", operation: "aggregate" },
    thirdRow: { value: "", operation: null },
    aggregateFunc: "Forecasted",
    targetField: "Sales",
    format: "Currency",
    color: "#1D54C8",
    icon: "Pipe",
  },
  {
    name: "%Change",
    helpText: "% Change of datapoint",
    firstRow: { value: "Change(%)", operation: null },
    secondRow: { value: "", operation: "aggregate" },

    thirdRow: { value: "", operation: null },
    aggregateFunc: "%Change",
    targetField: "Sales",
    format: "Percentage",
    color: "#EC2D30",
    icon: "",
  },
]
