import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getData } from "../../api/api"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { loadData, setChartData } from "../../redux/slices/inventorySlice"
import Masonry from "@mui/lab/Masonry"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import KPICard from "../../components/KPICard"
import RouteLoader from "../../components/loader/RouteLoader"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import CompareBy from "../../components/CompareBy"
import Table from "../../components/datagrid/Table"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import "./styles.scss"
import { useTheme } from "../../contexts/theme"
import MagicOverlay from "../authentication/MagicOverlay.jsx"
import { useLocation } from "react-router-dom"
import DivergingBarChartContainer from "../../components/charts/divergingBar/DivergingBarChartContainer.jsx"
import PieChartContainer from "../../components/charts/pie-re/PieChartContainer.jsx"
import ProgressBarContainer from "../../components/charts/progressBar_re/ProgressBarContainer.jsx"
import DashLineChartContainer from "../../components/charts/dashLine/DashLineChartContainer.jsx"
import HeatmapContainer from "../../components/charts/heatmap-re/HeatmapContainer.jsx"
import SwarmPlotContainer from "../../components/charts/swarmPlot/SwarmPlotContainer.jsx"
import GroupBarContainer from "../../components/charts/GroupBar_re/GroupBarContainer.jsx"
import CalendarContainer from "../../components/charts/calendar_re/CalendarContainer.jsx"
import ScatterPlotContainer from "../../components/charts/scatterplot/ScatterPlotContainer.jsx"
import LineChartContainer from "../../components/charts/line/LineChartContainer.jsx"
import BarChartContainer from "../../components/charts/bar_re/BarChartContainer.jsx"
import RadialBarChartContainer from "../../components/charts/radialBar_re/RadialBarChartContainer.jsx"
import LineBarContainer from "../../components/charts/lineBar/LineBarContainer.jsx"
import FunnelChartContainer from "../../components/charts/funnel/FunnelContainer.jsx"

const Inventory = (isOverlayVisible) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const inventoryData = useSelector((state) => state.inventory)
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const location = useLocation()
  useEffect(() => {
    document.body.style.overflow = "auto"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [location])

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("inventory", {})
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (inventoryData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryData?.reports?.length])

  const countReports = inventoryData?.reports?.filter(
    (report) =>
      report.reportTypes.some((type) => type.ReportType === "Count") &&
      report.reportName !== "Total Products"
  )

  const TotalProductsReport = inventoryData?.reports?.find(
    (report) =>
      report.reportName === "Total Products" &&
      report.reportTypes.some((type) => type.ReportType === "Count")
  )

  const otherReports = inventoryData?.reports
    ?.filter(
      (report) =>
        report.reportName != "Product Profitability Report" &&
        !report.reportTypes.some(
          (type) =>
            type.ReportType === "Count" || type.ReportType === "Calendar"
        )
    )
    .sort((a, b) => a.reportOrder - b.reportOrder)

  const calendarReports = inventoryData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  const fullscreenReport = inventoryData?.reports?.filter((report) =>
    ["Product Profitability Report"].includes(report.reportName)
  )

  const groupedBarReports = inventoryData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "GroupedBar")
  })

  const groupedReports = inventoryData?.reports?.filter((report) =>
    [
      "Top Departments by Product Sales Quantity",
      "Most Popular Brands",
    ].includes(report.reportName)
  )

  const isValidValue = (value) => {
    return value && value !== "null" && value !== "undefined" // Check for falsy, "null", or "undefined" string
  }

  const userInfoKeys = {
    businessName: "Business Name",
    addressLine1: "Address",
    addressLine2: "",
    postalCode: "Postal Code",
    businessPhone: "Business Phone",
  }

  return (
    <>
      <MagicOverlay open={isOverlayVisible} />
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle>
            <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
              <Typography
                variant="subtitle1"
                color="inherit"
                fontWeight="fontWeightMedium"
              >
                {`Good to see you again, ${localStorage.getItem("fname")}!`}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                gap="5px"
                alignItems="center"
              >
                {Object.entries(userInfoKeys).map(
                  ([key, label], index, array) => {
                    let value = localStorage.getItem(key)

                    // Only render if the value is valid
                    if (!isValidValue(value)) return null

                    return (
                      <>
                        {index !== 0 && (
                          <Typography variant="body2" sx={{ opacity: 0.6 }}>
                            |
                          </Typography>
                        )}

                        <Typography
                          key={key}
                          variant="body2"
                          color="inherit"
                          fontWeight="fontWeightLight"
                          sx={{ fontSize: "0.8rem", opacity: 0.9 }}
                        >
                          {`${label}: ${value}`}
                        </Typography>
                      </>
                    )
                  }
                )}
              </Box>
            </Stack>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              maxWidth="auto"
              gap={2}
            >
              <CompareBy dateRangeData={dateRangeData} />
              <DatePicker />
            </Box>
          </RouteTitle>

          <Box padding="5px 20px 15px 20px">
            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px" }}
            >
              <CustomPaper
                customStyles={{
                  backgroundColor: ThemeMode === "dark" ? "#030816" : "#E9EDFC",
                }}
                watermarkBg={true}
                CustomDrawerComponent={true}
              >
                {TotalProductsReport && (
                  <Grid
                    key={TotalProductsReport.report_uid}
                    sx={{ width: "12rem", pb: 1 }}
                  >
                    <KPICard
                      title={TotalProductsReport.reportName}
                      parameters={
                        TotalProductsReport.reportTypes[0].reportParameterTypes
                      }
                      reportUid={TotalProductsReport.report_uid}
                      dateRangeData={dateRangeData}
                      description={TotalProductsReport.reportDescription}
                      postUrl="sales/data"
                      comparisonPeriod={comparisonPeriod}
                      showPrevious={false}
                    />
                  </Grid>
                )}
                {countReports && (
                  <section className="inventory_grid_container">
                    {countReports?.map((report, index) => (
                      <CustomPaper
                        key={report.report_uid}
                        className={
                          index > 8
                            ? "inventory_grid_item_other"
                            : `inventory_grid_item_${index + 1}`
                        }
                        watermarkBg={true}
                        CustomDrawerComponent={true}
                      >
                        <KPICard
                          key={report.report_uid}
                          title={report.reportName}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          width="100%"
                          height="6rem"
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ))}
                  </section>
                )}
              </CustomPaper>

              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineChartContainer
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <PieChartContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="inventory"
                          setChartData={setChartData}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    List: (
                      <Table
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        showExportButton={false}
                        hidePageNumbers={true}
                        dateRangeData={dateRangeData}
                        reportUid={report.report_uid}
                        postUrl="inventory/list"
                        parameters={report.reportTypes[0].reportParameterTypes}
                        onRowClick={(params) =>
                          console.log(
                            `Inventory Route > ${report.reportName}, Row clicked`,
                            params
                          )
                        }
                      />
                    ),
                    Bar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        {/* <BarChartContainer
                          key={report.report_uid}
                          route="inventory"
                          showLegend={true}
                          title={report.reportName}
                          description={report.reportDescription}
                          setChartData={setChartData}
                          comparisonPeriod={comparisonPeriod}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          colorFlag="single"
                        /> */}
                        <BarChartContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="inventory"
                          setChartData={setChartData}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                        // CustomDrawerComponent={
                        //   report.reportTypes[0].ReportType !== "List"
                        // }
                      >
                        <LineBarContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="inventory"
                          setChartData={setChartData}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HeatmapContainer
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        {/* <ProgressBarContainer
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        /> */}

                        <ProgressBarContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="inventory"
                          setChartData={setChartData}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <ScatterPlotContainer
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    StackedBar: (
                      <StackedBarChart
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DivergingBarChartContainer
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChartContainer
                          title={report.reportName}
                          description={report.reportDescription}
                          route="inventory"
                          setChartData={setChartData}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    Funnel: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <FunnelChartContainer
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          configurations={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                    RadialBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <RadialBarChartContainer
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    SwarmPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <SwarmPlotContainer
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
              {/* 
              <Box>
                <Grid container sx={{ height: "100%" }} spacing={2}>
                  {groupedReports?.map((report) => (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ProgressBar
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          showPagination={true}
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    </Grid>
                  ))}
                </Grid>
              </Box> */}

              {/* <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
                <BarChart
                  route="inventory"
                  showLegend={true}
                  setChartData={setChartData}
                  comparisonPeriod={comparisonPeriod}
                  title={"Most Wasted Products"}
                  description={"Most Wasted Products"}
                  postUrl="inventory/data"
                  reportUid={"static-barchart"}
                  parameters={CustomerBarChartParameters}
                />
              </CustomPaper> */}

              {/* <Table
                route="inventory"
                setChartData={setChartData}
                title="Most Popular Product by Sales Qnt"
                showExportButton={false}
                hidePageNumbers={true}
                dateRangeData={dateRangeData}
                reportUid={"static-List"}
                postUrl="inventory/list"
                StaticData={PopularProductList}
                StaticParameters={PopularProductParameters}
              /> */}

              {/* <CustomPaper>
                <ProgressBar
                  route="inventory"
                  title="Most Popular Products"
                  description="Most Popular Products"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                  showPagination={true}
                />
              </CustomPaper>
              <CustomPaper>
                <ProgressBar
                  route="inventory"
                  title="Most Popular Departments"
                  description="Most Popular Departments"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                  showPagination={true}
                />
              </CustomPaper>
              <CustomPaper customStyles={{ overflow: "visible" }}>
                <CalendarChart
                  route="inventory"
                  title="Calendar"
                  description="Top Calendar Items"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper>
              <CustomPaper customStyles={{ overflow: "visible" }}>
                <SunburstChart
                  route="inventory"
                  title="Wastage Items"
                  description="Top Wastage Items"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper> */}
            </Masonry>

            {groupedBarReports?.map(
              (report) =>
                ({
                  GroupedBar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <GroupBarContainer
                        route="inventory"
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        configurations={
                          report.reportTypes[0].reportParameterTypes
                        }
                        showLegend={true}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible", margin: "10px" }}
                    >
                      <CalendarContainer
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        configurations={
                          report.reportTypes[0].reportParameterTypes
                        }
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {fullscreenReport?.map(
              (report) =>
                ({
                  Bar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible" }}
                    >
                      {/* <BarChartContainer
                        key={report.report_uid}
                        route="inventory"
                        showLegend={true}
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        colorFlag="single"
                      /> */}
                      <BarChartContainer
                        title={report.reportName}
                        description={report.reportDescription}
                        route="inventory"
                        setChartData={setChartData}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        configurations={
                          report.reportTypes[0].reportParameterTypes
                        }
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default Inventory
