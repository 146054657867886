export const tooltip_dataFallBack = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimensionsFallBack = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}
