import { tooltip_dataFallBack } from "../constants/configFallBack"

export const transfromChartData = (response, tooltip_data) => {
  let responseWithTooltip = []
  let chartInput = []
  const hours = [
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
  ]

  if (Array.isArray(response)) {
    responseWithTooltip = response.map((currentItem) => {
      const eachElement = {
        Weekday: currentItem.Weekday || 0,
        id: currentItem.id || "",
        x: currentItem.x || 0,
        y: currentItem.y || 0,
        tooltipData: {
          current: {},
        },
      }

      if (tooltip_data && Array.isArray(tooltip_data)) {
        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentItem[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }
        })
      }

      return eachElement
    })

    chartInput = responseWithTooltip.reduce((acc, current) => {
      const id = current.id
      if (!acc[id]) {
        acc[id] = {
          id,
          data: [],
          processedHours: new Set(),
        }
      }

      if (hours.includes(current.x)) {
        acc[id].data.push({
          x: current.x,
          y: current.y,
          reportType: "Heatmap",
          tooltipData: current.tooltipData,
        })
        acc[id].processedHours.add(current.x)
      }

      return acc
    }, {})

    // Add missing hours with default values
    Object.values(chartInput).forEach((entry) => {
      hours.forEach((hour) => {
        if (!entry.processedHours.has(hour)) {
          entry.data.push({
            x: hour,
            y: 0,
            reportType: "Heatmap",
            tooltipData: {
              current: {},
            },
          })
        }
      })
      delete entry.processedHours // Clean up the temporary Set

      // Sort from 9AM to 9PM (hours variable)
      entry.data.sort((a, b) => hours.indexOf(a.x) - hours.indexOf(b.x))
    })
  } else if (response?.reportDataCurrent) {
    responseWithTooltip = response?.reportDataCurrent.map((currentItem) => {
      const matchingPreviousItem =
        response?.reportDataPrevious?.find(
          (prevItem) =>
            prevItem.Weekday === currentItem.Weekday &&
            prevItem.id === currentItem.id &&
            prevItem.x === currentItem.x
        ) || {}

      const eachElement = {
        Weekday: currentItem.Weekday || 0,
        id: currentItem.id || "",
        reportType: "Heatmap",
        x: currentItem.x || 0,
        y: currentItem.y || 0,
        previousY: matchingPreviousItem?.y || 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      if (tooltip_data && Array.isArray(tooltip_data)) {
        tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: currentItem[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: matchingPreviousItem[tooltipDataItem.name] || 0,
            format: tooltipDataItem.format,
          }
        })
      }

      return eachElement
    })

    chartInput = responseWithTooltip.reduce((acc, current) => {
      const id = current.id
      if (!acc[id]) {
        acc[id] = {
          id,
          data: [],
          processedHours: new Set(),
        }
      }

      if (hours.includes(current.x)) {
        acc[id].data.push({
          x: current.x,
          y: current.y,
          reportType: "Heatmap",
          previousY: current.previousY,
          tooltipData: current.tooltipData,
        })
        acc[id].processedHours.add(current.x)
      }

      return acc
    }, {})

    // Add missing hours with default values
    Object.values(chartInput).forEach((entry) => {
      hours.forEach((hour) => {
        if (!entry.processedHours.has(hour)) {
          entry.data.push({
            x: hour,
            y: 0,
            reportType: "Heatmap",
            previousY: 0,
            tooltipData: {
              current: {},
              previous: {},
            },
          })
        }
      })
      delete entry.processedHours // Clean up the temporary Set

      // Sort from 9AM to 9PM (hours variable)
      entry.data.sort((a, b) => hours.indexOf(a.x) - hours.indexOf(b.x))
    })
  }

  return Object.values(chartInput)
}

export function transformExportData(apiResponse, extractedParameters = {}) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse || []
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const tooltip_data = extractedParameters?.tooltip_data || tooltip_dataFallBack

  if (previousData.length === 0) {
    currentData.forEach((current) => {
      if (!current || !current.id || !current.x) {
        console.warn("Invalid data structure in current data")
        return
      }

      const transformedWeek = {
        date: `${current.id} ${current.x}`,
      }

      tooltip_data.forEach((tooltip) => {
        const name = tooltip?.name
        if (!name) return

        const format = tooltip?.format
        if (!format) {
          console.warn("Tooltip data is missing or improperly structured")
          return
        }

        const currentVal = current[name] ?? 0

        transformedWeek[name] = {
          current:
            format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      })

      transformedData.push(transformedWeek)
    })
    return transformedData
  }

  currentData.forEach((current) => {
    if (!current || !current.id || !current.x) {
      console.warn("Invalid data structure in current data")
      return
    }

    const previous =
      previousData.find((p) => p?.id === current.id && p?.x === current.x) || {}

    const transformedWeek = {
      date: `${current.id} ${current.x}`,
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  })

  return transformedData
}
