export const CalendarChartData = [
  {
    day: "2024-12-31",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-31",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 37.08,
          format: "Currency",
        },
        Discounts: {
          value: 265.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-30",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-30",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 194,
          format: "Number",
        },
        Returns: {
          value: 44.66,
          format: "Currency",
        },
        Discounts: {
          value: 557.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-29",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-29",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 222.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-28",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-28",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 258.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-27",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-27",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 604.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-26",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-26",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 196.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-25",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-25",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 100.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-24",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-24",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 160,
          format: "Currency",
        },
        Customers: {
          value: 160,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 365.67,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-23",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-23",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 263.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-22",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-22",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.99,
          format: "Currency",
        },
        Discounts: {
          value: 270.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-21",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-21",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.01,
          format: "Currency",
        },
        Discounts: {
          value: 383.9,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-20",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-20",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 147.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-19",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-19",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 10.6,
          format: "Currency",
        },
        Discounts: {
          value: 424.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-18",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-18",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 135.88,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-17",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-17",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 216.61,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-16",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-16",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 16.43,
          format: "Currency",
        },
        Discounts: {
          value: 254.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-15",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-15",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 12.78,
          format: "Currency",
        },
        Discounts: {
          value: 241.07,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-14",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-14",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 13.52,
          format: "Currency",
        },
        Discounts: {
          value: 222.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-13",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-13",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 370.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-12",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-12",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 230.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-11",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-11",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-10",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-10",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 155,
          format: "Currency",
        },
        Customers: {
          value: 155,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 315.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-09",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-09",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.05,
          format: "Currency",
        },
        Discounts: {
          value: 278.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-08",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-08",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 10.27,
          format: "Currency",
        },
        Discounts: {
          value: 439.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-07",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-07",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 412.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-06",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-06",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 5.33,
          format: "Currency",
        },
        Discounts: {
          value: 250.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-05",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-05",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 230.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-04",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-04",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 5.94,
          format: "Currency",
        },
        Discounts: {
          value: 137.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-03",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-03",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 362.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-02",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-02",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 205.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-12-01",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-12-01",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 104,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 5.66,
          format: "Currency",
        },
        Discounts: {
          value: 268.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-30",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-30",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 52.07000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 294.01,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-29",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-29",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 265.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-28",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-28",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 280.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-27",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-27",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-26",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-26",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 6.99,
          format: "Currency",
        },
        Discounts: {
          value: 232.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-25",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-25",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.59,
          format: "Currency",
        },
        Discounts: {
          value: 267.82,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-24",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-24",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 14.5,
          format: "Currency",
        },
        Discounts: {
          value: 238.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-23",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-23",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 326.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-22",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-22",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 140,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 361.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-21",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-21",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 270,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-20",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-20",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 148.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-19",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-19",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 23.4,
          format: "Currency",
        },
        Discounts: {
          value: 190.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-18",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-18",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 211.9,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-17",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-17",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 41.92,
          format: "Currency",
        },
        Discounts: {
          value: 225.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-16",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-16",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 248.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-15",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-11-15",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 379.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-14",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-11-14",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 282.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.55,
          format: "Currency",
        },
        Discounts: {
          value: 215.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-13",
    value: 86,
    reportType: "Calendar",
    previousDate: "2023-11-13",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 4.6,
          format: "Currency",
        },
        Discounts: {
          value: 32.23,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 9.04,
          format: "Currency",
        },
        Discounts: {
          value: 123.49,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-12",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-11-12",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 1.5,
          format: "Currency",
        },
        Discounts: {
          value: 92.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 167,
          format: "Currency",
        },
        Customers: {
          value: 167,
          format: "Number",
        },
        Transactions: {
          value: 268,
          format: "Number",
        },
        Returns: {
          value: 8.129999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 278.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-11",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-11-11",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 34.93,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 53.35,
          format: "Currency",
        },
        Discounts: {
          value: 320.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-10",
    value: 124,
    reportType: "Calendar",
    previousDate: "2023-11-10",
    tooltipData: {
      current: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 17.98,
          format: "Currency",
        },
        Discounts: {
          value: 19.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 253.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-09",
    value: 114,
    reportType: "Calendar",
    previousDate: "2023-11-09",
    tooltipData: {
      current: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 182.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 268.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-08",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-11-08",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 4.96,
          format: "Currency",
        },
        Discounts: {
          value: 39.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 191.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-07",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-11-07",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 84.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 16.2,
          format: "Currency",
        },
        Discounts: {
          value: 397.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-06",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-11-06",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 188.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 502.64,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-05",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-11-05",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 10.7,
          format: "Currency",
        },
        Discounts: {
          value: 9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 1.29,
          format: "Currency",
        },
        Discounts: {
          value: 222.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-04",
    value: 79,
    reportType: "Calendar",
    previousDate: "2023-11-04",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 13.7,
          format: "Currency",
        },
        Discounts: {
          value: 37.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 31.3,
          format: "Currency",
        },
        Discounts: {
          value: 205.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-03",
    value: 128,
    reportType: "Calendar",
    previousDate: "2023-11-03",
    tooltipData: {
      current: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 40.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 227.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-02",
    value: 95,
    reportType: "Calendar",
    previousDate: "2023-11-02",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 63.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 223.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-11-01",
    value: 118,
    reportType: "Calendar",
    previousDate: "2023-11-01",
    tooltipData: {
      current: {
        value: {
          value: 118,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 15.1,
          format: "Currency",
        },
        Discounts: {
          value: 22.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 518.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-31",
    value: 99,
    reportType: "Calendar",
    previousDate: "2023-10-31",
    tooltipData: {
      current: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 19.69,
          format: "Currency",
        },
        Discounts: {
          value: 13.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 246.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-30",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-10-30",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 5.5,
          format: "Currency",
        },
        Discounts: {
          value: 189.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.47,
          format: "Currency",
        },
        Discounts: {
          value: 147,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-29",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-10-29",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 14.95,
          format: "Currency",
        },
        Discounts: {
          value: 43.59,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 229.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-28",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-10-28",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 1.25,
          format: "Currency",
        },
        Discounts: {
          value: 3.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 7.48,
          format: "Currency",
        },
        Discounts: {
          value: 228.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-27",
    value: 110,
    reportType: "Calendar",
    previousDate: "2023-10-27",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 4.88,
          format: "Currency",
        },
        Discounts: {
          value: 51.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 7.97,
          format: "Currency",
        },
        Discounts: {
          value: 665.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-26",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-10-26",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 10.8,
          format: "Currency",
        },
        Discounts: {
          value: 38.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 465.97,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-25",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-10-25",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 19.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.42,
          format: "Currency",
        },
        Discounts: {
          value: 309.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-24",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-10-24",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 1.7,
          format: "Currency",
        },
        Discounts: {
          value: 267.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-23",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-10-23",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 44.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 9.02,
          format: "Currency",
        },
        Discounts: {
          value: 152.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-22",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-10-22",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 43.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 139,
          format: "Currency",
        },
        Customers: {
          value: 139,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 4.4,
          format: "Currency",
        },
        Discounts: {
          value: 355.93,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-21",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-10-21",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 18.93,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 35.8,
          format: "Currency",
        },
        Discounts: {
          value: 260.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-20",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-10-20",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 1.6,
          format: "Currency",
        },
        Discounts: {
          value: 53.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 9.6,
          format: "Currency",
        },
        Discounts: {
          value: 388.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-19",
    value: 121,
    reportType: "Calendar",
    previousDate: "2023-10-19",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 38.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 22.46,
          format: "Currency",
        },
        Discounts: {
          value: 294.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-18",
    value: 115,
    reportType: "Calendar",
    previousDate: "2023-10-18",
    tooltipData: {
      current: {
        value: {
          value: 115,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 160,
          format: "Number",
        },
        Returns: {
          value: 33.74,
          format: "Currency",
        },
        Discounts: {
          value: 16.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 262.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-17",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-10-17",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 90,
          format: "Number",
        },
        Returns: {
          value: 1.5,
          format: "Currency",
        },
        Discounts: {
          value: 31.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 2.33,
          format: "Currency",
        },
        Discounts: {
          value: 216.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-16",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-10-16",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 7.3,
          format: "Currency",
        },
        Discounts: {
          value: 43.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 11.98,
          format: "Currency",
        },
        Discounts: {
          value: 138.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-15",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-10-15",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 24.87,
          format: "Currency",
        },
        Discounts: {
          value: 270.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-14",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-10-14",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 4.07,
          format: "Currency",
        },
        Discounts: {
          value: 19.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 214,
          format: "Number",
        },
        Returns: {
          value: 13.27,
          format: "Currency",
        },
        Discounts: {
          value: 272.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-13",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-10-13",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 23.4,
          format: "Currency",
        },
        Discounts: {
          value: 28.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 308.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-12",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-10-12",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 8.79,
          format: "Currency",
        },
        Discounts: {
          value: 26.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 4.1,
          format: "Currency",
        },
        Discounts: {
          value: 501.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-11",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-10-11",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 321.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 257.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-10",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-10-10",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.440000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 19.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 1.87,
          format: "Currency",
        },
        Discounts: {
          value: 205.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-09",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-10-09",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 7.31,
          format: "Currency",
        },
        Discounts: {
          value: 171.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-08",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-10-08",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 1.5,
          format: "Currency",
        },
        Discounts: {
          value: 6.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 199,
          format: "Number",
        },
        Returns: {
          value: 7.02,
          format: "Currency",
        },
        Discounts: {
          value: 287.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-07",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-10-07",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 52.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 137,
          format: "Currency",
        },
        Customers: {
          value: 137,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 349.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-06",
    value: 124,
    reportType: "Calendar",
    previousDate: "2023-10-06",
    tooltipData: {
      current: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 2.7,
          format: "Currency",
        },
        Discounts: {
          value: 60.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 284.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-05",
    value: 99,
    reportType: "Calendar",
    previousDate: "2023-10-05",
    tooltipData: {
      current: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 0.97,
          format: "Currency",
        },
        Discounts: {
          value: 36.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 1.15,
          format: "Currency",
        },
        Discounts: {
          value: 385.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-04",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-10-04",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 4.7,
          format: "Currency",
        },
        Discounts: {
          value: 40.19,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 222.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-03",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-10-03",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 18.2,
          format: "Currency",
        },
        Discounts: {
          value: 225.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 43.5,
          format: "Currency",
        },
        Discounts: {
          value: 198.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-02",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-10-02",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 7.8,
          format: "Currency",
        },
        Discounts: {
          value: 29.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 163.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-10-01",
    value: 70,
    reportType: "Calendar",
    previousDate: "2023-10-01",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 86,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 28.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 25.99,
          format: "Currency",
        },
        Discounts: {
          value: 273.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-30",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-09-30",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 17.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 17.9,
          format: "Currency",
        },
        Discounts: {
          value: 237.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-29",
    value: 116,
    reportType: "Calendar",
    previousDate: "2023-09-29",
    tooltipData: {
      current: {
        value: {
          value: 116,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 4.9,
          format: "Currency",
        },
        Discounts: {
          value: 52.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 359.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-28",
    value: 112,
    reportType: "Calendar",
    previousDate: "2023-09-28",
    tooltipData: {
      current: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 13.5,
          format: "Currency",
        },
        Discounts: {
          value: 63.97,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 308.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-27",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-09-27",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 35.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 227.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-26",
    value: 95,
    reportType: "Calendar",
    previousDate: "2023-09-26",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 15.25,
          format: "Currency",
        },
        Discounts: {
          value: 244.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-25",
    value: 96,
    reportType: "Calendar",
    previousDate: "2023-09-25",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 5.25,
          format: "Currency",
        },
        Discounts: {
          value: 192.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 5.25,
          format: "Currency",
        },
        Discounts: {
          value: 143.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-24",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-09-24",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 24.32,
          format: "Currency",
        },
        Discounts: {
          value: 13.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 34.17,
          format: "Currency",
        },
        Discounts: {
          value: 321.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-23",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-09-23",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 4.7,
          format: "Currency",
        },
        Discounts: {
          value: 41.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 20.09,
          format: "Currency",
        },
        Discounts: {
          value: 232.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-22",
    value: 110,
    reportType: "Calendar",
    previousDate: "2023-09-22",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 32.18,
          format: "Currency",
        },
        Discounts: {
          value: 105.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 83.19999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 295.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-21",
    value: 116,
    reportType: "Calendar",
    previousDate: "2023-09-21",
    tooltipData: {
      current: {
        value: {
          value: 116,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 3.56,
          format: "Currency",
        },
        Discounts: {
          value: 50.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 410.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-20",
    value: 96,
    reportType: "Calendar",
    previousDate: "2023-09-20",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 3.88,
          format: "Currency",
        },
        Discounts: {
          value: 52.76,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-19",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-09-19",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 30.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 187.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-18",
    value: 79,
    reportType: "Calendar",
    previousDate: "2023-09-18",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 12.38,
          format: "Currency",
        },
        Discounts: {
          value: 222.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 20.48,
          format: "Currency",
        },
        Discounts: {
          value: 118.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-17",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-09-17",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 73,
          format: "Number",
        },
        Returns: {
          value: 4.99,
          format: "Currency",
        },
        Discounts: {
          value: 67.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 142,
          format: "Currency",
        },
        Customers: {
          value: 142,
          format: "Number",
        },
        Transactions: {
          value: 237,
          format: "Number",
        },
        Returns: {
          value: 32.59,
          format: "Currency",
        },
        Discounts: {
          value: 415.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-16",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-09-16",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 24.48,
          format: "Currency",
        },
        Discounts: {
          value: 15.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 138,
          format: "Currency",
        },
        Customers: {
          value: 138,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 30.27,
          format: "Currency",
        },
        Discounts: {
          value: 399.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-15",
    value: 123,
    reportType: "Calendar",
    previousDate: "2023-09-15",
    tooltipData: {
      current: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 2.26,
          format: "Currency",
        },
        Discounts: {
          value: 81.01,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 71.5,
          format: "Currency",
        },
        Discounts: {
          value: 343.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-14",
    value: 117,
    reportType: "Calendar",
    previousDate: "2023-09-14",
    tooltipData: {
      current: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 24.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 255.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-13",
    value: 108,
    reportType: "Calendar",
    previousDate: "2023-09-13",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 5.33,
          format: "Currency",
        },
        Discounts: {
          value: 295.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 309.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-12",
    value: 86,
    reportType: "Calendar",
    previousDate: "2023-09-12",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 8.92,
          format: "Currency",
        },
        Discounts: {
          value: 142.97,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.3,
          format: "Currency",
        },
        Discounts: {
          value: 299.61,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-11",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-09-11",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 11.5,
          format: "Currency",
        },
        Discounts: {
          value: 31.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 131.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-10",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-09-10",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 8.5,
          format: "Currency",
        },
        Discounts: {
          value: 185.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 8.4,
          format: "Currency",
        },
        Discounts: {
          value: 267.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-09",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-09-09",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 2.16,
          format: "Currency",
        },
        Discounts: {
          value: 8.76,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 6.84,
          format: "Currency",
        },
        Discounts: {
          value: 393.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-08",
    value: 102,
    reportType: "Calendar",
    previousDate: "2023-09-08",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 29.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 10.89,
          format: "Currency",
        },
        Discounts: {
          value: 207.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-07",
    value: 109,
    reportType: "Calendar",
    previousDate: "2023-09-07",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 74.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 55.2,
          format: "Currency",
        },
        Discounts: {
          value: 428.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-06",
    value: 110,
    reportType: "Calendar",
    previousDate: "2023-09-06",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 28.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 8.99,
          format: "Currency",
        },
        Discounts: {
          value: 262.02,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-05",
    value: 100,
    reportType: "Calendar",
    previousDate: "2023-09-05",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 3.83,
          format: "Currency",
        },
        Discounts: {
          value: 41.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-04",
    value: 85,
    reportType: "Calendar",
    previousDate: "2023-09-04",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 241.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 300.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-03",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-09-03",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 21.79,
          format: "Currency",
        },
        Discounts: {
          value: 295.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-02",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-09-02",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 69.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 228,
          format: "Number",
        },
        Returns: {
          value: 1.83,
          format: "Currency",
        },
        Discounts: {
          value: 314.82,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-09-01",
    value: 107,
    reportType: "Calendar",
    previousDate: "2023-09-01",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 13.1,
          format: "Currency",
        },
        Discounts: {
          value: 87.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 104,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 482.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-31",
    value: 121,
    reportType: "Calendar",
    previousDate: "2023-08-31",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 49.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 32.01000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 252.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-30",
    value: 115,
    reportType: "Calendar",
    previousDate: "2023-08-30",
    tooltipData: {
      current: {
        value: {
          value: 115,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 72.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 278.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-29",
    value: 101,
    reportType: "Calendar",
    previousDate: "2023-08-29",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 193.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-28",
    value: 68,
    reportType: "Calendar",
    previousDate: "2023-08-28",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 16.6,
          format: "Currency",
        },
        Discounts: {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 282.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-27",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-08-27",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        Returns: {
          value: 1.54,
          format: "Currency",
        },
        Discounts: {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 118,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 16.87,
          format: "Currency",
        },
        Discounts: {
          value: 382.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-26",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-08-26",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 1.46,
          format: "Currency",
        },
        Discounts: {
          value: 63.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 15.71,
          format: "Currency",
        },
        Discounts: {
          value: 296.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-25",
    value: 116,
    reportType: "Calendar",
    previousDate: "2023-08-25",
    tooltipData: {
      current: {
        value: {
          value: 116,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 19,
          format: "Currency",
        },
        Discounts: {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 278.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-24",
    value: 102,
    reportType: "Calendar",
    previousDate: "2023-08-24",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 26.5,
          format: "Currency",
        },
        Discounts: {
          value: 336.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-23",
    value: 106,
    reportType: "Calendar",
    previousDate: "2023-08-23",
    tooltipData: {
      current: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 20.3,
          format: "Currency",
        },
        Discounts: {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 158.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-22",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-08-22",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 2.4,
          format: "Currency",
        },
        Discounts: {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 4.39,
          format: "Currency",
        },
        Discounts: {
          value: 325.93,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-21",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-08-21",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 33.1,
          format: "Currency",
        },
        Discounts: {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 33.75,
          format: "Currency",
        },
        Discounts: {
          value: 114.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-20",
    value: 78,
    reportType: "Calendar",
    previousDate: "2023-08-20",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 21.75,
          format: "Currency",
        },
        Discounts: {
          value: 177.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-19",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-08-19",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 140,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 9.54,
          format: "Currency",
        },
        Discounts: {
          value: 270.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-18",
    value: 133,
    reportType: "Calendar",
    previousDate: "2023-08-18",
    tooltipData: {
      current: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 193,
          format: "Number",
        },
        Returns: {
          value: 53.64999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 192.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-17",
    value: 106,
    reportType: "Calendar",
    previousDate: "2023-08-17",
    tooltipData: {
      current: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 31.97,
          format: "Currency",
        },
        Discounts: {
          value: 151.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-16",
    value: 85,
    reportType: "Calendar",
    previousDate: "2023-08-16",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 175.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-15",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-08-15",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 20,
          format: "Currency",
        },
        Discounts: {
          value: 187.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-14",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-08-14",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 21.09,
          format: "Currency",
        },
        Discounts: {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 15.15,
          format: "Currency",
        },
        Discounts: {
          value: 102.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-13",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-08-13",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 10.93,
          format: "Currency",
        },
        Discounts: {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 149,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 18.22,
          format: "Currency",
        },
        Discounts: {
          value: 265.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-12",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-08-12",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 5.87,
          format: "Currency",
        },
        Discounts: {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 129,
          format: "Currency",
        },
        Customers: {
          value: 129,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 1.26,
          format: "Currency",
        },
        Discounts: {
          value: 246.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-11",
    value: 115,
    reportType: "Calendar",
    previousDate: "2023-08-11",
    tooltipData: {
      current: {
        value: {
          value: 115,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 8.16,
          format: "Currency",
        },
        Discounts: {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 184.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-10",
    value: 130,
    reportType: "Calendar",
    previousDate: "2023-08-10",
    tooltipData: {
      current: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 6.55,
          format: "Currency",
        },
        Discounts: {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 7.25,
          format: "Currency",
        },
        Discounts: {
          value: 121.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-09",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-08-09",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 1.39,
          format: "Currency",
        },
        Discounts: {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 15.69,
          format: "Currency",
        },
        Discounts: {
          value: 113.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-08",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-08-08",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 128.76,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-07",
    value: 100,
    reportType: "Calendar",
    previousDate: "2023-08-07",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 7.05,
          format: "Currency",
        },
        Discounts: {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 174.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-06",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-08-06",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 1.8,
          format: "Currency",
        },
        Discounts: {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 27.86,
          format: "Currency",
        },
        Discounts: {
          value: 203.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-05",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-08-05",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 4.87,
          format: "Currency",
        },
        Discounts: {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 3.09,
          format: "Currency",
        },
        Discounts: {
          value: 253.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-04",
    value: 127,
    reportType: "Calendar",
    previousDate: "2023-08-04",
    tooltipData: {
      current: {
        value: {
          value: 127,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 18.26,
          format: "Currency",
        },
        Discounts: {
          value: 182.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-03",
    value: 131,
    reportType: "Calendar",
    previousDate: "2023-08-03",
    tooltipData: {
      current: {
        value: {
          value: 131,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 23.5,
          format: "Currency",
        },
        Discounts: {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 5.5,
          format: "Currency",
        },
        Discounts: {
          value: 124.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-02",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-08-02",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 40,
          format: "Currency",
        },
        Discounts: {
          value: 172.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-08-01",
    value: 89,
    reportType: "Calendar",
    previousDate: "2023-08-01",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 44,
          format: "Currency",
        },
        Discounts: {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 2.13,
          format: "Currency",
        },
        Discounts: {
          value: 125.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-31",
    value: 71,
    reportType: "Calendar",
    previousDate: "2023-07-31",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 200.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 168.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-30",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-07-30",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 44.08000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 3.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 172.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-29",
    value: 74,
    reportType: "Calendar",
    previousDate: "2023-07-29",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 30.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 210.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-28",
    value: 96,
    reportType: "Calendar",
    previousDate: "2023-07-28",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 5.45,
          format: "Currency",
        },
        Discounts: {
          value: 163.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 4.6,
          format: "Currency",
        },
        Discounts: {
          value: 134.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-27",
    value: 101,
    reportType: "Calendar",
    previousDate: "2023-07-27",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 12.15,
          format: "Currency",
        },
        Discounts: {
          value: 22.64,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-26",
    value: 108,
    reportType: "Calendar",
    previousDate: "2023-07-26",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 87.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 8.899999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 120.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-25",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-07-25",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 43.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 157.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-24",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-07-24",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 8.690000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 142.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 2.8,
          format: "Currency",
        },
        Discounts: {
          value: 114.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-23",
    value: 78,
    reportType: "Calendar",
    previousDate: "2023-07-23",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 162.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-22",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-07-22",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 9.6,
          format: "Currency",
        },
        Discounts: {
          value: 11.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 127.3,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-21",
    value: 107,
    reportType: "Calendar",
    previousDate: "2023-07-21",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 16.02,
          format: "Currency",
        },
        Discounts: {
          value: 57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 299,
          format: "Number",
        },
        Returns: {
          value: 75.47999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 230.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-20",
    value: 100,
    reportType: "Calendar",
    previousDate: "2023-07-20",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 1.8,
          format: "Currency",
        },
        Discounts: {
          value: 189.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 129.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-19",
    value: 100,
    reportType: "Calendar",
    previousDate: "2023-07-19",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0.63,
          format: "Currency",
        },
        Discounts: {
          value: 96.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 19.43,
          format: "Currency",
        },
        Discounts: {
          value: 118.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-18",
    value: 102,
    reportType: "Calendar",
    previousDate: "2023-07-18",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 2.2,
          format: "Currency",
        },
        Discounts: {
          value: 35.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-17",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-07-17",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 319.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-16",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-07-16",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 195.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-15",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-07-15",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 18.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 42.85,
          format: "Currency",
        },
        Discounts: {
          value: 180.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-14",
    value: 108,
    reportType: "Calendar",
    previousDate: "2023-07-14",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 27.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 144.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-13",
    value: 109,
    reportType: "Calendar",
    previousDate: "2023-07-13",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 45.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 11.57,
          format: "Currency",
        },
        Discounts: {
          value: 127.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-12",
    value: 89,
    reportType: "Calendar",
    previousDate: "2023-07-12",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 47.49,
          format: "Currency",
        },
        Discounts: {
          value: 37.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 122.22,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-11",
    value: 71,
    reportType: "Calendar",
    previousDate: "2023-07-11",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 14.86,
          format: "Currency",
        },
        Discounts: {
          value: 379,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 82.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-10",
    value: 19,
    reportType: "Calendar",
    previousDate: "2023-07-10",
    tooltipData: {
      current: {
        value: {
          value: 19,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10.76,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 4.17,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-09",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-07-09",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 190,
          format: "Number",
        },
        Returns: {
          value: 34.61,
          format: "Currency",
        },
        Discounts: {
          value: 180.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-08",
    value: 0,
    reportType: "Calendar",
    previousDate: "2023-07-08",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 2.32,
          format: "Currency",
        },
        Discounts: {
          value: 161.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-07",
    value: 149,
    reportType: "Calendar",
    previousDate: "2023-07-07",
    tooltipData: {
      current: {
        value: {
          value: 149,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 5.029999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 55.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.34,
          format: "Currency",
        },
        Discounts: {
          value: 189.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-06",
    value: 112,
    reportType: "Calendar",
    previousDate: "2023-07-06",
    tooltipData: {
      current: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 3.06,
          format: "Currency",
        },
        Discounts: {
          value: 25.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-05",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-07-05",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 7.4,
          format: "Currency",
        },
        Discounts: {
          value: 55.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.199999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 89.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-04",
    value: 99,
    reportType: "Calendar",
    previousDate: "2023-07-04",
    tooltipData: {
      current: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 1.43,
          format: "Currency",
        },
        Discounts: {
          value: 115.22,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 42,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 68.49,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-03",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-07-03",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 3.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 1.78,
          format: "Currency",
        },
        Discounts: {
          value: 132.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-02",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-07-02",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 90,
          format: "Number",
        },
        Returns: {
          value: 2.6,
          format: "Currency",
        },
        Discounts: {
          value: 3.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.71,
          format: "Currency",
        },
        Discounts: {
          value: 157.84,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-07-01",
    value: 74,
    reportType: "Calendar",
    previousDate: "2023-07-01",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 6.86,
          format: "Currency",
        },
        Discounts: {
          value: 35.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 9.77,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-30",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-06-30",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 19.4,
          format: "Currency",
        },
        Discounts: {
          value: 41.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 12.28,
          format: "Currency",
        },
        Discounts: {
          value: 96.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-29",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-06-29",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 46.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 12.7,
          format: "Currency",
        },
        Discounts: {
          value: 127.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-28",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-06-28",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 39.98,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 101.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-27",
    value: 78,
    reportType: "Calendar",
    previousDate: "2023-06-27",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 87.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 227.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-26",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-06-26",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 12.85,
          format: "Currency",
        },
        Discounts: {
          value: 490.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 207.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-25",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-06-25",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 4.1,
          format: "Currency",
        },
        Discounts: {
          value: 3.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 28,
          format: "Currency",
        },
        Discounts: {
          value: 191.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-24",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-06-24",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 42.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 63.83,
          format: "Currency",
        },
        Discounts: {
          value: 201.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-23",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-06-23",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 9.85,
          format: "Currency",
        },
        Discounts: {
          value: 23.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 116.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-22",
    value: 113,
    reportType: "Calendar",
    previousDate: "2023-06-22",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 6.99,
          format: "Currency",
        },
        Discounts: {
          value: 16.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0.5,
          format: "Currency",
        },
        Discounts: {
          value: 163.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-21",
    value: 103,
    reportType: "Calendar",
    previousDate: "2023-06-21",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 26.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 2.31,
          format: "Currency",
        },
        Discounts: {
          value: 115.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-20",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-06-20",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 1.63,
          format: "Currency",
        },
        Discounts: {
          value: 155.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 23.66,
          format: "Currency",
        },
        Discounts: {
          value: 116.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-19",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-06-19",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 12.89,
          format: "Currency",
        },
        Discounts: {
          value: 87.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 99.9,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-18",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-06-18",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 1.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 180.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-17",
    value: 69,
    reportType: "Calendar",
    previousDate: "2023-06-17",
    tooltipData: {
      current: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 8.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 42.01,
          format: "Currency",
        },
        Discounts: {
          value: 177.76,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-16",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-06-16",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 12.99,
          format: "Currency",
        },
        Discounts: {
          value: 90.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 20.95,
          format: "Currency",
        },
        Discounts: {
          value: 169.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-15",
    value: 155,
    reportType: "Calendar",
    previousDate: "2023-06-15",
    tooltipData: {
      current: {
        value: {
          value: 155,
          format: "Currency",
        },
        Customers: {
          value: 155,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 66.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 136.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-14",
    value: 106,
    reportType: "Calendar",
    previousDate: "2023-06-14",
    tooltipData: {
      current: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 67.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 51,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 109.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-13",
    value: 108,
    reportType: "Calendar",
    previousDate: "2023-06-13",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 13.46,
          format: "Currency",
        },
        Discounts: {
          value: 49.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 11.15,
          format: "Currency",
        },
        Discounts: {
          value: 114.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-12",
    value: 101,
    reportType: "Calendar",
    previousDate: "2023-06-12",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 275.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0.55,
          format: "Currency",
        },
        Discounts: {
          value: 133.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-11",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-06-11",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 12.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 9.39,
          format: "Currency",
        },
        Discounts: {
          value: 176.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-10",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-06-10",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 33.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 141.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-09",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-06-09",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 23.04,
          format: "Currency",
        },
        Discounts: {
          value: 40.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 4.97,
          format: "Currency",
        },
        Discounts: {
          value: 133.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-08",
    value: 121,
    reportType: "Calendar",
    previousDate: "2023-06-08",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 43.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-07",
    value: 109,
    reportType: "Calendar",
    previousDate: "2023-06-07",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 20.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 27.71,
          format: "Currency",
        },
        Discounts: {
          value: 112.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-06",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-06-06",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 64.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 63,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 154.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-05",
    value: 71,
    reportType: "Calendar",
    previousDate: "2023-06-05",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 9.73,
          format: "Currency",
        },
        Discounts: {
          value: 203.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 59,
          format: "Currency",
        },
        Customers: {
          value: 59,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 8.1,
          format: "Currency",
        },
        Discounts: {
          value: 107.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-04",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-06-04",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 23.08,
          format: "Currency",
        },
        Discounts: {
          value: 17.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 35.17,
          format: "Currency",
        },
        Discounts: {
          value: 140.64,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-03",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-06-03",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 5.779999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 11.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 156.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-02",
    value: 123,
    reportType: "Calendar",
    previousDate: "2023-06-02",
    tooltipData: {
      current: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 95.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 130.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-06-01",
    value: 119,
    reportType: "Calendar",
    previousDate: "2023-06-01",
    tooltipData: {
      current: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 130.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 19.66,
          format: "Currency",
        },
        Discounts: {
          value: 111.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-31",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-05-31",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 40.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 6.109999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 176.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-30",
    value: 79,
    reportType: "Calendar",
    previousDate: "2023-05-30",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 11.67,
          format: "Currency",
        },
        Discounts: {
          value: 105.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-29",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-05-29",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 198.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 5.2,
          format: "Currency",
        },
        Discounts: {
          value: 106.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-28",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-05-28",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-27",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-05-27",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 17.53,
          format: "Currency",
        },
        Discounts: {
          value: 20.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 18.6,
          format: "Currency",
        },
        Discounts: {
          value: 194.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-26",
    value: 125,
    reportType: "Calendar",
    previousDate: "2023-05-26",
    tooltipData: {
      current: {
        value: {
          value: 125,
          format: "Currency",
        },
        Customers: {
          value: 125,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 54,
          format: "Currency",
        },
        Discounts: {
          value: 30.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 20.79,
          format: "Currency",
        },
        Discounts: {
          value: 176.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-25",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-05-25",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 72.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 16.85,
          format: "Currency",
        },
        Discounts: {
          value: 188.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-24",
    value: 120,
    reportType: "Calendar",
    previousDate: "2023-05-24",
    tooltipData: {
      current: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 7.52,
          format: "Currency",
        },
        Discounts: {
          value: 36.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.08,
          format: "Currency",
        },
        Discounts: {
          value: 115.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-23",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-05-23",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 5.4,
          format: "Currency",
        },
        Discounts: {
          value: 64.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 1.73,
          format: "Currency",
        },
        Discounts: {
          value: 129.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-22",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-05-22",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 6.7,
          format: "Currency",
        },
        Discounts: {
          value: 273.68,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 113.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-21",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-05-21",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 6.699999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 6.22,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 244,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 262.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-20",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-05-20",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 98.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 180.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-19",
    value: 137,
    reportType: "Calendar",
    previousDate: "2023-05-19",
    tooltipData: {
      current: {
        value: {
          value: 137,
          format: "Currency",
        },
        Customers: {
          value: 137,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 99.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 21.17,
          format: "Currency",
        },
        Discounts: {
          value: 145.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-18",
    value: 133,
    reportType: "Calendar",
    previousDate: "2023-05-18",
    tooltipData: {
      current: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 24.4,
          format: "Currency",
        },
        Discounts: {
          value: 39.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-17",
    value: 128,
    reportType: "Calendar",
    previousDate: "2023-05-17",
    tooltipData: {
      current: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 10.5,
          format: "Currency",
        },
        Discounts: {
          value: 57.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 21.08,
          format: "Currency",
        },
        Discounts: {
          value: 129.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-16",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-05-16",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 72.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 26.65,
          format: "Currency",
        },
        Discounts: {
          value: 129.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-15",
    value: 97,
    reportType: "Calendar",
    previousDate: "2023-05-15",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 1.33,
          format: "Currency",
        },
        Discounts: {
          value: 229.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 44,
          format: "Currency",
        },
        Customers: {
          value: 44,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.38,
          format: "Currency",
        },
        Discounts: {
          value: 85.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-14",
    value: 88,
    reportType: "Calendar",
    previousDate: "2023-05-14",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 12.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 205.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-13",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-05-13",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 2.85,
          format: "Currency",
        },
        Discounts: {
          value: 37.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 65.33,
          format: "Currency",
        },
        Discounts: {
          value: 184.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-12",
    value: 117,
    reportType: "Calendar",
    previousDate: "2023-05-12",
    tooltipData: {
      current: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 22.18,
          format: "Currency",
        },
        Discounts: {
          value: 24.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-11",
    value: 114,
    reportType: "Calendar",
    previousDate: "2023-05-11",
    tooltipData: {
      current: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 7.2,
          format: "Currency",
        },
        Discounts: {
          value: 45.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 1.74,
          format: "Currency",
        },
        Discounts: {
          value: 156.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-10",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-05-10",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 7.6,
          format: "Currency",
        },
        Discounts: {
          value: 84.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 121.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-09",
    value: 102,
    reportType: "Calendar",
    previousDate: "2023-05-09",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 147.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 148.07,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-08",
    value: 87,
    reportType: "Calendar",
    previousDate: "2023-05-08",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 4.24,
          format: "Currency",
        },
        Discounts: {
          value: 298.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 7.45,
          format: "Currency",
        },
        Discounts: {
          value: 146.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-07",
    value: 84,
    reportType: "Calendar",
    previousDate: "2023-05-07",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 21.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 235,
          format: "Number",
        },
        Returns: {
          value: 78.25,
          format: "Currency",
        },
        Discounts: {
          value: 199.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-06",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-05-06",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 8.8,
          format: "Currency",
        },
        Discounts: {
          value: 13.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 243,
          format: "Number",
        },
        Returns: {
          value: 37.57,
          format: "Currency",
        },
        Discounts: {
          value: 265.82,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-05",
    value: 123,
    reportType: "Calendar",
    previousDate: "2023-05-05",
    tooltipData: {
      current: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 7.8,
          format: "Currency",
        },
        Discounts: {
          value: 59.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 210.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-04",
    value: 110,
    reportType: "Calendar",
    previousDate: "2023-05-04",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-03",
    value: 122,
    reportType: "Calendar",
    previousDate: "2023-05-03",
    tooltipData: {
      current: {
        value: {
          value: 122,
          format: "Currency",
        },
        Customers: {
          value: 122,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 63.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 153.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-02",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-05-02",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 93.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 187.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-05-01",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-05-01",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 7,
          format: "Currency",
        },
        Discounts: {
          value: 203.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 91.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-30",
    value: 54,
    reportType: "Calendar",
    previousDate: "2023-04-30",
    tooltipData: {
      current: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 1.6,
          format: "Currency",
        },
        Discounts: {
          value: 123.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 5.46,
          format: "Currency",
        },
        Discounts: {
          value: 207.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-29",
    value: 58,
    reportType: "Calendar",
    previousDate: "2023-04-29",
    tooltipData: {
      current: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 17.29,
          format: "Currency",
        },
        Discounts: {
          value: 143.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 234,
          format: "Number",
        },
        Returns: {
          value: 33.05,
          format: "Currency",
        },
        Discounts: {
          value: 181.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-28",
    value: 86,
    reportType: "Calendar",
    previousDate: "2023-04-28",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 18.79,
          format: "Currency",
        },
        Discounts: {
          value: 278.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 216.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-27",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-04-27",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 97.17,
          format: "Currency",
        },
        Discounts: {
          value: 191.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-26",
    value: 54,
    reportType: "Calendar",
    previousDate: "2023-04-26",
    tooltipData: {
      current: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 181.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 2.45,
          format: "Currency",
        },
        Discounts: {
          value: 136.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-25",
    value: 49,
    reportType: "Calendar",
    previousDate: "2023-04-25",
    tooltipData: {
      current: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 17.55,
          format: "Currency",
        },
        Discounts: {
          value: 141.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.26,
          format: "Currency",
        },
        Discounts: {
          value: 148.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-24",
    value: 59,
    reportType: "Calendar",
    previousDate: "2023-04-24",
    tooltipData: {
      current: {
        value: {
          value: 59,
          format: "Currency",
        },
        Customers: {
          value: 59,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 398.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 39.58000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 143.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-23",
    value: 48,
    reportType: "Calendar",
    previousDate: "2023-04-23",
    tooltipData: {
      current: {
        value: {
          value: 48,
          format: "Currency",
        },
        Customers: {
          value: 48,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 5.27,
          format: "Currency",
        },
        Discounts: {
          value: 75.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 20.9,
          format: "Currency",
        },
        Discounts: {
          value: 211.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-22",
    value: 62,
    reportType: "Calendar",
    previousDate: "2023-04-22",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 1,
          format: "Currency",
        },
        Discounts: {
          value: 128.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 245,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 207.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-21",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-04-21",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 199.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 173.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-20",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-04-20",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 18,
          format: "Currency",
        },
        Discounts: {
          value: 235.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 10.8,
          format: "Currency",
        },
        Discounts: {
          value: 283.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-19",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-04-19",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 4.17,
          format: "Currency",
        },
        Discounts: {
          value: 237.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 199.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-18",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-04-18",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 1.5,
          format: "Currency",
        },
        Discounts: {
          value: 295.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 6.17,
          format: "Currency",
        },
        Discounts: {
          value: 142.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-17",
    value: 55,
    reportType: "Calendar",
    previousDate: "2023-04-17",
    tooltipData: {
      current: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 26.66,
          format: "Currency",
        },
        Discounts: {
          value: 174.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 164.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-16",
    value: 56,
    reportType: "Calendar",
    previousDate: "2023-04-16",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 4.81,
          format: "Currency",
        },
        Discounts: {
          value: 131.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 12.69,
          format: "Currency",
        },
        Discounts: {
          value: 222.09,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-15",
    value: 55,
    reportType: "Calendar",
    previousDate: "2023-04-15",
    tooltipData: {
      current: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 4.75,
          format: "Currency",
        },
        Discounts: {
          value: 137.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 42.93,
          format: "Currency",
        },
        Discounts: {
          value: 282.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-14",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-04-14",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 204.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 21.91,
          format: "Currency",
        },
        Discounts: {
          value: 214.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-13",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-04-13",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 237.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 1.55,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-12",
    value: 69,
    reportType: "Calendar",
    previousDate: "2023-04-12",
    tooltipData: {
      current: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 265.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 14.63,
          format: "Currency",
        },
        Discounts: {
          value: 124.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-11",
    value: 52,
    reportType: "Calendar",
    previousDate: "2023-04-11",
    tooltipData: {
      current: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 438.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-10",
    value: 52,
    reportType: "Calendar",
    previousDate: "2023-04-10",
    tooltipData: {
      current: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 7.42,
          format: "Currency",
        },
        Discounts: {
          value: 87.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 9.1,
          format: "Currency",
        },
        Discounts: {
          value: 140.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-09",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-04-09",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 27.51,
          format: "Currency",
        },
        Discounts: {
          value: 169.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 266,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 228.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-08",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-04-08",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 12,
          format: "Currency",
        },
        Discounts: {
          value: 224.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 151,
          format: "Currency",
        },
        Customers: {
          value: 151,
          format: "Number",
        },
        Transactions: {
          value: 260,
          format: "Number",
        },
        Returns: {
          value: 18.95,
          format: "Currency",
        },
        Discounts: {
          value: 339.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-07",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-04-07",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 4.33,
          format: "Currency",
        },
        Discounts: {
          value: 352.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 14.45,
          format: "Currency",
        },
        Discounts: {
          value: 229.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-06",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-04-06",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 277.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 20.15,
          format: "Currency",
        },
        Discounts: {
          value: 203.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-05",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-04-05",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 155.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 9.19,
          format: "Currency",
        },
        Discounts: {
          value: 131.23,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-04",
    value: 62,
    reportType: "Calendar",
    previousDate: "2023-04-04",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 18.03,
          format: "Currency",
        },
        Discounts: {
          value: 511.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 63,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 2.53,
          format: "Currency",
        },
        Discounts: {
          value: 101.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-03",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-04-03",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 260.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 31.64,
          format: "Currency",
        },
        Discounts: {
          value: 126.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-02",
    value: 57,
    reportType: "Calendar",
    previousDate: "2023-04-02",
    tooltipData: {
      current: {
        value: {
          value: 57,
          format: "Currency",
        },
        Customers: {
          value: 57,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 15.41,
          format: "Currency",
        },
        Discounts: {
          value: 120.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 261,
          format: "Number",
        },
        Returns: {
          value: 31.81,
          format: "Currency",
        },
        Discounts: {
          value: 253.68,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-04-01",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-04-01",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 140.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 225,
          format: "Number",
        },
        Returns: {
          value: 17.85,
          format: "Currency",
        },
        Discounts: {
          value: 223.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-31",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-03-31",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 21,
          format: "Currency",
        },
        Discounts: {
          value: 314.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 118,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 222.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-30",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-03-30",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 7.91,
          format: "Currency",
        },
        Discounts: {
          value: 245.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 179.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-29",
    value: 85,
    reportType: "Calendar",
    previousDate: "2023-03-29",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 9.57,
          format: "Currency",
        },
        Discounts: {
          value: 213.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 153.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-28",
    value: 68,
    reportType: "Calendar",
    previousDate: "2023-03-28",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 9.9,
          format: "Currency",
        },
        Discounts: {
          value: 277.73,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 12.33,
          format: "Currency",
        },
        Discounts: {
          value: 132.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-27",
    value: 62,
    reportType: "Calendar",
    previousDate: "2023-03-27",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 15.55,
          format: "Currency",
        },
        Discounts: {
          value: 400.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 57,
          format: "Currency",
        },
        Customers: {
          value: 57,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.28,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-26",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-03-26",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 102.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 29.4,
          format: "Currency",
        },
        Discounts: {
          value: 234.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-25",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-03-25",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0.83,
          format: "Currency",
        },
        Discounts: {
          value: 151.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 18.98,
          format: "Currency",
        },
        Discounts: {
          value: 221.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-24",
    value: 79,
    reportType: "Calendar",
    previousDate: "2023-03-24",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 5.2,
          format: "Currency",
        },
        Discounts: {
          value: 227.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 18.71,
          format: "Currency",
        },
        Discounts: {
          value: 169.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-23",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-03-23",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 244.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 206,
          format: "Number",
        },
        Returns: {
          value: 37.4,
          format: "Currency",
        },
        Discounts: {
          value: 130.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-22",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-03-22",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 247.12,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 181.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-21",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-03-21",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 186.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 4.55,
          format: "Currency",
        },
        Discounts: {
          value: 85.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-20",
    value: 49,
    reportType: "Calendar",
    previousDate: "2023-03-20",
    tooltipData: {
      current: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 9.05,
          format: "Currency",
        },
        Discounts: {
          value: 473.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-19",
    value: 53,
    reportType: "Calendar",
    previousDate: "2023-03-19",
    tooltipData: {
      current: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 6.89,
          format: "Currency",
        },
        Discounts: {
          value: 122.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 12.39,
          format: "Currency",
        },
        Discounts: {
          value: 121.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-18",
    value: 95,
    reportType: "Calendar",
    previousDate: "2023-03-18",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 26.9,
          format: "Currency",
        },
        Discounts: {
          value: 178.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 23.16,
          format: "Currency",
        },
        Discounts: {
          value: 126.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-17",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-03-17",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 147.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-16",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-03-16",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 5.48,
          format: "Currency",
        },
        Discounts: {
          value: 239.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 149.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-15",
    value: 63,
    reportType: "Calendar",
    previousDate: "2023-03-15",
    tooltipData: {
      current: {
        value: {
          value: 63,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 286.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.32,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-14",
    value: 74,
    reportType: "Calendar",
    previousDate: "2023-03-14",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 22.4,
          format: "Currency",
        },
        Discounts: {
          value: 341.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 78.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-13",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-03-13",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 16.75,
          format: "Currency",
        },
        Discounts: {
          value: 360.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 10,
          format: "Number",
        },
        Returns: {
          value: 1.13,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-12",
    value: 72,
    reportType: "Calendar",
    previousDate: "2023-03-12",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0.6,
          format: "Currency",
        },
        Discounts: {
          value: 128.12,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 4.21,
          format: "Currency",
        },
        Discounts: {
          value: 104.59,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-11",
    value: 108,
    reportType: "Calendar",
    previousDate: "2023-03-11",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 138.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 4.85,
          format: "Currency",
        },
        Discounts: {
          value: 101.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-10",
    value: 101,
    reportType: "Calendar",
    previousDate: "2023-03-10",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 325,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.3,
          format: "Currency",
        },
        Discounts: {
          value: 110.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-09",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-03-09",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 15.08,
          format: "Currency",
        },
        Discounts: {
          value: 279.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 42,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 103.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-08",
    value: 55,
    reportType: "Calendar",
    previousDate: "2023-03-08",
    tooltipData: {
      current: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 12.67,
          format: "Currency",
        },
        Discounts: {
          value: 147.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 38,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 74.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-07",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-03-07",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 14.48,
          format: "Currency",
        },
        Discounts: {
          value: 272.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 40,
          format: "Currency",
        },
        Customers: {
          value: 40,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 3.7,
          format: "Currency",
        },
        Discounts: {
          value: 72.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-06",
    value: 54,
    reportType: "Calendar",
    previousDate: "2023-03-06",
    tooltipData: {
      current: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 17.8,
          format: "Currency",
        },
        Discounts: {
          value: 394.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 41,
          format: "Currency",
        },
        Customers: {
          value: 41,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 94.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-05",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-03-05",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 10.43,
          format: "Currency",
        },
        Discounts: {
          value: 121.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 137.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-04",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-03-04",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        Returns: {
          value: 121.4,
          format: "Currency",
        },
        Discounts: {
          value: 258.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 101.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-03",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-03-03",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 19.29,
          format: "Currency",
        },
        Discounts: {
          value: 267.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 141.34,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-02",
    value: 91,
    reportType: "Calendar",
    previousDate: "2023-03-02",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 99.63,
          format: "Currency",
        },
        Discounts: {
          value: 243.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 120.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-03-01",
    value: 75,
    reportType: "Calendar",
    previousDate: "2023-03-01",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 10.1,
          format: "Currency",
        },
        Discounts: {
          value: 230.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 16.49,
          format: "Currency",
        },
        Discounts: {
          value: 127.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-29",
    value: 62,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 92.06,
          format: "Currency",
        },
        Discounts: {
          value: 309.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-28",
    value: 66,
    reportType: "Calendar",
    previousDate: "2023-02-28",
    tooltipData: {
      current: {
        value: {
          value: 66,
          format: "Currency",
        },
        Customers: {
          value: 66,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.49,
          format: "Currency",
        },
        Discounts: {
          value: 333.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 35,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 15.49,
          format: "Currency",
        },
        Discounts: {
          value: 70.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-27",
    value: 42,
    reportType: "Calendar",
    previousDate: "2023-02-27",
    tooltipData: {
      current: {
        value: {
          value: 42,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 4.6,
          format: "Currency",
        },
        Discounts: {
          value: 77.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 35,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 16.58,
          format: "Currency",
        },
        Discounts: {
          value: 53.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-26",
    value: 78,
    reportType: "Calendar",
    previousDate: "2023-02-26",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 6.1,
          format: "Currency",
        },
        Discounts: {
          value: 166.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 17.95,
          format: "Currency",
        },
        Discounts: {
          value: 139.02,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-25",
    value: 95,
    reportType: "Calendar",
    previousDate: "2023-02-25",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 160,
          format: "Number",
        },
        Returns: {
          value: 6.16,
          format: "Currency",
        },
        Discounts: {
          value: 338.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 249,
          format: "Number",
        },
        Returns: {
          value: 33.2,
          format: "Currency",
        },
        Discounts: {
          value: 162.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-24",
    value: 87,
    reportType: "Calendar",
    previousDate: "2023-02-24",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 5.2,
          format: "Currency",
        },
        Discounts: {
          value: 215.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.99,
          format: "Currency",
        },
        Discounts: {
          value: 138.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-23",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-02-23",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 13.21,
          format: "Currency",
        },
        Discounts: {
          value: 269.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 34.19,
          format: "Currency",
        },
        Discounts: {
          value: 150.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-22",
    value: 64,
    reportType: "Calendar",
    previousDate: "2023-02-22",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 3.09,
          format: "Currency",
        },
        Discounts: {
          value: 346.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 51,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 11.3,
          format: "Currency",
        },
        Discounts: {
          value: 80.27,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-21",
    value: 56,
    reportType: "Calendar",
    previousDate: "2023-02-21",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 33.31,
          format: "Currency",
        },
        Discounts: {
          value: 274.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 21.35,
          format: "Currency",
        },
        Discounts: {
          value: 110.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-20",
    value: 56,
    reportType: "Calendar",
    previousDate: "2023-02-20",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 27,
          format: "Currency",
        },
        Discounts: {
          value: 93.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 9.61,
          format: "Currency",
        },
        Discounts: {
          value: 89.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-19",
    value: 73,
    reportType: "Calendar",
    previousDate: "2023-02-19",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 21,
          format: "Currency",
        },
        Discounts: {
          value: 165.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 34,
          format: "Currency",
        },
        Customers: {
          value: 34,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 46.11,
          format: "Currency",
        },
        Discounts: {
          value: 105.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-18",
    value: 82,
    reportType: "Calendar",
    previousDate: "2023-02-18",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 199,
          format: "Number",
        },
        Returns: {
          value: 1.3,
          format: "Currency",
        },
        Discounts: {
          value: 362.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 38,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 16.63,
          format: "Currency",
        },
        Discounts: {
          value: 125.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-17",
    value: 76,
    reportType: "Calendar",
    previousDate: "2023-02-17",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 7.03,
          format: "Currency",
        },
        Discounts: {
          value: 172.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 31,
          format: "Currency",
        },
        Customers: {
          value: 31,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 74.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-16",
    value: 87,
    reportType: "Calendar",
    previousDate: "2023-02-16",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 12.7,
          format: "Currency",
        },
        Discounts: {
          value: 197.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 45,
          format: "Currency",
        },
        Discounts: {
          value: 34.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-15",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-02-15",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 23,
          format: "Currency",
        },
        Discounts: {
          value: 172.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 21,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 18.15,
          format: "Currency",
        },
        Discounts: {
          value: 32.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-14",
    value: 83,
    reportType: "Calendar",
    previousDate: "2023-02-14",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 15.7,
          format: "Currency",
        },
        Discounts: {
          value: 252.22,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 24,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 75.80999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 76.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-13",
    value: 57,
    reportType: "Calendar",
    previousDate: "2023-02-13",
    tooltipData: {
      current: {
        value: {
          value: 57,
          format: "Currency",
        },
        Customers: {
          value: 57,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 79.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 43.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-12",
    value: 96,
    reportType: "Calendar",
    previousDate: "2023-02-12",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 1.45,
          format: "Currency",
        },
        Discounts: {
          value: 296.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 24,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 5.68,
          format: "Currency",
        },
        Discounts: {
          value: 92.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-11",
    value: 113,
    reportType: "Calendar",
    previousDate: "2023-02-11",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 16.48,
          format: "Currency",
        },
        Discounts: {
          value: 227.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 55.47,
          format: "Currency",
        },
        Discounts: {
          value: 64.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-10",
    value: 80,
    reportType: "Calendar",
    previousDate: "2023-02-10",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 275.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 17,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 23.64,
          format: "Currency",
        },
        Discounts: {
          value: 52.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-09",
    value: 55,
    reportType: "Calendar",
    previousDate: "2023-02-09",
    tooltipData: {
      current: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 21.5,
          format: "Currency",
        },
        Discounts: {
          value: 215.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.55,
          format: "Currency",
        },
        Discounts: {
          value: 29.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-08",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-02-08",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 311.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 46.37,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-07",
    value: 68,
    reportType: "Calendar",
    previousDate: "2023-02-07",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 275.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 17,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 35.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-06",
    value: 47,
    reportType: "Calendar",
    previousDate: "2023-02-06",
    tooltipData: {
      current: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 18.4,
          format: "Currency",
        },
        Discounts: {
          value: 108.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 13.05,
          format: "Currency",
        },
        Discounts: {
          value: 41.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-05",
    value: 43,
    reportType: "Calendar",
    previousDate: "2023-02-05",
    tooltipData: {
      current: {
        value: {
          value: 43,
          format: "Currency",
        },
        Customers: {
          value: 43,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 19.91,
          format: "Currency",
        },
        Discounts: {
          value: 118.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 233,
          format: "Number",
        },
        Returns: {
          value: 5.69,
          format: "Currency",
        },
        Discounts: {
          value: 50.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-04",
    value: 92,
    reportType: "Calendar",
    previousDate: "2023-02-04",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 21.5,
          format: "Currency",
        },
        Discounts: {
          value: 279.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 41.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-03",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-02-03",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 3.13,
          format: "Currency",
        },
        Discounts: {
          value: 187.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 19,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.71,
          format: "Currency",
        },
        Discounts: {
          value: 47.43,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-02",
    value: 65,
    reportType: "Calendar",
    previousDate: "2023-02-02",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 198.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-02-01",
    value: 56,
    reportType: "Calendar",
    previousDate: "2023-02-01",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 30.77,
          format: "Currency",
        },
        Discounts: {
          value: 349.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 40.25,
          format: "Currency",
        },
        Discounts: {
          value: 6.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-31",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-01-31",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 108.9,
          format: "Currency",
        },
        Discounts: {
          value: 358.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 70.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-30",
    value: 61,
    reportType: "Calendar",
    previousDate: "2023-01-30",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 23.5,
          format: "Currency",
        },
        Discounts: {
          value: 119.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 8.8,
          format: "Currency",
        },
        Discounts: {
          value: 36.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-29",
    value: 60,
    reportType: "Calendar",
    previousDate: "2023-01-29",
    tooltipData: {
      current: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 16.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-28",
    value: 142,
    reportType: "Calendar",
    previousDate: "2023-01-28",
    tooltipData: {
      current: {
        value: {
          value: 142,
          format: "Currency",
        },
        Customers: {
          value: 142,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 27.2,
          format: "Currency",
        },
        Discounts: {
          value: 307.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 23,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 254,
          format: "Number",
        },
        Returns: {
          value: 32.09,
          format: "Currency",
        },
        Discounts: {
          value: 49.72,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-27",
    value: 137,
    reportType: "Calendar",
    previousDate: "2023-01-27",
    tooltipData: {
      current: {
        value: {
          value: 137,
          format: "Currency",
        },
        Customers: {
          value: 137,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 16.08,
          format: "Currency",
        },
        Discounts: {
          value: 333.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 10.9,
          format: "Currency",
        },
        Discounts: {
          value: 23.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-26",
    value: 94,
    reportType: "Calendar",
    previousDate: "2023-01-26",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 9.030000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 199.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 23.34999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 13.87,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-25",
    value: 71,
    reportType: "Calendar",
    previousDate: "2023-01-25",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 321.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 30.01,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-24",
    value: 70,
    reportType: "Calendar",
    previousDate: "2023-01-24",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 32.59999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 200.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 5.27,
          format: "Currency",
        },
        Discounts: {
          value: 51.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-23",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-01-23",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 144.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 33.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-22",
    value: 60,
    reportType: "Calendar",
    previousDate: "2023-01-22",
    tooltipData: {
      current: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 232.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.46,
          format: "Currency",
        },
        Discounts: {
          value: 95.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-21",
    value: 126,
    reportType: "Calendar",
    previousDate: "2023-01-21",
    tooltipData: {
      current: {
        value: {
          value: 126,
          format: "Currency",
        },
        Customers: {
          value: 126,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 272.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 11.5,
          format: "Currency",
        },
        Discounts: {
          value: 46.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-20",
    value: 126,
    reportType: "Calendar",
    previousDate: "2023-01-20",
    tooltipData: {
      current: {
        value: {
          value: 126,
          format: "Currency",
        },
        Customers: {
          value: 126,
          format: "Number",
        },
        Transactions: {
          value: 196,
          format: "Number",
        },
        Returns: {
          value: 1,
          format: "Currency",
        },
        Discounts: {
          value: 300.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 32.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-19",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-01-19",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0.83,
          format: "Currency",
        },
        Discounts: {
          value: 245.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 20.67,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-18",
    value: 81,
    reportType: "Calendar",
    previousDate: "2023-01-18",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 8.3,
          format: "Currency",
        },
        Discounts: {
          value: 283,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 29.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-17",
    value: 90,
    reportType: "Calendar",
    previousDate: "2023-01-17",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 1.96,
          format: "Currency",
        },
        Discounts: {
          value: 195.01,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 15.77,
          format: "Currency",
        },
        Discounts: {
          value: 4.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-16",
    value: 53,
    reportType: "Calendar",
    previousDate: "2023-01-16",
    tooltipData: {
      current: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 69,
          format: "Number",
        },
        Returns: {
          value: 1.27,
          format: "Currency",
        },
        Discounts: {
          value: 76.56,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.62,
          format: "Currency",
        },
        Discounts: {
          value: 7.28,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-15",
    value: 67,
    reportType: "Calendar",
    previousDate: "2023-01-15",
    tooltipData: {
      current: {
        value: {
          value: 67,
          format: "Currency",
        },
        Customers: {
          value: 67,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 115.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 7.1,
          format: "Currency",
        },
        Discounts: {
          value: 26.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-14",
    value: 138,
    reportType: "Calendar",
    previousDate: "2023-01-14",
    tooltipData: {
      current: {
        value: {
          value: 138,
          format: "Currency",
        },
        Customers: {
          value: 138,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 14.1,
          format: "Currency",
        },
        Discounts: {
          value: 290.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 15,
          format: "Currency",
        },
        Customers: {
          value: 15,
          format: "Number",
        },
        Transactions: {
          value: 255,
          format: "Number",
        },
        Returns: {
          value: 37.12,
          format: "Currency",
        },
        Discounts: {
          value: 57.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-13",
    value: 161,
    reportType: "Calendar",
    previousDate: "2023-01-13",
    tooltipData: {
      current: {
        value: {
          value: 161,
          format: "Currency",
        },
        Customers: {
          value: 161,
          format: "Number",
        },
        Transactions: {
          value: 224,
          format: "Number",
        },
        Returns: {
          value: 15.9,
          format: "Currency",
        },
        Discounts: {
          value: 416.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.55,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-12",
    value: 122,
    reportType: "Calendar",
    previousDate: "2023-01-12",
    tooltipData: {
      current: {
        value: {
          value: 122,
          format: "Currency",
        },
        Customers: {
          value: 122,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 22.97,
          format: "Currency",
        },
        Discounts: {
          value: 371.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.310000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-11",
    value: 110,
    reportType: "Calendar",
    previousDate: "2023-01-11",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 10.33,
          format: "Currency",
        },
        Discounts: {
          value: 194.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 20.02,
          format: "Currency",
        },
        Discounts: {
          value: 22.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-10",
    value: 79,
    reportType: "Calendar",
    previousDate: "2023-01-10",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 9.65,
          format: "Currency",
        },
        Discounts: {
          value: 196.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 41.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-09",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-01-09",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 57.4,
          format: "Currency",
        },
        Discounts: {
          value: 435.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 4.8,
          format: "Currency",
        },
        Discounts: {
          value: 12.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-08",
    value: 95,
    reportType: "Calendar",
    previousDate: "2023-01-08",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 160.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 7.69,
          format: "Currency",
        },
        Discounts: {
          value: 53.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-07",
    value: 136,
    reportType: "Calendar",
    previousDate: "2023-01-07",
    tooltipData: {
      current: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 3.95,
          format: "Currency",
        },
        Discounts: {
          value: 254.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 49.76,
          format: "Currency",
        },
        Discounts: {
          value: 46.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-06",
    value: 142,
    reportType: "Calendar",
    previousDate: "2023-01-06",
    tooltipData: {
      current: {
        value: {
          value: 142,
          format: "Currency",
        },
        Customers: {
          value: 142,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 210.93,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 8.57,
          format: "Currency",
        },
        Discounts: {
          value: 16.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-05",
    value: 111,
    reportType: "Calendar",
    previousDate: "2023-01-05",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 15.1,
          format: "Currency",
        },
        Discounts: {
          value: 255.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 21,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 11.45,
          format: "Currency",
        },
        Discounts: {
          value: 32.98,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-04",
    value: 98,
    reportType: "Calendar",
    previousDate: "2023-01-04",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 359.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 67.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-03",
    value: 104,
    reportType: "Calendar",
    previousDate: "2023-01-03",
    tooltipData: {
      current: {
        value: {
          value: 104,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 1.4,
          format: "Currency",
        },
        Discounts: {
          value: 208.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-02",
    value: 93,
    reportType: "Calendar",
    previousDate: "2023-01-02",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 178.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2024-01-01",
    value: 77,
    reportType: "Calendar",
    previousDate: "2023-01-01",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 143.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 76,
          format: "Number",
        },
        Returns: {
          value: 15.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-31",
    value: 133,
    reportType: "Calendar",
    previousDate: "2022-12-31",
    tooltipData: {
      current: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 37.08,
          format: "Currency",
        },
        Discounts: {
          value: 265.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 5.58,
          format: "Currency",
        },
        Discounts: {
          value: 59.67,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-30",
    value: 124,
    reportType: "Calendar",
    previousDate: "2022-12-30",
    tooltipData: {
      current: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 194,
          format: "Number",
        },
        Returns: {
          value: 44.66,
          format: "Currency",
        },
        Discounts: {
          value: 557.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.54,
          format: "Currency",
        },
        Discounts: {
          value: 34.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-29",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-12-29",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 222.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.91,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-28",
    value: 102,
    reportType: "Calendar",
    previousDate: "2022-12-28",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 258.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 49.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-27",
    value: 98,
    reportType: "Calendar",
    previousDate: "2022-12-27",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 604.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 5.78,
          format: "Currency",
        },
        Discounts: {
          value: 28.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-26",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-12-26",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 196.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 160,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-25",
    value: 52,
    reportType: "Calendar",
    previousDate: "2022-12-25",
    tooltipData: {
      current: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 100.37,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 18.89,
          format: "Currency",
        },
        Discounts: {
          value: 16.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-24",
    value: 160,
    reportType: "Calendar",
    previousDate: "2022-12-24",
    tooltipData: {
      current: {
        value: {
          value: 160,
          format: "Currency",
        },
        Customers: {
          value: 160,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 365.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 15.99,
          format: "Currency",
        },
        Discounts: {
          value: 31.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-23",
    value: 119,
    reportType: "Calendar",
    previousDate: "2022-12-23",
    tooltipData: {
      current: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 263.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-22",
    value: 117,
    reportType: "Calendar",
    previousDate: "2022-12-22",
    tooltipData: {
      current: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.99,
          format: "Currency",
        },
        Discounts: {
          value: 270.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 25.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-21",
    value: 111,
    reportType: "Calendar",
    previousDate: "2022-12-21",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.01,
          format: "Currency",
        },
        Discounts: {
          value: 383.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.4,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-20",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-12-20",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 147.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-19",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-12-19",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 10.6,
          format: "Currency",
        },
        Discounts: {
          value: 424.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 5.73,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-18",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-12-18",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 135.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-17",
    value: 114,
    reportType: "Calendar",
    previousDate: "2022-12-17",
    tooltipData: {
      current: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 216.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 15.46,
          format: "Currency",
        },
        Discounts: {
          value: 17.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-16",
    value: 124,
    reportType: "Calendar",
    previousDate: "2022-12-16",
    tooltipData: {
      current: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 16.43,
          format: "Currency",
        },
        Discounts: {
          value: 254.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-15",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-12-15",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 12.78,
          format: "Currency",
        },
        Discounts: {
          value: 241.07,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 27.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-14",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-12-14",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 13.52,
          format: "Currency",
        },
        Discounts: {
          value: 222.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 8.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-13",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-12-13",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 370.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 14.91,
          format: "Currency",
        },
        Discounts: {
          value: 4.19,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-12",
    value: 98,
    reportType: "Calendar",
    previousDate: "2022-12-12",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 230.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 17.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-11",
    value: 73,
    reportType: "Calendar",
    previousDate: "2022-12-11",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 210,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 11.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-10",
    value: 155,
    reportType: "Calendar",
    previousDate: "2022-12-10",
    tooltipData: {
      current: {
        value: {
          value: 155,
          format: "Currency",
        },
        Customers: {
          value: 155,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 315.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-09",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-12-09",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.05,
          format: "Currency",
        },
        Discounts: {
          value: 278.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-08",
    value: 112,
    reportType: "Calendar",
    previousDate: "2022-12-08",
    tooltipData: {
      current: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 10.27,
          format: "Currency",
        },
        Discounts: {
          value: 439.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 2.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-07",
    value: 98,
    reportType: "Calendar",
    previousDate: "2022-12-07",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 412.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-06",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-12-06",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 5.33,
          format: "Currency",
        },
        Discounts: {
          value: 250.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-05",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-12-05",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 230.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-04",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-12-04",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 5.94,
          format: "Currency",
        },
        Discounts: {
          value: 137.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 3.93,
          format: "Currency",
        },
        Discounts: {
          value: 6.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-03",
    value: 119,
    reportType: "Calendar",
    previousDate: "2022-12-03",
    tooltipData: {
      current: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 362.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 30.59,
          format: "Currency",
        },
        Discounts: {
          value: 1.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-02",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-12-02",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 205.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 4.07,
          format: "Currency",
        },
        Discounts: {
          value: 4.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-12-01",
    value: 104,
    reportType: "Calendar",
    previousDate: "2022-12-01",
    tooltipData: {
      current: {
        value: {
          value: 104,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 5.66,
          format: "Currency",
        },
        Discounts: {
          value: 268.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 9.36,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-30",
    value: 75,
    reportType: "Calendar",
    previousDate: "2022-11-30",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 52.07000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 294.01,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-29",
    value: 91,
    reportType: "Calendar",
    previousDate: "2022-11-29",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 265.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 9.3,
          format: "Currency",
        },
        Discounts: {
          value: 4.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-28",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-11-28",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 280.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-27",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-11-27",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 2.42,
          format: "Currency",
        },
        Discounts: {
          value: 27.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-26",
    value: 107,
    reportType: "Calendar",
    previousDate: "2022-11-26",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 6.99,
          format: "Currency",
        },
        Discounts: {
          value: 232.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 3.3,
          format: "Currency",
        },
        Discounts: {
          value: 2.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-25",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-11-25",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.59,
          format: "Currency",
        },
        Discounts: {
          value: 267.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-24",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-11-24",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 14.5,
          format: "Currency",
        },
        Discounts: {
          value: 238.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-23",
    value: 110,
    reportType: "Calendar",
    previousDate: "2022-11-23",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 326.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 253,
          format: "Number",
        },
        Returns: {
          value: 2.16,
          format: "Currency",
        },
        Discounts: {
          value: 35.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-22",
    value: 140,
    reportType: "Calendar",
    previousDate: "2022-11-22",
    tooltipData: {
      current: {
        value: {
          value: 140,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 361.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-21",
    value: 77,
    reportType: "Calendar",
    previousDate: "2022-11-21",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 270,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-20",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-11-20",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 148.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 32.05,
          format: "Currency",
        },
        Discounts: {
          value: 12.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-19",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-11-19",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 23.4,
          format: "Currency",
        },
        Discounts: {
          value: 190.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.47,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-18",
    value: 114,
    reportType: "Calendar",
    previousDate: "2022-11-18",
    tooltipData: {
      current: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 7.15,
          format: "Currency",
        },
        Discounts: {
          value: 211.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 22.23,
          format: "Currency",
        },
        Discounts: {
          value: 2.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-17",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-11-17",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 41.92,
          format: "Currency",
        },
        Discounts: {
          value: 225.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 47.98,
          format: "Currency",
        },
        Discounts: {
          value: 13.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-16",
    value: 96,
    reportType: "Calendar",
    previousDate: "2022-11-16",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 248.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 2.2,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-15",
    value: 90,
    reportType: "Calendar",
    previousDate: "2022-11-15",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 379.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.16,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-14",
    value: 76,
    reportType: "Calendar",
    previousDate: "2022-11-14",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.55,
          format: "Currency",
        },
        Discounts: {
          value: 215.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-13",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-11-13",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 9.04,
          format: "Currency",
        },
        Discounts: {
          value: 123.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 30.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-12",
    value: 167,
    reportType: "Calendar",
    previousDate: "2022-11-12",
    tooltipData: {
      current: {
        value: {
          value: 167,
          format: "Currency",
        },
        Customers: {
          value: 167,
          format: "Number",
        },
        Transactions: {
          value: 268,
          format: "Number",
        },
        Returns: {
          value: 8.129999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 278.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-11",
    value: 128,
    reportType: "Calendar",
    previousDate: "2022-11-11",
    tooltipData: {
      current: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 53.35,
          format: "Currency",
        },
        Discounts: {
          value: 320.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.61,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-10",
    value: 120,
    reportType: "Calendar",
    previousDate: "2022-11-10",
    tooltipData: {
      current: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 253.37,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-09",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-11-09",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 268.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-08",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-11-08",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 191.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 9.75,
          format: "Currency",
        },
        Discounts: {
          value: 5.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-07",
    value: 62,
    reportType: "Calendar",
    previousDate: "2022-11-07",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 16.2,
          format: "Currency",
        },
        Discounts: {
          value: 397.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 10.81,
          format: "Currency",
        },
        Discounts: {
          value: 0.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-06",
    value: 80,
    reportType: "Calendar",
    previousDate: "2022-11-06",
    tooltipData: {
      current: {
        value: {
          value: 80,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 502.64,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-05",
    value: 130,
    reportType: "Calendar",
    previousDate: "2022-11-05",
    tooltipData: {
      current: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 1.29,
          format: "Currency",
        },
        Discounts: {
          value: 222.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-04",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-11-04",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 31.3,
          format: "Currency",
        },
        Discounts: {
          value: 205.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 6.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-03",
    value: 113,
    reportType: "Calendar",
    previousDate: "2022-11-03",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 227.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-02",
    value: 91,
    reportType: "Calendar",
    previousDate: "2022-11-02",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 223.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 55.11,
          format: "Currency",
        },
        Discounts: {
          value: 15.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-11-01",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-11-01",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 518.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-31",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-10-31",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 246.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-30",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-10-30",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.47,
          format: "Currency",
        },
        Discounts: {
          value: 147,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 5.96,
          format: "Currency",
        },
        Discounts: {
          value: 18.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-29",
    value: 136,
    reportType: "Calendar",
    previousDate: "2022-10-29",
    tooltipData: {
      current: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 229.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 33.09,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-28",
    value: 109,
    reportType: "Calendar",
    previousDate: "2022-10-28",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 7.48,
          format: "Currency",
        },
        Discounts: {
          value: 228.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2.95,
          format: "Currency",
        },
        Discounts: {
          value: 7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-27",
    value: 111,
    reportType: "Calendar",
    previousDate: "2022-10-27",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 7.97,
          format: "Currency",
        },
        Discounts: {
          value: 665.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 14.7,
          format: "Currency",
        },
        Discounts: {
          value: 12.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-26",
    value: 107,
    reportType: "Calendar",
    previousDate: "2022-10-26",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 465.97,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 4.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-25",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-10-25",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.42,
          format: "Currency",
        },
        Discounts: {
          value: 309.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 8.06,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-24",
    value: 110,
    reportType: "Calendar",
    previousDate: "2022-10-24",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 1.7,
          format: "Currency",
        },
        Discounts: {
          value: 267.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 246,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.28,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-23",
    value: 99,
    reportType: "Calendar",
    previousDate: "2022-10-23",
    tooltipData: {
      current: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 9.02,
          format: "Currency",
        },
        Discounts: {
          value: 152.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 17.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-22",
    value: 139,
    reportType: "Calendar",
    previousDate: "2022-10-22",
    tooltipData: {
      current: {
        value: {
          value: 139,
          format: "Currency",
        },
        Customers: {
          value: 139,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 4.4,
          format: "Currency",
        },
        Discounts: {
          value: 355.93,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 37.26000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 18.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-21",
    value: 121,
    reportType: "Calendar",
    previousDate: "2022-10-21",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 35.8,
          format: "Currency",
        },
        Discounts: {
          value: 260.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 15.91,
          format: "Currency",
        },
        Discounts: {
          value: 8.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-20",
    value: 113,
    reportType: "Calendar",
    previousDate: "2022-10-20",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 9.6,
          format: "Currency",
        },
        Discounts: {
          value: 388.68,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 16.9,
          format: "Currency",
        },
        Discounts: {
          value: 0.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-19",
    value: 98,
    reportType: "Calendar",
    previousDate: "2022-10-19",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 22.46,
          format: "Currency",
        },
        Discounts: {
          value: 294.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 7.75,
          format: "Currency",
        },
        Discounts: {
          value: 3.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-18",
    value: 109,
    reportType: "Calendar",
    previousDate: "2022-10-18",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 262.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.69,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-17",
    value: 85,
    reportType: "Calendar",
    previousDate: "2022-10-17",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 2.33,
          format: "Currency",
        },
        Discounts: {
          value: 216.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-16",
    value: 70,
    reportType: "Calendar",
    previousDate: "2022-10-16",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 11.98,
          format: "Currency",
        },
        Discounts: {
          value: 138.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.88,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-15",
    value: 130,
    reportType: "Calendar",
    previousDate: "2022-10-15",
    tooltipData: {
      current: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 24.87,
          format: "Currency",
        },
        Discounts: {
          value: 270.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 6.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-14",
    value: 123,
    reportType: "Calendar",
    previousDate: "2022-10-14",
    tooltipData: {
      current: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 214,
          format: "Number",
        },
        Returns: {
          value: 13.27,
          format: "Currency",
        },
        Discounts: {
          value: 272.84,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 22.5,
          format: "Currency",
        },
        Discounts: {
          value: 6.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-13",
    value: 109,
    reportType: "Calendar",
    previousDate: "2022-10-13",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 308.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.63,
          format: "Currency",
        },
        Discounts: {
          value: 3.89,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-12",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-10-12",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 4.1,
          format: "Currency",
        },
        Discounts: {
          value: 501.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 7.380000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 3.56,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-11",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-10-11",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 257.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-10",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-10-10",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 1.87,
          format: "Currency",
        },
        Discounts: {
          value: 205.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-09",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-10-09",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 14.76,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-08",
    value: 136,
    reportType: "Calendar",
    previousDate: "2022-10-08",
    tooltipData: {
      current: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 199,
          format: "Number",
        },
        Returns: {
          value: 7.02,
          format: "Currency",
        },
        Discounts: {
          value: 287.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 20.08,
          format: "Currency",
        },
        Discounts: {
          value: 0.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-07",
    value: 137,
    reportType: "Calendar",
    previousDate: "2022-10-07",
    tooltipData: {
      current: {
        value: {
          value: 137,
          format: "Currency",
        },
        Customers: {
          value: 137,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 349.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 8.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-06",
    value: 102,
    reportType: "Calendar",
    previousDate: "2022-10-06",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 284.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.2,
          format: "Currency",
        },
        Discounts: {
          value: 19.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-05",
    value: 87,
    reportType: "Calendar",
    previousDate: "2022-10-05",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 1.15,
          format: "Currency",
        },
        Discounts: {
          value: 385.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 1.77,
          format: "Currency",
        },
        Discounts: {
          value: 0.58,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-04",
    value: 90,
    reportType: "Calendar",
    previousDate: "2022-10-04",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 222.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 19.14,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-03",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-10-03",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 43.5,
          format: "Currency",
        },
        Discounts: {
          value: 198.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 3.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-02",
    value: 87,
    reportType: "Calendar",
    previousDate: "2022-10-02",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 163.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-10-01",
    value: 136,
    reportType: "Calendar",
    previousDate: "2022-10-01",
    tooltipData: {
      current: {
        value: {
          value: 136,
          format: "Currency",
        },
        Customers: {
          value: 136,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 25.99,
          format: "Currency",
        },
        Discounts: {
          value: 273.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-30",
    value: 111,
    reportType: "Calendar",
    previousDate: "2022-09-30",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 17.9,
          format: "Currency",
        },
        Discounts: {
          value: 237.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 14.33,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-29",
    value: 97,
    reportType: "Calendar",
    previousDate: "2022-09-29",
    tooltipData: {
      current: {
        value: {
          value: 97,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 359.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 6.560000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 23.05,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-28",
    value: 99,
    reportType: "Calendar",
    previousDate: "2022-09-28",
    tooltipData: {
      current: {
        value: {
          value: 99,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 308.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 5.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-27",
    value: 61,
    reportType: "Calendar",
    previousDate: "2022-09-27",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 227.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-26",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-09-26",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.59,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 3.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-25",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-09-25",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 5.25,
          format: "Currency",
        },
        Discounts: {
          value: 143.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-24",
    value: 133,
    reportType: "Calendar",
    previousDate: "2022-09-24",
    tooltipData: {
      current: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 215,
          format: "Number",
        },
        Returns: {
          value: 34.17,
          format: "Currency",
        },
        Discounts: {
          value: 321.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 15.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-23",
    value: 119,
    reportType: "Calendar",
    previousDate: "2022-09-23",
    tooltipData: {
      current: {
        value: {
          value: 119,
          format: "Currency",
        },
        Customers: {
          value: 119,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 20.09,
          format: "Currency",
        },
        Discounts: {
          value: 232.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 66.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.97,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-22",
    value: 92,
    reportType: "Calendar",
    previousDate: "2022-09-22",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 83.19999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 295.56,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-21",
    value: 65,
    reportType: "Calendar",
    previousDate: "2022-09-21",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 410.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-20",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-09-20",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 209.75,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 11.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-19",
    value: 68,
    reportType: "Calendar",
    previousDate: "2022-09-19",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 187.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-18",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-09-18",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 20.48,
          format: "Currency",
        },
        Discounts: {
          value: 118.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 5.09,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-17",
    value: 142,
    reportType: "Calendar",
    previousDate: "2022-09-17",
    tooltipData: {
      current: {
        value: {
          value: 142,
          format: "Currency",
        },
        Customers: {
          value: 142,
          format: "Number",
        },
        Transactions: {
          value: 237,
          format: "Number",
        },
        Returns: {
          value: 32.59,
          format: "Currency",
        },
        Discounts: {
          value: 415.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-16",
    value: 138,
    reportType: "Calendar",
    previousDate: "2022-09-16",
    tooltipData: {
      current: {
        value: {
          value: 138,
          format: "Currency",
        },
        Customers: {
          value: 138,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 30.27,
          format: "Currency",
        },
        Discounts: {
          value: 399.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 21.59,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-15",
    value: 110,
    reportType: "Calendar",
    previousDate: "2022-09-15",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 71.5,
          format: "Currency",
        },
        Discounts: {
          value: 343.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 22.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-14",
    value: 96,
    reportType: "Calendar",
    previousDate: "2022-09-14",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 255.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-13",
    value: 95,
    reportType: "Calendar",
    previousDate: "2022-09-13",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 309.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-12",
    value: 70,
    reportType: "Calendar",
    previousDate: "2022-09-12",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.3,
          format: "Currency",
        },
        Discounts: {
          value: 299.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-11",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-09-11",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 131.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 3.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-10",
    value: 130,
    reportType: "Calendar",
    previousDate: "2022-09-10",
    tooltipData: {
      current: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 8.4,
          format: "Currency",
        },
        Discounts: {
          value: 267.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 0.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-09",
    value: 130,
    reportType: "Calendar",
    previousDate: "2022-09-09",
    tooltipData: {
      current: {
        value: {
          value: 130,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 6.84,
          format: "Currency",
        },
        Discounts: {
          value: 393.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-08",
    value: 108,
    reportType: "Calendar",
    previousDate: "2022-09-08",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 10.89,
          format: "Currency",
        },
        Discounts: {
          value: 207.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-07",
    value: 76,
    reportType: "Calendar",
    previousDate: "2022-09-07",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 55.2,
          format: "Currency",
        },
        Discounts: {
          value: 428.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-06",
    value: 109,
    reportType: "Calendar",
    previousDate: "2022-09-06",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 8.99,
          format: "Currency",
        },
        Discounts: {
          value: 262.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-05",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-09-05",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 34.18,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-04",
    value: 87,
    reportType: "Calendar",
    previousDate: "2022-09-04",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 300.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 21.81,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-03",
    value: 117,
    reportType: "Calendar",
    previousDate: "2022-09-03",
    tooltipData: {
      current: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 21.79,
          format: "Currency",
        },
        Discounts: {
          value: 295.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 4.48,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-02",
    value: 128,
    reportType: "Calendar",
    previousDate: "2022-09-02",
    tooltipData: {
      current: {
        value: {
          value: 128,
          format: "Currency",
        },
        Customers: {
          value: 128,
          format: "Number",
        },
        Transactions: {
          value: 228,
          format: "Number",
        },
        Returns: {
          value: 1.83,
          format: "Currency",
        },
        Discounts: {
          value: 314.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 13.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-09-01",
    value: 104,
    reportType: "Calendar",
    previousDate: "2022-09-01",
    tooltipData: {
      current: {
        value: {
          value: 104,
          format: "Currency",
        },
        Customers: {
          value: 104,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 4.2,
          format: "Currency",
        },
        Discounts: {
          value: 482.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-31",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-08-31",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 184,
          format: "Number",
        },
        Returns: {
          value: 32.01000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 252.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-30",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-08-30",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 278.19,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 23.91,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-29",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-08-29",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 193.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-28",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-08-28",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 282.68,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-27",
    value: 118,
    reportType: "Calendar",
    previousDate: "2022-08-27",
    tooltipData: {
      current: {
        value: {
          value: 118,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 16.87,
          format: "Currency",
        },
        Discounts: {
          value: 382.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-26",
    value: 106,
    reportType: "Calendar",
    previousDate: "2022-08-26",
    tooltipData: {
      current: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 15.71,
          format: "Currency",
        },
        Discounts: {
          value: 296.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 10.51,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-25",
    value: 81,
    reportType: "Calendar",
    previousDate: "2022-08-25",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 278.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 12.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-24",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-08-24",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 26.5,
          format: "Currency",
        },
        Discounts: {
          value: 336.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-23",
    value: 69,
    reportType: "Calendar",
    previousDate: "2022-08-23",
    tooltipData: {
      current: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 158.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-22",
    value: 85,
    reportType: "Calendar",
    previousDate: "2022-08-22",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 4.39,
          format: "Currency",
        },
        Discounts: {
          value: 325.93,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 33.15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-21",
    value: 70,
    reportType: "Calendar",
    previousDate: "2022-08-21",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 33.75,
          format: "Currency",
        },
        Discounts: {
          value: 114.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 21.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-20",
    value: 108,
    reportType: "Calendar",
    previousDate: "2022-08-20",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 21.75,
          format: "Currency",
        },
        Discounts: {
          value: 177.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 20.58,
          format: "Currency",
        },
        Discounts: {
          value: 4.51,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-19",
    value: 140,
    reportType: "Calendar",
    previousDate: "2022-08-19",
    tooltipData: {
      current: {
        value: {
          value: 140,
          format: "Currency",
        },
        Customers: {
          value: 140,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 9.54,
          format: "Currency",
        },
        Discounts: {
          value: 270.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 6.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-18",
    value: 111,
    reportType: "Calendar",
    previousDate: "2022-08-18",
    tooltipData: {
      current: {
        value: {
          value: 111,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 193,
          format: "Number",
        },
        Returns: {
          value: 53.64999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 192.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-17",
    value: 109,
    reportType: "Calendar",
    previousDate: "2022-08-17",
    tooltipData: {
      current: {
        value: {
          value: 109,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 31.97,
          format: "Currency",
        },
        Discounts: {
          value: 151.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-16",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-08-16",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 175.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-15",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-08-15",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 20,
          format: "Currency",
        },
        Discounts: {
          value: 187.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-14",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-08-14",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 15.15,
          format: "Currency",
        },
        Discounts: {
          value: 102.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 53.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-13",
    value: 149,
    reportType: "Calendar",
    previousDate: "2022-08-13",
    tooltipData: {
      current: {
        value: {
          value: 149,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 18.22,
          format: "Currency",
        },
        Discounts: {
          value: 265.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 22.42,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-12",
    value: 129,
    reportType: "Calendar",
    previousDate: "2022-08-12",
    tooltipData: {
      current: {
        value: {
          value: 129,
          format: "Currency",
        },
        Customers: {
          value: 129,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 1.26,
          format: "Currency",
        },
        Discounts: {
          value: 246.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 27.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-11",
    value: 102,
    reportType: "Calendar",
    previousDate: "2022-08-11",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 184.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-10",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-08-10",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 7.25,
          format: "Currency",
        },
        Discounts: {
          value: 121.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 18.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-09",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-08-09",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 15.69,
          format: "Currency",
        },
        Discounts: {
          value: 113.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-08",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-08-08",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 128.76,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-07",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-08-07",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2,
          format: "Currency",
        },
        Discounts: {
          value: 174.75,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.61,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-06",
    value: 113,
    reportType: "Calendar",
    previousDate: "2022-08-06",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 27.86,
          format: "Currency",
        },
        Discounts: {
          value: 203.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-05",
    value: 121,
    reportType: "Calendar",
    previousDate: "2022-08-05",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 3.09,
          format: "Currency",
        },
        Discounts: {
          value: 253.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 32.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-04",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-08-04",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 18.26,
          format: "Currency",
        },
        Discounts: {
          value: 182.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 10.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-03",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-08-03",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 5.5,
          format: "Currency",
        },
        Discounts: {
          value: 124.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 20.44,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-02",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-08-02",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 40,
          format: "Currency",
        },
        Discounts: {
          value: 172.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 26.53,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-08-01",
    value: 65,
    reportType: "Calendar",
    previousDate: "2022-08-01",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 2.13,
          format: "Currency",
        },
        Discounts: {
          value: 125.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 14.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-31",
    value: 91,
    reportType: "Calendar",
    previousDate: "2022-07-31",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 168.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 26.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-30",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-07-30",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 172.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-29",
    value: 95,
    reportType: "Calendar",
    previousDate: "2022-07-29",
    tooltipData: {
      current: {
        value: {
          value: 95,
          format: "Currency",
        },
        Customers: {
          value: 95,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 210.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-28",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-07-28",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 4.6,
          format: "Currency",
        },
        Discounts: {
          value: 134.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 4.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-27",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-07-27",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-26",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-07-26",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 8.899999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 120.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 31.79,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-25",
    value: 75,
    reportType: "Calendar",
    previousDate: "2022-07-25",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 157.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 12.62,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-24",
    value: 56,
    reportType: "Calendar",
    previousDate: "2022-07-24",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 2.8,
          format: "Currency",
        },
        Discounts: {
          value: 114.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-23",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-07-23",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 162.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 28.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-22",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-07-22",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 127.3,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-21",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-07-21",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 299,
          format: "Number",
        },
        Returns: {
          value: 75.47999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 230.75,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 17.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-20",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-07-20",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 129.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-19",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-07-19",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 19.43,
          format: "Currency",
        },
        Discounts: {
          value: 118.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-18",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-07-18",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.84,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        Returns: {
          value: 12.34,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-17",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-07-17",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 14.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-16",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-07-16",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 195.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 16.07,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-15",
    value: 112,
    reportType: "Calendar",
    previousDate: "2022-07-15",
    tooltipData: {
      current: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 42.85,
          format: "Currency",
        },
        Discounts: {
          value: 180.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-14",
    value: 92,
    reportType: "Calendar",
    previousDate: "2022-07-14",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 144.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-13",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-07-13",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 11.57,
          format: "Currency",
        },
        Discounts: {
          value: 127.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-12",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-07-12",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 8.6,
          format: "Currency",
        },
        Discounts: {
          value: 122.22,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-11",
    value: 49,
    reportType: "Calendar",
    previousDate: "2022-07-11",
    tooltipData: {
      current: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 82.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-10",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-07-10",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 4.17,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 4.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-09",
    value: 120,
    reportType: "Calendar",
    previousDate: "2022-07-09",
    tooltipData: {
      current: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 190,
          format: "Number",
        },
        Returns: {
          value: 34.61,
          format: "Currency",
        },
        Discounts: {
          value: 180.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-08",
    value: 90,
    reportType: "Calendar",
    previousDate: "2022-07-08",
    tooltipData: {
      current: {
        value: {
          value: 90,
          format: "Currency",
        },
        Customers: {
          value: 90,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 2.32,
          format: "Currency",
        },
        Discounts: {
          value: 161.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-07",
    value: 101,
    reportType: "Calendar",
    previousDate: "2022-07-07",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 6.34,
          format: "Currency",
        },
        Discounts: {
          value: 189.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-06",
    value: 58,
    reportType: "Calendar",
    previousDate: "2022-07-06",
    tooltipData: {
      current: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 6.01,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-05",
    value: 60,
    reportType: "Calendar",
    previousDate: "2022-07-05",
    tooltipData: {
      current: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.199999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 89.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 16.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-04",
    value: 42,
    reportType: "Calendar",
    previousDate: "2022-07-04",
    tooltipData: {
      current: {
        value: {
          value: 42,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 68.49,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 45.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-03",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-07-03",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 1.78,
          format: "Currency",
        },
        Discounts: {
          value: 132.68,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-02",
    value: 92,
    reportType: "Calendar",
    previousDate: "2022-07-02",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.71,
          format: "Currency",
        },
        Discounts: {
          value: 157.84,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-07-01",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-07-01",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 9.77,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-30",
    value: 68,
    reportType: "Calendar",
    previousDate: "2022-06-30",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 12.28,
          format: "Currency",
        },
        Discounts: {
          value: 96.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.43,
          format: "Currency",
        },
        Discounts: {
          value: 0.4,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-29",
    value: 81,
    reportType: "Calendar",
    previousDate: "2022-06-29",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 12.7,
          format: "Currency",
        },
        Discounts: {
          value: 127.56,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 10.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-28",
    value: 60,
    reportType: "Calendar",
    previousDate: "2022-06-28",
    tooltipData: {
      current: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 101.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-27",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-06-27",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 227.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 90,
          format: "Number",
        },
        Returns: {
          value: 22.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-26",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-06-26",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 207.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-25",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-06-25",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 28,
          format: "Currency",
        },
        Discounts: {
          value: 191.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 8.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-24",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-06-24",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 63.83,
          format: "Currency",
        },
        Discounts: {
          value: 201.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-23",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-06-23",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 116.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-22",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-06-22",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0.5,
          format: "Currency",
        },
        Discounts: {
          value: 163.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-21",
    value: 73,
    reportType: "Calendar",
    previousDate: "2022-06-21",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 2.31,
          format: "Currency",
        },
        Discounts: {
          value: 115.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.89,
          format: "Currency",
        },
        Discounts: {
          value: 2.77,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-20",
    value: 70,
    reportType: "Calendar",
    previousDate: "2022-06-20",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 23.66,
          format: "Currency",
        },
        Discounts: {
          value: 116.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 10.14,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-19",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-06-19",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 99.9,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-18",
    value: 114,
    reportType: "Calendar",
    previousDate: "2022-06-18",
    tooltipData: {
      current: {
        value: {
          value: 114,
          format: "Currency",
        },
        Customers: {
          value: 114,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 180.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 19.5,
          format: "Currency",
        },
        Discounts: {
          value: 2.92,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-17",
    value: 91,
    reportType: "Calendar",
    previousDate: "2022-06-17",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 42.01,
          format: "Currency",
        },
        Discounts: {
          value: 177.76,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 14.61,
          format: "Currency",
        },
        Discounts: {
          value: 5.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-16",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-06-16",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 20.95,
          format: "Currency",
        },
        Discounts: {
          value: 169.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.54,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-15",
    value: 76,
    reportType: "Calendar",
    previousDate: "2022-06-15",
    tooltipData: {
      current: {
        value: {
          value: 76,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 136.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.35,
          format: "Currency",
        },
        Discounts: {
          value: 0.75,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-14",
    value: 51,
    reportType: "Calendar",
    previousDate: "2022-06-14",
    tooltipData: {
      current: {
        value: {
          value: 51,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 109.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-13",
    value: 65,
    reportType: "Calendar",
    previousDate: "2022-06-13",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 11.15,
          format: "Currency",
        },
        Discounts: {
          value: 114.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 14.15,
          format: "Currency",
        },
        Discounts: {
          value: 4.48,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-12",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-06-12",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 0.55,
          format: "Currency",
        },
        Discounts: {
          value: 133.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-11",
    value: 120,
    reportType: "Calendar",
    previousDate: "2022-06-11",
    tooltipData: {
      current: {
        value: {
          value: 120,
          format: "Currency",
        },
        Customers: {
          value: 120,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 9.39,
          format: "Currency",
        },
        Discounts: {
          value: 176.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 40.24,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-10",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-06-10",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 141.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-09",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-06-09",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 4.97,
          format: "Currency",
        },
        Discounts: {
          value: 133.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 3.88,
          format: "Currency",
        },
        Discounts: {
          value: 6.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-08",
    value: 61,
    reportType: "Calendar",
    previousDate: "2022-06-08",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 121.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 89,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-07",
    value: 85,
    reportType: "Calendar",
    previousDate: "2022-06-07",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        Returns: {
          value: 27.71,
          format: "Currency",
        },
        Discounts: {
          value: 112.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-06",
    value: 63,
    reportType: "Calendar",
    previousDate: "2022-06-06",
    tooltipData: {
      current: {
        value: {
          value: 63,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.75,
          format: "Currency",
        },
        Discounts: {
          value: 154.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 8.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-05",
    value: 59,
    reportType: "Calendar",
    previousDate: "2022-06-05",
    tooltipData: {
      current: {
        value: {
          value: 59,
          format: "Currency",
        },
        Customers: {
          value: 59,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 8.1,
          format: "Currency",
        },
        Discounts: {
          value: 107.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 3.83,
          format: "Currency",
        },
        Discounts: {
          value: 11.38,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-04",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-06-04",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 168,
          format: "Number",
        },
        Returns: {
          value: 35.17,
          format: "Currency",
        },
        Discounts: {
          value: 140.64,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 7.6,
          format: "Currency",
        },
        Discounts: {
          value: 15.74,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-03",
    value: 92,
    reportType: "Calendar",
    previousDate: "2022-06-03",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 156.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-02",
    value: 75,
    reportType: "Calendar",
    previousDate: "2022-06-02",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 130.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-06-01",
    value: 65,
    reportType: "Calendar",
    previousDate: "2022-06-01",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 19.66,
          format: "Currency",
        },
        Discounts: {
          value: 111.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.62,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-31",
    value: 77,
    reportType: "Calendar",
    previousDate: "2022-05-31",
    tooltipData: {
      current: {
        value: {
          value: 77,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 6.109999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 176.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 14.13,
          format: "Currency",
        },
        Discounts: {
          value: 1.1,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-30",
    value: 73,
    reportType: "Calendar",
    previousDate: "2022-05-30",
    tooltipData: {
      current: {
        value: {
          value: 73,
          format: "Currency",
        },
        Customers: {
          value: 73,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 132.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-29",
    value: 69,
    reportType: "Calendar",
    previousDate: "2022-05-29",
    tooltipData: {
      current: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 5.2,
          format: "Currency",
        },
        Discounts: {
          value: 106.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 20.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.44,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-28",
    value: 100,
    reportType: "Calendar",
    previousDate: "2022-05-28",
    tooltipData: {
      current: {
        value: {
          value: 100,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 155.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 18.47,
          format: "Currency",
        },
        Discounts: {
          value: 6.25,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-27",
    value: 92,
    reportType: "Calendar",
    previousDate: "2022-05-27",
    tooltipData: {
      current: {
        value: {
          value: 92,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 191,
          format: "Number",
        },
        Returns: {
          value: 18.6,
          format: "Currency",
        },
        Discounts: {
          value: 194.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 9.85,
          format: "Currency",
        },
        Discounts: {
          value: 22.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-26",
    value: 102,
    reportType: "Calendar",
    previousDate: "2022-05-26",
    tooltipData: {
      current: {
        value: {
          value: 102,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 20.79,
          format: "Currency",
        },
        Discounts: {
          value: 176.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 2.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-25",
    value: 110,
    reportType: "Calendar",
    previousDate: "2022-05-25",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 16.85,
          format: "Currency",
        },
        Discounts: {
          value: 188.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.99,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-24",
    value: 65,
    reportType: "Calendar",
    previousDate: "2022-05-24",
    tooltipData: {
      current: {
        value: {
          value: 65,
          format: "Currency",
        },
        Customers: {
          value: 65,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.08,
          format: "Currency",
        },
        Discounts: {
          value: 115.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 23,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.78,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-23",
    value: 70,
    reportType: "Calendar",
    previousDate: "2022-05-23",
    tooltipData: {
      current: {
        value: {
          value: 70,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 1.73,
          format: "Currency",
        },
        Discounts: {
          value: 129.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 21.99,
          format: "Currency",
        },
        Discounts: {
          value: 5.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-22",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-05-22",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 113.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 6.770000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 8.42,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-21",
    value: 124,
    reportType: "Calendar",
    previousDate: "2022-05-21",
    tooltipData: {
      current: {
        value: {
          value: 124,
          format: "Currency",
        },
        Customers: {
          value: 124,
          format: "Number",
        },
        Transactions: {
          value: 244,
          format: "Number",
        },
        Returns: {
          value: 4.45,
          format: "Currency",
        },
        Discounts: {
          value: 262.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 10.4,
          format: "Currency",
        },
        Discounts: {
          value: 10.86,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-20",
    value: 88,
    reportType: "Calendar",
    previousDate: "2022-05-20",
    tooltipData: {
      current: {
        value: {
          value: 88,
          format: "Currency",
        },
        Customers: {
          value: 88,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 180.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.66,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-19",
    value: 85,
    reportType: "Calendar",
    previousDate: "2022-05-19",
    tooltipData: {
      current: {
        value: {
          value: 85,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 21.17,
          format: "Currency",
        },
        Discounts: {
          value: 145.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        Returns: {
          value: 11.72,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-18",
    value: 55,
    reportType: "Calendar",
    previousDate: "2022-05-18",
    tooltipData: {
      current: {
        value: {
          value: 55,
          format: "Currency",
        },
        Customers: {
          value: 55,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 113.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 1.2,
          format: "Currency",
        },
        Discounts: {
          value: 2.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-17",
    value: 75,
    reportType: "Calendar",
    previousDate: "2022-05-17",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 21.08,
          format: "Currency",
        },
        Discounts: {
          value: 129.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.03,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-16",
    value: 68,
    reportType: "Calendar",
    previousDate: "2022-05-16",
    tooltipData: {
      current: {
        value: {
          value: 68,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 26.65,
          format: "Currency",
        },
        Discounts: {
          value: 129.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 0.46,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-15",
    value: 44,
    reportType: "Calendar",
    previousDate: "2022-05-15",
    tooltipData: {
      current: {
        value: {
          value: 44,
          format: "Currency",
        },
        Customers: {
          value: 44,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.38,
          format: "Currency",
        },
        Discounts: {
          value: 85.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 276,
          format: "Number",
        },
        Returns: {
          value: 16.44,
          format: "Currency",
        },
        Discounts: {
          value: 78.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-14",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-05-14",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 213,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 205.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 4.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-13",
    value: 91,
    reportType: "Calendar",
    previousDate: "2022-05-13",
    tooltipData: {
      current: {
        value: {
          value: 91,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 65.33,
          format: "Currency",
        },
        Discounts: {
          value: 184.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 1.8,
          format: "Currency",
        },
        Discounts: {
          value: 17.83,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-12",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-05-12",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 141.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-11",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-05-11",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 1.74,
          format: "Currency",
        },
        Discounts: {
          value: 156.59,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 21.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-10",
    value: 81,
    reportType: "Calendar",
    previousDate: "2022-05-10",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 121.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.7,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-09",
    value: 83,
    reportType: "Calendar",
    previousDate: "2022-05-09",
    tooltipData: {
      current: {
        value: {
          value: 83,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 148.07,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 71,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-08",
    value: 94,
    reportType: "Calendar",
    previousDate: "2022-05-08",
    tooltipData: {
      current: {
        value: {
          value: 94,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 166,
          format: "Number",
        },
        Returns: {
          value: 7.45,
          format: "Currency",
        },
        Discounts: {
          value: 146.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.39,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-07",
    value: 107,
    reportType: "Calendar",
    previousDate: "2022-05-07",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 235,
          format: "Number",
        },
        Returns: {
          value: 78.25,
          format: "Currency",
        },
        Discounts: {
          value: 199.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-06",
    value: 113,
    reportType: "Calendar",
    previousDate: "2022-05-06",
    tooltipData: {
      current: {
        value: {
          value: 113,
          format: "Currency",
        },
        Customers: {
          value: 113,
          format: "Number",
        },
        Transactions: {
          value: 243,
          format: "Number",
        },
        Returns: {
          value: 37.57,
          format: "Currency",
        },
        Discounts: {
          value: 265.82,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 1.24,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-05",
    value: 108,
    reportType: "Calendar",
    previousDate: "2022-05-05",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 219,
          format: "Number",
        },
        Returns: {
          value: 44.14,
          format: "Currency",
        },
        Discounts: {
          value: 210.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.21,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-04",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-05-04",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 180.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        Returns: {
          value: 3.19,
          format: "Currency",
        },
        Discounts: {
          value: 11.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-03",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-05-03",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 153.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-02",
    value: 82,
    reportType: "Calendar",
    previousDate: "2022-05-02",
    tooltipData: {
      current: {
        value: {
          value: 82,
          format: "Currency",
        },
        Customers: {
          value: 82,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 187.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-05-01",
    value: 58,
    reportType: "Calendar",
    previousDate: "2022-05-01",
    tooltipData: {
      current: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 91.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 88.4,
          format: "Currency",
        },
        Discounts: {
          value: 29.63,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-30",
    value: 107,
    reportType: "Calendar",
    previousDate: "2022-04-30",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 222,
          format: "Number",
        },
        Returns: {
          value: 5.46,
          format: "Currency",
        },
        Discounts: {
          value: 207.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 10.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-29",
    value: 106,
    reportType: "Calendar",
    previousDate: "2022-04-29",
    tooltipData: {
      current: {
        value: {
          value: 106,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 234,
          format: "Number",
        },
        Returns: {
          value: 33.05,
          format: "Currency",
        },
        Discounts: {
          value: 181.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.31,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-28",
    value: 101,
    reportType: "Calendar",
    previousDate: "2022-04-28",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        Returns: {
          value: 8.2,
          format: "Currency",
        },
        Discounts: {
          value: 216.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-27",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-04-27",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 97.17,
          format: "Currency",
        },
        Discounts: {
          value: 191.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-26",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-04-26",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 2.45,
          format: "Currency",
        },
        Discounts: {
          value: 136.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 7.49,
          format: "Currency",
        },
        Discounts: {
          value: 2.26,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-25",
    value: 72,
    reportType: "Calendar",
    previousDate: "2022-04-25",
    tooltipData: {
      current: {
        value: {
          value: 72,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.26,
          format: "Currency",
        },
        Discounts: {
          value: 148.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-24",
    value: 81,
    reportType: "Calendar",
    previousDate: "2022-04-24",
    tooltipData: {
      current: {
        value: {
          value: 81,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 39.58000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 143.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 29.97,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-23",
    value: 103,
    reportType: "Calendar",
    previousDate: "2022-04-23",
    tooltipData: {
      current: {
        value: {
          value: 103,
          format: "Currency",
        },
        Customers: {
          value: 103,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 20.9,
          format: "Currency",
        },
        Discounts: {
          value: 211.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.45,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-22",
    value: 101,
    reportType: "Calendar",
    previousDate: "2022-04-22",
    tooltipData: {
      current: {
        value: {
          value: 101,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 245,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 207.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 28.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-21",
    value: 75,
    reportType: "Calendar",
    previousDate: "2022-04-21",
    tooltipData: {
      current: {
        value: {
          value: 75,
          format: "Currency",
        },
        Customers: {
          value: 75,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 173.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-20",
    value: 110,
    reportType: "Calendar",
    previousDate: "2022-04-20",
    tooltipData: {
      current: {
        value: {
          value: 110,
          format: "Currency",
        },
        Customers: {
          value: 110,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 10.8,
          format: "Currency",
        },
        Discounts: {
          value: 283.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 80,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.96,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-19",
    value: 107,
    reportType: "Calendar",
    previousDate: "2022-04-19",
    tooltipData: {
      current: {
        value: {
          value: 107,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 199.59,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 12,
          format: "Currency",
        },
        Discounts: {
          value: 16.85,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-18",
    value: 87,
    reportType: "Calendar",
    previousDate: "2022-04-18",
    tooltipData: {
      current: {
        value: {
          value: 87,
          format: "Currency",
        },
        Customers: {
          value: 87,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 6.17,
          format: "Currency",
        },
        Discounts: {
          value: 142.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 2.07,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-17",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-04-17",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 164.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-16",
    value: 121,
    reportType: "Calendar",
    previousDate: "2022-04-16",
    tooltipData: {
      current: {
        value: {
          value: 121,
          format: "Currency",
        },
        Customers: {
          value: 121,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 12.69,
          format: "Currency",
        },
        Discounts: {
          value: 222.09,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-15",
    value: 105,
    reportType: "Calendar",
    previousDate: "2022-04-15",
    tooltipData: {
      current: {
        value: {
          value: 105,
          format: "Currency",
        },
        Customers: {
          value: 105,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 42.93,
          format: "Currency",
        },
        Discounts: {
          value: 282.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 17.06,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-14",
    value: 108,
    reportType: "Calendar",
    previousDate: "2022-04-14",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 21.91,
          format: "Currency",
        },
        Discounts: {
          value: 214.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.73,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-13",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-04-13",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 1.55,
          format: "Currency",
        },
        Discounts: {
          value: 139.15,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 65.81,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-12",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-04-12",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 14.63,
          format: "Currency",
        },
        Discounts: {
          value: 124.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-11",
    value: 89,
    reportType: "Calendar",
    previousDate: "2022-04-11",
    tooltipData: {
      current: {
        value: {
          value: 89,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 172.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-10",
    value: 93,
    reportType: "Calendar",
    previousDate: "2022-04-10",
    tooltipData: {
      current: {
        value: {
          value: 93,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 9.1,
          format: "Currency",
        },
        Discounts: {
          value: 140.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 13.35,
          format: "Currency",
        },
        Discounts: {
          value: 10.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-09",
    value: 133,
    reportType: "Calendar",
    previousDate: "2022-04-09",
    tooltipData: {
      current: {
        value: {
          value: 133,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 266,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 228.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.23,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-08",
    value: 151,
    reportType: "Calendar",
    previousDate: "2022-04-08",
    tooltipData: {
      current: {
        value: {
          value: 151,
          format: "Currency",
        },
        Customers: {
          value: 151,
          format: "Number",
        },
        Transactions: {
          value: 260,
          format: "Number",
        },
        Returns: {
          value: 18.95,
          format: "Currency",
        },
        Discounts: {
          value: 339.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.35,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-07",
    value: 112,
    reportType: "Calendar",
    previousDate: "2022-04-07",
    tooltipData: {
      current: {
        value: {
          value: 112,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 14.45,
          format: "Currency",
        },
        Discounts: {
          value: 229.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 6.13,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-06",
    value: 96,
    reportType: "Calendar",
    previousDate: "2022-04-06",
    tooltipData: {
      current: {
        value: {
          value: 96,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 20.15,
          format: "Currency",
        },
        Discounts: {
          value: 203.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-05",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-04-05",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 9.19,
          format: "Currency",
        },
        Discounts: {
          value: 131.23,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 4.05,
          format: "Currency",
        },
        Discounts: {
          value: 7.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-04",
    value: 63,
    reportType: "Calendar",
    previousDate: "2022-04-04",
    tooltipData: {
      current: {
        value: {
          value: 63,
          format: "Currency",
        },
        Customers: {
          value: 63,
          format: "Number",
        },
        Transactions: {
          value: 186,
          format: "Number",
        },
        Returns: {
          value: 2.53,
          format: "Currency",
        },
        Discounts: {
          value: 101.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.71,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-03",
    value: 79,
    reportType: "Calendar",
    previousDate: "2022-04-03",
    tooltipData: {
      current: {
        value: {
          value: 79,
          format: "Currency",
        },
        Customers: {
          value: 79,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 31.64,
          format: "Currency",
        },
        Discounts: {
          value: 126.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 27.49,
          format: "Currency",
        },
        Discounts: {
          value: 474.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-02",
    value: 123,
    reportType: "Calendar",
    previousDate: "2022-04-02",
    tooltipData: {
      current: {
        value: {
          value: 123,
          format: "Currency",
        },
        Customers: {
          value: 123,
          format: "Number",
        },
        Transactions: {
          value: 261,
          format: "Number",
        },
        Returns: {
          value: 31.81,
          format: "Currency",
        },
        Discounts: {
          value: 253.68,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 8.85,
          format: "Currency",
        },
        Discounts: {
          value: 383.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-04-01",
    value: 98,
    reportType: "Calendar",
    previousDate: "2022-04-01",
    tooltipData: {
      current: {
        value: {
          value: 98,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 225,
          format: "Number",
        },
        Returns: {
          value: 17.85,
          format: "Currency",
        },
        Discounts: {
          value: 223.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 381.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-31",
    value: 118,
    reportType: "Calendar",
    previousDate: "2022-03-31",
    tooltipData: {
      current: {
        value: {
          value: 118,
          format: "Currency",
        },
        Customers: {
          value: 118,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 222.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 10.96,
          format: "Currency",
        },
        Discounts: {
          value: 12.8,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-30",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-03-30",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 179.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 64,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-29",
    value: 86,
    reportType: "Calendar",
    previousDate: "2022-03-29",
    tooltipData: {
      current: {
        value: {
          value: 86,
          format: "Currency",
        },
        Customers: {
          value: 86,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 153.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.29,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-28",
    value: 84,
    reportType: "Calendar",
    previousDate: "2022-03-28",
    tooltipData: {
      current: {
        value: {
          value: 84,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 12.33,
          format: "Currency",
        },
        Discounts: {
          value: 132.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 81,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.41,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-27",
    value: 57,
    reportType: "Calendar",
    previousDate: "2022-03-27",
    tooltipData: {
      current: {
        value: {
          value: 57,
          format: "Currency",
        },
        Customers: {
          value: 57,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 119.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 626.52,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-26",
    value: 108,
    reportType: "Calendar",
    previousDate: "2022-03-26",
    tooltipData: {
      current: {
        value: {
          value: 108,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 223,
          format: "Number",
        },
        Returns: {
          value: 29.4,
          format: "Currency",
        },
        Discounts: {
          value: 234.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 801.5,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-25",
    value: 117,
    reportType: "Calendar",
    previousDate: "2022-03-25",
    tooltipData: {
      current: {
        value: {
          value: 117,
          format: "Currency",
        },
        Customers: {
          value: 117,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 18.98,
          format: "Currency",
        },
        Discounts: {
          value: 221.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-24",
    value: 74,
    reportType: "Calendar",
    previousDate: "2022-03-24",
    tooltipData: {
      current: {
        value: {
          value: 74,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 18.71,
          format: "Currency",
        },
        Discounts: {
          value: 169.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 2,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-23",
    value: 56,
    reportType: "Calendar",
    previousDate: "2022-03-23",
    tooltipData: {
      current: {
        value: {
          value: 56,
          format: "Currency",
        },
        Customers: {
          value: 56,
          format: "Number",
        },
        Transactions: {
          value: 206,
          format: "Number",
        },
        Returns: {
          value: 37.4,
          format: "Currency",
        },
        Discounts: {
          value: 130.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-22",
    value: 71,
    reportType: "Calendar",
    previousDate: "2022-03-22",
    tooltipData: {
      current: {
        value: {
          value: 71,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 181.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-21",
    value: 62,
    reportType: "Calendar",
    previousDate: "2022-03-21",
    tooltipData: {
      current: {
        value: {
          value: 62,
          format: "Currency",
        },
        Customers: {
          value: 62,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 4.55,
          format: "Currency",
        },
        Discounts: {
          value: 85.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-20",
    value: 50,
    reportType: "Calendar",
    previousDate: "2022-03-20",
    tooltipData: {
      current: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-19",
    value: 64,
    reportType: "Calendar",
    previousDate: "2022-03-19",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 12.39,
          format: "Currency",
        },
        Discounts: {
          value: 121.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-18",
    value: 69,
    reportType: "Calendar",
    previousDate: "2022-03-18",
    tooltipData: {
      current: {
        value: {
          value: 69,
          format: "Currency",
        },
        Customers: {
          value: 69,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 23.16,
          format: "Currency",
        },
        Discounts: {
          value: 126.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-17",
    value: 54,
    reportType: "Calendar",
    previousDate: "2022-03-17",
    tooltipData: {
      current: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 110.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-16",
    value: 64,
    reportType: "Calendar",
    previousDate: "2022-03-16",
    tooltipData: {
      current: {
        value: {
          value: 64,
          format: "Currency",
        },
        Customers: {
          value: 64,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 149.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-15",
    value: 47,
    reportType: "Calendar",
    previousDate: "2022-03-15",
    tooltipData: {
      current: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 109.32,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-14",
    value: 47,
    reportType: "Calendar",
    previousDate: "2022-03-14",
    tooltipData: {
      current: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 78.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-13",
    value: 5,
    reportType: "Calendar",
    previousDate: "2022-03-13",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 10,
          format: "Number",
        },
        Returns: {
          value: 1.13,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-12",
    value: 47,
    reportType: "Calendar",
    previousDate: "2022-03-12",
    tooltipData: {
      current: {
        value: {
          value: 47,
          format: "Currency",
        },
        Customers: {
          value: 47,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 4.21,
          format: "Currency",
        },
        Discounts: {
          value: 104.59,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-11",
    value: 54,
    reportType: "Calendar",
    previousDate: "2022-03-11",
    tooltipData: {
      current: {
        value: {
          value: 54,
          format: "Currency",
        },
        Customers: {
          value: 54,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 4.85,
          format: "Currency",
        },
        Discounts: {
          value: 101.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-10",
    value: 50,
    reportType: "Calendar",
    previousDate: "2022-03-10",
    tooltipData: {
      current: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.3,
          format: "Currency",
        },
        Discounts: {
          value: 110.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-09",
    value: 42,
    reportType: "Calendar",
    previousDate: "2022-03-09",
    tooltipData: {
      current: {
        value: {
          value: 42,
          format: "Currency",
        },
        Customers: {
          value: 42,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 103.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-08",
    value: 38,
    reportType: "Calendar",
    previousDate: "2022-03-08",
    tooltipData: {
      current: {
        value: {
          value: 38,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 4.09,
          format: "Currency",
        },
        Discounts: {
          value: 74.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-07",
    value: 40,
    reportType: "Calendar",
    previousDate: "2022-03-07",
    tooltipData: {
      current: {
        value: {
          value: 40,
          format: "Currency",
        },
        Customers: {
          value: 40,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 3.7,
          format: "Currency",
        },
        Discounts: {
          value: 72.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-06",
    value: 41,
    reportType: "Calendar",
    previousDate: "2022-03-06",
    tooltipData: {
      current: {
        value: {
          value: 41,
          format: "Currency",
        },
        Customers: {
          value: 41,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 94.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-05",
    value: 52,
    reportType: "Calendar",
    previousDate: "2022-03-05",
    tooltipData: {
      current: {
        value: {
          value: 52,
          format: "Currency",
        },
        Customers: {
          value: 52,
          format: "Number",
        },
        Transactions: {
          value: 232,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 137.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-04",
    value: 50,
    reportType: "Calendar",
    previousDate: "2022-03-04",
    tooltipData: {
      current: {
        value: {
          value: 50,
          format: "Currency",
        },
        Customers: {
          value: 50,
          format: "Number",
        },
        Transactions: {
          value: 220,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 101.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-03",
    value: 61,
    reportType: "Calendar",
    previousDate: "2022-03-03",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 209,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 141.34,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-02",
    value: 60,
    reportType: "Calendar",
    previousDate: "2022-03-02",
    tooltipData: {
      current: {
        value: {
          value: 60,
          format: "Currency",
        },
        Customers: {
          value: 60,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 13.3,
          format: "Currency",
        },
        Discounts: {
          value: 120.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-03-01",
    value: 49,
    reportType: "Calendar",
    previousDate: "2022-03-01",
    tooltipData: {
      current: {
        value: {
          value: 49,
          format: "Currency",
        },
        Customers: {
          value: 49,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 16.49,
          format: "Currency",
        },
        Discounts: {
          value: 127.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-28",
    value: 35,
    reportType: "Calendar",
    previousDate: "2022-02-28",
    tooltipData: {
      current: {
        value: {
          value: 35,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 15.49,
          format: "Currency",
        },
        Discounts: {
          value: 70.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-27",
    value: 35,
    reportType: "Calendar",
    previousDate: "2022-02-27",
    tooltipData: {
      current: {
        value: {
          value: 35,
          format: "Currency",
        },
        Customers: {
          value: 35,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 16.58,
          format: "Currency",
        },
        Discounts: {
          value: 53.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-26",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-02-26",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 230,
          format: "Number",
        },
        Returns: {
          value: 17.95,
          format: "Currency",
        },
        Discounts: {
          value: 139.02,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-25",
    value: 78,
    reportType: "Calendar",
    previousDate: "2022-02-25",
    tooltipData: {
      current: {
        value: {
          value: 78,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 249,
          format: "Number",
        },
        Returns: {
          value: 33.2,
          format: "Currency",
        },
        Discounts: {
          value: 162.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-24",
    value: 53,
    reportType: "Calendar",
    previousDate: "2022-02-24",
    tooltipData: {
      current: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 189,
          format: "Number",
        },
        Returns: {
          value: 4.99,
          format: "Currency",
        },
        Discounts: {
          value: 138.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-23",
    value: 58,
    reportType: "Calendar",
    previousDate: "2022-02-23",
    tooltipData: {
      current: {
        value: {
          value: 58,
          format: "Currency",
        },
        Customers: {
          value: 58,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 34.19,
          format: "Currency",
        },
        Discounts: {
          value: 150.37,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-22",
    value: 51,
    reportType: "Calendar",
    previousDate: "2022-02-22",
    tooltipData: {
      current: {
        value: {
          value: 51,
          format: "Currency",
        },
        Customers: {
          value: 51,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 11.3,
          format: "Currency",
        },
        Discounts: {
          value: 80.27,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-21",
    value: 61,
    reportType: "Calendar",
    previousDate: "2022-02-21",
    tooltipData: {
      current: {
        value: {
          value: 61,
          format: "Currency",
        },
        Customers: {
          value: 61,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 21.35,
          format: "Currency",
        },
        Discounts: {
          value: 110.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-20",
    value: 53,
    reportType: "Calendar",
    previousDate: "2022-02-20",
    tooltipData: {
      current: {
        value: {
          value: 53,
          format: "Currency",
        },
        Customers: {
          value: 53,
          format: "Number",
        },
        Transactions: {
          value: 179,
          format: "Number",
        },
        Returns: {
          value: 9.61,
          format: "Currency",
        },
        Discounts: {
          value: 89.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-19",
    value: 34,
    reportType: "Calendar",
    previousDate: "2022-02-19",
    tooltipData: {
      current: {
        value: {
          value: 34,
          format: "Currency",
        },
        Customers: {
          value: 34,
          format: "Number",
        },
        Transactions: {
          value: 205,
          format: "Number",
        },
        Returns: {
          value: 46.11,
          format: "Currency",
        },
        Discounts: {
          value: 105.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-18",
    value: 38,
    reportType: "Calendar",
    previousDate: "2022-02-18",
    tooltipData: {
      current: {
        value: {
          value: 38,
          format: "Currency",
        },
        Customers: {
          value: 38,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 16.63,
          format: "Currency",
        },
        Discounts: {
          value: 125.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-17",
    value: 31,
    reportType: "Calendar",
    previousDate: "2022-02-17",
    tooltipData: {
      current: {
        value: {
          value: 31,
          format: "Currency",
        },
        Customers: {
          value: 31,
          format: "Number",
        },
        Transactions: {
          value: 204,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 74.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-16",
    value: 20,
    reportType: "Calendar",
    previousDate: "2022-02-16",
    tooltipData: {
      current: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 45,
          format: "Currency",
        },
        Discounts: {
          value: 34.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-15",
    value: 21,
    reportType: "Calendar",
    previousDate: "2022-02-15",
    tooltipData: {
      current: {
        value: {
          value: 21,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 18.15,
          format: "Currency",
        },
        Discounts: {
          value: 32.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-14",
    value: 24,
    reportType: "Calendar",
    previousDate: "2022-02-14",
    tooltipData: {
      current: {
        value: {
          value: 24,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 75.80999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 76.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-13",
    value: 18,
    reportType: "Calendar",
    previousDate: "2022-02-13",
    tooltipData: {
      current: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 43.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-12",
    value: 24,
    reportType: "Calendar",
    previousDate: "2022-02-12",
    tooltipData: {
      current: {
        value: {
          value: 24,
          format: "Currency",
        },
        Customers: {
          value: 24,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 5.68,
          format: "Currency",
        },
        Discounts: {
          value: 92.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-11",
    value: 22,
    reportType: "Calendar",
    previousDate: "2022-02-11",
    tooltipData: {
      current: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 240,
          format: "Number",
        },
        Returns: {
          value: 55.47,
          format: "Currency",
        },
        Discounts: {
          value: 64.19,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-10",
    value: 17,
    reportType: "Calendar",
    previousDate: "2022-02-10",
    tooltipData: {
      current: {
        value: {
          value: 17,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 23.64,
          format: "Currency",
        },
        Discounts: {
          value: 52.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-09",
    value: 18,
    reportType: "Calendar",
    previousDate: "2022-02-09",
    tooltipData: {
      current: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.55,
          format: "Currency",
        },
        Discounts: {
          value: 29.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-08",
    value: 13,
    reportType: "Calendar",
    previousDate: "2022-02-08",
    tooltipData: {
      current: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 9,
          format: "Currency",
        },
        Discounts: {
          value: 46.37,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-07",
    value: 17,
    reportType: "Calendar",
    previousDate: "2022-02-07",
    tooltipData: {
      current: {
        value: {
          value: 17,
          format: "Currency",
        },
        Customers: {
          value: 17,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 16.95,
          format: "Currency",
        },
        Discounts: {
          value: 35.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-06",
    value: 18,
    reportType: "Calendar",
    previousDate: "2022-02-06",
    tooltipData: {
      current: {
        value: {
          value: 18,
          format: "Currency",
        },
        Customers: {
          value: 18,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 13.05,
          format: "Currency",
        },
        Discounts: {
          value: 41.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-05",
    value: 22,
    reportType: "Calendar",
    previousDate: "2022-02-05",
    tooltipData: {
      current: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 233,
          format: "Number",
        },
        Returns: {
          value: 5.69,
          format: "Currency",
        },
        Discounts: {
          value: 50.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-04",
    value: 16,
    reportType: "Calendar",
    previousDate: "2022-02-04",
    tooltipData: {
      current: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 207,
          format: "Number",
        },
        Returns: {
          value: 16.18,
          format: "Currency",
        },
        Discounts: {
          value: 41.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-03",
    value: 19,
    reportType: "Calendar",
    previousDate: "2022-02-03",
    tooltipData: {
      current: {
        value: {
          value: 19,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.71,
          format: "Currency",
        },
        Discounts: {
          value: 47.43,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-02",
    value: 16,
    reportType: "Calendar",
    previousDate: "2022-02-02",
    tooltipData: {
      current: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-02-01",
    value: 8,
    reportType: "Calendar",
    previousDate: "2022-02-01",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 40.25,
          format: "Currency",
        },
        Discounts: {
          value: 6.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-31",
    value: 20,
    reportType: "Calendar",
    previousDate: "2022-01-31",
    tooltipData: {
      current: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 70.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-30",
    value: 14,
    reportType: "Calendar",
    previousDate: "2022-01-30",
    tooltipData: {
      current: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 8.8,
          format: "Currency",
        },
        Discounts: {
          value: 36.73,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-29",
    value: 14,
    reportType: "Calendar",
    previousDate: "2022-01-29",
    tooltipData: {
      current: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 16.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-28",
    value: 23,
    reportType: "Calendar",
    previousDate: "2022-01-28",
    tooltipData: {
      current: {
        value: {
          value: 23,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 254,
          format: "Number",
        },
        Returns: {
          value: 32.09,
          format: "Currency",
        },
        Discounts: {
          value: 49.72,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-27",
    value: 16,
    reportType: "Calendar",
    previousDate: "2022-01-27",
    tooltipData: {
      current: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 10.9,
          format: "Currency",
        },
        Discounts: {
          value: 23.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-26",
    value: 11,
    reportType: "Calendar",
    previousDate: "2022-01-26",
    tooltipData: {
      current: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 23.34999999999999,
          format: "Currency",
        },
        Discounts: {
          value: 13.87,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-25",
    value: 12,
    reportType: "Calendar",
    previousDate: "2022-01-25",
    tooltipData: {
      current: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 30.01,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-24",
    value: 13,
    reportType: "Calendar",
    previousDate: "2022-01-24",
    tooltipData: {
      current: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 5.27,
          format: "Currency",
        },
        Discounts: {
          value: 51.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-23",
    value: 10,
    reportType: "Calendar",
    previousDate: "2022-01-23",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 33.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-22",
    value: 22,
    reportType: "Calendar",
    previousDate: "2022-01-22",
    tooltipData: {
      current: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.46,
          format: "Currency",
        },
        Discounts: {
          value: 95.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-21",
    value: 16,
    reportType: "Calendar",
    previousDate: "2022-01-21",
    tooltipData: {
      current: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 11.5,
          format: "Currency",
        },
        Discounts: {
          value: 46.79,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-20",
    value: 14,
    reportType: "Calendar",
    previousDate: "2022-01-20",
    tooltipData: {
      current: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 3,
          format: "Currency",
        },
        Discounts: {
          value: 32.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-19",
    value: 10,
    reportType: "Calendar",
    previousDate: "2022-01-19",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 20.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-18",
    value: 10,
    reportType: "Calendar",
    previousDate: "2022-01-18",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 29.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-17",
    value: 7,
    reportType: "Calendar",
    previousDate: "2022-01-17",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 15.77,
          format: "Currency",
        },
        Discounts: {
          value: 4.73,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-16",
    value: 7,
    reportType: "Calendar",
    previousDate: "2022-01-16",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 182,
          format: "Number",
        },
        Returns: {
          value: 5.62,
          format: "Currency",
        },
        Discounts: {
          value: 7.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-15",
    value: 14,
    reportType: "Calendar",
    previousDate: "2022-01-15",
    tooltipData: {
      current: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 238,
          format: "Number",
        },
        Returns: {
          value: 7.1,
          format: "Currency",
        },
        Discounts: {
          value: 26.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-14",
    value: 15,
    reportType: "Calendar",
    previousDate: "2022-01-14",
    tooltipData: {
      current: {
        value: {
          value: 15,
          format: "Currency",
        },
        Customers: {
          value: 15,
          format: "Number",
        },
        Transactions: {
          value: 255,
          format: "Number",
        },
        Returns: {
          value: 37.12,
          format: "Currency",
        },
        Discounts: {
          value: 57.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-13",
    value: 11,
    reportType: "Calendar",
    previousDate: "2022-01-13",
    tooltipData: {
      current: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.55,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-12",
    value: 12,
    reportType: "Calendar",
    previousDate: "2022-01-12",
    tooltipData: {
      current: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 7.310000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-11",
    value: 9,
    reportType: "Calendar",
    previousDate: "2022-01-11",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 20.02,
          format: "Currency",
        },
        Discounts: {
          value: 22.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-10",
    value: 10,
    reportType: "Calendar",
    previousDate: "2022-01-10",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 41.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-09",
    value: 5,
    reportType: "Calendar",
    previousDate: "2022-01-09",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 4.8,
          format: "Currency",
        },
        Discounts: {
          value: 12.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-08",
    value: 20,
    reportType: "Calendar",
    previousDate: "2022-01-08",
    tooltipData: {
      current: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 7.69,
          format: "Currency",
        },
        Discounts: {
          value: 53.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-07",
    value: 20,
    reportType: "Calendar",
    previousDate: "2022-01-07",
    tooltipData: {
      current: {
        value: {
          value: 20,
          format: "Currency",
        },
        Customers: {
          value: 20,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 49.76,
          format: "Currency",
        },
        Discounts: {
          value: 46.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-06",
    value: 10,
    reportType: "Calendar",
    previousDate: "2022-01-06",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 8.57,
          format: "Currency",
        },
        Discounts: {
          value: 16.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-05",
    value: 21,
    reportType: "Calendar",
    previousDate: "2022-01-05",
    tooltipData: {
      current: {
        value: {
          value: 21,
          format: "Currency",
        },
        Customers: {
          value: 21,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 11.45,
          format: "Currency",
        },
        Discounts: {
          value: 32.98,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-04",
    value: 9,
    reportType: "Calendar",
    previousDate: "2022-01-04",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 67.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-03",
    value: 14,
    reportType: "Calendar",
    previousDate: "2022-01-03",
    tooltipData: {
      current: {
        value: {
          value: 14,
          format: "Currency",
        },
        Customers: {
          value: 14,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-02",
    value: 16,
    reportType: "Calendar",
    previousDate: "2022-01-02",
    tooltipData: {
      current: {
        value: {
          value: 16,
          format: "Currency",
        },
        Customers: {
          value: 16,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 19.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2023-01-01",
    value: 4,
    reportType: "Calendar",
    previousDate: "2022-01-01",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 76,
          format: "Number",
        },
        Returns: {
          value: 15.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.04,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-31",
    value: 11,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 226,
          format: "Number",
        },
        Returns: {
          value: 5.58,
          format: "Currency",
        },
        Discounts: {
          value: 59.67,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-30",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 6.54,
          format: "Currency",
        },
        Discounts: {
          value: 34.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-29",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.91,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-28",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 49.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-27",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 142,
          format: "Number",
        },
        Returns: {
          value: 5.78,
          format: "Currency",
        },
        Discounts: {
          value: 28.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-26",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 160,
          format: "Number",
        },
        Returns: {
          value: 8.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-25",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 18.89,
          format: "Currency",
        },
        Discounts: {
          value: 16.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-24",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 221,
          format: "Number",
        },
        Returns: {
          value: 15.99,
          format: "Currency",
        },
        Discounts: {
          value: 31.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-23",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-22",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 217,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 25.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-21",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 24.4,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-20",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-19",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 5.73,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-18",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 3.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-17",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 15.46,
          format: "Currency",
        },
        Discounts: {
          value: 17.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-16",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        Returns: {
          value: 4,
          format: "Currency",
        },
        Discounts: {
          value: 1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-15",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 27.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-14",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 8.75,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-13",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 14.91,
          format: "Currency",
        },
        Discounts: {
          value: 4.19,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-12",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 17.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-11",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 210,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 11.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-10",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-09",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 10,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-08",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 2.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-07",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-06",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-05",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-04",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 183,
          format: "Number",
        },
        Returns: {
          value: 3.93,
          format: "Currency",
        },
        Discounts: {
          value: 6.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-03",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 30.59,
          format: "Currency",
        },
        Discounts: {
          value: 1.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-02",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 4.07,
          format: "Currency",
        },
        Discounts: {
          value: 4.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-12-01",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 9.36,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-30",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-29",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 9.3,
          format: "Currency",
        },
        Discounts: {
          value: 4.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-28",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 20.84,
          format: "Currency",
        },
        Discounts: {
          value: 5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-27",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 2.42,
          format: "Currency",
        },
        Discounts: {
          value: 27.95,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-26",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 3.3,
          format: "Currency",
        },
        Discounts: {
          value: 2.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-25",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-24",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-23",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 253,
          format: "Number",
        },
        Returns: {
          value: 2.16,
          format: "Currency",
        },
        Discounts: {
          value: 35.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-22",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.94,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-21",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-20",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 187,
          format: "Number",
        },
        Returns: {
          value: 32.05,
          format: "Currency",
        },
        Discounts: {
          value: 12.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-19",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 10.47,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-18",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 22.23,
          format: "Currency",
        },
        Discounts: {
          value: 2.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-17",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 47.98,
          format: "Currency",
        },
        Discounts: {
          value: 13.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-16",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 2.2,
          format: "Currency",
        },
        Discounts: {
          value: 0.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-15",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 11.16,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-14",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-13",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 30.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-12",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.17,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-11",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 188,
          format: "Number",
        },
        Returns: {
          value: 11,
          format: "Currency",
        },
        Discounts: {
          value: 6.61,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-10",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-09",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-08",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 9.75,
          format: "Currency",
        },
        Discounts: {
          value: 5.33,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-07",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 10.81,
          format: "Currency",
        },
        Discounts: {
          value: 0.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-06",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-05",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-04",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 178,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 6.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-03",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-02",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 55.11,
          format: "Currency",
        },
        Discounts: {
          value: 15.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-11-01",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 5.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-31",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-30",
    value: 10,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 211,
          format: "Number",
        },
        Returns: {
          value: 5.96,
          format: "Currency",
        },
        Discounts: {
          value: 18.56,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-29",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 33.09,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-28",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 2.95,
          format: "Currency",
        },
        Discounts: {
          value: 7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-27",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 14.7,
          format: "Currency",
        },
        Discounts: {
          value: 12.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-26",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 4.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-25",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 8.06,
          format: "Currency",
        },
        Discounts: {
          value: 6.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-24",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 246,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.28,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-23",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 263,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 17.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-22",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 241,
          format: "Number",
        },
        Returns: {
          value: 37.26000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 18.11,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-21",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 201,
          format: "Number",
        },
        Returns: {
          value: 15.91,
          format: "Currency",
        },
        Discounts: {
          value: 8.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-20",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 16.9,
          format: "Currency",
        },
        Discounts: {
          value: 0.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-19",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 7.75,
          format: "Currency",
        },
        Discounts: {
          value: 3.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-18",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.69,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-17",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-16",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 203,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.88,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-15",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 6.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-14",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 22.5,
          format: "Currency",
        },
        Discounts: {
          value: 6.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-13",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        Returns: {
          value: 5.63,
          format: "Currency",
        },
        Discounts: {
          value: 3.89,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-12",
    value: 9,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 7.380000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 3.56,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-11",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 16,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-10",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        Returns: {
          value: 4.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-09",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 14.76,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-08",
    value: 8,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 8,
          format: "Currency",
        },
        Customers: {
          value: 8,
          format: "Number",
        },
        Transactions: {
          value: 169,
          format: "Number",
        },
        Returns: {
          value: 20.08,
          format: "Currency",
        },
        Discounts: {
          value: 0.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-07",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 8.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-06",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 10.2,
          format: "Currency",
        },
        Discounts: {
          value: 19.53,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-05",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 1.77,
          format: "Currency",
        },
        Discounts: {
          value: 0.58,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-04",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        Returns: {
          value: 19.14,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-03",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 3.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-02",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 236,
          format: "Number",
        },
        Returns: {
          value: 17.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-10-01",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 3.05,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-30",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        Returns: {
          value: 14.33,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-29",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        Returns: {
          value: 6.560000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 23.05,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-28",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 5.98,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-27",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-26",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 3.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-25",
    value: 9,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 216,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-24",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 198,
          format: "Number",
        },
        Returns: {
          value: 15.4,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-23",
    value: 10,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 10,
          format: "Currency",
        },
        Customers: {
          value: 10,
          format: "Number",
        },
        Transactions: {
          value: 147,
          format: "Number",
        },
        Returns: {
          value: 66.6,
          format: "Currency",
        },
        Discounts: {
          value: 18.97,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-22",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-21",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-20",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 11.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-19",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 1.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-18",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 5.09,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-17",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-16",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 21.59,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-15",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 22.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-14",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-13",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-12",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 117,
          format: "Number",
        },
        Returns: {
          value: 1.85,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-11",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 3.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-10",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 195,
          format: "Number",
        },
        Returns: {
          value: 0.9,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-09",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 7.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-08",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 129,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-07",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-06",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 5.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-05",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 34.18,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-04",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        Returns: {
          value: 21.81,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-03",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 197,
          format: "Number",
        },
        Returns: {
          value: 4.48,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-02",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 171,
          format: "Number",
        },
        Returns: {
          value: 13.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-09-01",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        Returns: {
          value: 22.99,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-31",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-30",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 23.91,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-29",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 13.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-28",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 175,
          format: "Number",
        },
        Returns: {
          value: 10.3,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-27",
    value: 9,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 192,
          format: "Number",
        },
        Returns: {
          value: 1.95,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-26",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        Returns: {
          value: 10.51,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-25",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 12.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-24",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-23",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-22",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 123,
          format: "Number",
        },
        Returns: {
          value: 33.15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-21",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 202,
          format: "Number",
        },
        Returns: {
          value: 21.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-20",
    value: 9,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 9,
          format: "Currency",
        },
        Customers: {
          value: 9,
          format: "Number",
        },
        Transactions: {
          value: 212,
          format: "Number",
        },
        Returns: {
          value: 20.58,
          format: "Currency",
        },
        Discounts: {
          value: 4.51,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-19",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        Returns: {
          value: 6.1,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-18",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-17",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-16",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 116,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-15",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 2.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-14",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 181,
          format: "Number",
        },
        Returns: {
          value: 53.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-13",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        Returns: {
          value: 22.42,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-12",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 176,
          format: "Number",
        },
        Returns: {
          value: 27.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-11",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 10.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-10",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 18.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-09",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        Returns: {
          value: 7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-08",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        Returns: {
          value: 6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-07",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 170,
          format: "Number",
        },
        Returns: {
          value: 10.61,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-06",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 0.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-05",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 32.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-04",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 10.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-03",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 20.44,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-02",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 26.53,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-08-01",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 14.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-31",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 163,
          format: "Number",
        },
        Returns: {
          value: 26.38,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-30",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-29",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 150,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-28",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 4.49,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-27",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 110,
          format: "Number",
        },
        Returns: {
          value: 5.84,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-26",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 31.79,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-25",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 12.62,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-24",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 21.97,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-23",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 28.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-22",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-21",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 17.25,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-20",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-19",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 102,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-18",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 97,
          format: "Number",
        },
        Returns: {
          value: 12.34,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-17",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 165,
          format: "Number",
        },
        Returns: {
          value: 14.08,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-16",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 16.07,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-15",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 39.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-14",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-13",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-12",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 3.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-11",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-10",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        Returns: {
          value: 4.02,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-09",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-08",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        Returns: {
          value: 5.65,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-07",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 15,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-06",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 6.01,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-05",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 16.29,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-04",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 45.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-03",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-02",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 15.6,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-07-01",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 133,
          format: "Number",
        },
        Returns: {
          value: 7.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-30",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 134,
          format: "Number",
        },
        Returns: {
          value: 8.43,
          format: "Currency",
        },
        Discounts: {
          value: 0.4,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-29",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 10.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-28",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-27",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 90,
          format: "Number",
        },
        Returns: {
          value: 22.2,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-26",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-25",
    value: 6,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 6,
          format: "Currency",
        },
        Customers: {
          value: 6,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        Returns: {
          value: 8.5,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-24",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 1.75,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-23",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-22",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-21",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.89,
          format: "Currency",
        },
        Discounts: {
          value: 2.77,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-20",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 3.9,
          format: "Currency",
        },
        Discounts: {
          value: 10.14,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-19",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-18",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 19.5,
          format: "Currency",
        },
        Discounts: {
          value: 2.92,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-17",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 14.61,
          format: "Currency",
        },
        Discounts: {
          value: 5.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-16",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 104,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.54,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-15",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 94,
          format: "Number",
        },
        Returns: {
          value: 1.35,
          format: "Currency",
        },
        Discounts: {
          value: 0.75,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-14",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 9.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-13",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 14.15,
          format: "Currency",
        },
        Discounts: {
          value: 4.48,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-12",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 125,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 9.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-11",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 40.24,
          format: "Currency",
        },
        Discounts: {
          value: 5.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-10",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-09",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 3.88,
          format: "Currency",
        },
        Discounts: {
          value: 6.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-08",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 89,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-07",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-06",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 16.7,
          format: "Currency",
        },
        Discounts: {
          value: 8.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-05",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 151,
          format: "Number",
        },
        Returns: {
          value: 3.83,
          format: "Currency",
        },
        Discounts: {
          value: 11.38,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-04",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 7.6,
          format: "Currency",
        },
        Discounts: {
          value: 15.74,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-03",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        Returns: {
          value: 3.4,
          format: "Currency",
        },
        Discounts: {
          value: 7.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-02",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-06-01",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.62,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-31",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 14.13,
          format: "Currency",
        },
        Discounts: {
          value: 1.1,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-30",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-29",
    value: 7,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 7,
          format: "Currency",
        },
        Customers: {
          value: 7,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 20.27,
          format: "Currency",
        },
        Discounts: {
          value: 13.44,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-28",
    value: 12,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 12,
          format: "Currency",
        },
        Customers: {
          value: 12,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 18.47,
          format: "Currency",
        },
        Discounts: {
          value: 6.25,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-27",
    value: 13,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 13,
          format: "Currency",
        },
        Customers: {
          value: 13,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        Returns: {
          value: 9.85,
          format: "Currency",
        },
        Discounts: {
          value: 22.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-26",
    value: 11,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 11,
          format: "Currency",
        },
        Customers: {
          value: 11,
          format: "Number",
        },
        Transactions: {
          value: 126,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 2.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-25",
    value: 22,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 22,
          format: "Currency",
        },
        Customers: {
          value: 22,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.99,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-24",
    value: 23,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 23,
          format: "Currency",
        },
        Customers: {
          value: 23,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.78,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-23",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 21.99,
          format: "Currency",
        },
        Discounts: {
          value: 5.08,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-22",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 185,
          format: "Number",
        },
        Returns: {
          value: 6.770000000000001,
          format: "Currency",
        },
        Discounts: {
          value: 8.42,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-21",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 156,
          format: "Number",
        },
        Returns: {
          value: 10.4,
          format: "Currency",
        },
        Discounts: {
          value: 10.86,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-20",
    value: 5,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 5,
          format: "Currency",
        },
        Customers: {
          value: 5,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.66,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-19",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        Returns: {
          value: 11.72,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-18",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        Returns: {
          value: 1.2,
          format: "Currency",
        },
        Discounts: {
          value: 2.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-17",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.03,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-16",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        Returns: {
          value: 14,
          format: "Currency",
        },
        Discounts: {
          value: 0.46,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-15",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 276,
          format: "Number",
        },
        Returns: {
          value: 16.44,
          format: "Currency",
        },
        Discounts: {
          value: 78.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-14",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 158,
          format: "Number",
        },
        Returns: {
          value: 3.08,
          format: "Currency",
        },
        Discounts: {
          value: 4.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-13",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 121,
          format: "Number",
        },
        Returns: {
          value: 1.8,
          format: "Currency",
        },
        Discounts: {
          value: 17.83,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-12",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-11",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 21.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-10",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 3.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.7,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-09",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 71,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-08",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.39,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-07",
    value: 4,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 4,
          format: "Currency",
        },
        Customers: {
          value: 4,
          format: "Number",
        },
        Transactions: {
          value: 124,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-06",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        Returns: {
          value: 6.4,
          format: "Currency",
        },
        Discounts: {
          value: 1.24,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-05",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 92,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 2.21,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-04",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        Returns: {
          value: 3.19,
          format: "Currency",
        },
        Discounts: {
          value: 11.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-03",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-02",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 91,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-05-01",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 177,
          format: "Number",
        },
        Returns: {
          value: 88.4,
          format: "Currency",
        },
        Discounts: {
          value: 29.63,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-30",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 155,
          format: "Number",
        },
        Returns: {
          value: 10.25,
          format: "Currency",
        },
        Discounts: {
          value: 5.3,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-29",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 2.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.31,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-28",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 105,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.6,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-27",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 139,
          format: "Number",
        },
        Returns: {
          value: 12.5,
          format: "Currency",
        },
        Discounts: {
          value: 1.57,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-26",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 128,
          format: "Number",
        },
        Returns: {
          value: 7.49,
          format: "Currency",
        },
        Discounts: {
          value: 2.26,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-25",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-24",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 173,
          format: "Number",
        },
        Returns: {
          value: 3.5,
          format: "Currency",
        },
        Discounts: {
          value: 29.97,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-23",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 146,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.45,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-22",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        Returns: {
          value: 28.34,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-21",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 10,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-20",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 80,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.96,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-19",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 96,
          format: "Number",
        },
        Returns: {
          value: 12,
          format: "Currency",
        },
        Discounts: {
          value: 16.85,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-18",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 83,
          format: "Number",
        },
        Returns: {
          value: 5.75,
          format: "Currency",
        },
        Discounts: {
          value: 2.07,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-17",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 149,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-16",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 141,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-15",
    value: 3,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 3,
          format: "Currency",
        },
        Customers: {
          value: 3,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 17.06,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-14",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 3.73,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-13",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 85,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 65.81,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-12",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 87,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 15.65,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-11",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0.2,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-10",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 114,
          format: "Number",
        },
        Returns: {
          value: 13.35,
          format: "Currency",
        },
        Discounts: {
          value: 10.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-09",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 1.23,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-08",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 13.35,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-07",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        Returns: {
          value: 8,
          format: "Currency",
        },
        Discounts: {
          value: 6.13,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-06",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-05",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 4.05,
          format: "Currency",
        },
        Discounts: {
          value: 7.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-04",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 95,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 7.71,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-03",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 162,
          format: "Number",
        },
        Returns: {
          value: 27.49,
          format: "Currency",
        },
        Discounts: {
          value: 474.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-02",
    value: 2,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 2,
          format: "Currency",
        },
        Customers: {
          value: 2,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        Returns: {
          value: 8.85,
          format: "Currency",
        },
        Discounts: {
          value: 383.18,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-04-01",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        Returns: {
          value: 5,
          format: "Currency",
        },
        Discounts: {
          value: 381.17,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-31",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        Returns: {
          value: 10.96,
          format: "Currency",
        },
        Discounts: {
          value: 12.8,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-30",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 64,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-29",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 88,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 6.29,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-28",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 81,
          format: "Number",
        },
        Returns: {
          value: 6.5,
          format: "Currency",
        },
        Discounts: {
          value: 8.41,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-27",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 200,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 626.52,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-26",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 229,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 801.5,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-25",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-24",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 2,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-23",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-22",
    value: 1,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 1,
          format: "Currency",
        },
        Customers: {
          value: 1,
          format: "Number",
        },
        Transactions: {
          value: 1,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-21",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-20",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-19",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-18",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-17",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-16",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-15",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-14",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-13",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-12",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-11",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-10",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-09",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-08",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-07",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-06",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-05",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-04",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-03",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-02",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-03-01",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-28",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-27",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-26",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-25",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-24",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-23",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-22",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-21",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-20",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-19",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-18",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-17",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-16",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-15",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-14",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-13",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-12",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-11",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-10",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-09",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-08",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-07",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-06",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-05",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-04",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-03",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-02",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-02-01",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-31",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-30",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-29",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-28",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-27",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-26",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-25",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-24",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-23",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-22",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-21",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-20",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-19",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-18",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-17",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-16",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-15",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-14",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-13",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-12",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-11",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-10",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-09",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-08",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-07",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-06",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-05",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-04",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-03",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-02",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: "2022-01-01",
    value: 0,
    reportType: "Calendar",
    tooltipData: {
      current: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
      previous: {
        value: {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        Returns: {
          value: 0,
          format: "Currency",
        },
        Discounts: {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
]
export const CalendarChartParameters = {
  margin: {
    top: 40,
    right: 0,
    bottom: 40,
    left: 0,
  },
  from: "2015-03-01",
  to: "2016-07-12",

  emptyColor: "#eeeeee",
  colors: ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"],
  yearSpacing: 40,
  monthBorderColor: "#ffffff",
  dayBorderWidth: 2,
  dayBorderColor: "#ffffff",

  chartContainerDimensions: {
    height: "600px",
    minHeight: "350px",
    maxHeight: "unset",
  },
  tooltip_data: [
    {
      name: "value",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Currency",
      color: "",
    },
    {
      name: "Discounts",
      format: "Currency",
      color: "",
    },
  ],
  defaultColor: [
    "#e0f7ff",
    "#b3eaff",
    "#80dcff",
    "#4dceff",
    "#1abfff",
    "#0099cc",
  ],
  "macro-metrics": [
    {
      name: "highest",
      helpText: "Highest datapoint",
      firstRow: {
        value: "",
        operation: "aggregate",
      },
      secondRow: {
        value: "{day}",
        operation: "templateString",
      },
      thirdRow: {
        value: "Star Performer",
        operation: null,
      },
      aggregateFunc: "MAX",
      targetField: "value",
      format: "Currency",
      color: "#23BF6F",
      icon: "VerifiedIcon",
    },
    {
      name: "lowest",
      helpText: "Lowest datapoint",
      firstRow: {
        value: "",
        operation: "aggregate",
      },
      secondRow: {
        value: "{day}",
        operation: "templateString",
      },
      thirdRow: {
        value: "Low Performer",
        operation: null,
      },
      aggregateFunc: "MIN",
      targetField: "value",
      format: "Currency",
      color: "#EC2D30",
      icon: "NewReleasesIcon",
    },
  ],
  pdfFields: [
    {
      name: "value",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Currency",
      color: "",
    },
    {
      name: "Discounts",
      format: "Currency",
      color: "",
    },
  ],
  showTooltipPrevious: true,
}
export const staticApiResponse = {
  reportDataCurrent: [
    {
      day: "2024-12-31",
      datekey: 20241231,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-30",
      datekey: 20241230,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-29",
      datekey: 20241229,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-28",
      datekey: 20241228,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-27",
      datekey: 20241227,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-26",
      datekey: 20241226,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-25",
      datekey: 20241225,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-24",
      datekey: 20241224,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-23",
      datekey: 20241223,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-22",
      datekey: 20241222,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-21",
      datekey: 20241221,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-20",
      datekey: 20241220,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-19",
      datekey: 20241219,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-18",
      datekey: 20241218,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-17",
      datekey: 20241217,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-16",
      datekey: 20241216,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-15",
      datekey: 20241215,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-14",
      datekey: 20241214,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-13",
      datekey: 20241213,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-12",
      datekey: 20241212,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-11",
      datekey: 20241211,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-10",
      datekey: 20241210,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-09",
      datekey: 20241209,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-08",
      datekey: 20241208,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-07",
      datekey: 20241207,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-06",
      datekey: 20241206,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-05",
      datekey: 20241205,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-04",
      datekey: 20241204,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-03",
      datekey: 20241203,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-02",
      datekey: 20241202,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-12-01",
      datekey: 20241201,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-30",
      datekey: 20241130,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-29",
      datekey: 20241129,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-28",
      datekey: 20241128,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-27",
      datekey: 20241127,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-26",
      datekey: 20241126,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-25",
      datekey: 20241125,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-24",
      datekey: 20241124,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-23",
      datekey: 20241123,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-22",
      datekey: 20241122,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-21",
      datekey: 20241121,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-20",
      datekey: 20241120,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-19",
      datekey: 20241119,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-18",
      datekey: 20241118,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-17",
      datekey: 20241117,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-16",
      datekey: 20241116,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-15",
      datekey: 20241115,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-14",
      datekey: 20241114,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-13",
      datekey: 20241113,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-12",
      datekey: 20241112,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-11",
      datekey: 20241111,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-10",
      datekey: 20241110,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-11-09",
      datekey: 20241109,
      value: 5244.259999999998,
      "Total Amount": 5244.259999999998,
      Customers: 114,
      Transactions: 152,
      Returns: 5.75,
      Discounts: 37.95,
    },
    {
      day: "2024-11-08",
      datekey: 20241108,
      value: 3167.899999999999,
      "Total Amount": 3167.899999999999,
      Customers: 81,
      Transactions: 110,
      Returns: 4.96,
      Discounts: 39.74,
    },
    {
      day: "2024-11-07",
      datekey: 20241107,
      value: 2506.569999999999,
      "Total Amount": 2506.569999999999,
      Customers: 65,
      Transactions: 99,
      Returns: 0,
      Discounts: 27.43,
    },
    {
      day: "2024-11-06",
      datekey: 20241106,
      value: 3100.369999999998,
      "Total Amount": 3100.369999999998,
      Customers: 61,
      Transactions: 88,
      Returns: 0,
      Discounts: 188.26,
    },
    {
      day: "2024-11-05",
      datekey: 20241105,
      value: 2792.269999999999,
      "Total Amount": 2792.269999999999,
      Customers: 72,
      Transactions: 104,
      Returns: 10.7,
      Discounts: 9,
    },
    {
      day: "2024-11-04",
      datekey: 20241104,
      value: 2926.300000000001,
      "Total Amount": 2926.300000000001,
      Customers: 79,
      Transactions: 110,
      Returns: 13.7,
      Discounts: 30.05000000000001,
    },
    {
      day: "2024-11-03",
      datekey: 20241103,
      value: 4317.190000000001,
      "Total Amount": 4317.190000000001,
      Customers: 128,
      Transactions: 157,
      Returns: 0,
      Discounts: 35.59,
    },
    {
      day: "2024-11-02",
      datekey: 20241102,
      value: 4304.139999999995,
      "Total Amount": 4304.139999999995,
      Customers: 95,
      Transactions: 154,
      Returns: 0,
      Discounts: 63.37999999999999,
    },
    {
      day: "2024-11-01",
      datekey: 20241101,
      value: 3830.789999999997,
      "Total Amount": 3830.789999999997,
      Customers: 118,
      Transactions: 157,
      Returns: 15.1,
      Discounts: 20.4,
    },
    {
      day: "2024-10-31",
      datekey: 20241031,
      value: 3544.669999999998,
      "Total Amount": 3544.669999999998,
      Customers: 99,
      Transactions: 142,
      Returns: 19.69,
      Discounts: 12.08,
    },
    {
      day: "2024-10-30",
      datekey: 20241030,
      value: 4319.699999999995,
      "Total Amount": 4319.699999999995,
      Customers: 98,
      Transactions: 134,
      Returns: 5.5,
      Discounts: 176.24,
    },
    {
      day: "2024-10-29",
      datekey: 20241029,
      value: 2641.4,
      "Total Amount": 2641.4,
      Customers: 90,
      Transactions: 102,
      Returns: 14.95,
      Discounts: 25.46,
    },
    {
      day: "2024-10-28",
      datekey: 20241028,
      value: 2084.670000000001,
      "Total Amount": 2084.670000000001,
      Customers: 65,
      Transactions: 91,
      Returns: 1.25,
      Discounts: 1.17,
    },
    {
      day: "2024-10-27",
      datekey: 20241027,
      value: 4349.139999999999,
      "Total Amount": 4349.139999999999,
      Customers: 110,
      Transactions: 167,
      Returns: 4.88,
      Discounts: 51.1,
    },
    {
      day: "2024-10-26",
      datekey: 20241026,
      value: 4143.2,
      "Total Amount": 4143.2,
      Customers: 92,
      Transactions: 144,
      Returns: 10.8,
      Discounts: 31.82,
    },
    {
      day: "2024-10-25",
      datekey: 20241025,
      value: 3218.739999999999,
      "Total Amount": 3218.739999999999,
      Customers: 88,
      Transactions: 117,
      Returns: 0,
      Discounts: 16.51,
    },
    {
      day: "2024-10-24",
      datekey: 20241024,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-10-23",
      datekey: 20241023,
      value: 2540.79,
      "Total Amount": 2540.79,
      Customers: 75,
      Transactions: 101,
      Returns: 7.15,
      Discounts: 42.16,
    },
    {
      day: "2024-10-22",
      datekey: 20241022,
      value: 3167.339999999998,
      "Total Amount": 3167.339999999998,
      Customers: 76,
      Transactions: 103,
      Returns: 4.5,
      Discounts: 28.55,
    },
    {
      day: "2024-10-21",
      datekey: 20241021,
      value: 2625.930000000001,
      "Total Amount": 2625.930000000001,
      Customers: 76,
      Transactions: 101,
      Returns: 0,
      Discounts: 12.55,
    },
    {
      day: "2024-10-20",
      datekey: 20241020,
      value: 3655.079999999998,
      "Total Amount": 3655.079999999998,
      Customers: 92,
      Transactions: 136,
      Returns: 1.6,
      Discounts: 22.38999999999999,
    },
    {
      day: "2024-10-19",
      datekey: 20241019,
      value: 4364.519999999996,
      "Total Amount": 4364.519999999996,
      Customers: 121,
      Transactions: 179,
      Returns: 0,
      Discounts: 36.25000000000001,
    },
    {
      day: "2024-10-18",
      datekey: 20241018,
      value: 4410.85,
      "Total Amount": 4410.85,
      Customers: 115,
      Transactions: 160,
      Returns: 33.74,
      Discounts: 13.23,
    },
    {
      day: "2024-10-17",
      datekey: 20241017,
      value: 2547.44,
      "Total Amount": 2547.44,
      Customers: 64,
      Transactions: 90,
      Returns: 1.5,
      Discounts: 30.64,
    },
    {
      day: "2024-10-16",
      datekey: 20241016,
      value: 2753.89,
      "Total Amount": 2753.89,
      Customers: 81,
      Transactions: 116,
      Returns: 7.3,
      Discounts: 40.96,
    },
    {
      day: "2024-10-15",
      datekey: 20241015,
      value: 2891.739999999998,
      "Total Amount": 2891.739999999998,
      Customers: 88,
      Transactions: 110,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-10-14",
      datekey: 20241014,
      value: 2494.62,
      "Total Amount": 2494.62,
      Customers: 73,
      Transactions: 96,
      Returns: 4.07,
      Discounts: 17.24,
    },
    {
      day: "2024-10-13",
      datekey: 20241013,
      value: 3199.299999999998,
      "Total Amount": 3199.299999999998,
      Customers: 93,
      Transactions: 156,
      Returns: 23.4,
      Discounts: 24.76,
    },
    {
      day: "2024-10-12",
      datekey: 20241012,
      value: 3837.140000000001,
      "Total Amount": 3837.140000000001,
      Customers: 94,
      Transactions: 149,
      Returns: 8.79,
      Discounts: 22.95,
    },
    {
      day: "2024-10-11",
      datekey: 20241011,
      value: 5816.01,
      "Total Amount": 5816.01,
      Customers: 88,
      Transactions: 137,
      Returns: 0,
      Discounts: 314.39,
    },
    {
      day: "2024-10-10",
      datekey: 20241010,
      value: 3409.38,
      "Total Amount": 3409.38,
      Customers: 88,
      Transactions: 134,
      Returns: 8.440000000000001,
      Discounts: 16.38,
    },
    {
      day: "2024-10-09",
      datekey: 20241009,
      value: 3644.820000000001,
      "Total Amount": 3644.820000000001,
      Customers: 64,
      Transactions: 110,
      Returns: 7.31,
      Discounts: 167.9,
    },
    {
      day: "2024-10-08",
      datekey: 20241008,
      value: 2811.519999999999,
      "Total Amount": 2811.519999999999,
      Customers: 94,
      Transactions: 117,
      Returns: 1.5,
      Discounts: 5.65,
    },
    {
      day: "2024-10-07",
      datekey: 20241007,
      value: 2707.1,
      "Total Amount": 2707.1,
      Customers: 67,
      Transactions: 104,
      Returns: 0,
      Discounts: 47.66,
    },
    {
      day: "2024-10-06",
      datekey: 20241006,
      value: 4933.879999999998,
      "Total Amount": 4933.879999999998,
      Customers: 124,
      Transactions: 175,
      Returns: 2.7,
      Discounts: 42.08,
    },
    {
      day: "2024-10-05",
      datekey: 20241005,
      value: 3539.349999999998,
      "Total Amount": 3539.349999999998,
      Customers: 99,
      Transactions: 142,
      Returns: 0.97,
      Discounts: 31.98,
    },
    {
      day: "2024-10-04",
      datekey: 20241004,
      value: 2783.799999999998,
      "Total Amount": 2783.799999999998,
      Customers: 90,
      Transactions: 125,
      Returns: 4.7,
      Discounts: 37.49,
    },
    {
      day: "2024-10-03",
      datekey: 20241003,
      value: 3994.359999999998,
      "Total Amount": 3994.359999999998,
      Customers: 75,
      Transactions: 113,
      Returns: 18.2,
      Discounts: 225.17,
    },
    {
      day: "2024-10-02",
      datekey: 20241002,
      value: 3104.259999999998,
      "Total Amount": 3104.259999999998,
      Customers: 81,
      Transactions: 117,
      Returns: 7.8,
      Discounts: 26.43,
    },
    {
      day: "2024-10-01",
      datekey: 20241001,
      value: 2400.069999999999,
      "Total Amount": 2400.069999999999,
      Customers: 70,
      Transactions: 86,
      Returns: 0,
      Discounts: 20.9,
    },
    {
      day: "2024-09-30",
      datekey: 20240930,
      value: 2104.26,
      "Total Amount": 2104.26,
      Customers: 77,
      Transactions: 95,
      Returns: 0,
      Discounts: 14.55,
    },
    {
      day: "2024-09-29",
      datekey: 20240929,
      value: 4253.789999999997,
      "Total Amount": 4253.789999999997,
      Customers: 116,
      Transactions: 171,
      Returns: 4.9,
      Discounts: 52.5,
    },
    {
      day: "2024-09-28",
      datekey: 20240928,
      value: 4882.859999999996,
      "Total Amount": 4882.859999999996,
      Customers: 112,
      Transactions: 162,
      Returns: 13.5,
      Discounts: 58.24,
    },
    {
      day: "2024-09-27",
      datekey: 20240927,
      value: 2936.09,
      "Total Amount": 2936.09,
      Customers: 77,
      Transactions: 109,
      Returns: 0,
      Discounts: 33.52,
    },
    {
      day: "2024-09-26",
      datekey: 20240926,
      value: 4032.419999999999,
      "Total Amount": 4032.419999999999,
      Customers: 95,
      Transactions: 144,
      Returns: 15.25,
      Discounts: 58.35,
    },
    {
      day: "2024-09-25",
      datekey: 20240925,
      value: 4201.079999999996,
      "Total Amount": 4201.079999999996,
      Customers: 96,
      Transactions: 138,
      Returns: 5.25,
      Discounts: 192.02,
    },
    {
      day: "2024-09-24",
      datekey: 20240924,
      value: 2048.98,
      "Total Amount": 2048.98,
      Customers: 75,
      Transactions: 94,
      Returns: 24.32,
      Discounts: 6.46,
    },
    {
      day: "2024-09-23",
      datekey: 20240923,
      value: 2622.19,
      "Total Amount": 2622.19,
      Customers: 80,
      Transactions: 105,
      Returns: 4.7,
      Discounts: 24.38,
    },
    {
      day: "2024-09-22",
      datekey: 20240922,
      value: 4083.64,
      "Total Amount": 4083.64,
      Customers: 110,
      Transactions: 152,
      Returns: 32.18,
      Discounts: 101.2,
    },
    {
      day: "2024-09-21",
      datekey: 20240921,
      value: 3826.059999999997,
      "Total Amount": 3826.059999999997,
      Customers: 116,
      Transactions: 152,
      Returns: 3.56,
      Discounts: 23.38,
    },
    {
      day: "2024-09-20",
      datekey: 20240920,
      value: 4157.889999999999,
      "Total Amount": 4157.889999999999,
      Customers: 96,
      Transactions: 143,
      Returns: 3.88,
      Discounts: 46.37,
    },
    {
      day: "2024-09-19",
      datekey: 20240919,
      value: 2605.83,
      "Total Amount": 2605.83,
      Customers: 75,
      Transactions: 115,
      Returns: 0,
      Discounts: 30.96,
    },
    {
      day: "2024-09-18",
      datekey: 20240918,
      value: 3772.09,
      "Total Amount": 3772.09,
      Customers: 79,
      Transactions: 115,
      Returns: 12.38,
      Discounts: 222.05,
    },
    {
      day: "2024-09-17",
      datekey: 20240917,
      value: 1790.73,
      "Total Amount": 1790.73,
      Customers: 61,
      Transactions: 73,
      Returns: 4.99,
      Discounts: 47.05,
    },
    {
      day: "2024-09-16",
      datekey: 20240916,
      value: 2684.01,
      "Total Amount": 2684.01,
      Customers: 80,
      Transactions: 102,
      Returns: 24.48,
      Discounts: 13.51,
    },
    {
      day: "2024-09-15",
      datekey: 20240915,
      value: 4174.929999999996,
      "Total Amount": 4174.929999999996,
      Customers: 123,
      Transactions: 169,
      Returns: 2.26,
      Discounts: 65.01,
    },
    {
      day: "2024-09-14",
      datekey: 20240914,
      value: 3535.369999999999,
      "Total Amount": 3535.369999999999,
      Customers: 117,
      Transactions: 159,
      Returns: 2,
      Discounts: 24.02,
    },
    {
      day: "2024-09-13",
      datekey: 20240913,
      value: 4228.719999999998,
      "Total Amount": 4228.719999999998,
      Customers: 108,
      Transactions: 142,
      Returns: 5.33,
      Discounts: 175.83,
    },
    {
      day: "2024-09-12",
      datekey: 20240912,
      value: 3820.949999999999,
      "Total Amount": 3820.949999999999,
      Customers: 86,
      Transactions: 116,
      Returns: 8.92,
      Discounts: 142.97,
    },
    {
      day: "2024-09-11",
      datekey: 20240911,
      value: 2794.5,
      "Total Amount": 2794.5,
      Customers: 83,
      Transactions: 107,
      Returns: 11.5,
      Discounts: 31.69,
    },
    {
      day: "2024-09-10",
      datekey: 20240910,
      value: 3304.689999999998,
      "Total Amount": 3304.689999999998,
      Customers: 97,
      Transactions: 118,
      Returns: 8.5,
      Discounts: 0.42,
    },
    {
      day: "2024-09-09",
      datekey: 20240909,
      value: 2634.66,
      "Total Amount": 2634.66,
      Customers: 73,
      Transactions: 104,
      Returns: 2.16,
      Discounts: 7.699999999999999,
    },
    {
      day: "2024-09-08",
      datekey: 20240908,
      value: 3254.539999999998,
      "Total Amount": 3254.539999999998,
      Customers: 102,
      Transactions: 142,
      Returns: 0,
      Discounts: 23.58,
    },
    {
      day: "2024-09-07",
      datekey: 20240907,
      value: 3817.389999999999,
      "Total Amount": 3817.389999999999,
      Customers: 109,
      Transactions: 152,
      Returns: 0,
      Discounts: 59.16999999999998,
    },
    {
      day: "2024-09-06",
      datekey: 20240906,
      value: 3494.089999999998,
      "Total Amount": 3494.089999999998,
      Customers: 110,
      Transactions: 165,
      Returns: 0,
      Discounts: 28.25,
    },
    {
      day: "2024-09-05",
      datekey: 20240905,
      value: 3098.55,
      "Total Amount": 3098.55,
      Customers: 100,
      Transactions: 128,
      Returns: 3.83,
      Discounts: 41.27,
    },
    {
      day: "2024-09-04",
      datekey: 20240904,
      value: 4208.949999999998,
      "Total Amount": 4208.949999999998,
      Customers: 85,
      Transactions: 120,
      Returns: 7.5,
      Discounts: 241.13,
    },
    {
      day: "2024-09-03",
      datekey: 20240903,
      value: 2118.100000000001,
      "Total Amount": 2118.100000000001,
      Customers: 75,
      Transactions: 95,
      Returns: 0,
      Discounts: 7.46,
    },
    {
      day: "2024-09-02",
      datekey: 20240902,
      value: 3536.219999999998,
      "Total Amount": 3536.219999999998,
      Customers: 98,
      Transactions: 142,
      Returns: 1.75,
      Discounts: 45.27,
    },
    {
      day: "2024-09-01",
      datekey: 20240901,
      value: 4465.089999999993,
      "Total Amount": 4465.089999999993,
      Customers: 107,
      Transactions: 161,
      Returns: 13.1,
      Discounts: 86.86999999999999,
    },
    {
      day: "2024-08-31",
      datekey: 20240831,
      value: 4211.759999999995,
      "Total Amount": 4211.759999999995,
      Customers: 121,
      Transactions: 153,
      Returns: 0,
      Discounts: 45.94,
    },
    {
      day: "2024-08-30",
      datekey: 20240830,
      value: 4842.619999999997,
      "Total Amount": 4842.619999999997,
      Customers: 115,
      Transactions: 158,
      Returns: 0,
      Discounts: 70.26,
    },
    {
      day: "2024-08-29",
      datekey: 20240829,
      value: 3300.889999999999,
      "Total Amount": 3300.889999999999,
      Customers: 101,
      Transactions: 130,
      Returns: 0,
      Discounts: 38.06,
    },
    {
      day: "2024-08-28",
      datekey: 20240828,
      value: 3342.879999999998,
      "Total Amount": 3342.879999999998,
      Customers: 68,
      Transactions: 101,
      Returns: 16.6,
      Discounts: 245.86,
    },
    {
      day: "2024-08-27",
      datekey: 20240827,
      value: 2485.69,
      "Total Amount": 2485.69,
      Customers: 80,
      Transactions: 100,
      Returns: 1.54,
      Discounts: 9.2,
    },
    {
      day: "2024-08-26",
      datekey: 20240826,
      value: 2718.699999999999,
      "Total Amount": 2718.699999999999,
      Customers: 83,
      Transactions: 103,
      Returns: 1.46,
      Discounts: 41.63,
    },
    {
      day: "2024-08-25",
      datekey: 20240825,
      value: 4175.489999999999,
      "Total Amount": 4175.489999999999,
      Customers: 116,
      Transactions: 159,
      Returns: 19,
      Discounts: 32.92,
    },
    {
      day: "2024-08-24",
      datekey: 20240824,
      value: 3617.329999999998,
      "Total Amount": 3617.329999999998,
      Customers: 102,
      Transactions: 145,
      Returns: 5,
      Discounts: 12.11,
    },
    {
      day: "2024-08-23",
      datekey: 20240823,
      value: 4092.72,
      "Total Amount": 4092.72,
      Customers: 106,
      Transactions: 153,
      Returns: 20.3,
      Discounts: 31.02,
    },
    {
      day: "2024-08-22",
      datekey: 20240822,
      value: 3804.289999999997,
      "Total Amount": 3804.289999999997,
      Customers: 97,
      Transactions: 132,
      Returns: 2.4,
      Discounts: 82.61999999999999,
    },
    {
      day: "2024-08-21",
      datekey: 20240821,
      value: 3953.260000000001,
      "Total Amount": 3953.260000000001,
      Customers: 92,
      Transactions: 130,
      Returns: 33.1,
      Discounts: 230.44,
    },
    {
      day: "2024-08-20",
      datekey: 20240820,
      value: 2664.939999999998,
      "Total Amount": 2664.939999999998,
      Customers: 78,
      Transactions: 93,
      Returns: 2.75,
      Discounts: 19.95,
    },
    {
      day: "2024-08-19",
      datekey: 20240819,
      value: 2375.58,
      "Total Amount": 2375.58,
      Customers: 76,
      Transactions: 108,
      Returns: 0,
      Discounts: 16.89,
    },
    {
      day: "2024-08-18",
      datekey: 20240818,
      value: 4795.909999999997,
      "Total Amount": 4795.909999999997,
      Customers: 133,
      Transactions: 174,
      Returns: 11,
      Discounts: 39.79,
    },
    {
      day: "2024-08-17",
      datekey: 20240817,
      value: 3562.599999999999,
      "Total Amount": 3562.599999999999,
      Customers: 106,
      Transactions: 135,
      Returns: 0,
      Discounts: 61.87000000000001,
    },
    {
      day: "2024-08-16",
      datekey: 20240816,
      value: 3173.439999999998,
      "Total Amount": 3173.439999999998,
      Customers: 85,
      Transactions: 131,
      Returns: 0,
      Discounts: 25.91,
    },
    {
      day: "2024-08-15",
      datekey: 20240815,
      value: 3807.559999999998,
      "Total Amount": 3807.559999999998,
      Customers: 84,
      Transactions: 145,
      Returns: 0,
      Discounts: 47.25000000000001,
    },
    {
      day: "2024-08-14",
      datekey: 20240814,
      value: 5222.109999999996,
      "Total Amount": 5222.109999999996,
      Customers: 98,
      Transactions: 136,
      Returns: 21.09,
      Discounts: 319.8100000000001,
    },
    {
      day: "2024-08-13",
      datekey: 20240813,
      value: 3443.919999999999,
      "Total Amount": 3443.919999999999,
      Customers: 91,
      Transactions: 115,
      Returns: 10.93,
      Discounts: 2.58,
    },
    {
      day: "2024-08-12",
      datekey: 20240812,
      value: 3417.719999999999,
      "Total Amount": 3417.719999999999,
      Customers: 81,
      Transactions: 118,
      Returns: 5.87,
      Discounts: 37.49,
    },
    {
      day: "2024-08-11",
      datekey: 20240811,
      value: 3534.910000000001,
      "Total Amount": 3534.910000000001,
      Customers: 115,
      Transactions: 161,
      Returns: 8.16,
      Discounts: 61.66,
    },
    {
      day: "2024-08-10",
      datekey: 20240810,
      value: 5123.099999999998,
      "Total Amount": 5123.099999999998,
      Customers: 130,
      Transactions: 167,
      Returns: 6.55,
      Discounts: 35.4,
    },
    {
      day: "2024-08-09",
      datekey: 20240809,
      value: 3449.759999999999,
      "Total Amount": 3449.759999999999,
      Customers: 97,
      Transactions: 136,
      Returns: 1.39,
      Discounts: 60.53000000000001,
    },
    {
      day: "2024-08-08",
      datekey: 20240808,
      value: 3047.639999999999,
      "Total Amount": 3047.639999999999,
      Customers: 84,
      Transactions: 120,
      Returns: 5,
      Discounts: 15.87,
    },
    {
      day: "2024-08-07",
      datekey: 20240807,
      value: 3768.570000000001,
      "Total Amount": 3768.570000000001,
      Customers: 100,
      Transactions: 127,
      Returns: 7.05,
      Discounts: 225.8700000000001,
    },
    {
      day: "2024-08-06",
      datekey: 20240806,
      value: 2325.960000000001,
      "Total Amount": 2325.960000000001,
      Customers: 81,
      Transactions: 98,
      Returns: 1.8,
      Discounts: 6.59,
    },
    {
      day: "2024-08-05",
      datekey: 20240805,
      value: 2973.239999999999,
      "Total Amount": 2973.239999999999,
      Customers: 77,
      Transactions: 113,
      Returns: 4.87,
      Discounts: 74.26,
    },
    {
      day: "2024-08-04",
      datekey: 20240804,
      value: 3773.119999999999,
      "Total Amount": 3773.119999999999,
      Customers: 127,
      Transactions: 172,
      Returns: 0,
      Discounts: 18.65,
    },
    {
      day: "2024-08-03",
      datekey: 20240803,
      value: 4900.359999999992,
      "Total Amount": 4900.359999999992,
      Customers: 131,
      Transactions: 164,
      Returns: 23.5,
      Discounts: 32.79,
    },
    {
      day: "2024-08-02",
      datekey: 20240802,
      value: 3321.989999999998,
      "Total Amount": 3321.989999999998,
      Customers: 94,
      Transactions: 119,
      Returns: 11,
      Discounts: 47.41,
    },
    {
      day: "2024-08-01",
      datekey: 20240801,
      value: 3283.68,
      "Total Amount": 3283.68,
      Customers: 89,
      Transactions: 122,
      Returns: 44,
      Discounts: 72.45,
    },
    {
      day: "2024-07-31",
      datekey: 20240731,
      value: 3568.349999999999,
      "Total Amount": 3568.349999999999,
      Customers: 71,
      Transactions: 109,
      Returns: 3.6,
      Discounts: 200.43,
    },
    {
      day: "2024-07-30",
      datekey: 20240730,
      value: 2398.79,
      "Total Amount": 2398.79,
      Customers: 72,
      Transactions: 107,
      Returns: 44.08000000000001,
      Discounts: 3.53,
    },
    {
      day: "2024-07-29",
      datekey: 20240729,
      value: 3166.1,
      "Total Amount": 3166.1,
      Customers: 74,
      Transactions: 106,
      Returns: 0,
      Discounts: 30.95,
    },
    {
      day: "2024-07-28",
      datekey: 20240728,
      value: 4457.979999999998,
      "Total Amount": 4457.979999999998,
      Customers: 96,
      Transactions: 136,
      Returns: 5.45,
      Discounts: 163.11,
    },
    {
      day: "2024-07-27",
      datekey: 20240727,
      value: 4482.679999999994,
      "Total Amount": 4482.679999999994,
      Customers: 101,
      Transactions: 152,
      Returns: 12.15,
      Discounts: 22.64,
    },
    {
      day: "2024-07-26",
      datekey: 20240726,
      value: 3815.25,
      "Total Amount": 3815.25,
      Customers: 108,
      Transactions: 143,
      Returns: 0,
      Discounts: 19.51,
    },
    {
      day: "2024-07-25",
      datekey: 20240725,
      value: 2455.66,
      "Total Amount": 2455.66,
      Customers: 72,
      Transactions: 99,
      Returns: 0,
      Discounts: 43.11000000000001,
    },
    {
      day: "2024-07-24",
      datekey: 20240724,
      value: 3643.129999999997,
      "Total Amount": 3643.129999999997,
      Customers: 93,
      Transactions: 135,
      Returns: 8.690000000000001,
      Discounts: 138.9,
    },
    {
      day: "2024-07-23",
      datekey: 20240723,
      value: 2854.629999999998,
      "Total Amount": 2854.629999999998,
      Customers: 78,
      Transactions: 120,
      Returns: 0,
      Discounts: 0.8,
    },
    {
      day: "2024-07-22",
      datekey: 20240722,
      value: 2797.87,
      "Total Amount": 2797.87,
      Customers: 83,
      Transactions: 112,
      Returns: 9.6,
      Discounts: 8.799999999999999,
    },
    {
      day: "2024-07-21",
      datekey: 20240721,
      value: 3763.959999999997,
      "Total Amount": 3763.959999999997,
      Customers: 107,
      Transactions: 161,
      Returns: 16.02,
      Discounts: 55.47999999999999,
    },
    {
      day: "2024-07-20",
      datekey: 20240720,
      value: 3977.17,
      "Total Amount": 3977.17,
      Customers: 100,
      Transactions: 137,
      Returns: 1.8,
      Discounts: 39.85,
    },
    {
      day: "2024-07-19",
      datekey: 20240719,
      value: 3922.969999999997,
      "Total Amount": 3922.969999999997,
      Customers: 100,
      Transactions: 144,
      Returns: 0.63,
      Discounts: 95.61999999999999,
    },
    {
      day: "2024-07-18",
      datekey: 20240718,
      value: 3796.93,
      "Total Amount": 3796.93,
      Customers: 102,
      Transactions: 130,
      Returns: 2.2,
      Discounts: 34.57999999999999,
    },
    {
      day: "2024-07-17",
      datekey: 20240717,
      value: 3920.589999999995,
      "Total Amount": 3920.589999999995,
      Customers: 84,
      Transactions: 111,
      Returns: 0,
      Discounts: 317.4400000000001,
    },
    {
      day: "2024-07-16",
      datekey: 20240716,
      value: 3471.69,
      "Total Amount": 3471.69,
      Customers: 91,
      Transactions: 118,
      Returns: 0,
      Discounts: 2.95,
    },
    {
      day: "2024-07-15",
      datekey: 20240715,
      value: 2718.319999999999,
      "Total Amount": 2718.319999999999,
      Customers: 83,
      Transactions: 111,
      Returns: 0,
      Discounts: 18.46,
    },
    {
      day: "2024-07-14",
      datekey: 20240714,
      value: 3412.239999999999,
      "Total Amount": 3412.239999999999,
      Customers: 108,
      Transactions: 157,
      Returns: 0,
      Discounts: 27.16,
    },
    {
      day: "2024-07-13",
      datekey: 20240713,
      value: 4077.01,
      "Total Amount": 4077.01,
      Customers: 109,
      Transactions: 144,
      Returns: 4,
      Discounts: 45.03,
    },
    {
      day: "2024-07-12",
      datekey: 20240712,
      value: 3275.419999999998,
      "Total Amount": 3275.419999999998,
      Customers: 89,
      Transactions: 135,
      Returns: 47.49,
      Discounts: 37.9,
    },
    {
      day: "2024-07-11",
      datekey: 20240711,
      value: 4238.910000000001,
      "Total Amount": 4238.910000000001,
      Customers: 71,
      Transactions: 111,
      Returns: 14.86,
      Discounts: 379,
    },
    {
      day: "2024-07-10",
      datekey: 20240710,
      value: 333.37,
      "Total Amount": 333.37,
      Customers: 19,
      Transactions: 24,
      Returns: 0,
      Discounts: 10.76,
    },
    {
      day: "2024-07-09",
      datekey: 20240709,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-07-08",
      datekey: 20240708,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-07-07",
      datekey: 20240707,
      value: 4715.62,
      "Total Amount": 4715.62,
      Customers: 149,
      Transactions: 180,
      Returns: 5.029999999999999,
      Discounts: 55.6,
    },
    {
      day: "2024-07-06",
      datekey: 20240706,
      value: 3900.370000000002,
      "Total Amount": 3900.370000000002,
      Customers: 112,
      Transactions: 148,
      Returns: 3.06,
      Discounts: 25.02,
    },
    {
      day: "2024-07-05",
      datekey: 20240705,
      value: 3676.929999999997,
      "Total Amount": 3676.929999999997,
      Customers: 94,
      Transactions: 138,
      Returns: 7.4,
      Discounts: 55.33,
    },
    {
      day: "2024-07-04",
      datekey: 20240704,
      value: 4188.269999999999,
      "Total Amount": 4188.269999999999,
      Customers: 99,
      Transactions: 135,
      Returns: 1.43,
      Discounts: 115.22,
    },
    {
      day: "2024-07-03",
      datekey: 20240703,
      value: 3740.119999999997,
      "Total Amount": 3740.119999999997,
      Customers: 111,
      Transactions: 140,
      Returns: 3,
      Discounts: 3.87,
    },
    {
      day: "2024-07-02",
      datekey: 20240702,
      value: 2405.34,
      "Total Amount": 2405.34,
      Customers: 77,
      Transactions: 90,
      Returns: 2.6,
      Discounts: 3.9,
    },
    {
      day: "2024-07-01",
      datekey: 20240701,
      value: 2833.99,
      "Total Amount": 2833.99,
      Customers: 74,
      Transactions: 91,
      Returns: 6.86,
      Discounts: 35.88000000000001,
    },
    {
      day: "2024-06-30",
      datekey: 20240630,
      value: 4471.149999999999,
      "Total Amount": 4471.149999999999,
      Customers: 111,
      Transactions: 146,
      Returns: 19.4,
      Discounts: 41.02,
    },
    {
      day: "2024-06-29",
      datekey: 20240629,
      value: 3288.759999999998,
      "Total Amount": 3288.759999999998,
      Customers: 93,
      Transactions: 133,
      Returns: 0,
      Discounts: 46.34,
    },
    {
      day: "2024-06-28",
      datekey: 20240628,
      value: 3614.679999999999,
      "Total Amount": 3614.679999999999,
      Customers: 97,
      Transactions: 141,
      Returns: 4.5,
      Discounts: 39.98,
    },
    {
      day: "2024-06-27",
      datekey: 20240627,
      value: 3076.469999999999,
      "Total Amount": 3076.469999999999,
      Customers: 78,
      Transactions: 112,
      Returns: 0,
      Discounts: 87.78999999999999,
    },
    {
      day: "2024-06-26",
      datekey: 20240626,
      value: 5085.529999999999,
      "Total Amount": 5085.529999999999,
      Customers: 81,
      Transactions: 113,
      Returns: 12.85,
      Discounts: 490.74,
    },
    {
      day: "2024-06-25",
      datekey: 20240625,
      value: 2762.88,
      "Total Amount": 2762.88,
      Customers: 76,
      Transactions: 102,
      Returns: 4.1,
      Discounts: 3.05,
    },
    {
      day: "2024-06-24",
      datekey: 20240624,
      value: 2578.34,
      "Total Amount": 2578.34,
      Customers: 91,
      Transactions: 120,
      Returns: 0,
      Discounts: 42.67,
    },
    {
      day: "2024-06-23",
      datekey: 20240623,
      value: 2384.089999999999,
      "Total Amount": 2384.089999999999,
      Customers: 97,
      Transactions: 125,
      Returns: 9.85,
      Discounts: 23.17,
    },
    {
      day: "2024-06-22",
      datekey: 20240622,
      value: 3726.199999999999,
      "Total Amount": 3726.199999999999,
      Customers: 113,
      Transactions: 170,
      Returns: 6.99,
      Discounts: 16.58,
    },
    {
      day: "2024-06-21",
      datekey: 20240621,
      value: 3459.449999999999,
      "Total Amount": 3459.449999999999,
      Customers: 103,
      Transactions: 142,
      Returns: 0,
      Discounts: 26.09,
    },
    {
      day: "2024-06-20",
      datekey: 20240620,
      value: 3395.549999999998,
      "Total Amount": 3395.549999999998,
      Customers: 82,
      Transactions: 117,
      Returns: 1.63,
      Discounts: 155.17,
    },
    {
      day: "2024-06-19",
      datekey: 20240619,
      value: 3163.489999999999,
      "Total Amount": 3163.489999999999,
      Customers: 84,
      Transactions: 121,
      Returns: 12.89,
      Discounts: 87.27000000000001,
    },
    {
      day: "2024-06-18",
      datekey: 20240618,
      value: 2859.27,
      "Total Amount": 2859.27,
      Customers: 76,
      Transactions: 114,
      Returns: 4.45,
      Discounts: 1.24,
    },
    {
      day: "2024-06-17",
      datekey: 20240617,
      value: 1939.88,
      "Total Amount": 1939.88,
      Customers: 69,
      Transactions: 87,
      Returns: 3,
      Discounts: 8.510000000000002,
    },
    {
      day: "2024-06-16",
      datekey: 20240616,
      value: 4183.819999999999,
      "Total Amount": 4183.819999999999,
      Customers: 111,
      Transactions: 164,
      Returns: 12.99,
      Discounts: 90.05999999999999,
    },
    {
      day: "2024-06-15",
      datekey: 20240615,
      value: 6123.199999999995,
      "Total Amount": 6123.199999999995,
      Customers: 155,
      Transactions: 203,
      Returns: 2,
      Discounts: 66.60000000000001,
    },
    {
      day: "2024-06-14",
      datekey: 20240614,
      value: 4139.159999999995,
      "Total Amount": 4139.159999999995,
      Customers: 106,
      Transactions: 143,
      Returns: 0,
      Discounts: 67.29,
    },
    {
      day: "2024-06-13",
      datekey: 20240613,
      value: 4126.459999999998,
      "Total Amount": 4126.459999999998,
      Customers: 108,
      Transactions: 143,
      Returns: 13.46,
      Discounts: 49.02,
    },
    {
      day: "2024-06-12",
      datekey: 20240612,
      value: 5863.429999999992,
      "Total Amount": 5863.429999999992,
      Customers: 101,
      Transactions: 142,
      Returns: 0,
      Discounts: 275.4400000000001,
    },
    {
      day: "2024-06-11",
      datekey: 20240611,
      value: 3202.95,
      "Total Amount": 3202.95,
      Customers: 82,
      Transactions: 98,
      Returns: 0,
      Discounts: 12.49,
    },
    {
      day: "2024-06-10",
      datekey: 20240610,
      value: 2533.44,
      "Total Amount": 2533.44,
      Customers: 80,
      Transactions: 107,
      Returns: 0,
      Discounts: 33.83,
    },
    {
      day: "2024-06-09",
      datekey: 20240609,
      value: 3504.659999999999,
      "Total Amount": 3504.659999999999,
      Customers: 111,
      Transactions: 152,
      Returns: 23.04,
      Discounts: 40.57000000000001,
    },
    {
      day: "2024-06-08",
      datekey: 20240608,
      value: 3930.319999999998,
      "Total Amount": 3930.319999999998,
      Customers: 121,
      Transactions: 151,
      Returns: 0,
      Discounts: 43.1,
    },
    {
      day: "2024-06-07",
      datekey: 20240607,
      value: 3746.44,
      "Total Amount": 3746.44,
      Customers: 109,
      Transactions: 153,
      Returns: 4,
      Discounts: 20.02,
    },
    {
      day: "2024-06-06",
      datekey: 20240606,
      value: 4148.34,
      "Total Amount": 4148.34,
      Customers: 82,
      Transactions: 120,
      Returns: 3.9,
      Discounts: 64.81,
    },
    {
      day: "2024-06-05",
      datekey: 20240605,
      value: 3782.119999999999,
      "Total Amount": 3782.119999999999,
      Customers: 71,
      Transactions: 93,
      Returns: 9.73,
      Discounts: 203.52,
    },
    {
      day: "2024-06-04",
      datekey: 20240604,
      value: 2563.619999999998,
      "Total Amount": 2563.619999999998,
      Customers: 84,
      Transactions: 105,
      Returns: 23.08,
      Discounts: 17.82,
    },
    {
      day: "2024-06-03",
      datekey: 20240603,
      value: 2718.059999999999,
      "Total Amount": 2718.059999999999,
      Customers: 81,
      Transactions: 112,
      Returns: 5.779999999999999,
      Discounts: 11.46,
    },
    {
      day: "2024-06-02",
      datekey: 20240602,
      value: 4940.879999999993,
      "Total Amount": 4940.879999999993,
      Customers: 123,
      Transactions: 161,
      Returns: 0,
      Discounts: 95.1,
    },
    {
      day: "2024-06-01",
      datekey: 20240601,
      value: 4630.229999999997,
      "Total Amount": 4630.229999999997,
      Customers: 119,
      Transactions: 161,
      Returns: 0,
      Discounts: 130.15,
    },
    {
      day: "2024-05-31",
      datekey: 20240531,
      value: 3893.279999999999,
      "Total Amount": 3893.279999999999,
      Customers: 98,
      Transactions: 136,
      Returns: 9,
      Discounts: 40.72000000000001,
    },
    {
      day: "2024-05-30",
      datekey: 20240530,
      value: 2873.12,
      "Total Amount": 2873.12,
      Customers: 79,
      Transactions: 107,
      Returns: 11.67,
      Discounts: 105.61,
    },
    {
      day: "2024-05-29",
      datekey: 20240529,
      value: 3704.989999999998,
      "Total Amount": 3704.989999999998,
      Customers: 93,
      Transactions: 125,
      Returns: 0,
      Discounts: 198.6,
    },
    {
      day: "2024-05-28",
      datekey: 20240528,
      value: 3169.399999999999,
      "Total Amount": 3169.399999999999,
      Customers: 88,
      Transactions: 109,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2024-05-27",
      datekey: 20240527,
      value: 3101.069999999999,
      "Total Amount": 3101.069999999999,
      Customers: 90,
      Transactions: 123,
      Returns: 17.53,
      Discounts: 20.86,
    },
    {
      day: "2024-05-26",
      datekey: 20240526,
      value: 4500.119999999998,
      "Total Amount": 4500.119999999998,
      Customers: 125,
      Transactions: 154,
      Returns: 54,
      Discounts: 30.38,
    },
    {
      day: "2024-05-25",
      datekey: 20240525,
      value: 3675.259999999999,
      "Total Amount": 3675.259999999999,
      Customers: 92,
      Transactions: 139,
      Returns: 0,
      Discounts: 72.47,
    },
    {
      day: "2024-05-24",
      datekey: 20240524,
      value: 4182.92,
      "Total Amount": 4182.92,
      Customers: 120,
      Transactions: 153,
      Returns: 7.52,
      Discounts: 36.14,
    },
    {
      day: "2024-05-23",
      datekey: 20240523,
      value: 2718.51,
      "Total Amount": 2718.51,
      Customers: 88,
      Transactions: 115,
      Returns: 5.4,
      Discounts: 64.66,
    },
    {
      day: "2024-05-22",
      datekey: 20240522,
      value: 3891.829999999999,
      "Total Amount": 3891.829999999999,
      Customers: 84,
      Transactions: 114,
      Returns: 6.7,
      Discounts: 273.68,
    },
    {
      day: "2024-05-21",
      datekey: 20240521,
      value: 2204.9,
      "Total Amount": 2204.9,
      Customers: 76,
      Transactions: 101,
      Returns: 6.699999999999999,
      Discounts: 6.220000000000001,
    },
    {
      day: "2024-05-20",
      datekey: 20240520,
      value: 3582.109999999999,
      "Total Amount": 3582.109999999999,
      Customers: 91,
      Transactions: 118,
      Returns: 2,
      Discounts: 98.15000000000002,
    },
    {
      day: "2024-05-19",
      datekey: 20240519,
      value: 5055.739999999996,
      "Total Amount": 5055.739999999996,
      Customers: 137,
      Transactions: 180,
      Returns: 0,
      Discounts: 99.39,
    },
    {
      day: "2024-05-18",
      datekey: 20240518,
      value: 5423.909999999995,
      "Total Amount": 5423.909999999995,
      Customers: 133,
      Transactions: 188,
      Returns: 24.4,
      Discounts: 39.17,
    },
    {
      day: "2024-05-17",
      datekey: 20240517,
      value: 4520.669999999998,
      "Total Amount": 4520.669999999998,
      Customers: 128,
      Transactions: 181,
      Returns: 10.5,
      Discounts: 57.26000000000001,
    },
    {
      day: "2024-05-16",
      datekey: 20240516,
      value: 2867.290000000001,
      "Total Amount": 2867.290000000001,
      Customers: 65,
      Transactions: 97,
      Returns: 0,
      Discounts: 72.74000000000001,
    },
    {
      day: "2024-05-15",
      datekey: 20240515,
      value: 4566.209999999998,
      "Total Amount": 4566.209999999998,
      Customers: 97,
      Transactions: 142,
      Returns: 1.33,
      Discounts: 229.82,
    },
    {
      day: "2024-05-14",
      datekey: 20240514,
      value: 2973.149999999999,
      "Total Amount": 2973.149999999999,
      Customers: 88,
      Transactions: 115,
      Returns: 0,
      Discounts: 12.47,
    },
    {
      day: "2024-05-13",
      datekey: 20240513,
      value: 3052.879999999999,
      "Total Amount": 3052.879999999999,
      Customers: 81,
      Transactions: 103,
      Returns: 2.85,
      Discounts: 37.33,
    },
    {
      day: "2024-05-12",
      datekey: 20240512,
      value: 4172.989999999997,
      "Total Amount": 4172.989999999997,
      Customers: 117,
      Transactions: 170,
      Returns: 22.18,
      Discounts: 24.5,
    },
    {
      day: "2024-05-11",
      datekey: 20240511,
      value: 4101.36,
      "Total Amount": 4101.36,
      Customers: 114,
      Transactions: 164,
      Returns: 7.2,
      Discounts: 45.7,
    },
    {
      day: "2024-05-10",
      datekey: 20240510,
      value: 3893.919999999998,
      "Total Amount": 3893.919999999998,
      Customers: 111,
      Transactions: 146,
      Returns: 7.6,
      Discounts: 84.39999999999999,
    },
    {
      day: "2024-05-09",
      datekey: 20240509,
      value: 4633.099999999998,
      "Total Amount": 4633.099999999998,
      Customers: 102,
      Transactions: 144,
      Returns: 0,
      Discounts: 147.33,
    },
    {
      day: "2024-05-08",
      datekey: 20240508,
      value: 4367.39,
      "Total Amount": 4367.39,
      Customers: 87,
      Transactions: 127,
      Returns: 4.24,
      Discounts: 298.08,
    },
    {
      day: "2024-05-07",
      datekey: 20240507,
      value: 2832.489999999999,
      "Total Amount": 2832.489999999999,
      Customers: 84,
      Transactions: 105,
      Returns: 0,
      Discounts: 21.96,
    },
    {
      day: "2024-05-06",
      datekey: 20240506,
      value: 2873.479999999998,
      "Total Amount": 2873.479999999998,
      Customers: 94,
      Transactions: 118,
      Returns: 8.8,
      Discounts: 13.33,
    },
    {
      day: "2024-05-05",
      datekey: 20240505,
      value: 4805.859999999997,
      "Total Amount": 4805.859999999997,
      Customers: 123,
      Transactions: 176,
      Returns: 7.8,
      Discounts: 59.54,
    },
    {
      day: "2024-05-04",
      datekey: 20240504,
      value: 3603,
      "Total Amount": 3603,
      Customers: 110,
      Transactions: 143,
      Returns: 0,
      Discounts: 13.66,
    },
    {
      day: "2024-05-03",
      datekey: 20240503,
      value: 3987.109999999998,
      "Total Amount": 3987.109999999998,
      Customers: 122,
      Transactions: 164,
      Returns: 0,
      Discounts: 63.90000000000001,
    },
    {
      day: "2024-05-02",
      datekey: 20240502,
      value: 3459.9,
      "Total Amount": 3459.9,
      Customers: 82,
      Transactions: 119,
      Returns: 0,
      Discounts: 93.38999999999999,
    },
    {
      day: "2024-05-01",
      datekey: 20240501,
      value: 4608.819999999999,
      "Total Amount": 4608.819999999999,
      Customers: 111,
      Transactions: 138,
      Returns: 7,
      Discounts: 203.46,
    },
    {
      day: "2024-04-30",
      datekey: 20240430,
      value: 2859.559999999999,
      "Total Amount": 2859.559999999999,
      Customers: 54,
      Transactions: 111,
      Returns: 1.6,
      Discounts: 123.96,
    },
    {
      day: "2024-04-29",
      datekey: 20240429,
      value: 3009.319999999998,
      "Total Amount": 3009.319999999998,
      Customers: 58,
      Transactions: 121,
      Returns: 17.29,
      Discounts: 143.92,
    },
    {
      day: "2024-04-28",
      datekey: 20240428,
      value: 5174.659999999996,
      "Total Amount": 5174.659999999996,
      Customers: 86,
      Transactions: 184,
      Returns: 18.79,
      Discounts: 278.1800000000001,
    },
    {
      day: "2024-04-27",
      datekey: 20240427,
      value: 4514.339999999995,
      "Total Amount": 4514.339999999995,
      Customers: 73,
      Transactions: 176,
      Returns: 0,
      Discounts: 209.71,
    },
    {
      day: "2024-04-26",
      datekey: 20240426,
      value: 3493.759999999998,
      "Total Amount": 3493.759999999998,
      Customers: 54,
      Transactions: 134,
      Returns: 0,
      Discounts: 181.4100000000001,
    },
    {
      day: "2024-04-25",
      datekey: 20240425,
      value: 3310.039999999999,
      "Total Amount": 3310.039999999999,
      Customers: 49,
      Transactions: 133,
      Returns: 17.55,
      Discounts: 141.6300000000001,
    },
    {
      day: "2024-04-24",
      datekey: 20240424,
      value: 4671.629999999998,
      "Total Amount": 4671.629999999998,
      Customers: 59,
      Transactions: 127,
      Returns: 16.95,
      Discounts: 398.95,
    },
    {
      day: "2024-04-23",
      datekey: 20240423,
      value: 2046.779999999999,
      "Total Amount": 2046.779999999999,
      Customers: 48,
      Transactions: 113,
      Returns: 5.27,
      Discounts: 75.85000000000002,
    },
    {
      day: "2024-04-22",
      datekey: 20240422,
      value: 2604.98,
      "Total Amount": 2604.98,
      Customers: 62,
      Transactions: 110,
      Returns: 1,
      Discounts: 128.49,
    },
    {
      day: "2024-04-21",
      datekey: 20240421,
      value: 4135.629999999998,
      "Total Amount": 4135.629999999998,
      Customers: 82,
      Transactions: 183,
      Returns: 0,
      Discounts: 199.39,
    },
    {
      day: "2024-04-20",
      datekey: 20240420,
      value: 4852.299999999996,
      "Total Amount": 4852.299999999996,
      Customers: 83,
      Transactions: 169,
      Returns: 18,
      Discounts: 235.81,
    },
    {
      day: "2024-04-19",
      datekey: 20240419,
      value: 4511.399999999995,
      "Total Amount": 4511.399999999995,
      Customers: 80,
      Transactions: 161,
      Returns: 4.17,
      Discounts: 237.0900000000001,
    },
    {
      day: "2024-04-18",
      datekey: 20240418,
      value: 4022.079999999999,
      "Total Amount": 4022.079999999999,
      Customers: 64,
      Transactions: 137,
      Returns: 1.5,
      Discounts: 295.88,
    },
    {
      day: "2024-04-17",
      datekey: 20240417,
      value: 3238.719999999998,
      "Total Amount": 3238.719999999998,
      Customers: 55,
      Transactions: 123,
      Returns: 26.66,
      Discounts: 174.99,
    },
    {
      day: "2024-04-16",
      datekey: 20240416,
      value: 2763.110000000001,
      "Total Amount": 2763.110000000001,
      Customers: 56,
      Transactions: 106,
      Returns: 4.81,
      Discounts: 131.54,
    },
    {
      day: "2024-04-15",
      datekey: 20240415,
      value: 2915.61,
      "Total Amount": 2915.61,
      Customers: 55,
      Transactions: 111,
      Returns: 4.75,
      Discounts: 137.29,
    },
    {
      day: "2024-04-14",
      datekey: 20240414,
      value: 4130.220000000001,
      "Total Amount": 4130.220000000001,
      Customers: 67,
      Transactions: 156,
      Returns: 0,
      Discounts: 204.3599999999999,
    },
    {
      day: "2024-04-13",
      datekey: 20240413,
      value: 4478.029999999999,
      "Total Amount": 4478.029999999999,
      Customers: 75,
      Transactions: 174,
      Returns: 0,
      Discounts: 237.62,
    },
    {
      day: "2024-04-12",
      datekey: 20240412,
      value: 4523.709999999994,
      "Total Amount": 4523.709999999994,
      Customers: 69,
      Transactions: 152,
      Returns: 0,
      Discounts: 265.31,
    },
    {
      day: "2024-04-11",
      datekey: 20240411,
      value: 4544.530000000002,
      "Total Amount": 4544.530000000002,
      Customers: 52,
      Transactions: 131,
      Returns: 0,
      Discounts: 438.36,
    },
    {
      day: "2024-04-10",
      datekey: 20240410,
      value: 2371.190000000001,
      "Total Amount": 2371.190000000001,
      Customers: 52,
      Transactions: 105,
      Returns: 7.42,
      Discounts: 87.02,
    },
    {
      day: "2024-04-09",
      datekey: 20240409,
      value: 3982.399999999997,
      "Total Amount": 3982.399999999997,
      Customers: 80,
      Transactions: 171,
      Returns: 27.51,
      Discounts: 169.42,
    },
    {
      day: "2024-04-08",
      datekey: 20240408,
      value: 4032.839999999998,
      "Total Amount": 4032.839999999998,
      Customers: 92,
      Transactions: 167,
      Returns: 12,
      Discounts: 224.03,
    },
    {
      day: "2024-04-07",
      datekey: 20240407,
      value: 5800.629999999991,
      "Total Amount": 5800.629999999991,
      Customers: 90,
      Transactions: 221,
      Returns: 4.33,
      Discounts: 352.48,
    },
    {
      day: "2024-04-06",
      datekey: 20240406,
      value: 4973.269999999998,
      "Total Amount": 4973.269999999998,
      Customers: 83,
      Transactions: 181,
      Returns: 2,
      Discounts: 277.1100000000001,
    },
    {
      day: "2024-04-05",
      datekey: 20240405,
      value: 3669.44,
      "Total Amount": 3669.44,
      Customers: 67,
      Transactions: 151,
      Returns: 6.5,
      Discounts: 155.28,
    },
    {
      day: "2024-04-04",
      datekey: 20240404,
      value: 5314.970000000004,
      "Total Amount": 5314.970000000004,
      Customers: 62,
      Transactions: 139,
      Returns: 18.03,
      Discounts: 511.4200000000001,
    },
    {
      day: "2024-04-03",
      datekey: 20240403,
      value: 3818.329999999999,
      "Total Amount": 3818.329999999999,
      Customers: 61,
      Transactions: 135,
      Returns: 0,
      Discounts: 260.44,
    },
    {
      day: "2024-04-02",
      datekey: 20240402,
      value: 2688.099999999998,
      "Total Amount": 2688.099999999998,
      Customers: 57,
      Transactions: 111,
      Returns: 15.41,
      Discounts: 120.26,
    },
    {
      day: "2024-04-01",
      datekey: 20240401,
      value: 3027.369999999999,
      "Total Amount": 3027.369999999999,
      Customers: 90,
      Transactions: 140,
      Returns: 0,
      Discounts: 140.91,
    },
    {
      day: "2024-03-31",
      datekey: 20240331,
      value: 5314.189999999996,
      "Total Amount": 5314.189999999996,
      Customers: 92,
      Transactions: 213,
      Returns: 21,
      Discounts: 314.32,
    },
    {
      day: "2024-03-30",
      datekey: 20240330,
      value: 4510.209999999997,
      "Total Amount": 4510.209999999997,
      Customers: 76,
      Transactions: 188,
      Returns: 7.91,
      Discounts: 245.29,
    },
    {
      day: "2024-03-29",
      datekey: 20240329,
      value: 4334.749999999999,
      "Total Amount": 4334.749999999999,
      Customers: 85,
      Transactions: 176,
      Returns: 9.57,
      Discounts: 213.08,
    },
    {
      day: "2024-03-28",
      datekey: 20240328,
      value: 3950.599999999999,
      "Total Amount": 3950.599999999999,
      Customers: 68,
      Transactions: 149,
      Returns: 9.9,
      Discounts: 277.7300000000001,
    },
    {
      day: "2024-03-27",
      datekey: 20240327,
      value: 4505.48,
      "Total Amount": 4505.48,
      Customers: 62,
      Transactions: 142,
      Returns: 15.55,
      Discounts: 400.8599999999998,
    },
    {
      day: "2024-03-26",
      datekey: 20240326,
      value: 2730.57,
      "Total Amount": 2730.57,
      Customers: 64,
      Transactions: 120,
      Returns: 7.5,
      Discounts: 102.39,
    },
    {
      day: "2024-03-25",
      datekey: 20240325,
      value: 3051.54,
      "Total Amount": 3051.54,
      Customers: 93,
      Transactions: 140,
      Returns: 0.83,
      Discounts: 151.61,
    },
    {
      day: "2024-03-24",
      datekey: 20240324,
      value: 4492.229999999998,
      "Total Amount": 4492.229999999998,
      Customers: 79,
      Transactions: 170,
      Returns: 5.2,
      Discounts: 227.9,
    },
    {
      day: "2024-03-23",
      datekey: 20240323,
      value: 4975.599999999995,
      "Total Amount": 4975.599999999995,
      Customers: 91,
      Transactions: 189,
      Returns: 3.5,
      Discounts: 244.74,
    },
    {
      day: "2024-03-22",
      datekey: 20240322,
      value: 3880.069999999998,
      "Total Amount": 3880.069999999998,
      Customers: 77,
      Transactions: 140,
      Returns: 0,
      Discounts: 247.12,
    },
    {
      day: "2024-03-21",
      datekey: 20240321,
      value: 2965.769999999998,
      "Total Amount": 2965.769999999998,
      Customers: 65,
      Transactions: 133,
      Returns: 0,
      Discounts: 186.54,
    },
    {
      day: "2024-03-20",
      datekey: 20240320,
      value: 4924.410000000001,
      "Total Amount": 4924.410000000001,
      Customers: 49,
      Transactions: 128,
      Returns: 9.05,
      Discounts: 473.79,
    },
    {
      day: "2024-03-19",
      datekey: 20240319,
      value: 2963.569999999998,
      "Total Amount": 2963.569999999998,
      Customers: 53,
      Transactions: 105,
      Returns: 6.89,
      Discounts: 122.72,
    },
    {
      day: "2024-03-18",
      datekey: 20240318,
      value: 3350.679999999999,
      "Total Amount": 3350.679999999999,
      Customers: 95,
      Transactions: 121,
      Returns: 26.9,
      Discounts: 178.57,
    },
    {
      day: "2024-03-17",
      datekey: 20240317,
      value: 3538.449999999999,
      "Total Amount": 3538.449999999999,
      Customers: 72,
      Transactions: 170,
      Returns: 0,
      Discounts: 147.92,
    },
    {
      day: "2024-03-16",
      datekey: 20240316,
      value: 4838.949999999997,
      "Total Amount": 4838.949999999997,
      Customers: 98,
      Transactions: 176,
      Returns: 5.48,
      Discounts: 239.4,
    },
    {
      day: "2024-03-15",
      datekey: 20240315,
      value: 3982.33,
      "Total Amount": 3982.33,
      Customers: 63,
      Transactions: 143,
      Returns: 0,
      Discounts: 286.45,
    },
    {
      day: "2024-03-14",
      datekey: 20240314,
      value: 4529.229999999996,
      "Total Amount": 4529.229999999996,
      Customers: 74,
      Transactions: 143,
      Returns: 22.4,
      Discounts: 341.0300000000001,
    },
    {
      day: "2024-03-13",
      datekey: 20240313,
      value: 4564.529999999999,
      "Total Amount": 4564.529999999999,
      Customers: 64,
      Transactions: 140,
      Returns: 16.75,
      Discounts: 360.8800000000001,
    },
    {
      day: "2024-03-12",
      datekey: 20240312,
      value: 3968.439999999997,
      "Total Amount": 3968.439999999997,
      Customers: 72,
      Transactions: 149,
      Returns: 0.6,
      Discounts: 128.1200000000001,
    },
    {
      day: "2024-03-11",
      datekey: 20240311,
      value: 3323.359999999999,
      "Total Amount": 3323.359999999999,
      Customers: 108,
      Transactions: 131,
      Returns: 0,
      Discounts: 138.38,
    },
    {
      day: "2024-03-10",
      datekey: 20240310,
      value: 5884.429999999996,
      "Total Amount": 5884.429999999996,
      Customers: 101,
      Transactions: 189,
      Returns: 11,
      Discounts: 324.9999999999999,
    },
    {
      day: "2024-03-09",
      datekey: 20240309,
      value: 4975.079999999998,
      "Total Amount": 4975.079999999998,
      Customers: 67,
      Transactions: 161,
      Returns: 15.08,
      Discounts: 279.71,
    },
    {
      day: "2024-03-08",
      datekey: 20240308,
      value: 3204.439999999998,
      "Total Amount": 3204.439999999998,
      Customers: 55,
      Transactions: 148,
      Returns: 12.67,
      Discounts: 147.96,
    },
    {
      day: "2024-03-07",
      datekey: 20240307,
      value: 4039.919999999999,
      "Total Amount": 4039.919999999999,
      Customers: 73,
      Transactions: 139,
      Returns: 14.48,
      Discounts: 272.9099999999999,
    },
    {
      day: "2024-03-06",
      datekey: 20240306,
      value: 4503.409999999996,
      "Total Amount": 4503.409999999996,
      Customers: 54,
      Transactions: 124,
      Returns: 17.8,
      Discounts: 394.81,
    },
    {
      day: "2024-03-05",
      datekey: 20240305,
      value: 3209.939999999998,
      "Total Amount": 3209.939999999998,
      Customers: 61,
      Transactions: 128,
      Returns: 10.43,
      Discounts: 121.78,
    },
    {
      day: "2024-03-04",
      datekey: 20240304,
      value: 3178.43,
      "Total Amount": 3178.43,
      Customers: 61,
      Transactions: 100,
      Returns: 121.4,
      Discounts: 258.66,
    },
    {
      day: "2024-03-03",
      datekey: 20240303,
      value: 5190.229999999996,
      "Total Amount": 5190.229999999996,
      Customers: 80,
      Transactions: 185,
      Returns: 19.29,
      Discounts: 267.5500000000001,
    },
    {
      day: "2024-03-02",
      datekey: 20240302,
      value: 4970.169999999997,
      "Total Amount": 4970.169999999997,
      Customers: 91,
      Transactions: 171,
      Returns: 99.63,
      Discounts: 243.8200000000001,
    },
    {
      day: "2024-03-01",
      datekey: 20240301,
      value: 3812.029999999998,
      "Total Amount": 3812.029999999998,
      Customers: 75,
      Transactions: 157,
      Returns: 10.1,
      Discounts: 230.6199999999999,
    },
    {
      day: "2024-02-29",
      datekey: 20240229,
      value: 4009.92,
      "Total Amount": 4009.92,
      Customers: 62,
      Transactions: 134,
      Returns: 92.06,
      Discounts: 309.3299999999999,
    },
    {
      day: "2024-02-28",
      datekey: 20240228,
      value: 4393.389999999998,
      "Total Amount": 4393.389999999998,
      Customers: 66,
      Transactions: 127,
      Returns: 6.49,
      Discounts: 333.24,
    },
    {
      day: "2024-02-27",
      datekey: 20240227,
      value: 2201.68,
      "Total Amount": 2201.68,
      Customers: 42,
      Transactions: 96,
      Returns: 4.6,
      Discounts: 77.32000000000002,
    },
    {
      day: "2024-02-26",
      datekey: 20240226,
      value: 3021.509999999998,
      "Total Amount": 3021.509999999998,
      Customers: 78,
      Transactions: 112,
      Returns: 6.1,
      Discounts: 166.04,
    },
    {
      day: "2024-02-25",
      datekey: 20240225,
      value: 5172.769999999995,
      "Total Amount": 5172.769999999995,
      Customers: 95,
      Transactions: 160,
      Returns: 6.16,
      Discounts: 338.8200000000001,
    },
    {
      day: "2024-02-24",
      datekey: 20240224,
      value: 4644.279999999993,
      "Total Amount": 4644.279999999993,
      Customers: 87,
      Transactions: 189,
      Returns: 5.2,
      Discounts: 215.05,
    },
    {
      day: "2024-02-23",
      datekey: 20240223,
      value: 4673.019999999998,
      "Total Amount": 4673.019999999998,
      Customers: 73,
      Transactions: 141,
      Returns: 13.21,
      Discounts: 269.7999999999999,
    },
    {
      day: "2024-02-22",
      datekey: 20240222,
      value: 4482.019999999996,
      "Total Amount": 4482.019999999996,
      Customers: 64,
      Transactions: 124,
      Returns: 3.09,
      Discounts: 346.5499999999999,
    },
    {
      day: "2024-02-21",
      datekey: 20240221,
      value: 3988.769999999999,
      "Total Amount": 3988.769999999999,
      Customers: 56,
      Transactions: 131,
      Returns: 33.31,
      Discounts: 274.28,
    },
    {
      day: "2024-02-20",
      datekey: 20240220,
      value: 2464.259999999998,
      "Total Amount": 2464.259999999998,
      Customers: 56,
      Transactions: 109,
      Returns: 27,
      Discounts: 93.61000000000001,
    },
    {
      day: "2024-02-19",
      datekey: 20240219,
      value: 3471.27,
      "Total Amount": 3471.27,
      Customers: 73,
      Transactions: 114,
      Returns: 21,
      Discounts: 165.36,
    },
    {
      day: "2024-02-18",
      datekey: 20240218,
      value: 5818.799999999998,
      "Total Amount": 5818.799999999998,
      Customers: 82,
      Transactions: 199,
      Returns: 1.3,
      Discounts: 362.1800000000001,
    },
    {
      day: "2024-02-17",
      datekey: 20240217,
      value: 3687.979999999995,
      "Total Amount": 3687.979999999995,
      Customers: 76,
      Transactions: 153,
      Returns: 7.03,
      Discounts: 172.6,
    },
    {
      day: "2024-02-16",
      datekey: 20240216,
      value: 3709.019999999998,
      "Total Amount": 3709.019999999998,
      Customers: 87,
      Transactions: 151,
      Returns: 12.7,
      Discounts: 197.92,
    },
    {
      day: "2024-02-15",
      datekey: 20240215,
      value: 3280.459999999998,
      "Total Amount": 3280.459999999998,
      Customers: 77,
      Transactions: 131,
      Returns: 23,
      Discounts: 172.47,
    },
    {
      day: "2024-02-14",
      datekey: 20240214,
      value: 3118.429999999998,
      "Total Amount": 3118.429999999998,
      Customers: 83,
      Transactions: 112,
      Returns: 15.7,
      Discounts: 252.22,
    },
    {
      day: "2024-02-13",
      datekey: 20240213,
      value: 2247.960000000001,
      "Total Amount": 2247.960000000001,
      Customers: 57,
      Transactions: 98,
      Returns: 0,
      Discounts: 79.79,
    },
    {
      day: "2024-02-12",
      datekey: 20240212,
      value: 3853.379999999999,
      "Total Amount": 3853.379999999999,
      Customers: 96,
      Transactions: 131,
      Returns: 1.45,
      Discounts: 296.3000000000001,
    },
    {
      day: "2024-02-11",
      datekey: 20240211,
      value: 5143.449999999995,
      "Total Amount": 5143.449999999995,
      Customers: 113,
      Transactions: 221,
      Returns: 16.48,
      Discounts: 227.77,
    },
    {
      day: "2024-02-10",
      datekey: 20240210,
      value: 4780.989999999993,
      "Total Amount": 4780.989999999993,
      Customers: 80,
      Transactions: 179,
      Returns: 9.4,
      Discounts: 275.2999999999999,
    },
    {
      day: "2024-02-09",
      datekey: 20240209,
      value: 3859.85,
      "Total Amount": 3859.85,
      Customers: 55,
      Transactions: 144,
      Returns: 21.5,
      Discounts: 215.6000000000001,
    },
    {
      day: "2024-02-08",
      datekey: 20240208,
      value: 3986.5,
      "Total Amount": 3986.5,
      Customers: 61,
      Transactions: 131,
      Returns: 12.5,
      Discounts: 311.08,
    },
    {
      day: "2024-02-07",
      datekey: 20240207,
      value: 4092.489999999999,
      "Total Amount": 4092.489999999999,
      Customers: 68,
      Transactions: 149,
      Returns: 9,
      Discounts: 275.6300000000001,
    },
    {
      day: "2024-02-06",
      datekey: 20240206,
      value: 2860.63,
      "Total Amount": 2860.63,
      Customers: 47,
      Transactions: 104,
      Returns: 18.4,
      Discounts: 108.05,
    },
    {
      day: "2024-02-05",
      datekey: 20240205,
      value: 2693.08,
      "Total Amount": 2693.08,
      Customers: 43,
      Transactions: 106,
      Returns: 19.91,
      Discounts: 118.27,
    },
    {
      day: "2024-02-04",
      datekey: 20240204,
      value: 5580.929999999995,
      "Total Amount": 5580.929999999995,
      Customers: 92,
      Transactions: 216,
      Returns: 21.5,
      Discounts: 279.11,
    },
    {
      day: "2024-02-03",
      datekey: 20240203,
      value: 4067.859999999999,
      "Total Amount": 4067.859999999999,
      Customers: 67,
      Transactions: 177,
      Returns: 3.13,
      Discounts: 187.6700000000001,
    },
    {
      day: "2024-02-02",
      datekey: 20240202,
      value: 3980.299999999999,
      "Total Amount": 3980.299999999999,
      Customers: 65,
      Transactions: 147,
      Returns: 2.75,
      Discounts: 198.26,
    },
    {
      day: "2024-02-01",
      datekey: 20240201,
      value: 4272.489999999996,
      "Total Amount": 4272.489999999996,
      Customers: 56,
      Transactions: 142,
      Returns: 30.77,
      Discounts: 349.67,
    },
    {
      day: "2024-01-31",
      datekey: 20240131,
      value: 3923.569999999997,
      "Total Amount": 3923.569999999997,
      Customers: 61,
      Transactions: 125,
      Returns: 108.9,
      Discounts: 358.4000000000004,
    },
    {
      day: "2024-01-30",
      datekey: 20240130,
      value: 2945.769999999999,
      "Total Amount": 2945.769999999999,
      Customers: 61,
      Transactions: 117,
      Returns: 23.5,
      Discounts: 119.21,
    },
    {
      day: "2024-01-29",
      datekey: 20240129,
      value: 2311.949999999999,
      "Total Amount": 2311.949999999999,
      Customers: 60,
      Transactions: 98,
      Returns: 0,
      Discounts: 109.45,
    },
    {
      day: "2024-01-28",
      datekey: 20240128,
      value: 5320.619999999994,
      "Total Amount": 5320.619999999994,
      Customers: 142,
      Transactions: 205,
      Returns: 27.2,
      Discounts: 307.4,
    },
    {
      day: "2024-01-27",
      datekey: 20240127,
      value: 5628.2,
      "Total Amount": 5628.2,
      Customers: 137,
      Transactions: 205,
      Returns: 16.08,
      Discounts: 333.1500000000001,
    },
    {
      day: "2024-01-26",
      datekey: 20240126,
      value: 3470.39,
      "Total Amount": 3470.39,
      Customers: 94,
      Transactions: 133,
      Returns: 9.030000000000001,
      Discounts: 199.8999999999999,
    },
    {
      day: "2024-01-25",
      datekey: 20240125,
      value: 3997.929999999999,
      "Total Amount": 3997.929999999999,
      Customers: 71,
      Transactions: 116,
      Returns: 0,
      Discounts: 321.2099999999999,
    },
    {
      day: "2024-01-24",
      datekey: 20240124,
      value: 3194.349999999999,
      "Total Amount": 3194.349999999999,
      Customers: 70,
      Transactions: 125,
      Returns: 32.59999999999999,
      Discounts: 200.42,
    },
    {
      day: "2024-01-23",
      datekey: 20240123,
      value: 3064.24,
      "Total Amount": 3064.24,
      Customers: 81,
      Transactions: 116,
      Returns: 0,
      Discounts: 144.21,
    },
    {
      day: "2024-01-22",
      datekey: 20240122,
      value: 3043.47,
      "Total Amount": 3043.47,
      Customers: 60,
      Transactions: 92,
      Returns: 5,
      Discounts: 232.69,
    },
    {
      day: "2024-01-21",
      datekey: 20240121,
      value: 5526.339999999999,
      "Total Amount": 5526.339999999999,
      Customers: 126,
      Transactions: 169,
      Returns: 0,
      Discounts: 272.94,
    },
    {
      day: "2024-01-20",
      datekey: 20240120,
      value: 4960.139999999997,
      "Total Amount": 4960.139999999997,
      Customers: 126,
      Transactions: 196,
      Returns: 1,
      Discounts: 300.4500000000001,
    },
    {
      day: "2024-01-19",
      datekey: 20240119,
      value: 3882.920000000002,
      "Total Amount": 3882.920000000002,
      Customers: 98,
      Transactions: 133,
      Returns: 0.83,
      Discounts: 245.3199999999999,
    },
    {
      day: "2024-01-18",
      datekey: 20240118,
      value: 3699.730000000001,
      "Total Amount": 3699.730000000001,
      Customers: 81,
      Transactions: 116,
      Returns: 8.3,
      Discounts: 282.9999999999999,
    },
    {
      day: "2024-01-17",
      datekey: 20240117,
      value: 3325.339999999999,
      "Total Amount": 3325.339999999999,
      Customers: 90,
      Transactions: 120,
      Returns: 1.96,
      Discounts: 195.0099999999999,
    },
    {
      day: "2024-01-16",
      datekey: 20240116,
      value: 1687.4,
      "Total Amount": 1687.4,
      Customers: 53,
      Transactions: 69,
      Returns: 1.27,
      Discounts: 76.56000000000003,
    },
    {
      day: "2024-01-15",
      datekey: 20240115,
      value: 2424.819999999999,
      "Total Amount": 2424.819999999999,
      Customers: 67,
      Transactions: 91,
      Returns: 0,
      Discounts: 115.14,
    },
    {
      day: "2024-01-14",
      datekey: 20240114,
      value: 5012.670000000001,
      "Total Amount": 5012.670000000001,
      Customers: 138,
      Transactions: 202,
      Returns: 14.1,
      Discounts: 290.24,
    },
    {
      day: "2024-01-13",
      datekey: 20240113,
      value: 6757.019999999997,
      "Total Amount": 6757.019999999997,
      Customers: 161,
      Transactions: 224,
      Returns: 15.9,
      Discounts: 416.94,
    },
    {
      day: "2024-01-12",
      datekey: 20240112,
      value: 5620.019999999999,
      "Total Amount": 5620.019999999999,
      Customers: 122,
      Transactions: 170,
      Returns: 22.97,
      Discounts: 371.7900000000001,
    },
    {
      day: "2024-01-11",
      datekey: 20240111,
      value: 3506.479999999998,
      "Total Amount": 3506.479999999998,
      Customers: 110,
      Transactions: 139,
      Returns: 10.33,
      Discounts: 194.63,
    },
    {
      day: "2024-01-10",
      datekey: 20240110,
      value: 3067.399999999999,
      "Total Amount": 3067.399999999999,
      Customers: 79,
      Transactions: 113,
      Returns: 9.65,
      Discounts: 196.32,
    },
    {
      day: "2024-01-09",
      datekey: 20240109,
      value: 4972.28,
      "Total Amount": 4972.28,
      Customers: 98,
      Transactions: 140,
      Returns: 57.4,
      Discounts: 435.8199999999999,
    },
    {
      day: "2024-01-08",
      datekey: 20240108,
      value: 2919.759999999998,
      "Total Amount": 2919.759999999998,
      Customers: 95,
      Transactions: 127,
      Returns: 0,
      Discounts: 160.55,
    },
    {
      day: "2024-01-07",
      datekey: 20240107,
      value: 4459.959999999997,
      "Total Amount": 4459.959999999997,
      Customers: 136,
      Transactions: 178,
      Returns: 3.95,
      Discounts: 254.9,
    },
    {
      day: "2024-01-06",
      datekey: 20240106,
      value: 4172.69,
      "Total Amount": 4172.69,
      Customers: 142,
      Transactions: 187,
      Returns: 0,
      Discounts: 210.9299999999999,
    },
    {
      day: "2024-01-05",
      datekey: 20240105,
      value: 3938.419999999999,
      "Total Amount": 3938.419999999999,
      Customers: 111,
      Transactions: 151,
      Returns: 15.1,
      Discounts: 255.11,
    },
    {
      day: "2024-01-04",
      datekey: 20240104,
      value: 4449.009999999999,
      "Total Amount": 4449.009999999999,
      Customers: 98,
      Transactions: 134,
      Returns: 1.9,
      Discounts: 359.2700000000001,
    },
    {
      day: "2024-01-03",
      datekey: 20240103,
      value: 3519.3,
      "Total Amount": 3519.3,
      Customers: 104,
      Transactions: 152,
      Returns: 1.4,
      Discounts: 208.2799999999999,
    },
    {
      day: "2024-01-02",
      datekey: 20240102,
      value: 3694.819999999998,
      "Total Amount": 3694.819999999998,
      Customers: 93,
      Transactions: 136,
      Returns: 0,
      Discounts: 178.9,
    },
    {
      day: "2024-01-01",
      datekey: 20240101,
      value: 2535.91,
      "Total Amount": 2535.91,
      Customers: 77,
      Transactions: 107,
      Returns: 12.5,
      Discounts: 143.79,
    },
    {
      day: "2023-12-31",
      datekey: 20231231,
      value: 4816.520000000004,
      "Total Amount": 4816.520000000004,
      Customers: 133,
      Transactions: 211,
      Returns: 37.08,
      Discounts: 265.4599999999999,
    },
    {
      day: "2023-12-30",
      datekey: 20231230,
      value: 6432.989999999999,
      "Total Amount": 6432.989999999999,
      Customers: 124,
      Transactions: 194,
      Returns: 44.66,
      Discounts: 557.8300000000002,
    },
    {
      day: "2023-12-29",
      datekey: 20231229,
      value: 3711.109999999998,
      "Total Amount": 3711.109999999998,
      Customers: 100,
      Transactions: 153,
      Returns: 13.3,
      Discounts: 222.7800000000001,
    },
    {
      day: "2023-12-28",
      datekey: 20231228,
      value: 3760.269999999999,
      "Total Amount": 3760.269999999999,
      Customers: 102,
      Transactions: 149,
      Returns: 4,
      Discounts: 258.0899999999999,
    },
    {
      day: "2023-12-27",
      datekey: 20231227,
      value: 6052.729999999996,
      "Total Amount": 6052.729999999996,
      Customers: 98,
      Transactions: 151,
      Returns: 0,
      Discounts: 604.5700000000002,
    },
    {
      day: "2023-12-26",
      datekey: 20231226,
      value: 3248.469999999999,
      "Total Amount": 3248.469999999999,
      Customers: 82,
      Transactions: 132,
      Returns: 0,
      Discounts: 196.4600000000001,
    },
    {
      day: "2023-12-25",
      datekey: 20231225,
      value: 2028.710000000001,
      "Total Amount": 2028.710000000001,
      Customers: 52,
      Transactions: 95,
      Returns: 0,
      Discounts: 100.37,
    },
    {
      day: "2023-12-24",
      datekey: 20231224,
      value: 6150.459999999996,
      "Total Amount": 6150.459999999996,
      Customers: 160,
      Transactions: 220,
      Returns: 0,
      Discounts: 365.67,
    },
    {
      day: "2023-12-23",
      datekey: 20231223,
      value: 4618.109999999998,
      "Total Amount": 4618.109999999998,
      Customers: 119,
      Transactions: 181,
      Returns: 0,
      Discounts: 263.4099999999998,
    },
    {
      day: "2023-12-22",
      datekey: 20231222,
      value: 3652.749999999997,
      "Total Amount": 3652.749999999997,
      Customers: 117,
      Transactions: 154,
      Returns: 5.99,
      Discounts: 270.3100000000001,
    },
    {
      day: "2023-12-21",
      datekey: 20231221,
      value: 4653.339999999998,
      "Total Amount": 4653.339999999998,
      Customers: 111,
      Transactions: 149,
      Returns: 21.01,
      Discounts: 383.9,
    },
    {
      day: "2023-12-20",
      datekey: 20231220,
      value: 2635.369999999999,
      "Total Amount": 2635.369999999999,
      Customers: 86,
      Transactions: 117,
      Returns: 0,
      Discounts: 147.43,
    },
    {
      day: "2023-12-19",
      datekey: 20231219,
      value: 4566.899999999998,
      "Total Amount": 4566.899999999998,
      Customers: 88,
      Transactions: 123,
      Returns: 10.6,
      Discounts: 424.9200000000001,
    },
    {
      day: "2023-12-18",
      datekey: 20231218,
      value: 2519.410000000001,
      "Total Amount": 2519.410000000001,
      Customers: 72,
      Transactions: 115,
      Returns: 3.2,
      Discounts: 135.88,
    },
    {
      day: "2023-12-17",
      datekey: 20231217,
      value: 3771.569999999997,
      "Total Amount": 3771.569999999997,
      Customers: 114,
      Transactions: 153,
      Returns: 0,
      Discounts: 216.61,
    },
    {
      day: "2023-12-16",
      datekey: 20231216,
      value: 4368.84,
      "Total Amount": 4368.84,
      Customers: 124,
      Transactions: 180,
      Returns: 16.43,
      Discounts: 254.7400000000001,
    },
    {
      day: "2023-12-15",
      datekey: 20231215,
      value: 4146.949999999999,
      "Total Amount": 4146.949999999999,
      Customers: 103,
      Transactions: 149,
      Returns: 12.78,
      Discounts: 241.0700000000001,
    },
    {
      day: "2023-12-14",
      datekey: 20231214,
      value: 3087.090000000001,
      "Total Amount": 3087.090000000001,
      Customers: 93,
      Transactions: 144,
      Returns: 13.52,
      Discounts: 222.94,
    },
    {
      day: "2023-12-13",
      datekey: 20231213,
      value: 3762.18,
      "Total Amount": 3762.18,
      Customers: 89,
      Transactions: 134,
      Returns: 8.2,
      Discounts: 370.0799999999999,
    },
    {
      day: "2023-12-12",
      datekey: 20231212,
      value: 4071.199999999998,
      "Total Amount": 4071.199999999998,
      Customers: 98,
      Transactions: 152,
      Returns: 6.5,
      Discounts: 230.0999999999999,
    },
    {
      day: "2023-12-11",
      datekey: 20231211,
      value: 2061.11,
      "Total Amount": 2061.11,
      Customers: 73,
      Transactions: 102,
      Returns: 0,
      Discounts: 113.18,
    },
    {
      day: "2023-12-10",
      datekey: 20231210,
      value: 5269.499999999997,
      "Total Amount": 5269.499999999997,
      Customers: 155,
      Transactions: 222,
      Returns: 7.15,
      Discounts: 315.33,
    },
    {
      day: "2023-12-09",
      datekey: 20231209,
      value: 4857.87,
      "Total Amount": 4857.87,
      Customers: 100,
      Transactions: 167,
      Returns: 2.05,
      Discounts: 278.83,
    },
    {
      day: "2023-12-08",
      datekey: 20231208,
      value: 4889.47,
      "Total Amount": 4889.47,
      Customers: 112,
      Transactions: 154,
      Returns: 10.27,
      Discounts: 439.4200000000001,
    },
    {
      day: "2023-12-07",
      datekey: 20231207,
      value: 4319.51,
      "Total Amount": 4319.51,
      Customers: 98,
      Transactions: 139,
      Returns: 0,
      Discounts: 412.21,
    },
    {
      day: "2023-12-06",
      datekey: 20231206,
      value: 3461.79,
      "Total Amount": 3461.79,
      Customers: 83,
      Transactions: 131,
      Returns: 5.33,
      Discounts: 250.11,
    },
    {
      day: "2023-12-05",
      datekey: 20231205,
      value: 3571.88,
      "Total Amount": 3571.88,
      Customers: 83,
      Transactions: 131,
      Returns: 4.2,
      Discounts: 230.48,
    },
    {
      day: "2023-12-04",
      datekey: 20231204,
      value: 2842.489999999999,
      "Total Amount": 2842.489999999999,
      Customers: 78,
      Transactions: 114,
      Returns: 5.94,
      Discounts: 137.63,
    },
    {
      day: "2023-12-03",
      datekey: 20231203,
      value: 5064.579999999998,
      "Total Amount": 5064.579999999998,
      Customers: 119,
      Transactions: 175,
      Returns: 6.4,
      Discounts: 362.1499999999999,
    },
    {
      day: "2023-12-02",
      datekey: 20231202,
      value: 4110.829999999997,
      "Total Amount": 4110.829999999997,
      Customers: 100,
      Transactions: 161,
      Returns: 0,
      Discounts: 205.58,
    },
    {
      day: "2023-12-01",
      datekey: 20231201,
      value: 4205.669999999999,
      "Total Amount": 4205.669999999999,
      Customers: 104,
      Transactions: 157,
      Returns: 5.66,
      Discounts: 268.46,
    },
    {
      day: "2023-11-30",
      datekey: 20231130,
      value: 3773.599999999998,
      "Total Amount": 3773.599999999998,
      Customers: 75,
      Transactions: 139,
      Returns: 52.07000000000001,
      Discounts: 294.0099999999999,
    },
    {
      day: "2023-11-29",
      datekey: 20231129,
      value: 3602.52,
      "Total Amount": 3602.52,
      Customers: 91,
      Transactions: 131,
      Returns: 0,
      Discounts: 265.18,
    },
    {
      day: "2023-11-28",
      datekey: 20231128,
      value: 3639.68,
      "Total Amount": 3639.68,
      Customers: 84,
      Transactions: 153,
      Returns: 1.75,
      Discounts: 280.92,
    },
    {
      day: "2023-11-27",
      datekey: 20231127,
      value: 2444.84,
      "Total Amount": 2444.84,
      Customers: 82,
      Transactions: 125,
      Returns: 0,
      Discounts: 109.25,
    },
    {
      day: "2023-11-26",
      datekey: 20231126,
      value: 3854.659999999999,
      "Total Amount": 3854.659999999999,
      Customers: 107,
      Transactions: 153,
      Returns: 6.99,
      Discounts: 232.43,
    },
    {
      day: "2023-11-25",
      datekey: 20231125,
      value: 3397.109999999997,
      "Total Amount": 3397.109999999997,
      Customers: 83,
      Transactions: 145,
      Returns: 8.59,
      Discounts: 267.82,
    },
    {
      day: "2023-11-24",
      datekey: 20231124,
      value: 3162.500000000002,
      "Total Amount": 3162.500000000002,
      Customers: 83,
      Transactions: 136,
      Returns: 14.5,
      Discounts: 238.13,
    },
    {
      day: "2023-11-23",
      datekey: 20231123,
      value: 4688.46,
      "Total Amount": 4688.46,
      Customers: 110,
      Transactions: 184,
      Returns: 0,
      Discounts: 326.99,
    },
    {
      day: "2023-11-22",
      datekey: 20231122,
      value: 5076.360000000002,
      "Total Amount": 5076.360000000002,
      Customers: 140,
      Transactions: 211,
      Returns: 0,
      Discounts: 361.5799999999998,
    },
    {
      day: "2023-11-21",
      datekey: 20231121,
      value: 3781.48,
      "Total Amount": 3781.48,
      Customers: 77,
      Transactions: 115,
      Returns: 0,
      Discounts: 270,
    },
    {
      day: "2023-11-20",
      datekey: 20231120,
      value: 3118.200000000001,
      "Total Amount": 3118.200000000001,
      Customers: 103,
      Transactions: 133,
      Returns: 6,
      Discounts: 148.72,
    },
    {
      day: "2023-11-19",
      datekey: 20231119,
      value: 4000.159999999998,
      "Total Amount": 4000.159999999998,
      Customers: 94,
      Transactions: 167,
      Returns: 23.4,
      Discounts: 190.63,
    },
    {
      day: "2023-11-18",
      datekey: 20231118,
      value: 3937.879999999999,
      "Total Amount": 3937.879999999999,
      Customers: 114,
      Transactions: 187,
      Returns: 7.15,
      Discounts: 211.9,
    },
    {
      day: "2023-11-17",
      datekey: 20231117,
      value: 3367.499999999998,
      "Total Amount": 3367.499999999998,
      Customers: 86,
      Transactions: 150,
      Returns: 41.92,
      Discounts: 225.3600000000001,
    },
    {
      day: "2023-11-16",
      datekey: 20231116,
      value: 3754.6,
      "Total Amount": 3754.6,
      Customers: 96,
      Transactions: 150,
      Returns: 8.2,
      Discounts: 248.9399999999999,
    },
    {
      day: "2023-11-15",
      datekey: 20231115,
      value: 4102.179999999999,
      "Total Amount": 4102.179999999999,
      Customers: 90,
      Transactions: 134,
      Returns: 11,
      Discounts: 379.77,
    },
    {
      day: "2023-11-14",
      datekey: 20231114,
      value: 3178.479999999999,
      "Total Amount": 3178.479999999999,
      Customers: 76,
      Transactions: 125,
      Returns: 10.55,
      Discounts: 215.25,
    },
    {
      day: "2023-11-13",
      datekey: 20231113,
      value: 2223.98,
      "Total Amount": 2223.98,
      Customers: 84,
      Transactions: 117,
      Returns: 9.04,
      Discounts: 123.49,
    },
    {
      day: "2023-11-12",
      datekey: 20231112,
      value: 5720.279999999993,
      "Total Amount": 5720.279999999993,
      Customers: 167,
      Transactions: 268,
      Returns: 8.129999999999999,
      Discounts: 278.32,
    },
    {
      day: "2023-11-11",
      datekey: 20231111,
      value: 5936.259999999993,
      "Total Amount": 5936.259999999993,
      Customers: 128,
      Transactions: 232,
      Returns: 53.35,
      Discounts: 320.2899999999997,
    },
    {
      day: "2023-11-10",
      datekey: 20231110,
      value: 4508.819999999997,
      "Total Amount": 4508.819999999997,
      Customers: 120,
      Transactions: 198,
      Returns: 0,
      Discounts: 253.3700000000001,
    },
    {
      day: "2023-11-09",
      datekey: 20231109,
      value: 4194.040000000001,
      "Total Amount": 4194.040000000001,
      Customers: 94,
      Transactions: 148,
      Returns: 5.75,
      Discounts: 268.2699999999999,
    },
    {
      day: "2023-11-08",
      datekey: 20231108,
      value: 3656.98,
      "Total Amount": 3656.98,
      Customers: 94,
      Transactions: 146,
      Returns: 8.2,
      Discounts: 191.6500000000001,
    },
    {
      day: "2023-11-07",
      datekey: 20231107,
      value: 3889.479999999998,
      "Total Amount": 3889.479999999998,
      Customers: 62,
      Transactions: 129,
      Returns: 16.2,
      Discounts: 397.0599999999999,
    },
    {
      day: "2023-11-06",
      datekey: 20231106,
      value: 4564.88,
      "Total Amount": 4564.88,
      Customers: 80,
      Transactions: 116,
      Returns: 3,
      Discounts: 502.6399999999998,
    },
    {
      day: "2023-11-05",
      datekey: 20231105,
      value: 4730.809999999995,
      "Total Amount": 4730.809999999995,
      Customers: 130,
      Transactions: 188,
      Returns: 1.29,
      Discounts: 222.55,
    },
    {
      day: "2023-11-04",
      datekey: 20231104,
      value: 3871.679999999998,
      "Total Amount": 3871.679999999998,
      Customers: 100,
      Transactions: 182,
      Returns: 31.3,
      Discounts: 205.91,
    },
    {
      day: "2023-11-03",
      datekey: 20231103,
      value: 3865.579999999999,
      "Total Amount": 3865.579999999999,
      Customers: 113,
      Transactions: 154,
      Returns: 0,
      Discounts: 227.57,
    },
    {
      day: "2023-11-02",
      datekey: 20231102,
      value: 3525.52,
      "Total Amount": 3525.52,
      Customers: 91,
      Transactions: 135,
      Returns: 0,
      Discounts: 223.58,
    },
    {
      day: "2023-11-01",
      datekey: 20231101,
      value: 5042.070000000002,
      "Total Amount": 5042.070000000002,
      Customers: 103,
      Transactions: 161,
      Returns: 3.2,
      Discounts: 518.0399999999998,
    },
    {
      day: "2023-10-31",
      datekey: 20231031,
      value: 3425.579999999998,
      "Total Amount": 3425.579999999998,
      Customers: 82,
      Transactions: 134,
      Returns: 0,
      Discounts: 246.4,
    },
    {
      day: "2023-10-30",
      datekey: 20231030,
      value: 2457.99,
      "Total Amount": 2457.99,
      Customers: 71,
      Transactions: 110,
      Returns: 5.47,
      Discounts: 147,
    },
    {
      day: "2023-10-29",
      datekey: 20231029,
      value: 4640.029999999996,
      "Total Amount": 4640.029999999996,
      Customers: 136,
      Transactions: 204,
      Returns: 3.5,
      Discounts: 229.34,
    },
    {
      day: "2023-10-28",
      datekey: 20231028,
      value: 4039.3,
      "Total Amount": 4039.3,
      Customers: 109,
      Transactions: 188,
      Returns: 7.48,
      Discounts: 228.41,
    },
    {
      day: "2023-10-27",
      datekey: 20231027,
      value: 6613.529999999998,
      "Total Amount": 6613.529999999998,
      Customers: 111,
      Transactions: 180,
      Returns: 7.97,
      Discounts: 665.5,
    },
    {
      day: "2023-10-26",
      datekey: 20231026,
      value: 5141.699999999998,
      "Total Amount": 5141.699999999998,
      Customers: 107,
      Transactions: 173,
      Returns: 5,
      Discounts: 465.9699999999999,
    },
    {
      day: "2023-10-25",
      datekey: 20231025,
      value: 4055.399999999998,
      "Total Amount": 4055.399999999998,
      Customers: 105,
      Transactions: 165,
      Returns: 7.42,
      Discounts: 309.2099999999999,
    },
    {
      day: "2023-10-24",
      datekey: 20231024,
      value: 3924.599999999999,
      "Total Amount": 3924.599999999999,
      Customers: 110,
      Transactions: 165,
      Returns: 1.7,
      Discounts: 267.4500000000001,
    },
    {
      day: "2023-10-23",
      datekey: 20231023,
      value: 2672.01,
      "Total Amount": 2672.01,
      Customers: 99,
      Transactions: 134,
      Returns: 9.02,
      Discounts: 152.94,
    },
    {
      day: "2023-10-22",
      datekey: 20231022,
      value: 5872.779999999997,
      "Total Amount": 5872.779999999997,
      Customers: 139,
      Transactions: 215,
      Returns: 4.4,
      Discounts: 355.93,
    },
    {
      day: "2023-10-21",
      datekey: 20231021,
      value: 4257.059999999997,
      "Total Amount": 4257.059999999997,
      Customers: 121,
      Transactions: 198,
      Returns: 35.8,
      Discounts: 260.8700000000001,
    },
    {
      day: "2023-10-20",
      datekey: 20231020,
      value: 4818.709999999996,
      "Total Amount": 4818.709999999996,
      Customers: 113,
      Transactions: 177,
      Returns: 9.6,
      Discounts: 388.6800000000001,
    },
    {
      day: "2023-10-19",
      datekey: 20231019,
      value: 4254.069999999997,
      "Total Amount": 4254.069999999997,
      Customers: 98,
      Transactions: 171,
      Returns: 22.46,
      Discounts: 294.21,
    },
    {
      day: "2023-10-18",
      datekey: 20231018,
      value: 3788.979999999998,
      "Total Amount": 3788.979999999998,
      Customers: 109,
      Transactions: 170,
      Returns: 0,
      Discounts: 262.4199999999999,
    },
    {
      day: "2023-10-17",
      datekey: 20231017,
      value: 3370.849999999998,
      "Total Amount": 3370.849999999998,
      Customers: 85,
      Transactions: 138,
      Returns: 2.33,
      Discounts: 216.1700000000001,
    },
    {
      day: "2023-10-16",
      datekey: 20231016,
      value: 2468.990000000001,
      "Total Amount": 2468.990000000001,
      Customers: 70,
      Transactions: 126,
      Returns: 11.98,
      Discounts: 138.8500000000001,
    },
    {
      day: "2023-10-15",
      datekey: 20231015,
      value: 4820.199999999995,
      "Total Amount": 4820.199999999995,
      Customers: 130,
      Transactions: 200,
      Returns: 24.87,
      Discounts: 270.9900000000001,
    },
    {
      day: "2023-10-14",
      datekey: 20231014,
      value: 4812.289999999995,
      "Total Amount": 4812.289999999995,
      Customers: 123,
      Transactions: 214,
      Returns: 13.27,
      Discounts: 272.8399999999999,
    },
    {
      day: "2023-10-13",
      datekey: 20231013,
      value: 4482.969999999999,
      "Total Amount": 4482.969999999999,
      Customers: 109,
      Transactions: 177,
      Returns: 0,
      Discounts: 308.0400000000001,
    },
    {
      day: "2023-10-12",
      datekey: 20231012,
      value: 4891.029999999999,
      "Total Amount": 4891.029999999999,
      Customers: 79,
      Transactions: 141,
      Returns: 4.1,
      Discounts: 501.2600000000001,
    },
    {
      day: "2023-10-11",
      datekey: 20231011,
      value: 3626.449999999997,
      "Total Amount": 3626.449999999997,
      Customers: 103,
      Transactions: 155,
      Returns: 1.9,
      Discounts: 257.2000000000001,
    },
    {
      day: "2023-10-10",
      datekey: 20231010,
      value: 3742.739999999998,
      "Total Amount": 3742.739999999998,
      Customers: 79,
      Transactions: 148,
      Returns: 1.87,
      Discounts: 205.36,
    },
    {
      day: "2023-10-09",
      datekey: 20231009,
      value: 2934.710000000001,
      "Total Amount": 2934.710000000001,
      Customers: 83,
      Transactions: 118,
      Returns: 0,
      Discounts: 155.14,
    },
    {
      day: "2023-10-08",
      datekey: 20231008,
      value: 5238.729999999998,
      "Total Amount": 5238.729999999998,
      Customers: 136,
      Transactions: 199,
      Returns: 7.02,
      Discounts: 287.16,
    },
    {
      day: "2023-10-07",
      datekey: 20231007,
      value: 5586.569999999999,
      "Total Amount": 5586.569999999999,
      Customers: 137,
      Transactions: 212,
      Returns: 0,
      Discounts: 349.43,
    },
    {
      day: "2023-10-06",
      datekey: 20231006,
      value: 4004.279999999997,
      "Total Amount": 4004.279999999997,
      Customers: 102,
      Transactions: 164,
      Returns: 10,
      Discounts: 284.91,
    },
    {
      day: "2023-10-05",
      datekey: 20231005,
      value: 4107.789999999999,
      "Total Amount": 4107.789999999999,
      Customers: 87,
      Transactions: 149,
      Returns: 1.15,
      Discounts: 385.43,
    },
    {
      day: "2023-10-04",
      datekey: 20231004,
      value: 3549.459999999999,
      "Total Amount": 3549.459999999999,
      Customers: 90,
      Transactions: 139,
      Returns: 0,
      Discounts: 222.96,
    },
    {
      day: "2023-10-03",
      datekey: 20231003,
      value: 3536.23,
      "Total Amount": 3536.23,
      Customers: 79,
      Transactions: 145,
      Returns: 43.5,
      Discounts: 198.4500000000001,
    },
    {
      day: "2023-10-02",
      datekey: 20231002,
      value: 3153.189999999999,
      "Total Amount": 3153.189999999999,
      Customers: 87,
      Transactions: 127,
      Returns: 1.75,
      Discounts: 163.33,
    },
    {
      day: "2023-10-01",
      datekey: 20231001,
      value: 5034.969999999994,
      "Total Amount": 5034.969999999994,
      Customers: 136,
      Transactions: 213,
      Returns: 25.99,
      Discounts: 273.9600000000001,
    },
    {
      day: "2023-09-30",
      datekey: 20230930,
      value: 4226.109999999999,
      "Total Amount": 4226.109999999999,
      Customers: 111,
      Transactions: 185,
      Returns: 17.9,
      Discounts: 237.52,
    },
    {
      day: "2023-09-29",
      datekey: 20230929,
      value: 4697.419999999998,
      "Total Amount": 4697.419999999998,
      Customers: 97,
      Transactions: 173,
      Returns: 10.3,
      Discounts: 359.5500000000001,
    },
    {
      day: "2023-09-28",
      datekey: 20230928,
      value: 3999.439999999998,
      "Total Amount": 3999.439999999998,
      Customers: 99,
      Transactions: 162,
      Returns: 3.05,
      Discounts: 308.1399999999999,
    },
    {
      day: "2023-09-27",
      datekey: 20230927,
      value: 3073.51,
      "Total Amount": 3073.51,
      Customers: 61,
      Transactions: 118,
      Returns: 1.75,
      Discounts: 227.1100000000001,
    },
    {
      day: "2023-09-26",
      datekey: 20230926,
      value: 3589.56,
      "Total Amount": 3589.56,
      Customers: 79,
      Transactions: 148,
      Returns: 0,
      Discounts: 209.59,
    },
    {
      day: "2023-09-25",
      datekey: 20230925,
      value: 2728.72,
      "Total Amount": 2728.72,
      Customers: 84,
      Transactions: 129,
      Returns: 5.25,
      Discounts: 143.65,
    },
    {
      day: "2023-09-24",
      datekey: 20230924,
      value: 5151.010000000002,
      "Total Amount": 5151.010000000002,
      Customers: 133,
      Transactions: 215,
      Returns: 34.17,
      Discounts: 321.4799999999999,
    },
    {
      day: "2023-09-23",
      datekey: 20230923,
      value: 4468.279999999994,
      "Total Amount": 4468.279999999994,
      Customers: 119,
      Transactions: 213,
      Returns: 20.09,
      Discounts: 232.87,
    },
    {
      day: "2023-09-22",
      datekey: 20230922,
      value: 4585.479999999997,
      "Total Amount": 4585.479999999997,
      Customers: 92,
      Transactions: 184,
      Returns: 83.2,
      Discounts: 295.56,
    },
    {
      day: "2023-09-21",
      datekey: 20230921,
      value: 4305.939999999998,
      "Total Amount": 4305.939999999998,
      Customers: 65,
      Transactions: 137,
      Returns: 0,
      Discounts: 410.7700000000001,
    },
    {
      day: "2023-09-20",
      datekey: 20230920,
      value: 3240.27,
      "Total Amount": 3240.27,
      Customers: 93,
      Transactions: 147,
      Returns: 0,
      Discounts: 209.7500000000002,
    },
    {
      day: "2023-09-19",
      datekey: 20230919,
      value: 2660.349999999998,
      "Total Amount": 2660.349999999998,
      Customers: 68,
      Transactions: 144,
      Returns: 11,
      Discounts: 187.7700000000002,
    },
    {
      day: "2023-09-18",
      datekey: 20230918,
      value: 2450.379999999999,
      "Total Amount": 2450.379999999999,
      Customers: 74,
      Transactions: 148,
      Returns: 20.48,
      Discounts: 118.86,
    },
    {
      day: "2023-09-17",
      datekey: 20230917,
      value: 5947.769999999998,
      "Total Amount": 5947.769999999998,
      Customers: 142,
      Transactions: 237,
      Returns: 32.59,
      Discounts: 415.1100000000001,
    },
    {
      day: "2023-09-16",
      datekey: 20230916,
      value: 5420.049999999992,
      "Total Amount": 5420.049999999992,
      Customers: 138,
      Transactions: 213,
      Returns: 30.27,
      Discounts: 399.3800000000001,
    },
    {
      day: "2023-09-15",
      datekey: 20230915,
      value: 5069.499999999995,
      "Total Amount": 5069.499999999995,
      Customers: 110,
      Transactions: 176,
      Returns: 71.5,
      Discounts: 343.72,
    },
    {
      day: "2023-09-14",
      datekey: 20230914,
      value: 4068.429999999999,
      "Total Amount": 4068.429999999999,
      Customers: 96,
      Transactions: 147,
      Returns: 0,
      Discounts: 255.87,
    },
    {
      day: "2023-09-13",
      datekey: 20230913,
      value: 4317.190000000001,
      "Total Amount": 4317.190000000001,
      Customers: 95,
      Transactions: 153,
      Returns: 5.65,
      Discounts: 309.35,
    },
    {
      day: "2023-09-12",
      datekey: 20230912,
      value: 3221.719999999998,
      "Total Amount": 3221.719999999998,
      Customers: 70,
      Transactions: 131,
      Returns: 2.3,
      Discounts: 299.6100000000003,
    },
    {
      day: "2023-09-11",
      datekey: 20230911,
      value: 2448.190000000001,
      "Total Amount": 2448.190000000001,
      Customers: 79,
      Transactions: 122,
      Returns: 9,
      Discounts: 131.62,
    },
    {
      day: "2023-09-10",
      datekey: 20230910,
      value: 4627.009999999999,
      "Total Amount": 4627.009999999999,
      Customers: 130,
      Transactions: 211,
      Returns: 8.4,
      Discounts: 267.4,
    },
    {
      day: "2023-09-09",
      datekey: 20230909,
      value: 5641.489999999998,
      "Total Amount": 5641.489999999998,
      Customers: 130,
      Transactions: 198,
      Returns: 6.84,
      Discounts: 393.5799999999998,
    },
    {
      day: "2023-09-08",
      datekey: 20230908,
      value: 3829.509999999999,
      "Total Amount": 3829.509999999999,
      Customers: 108,
      Transactions: 168,
      Returns: 10.89,
      Discounts: 207.3499999999999,
    },
    {
      day: "2023-09-07",
      datekey: 20230907,
      value: 4251.16,
      "Total Amount": 4251.16,
      Customers: 76,
      Transactions: 142,
      Returns: 55.2,
      Discounts: 428.48,
    },
    {
      day: "2023-09-06",
      datekey: 20230906,
      value: 4674.949999999997,
      "Total Amount": 4674.949999999997,
      Customers: 109,
      Transactions: 164,
      Returns: 8.99,
      Discounts: 262.02,
    },
    {
      day: "2023-09-05",
      datekey: 20230905,
      value: 3257.55,
      "Total Amount": 3257.55,
      Customers: 74,
      Transactions: 127,
      Returns: 15.6,
      Discounts: 180.63,
    },
    {
      day: "2023-09-04",
      datekey: 20230904,
      value: 4372.679999999999,
      "Total Amount": 4372.679999999999,
      Customers: 87,
      Transactions: 165,
      Returns: 0,
      Discounts: 300.66,
    },
    {
      day: "2023-09-03",
      datekey: 20230903,
      value: 5241.799999999999,
      "Total Amount": 5241.799999999999,
      Customers: 117,
      Transactions: 223,
      Returns: 21.79,
      Discounts: 295.9400000000001,
    },
    {
      day: "2023-09-02",
      datekey: 20230902,
      value: 5211.760000000002,
      "Total Amount": 5211.760000000002,
      Customers: 128,
      Transactions: 228,
      Returns: 1.83,
      Discounts: 314.8199999999999,
    },
    {
      day: "2023-09-01",
      datekey: 20230901,
      value: 5547.919999999995,
      "Total Amount": 5547.919999999995,
      Customers: 104,
      Transactions: 200,
      Returns: 4.2,
      Discounts: 482.4300000000002,
    },
    {
      day: "2023-08-31",
      datekey: 20230831,
      value: 4056.719999999998,
      "Total Amount": 4056.719999999998,
      Customers: 105,
      Transactions: 184,
      Returns: 32.01000000000001,
      Discounts: 252.52,
    },
    {
      day: "2023-08-30",
      datekey: 20230830,
      value: 4547.349999999997,
      "Total Amount": 4547.349999999997,
      Customers: 94,
      Transactions: 162,
      Returns: 0,
      Discounts: 278.19,
    },
    {
      day: "2023-08-29",
      datekey: 20230829,
      value: 3611.87,
      "Total Amount": 3611.87,
      Customers: 86,
      Transactions: 152,
      Returns: 0,
      Discounts: 193.95,
    },
    {
      day: "2023-08-28",
      datekey: 20230828,
      value: 3586.529999999999,
      "Total Amount": 3586.529999999999,
      Customers: 89,
      Transactions: 135,
      Returns: 0,
      Discounts: 282.68,
    },
    {
      day: "2023-08-27",
      datekey: 20230827,
      value: 5989.529999999997,
      "Total Amount": 5989.529999999997,
      Customers: 118,
      Transactions: 216,
      Returns: 16.87,
      Discounts: 382.1700000000001,
    },
    {
      day: "2023-08-26",
      datekey: 20230826,
      value: 5328.859999999998,
      "Total Amount": 5328.859999999998,
      Customers: 106,
      Transactions: 209,
      Returns: 15.71,
      Discounts: 296.3199999999999,
    },
    {
      day: "2023-08-25",
      datekey: 20230825,
      value: 3683.539999999998,
      "Total Amount": 3683.539999999998,
      Customers: 81,
      Transactions: 166,
      Returns: 1.75,
      Discounts: 278.34,
    },
    {
      day: "2023-08-24",
      datekey: 20230824,
      value: 4299.569999999998,
      "Total Amount": 4299.569999999998,
      Customers: 78,
      Transactions: 162,
      Returns: 26.5,
      Discounts: 336.91,
    },
    {
      day: "2023-08-23",
      datekey: 20230823,
      value: 3149.379999999999,
      "Total Amount": 3149.379999999999,
      Customers: 69,
      Transactions: 142,
      Returns: 2.75,
      Discounts: 158.89,
    },
    {
      day: "2023-08-22",
      datekey: 20230822,
      value: 3807.49,
      "Total Amount": 3807.49,
      Customers: 85,
      Transactions: 143,
      Returns: 4.39,
      Discounts: 325.9299999999997,
    },
    {
      day: "2023-08-21",
      datekey: 20230821,
      value: 2442.409999999999,
      "Total Amount": 2442.409999999999,
      Customers: 70,
      Transactions: 120,
      Returns: 33.75,
      Discounts: 114.43,
    },
    {
      day: "2023-08-20",
      datekey: 20230820,
      value: 4047.779999999998,
      "Total Amount": 4047.779999999998,
      Customers: 108,
      Transactions: 207,
      Returns: 21.75,
      Discounts: 177.1799999999999,
    },
    {
      day: "2023-08-19",
      datekey: 20230819,
      value: 6210.199999999999,
      "Total Amount": 6210.199999999999,
      Customers: 140,
      Transactions: 240,
      Returns: 9.54,
      Discounts: 270.4399999999999,
    },
    {
      day: "2023-08-18",
      datekey: 20230818,
      value: 4860.36,
      "Total Amount": 4860.36,
      Customers: 111,
      Transactions: 193,
      Returns: 53.64999999999999,
      Discounts: 192.21,
    },
    {
      day: "2023-08-17",
      datekey: 20230817,
      value: 4163.79,
      "Total Amount": 4163.79,
      Customers: 109,
      Transactions: 166,
      Returns: 31.97,
      Discounts: 151.79,
    },
    {
      day: "2023-08-16",
      datekey: 20230816,
      value: 3763.429999999997,
      "Total Amount": 3763.429999999997,
      Customers: 94,
      Transactions: 149,
      Returns: 0,
      Discounts: 175.87,
    },
    {
      day: "2023-08-15",
      datekey: 20230815,
      value: 5585.919999999994,
      "Total Amount": 5585.919999999994,
      Customers: 100,
      Transactions: 166,
      Returns: 20,
      Discounts: 187.78,
    },
    {
      day: "2023-08-14",
      datekey: 20230814,
      value: 2730.439999999999,
      "Total Amount": 2730.439999999999,
      Customers: 71,
      Transactions: 145,
      Returns: 15.15,
      Discounts: 102.89,
    },
    {
      day: "2023-08-13",
      datekey: 20230813,
      value: 5784.3,
      "Total Amount": 5784.3,
      Customers: 149,
      Transactions: 238,
      Returns: 18.22,
      Discounts: 265.6900000000001,
    },
    {
      day: "2023-08-12",
      datekey: 20230812,
      value: 5944.909999999997,
      "Total Amount": 5944.909999999997,
      Customers: 129,
      Transactions: 221,
      Returns: 1.26,
      Discounts: 246.9600000000001,
    },
    {
      day: "2023-08-11",
      datekey: 20230811,
      value: 4453.06,
      "Total Amount": 4453.06,
      Customers: 102,
      Transactions: 168,
      Returns: 8.6,
      Discounts: 184.39,
    },
    {
      day: "2023-08-10",
      datekey: 20230810,
      value: 3826.110000000001,
      "Total Amount": 3826.110000000001,
      Customers: 72,
      Transactions: 129,
      Returns: 7.25,
      Discounts: 121.51,
    },
    {
      day: "2023-08-09",
      datekey: 20230809,
      value: 3019.710000000001,
      "Total Amount": 3019.710000000001,
      Customers: 74,
      Transactions: 140,
      Returns: 15.69,
      Discounts: 113.43,
    },
    {
      day: "2023-08-08",
      datekey: 20230808,
      value: 3280.809999999999,
      "Total Amount": 3280.809999999999,
      Customers: 79,
      Transactions: 131,
      Returns: 0,
      Discounts: 128.76,
    },
    {
      day: "2023-08-07",
      datekey: 20230807,
      value: 3639.169999999996,
      "Total Amount": 3639.169999999996,
      Customers: 88,
      Transactions: 147,
      Returns: 2,
      Discounts: 174.75,
    },
    {
      day: "2023-08-06",
      datekey: 20230806,
      value: 5567.559999999994,
      "Total Amount": 5567.559999999994,
      Customers: 113,
      Transactions: 213,
      Returns: 27.86,
      Discounts: 203.5299999999999,
    },
    {
      day: "2023-08-05",
      datekey: 20230805,
      value: 5866.569999999998,
      "Total Amount": 5866.569999999998,
      Customers: 121,
      Transactions: 198,
      Returns: 3.09,
      Discounts: 253.1599999999999,
    },
    {
      day: "2023-08-04",
      datekey: 20230804,
      value: 4459.219999999998,
      "Total Amount": 4459.219999999998,
      Customers: 88,
      Transactions: 144,
      Returns: 18.26,
      Discounts: 182.17,
    },
    {
      day: "2023-08-03",
      datekey: 20230803,
      value: 3314.409999999999,
      "Total Amount": 3314.409999999999,
      Customers: 78,
      Transactions: 143,
      Returns: 5.5,
      Discounts: 124.1599999999999,
    },
    {
      day: "2023-08-02",
      datekey: 20230802,
      value: 3849.409999999999,
      "Total Amount": 3849.409999999999,
      Customers: 82,
      Transactions: 157,
      Returns: 40,
      Discounts: 172.57,
    },
    {
      day: "2023-08-01",
      datekey: 20230801,
      value: 2828.97,
      "Total Amount": 2828.97,
      Customers: 65,
      Transactions: 122,
      Returns: 2.13,
      Discounts: 125.66,
    },
    {
      day: "2023-07-31",
      datekey: 20230731,
      value: 3833.71,
      "Total Amount": 3833.71,
      Customers: 91,
      Transactions: 153,
      Returns: 3.6,
      Discounts: 168.09,
    },
    {
      day: "2023-07-30",
      datekey: 20230730,
      value: 4634.819999999997,
      "Total Amount": 4634.819999999997,
      Customers: 105,
      Transactions: 191,
      Returns: 0,
      Discounts: 172.21,
    },
    {
      day: "2023-07-29",
      datekey: 20230729,
      value: 4856.239999999999,
      "Total Amount": 4856.239999999999,
      Customers: 95,
      Transactions: 172,
      Returns: 0,
      Discounts: 210.7699999999999,
    },
    {
      day: "2023-07-28",
      datekey: 20230728,
      value: 4381.639999999999,
      "Total Amount": 4381.639999999999,
      Customers: 84,
      Transactions: 161,
      Returns: 4.6,
      Discounts: 134.3200000000001,
    },
    {
      day: "2023-07-27",
      datekey: 20230727,
      value: 3876.869999999998,
      "Total Amount": 3876.869999999998,
      Customers: 72,
      Transactions: 129,
      Returns: 0,
      Discounts: 141.9400000000001,
    },
    {
      day: "2023-07-26",
      datekey: 20230726,
      value: 4206.800000000001,
      "Total Amount": 4206.800000000001,
      Customers: 74,
      Transactions: 162,
      Returns: 8.899999999999999,
      Discounts: 120.09,
    },
    {
      day: "2023-07-25",
      datekey: 20230725,
      value: 4431.549999999999,
      "Total Amount": 4431.549999999999,
      Customers: 75,
      Transactions: 145,
      Returns: 8.2,
      Discounts: 157.5,
    },
    {
      day: "2023-07-24",
      datekey: 20230724,
      value: 2962.17,
      "Total Amount": 2962.17,
      Customers: 56,
      Transactions: 130,
      Returns: 2.8,
      Discounts: 114.4600000000001,
    },
    {
      day: "2023-07-23",
      datekey: 20230723,
      value: 6559.439999999991,
      "Total Amount": 6559.439999999991,
      Customers: 89,
      Transactions: 221,
      Returns: 3.9,
      Discounts: 162.7100000000001,
    },
    {
      day: "2023-07-22",
      datekey: 20230722,
      value: 6459.409999999994,
      "Total Amount": 6459.409999999994,
      Customers: 100,
      Transactions: 230,
      Returns: 127.3,
      Discounts: 172.1,
    },
    {
      day: "2023-07-21",
      datekey: 20230721,
      value: 10745.63999999998,
      "Total Amount": 10745.63999999998,
      Customers: 93,
      Transactions: 299,
      Returns: 75.47999999999999,
      Discounts: 230.75,
    },
    {
      day: "2023-07-20",
      datekey: 20230720,
      value: 4280.5,
      "Total Amount": 4280.5,
      Customers: 78,
      Transactions: 159,
      Returns: 2.5,
      Discounts: 129.16,
    },
    {
      day: "2023-07-19",
      datekey: 20230719,
      value: 2465.48,
      "Total Amount": 2465.48,
      Customers: 82,
      Transactions: 117,
      Returns: 19.43,
      Discounts: 118.6,
    },
    {
      day: "2023-07-18",
      datekey: 20230718,
      value: 3209.329999999999,
      "Total Amount": 3209.329999999999,
      Customers: 89,
      Transactions: 121,
      Returns: 0,
      Discounts: 141.84,
    },
    {
      day: "2023-07-17",
      datekey: 20230717,
      value: 2928.899999999998,
      "Total Amount": 2928.899999999998,
      Customers: 74,
      Transactions: 137,
      Returns: 0,
      Discounts: 121.09,
    },
    {
      day: "2023-07-16",
      datekey: 20230716,
      value: 4061.159999999999,
      "Total Amount": 4061.159999999999,
      Customers: 103,
      Transactions: 185,
      Returns: 0,
      Discounts: 195.08,
    },
    {
      day: "2023-07-15",
      datekey: 20230715,
      value: 3978.57,
      "Total Amount": 3978.57,
      Customers: 112,
      Transactions: 202,
      Returns: 42.85,
      Discounts: 180.7400000000001,
    },
    {
      day: "2023-07-14",
      datekey: 20230714,
      value: 4370.009999999999,
      "Total Amount": 4370.009999999999,
      Customers: 92,
      Transactions: 167,
      Returns: 0,
      Discounts: 144.41,
    },
    {
      day: "2023-07-13",
      datekey: 20230713,
      value: 4529.549999999995,
      "Total Amount": 4529.549999999995,
      Customers: 72,
      Transactions: 139,
      Returns: 11.57,
      Discounts: 127.13,
    },
    {
      day: "2023-07-12",
      datekey: 20230712,
      value: 4524.459999999999,
      "Total Amount": 4524.459999999999,
      Customers: 78,
      Transactions: 153,
      Returns: 8.6,
      Discounts: 122.22,
    },
    {
      day: "2023-07-11",
      datekey: 20230711,
      value: 2631.19,
      "Total Amount": 2631.19,
      Customers: 49,
      Transactions: 112,
      Returns: 0,
      Discounts: 82.06000000000004,
    },
    {
      day: "2023-07-10",
      datekey: 20230710,
      value: 3567.189999999999,
      "Total Amount": 3567.189999999999,
      Customers: 82,
      Transactions: 171,
      Returns: 4.17,
      Discounts: 132.27,
    },
    {
      day: "2023-07-09",
      datekey: 20230709,
      value: 4770.229999999998,
      "Total Amount": 4770.229999999998,
      Customers: 120,
      Transactions: 190,
      Returns: 34.61,
      Discounts: 180.33,
    },
    {
      day: "2023-07-08",
      datekey: 20230708,
      value: 4187.799999999998,
      "Total Amount": 4187.799999999998,
      Customers: 90,
      Transactions: 165,
      Returns: 2.32,
      Discounts: 161.95,
    },
    {
      day: "2023-07-07",
      datekey: 20230707,
      value: 3964.969999999997,
      "Total Amount": 3964.969999999997,
      Customers: 101,
      Transactions: 175,
      Returns: 6.34,
      Discounts: 189.6900000000001,
    },
    {
      day: "2023-07-06",
      datekey: 20230706,
      value: 2709.08,
      "Total Amount": 2709.08,
      Customers: 58,
      Transactions: 125,
      Returns: 0,
      Discounts: 119.44,
    },
    {
      day: "2023-07-05",
      datekey: 20230705,
      value: 2614.17,
      "Total Amount": 2614.17,
      Customers: 60,
      Transactions: 127,
      Returns: 6.199999999999999,
      Discounts: 89.95,
    },
    {
      day: "2023-07-04",
      datekey: 20230704,
      value: 2277.230000000001,
      "Total Amount": 2277.230000000001,
      Customers: 42,
      Transactions: 96,
      Returns: 0,
      Discounts: 68.48999999999997,
    },
    {
      day: "2023-07-03",
      datekey: 20230703,
      value: 3050.450000000001,
      "Total Amount": 3050.450000000001,
      Customers: 78,
      Transactions: 144,
      Returns: 1.78,
      Discounts: 132.68,
    },
    {
      day: "2023-07-02",
      datekey: 20230702,
      value: 4811.309999999996,
      "Total Amount": 4811.309999999996,
      Customers: 92,
      Transactions: 188,
      Returns: 6.71,
      Discounts: 157.84,
    },
    {
      day: "2023-07-01",
      datekey: 20230701,
      value: 3704.899999999998,
      "Total Amount": 3704.899999999998,
      Customers: 88,
      Transactions: 158,
      Returns: 9.77,
      Discounts: 139.15,
    },
    {
      day: "2023-06-30",
      datekey: 20230630,
      value: 3208.599999999998,
      "Total Amount": 3208.599999999998,
      Customers: 68,
      Transactions: 125,
      Returns: 12.28,
      Discounts: 96.27,
    },
    {
      day: "2023-06-29",
      datekey: 20230629,
      value: 3932.549999999998,
      "Total Amount": 3932.549999999998,
      Customers: 81,
      Transactions: 150,
      Returns: 12.7,
      Discounts: 127.56,
    },
    {
      day: "2023-06-28",
      datekey: 20230628,
      value: 2466.320000000001,
      "Total Amount": 2466.320000000001,
      Customers: 60,
      Transactions: 109,
      Returns: 44.14,
      Discounts: 101.42,
    },
    {
      day: "2023-06-27",
      datekey: 20230627,
      value: 3708.779999999999,
      "Total Amount": 3708.779999999999,
      Customers: 88,
      Transactions: 148,
      Returns: 4.5,
      Discounts: 227.79,
    },
    {
      day: "2023-06-26",
      datekey: 20230626,
      value: 3782.069999999997,
      "Total Amount": 3782.069999999997,
      Customers: 84,
      Transactions: 127,
      Returns: 14,
      Discounts: 207.21,
    },
    {
      day: "2023-06-25",
      datekey: 20230625,
      value: 4529.339999999997,
      "Total Amount": 4529.339999999997,
      Customers: 103,
      Transactions: 183,
      Returns: 28,
      Discounts: 191.62,
    },
    {
      day: "2023-06-24",
      datekey: 20230624,
      value: 4764.199999999996,
      "Total Amount": 4764.199999999996,
      Customers: 105,
      Transactions: 173,
      Returns: 63.83,
      Discounts: 201.55,
    },
    {
      day: "2023-06-23",
      datekey: 20230623,
      value: 3453.05,
      "Total Amount": 3453.05,
      Customers: 78,
      Transactions: 134,
      Returns: 0,
      Discounts: 116.86,
    },
    {
      day: "2023-06-22",
      datekey: 20230622,
      value: 3662.83,
      "Total Amount": 3662.83,
      Customers: 83,
      Transactions: 134,
      Returns: 0.5,
      Discounts: 163.35,
    },
    {
      day: "2023-06-21",
      datekey: 20230621,
      value: 2800.909999999999,
      "Total Amount": 2800.909999999999,
      Customers: 73,
      Transactions: 112,
      Returns: 2.31,
      Discounts: 115.54,
    },
    {
      day: "2023-06-20",
      datekey: 20230620,
      value: 2267.46,
      "Total Amount": 2267.46,
      Customers: 70,
      Transactions: 126,
      Returns: 23.66,
      Discounts: 116.14,
    },
    {
      day: "2023-06-19",
      datekey: 20230619,
      value: 2550.679999999999,
      "Total Amount": 2550.679999999999,
      Customers: 71,
      Transactions: 120,
      Returns: 0,
      Discounts: 99.90000000000002,
    },
    {
      day: "2023-06-18",
      datekey: 20230618,
      value: 4191.85,
      "Total Amount": 4191.85,
      Customers: 114,
      Transactions: 162,
      Returns: 1.95,
      Discounts: 180.3099999999999,
    },
    {
      day: "2023-06-17",
      datekey: 20230617,
      value: 4478.489999999996,
      "Total Amount": 4478.489999999996,
      Customers: 91,
      Transactions: 170,
      Returns: 42.01,
      Discounts: 177.7600000000001,
    },
    {
      day: "2023-06-16",
      datekey: 20230616,
      value: 4078.439999999998,
      "Total Amount": 4078.439999999998,
      Customers: 89,
      Transactions: 151,
      Returns: 20.95,
      Discounts: 169.2,
    },
    {
      day: "2023-06-15",
      datekey: 20230615,
      value: 3249,
      "Total Amount": 3249,
      Customers: 76,
      Transactions: 135,
      Returns: 0,
      Discounts: 136.15,
    },
    {
      day: "2023-06-14",
      datekey: 20230614,
      value: 2774.52,
      "Total Amount": 2774.52,
      Customers: 51,
      Transactions: 114,
      Returns: 3,
      Discounts: 109.45,
    },
    {
      day: "2023-06-13",
      datekey: 20230613,
      value: 3206.809999999999,
      "Total Amount": 3206.809999999999,
      Customers: 65,
      Transactions: 116,
      Returns: 11.15,
      Discounts: 114.58,
    },
    {
      day: "2023-06-12",
      datekey: 20230612,
      value: 2797.570000000001,
      "Total Amount": 2797.570000000001,
      Customers: 71,
      Transactions: 120,
      Returns: 0.55,
      Discounts: 133.41,
    },
    {
      day: "2023-06-11",
      datekey: 20230611,
      value: 3668.800000000002,
      "Total Amount": 3668.800000000002,
      Customers: 120,
      Transactions: 168,
      Returns: 9.39,
      Discounts: 176.2900000000001,
    },
    {
      day: "2023-06-10",
      datekey: 20230610,
      value: 4108.459999999999,
      "Total Amount": 4108.459999999999,
      Customers: 82,
      Transactions: 185,
      Returns: 4.45,
      Discounts: 141.57,
    },
    {
      day: "2023-06-09",
      datekey: 20230609,
      value: 3521.199999999997,
      "Total Amount": 3521.199999999997,
      Customers: 71,
      Transactions: 156,
      Returns: 4.97,
      Discounts: 133.25,
    },
    {
      day: "2023-06-08",
      datekey: 20230608,
      value: 3338.510000000001,
      "Total Amount": 3338.510000000001,
      Customers: 61,
      Transactions: 138,
      Returns: 0,
      Discounts: 121.36,
    },
    {
      day: "2023-06-07",
      datekey: 20230607,
      value: 3084.560000000001,
      "Total Amount": 3084.560000000001,
      Customers: 85,
      Transactions: 145,
      Returns: 27.71,
      Discounts: 112.15,
    },
    {
      day: "2023-06-06",
      datekey: 20230606,
      value: 3218.469999999999,
      "Total Amount": 3218.469999999999,
      Customers: 63,
      Transactions: 131,
      Returns: 2.75,
      Discounts: 154.08,
    },
    {
      day: "2023-06-05",
      datekey: 20230605,
      value: 2839.48,
      "Total Amount": 2839.48,
      Customers: 59,
      Transactions: 121,
      Returns: 8.1,
      Discounts: 107.31,
    },
    {
      day: "2023-06-04",
      datekey: 20230604,
      value: 3971.909999999999,
      "Total Amount": 3971.909999999999,
      Customers: 86,
      Transactions: 168,
      Returns: 35.17,
      Discounts: 140.64,
    },
    {
      day: "2023-06-03",
      datekey: 20230603,
      value: 4271.119999999998,
      "Total Amount": 4271.119999999998,
      Customers: 92,
      Transactions: 159,
      Returns: 8.25,
      Discounts: 156.7000000000001,
    },
    {
      day: "2023-06-02",
      datekey: 20230602,
      value: 3756.930000000001,
      "Total Amount": 3756.930000000001,
      Customers: 75,
      Transactions: 144,
      Returns: 7.5,
      Discounts: 130.38,
    },
    {
      day: "2023-06-01",
      datekey: 20230601,
      value: 2991.319999999997,
      "Total Amount": 2991.319999999997,
      Customers: 65,
      Transactions: 131,
      Returns: 19.66,
      Discounts: 111.06,
    },
    {
      day: "2023-05-31",
      datekey: 20230531,
      value: 3823.969999999999,
      "Total Amount": 3823.969999999999,
      Customers: 77,
      Transactions: 142,
      Returns: 6.109999999999999,
      Discounts: 176.0599999999999,
    },
    {
      day: "2023-05-30",
      datekey: 20230530,
      value: 2729.240000000001,
      "Total Amount": 2729.240000000001,
      Customers: 73,
      Transactions: 137,
      Returns: 0,
      Discounts: 132.27,
    },
    {
      day: "2023-05-29",
      datekey: 20230529,
      value: 2988.97,
      "Total Amount": 2988.97,
      Customers: 69,
      Transactions: 127,
      Returns: 5.2,
      Discounts: 106.78,
    },
    {
      day: "2023-05-28",
      datekey: 20230528,
      value: 4097.359999999997,
      "Total Amount": 4097.359999999997,
      Customers: 100,
      Transactions: 176,
      Returns: 0,
      Discounts: 155.57,
    },
    {
      day: "2023-05-27",
      datekey: 20230527,
      value: 4649.289999999999,
      "Total Amount": 4649.289999999999,
      Customers: 92,
      Transactions: 191,
      Returns: 18.6,
      Discounts: 194.21,
    },
    {
      day: "2023-05-26",
      datekey: 20230526,
      value: 4930.809999999999,
      "Total Amount": 4930.809999999999,
      Customers: 102,
      Transactions: 192,
      Returns: 20.79,
      Discounts: 176.0300000000001,
    },
    {
      day: "2023-05-25",
      datekey: 20230525,
      value: 4383.919999999999,
      "Total Amount": 4383.919999999999,
      Customers: 110,
      Transactions: 189,
      Returns: 16.85,
      Discounts: 188.7900000000001,
    },
    {
      day: "2023-05-24",
      datekey: 20230524,
      value: 3343.979999999998,
      "Total Amount": 3343.979999999998,
      Customers: 65,
      Transactions: 138,
      Returns: 22.08,
      Discounts: 115.21,
    },
    {
      day: "2023-05-23",
      datekey: 20230523,
      value: 3628.649999999999,
      "Total Amount": 3628.649999999999,
      Customers: 70,
      Transactions: 143,
      Returns: 1.73,
      Discounts: 129.72,
    },
    {
      day: "2023-05-22",
      datekey: 20230522,
      value: 3491.010000000001,
      "Total Amount": 3491.010000000001,
      Customers: 78,
      Transactions: 156,
      Returns: 2.5,
      Discounts: 113.63,
    },
    {
      day: "2023-05-21",
      datekey: 20230521,
      value: 7024.989999999998,
      "Total Amount": 7024.989999999998,
      Customers: 124,
      Transactions: 244,
      Returns: 4.45,
      Discounts: 262.96,
    },
    {
      day: "2023-05-20",
      datekey: 20230520,
      value: 5924.619999999994,
      "Total Amount": 5924.619999999994,
      Customers: 88,
      Transactions: 221,
      Returns: 5.7,
      Discounts: 180.6500000000001,
    },
    {
      day: "2023-05-19",
      datekey: 20230519,
      value: 4862.669999999997,
      "Total Amount": 4862.669999999997,
      Customers: 85,
      Transactions: 192,
      Returns: 21.17,
      Discounts: 145.25,
    },
    {
      day: "2023-05-18",
      datekey: 20230518,
      value: 3762.08,
      "Total Amount": 3762.08,
      Customers: 55,
      Transactions: 161,
      Returns: 0,
      Discounts: 113.33,
    },
    {
      day: "2023-05-17",
      datekey: 20230517,
      value: 4080.979999999998,
      "Total Amount": 4080.979999999998,
      Customers: 75,
      Transactions: 166,
      Returns: 21.08,
      Discounts: 129.21,
    },
    {
      day: "2023-05-16",
      datekey: 20230516,
      value: 3736.990000000001,
      "Total Amount": 3736.990000000001,
      Customers: 68,
      Transactions: 148,
      Returns: 26.65,
      Discounts: 129.42,
    },
    {
      day: "2023-05-15",
      datekey: 20230515,
      value: 2855.99,
      "Total Amount": 2855.99,
      Customers: 44,
      Transactions: 132,
      Returns: 5.38,
      Discounts: 85.40999999999998,
    },
    {
      day: "2023-05-14",
      datekey: 20230514,
      value: 5877.829999999996,
      "Total Amount": 5877.829999999996,
      Customers: 105,
      Transactions: 213,
      Returns: 3.5,
      Discounts: 205.5299999999999,
    },
    {
      day: "2023-05-13",
      datekey: 20230513,
      value: 5877.969999999996,
      "Total Amount": 5877.969999999996,
      Customers: 91,
      Transactions: 219,
      Returns: 65.33,
      Discounts: 184.8899999999999,
    },
    {
      day: "2023-05-12",
      datekey: 20230512,
      value: 4890.319999999997,
      "Total Amount": 4890.319999999997,
      Customers: 86,
      Transactions: 189,
      Returns: 0,
      Discounts: 141.83,
    },
    {
      day: "2023-05-11",
      datekey: 20230511,
      value: 5270.209999999996,
      "Total Amount": 5270.209999999996,
      Customers: 72,
      Transactions: 186,
      Returns: 1.74,
      Discounts: 156.5900000000001,
    },
    {
      day: "2023-05-10",
      datekey: 20230510,
      value: 4271.380000000001,
      "Total Amount": 4271.380000000001,
      Customers: 81,
      Transactions: 165,
      Returns: 4.09,
      Discounts: 121.86,
    },
    {
      day: "2023-05-09",
      datekey: 20230509,
      value: 4091.059999999998,
      "Total Amount": 4091.059999999998,
      Customers: 83,
      Transactions: 152,
      Returns: 0,
      Discounts: 148.0700000000001,
    },
    {
      day: "2023-05-08",
      datekey: 20230508,
      value: 3811.939999999998,
      "Total Amount": 3811.939999999998,
      Customers: 94,
      Transactions: 166,
      Returns: 7.45,
      Discounts: 146.5,
    },
    {
      day: "2023-05-07",
      datekey: 20230507,
      value: 5688.579999999996,
      "Total Amount": 5688.579999999996,
      Customers: 107,
      Transactions: 235,
      Returns: 78.25,
      Discounts: 199.04,
    },
    {
      day: "2023-05-06",
      datekey: 20230506,
      value: 6266.209999999996,
      "Total Amount": 6266.209999999996,
      Customers: 113,
      Transactions: 243,
      Returns: 37.57,
      Discounts: 265.8200000000001,
    },
    {
      day: "2023-05-05",
      datekey: 20230505,
      value: 5284.06,
      "Total Amount": 5284.06,
      Customers: 108,
      Transactions: 219,
      Returns: 44.14,
      Discounts: 210.8899999999999,
    },
    {
      day: "2023-05-04",
      datekey: 20230504,
      value: 4714.389999999996,
      "Total Amount": 4714.389999999996,
      Customers: 93,
      Transactions: 197,
      Returns: 13.6,
      Discounts: 180.13,
    },
    {
      day: "2023-05-03",
      datekey: 20230503,
      value: 3873.739999999998,
      "Total Amount": 3873.739999999998,
      Customers: 86,
      Transactions: 155,
      Returns: 0,
      Discounts: 153.14,
    },
    {
      day: "2023-05-02",
      datekey: 20230502,
      value: 4111.929999999999,
      "Total Amount": 4111.929999999999,
      Customers: 82,
      Transactions: 139,
      Returns: 0,
      Discounts: 187.31,
    },
    {
      day: "2023-05-01",
      datekey: 20230501,
      value: 2928.460000000001,
      "Total Amount": 2928.460000000001,
      Customers: 58,
      Transactions: 127,
      Returns: 6.6,
      Discounts: 91.94000000000004,
    },
    {
      day: "2023-04-30",
      datekey: 20230430,
      value: 5990.809999999998,
      "Total Amount": 5990.809999999998,
      Customers: 107,
      Transactions: 222,
      Returns: 5.46,
      Discounts: 207.06,
    },
    {
      day: "2023-04-29",
      datekey: 20230429,
      value: 5565.259999999997,
      "Total Amount": 5565.259999999997,
      Customers: 106,
      Transactions: 234,
      Returns: 33.05,
      Discounts: 181.3900000000001,
    },
    {
      day: "2023-04-28",
      datekey: 20230428,
      value: 4553.809999999999,
      "Total Amount": 4553.809999999999,
      Customers: 101,
      Transactions: 174,
      Returns: 8.2,
      Discounts: 216.6000000000001,
    },
    {
      day: "2023-04-27",
      datekey: 20230427,
      value: 4367.629999999997,
      "Total Amount": 4367.629999999997,
      Customers: 93,
      Transactions: 179,
      Returns: 97.17,
      Discounts: 191.06,
    },
    {
      day: "2023-04-26",
      datekey: 20230426,
      value: 3767.429999999999,
      "Total Amount": 3767.429999999999,
      Customers: 84,
      Transactions: 151,
      Returns: 2.45,
      Discounts: 136.4,
    },
    {
      day: "2023-04-25",
      datekey: 20230425,
      value: 3850.599999999998,
      "Total Amount": 3850.599999999998,
      Customers: 72,
      Transactions: 154,
      Returns: 4.26,
      Discounts: 148.7,
    },
    {
      day: "2023-04-24",
      datekey: 20230424,
      value: 3131.729999999998,
      "Total Amount": 3131.729999999998,
      Customers: 81,
      Transactions: 151,
      Returns: 39.58000000000001,
      Discounts: 143.09,
    },
    {
      day: "2023-04-23",
      datekey: 20230423,
      value: 5596.109999999998,
      "Total Amount": 5596.109999999998,
      Customers: 103,
      Transactions: 217,
      Returns: 20.9,
      Discounts: 211.6299999999998,
    },
    {
      day: "2023-04-22",
      datekey: 20230422,
      value: 6564.269999999993,
      "Total Amount": 6564.269999999993,
      Customers: 101,
      Transactions: 245,
      Returns: 9,
      Discounts: 207.66,
    },
    {
      day: "2023-04-21",
      datekey: 20230421,
      value: 4374.269999999998,
      "Total Amount": 4374.269999999998,
      Customers: 75,
      Transactions: 189,
      Returns: 0,
      Discounts: 173.45,
    },
    {
      day: "2023-04-20",
      datekey: 20230420,
      value: 6677.539999999995,
      "Total Amount": 6677.539999999995,
      Customers: 110,
      Transactions: 223,
      Returns: 10.8,
      Discounts: 283.6,
    },
    {
      day: "2023-04-19",
      datekey: 20230419,
      value: 4633.449999999997,
      "Total Amount": 4633.449999999997,
      Customers: 107,
      Transactions: 176,
      Returns: 1.85,
      Discounts: 199.59,
    },
    {
      day: "2023-04-18",
      datekey: 20230418,
      value: 3883.580000000002,
      "Total Amount": 3883.580000000002,
      Customers: 87,
      Transactions: 158,
      Returns: 6.17,
      Discounts: 142.25,
    },
    {
      day: "2023-04-17",
      datekey: 20230417,
      value: 3839.339999999999,
      "Total Amount": 3839.339999999999,
      Customers: 93,
      Transactions: 163,
      Returns: 0,
      Discounts: 164.89,
    },
    {
      day: "2023-04-16",
      datekey: 20230416,
      value: 6665.929999999997,
      "Total Amount": 6665.929999999997,
      Customers: 121,
      Transactions: 263,
      Returns: 12.69,
      Discounts: 222.09,
    },
    {
      day: "2023-04-15",
      datekey: 20230415,
      value: 5853.330000000001,
      "Total Amount": 5853.330000000001,
      Customers: 105,
      Transactions: 217,
      Returns: 42.93,
      Discounts: 282.53,
    },
    {
      day: "2023-04-14",
      datekey: 20230414,
      value: 5578.959999999996,
      "Total Amount": 5578.959999999996,
      Customers: 108,
      Transactions: 205,
      Returns: 21.91,
      Discounts: 214.8500000000001,
    },
    {
      day: "2023-04-13",
      datekey: 20230413,
      value: 3490.949999999999,
      "Total Amount": 3490.949999999999,
      Customers: 93,
      Transactions: 158,
      Returns: 1.55,
      Discounts: 139.15,
    },
    {
      day: "2023-04-12",
      datekey: 20230412,
      value: 3576.09,
      "Total Amount": 3576.09,
      Customers: 86,
      Transactions: 153,
      Returns: 14.63,
      Discounts: 124.34,
    },
    {
      day: "2023-04-11",
      datekey: 20230411,
      value: 4307.830000000001,
      "Total Amount": 4307.830000000001,
      Customers: 89,
      Transactions: 167,
      Returns: 7.5,
      Discounts: 172.1,
    },
    {
      day: "2023-04-10",
      datekey: 20230410,
      value: 3312.819999999999,
      "Total Amount": 3312.819999999999,
      Customers: 93,
      Transactions: 170,
      Returns: 9.1,
      Discounts: 140.63,
    },
    {
      day: "2023-04-09",
      datekey: 20230409,
      value: 6652.359999999993,
      "Total Amount": 6652.359999999993,
      Customers: 133,
      Transactions: 266,
      Returns: 0,
      Discounts: 228.62,
    },
    {
      day: "2023-04-08",
      datekey: 20230408,
      value: 7298.299999999997,
      "Total Amount": 7298.299999999997,
      Customers: 151,
      Transactions: 260,
      Returns: 18.95,
      Discounts: 339.1999999999999,
    },
    {
      day: "2023-04-07",
      datekey: 20230407,
      value: 5605.299999999998,
      "Total Amount": 5605.299999999998,
      Customers: 112,
      Transactions: 226,
      Returns: 14.45,
      Discounts: 229.39,
    },
    {
      day: "2023-04-06",
      datekey: 20230406,
      value: 4845.279999999995,
      "Total Amount": 4845.279999999995,
      Customers: 96,
      Transactions: 186,
      Returns: 20.15,
      Discounts: 203.25,
    },
    {
      day: "2023-04-05",
      datekey: 20230405,
      value: 3239.049999999998,
      "Total Amount": 3239.049999999998,
      Customers: 78,
      Transactions: 144,
      Returns: 9.19,
      Discounts: 131.23,
    },
    {
      day: "2023-04-04",
      datekey: 20230404,
      value: 3963.249999999995,
      "Total Amount": 3963.249999999995,
      Customers: 63,
      Transactions: 186,
      Returns: 2.53,
      Discounts: 101.05,
    },
    {
      day: "2023-04-03",
      datekey: 20230403,
      value: 3181.239999999998,
      "Total Amount": 3181.239999999998,
      Customers: 79,
      Transactions: 152,
      Returns: 31.64,
      Discounts: 126.14,
    },
    {
      day: "2023-04-02",
      datekey: 20230402,
      value: 6543.489999999996,
      "Total Amount": 6543.489999999996,
      Customers: 123,
      Transactions: 261,
      Returns: 31.81,
      Discounts: 253.6800000000001,
    },
    {
      day: "2023-04-01",
      datekey: 20230401,
      value: 5809.589999999997,
      "Total Amount": 5809.589999999997,
      Customers: 98,
      Transactions: 225,
      Returns: 17.85,
      Discounts: 223.2400000000001,
    },
    {
      day: "2023-03-31",
      datekey: 20230331,
      value: 4680.469999999997,
      "Total Amount": 4680.469999999997,
      Customers: 118,
      Transactions: 182,
      Returns: 5.75,
      Discounts: 222.51,
    },
    {
      day: "2023-03-30",
      datekey: 20230330,
      value: 4222.91,
      "Total Amount": 4222.91,
      Customers: 84,
      Transactions: 176,
      Returns: 16.95,
      Discounts: 179.62,
    },
    {
      day: "2023-03-29",
      datekey: 20230329,
      value: 4195.949999999998,
      "Total Amount": 4195.949999999998,
      Customers: 86,
      Transactions: 182,
      Returns: 3.4,
      Discounts: 153.7200000000001,
    },
    {
      day: "2023-03-28",
      datekey: 20230328,
      value: 3597.6,
      "Total Amount": 3597.6,
      Customers: 84,
      Transactions: 154,
      Returns: 12.33,
      Discounts: 132.03,
    },
    {
      day: "2023-03-27",
      datekey: 20230327,
      value: 2891.669999999998,
      "Total Amount": 2891.669999999998,
      Customers: 57,
      Transactions: 131,
      Returns: 0,
      Discounts: 119.2799999999999,
    },
    {
      day: "2023-03-26",
      datekey: 20230326,
      value: 5600.459999999997,
      "Total Amount": 5600.459999999997,
      Customers: 108,
      Transactions: 223,
      Returns: 29.4,
      Discounts: 234.4100000000001,
    },
    {
      day: "2023-03-25",
      datekey: 20230325,
      value: 6258.569999999996,
      "Total Amount": 6258.569999999996,
      Customers: 117,
      Transactions: 221,
      Returns: 18.98,
      Discounts: 221.92,
    },
    {
      day: "2023-03-24",
      datekey: 20230324,
      value: 5439.779999999999,
      "Total Amount": 5439.779999999999,
      Customers: 74,
      Transactions: 217,
      Returns: 18.71,
      Discounts: 169.6000000000001,
    },
    {
      day: "2023-03-23",
      datekey: 20230323,
      value: 5484.429999999997,
      "Total Amount": 5484.429999999997,
      Customers: 56,
      Transactions: 206,
      Returns: 37.4,
      Discounts: 130.33,
    },
    {
      day: "2023-03-22",
      datekey: 20230322,
      value: 4689.949999999995,
      "Total Amount": 4689.949999999995,
      Customers: 71,
      Transactions: 161,
      Returns: 3,
      Discounts: 181.03,
    },
    {
      day: "2023-03-21",
      datekey: 20230321,
      value: 4679.429999999997,
      "Total Amount": 4679.429999999997,
      Customers: 62,
      Transactions: 230,
      Returns: 4.55,
      Discounts: 85.77,
    },
    {
      day: "2023-03-20",
      datekey: 20230320,
      value: 3942.719999999998,
      "Total Amount": 3942.719999999998,
      Customers: 50,
      Transactions: 148,
      Returns: 0,
      Discounts: 110.43,
    },
    {
      day: "2023-03-19",
      datekey: 20230319,
      value: 5344.339999999996,
      "Total Amount": 5344.339999999996,
      Customers: 64,
      Transactions: 205,
      Returns: 12.39,
      Discounts: 121.47,
    },
    {
      day: "2023-03-18",
      datekey: 20230318,
      value: 4816.479999999996,
      "Total Amount": 4816.479999999996,
      Customers: 69,
      Transactions: 212,
      Returns: 23.16,
      Discounts: 126.72,
    },
    {
      day: "2023-03-17",
      datekey: 20230317,
      value: 5005.539999999996,
      "Total Amount": 5005.539999999996,
      Customers: 54,
      Transactions: 188,
      Returns: 0,
      Discounts: 110.63,
    },
    {
      day: "2023-03-16",
      datekey: 20230316,
      value: 4824.570000000001,
      "Total Amount": 4824.570000000001,
      Customers: 64,
      Transactions: 169,
      Returns: 16,
      Discounts: 149.03,
    },
    {
      day: "2023-03-15",
      datekey: 20230315,
      value: 3953.479999999998,
      "Total Amount": 3953.479999999998,
      Customers: 47,
      Transactions: 133,
      Returns: 0,
      Discounts: 109.32,
    },
    {
      day: "2023-03-14",
      datekey: 20230314,
      value: 3883.909999999997,
      "Total Amount": 3883.909999999997,
      Customers: 47,
      Transactions: 149,
      Returns: 16.18,
      Discounts: 78.74000000000001,
    },
    {
      day: "2023-03-13",
      datekey: 20230313,
      value: 236.84,
      "Total Amount": 236.84,
      Customers: 5,
      Transactions: 10,
      Returns: 1.13,
      Discounts: 11.94,
    },
    {
      day: "2023-03-12",
      datekey: 20230312,
      value: 5082.659999999994,
      "Total Amount": 5082.659999999994,
      Customers: 47,
      Transactions: 192,
      Returns: 4.21,
      Discounts: 104.59,
    },
    {
      day: "2023-03-11",
      datekey: 20230311,
      value: 6313.419999999997,
      "Total Amount": 6313.419999999997,
      Customers: 54,
      Transactions: 221,
      Returns: 4.85,
      Discounts: 101.99,
    },
    {
      day: "2023-03-10",
      datekey: 20230310,
      value: 5266.629999999992,
      "Total Amount": 5266.629999999992,
      Customers: 50,
      Transactions: 189,
      Returns: 4.3,
      Discounts: 110.04,
    },
    {
      day: "2023-03-09",
      datekey: 20230309,
      value: 4176.239999999999,
      "Total Amount": 4176.239999999999,
      Customers: 42,
      Transactions: 131,
      Returns: 14,
      Discounts: 103.46,
    },
    {
      day: "2023-03-08",
      datekey: 20230308,
      value: 4138.600000000001,
      "Total Amount": 4138.600000000001,
      Customers: 38,
      Transactions: 154,
      Returns: 4.09,
      Discounts: 74.21000000000002,
    },
    {
      day: "2023-03-07",
      datekey: 20230307,
      value: 3453.77,
      "Total Amount": 3453.77,
      Customers: 40,
      Transactions: 146,
      Returns: 3.7,
      Discounts: 72.55,
    },
    {
      day: "2023-03-06",
      datekey: 20230306,
      value: 3897.819999999998,
      "Total Amount": 3897.819999999998,
      Customers: 41,
      Transactions: 167,
      Returns: 2.97,
      Discounts: 94.74000000000004,
    },
    {
      day: "2023-03-05",
      datekey: 20230305,
      value: 5723.079999999998,
      "Total Amount": 5723.079999999998,
      Customers: 52,
      Transactions: 232,
      Returns: 20.84,
      Discounts: 137.9900000000001,
    },
    {
      day: "2023-03-04",
      datekey: 20230304,
      value: 5882.649999999995,
      "Total Amount": 5882.649999999995,
      Customers: 50,
      Transactions: 220,
      Returns: 0,
      Discounts: 101.14,
    },
    {
      day: "2023-03-03",
      datekey: 20230303,
      value: 5387.759999999996,
      "Total Amount": 5387.759999999996,
      Customers: 61,
      Transactions: 209,
      Returns: 6,
      Discounts: 141.34,
    },
    {
      day: "2023-03-02",
      datekey: 20230302,
      value: 4530.489999999998,
      "Total Amount": 4530.489999999998,
      Customers: 60,
      Transactions: 176,
      Returns: 13.3,
      Discounts: 120.54,
    },
    {
      day: "2023-03-01",
      datekey: 20230301,
      value: 4400.32,
      "Total Amount": 4400.32,
      Customers: 49,
      Transactions: 151,
      Returns: 16.49,
      Discounts: 127.83,
    },
    {
      day: "2023-02-28",
      datekey: 20230228,
      value: 2979.399999999999,
      "Total Amount": 2979.399999999999,
      Customers: 35,
      Transactions: 133,
      Returns: 15.49,
      Discounts: 70.79,
    },
    {
      day: "2023-02-27",
      datekey: 20230227,
      value: 3338.29,
      "Total Amount": 3338.29,
      Customers: 35,
      Transactions: 147,
      Returns: 16.58,
      Discounts: 53.93999999999999,
    },
    {
      day: "2023-02-26",
      datekey: 20230226,
      value: 5473.86,
      "Total Amount": 5473.86,
      Customers: 78,
      Transactions: 230,
      Returns: 17.95,
      Discounts: 139.02,
    },
    {
      day: "2023-02-25",
      datekey: 20230225,
      value: 6760.150000000001,
      "Total Amount": 6760.150000000001,
      Customers: 78,
      Transactions: 249,
      Returns: 33.2,
      Discounts: 162.06,
    },
    {
      day: "2023-02-24",
      datekey: 20230224,
      value: 4925.569999999996,
      "Total Amount": 4925.569999999996,
      Customers: 53,
      Transactions: 189,
      Returns: 4.99,
      Discounts: 138.52,
    },
    {
      day: "2023-02-23",
      datekey: 20230223,
      value: 4789.29,
      "Total Amount": 4789.29,
      Customers: 58,
      Transactions: 150,
      Returns: 34.19,
      Discounts: 150.37,
    },
    {
      day: "2023-02-22",
      datekey: 20230222,
      value: 2527.909999999999,
      "Total Amount": 2527.909999999999,
      Customers: 51,
      Transactions: 113,
      Returns: 11.3,
      Discounts: 80.27000000000001,
    },
    {
      day: "2023-02-21",
      datekey: 20230221,
      value: 4135.479999999996,
      "Total Amount": 4135.479999999996,
      Customers: 61,
      Transactions: 158,
      Returns: 21.35,
      Discounts: 110.08,
    },
    {
      day: "2023-02-20",
      datekey: 20230220,
      value: 3786.489999999999,
      "Total Amount": 3786.489999999999,
      Customers: 53,
      Transactions: 179,
      Returns: 9.61,
      Discounts: 89.80999999999999,
    },
    {
      day: "2023-02-19",
      datekey: 20230219,
      value: 5367.639999999996,
      "Total Amount": 5367.639999999996,
      Customers: 34,
      Transactions: 205,
      Returns: 46.11,
      Discounts: 105.81,
    },
    {
      day: "2023-02-18",
      datekey: 20230218,
      value: 6609.499999999993,
      "Total Amount": 6609.499999999993,
      Customers: 38,
      Transactions: 241,
      Returns: 16.63,
      Discounts: 125.45,
    },
    {
      day: "2023-02-17",
      datekey: 20230217,
      value: 6278.299999999997,
      "Total Amount": 6278.299999999997,
      Customers: 31,
      Transactions: 204,
      Returns: 0,
      Discounts: 74.89,
    },
    {
      day: "2023-02-16",
      datekey: 20230216,
      value: 3897.809999999999,
      "Total Amount": 3897.809999999999,
      Customers: 20,
      Transactions: 135,
      Returns: 45,
      Discounts: 34.83,
    },
    {
      day: "2023-02-15",
      datekey: 20230215,
      value: 3207.759999999998,
      "Total Amount": 3207.759999999998,
      Customers: 21,
      Transactions: 138,
      Returns: 18.15,
      Discounts: 32.03,
    },
    {
      day: "2023-02-14",
      datekey: 20230214,
      value: 3555.12,
      "Total Amount": 3555.12,
      Customers: 24,
      Transactions: 126,
      Returns: 75.80999999999999,
      Discounts: 76.52000000000004,
    },
    {
      day: "2023-02-13",
      datekey: 20230213,
      value: 3145.439999999998,
      "Total Amount": 3145.439999999998,
      Customers: 18,
      Transactions: 138,
      Returns: 0,
      Discounts: 43.80000000000001,
    },
    {
      day: "2023-02-12",
      datekey: 20230212,
      value: 5418.139999999993,
      "Total Amount": 5418.139999999993,
      Customers: 24,
      Transactions: 216,
      Returns: 5.68,
      Discounts: 92.58,
    },
    {
      day: "2023-02-11",
      datekey: 20230211,
      value: 7163.269999999997,
      "Total Amount": 7163.269999999997,
      Customers: 22,
      Transactions: 240,
      Returns: 55.47,
      Discounts: 64.19,
    },
    {
      day: "2023-02-10",
      datekey: 20230210,
      value: 4638.789999999999,
      "Total Amount": 4638.789999999999,
      Customers: 17,
      Transactions: 171,
      Returns: 23.64,
      Discounts: 52.26000000000001,
    },
    {
      day: "2023-02-09",
      datekey: 20230209,
      value: 3427.090000000001,
      "Total Amount": 3427.090000000001,
      Customers: 18,
      Transactions: 132,
      Returns: 5.55,
      Discounts: 29.36,
    },
    {
      day: "2023-02-08",
      datekey: 20230208,
      value: 3232.429999999998,
      "Total Amount": 3232.429999999998,
      Customers: 13,
      Transactions: 135,
      Returns: 9,
      Discounts: 46.37,
    },
    {
      day: "2023-02-07",
      datekey: 20230207,
      value: 3516.460000000001,
      "Total Amount": 3516.460000000001,
      Customers: 17,
      Transactions: 144,
      Returns: 16.95,
      Discounts: 35.18,
    },
    {
      day: "2023-02-06",
      datekey: 20230206,
      value: 2768.29,
      "Total Amount": 2768.29,
      Customers: 18,
      Transactions: 124,
      Returns: 13.05,
      Discounts: 41.69000000000001,
    },
    {
      day: "2023-02-05",
      datekey: 20230205,
      value: 6335.499999999997,
      "Total Amount": 6335.499999999997,
      Customers: 22,
      Transactions: 233,
      Returns: 5.69,
      Discounts: 50.52,
    },
    {
      day: "2023-02-04",
      datekey: 20230204,
      value: 6161.499999999996,
      "Total Amount": 6161.499999999996,
      Customers: 16,
      Transactions: 207,
      Returns: 16.18,
      Discounts: 41.77999999999999,
    },
    {
      day: "2023-02-03",
      datekey: 20230203,
      value: 5415.709999999995,
      "Total Amount": 5415.709999999995,
      Customers: 19,
      Transactions: 183,
      Returns: 24.71,
      Discounts: 47.43000000000001,
    },
    {
      day: "2023-02-02",
      datekey: 20230202,
      value: 3938.399999999999,
      "Total Amount": 3938.399999999999,
      Customers: 16,
      Transactions: 128,
      Returns: 0,
      Discounts: 39.26,
    },
    {
      day: "2023-02-01",
      datekey: 20230201,
      value: 3744.619999999998,
      "Total Amount": 3744.619999999998,
      Customers: 8,
      Transactions: 139,
      Returns: 40.25,
      Discounts: 6.44,
    },
    {
      day: "2023-01-31",
      datekey: 20230131,
      value: 3930.529999999998,
      "Total Amount": 3930.529999999998,
      Customers: 20,
      Transactions: 146,
      Returns: 0,
      Discounts: 70.14,
    },
    {
      day: "2023-01-30",
      datekey: 20230130,
      value: 2541.249999999999,
      "Total Amount": 2541.249999999999,
      Customers: 14,
      Transactions: 111,
      Returns: 8.8,
      Discounts: 36.73,
    },
    {
      day: "2023-01-29",
      datekey: 20230129,
      value: 5802.849999999996,
      "Total Amount": 5802.849999999996,
      Customers: 14,
      Transactions: 202,
      Returns: 5,
      Discounts: 16.71,
    },
    {
      day: "2023-01-28",
      datekey: 20230128,
      value: 6441.749999999991,
      "Total Amount": 6441.749999999991,
      Customers: 23,
      Transactions: 254,
      Returns: 32.09,
      Discounts: 49.72000000000001,
    },
    {
      day: "2023-01-27",
      datekey: 20230127,
      value: 4965.729999999995,
      "Total Amount": 4965.729999999995,
      Customers: 16,
      Transactions: 177,
      Returns: 10.9,
      Discounts: 23.54,
    },
    {
      day: "2023-01-26",
      datekey: 20230126,
      value: 3228.360000000001,
      "Total Amount": 3228.360000000001,
      Customers: 11,
      Transactions: 147,
      Returns: 23.34999999999999,
      Discounts: 13.87,
    },
    {
      day: "2023-01-25",
      datekey: 20230125,
      value: 3727.979999999999,
      "Total Amount": 3727.979999999999,
      Customers: 12,
      Transactions: 141,
      Returns: 13.6,
      Discounts: 30.01,
    },
    {
      day: "2023-01-24",
      datekey: 20230124,
      value: 3188.65,
      "Total Amount": 3188.65,
      Customers: 13,
      Transactions: 103,
      Returns: 5.27,
      Discounts: 51.65,
    },
    {
      day: "2023-01-23",
      datekey: 20230123,
      value: 3211.97,
      "Total Amount": 3211.97,
      Customers: 10,
      Transactions: 110,
      Returns: 0,
      Discounts: 33.1,
    },
    {
      day: "2023-01-22",
      datekey: 20230122,
      value: 6556.449999999997,
      "Total Amount": 6556.449999999997,
      Customers: 22,
      Transactions: 236,
      Returns: 17.46,
      Discounts: 95.53999999999999,
    },
    {
      day: "2023-01-21",
      datekey: 20230121,
      value: 5930.419999999997,
      "Total Amount": 5930.419999999997,
      Customers: 16,
      Transactions: 221,
      Returns: 11.5,
      Discounts: 46.79,
    },
    {
      day: "2023-01-20",
      datekey: 20230120,
      value: 4267.009999999998,
      "Total Amount": 4267.009999999998,
      Customers: 14,
      Transactions: 157,
      Returns: 3,
      Discounts: 32.84999999999999,
    },
    {
      day: "2023-01-19",
      datekey: 20230119,
      value: 3894.759999999998,
      "Total Amount": 3894.759999999998,
      Customers: 10,
      Transactions: 149,
      Returns: 0,
      Discounts: 20.67,
    },
    {
      day: "2023-01-18",
      datekey: 20230118,
      value: 3343.839999999997,
      "Total Amount": 3343.839999999997,
      Customers: 10,
      Transactions: 140,
      Returns: 1.9,
      Discounts: 29.05,
    },
    {
      day: "2023-01-17",
      datekey: 20230117,
      value: 3220.78,
      "Total Amount": 3220.78,
      Customers: 7,
      Transactions: 132,
      Returns: 15.77,
      Discounts: 4.73,
    },
    {
      day: "2023-01-16",
      datekey: 20230116,
      value: 4866.709999999996,
      "Total Amount": 4866.709999999996,
      Customers: 7,
      Transactions: 182,
      Returns: 5.62,
      Discounts: 7.279999999999999,
    },
    {
      day: "2023-01-15",
      datekey: 20230115,
      value: 6011.119999999996,
      "Total Amount": 6011.119999999996,
      Customers: 14,
      Transactions: 238,
      Returns: 7.1,
      Discounts: 26.74,
    },
    {
      day: "2023-01-14",
      datekey: 20230114,
      value: 7150.789999999985,
      "Total Amount": 7150.789999999985,
      Customers: 15,
      Transactions: 255,
      Returns: 37.12,
      Discounts: 57.95,
    },
    {
      day: "2023-01-13",
      datekey: 20230113,
      value: 4763.259999999998,
      "Total Amount": 4763.259999999998,
      Customers: 11,
      Transactions: 216,
      Returns: 6.6,
      Discounts: 18.55,
    },
    {
      day: "2023-01-12",
      datekey: 20230112,
      value: 4102.739999999997,
      "Total Amount": 4102.739999999997,
      Customers: 12,
      Transactions: 165,
      Returns: 7.310000000000001,
      Discounts: 19.39,
    },
    {
      day: "2023-01-11",
      datekey: 20230111,
      value: 2807.739999999999,
      "Total Amount": 2807.739999999999,
      Customers: 9,
      Transactions: 118,
      Returns: 20.02,
      Discounts: 22.13,
    },
    {
      day: "2023-01-10",
      datekey: 20230110,
      value: 4305.879999999997,
      "Total Amount": 4305.879999999997,
      Customers: 10,
      Transactions: 133,
      Returns: 0,
      Discounts: 41.35,
    },
    {
      day: "2023-01-09",
      datekey: 20230109,
      value: 3175.659999999999,
      "Total Amount": 3175.659999999999,
      Customers: 5,
      Transactions: 134,
      Returns: 4.8,
      Discounts: 12.44,
    },
    {
      day: "2023-01-08",
      datekey: 20230108,
      value: 6672.519999999992,
      "Total Amount": 6672.519999999992,
      Customers: 20,
      Transactions: 226,
      Returns: 7.69,
      Discounts: 53.25000000000001,
    },
    {
      day: "2023-01-07",
      datekey: 20230107,
      value: 6485.009999999997,
      "Total Amount": 6485.009999999997,
      Customers: 20,
      Transactions: 229,
      Returns: 49.76,
      Discounts: 46.70999999999999,
    },
    {
      day: "2023-01-06",
      datekey: 20230106,
      value: 3780.489999999998,
      "Total Amount": 3780.489999999998,
      Customers: 10,
      Transactions: 176,
      Returns: 8.57,
      Discounts: 16.51,
    },
    {
      day: "2023-01-05",
      datekey: 20230105,
      value: 4362.479999999994,
      "Total Amount": 4362.479999999994,
      Customers: 21,
      Transactions: 185,
      Returns: 11.45,
      Discounts: 32.98,
    },
    {
      day: "2023-01-04",
      datekey: 20230104,
      value: 3553.729999999999,
      "Total Amount": 3553.729999999999,
      Customers: 9,
      Transactions: 128,
      Returns: 0,
      Discounts: 67.66,
    },
    {
      day: "2023-01-03",
      datekey: 20230103,
      value: 4394.889999999997,
      "Total Amount": 4394.889999999997,
      Customers: 14,
      Transactions: 156,
      Returns: 0,
      Discounts: 39.92000000000001,
    },
    {
      day: "2023-01-02",
      datekey: 20230102,
      value: 4529.559999999999,
      "Total Amount": 4529.559999999999,
      Customers: 16,
      Transactions: 161,
      Returns: 5,
      Discounts: 19.39,
    },
    {
      day: "2023-01-01",
      datekey: 20230101,
      value: 2269.19,
      "Total Amount": 2269.19,
      Customers: 4,
      Transactions: 76,
      Returns: 15.5,
      Discounts: 1.04,
    },
    {
      day: "2022-12-31",
      datekey: 20221231,
      value: 5886.669999999996,
      "Total Amount": 5886.669999999996,
      Customers: 11,
      Transactions: 226,
      Returns: 5.58,
      Discounts: 59.67000000000001,
    },
    {
      day: "2022-12-30",
      datekey: 20221230,
      value: 5485.479999999992,
      "Total Amount": 5485.479999999992,
      Customers: 7,
      Transactions: 188,
      Returns: 6.54,
      Discounts: 34.29,
    },
    {
      day: "2022-12-29",
      datekey: 20221229,
      value: 4813.999999999996,
      "Total Amount": 4813.999999999996,
      Customers: 8,
      Transactions: 155,
      Returns: 0,
      Discounts: 39.91,
    },
    {
      day: "2022-12-28",
      datekey: 20221228,
      value: 3670.759999999999,
      "Total Amount": 3670.759999999999,
      Customers: 4,
      Transactions: 148,
      Returns: 49.17,
      Discounts: 0,
    },
    {
      day: "2022-12-27",
      datekey: 20221227,
      value: 3771.91,
      "Total Amount": 3771.91,
      Customers: 3,
      Transactions: 142,
      Returns: 5.78,
      Discounts: 28.14,
    },
    {
      day: "2022-12-26",
      datekey: 20221226,
      value: 4626.279999999998,
      "Total Amount": 4626.279999999998,
      Customers: 5,
      Transactions: 160,
      Returns: 8.25,
      Discounts: 5.38,
    },
    {
      day: "2022-12-25",
      datekey: 20221225,
      value: 2972.43,
      "Total Amount": 2972.43,
      Customers: 3,
      Transactions: 146,
      Returns: 18.89,
      Discounts: 16.29,
    },
    {
      day: "2022-12-24",
      datekey: 20221224,
      value: 6120.939999999999,
      "Total Amount": 6120.939999999999,
      Customers: 6,
      Transactions: 221,
      Returns: 15.99,
      Discounts: 31.36,
    },
    {
      day: "2022-12-23",
      datekey: 20221223,
      value: 3443.359999999998,
      "Total Amount": 3443.359999999998,
      Customers: 4,
      Transactions: 132,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-12-22",
      datekey: 20221222,
      value: 7282.069999999997,
      "Total Amount": 7282.069999999997,
      Customers: 6,
      Transactions: 217,
      Returns: 0,
      Discounts: 25.47,
    },
    {
      day: "2022-12-21",
      datekey: 20221221,
      value: 5705.099999999994,
      "Total Amount": 5705.099999999994,
      Customers: 8,
      Transactions: 183,
      Returns: 24.4,
      Discounts: 0.5,
    },
    {
      day: "2022-12-20",
      datekey: 20221220,
      value: 4251,
      "Total Amount": 4251,
      Customers: 3,
      Transactions: 128,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-12-19",
      datekey: 20221219,
      value: 3622.029999999999,
      "Total Amount": 3622.029999999999,
      Customers: 3,
      Transactions: 137,
      Returns: 5.73,
      Discounts: 0,
    },
    {
      day: "2022-12-18",
      datekey: 20221218,
      value: 5068.289999999995,
      "Total Amount": 5068.289999999995,
      Customers: 6,
      Transactions: 201,
      Returns: 3.6,
      Discounts: 0,
    },
    {
      day: "2022-12-17",
      datekey: 20221217,
      value: 5777.199999999995,
      "Total Amount": 5777.199999999995,
      Customers: 4,
      Transactions: 187,
      Returns: 15.46,
      Discounts: 17.45,
    },
    {
      day: "2022-12-16",
      datekey: 20221216,
      value: 4842.459999999996,
      "Total Amount": 4842.459999999996,
      Customers: 4,
      Transactions: 180,
      Returns: 4,
      Discounts: 1,
    },
    {
      day: "2022-12-15",
      datekey: 20221215,
      value: 3633.369999999998,
      "Total Amount": 3633.369999999998,
      Customers: 7,
      Transactions: 137,
      Returns: 15,
      Discounts: 27.2,
    },
    {
      day: "2022-12-14",
      datekey: 20221214,
      value: 3097.800000000001,
      "Total Amount": 3097.800000000001,
      Customers: 3,
      Transactions: 124,
      Returns: 0,
      Discounts: 8.75,
    },
    {
      day: "2022-12-13",
      datekey: 20221213,
      value: 3533.29,
      "Total Amount": 3533.29,
      Customers: 4,
      Transactions: 128,
      Returns: 14.91,
      Discounts: 4.19,
    },
    {
      day: "2022-12-12",
      datekey: 20221212,
      value: 3267.159999999996,
      "Total Amount": 3267.159999999996,
      Customers: 4,
      Transactions: 132,
      Returns: 12.5,
      Discounts: 17.66,
    },
    {
      day: "2022-12-11",
      datekey: 20221211,
      value: 6188.189999999998,
      "Total Amount": 6188.189999999998,
      Customers: 5,
      Transactions: 210,
      Returns: 1.85,
      Discounts: 11.24,
    },
    {
      day: "2022-12-10",
      datekey: 20221210,
      value: 4622.849999999998,
      "Total Amount": 4622.849999999998,
      Customers: 6,
      Transactions: 163,
      Returns: 9.4,
      Discounts: 7.66,
    },
    {
      day: "2022-12-09",
      datekey: 20221209,
      value: 5014.449999999996,
      "Total Amount": 5014.449999999996,
      Customers: 5,
      Transactions: 163,
      Returns: 10,
      Discounts: 0,
    },
    {
      day: "2022-12-08",
      datekey: 20221208,
      value: 3292.799999999999,
      "Total Amount": 3292.799999999999,
      Customers: 6,
      Transactions: 136,
      Returns: 2.25,
      Discounts: 5.4,
    },
    {
      day: "2022-12-07",
      datekey: 20221207,
      value: 3673.619999999998,
      "Total Amount": 3673.619999999998,
      Customers: 5,
      Transactions: 132,
      Returns: 0,
      Discounts: 3.3,
    },
    {
      day: "2022-12-06",
      datekey: 20221206,
      value: 2973.4,
      "Total Amount": 2973.4,
      Customers: 7,
      Transactions: 120,
      Returns: 11,
      Discounts: 6.779999999999999,
    },
    {
      day: "2022-12-05",
      datekey: 20221205,
      value: 2545.109999999999,
      "Total Amount": 2545.109999999999,
      Customers: 5,
      Transactions: 105,
      Returns: 0,
      Discounts: 5.21,
    },
    {
      day: "2022-12-04",
      datekey: 20221204,
      value: 5195.450000000001,
      "Total Amount": 5195.450000000001,
      Customers: 5,
      Transactions: 183,
      Returns: 3.93,
      Discounts: 6.310000000000001,
    },
    {
      day: "2022-12-03",
      datekey: 20221203,
      value: 4710.859999999996,
      "Total Amount": 4710.859999999996,
      Customers: 7,
      Transactions: 177,
      Returns: 30.59,
      Discounts: 1.4,
    },
    {
      day: "2022-12-02",
      datekey: 20221202,
      value: 4246.569999999998,
      "Total Amount": 4246.569999999998,
      Customers: 6,
      Transactions: 170,
      Returns: 4.07,
      Discounts: 4.44,
    },
    {
      day: "2022-12-01",
      datekey: 20221201,
      value: 3716.129999999998,
      "Total Amount": 3716.129999999998,
      Customers: 7,
      Transactions: 126,
      Returns: 1.9,
      Discounts: 9.36,
    },
    {
      day: "2022-11-30",
      datekey: 20221130,
      value: 2854.250000000001,
      "Total Amount": 2854.250000000001,
      Customers: 3,
      Transactions: 135,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-29",
      datekey: 20221129,
      value: 3363.799999999999,
      "Total Amount": 3363.799999999999,
      Customers: 4,
      Transactions: 133,
      Returns: 9.3,
      Discounts: 4.42,
    },
    {
      day: "2022-11-28",
      datekey: 20221128,
      value: 2920.499999999998,
      "Total Amount": 2920.499999999998,
      Customers: 4,
      Transactions: 120,
      Returns: 20.84,
      Discounts: 5,
    },
    {
      day: "2022-11-27",
      datekey: 20221127,
      value: 4247.86,
      "Total Amount": 4247.86,
      Customers: 7,
      Transactions: 173,
      Returns: 2.42,
      Discounts: 27.95,
    },
    {
      day: "2022-11-26",
      datekey: 20221126,
      value: 4243.469999999998,
      "Total Amount": 4243.469999999998,
      Customers: 5,
      Transactions: 167,
      Returns: 3.3,
      Discounts: 2.7,
    },
    {
      day: "2022-11-25",
      datekey: 20221125,
      value: 4147.479999999999,
      "Total Amount": 4147.479999999999,
      Customers: 7,
      Transactions: 148,
      Returns: 3.5,
      Discounts: 10.24,
    },
    {
      day: "2022-11-24",
      datekey: 20221124,
      value: 4051.399999999997,
      "Total Amount": 4051.399999999997,
      Customers: 5,
      Transactions: 178,
      Returns: 0,
      Discounts: 6.08,
    },
    {
      day: "2022-11-23",
      datekey: 20221123,
      value: 7653.379999999999,
      "Total Amount": 7653.379999999999,
      Customers: 5,
      Transactions: 253,
      Returns: 2.16,
      Discounts: 35.34999999999999,
    },
    {
      day: "2022-11-22",
      datekey: 20221122,
      value: 5104.769999999993,
      "Total Amount": 5104.769999999993,
      Customers: 5,
      Transactions: 167,
      Returns: 0,
      Discounts: 11.94,
    },
    {
      day: "2022-11-21",
      datekey: 20221121,
      value: 3206.249999999997,
      "Total Amount": 3206.249999999997,
      Customers: 3,
      Transactions: 129,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-20",
      datekey: 20221120,
      value: 4614.2,
      "Total Amount": 4614.2,
      Customers: 8,
      Transactions: 187,
      Returns: 32.05,
      Discounts: 12.7,
    },
    {
      day: "2022-11-19",
      datekey: 20221119,
      value: 4705.219999999997,
      "Total Amount": 4705.219999999997,
      Customers: 7,
      Transactions: 164,
      Returns: 2.5,
      Discounts: 10.47,
    },
    {
      day: "2022-11-18",
      datekey: 20221118,
      value: 4568.879999999998,
      "Total Amount": 4568.879999999998,
      Customers: 4,
      Transactions: 175,
      Returns: 22.23,
      Discounts: 2.6,
    },
    {
      day: "2022-11-17",
      datekey: 20221117,
      value: 3221.639999999999,
      "Total Amount": 3221.639999999999,
      Customers: 4,
      Transactions: 125,
      Returns: 47.98,
      Discounts: 13.83,
    },
    {
      day: "2022-11-16",
      datekey: 20221116,
      value: 2921.499999999999,
      "Total Amount": 2921.499999999999,
      Customers: 5,
      Transactions: 124,
      Returns: 2.2,
      Discounts: 0.5,
    },
    {
      day: "2022-11-15",
      datekey: 20221115,
      value: 3491.849999999999,
      "Total Amount": 3491.849999999999,
      Customers: 6,
      Transactions: 139,
      Returns: 0,
      Discounts: 11.16,
    },
    {
      day: "2022-11-14",
      datekey: 20221114,
      value: 2902.479999999998,
      "Total Amount": 2902.479999999998,
      Customers: 2,
      Transactions: 126,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-13",
      datekey: 20221113,
      value: 5969.119999999999,
      "Total Amount": 5969.119999999999,
      Customers: 3,
      Transactions: 212,
      Returns: 30.75,
      Discounts: 0,
    },
    {
      day: "2022-11-12",
      datekey: 20221112,
      value: 5633.819999999993,
      "Total Amount": 5633.819999999993,
      Customers: 7,
      Transactions: 170,
      Returns: 10.17,
      Discounts: 0,
    },
    {
      day: "2022-11-11",
      datekey: 20221111,
      value: 5212.119999999997,
      "Total Amount": 5212.119999999997,
      Customers: 6,
      Transactions: 188,
      Returns: 11,
      Discounts: 6.61,
    },
    {
      day: "2022-11-10",
      datekey: 20221110,
      value: 4005.209999999998,
      "Total Amount": 4005.209999999998,
      Customers: 6,
      Transactions: 140,
      Returns: 0,
      Discounts: 9.83,
    },
    {
      day: "2022-11-09",
      datekey: 20221109,
      value: 3719.299999999999,
      "Total Amount": 3719.299999999999,
      Customers: 5,
      Transactions: 140,
      Returns: 0,
      Discounts: 13.52,
    },
    {
      day: "2022-11-08",
      datekey: 20221108,
      value: 3503.029999999999,
      "Total Amount": 3503.029999999999,
      Customers: 7,
      Transactions: 141,
      Returns: 9.75,
      Discounts: 5.33,
    },
    {
      day: "2022-11-07",
      datekey: 20221107,
      value: 2788.329999999998,
      "Total Amount": 2788.329999999998,
      Customers: 5,
      Transactions: 122,
      Returns: 10.81,
      Discounts: 0.3,
    },
    {
      day: "2022-11-06",
      datekey: 20221106,
      value: 5585.609999999997,
      "Total Amount": 5585.609999999997,
      Customers: 4,
      Transactions: 203,
      Returns: 0,
      Discounts: 6.99,
    },
    {
      day: "2022-11-05",
      datekey: 20221105,
      value: 4912.199999999998,
      "Total Amount": 4912.199999999998,
      Customers: 5,
      Transactions: 192,
      Returns: 0,
      Discounts: 5.13,
    },
    {
      day: "2022-11-04",
      datekey: 20221104,
      value: 4568.029999999997,
      "Total Amount": 4568.029999999997,
      Customers: 7,
      Transactions: 178,
      Returns: 5.75,
      Discounts: 6.71,
    },
    {
      day: "2022-11-03",
      datekey: 20221103,
      value: 3828.9,
      "Total Amount": 3828.9,
      Customers: 8,
      Transactions: 138,
      Returns: 0,
      Discounts: 9.39,
    },
    {
      day: "2022-11-02",
      datekey: 20221102,
      value: 3458.769999999998,
      "Total Amount": 3458.769999999998,
      Customers: 4,
      Transactions: 126,
      Returns: 55.11,
      Discounts: 15.24,
    },
    {
      day: "2022-11-01",
      datekey: 20221101,
      value: 2673.94,
      "Total Amount": 2673.94,
      Customers: 4,
      Transactions: 124,
      Returns: 0,
      Discounts: 5.8,
    },
    {
      day: "2022-10-31",
      datekey: 20221031,
      value: 2549.450000000001,
      "Total Amount": 2549.450000000001,
      Customers: 3,
      Transactions: 103,
      Returns: 8,
      Discounts: 0,
    },
    {
      day: "2022-10-30",
      datekey: 20221030,
      value: 5251.709999999999,
      "Total Amount": 5251.709999999999,
      Customers: 10,
      Transactions: 211,
      Returns: 5.96,
      Discounts: 18.56,
    },
    {
      day: "2022-10-29",
      datekey: 20221029,
      value: 4863.109999999997,
      "Total Amount": 4863.109999999997,
      Customers: 4,
      Transactions: 165,
      Returns: 33.09,
      Discounts: 1.2,
    },
    {
      day: "2022-10-28",
      datekey: 20221028,
      value: 3953.589999999998,
      "Total Amount": 3953.589999999998,
      Customers: 6,
      Transactions: 147,
      Returns: 2.95,
      Discounts: 7,
    },
    {
      day: "2022-10-27",
      datekey: 20221027,
      value: 3268.719999999997,
      "Total Amount": 3268.719999999997,
      Customers: 5,
      Transactions: 122,
      Returns: 14.7,
      Discounts: 12.18,
    },
    {
      day: "2022-10-26",
      datekey: 20221026,
      value: 3222.439999999999,
      "Total Amount": 3222.439999999999,
      Customers: 7,
      Transactions: 135,
      Returns: 3.5,
      Discounts: 4.3,
    },
    {
      day: "2022-10-25",
      datekey: 20221025,
      value: 2780.35,
      "Total Amount": 2780.35,
      Customers: 6,
      Transactions: 117,
      Returns: 8.06,
      Discounts: 6.08,
    },
    {
      day: "2022-10-24",
      datekey: 20221024,
      value: 5347.509999999996,
      "Total Amount": 5347.509999999996,
      Customers: 5,
      Transactions: 246,
      Returns: 0,
      Discounts: 6.28,
    },
    {
      day: "2022-10-23",
      datekey: 20221023,
      value: 6992.249999999993,
      "Total Amount": 6992.249999999993,
      Customers: 6,
      Transactions: 263,
      Returns: 1.85,
      Discounts: 17.69,
    },
    {
      day: "2022-10-22",
      datekey: 20221022,
      value: 6432.709999999992,
      "Total Amount": 6432.709999999992,
      Customers: 8,
      Transactions: 241,
      Returns: 37.26000000000001,
      Discounts: 18.11,
    },
    {
      day: "2022-10-21",
      datekey: 20221021,
      value: 5110.839999999994,
      "Total Amount": 5110.839999999994,
      Customers: 8,
      Transactions: 201,
      Returns: 15.91,
      Discounts: 8.629999999999999,
    },
    {
      day: "2022-10-20",
      datekey: 20221020,
      value: 3988.659999999999,
      "Total Amount": 3988.659999999999,
      Customers: 5,
      Transactions: 123,
      Returns: 16.9,
      Discounts: 0.89,
    },
    {
      day: "2022-10-19",
      datekey: 20221019,
      value: 3602.819999999997,
      "Total Amount": 3602.819999999997,
      Customers: 5,
      Transactions: 137,
      Returns: 7.75,
      Discounts: 3.5,
    },
    {
      day: "2022-10-18",
      datekey: 20221018,
      value: 4174.279999999998,
      "Total Amount": 4174.279999999998,
      Customers: 6,
      Transactions: 134,
      Returns: 0,
      Discounts: 2.69,
    },
    {
      day: "2022-10-17",
      datekey: 20221017,
      value: 3418.499999999999,
      "Total Amount": 3418.499999999999,
      Customers: 5,
      Transactions: 123,
      Returns: 0,
      Discounts: 9.81,
    },
    {
      day: "2022-10-16",
      datekey: 20221016,
      value: 4944.539999999999,
      "Total Amount": 4944.539999999999,
      Customers: 6,
      Transactions: 203,
      Returns: 0,
      Discounts: 3.88,
    },
    {
      day: "2022-10-15",
      datekey: 20221015,
      value: 4062.349999999997,
      "Total Amount": 4062.349999999997,
      Customers: 6,
      Transactions: 155,
      Returns: 6.27,
      Discounts: 13.39,
    },
    {
      day: "2022-10-14",
      datekey: 20221014,
      value: 4400.239999999999,
      "Total Amount": 4400.239999999999,
      Customers: 8,
      Transactions: 164,
      Returns: 22.5,
      Discounts: 6.510000000000001,
    },
    {
      day: "2022-10-13",
      datekey: 20221013,
      value: 2914.259999999998,
      "Total Amount": 2914.259999999998,
      Customers: 5,
      Transactions: 132,
      Returns: 5.63,
      Discounts: 3.89,
    },
    {
      day: "2022-10-12",
      datekey: 20221012,
      value: 3651.659999999999,
      "Total Amount": 3651.659999999999,
      Customers: 9,
      Transactions: 149,
      Returns: 7.380000000000001,
      Discounts: 3.56,
    },
    {
      day: "2022-10-11",
      datekey: 20221011,
      value: 3649.22,
      "Total Amount": 3649.22,
      Customers: 7,
      Transactions: 154,
      Returns: 16,
      Discounts: 0,
    },
    {
      day: "2022-10-10",
      datekey: 20221010,
      value: 2815.59,
      "Total Amount": 2815.59,
      Customers: 2,
      Transactions: 113,
      Returns: 4.5,
      Discounts: 0,
    },
    {
      day: "2022-10-09",
      datekey: 20221009,
      value: 4947.079999999999,
      "Total Amount": 4947.079999999999,
      Customers: 7,
      Transactions: 195,
      Returns: 14.76,
      Discounts: 1.6,
    },
    {
      day: "2022-10-08",
      datekey: 20221008,
      value: 5297.339999999997,
      "Total Amount": 5297.339999999997,
      Customers: 8,
      Transactions: 169,
      Returns: 20.08,
      Discounts: 0.51,
    },
    {
      day: "2022-10-07",
      datekey: 20221007,
      value: 4882.439999999995,
      "Total Amount": 4882.439999999995,
      Customers: 6,
      Transactions: 171,
      Returns: 8.98,
      Discounts: 0,
    },
    {
      day: "2022-10-06",
      datekey: 20221006,
      value: 3175.039999999998,
      "Total Amount": 3175.039999999998,
      Customers: 5,
      Transactions: 125,
      Returns: 10.2,
      Discounts: 19.53,
    },
    {
      day: "2022-10-05",
      datekey: 20221005,
      value: 3656.92,
      "Total Amount": 3656.92,
      Customers: 5,
      Transactions: 150,
      Returns: 1.77,
      Discounts: 0.58,
    },
    {
      day: "2022-10-04",
      datekey: 20221004,
      value: 4293.929999999999,
      "Total Amount": 4293.929999999999,
      Customers: 5,
      Transactions: 159,
      Returns: 19.14,
      Discounts: 0,
    },
    {
      day: "2022-10-03",
      datekey: 20221003,
      value: 3610.119999999995,
      "Total Amount": 3610.119999999995,
      Customers: 6,
      Transactions: 139,
      Returns: 3.99,
      Discounts: 0,
    },
    {
      day: "2022-10-02",
      datekey: 20221002,
      value: 5571.509999999991,
      "Total Amount": 5571.509999999991,
      Customers: 6,
      Transactions: 236,
      Returns: 17.05,
      Discounts: 0,
    },
    {
      day: "2022-10-01",
      datekey: 20221001,
      value: 4587.91,
      "Total Amount": 4587.91,
      Customers: 4,
      Transactions: 163,
      Returns: 3.05,
      Discounts: 0,
    },
    {
      day: "2022-09-30",
      datekey: 20220930,
      value: 4137.499999999999,
      "Total Amount": 4137.499999999999,
      Customers: 7,
      Transactions: 161,
      Returns: 14.33,
      Discounts: 0,
    },
    {
      day: "2022-09-29",
      datekey: 20220929,
      value: 3490.239999999997,
      "Total Amount": 3490.239999999997,
      Customers: 6,
      Transactions: 136,
      Returns: 6.560000000000001,
      Discounts: 23.05,
    },
    {
      day: "2022-09-28",
      datekey: 20220928,
      value: 2489.14,
      "Total Amount": 2489.14,
      Customers: 4,
      Transactions: 112,
      Returns: 5.98,
      Discounts: 0,
    },
    {
      day: "2022-09-27",
      datekey: 20220927,
      value: 3015.21,
      "Total Amount": 3015.21,
      Customers: 2,
      Transactions: 135,
      Returns: 3.5,
      Discounts: 0,
    },
    {
      day: "2022-09-26",
      datekey: 20220926,
      value: 2393.25,
      "Total Amount": 2393.25,
      Customers: 4,
      Transactions: 105,
      Returns: 3.95,
      Discounts: 0,
    },
    {
      day: "2022-09-25",
      datekey: 20220925,
      value: 6603.209999999994,
      "Total Amount": 6603.209999999994,
      Customers: 9,
      Transactions: 216,
      Returns: 6.4,
      Discounts: 0,
    },
    {
      day: "2022-09-24",
      datekey: 20220924,
      value: 5693.779999999997,
      "Total Amount": 5693.779999999997,
      Customers: 6,
      Transactions: 198,
      Returns: 15.4,
      Discounts: 0,
    },
    {
      day: "2022-09-23",
      datekey: 20220923,
      value: 5157.499999999998,
      "Total Amount": 5157.499999999998,
      Customers: 10,
      Transactions: 147,
      Returns: 66.6,
      Discounts: 18.97,
    },
    {
      day: "2022-09-22",
      datekey: 20220922,
      value: 3385.139999999999,
      "Total Amount": 3385.139999999999,
      Customers: 3,
      Transactions: 131,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-21",
      datekey: 20220921,
      value: 2998.429999999998,
      "Total Amount": 2998.429999999998,
      Customers: 3,
      Transactions: 123,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-20",
      datekey: 20220920,
      value: 3048.979999999999,
      "Total Amount": 3048.979999999999,
      Customers: 4,
      Transactions: 129,
      Returns: 11.6,
      Discounts: 0,
    },
    {
      day: "2022-09-19",
      datekey: 20220919,
      value: 2778.63,
      "Total Amount": 2778.63,
      Customers: 4,
      Transactions: 131,
      Returns: 1.9,
      Discounts: 0,
    },
    {
      day: "2022-09-18",
      datekey: 20220918,
      value: 4356.209999999996,
      "Total Amount": 4356.209999999996,
      Customers: 7,
      Transactions: 181,
      Returns: 5.09,
      Discounts: 0,
    },
    {
      day: "2022-09-17",
      datekey: 20220917,
      value: 5358.349999999992,
      "Total Amount": 5358.349999999992,
      Customers: 5,
      Transactions: 192,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-16",
      datekey: 20220916,
      value: 4255.489999999999,
      "Total Amount": 4255.489999999999,
      Customers: 5,
      Transactions: 157,
      Returns: 21.59,
      Discounts: 0,
    },
    {
      day: "2022-09-15",
      datekey: 20220915,
      value: 2933.05,
      "Total Amount": 2933.05,
      Customers: 2,
      Transactions: 121,
      Returns: 22.49,
      Discounts: 0,
    },
    {
      day: "2022-09-14",
      datekey: 20220914,
      value: 3525.049999999998,
      "Total Amount": 3525.049999999998,
      Customers: 5,
      Transactions: 140,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-13",
      datekey: 20220913,
      value: 3409.609999999999,
      "Total Amount": 3409.609999999999,
      Customers: 4,
      Transactions: 123,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-12",
      datekey: 20220912,
      value: 2416.839999999999,
      "Total Amount": 2416.839999999999,
      Customers: 1,
      Transactions: 117,
      Returns: 1.85,
      Discounts: 0,
    },
    {
      day: "2022-09-11",
      datekey: 20220911,
      value: 4988.449999999996,
      "Total Amount": 4988.449999999996,
      Customers: 3,
      Transactions: 192,
      Returns: 3.1,
      Discounts: 0,
    },
    {
      day: "2022-09-10",
      datekey: 20220910,
      value: 5468.189999999998,
      "Total Amount": 5468.189999999998,
      Customers: 4,
      Transactions: 195,
      Returns: 0.9,
      Discounts: 0,
    },
    {
      day: "2022-09-09",
      datekey: 20220909,
      value: 4099.040000000002,
      "Total Amount": 4099.040000000002,
      Customers: 6,
      Transactions: 144,
      Returns: 7.7,
      Discounts: 0,
    },
    {
      day: "2022-09-08",
      datekey: 20220908,
      value: 3117.54,
      "Total Amount": 3117.54,
      Customers: 4,
      Transactions: 129,
      Returns: 8,
      Discounts: 0,
    },
    {
      day: "2022-09-07",
      datekey: 20220907,
      value: 3177.01,
      "Total Amount": 3177.01,
      Customers: 5,
      Transactions: 124,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-06",
      datekey: 20220906,
      value: 3345.75,
      "Total Amount": 3345.75,
      Customers: 4,
      Transactions: 123,
      Returns: 5.7,
      Discounts: 0,
    },
    {
      day: "2022-09-05",
      datekey: 20220905,
      value: 3801.79,
      "Total Amount": 3801.79,
      Customers: 3,
      Transactions: 164,
      Returns: 34.18,
      Discounts: 0,
    },
    {
      day: "2022-09-04",
      datekey: 20220904,
      value: 4081.449999999999,
      "Total Amount": 4081.449999999999,
      Customers: 4,
      Transactions: 172,
      Returns: 21.81,
      Discounts: 0,
    },
    {
      day: "2022-09-03",
      datekey: 20220903,
      value: 5421.589999999994,
      "Total Amount": 5421.589999999994,
      Customers: 6,
      Transactions: 197,
      Returns: 4.48,
      Discounts: 0,
    },
    {
      day: "2022-09-02",
      datekey: 20220902,
      value: 5431.159999999996,
      "Total Amount": 5431.159999999996,
      Customers: 5,
      Transactions: 171,
      Returns: 13.5,
      Discounts: 0,
    },
    {
      day: "2022-09-01",
      datekey: 20220901,
      value: 3594.039999999998,
      "Total Amount": 3594.039999999998,
      Customers: 2,
      Transactions: 138,
      Returns: 22.99,
      Discounts: 0,
    },
    {
      day: "2022-08-31",
      datekey: 20220831,
      value: 3388.129999999998,
      "Total Amount": 3388.129999999998,
      Customers: 6,
      Transactions: 131,
      Returns: 15,
      Discounts: 0,
    },
    {
      day: "2022-08-30",
      datekey: 20220830,
      value: 2880.359999999997,
      "Total Amount": 2880.359999999997,
      Customers: 5,
      Transactions: 131,
      Returns: 23.91,
      Discounts: 0,
    },
    {
      day: "2022-08-29",
      datekey: 20220829,
      value: 2591.76,
      "Total Amount": 2591.76,
      Customers: 2,
      Transactions: 120,
      Returns: 13.6,
      Discounts: 0,
    },
    {
      day: "2022-08-28",
      datekey: 20220828,
      value: 4736.449999999998,
      "Total Amount": 4736.449999999998,
      Customers: 2,
      Transactions: 175,
      Returns: 10.3,
      Discounts: 0,
    },
    {
      day: "2022-08-27",
      datekey: 20220827,
      value: 6234.689999999996,
      "Total Amount": 6234.689999999996,
      Customers: 9,
      Transactions: 192,
      Returns: 1.95,
      Discounts: 0,
    },
    {
      day: "2022-08-26",
      datekey: 20220826,
      value: 3613.749999999998,
      "Total Amount": 3613.749999999998,
      Customers: 4,
      Transactions: 140,
      Returns: 10.51,
      Discounts: 0,
    },
    {
      day: "2022-08-25",
      datekey: 20220825,
      value: 4076.119999999998,
      "Total Amount": 4076.119999999998,
      Customers: 5,
      Transactions: 128,
      Returns: 12.75,
      Discounts: 0,
    },
    {
      day: "2022-08-24",
      datekey: 20220824,
      value: 2665.179999999999,
      "Total Amount": 2665.179999999999,
      Customers: 4,
      Transactions: 98,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-23",
      datekey: 20220823,
      value: 2715.039999999999,
      "Total Amount": 2715.039999999999,
      Customers: 6,
      Transactions: 111,
      Returns: 3.08,
      Discounts: 0,
    },
    {
      day: "2022-08-22",
      datekey: 20220822,
      value: 3243.559999999999,
      "Total Amount": 3243.559999999999,
      Customers: 3,
      Transactions: 123,
      Returns: 33.15,
      Discounts: 0,
    },
    {
      day: "2022-08-21",
      datekey: 20220821,
      value: 5687.360000000001,
      "Total Amount": 5687.360000000001,
      Customers: 6,
      Transactions: 202,
      Returns: 21.25,
      Discounts: 0,
    },
    {
      day: "2022-08-20",
      datekey: 20220820,
      value: 6047.899999999999,
      "Total Amount": 6047.899999999999,
      Customers: 9,
      Transactions: 212,
      Returns: 20.58,
      Discounts: 4.51,
    },
    {
      day: "2022-08-19",
      datekey: 20220819,
      value: 4145.089999999997,
      "Total Amount": 4145.089999999997,
      Customers: 3,
      Transactions: 157,
      Returns: 6.1,
      Discounts: 0,
    },
    {
      day: "2022-08-18",
      datekey: 20220818,
      value: 3315.189999999999,
      "Total Amount": 3315.189999999999,
      Customers: 6,
      Transactions: 131,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-17",
      datekey: 20220817,
      value: 3249.269999999999,
      "Total Amount": 3249.269999999999,
      Customers: 2,
      Transactions: 116,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-16",
      datekey: 20220816,
      value: 3006.449999999999,
      "Total Amount": 3006.449999999999,
      Customers: 2,
      Transactions: 116,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-15",
      datekey: 20220815,
      value: 3076.339999999997,
      "Total Amount": 3076.339999999997,
      Customers: 1,
      Transactions: 131,
      Returns: 2.97,
      Discounts: 0,
    },
    {
      day: "2022-08-14",
      datekey: 20220814,
      value: 6753.929999999992,
      "Total Amount": 6753.929999999992,
      Customers: 4,
      Transactions: 181,
      Returns: 53.02,
      Discounts: 0,
    },
    {
      day: "2022-08-13",
      datekey: 20220813,
      value: 4099.189999999998,
      "Total Amount": 4099.189999999998,
      Customers: 6,
      Transactions: 153,
      Returns: 22.42,
      Discounts: 0,
    },
    {
      day: "2022-08-12",
      datekey: 20220812,
      value: 4011.129999999999,
      "Total Amount": 4011.129999999999,
      Customers: 6,
      Transactions: 176,
      Returns: 27.84,
      Discounts: 0,
    },
    {
      day: "2022-08-11",
      datekey: 20220811,
      value: 4422.829999999997,
      "Total Amount": 4422.829999999997,
      Customers: 6,
      Transactions: 173,
      Returns: 10.38,
      Discounts: 0,
    },
    {
      day: "2022-08-10",
      datekey: 20220810,
      value: 3209.429999999999,
      "Total Amount": 3209.429999999999,
      Customers: 5,
      Transactions: 124,
      Returns: 18.25,
      Discounts: 0,
    },
    {
      day: "2022-08-09",
      datekey: 20220809,
      value: 3178.109999999997,
      "Total Amount": 3178.109999999997,
      Customers: 6,
      Transactions: 115,
      Returns: 7,
      Discounts: 0,
    },
    {
      day: "2022-08-08",
      datekey: 20220808,
      value: 2728.399999999998,
      "Total Amount": 2728.399999999998,
      Customers: 7,
      Transactions: 120,
      Returns: 6,
      Discounts: 0,
    },
    {
      day: "2022-08-07",
      datekey: 20220807,
      value: 4748.739999999999,
      "Total Amount": 4748.739999999999,
      Customers: 3,
      Transactions: 170,
      Returns: 10.61,
      Discounts: 0,
    },
    {
      day: "2022-08-06",
      datekey: 20220806,
      value: 4738.1,
      "Total Amount": 4738.1,
      Customers: 4,
      Transactions: 151,
      Returns: 0.25,
      Discounts: 0,
    },
    {
      day: "2022-08-05",
      datekey: 20220805,
      value: 2640.28,
      "Total Amount": 2640.28,
      Customers: 2,
      Transactions: 127,
      Returns: 32.38,
      Discounts: 0,
    },
    {
      day: "2022-08-04",
      datekey: 20220804,
      value: 3812.909999999997,
      "Total Amount": 3812.909999999997,
      Customers: 5,
      Transactions: 139,
      Returns: 10.29,
      Discounts: 0,
    },
    {
      day: "2022-08-03",
      datekey: 20220803,
      value: 3637.299999999999,
      "Total Amount": 3637.299999999999,
      Customers: 4,
      Transactions: 122,
      Returns: 20.44,
      Discounts: 0,
    },
    {
      day: "2022-08-02",
      datekey: 20220802,
      value: 3288.58,
      "Total Amount": 3288.58,
      Customers: 4,
      Transactions: 121,
      Returns: 26.53,
      Discounts: 0,
    },
    {
      day: "2022-08-01",
      datekey: 20220801,
      value: 2119.219999999999,
      "Total Amount": 2119.219999999999,
      Customers: 5,
      Transactions: 94,
      Returns: 14.49,
      Discounts: 0,
    },
    {
      day: "2022-07-31",
      datekey: 20220731,
      value: 4482.209999999997,
      "Total Amount": 4482.209999999997,
      Customers: 6,
      Transactions: 163,
      Returns: 26.38,
      Discounts: 0,
    },
    {
      day: "2022-07-30",
      datekey: 20220730,
      value: 4411.85,
      "Total Amount": 4411.85,
      Customers: 5,
      Transactions: 164,
      Returns: 1.75,
      Discounts: 0,
    },
    {
      day: "2022-07-29",
      datekey: 20220729,
      value: 4234.919999999998,
      "Total Amount": 4234.919999999998,
      Customers: 5,
      Transactions: 150,
      Returns: 16.7,
      Discounts: 0,
    },
    {
      day: "2022-07-28",
      datekey: 20220728,
      value: 3995.549999999998,
      "Total Amount": 3995.549999999998,
      Customers: 2,
      Transactions: 135,
      Returns: 4.49,
      Discounts: 0,
    },
    {
      day: "2022-07-27",
      datekey: 20220727,
      value: 2774.510000000001,
      "Total Amount": 2774.510000000001,
      Customers: 3,
      Transactions: 110,
      Returns: 5.84,
      Discounts: 0,
    },
    {
      day: "2022-07-26",
      datekey: 20220726,
      value: 2843.189999999999,
      "Total Amount": 2843.189999999999,
      Customers: 3,
      Transactions: 105,
      Returns: 31.79,
      Discounts: 0,
    },
    {
      day: "2022-07-25",
      datekey: 20220725,
      value: 2306.92,
      "Total Amount": 2306.92,
      Customers: 2,
      Transactions: 99,
      Returns: 12.62,
      Discounts: 0,
    },
    {
      day: "2022-07-24",
      datekey: 20220724,
      value: 3898.489999999999,
      "Total Amount": 3898.489999999999,
      Customers: 4,
      Transactions: 149,
      Returns: 21.97,
      Discounts: 0,
    },
    {
      day: "2022-07-23",
      datekey: 20220723,
      value: 4066.219999999998,
      "Total Amount": 4066.219999999998,
      Customers: 7,
      Transactions: 155,
      Returns: 28.65,
      Discounts: 0,
    },
    {
      day: "2022-07-22",
      datekey: 20220722,
      value: 3149.419999999998,
      "Total Amount": 3149.419999999998,
      Customers: 4,
      Transactions: 128,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-21",
      datekey: 20220721,
      value: 3441.8,
      "Total Amount": 3441.8,
      Customers: 3,
      Transactions: 121,
      Returns: 17.25,
      Discounts: 0,
    },
    {
      day: "2022-07-20",
      datekey: 20220720,
      value: 2125.7,
      "Total Amount": 2125.7,
      Customers: 6,
      Transactions: 96,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-19",
      datekey: 20220719,
      value: 2634.6,
      "Total Amount": 2634.6,
      Customers: 3,
      Transactions: 102,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-18",
      datekey: 20220718,
      value: 2233.190000000001,
      "Total Amount": 2233.190000000001,
      Customers: 1,
      Transactions: 97,
      Returns: 12.34,
      Discounts: 0,
    },
    {
      day: "2022-07-17",
      datekey: 20220717,
      value: 4185.869999999996,
      "Total Amount": 4185.869999999996,
      Customers: 4,
      Transactions: 165,
      Returns: 14.08,
      Discounts: 0,
    },
    {
      day: "2022-07-16",
      datekey: 20220716,
      value: 5006.899999999999,
      "Total Amount": 5006.899999999999,
      Customers: 3,
      Transactions: 167,
      Returns: 16.07,
      Discounts: 0,
    },
    {
      day: "2022-07-15",
      datekey: 20220715,
      value: 3750.759999999999,
      "Total Amount": 3750.759999999999,
      Customers: 2,
      Transactions: 144,
      Returns: 0,
      Discounts: 39.45,
    },
    {
      day: "2022-07-14",
      datekey: 20220714,
      value: 2700.089999999998,
      "Total Amount": 2700.089999999998,
      Customers: 3,
      Transactions: 105,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-13",
      datekey: 20220713,
      value: 3049.91,
      "Total Amount": 3049.91,
      Customers: 2,
      Transactions: 114,
      Returns: 7.5,
      Discounts: 0,
    },
    {
      day: "2022-07-12",
      datekey: 20220712,
      value: 2382.210000000001,
      "Total Amount": 2382.210000000001,
      Customers: 5,
      Transactions: 99,
      Returns: 3.2,
      Discounts: 0,
    },
    {
      day: "2022-07-11",
      datekey: 20220711,
      value: 1787.830000000001,
      "Total Amount": 1787.830000000001,
      Customers: 3,
      Transactions: 94,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-10",
      datekey: 20220710,
      value: 3951.449999999997,
      "Total Amount": 3951.449999999997,
      Customers: 4,
      Transactions: 152,
      Returns: 4.02,
      Discounts: 0,
    },
    {
      day: "2022-07-09",
      datekey: 20220709,
      value: 3826.239999999999,
      "Total Amount": 3826.239999999999,
      Customers: 5,
      Transactions: 128,
      Returns: 5.75,
      Discounts: 0,
    },
    {
      day: "2022-07-08",
      datekey: 20220708,
      value: 4835.469999999998,
      "Total Amount": 4835.469999999998,
      Customers: 3,
      Transactions: 154,
      Returns: 5.65,
      Discounts: 0,
    },
    {
      day: "2022-07-07",
      datekey: 20220707,
      value: 3336.669999999998,
      "Total Amount": 3336.669999999998,
      Customers: 4,
      Transactions: 119,
      Returns: 15,
      Discounts: 0,
    },
    {
      day: "2022-07-06",
      datekey: 20220706,
      value: 2372.93,
      "Total Amount": 2372.93,
      Customers: 4,
      Transactions: 95,
      Returns: 6.01,
      Discounts: 0,
    },
    {
      day: "2022-07-05",
      datekey: 20220705,
      value: 2805.739999999999,
      "Total Amount": 2805.739999999999,
      Customers: 1,
      Transactions: 105,
      Returns: 16.29,
      Discounts: 0,
    },
    {
      day: "2022-07-04",
      datekey: 20220704,
      value: 3468.319999999998,
      "Total Amount": 3468.319999999998,
      Customers: 3,
      Transactions: 134,
      Returns: 45.6,
      Discounts: 0,
    },
    {
      day: "2022-07-03",
      datekey: 20220703,
      value: 3323.679999999999,
      "Total Amount": 3323.679999999999,
      Customers: 4,
      Transactions: 121,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-02",
      datekey: 20220702,
      value: 4421.629999999997,
      "Total Amount": 4421.629999999997,
      Customers: 4,
      Transactions: 149,
      Returns: 15.6,
      Discounts: 0,
    },
    {
      day: "2022-07-01",
      datekey: 20220701,
      value: 3910.529999999998,
      "Total Amount": 3910.529999999998,
      Customers: 4,
      Transactions: 133,
      Returns: 7.5,
      Discounts: 0,
    },
    {
      day: "2022-06-30",
      datekey: 20220630,
      value: 3308.349999999999,
      "Total Amount": 3308.349999999999,
      Customers: 2,
      Transactions: 134,
      Returns: 8.43,
      Discounts: 0.4,
    },
    {
      day: "2022-06-29",
      datekey: 20220629,
      value: 3242.510000000001,
      "Total Amount": 3242.510000000001,
      Customers: 2,
      Transactions: 105,
      Returns: 10.5,
      Discounts: 0,
    },
    {
      day: "2022-06-28",
      datekey: 20220628,
      value: 3467.6,
      "Total Amount": 3467.6,
      Customers: 3,
      Transactions: 107,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-27",
      datekey: 20220627,
      value: 2212.96,
      "Total Amount": 2212.96,
      Customers: 1,
      Transactions: 90,
      Returns: 22.2,
      Discounts: 0,
    },
    {
      day: "2022-06-26",
      datekey: 20220626,
      value: 3960.229999999998,
      "Total Amount": 3960.229999999998,
      Customers: 2,
      Transactions: 144,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-25",
      datekey: 20220625,
      value: 4047.849999999998,
      "Total Amount": 4047.849999999998,
      Customers: 6,
      Transactions: 143,
      Returns: 8.5,
      Discounts: 0,
    },
    {
      day: "2022-06-24",
      datekey: 20220624,
      value: 3678.019999999999,
      "Total Amount": 3678.019999999999,
      Customers: 4,
      Transactions: 127,
      Returns: 1.75,
      Discounts: 0,
    },
    {
      day: "2022-06-23",
      datekey: 20220623,
      value: 2616.659999999999,
      "Total Amount": 2616.659999999999,
      Customers: 2,
      Transactions: 93,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-22",
      datekey: 20220622,
      value: 2712.679999999999,
      "Total Amount": 2712.679999999999,
      Customers: 5,
      Transactions: 106,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-21",
      datekey: 20220621,
      value: 2223.180000000001,
      "Total Amount": 2223.180000000001,
      Customers: 3,
      Transactions: 94,
      Returns: 1.89,
      Discounts: 2.77,
    },
    {
      day: "2022-06-20",
      datekey: 20220620,
      value: 2363.08,
      "Total Amount": 2363.08,
      Customers: 1,
      Transactions: 87,
      Returns: 3.9,
      Discounts: 10.14,
    },
    {
      day: "2022-06-19",
      datekey: 20220619,
      value: 3579.069999999997,
      "Total Amount": 3579.069999999997,
      Customers: 3,
      Transactions: 118,
      Returns: 0,
      Discounts: 1.44,
    },
    {
      day: "2022-06-18",
      datekey: 20220618,
      value: 4002.609999999999,
      "Total Amount": 4002.609999999999,
      Customers: 4,
      Transactions: 146,
      Returns: 19.5,
      Discounts: 2.92,
    },
    {
      day: "2022-06-17",
      datekey: 20220617,
      value: 3508.69,
      "Total Amount": 3508.69,
      Customers: 5,
      Transactions: 124,
      Returns: 14.61,
      Discounts: 5.83,
    },
    {
      day: "2022-06-16",
      datekey: 20220616,
      value: 2732.909999999999,
      "Total Amount": 2732.909999999999,
      Customers: 4,
      Transactions: 104,
      Returns: 0,
      Discounts: 6.54,
    },
    {
      day: "2022-06-15",
      datekey: 20220615,
      value: 2781.819999999999,
      "Total Amount": 2781.819999999999,
      Customers: 5,
      Transactions: 94,
      Returns: 1.35,
      Discounts: 0.75,
    },
    {
      day: "2022-06-14",
      datekey: 20220614,
      value: 2064.979999999999,
      "Total Amount": 2064.979999999999,
      Customers: 2,
      Transactions: 93,
      Returns: 9.4,
      Discounts: 7.13,
    },
    {
      day: "2022-06-13",
      datekey: 20220613,
      value: 2204.43,
      "Total Amount": 2204.43,
      Customers: 3,
      Transactions: 85,
      Returns: 14.15,
      Discounts: 4.48,
    },
    {
      day: "2022-06-12",
      datekey: 20220612,
      value: 3312.17,
      "Total Amount": 3312.17,
      Customers: 4,
      Transactions: 125,
      Returns: 0,
      Discounts: 9.780000000000001,
    },
    {
      day: "2022-06-11",
      datekey: 20220611,
      value: 3331.259999999997,
      "Total Amount": 3331.259999999997,
      Customers: 4,
      Transactions: 141,
      Returns: 40.24,
      Discounts: 5.21,
    },
    {
      day: "2022-06-10",
      datekey: 20220610,
      value: 3790.269999999998,
      "Total Amount": 3790.269999999998,
      Customers: 7,
      Transactions: 135,
      Returns: 0,
      Discounts: 10.74,
    },
    {
      day: "2022-06-09",
      datekey: 20220609,
      value: -306.1600000000016,
      "Total Amount": -306.1600000000016,
      Customers: 3,
      Transactions: 98,
      Returns: 3.88,
      Discounts: 6.8,
    },
    {
      day: "2022-06-08",
      datekey: 20220608,
      value: 6635.249999999997,
      "Total Amount": 6635.249999999997,
      Customers: 5,
      Transactions: 89,
      Returns: 0,
      Discounts: 3.7,
    },
    {
      day: "2022-06-07",
      datekey: 20220607,
      value: 2719.7,
      "Total Amount": 2719.7,
      Customers: 2,
      Transactions: 103,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-06-06",
      datekey: 20220606,
      value: 2675.96,
      "Total Amount": 2675.96,
      Customers: 2,
      Transactions: 88,
      Returns: 16.7,
      Discounts: 8.2,
    },
    {
      day: "2022-06-05",
      datekey: 20220605,
      value: 3414.699999999999,
      "Total Amount": 3414.699999999999,
      Customers: 2,
      Transactions: 151,
      Returns: 3.83,
      Discounts: 11.38,
    },
    {
      day: "2022-06-04",
      datekey: 20220604,
      value: 3193.839999999998,
      "Total Amount": 3193.839999999998,
      Customers: 2,
      Transactions: 121,
      Returns: 7.6,
      Discounts: 15.74,
    },
    {
      day: "2022-06-03",
      datekey: 20220603,
      value: 3480.079999999997,
      "Total Amount": 3480.079999999997,
      Customers: 5,
      Transactions: 122,
      Returns: 3.4,
      Discounts: 7.24,
    },
    {
      day: "2022-06-02",
      datekey: 20220602,
      value: 3110.369999999999,
      "Total Amount": 3110.369999999999,
      Customers: 2,
      Transactions: 119,
      Returns: 0,
      Discounts: 15.45,
    },
    {
      day: "2022-06-01",
      datekey: 20220601,
      value: 2712.7,
      "Total Amount": 2712.7,
      Customers: 2,
      Transactions: 112,
      Returns: 0,
      Discounts: 0.62,
    },
    {
      day: "2022-05-31",
      datekey: 20220531,
      value: 2535.55,
      "Total Amount": 2535.55,
      Customers: 3,
      Transactions: 101,
      Returns: 14.13,
      Discounts: 1.1,
    },
    {
      day: "2022-05-30",
      datekey: 20220530,
      value: 3424.519999999999,
      "Total Amount": 3424.519999999999,
      Customers: 3,
      Transactions: 141,
      Returns: 0,
      Discounts: 3.63,
    },
    {
      day: "2022-05-29",
      datekey: 20220529,
      value: 3841.290000000001,
      "Total Amount": 3841.290000000001,
      Customers: 7,
      Transactions: 149,
      Returns: 20.27,
      Discounts: 13.44,
    },
    {
      day: "2022-05-28",
      datekey: 20220528,
      value: 3211.799999999999,
      "Total Amount": 3211.799999999999,
      Customers: 12,
      Transactions: 130,
      Returns: 18.47,
      Discounts: 6.250000000000001,
    },
    {
      day: "2022-05-27",
      datekey: 20220527,
      value: 3862.689999999998,
      "Total Amount": 3862.689999999998,
      Customers: 13,
      Transactions: 131,
      Returns: 9.85,
      Discounts: 22.83,
    },
    {
      day: "2022-05-26",
      datekey: 20220526,
      value: 3775.109999999998,
      "Total Amount": 3775.109999999998,
      Customers: 11,
      Transactions: 126,
      Returns: 3.34,
      Discounts: 2.78,
    },
    {
      day: "2022-05-25",
      datekey: 20220525,
      value: 1997.98,
      "Total Amount": 1997.98,
      Customers: 22,
      Transactions: 96,
      Returns: 0,
      Discounts: 7.99,
    },
    {
      day: "2022-05-24",
      datekey: 20220524,
      value: 2633.28,
      "Total Amount": 2633.28,
      Customers: 23,
      Transactions: 111,
      Returns: 0,
      Discounts: 6.78,
    },
    {
      day: "2022-05-23",
      datekey: 20220523,
      value: 2209.76,
      "Total Amount": 2209.76,
      Customers: 2,
      Transactions: 111,
      Returns: 21.99,
      Discounts: 5.08,
    },
    {
      day: "2022-05-22",
      datekey: 20220522,
      value: 5054.689999999996,
      "Total Amount": 5054.689999999996,
      Customers: 4,
      Transactions: 185,
      Returns: 6.770000000000001,
      Discounts: 8.42,
    },
    {
      day: "2022-05-21",
      datekey: 20220521,
      value: 3842.609999999997,
      "Total Amount": 3842.609999999997,
      Customers: 4,
      Transactions: 156,
      Returns: 10.4,
      Discounts: 10.86,
    },
    {
      day: "2022-05-20",
      datekey: 20220520,
      value: 2571.29,
      "Total Amount": 2571.29,
      Customers: 5,
      Transactions: 121,
      Returns: 0,
      Discounts: 0.66,
    },
    {
      day: "2022-05-19",
      datekey: 20220519,
      value: 3238.489999999999,
      "Total Amount": 3238.489999999999,
      Customers: 2,
      Transactions: 108,
      Returns: 11.72,
      Discounts: 0,
    },
    {
      day: "2022-05-18",
      datekey: 20220518,
      value: 3309.370000000001,
      "Total Amount": 3309.370000000001,
      Customers: 2,
      Transactions: 106,
      Returns: 1.2,
      Discounts: 2.41,
    },
    {
      day: "2022-05-17",
      datekey: 20220517,
      value: 2262.2,
      "Total Amount": 2262.2,
      Customers: 3,
      Transactions: 92,
      Returns: 0,
      Discounts: 3.03,
    },
    {
      day: "2022-05-16",
      datekey: 20220516,
      value: 2747,
      "Total Amount": 2747,
      Customers: 1,
      Transactions: 99,
      Returns: 14,
      Discounts: 0.46,
    },
    {
      day: "2022-05-15",
      datekey: 20220515,
      value: 11015.78,
      "Total Amount": 11015.78,
      Customers: 1,
      Transactions: 276,
      Returns: 16.44,
      Discounts: 78.24000000000001,
    },
    {
      day: "2022-05-14",
      datekey: 20220514,
      value: 4026.189999999997,
      "Total Amount": 4026.189999999997,
      Customers: 2,
      Transactions: 158,
      Returns: 3.08,
      Discounts: 4.17,
    },
    {
      day: "2022-05-13",
      datekey: 20220513,
      value: 3816.25,
      "Total Amount": 3816.25,
      Customers: 3,
      Transactions: 121,
      Returns: 1.8,
      Discounts: 17.83,
    },
    {
      day: "2022-05-12",
      datekey: 20220512,
      value: 2887.28,
      "Total Amount": 2887.28,
      Customers: 4,
      Transactions: 98,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-05-11",
      datekey: 20220511,
      value: 2861.63,
      "Total Amount": 2861.63,
      Customers: 1,
      Transactions: 118,
      Returns: 0,
      Discounts: 21.3,
    },
    {
      day: "2022-05-10",
      datekey: 20220510,
      value: 2713.38,
      "Total Amount": 2713.38,
      Customers: 3,
      Transactions: 95,
      Returns: 3.34,
      Discounts: 0.7,
    },
    {
      day: "2022-05-09",
      datekey: 20220509,
      value: 1903.630000000001,
      "Total Amount": 1903.630000000001,
      Customers: 1,
      Transactions: 71,
      Returns: 0,
      Discounts: 0.13,
    },
    {
      day: "2022-05-08",
      datekey: 20220508,
      value: 4092.079999999999,
      "Total Amount": 4092.079999999999,
      Customers: 3,
      Transactions: 141,
      Returns: 0,
      Discounts: 3.39,
    },
    {
      day: "2022-05-07",
      datekey: 20220507,
      value: 3778.309999999998,
      "Total Amount": 3778.309999999998,
      Customers: 4,
      Transactions: 124,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-05-06",
      datekey: 20220506,
      value: 4182.83,
      "Total Amount": 4182.83,
      Customers: 3,
      Transactions: 137,
      Returns: 6.4,
      Discounts: 1.24,
    },
    {
      day: "2022-05-05",
      datekey: 20220505,
      value: 2962.789999999999,
      "Total Amount": 2962.789999999999,
      Customers: 3,
      Transactions: 92,
      Returns: 0,
      Discounts: 2.21,
    },
    {
      day: "2022-05-04",
      datekey: 20220504,
      value: 2118.19,
      "Total Amount": 2118.19,
      Customers: 2,
      Transactions: 100,
      Returns: 3.19,
      Discounts: 11.2,
    },
    {
      day: "2022-05-03",
      datekey: 20220503,
      value: 2034.950000000001,
      "Total Amount": 2034.950000000001,
      Customers: 2,
      Transactions: 96,
      Returns: 0,
      Discounts: 0.85,
    },
    {
      day: "2022-05-02",
      datekey: 20220502,
      value: 2097.84,
      "Total Amount": 2097.84,
      Customers: 1,
      Transactions: 91,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-05-01",
      datekey: 20220501,
      value: 4214.459999999999,
      "Total Amount": 4214.459999999999,
      Customers: 2,
      Transactions: 177,
      Returns: 88.4,
      Discounts: 29.63,
    },
    {
      day: "2022-04-30",
      datekey: 20220430,
      value: 4711.629999999997,
      "Total Amount": 4711.629999999997,
      Customers: 3,
      Transactions: 155,
      Returns: 10.25,
      Discounts: 5.3,
    },
    {
      day: "2022-04-29",
      datekey: 20220429,
      value: 3518.929999999999,
      "Total Amount": 3518.929999999999,
      Customers: 3,
      Transactions: 114,
      Returns: 2.5,
      Discounts: 8.309999999999999,
    },
    {
      day: "2022-04-28",
      datekey: 20220428,
      value: 2961.999999999999,
      "Total Amount": 2961.999999999999,
      Customers: 2,
      Transactions: 105,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-04-27",
      datekey: 20220427,
      value: 3048.029999999999,
      "Total Amount": 3048.029999999999,
      Customers: 2,
      Transactions: 139,
      Returns: 12.5,
      Discounts: 1.57,
    },
    {
      day: "2022-04-26",
      datekey: 20220426,
      value: 2850.279999999999,
      "Total Amount": 2850.279999999999,
      Customers: 1,
      Transactions: 128,
      Returns: 7.49,
      Discounts: 2.26,
    },
    {
      day: "2022-04-25",
      datekey: 20220425,
      value: 1645.020000000001,
      "Total Amount": 1645.020000000001,
      Customers: 1,
      Transactions: 83,
      Returns: 0,
      Discounts: 1.8,
    },
    {
      day: "2022-04-24",
      datekey: 20220424,
      value: 4496.510000000001,
      "Total Amount": 4496.510000000001,
      Customers: 1,
      Transactions: 173,
      Returns: 3.5,
      Discounts: 29.97,
    },
    {
      day: "2022-04-23",
      datekey: 20220423,
      value: 3168.1,
      "Total Amount": 3168.1,
      Customers: 1,
      Transactions: 146,
      Returns: 0,
      Discounts: 0.45,
    },
    {
      day: "2022-04-22",
      datekey: 20220422,
      value: 3295.33,
      "Total Amount": 3295.33,
      Customers: 2,
      Transactions: 130,
      Returns: 28.34,
      Discounts: 0.2,
    },
    {
      day: "2022-04-21",
      datekey: 20220421,
      value: 2295.09,
      "Total Amount": 2295.09,
      Customers: 2,
      Transactions: 93,
      Returns: 0,
      Discounts: 10,
    },
    {
      day: "2022-04-20",
      datekey: 20220420,
      value: 1740.950000000001,
      "Total Amount": 1740.950000000001,
      Customers: 2,
      Transactions: 80,
      Returns: 0,
      Discounts: 1.96,
    },
    {
      day: "2022-04-19",
      datekey: 20220419,
      value: 1977.620000000001,
      "Total Amount": 1977.620000000001,
      Customers: 2,
      Transactions: 96,
      Returns: 12,
      Discounts: 16.85,
    },
    {
      day: "2022-04-18",
      datekey: 20220418,
      value: 1215.710000000001,
      "Total Amount": 1215.710000000001,
      Customers: 1,
      Transactions: 83,
      Returns: 5.75,
      Discounts: 2.07,
    },
    {
      day: "2022-04-17",
      datekey: 20220417,
      value: 3632.459999999999,
      "Total Amount": 3632.459999999999,
      Customers: 1,
      Transactions: 149,
      Returns: 0,
      Discounts: 1.2,
    },
    {
      day: "2022-04-16",
      datekey: 20220416,
      value: 3014.17,
      "Total Amount": 3014.17,
      Customers: 2,
      Transactions: 141,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-04-15",
      datekey: 20220415,
      value: 3131.730000000001,
      "Total Amount": 3131.730000000001,
      Customers: 3,
      Transactions: 119,
      Returns: 0,
      Discounts: 17.06,
    },
    {
      day: "2022-04-14",
      datekey: 20220414,
      value: 2150.420000000001,
      "Total Amount": 2150.420000000001,
      Customers: 2,
      Transactions: 111,
      Returns: 0,
      Discounts: 3.73,
    },
    {
      day: "2022-04-13",
      datekey: 20220413,
      value: 2511.03,
      "Total Amount": 2511.03,
      Customers: 1,
      Transactions: 85,
      Returns: 0,
      Discounts: 65.81,
    },
    {
      day: "2022-04-12",
      datekey: 20220412,
      value: 1727.140000000001,
      "Total Amount": 1727.140000000001,
      Customers: 1,
      Transactions: 87,
      Returns: 0,
      Discounts: 15.65,
    },
    {
      day: "2022-04-11",
      datekey: 20220411,
      value: 2553.32,
      "Total Amount": 2553.32,
      Customers: 1,
      Transactions: 95,
      Returns: 0,
      Discounts: 0.2,
    },
    {
      day: "2022-04-10",
      datekey: 20220410,
      value: 2930.4,
      "Total Amount": 2930.4,
      Customers: 1,
      Transactions: 114,
      Returns: 13.35,
      Discounts: 10.13,
    },
    {
      day: "2022-04-09",
      datekey: 20220409,
      value: 2980.55,
      "Total Amount": 2980.55,
      Customers: 1,
      Transactions: 127,
      Returns: 0,
      Discounts: 1.23,
    },
    {
      day: "2022-04-08",
      datekey: 20220408,
      value: 2489.06,
      "Total Amount": 2489.06,
      Customers: 1,
      Transactions: 101,
      Returns: 0,
      Discounts: 13.35,
    },
    {
      day: "2022-04-07",
      datekey: 20220407,
      value: 2749.24,
      "Total Amount": 2749.24,
      Customers: 2,
      Transactions: 103,
      Returns: 8,
      Discounts: 6.13,
    },
    {
      day: "2022-04-06",
      datekey: 20220406,
      value: 2047.46,
      "Total Amount": 2047.46,
      Customers: 1,
      Transactions: 88,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-04-05",
      datekey: 20220405,
      value: 1878.800000000001,
      "Total Amount": 1878.800000000001,
      Customers: 1,
      Transactions: 95,
      Returns: 4.05,
      Discounts: 7.180000000000001,
    },
    {
      day: "2022-04-04",
      datekey: 20220404,
      value: 2283.23,
      "Total Amount": 2283.23,
      Customers: 1,
      Transactions: 95,
      Returns: 0,
      Discounts: 7.709999999999999,
    },
    {
      day: "2022-04-03",
      datekey: 20220403,
      value: 4246.720000000001,
      "Total Amount": 4246.720000000001,
      Customers: 1,
      Transactions: 162,
      Returns: 27.49,
      Discounts: 474.4099999999997,
    },
    {
      day: "2022-04-02",
      datekey: 20220402,
      value: 3458.85,
      "Total Amount": 3458.85,
      Customers: 2,
      Transactions: 144,
      Returns: 8.85,
      Discounts: 383.1799999999997,
    },
    {
      day: "2022-04-01",
      datekey: 20220401,
      value: 3860.329999999999,
      "Total Amount": 3860.329999999999,
      Customers: 1,
      Transactions: 167,
      Returns: 5,
      Discounts: 381.17,
    },
    {
      day: "2022-03-31",
      datekey: 20220331,
      value: 2478.399999999998,
      "Total Amount": 2478.399999999998,
      Customers: 1,
      Transactions: 112,
      Returns: 10.96,
      Discounts: 12.8,
    },
    {
      day: "2022-03-30",
      datekey: 20220330,
      value: 1506.76,
      "Total Amount": 1506.76,
      Customers: 1,
      Transactions: 64,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-29",
      datekey: 20220329,
      value: 1875.620000000001,
      "Total Amount": 1875.620000000001,
      Customers: 1,
      Transactions: 88,
      Returns: 0,
      Discounts: 6.29,
    },
    {
      day: "2022-03-28",
      datekey: 20220328,
      value: 2016.5,
      "Total Amount": 2016.5,
      Customers: 1,
      Transactions: 81,
      Returns: 6.5,
      Discounts: 8.41,
    },
    {
      day: "2022-03-27",
      datekey: 20220327,
      value: 5782.699999999998,
      "Total Amount": 5782.699999999998,
      Customers: 1,
      Transactions: 200,
      Returns: 0,
      Discounts: 626.5200000000003,
    },
    {
      day: "2022-03-26",
      datekey: 20220326,
      value: 7393.009999999991,
      "Total Amount": 7393.009999999991,
      Customers: 1,
      Transactions: 229,
      Returns: 0,
      Discounts: 801.4999999999994,
    },
    {
      day: "2022-03-25",
      datekey: 20220325,
      value: 4.49,
      "Total Amount": 4.49,
      Customers: 1,
      Transactions: 1,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-24",
      datekey: 20220324,
      value: 2.75,
      "Total Amount": 2.75,
      Customers: 1,
      Transactions: 2,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-23",
      datekey: 20220323,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-22",
      datekey: 20220322,
      value: 4.49,
      "Total Amount": 4.49,
      Customers: 1,
      Transactions: 1,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-21",
      datekey: 20220321,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-20",
      datekey: 20220320,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-19",
      datekey: 20220319,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-18",
      datekey: 20220318,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-17",
      datekey: 20220317,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-16",
      datekey: 20220316,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-15",
      datekey: 20220315,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-14",
      datekey: 20220314,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-13",
      datekey: 20220313,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-12",
      datekey: 20220312,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-11",
      datekey: 20220311,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-10",
      datekey: 20220310,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-09",
      datekey: 20220309,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-08",
      datekey: 20220308,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-07",
      datekey: 20220307,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-06",
      datekey: 20220306,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-05",
      datekey: 20220305,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-04",
      datekey: 20220304,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-03",
      datekey: 20220303,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-02",
      datekey: 20220302,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-01",
      datekey: 20220301,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-28",
      datekey: 20220228,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-27",
      datekey: 20220227,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-26",
      datekey: 20220226,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-25",
      datekey: 20220225,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-24",
      datekey: 20220224,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-23",
      datekey: 20220223,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-22",
      datekey: 20220222,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-21",
      datekey: 20220221,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-20",
      datekey: 20220220,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-19",
      datekey: 20220219,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-18",
      datekey: 20220218,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-17",
      datekey: 20220217,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-16",
      datekey: 20220216,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-15",
      datekey: 20220215,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-14",
      datekey: 20220214,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-13",
      datekey: 20220213,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-12",
      datekey: 20220212,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-11",
      datekey: 20220211,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-10",
      datekey: 20220210,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-09",
      datekey: 20220209,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-08",
      datekey: 20220208,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-07",
      datekey: 20220207,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-06",
      datekey: 20220206,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-05",
      datekey: 20220205,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-04",
      datekey: 20220204,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-03",
      datekey: 20220203,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-02",
      datekey: 20220202,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-01",
      datekey: 20220201,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-31",
      datekey: 20220131,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-30",
      datekey: 20220130,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-29",
      datekey: 20220129,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-28",
      datekey: 20220128,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-27",
      datekey: 20220127,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-26",
      datekey: 20220126,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-25",
      datekey: 20220125,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-24",
      datekey: 20220124,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-23",
      datekey: 20220123,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-22",
      datekey: 20220122,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-21",
      datekey: 20220121,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-20",
      datekey: 20220120,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-19",
      datekey: 20220119,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-18",
      datekey: 20220118,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-17",
      datekey: 20220117,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-16",
      datekey: 20220116,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-15",
      datekey: 20220115,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-14",
      datekey: 20220114,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-13",
      datekey: 20220113,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-12",
      datekey: 20220112,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-11",
      datekey: 20220111,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-10",
      datekey: 20220110,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-09",
      datekey: 20220109,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-08",
      datekey: 20220108,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-07",
      datekey: 20220107,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-06",
      datekey: 20220106,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-05",
      datekey: 20220105,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-04",
      datekey: 20220104,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-03",
      datekey: 20220103,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-02",
      datekey: 20220102,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-01",
      datekey: 20220101,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
  ],
  reportDataPrevious: [
    {
      day: "2023-12-31",
      datekey: 20231231,
      value: 4816.520000000004,
      "Total Amount": 4816.520000000004,
      Customers: 133,
      Transactions: 211,
      Returns: 37.08,
      Discounts: 265.4599999999999,
    },
    {
      day: "2023-12-30",
      datekey: 20231230,
      value: 6432.989999999999,
      "Total Amount": 6432.989999999999,
      Customers: 124,
      Transactions: 194,
      Returns: 44.66,
      Discounts: 557.8300000000002,
    },
    {
      day: "2023-12-29",
      datekey: 20231229,
      value: 3711.109999999998,
      "Total Amount": 3711.109999999998,
      Customers: 100,
      Transactions: 153,
      Returns: 13.3,
      Discounts: 222.7800000000001,
    },
    {
      day: "2023-12-28",
      datekey: 20231228,
      value: 3760.269999999999,
      "Total Amount": 3760.269999999999,
      Customers: 102,
      Transactions: 149,
      Returns: 4,
      Discounts: 258.0899999999999,
    },
    {
      day: "2023-12-27",
      datekey: 20231227,
      value: 6052.729999999996,
      "Total Amount": 6052.729999999996,
      Customers: 98,
      Transactions: 151,
      Returns: 0,
      Discounts: 604.5700000000002,
    },
    {
      day: "2023-12-26",
      datekey: 20231226,
      value: 3248.469999999999,
      "Total Amount": 3248.469999999999,
      Customers: 82,
      Transactions: 132,
      Returns: 0,
      Discounts: 196.4600000000001,
    },
    {
      day: "2023-12-25",
      datekey: 20231225,
      value: 2028.710000000001,
      "Total Amount": 2028.710000000001,
      Customers: 52,
      Transactions: 95,
      Returns: 0,
      Discounts: 100.37,
    },
    {
      day: "2023-12-24",
      datekey: 20231224,
      value: 6150.459999999996,
      "Total Amount": 6150.459999999996,
      Customers: 160,
      Transactions: 220,
      Returns: 0,
      Discounts: 365.67,
    },
    {
      day: "2023-12-23",
      datekey: 20231223,
      value: 4618.109999999998,
      "Total Amount": 4618.109999999998,
      Customers: 119,
      Transactions: 181,
      Returns: 0,
      Discounts: 263.4099999999998,
    },
    {
      day: "2023-12-22",
      datekey: 20231222,
      value: 3652.749999999997,
      "Total Amount": 3652.749999999997,
      Customers: 117,
      Transactions: 154,
      Returns: 5.99,
      Discounts: 270.3100000000001,
    },
    {
      day: "2023-12-21",
      datekey: 20231221,
      value: 4653.339999999998,
      "Total Amount": 4653.339999999998,
      Customers: 111,
      Transactions: 149,
      Returns: 21.01,
      Discounts: 383.9,
    },
    {
      day: "2023-12-20",
      datekey: 20231220,
      value: 2635.369999999999,
      "Total Amount": 2635.369999999999,
      Customers: 86,
      Transactions: 117,
      Returns: 0,
      Discounts: 147.43,
    },
    {
      day: "2023-12-19",
      datekey: 20231219,
      value: 4566.899999999998,
      "Total Amount": 4566.899999999998,
      Customers: 88,
      Transactions: 123,
      Returns: 10.6,
      Discounts: 424.9200000000001,
    },
    {
      day: "2023-12-18",
      datekey: 20231218,
      value: 2519.410000000001,
      "Total Amount": 2519.410000000001,
      Customers: 72,
      Transactions: 115,
      Returns: 3.2,
      Discounts: 135.88,
    },
    {
      day: "2023-12-17",
      datekey: 20231217,
      value: 3771.569999999997,
      "Total Amount": 3771.569999999997,
      Customers: 114,
      Transactions: 153,
      Returns: 0,
      Discounts: 216.61,
    },
    {
      day: "2023-12-16",
      datekey: 20231216,
      value: 4368.84,
      "Total Amount": 4368.84,
      Customers: 124,
      Transactions: 180,
      Returns: 16.43,
      Discounts: 254.7400000000001,
    },
    {
      day: "2023-12-15",
      datekey: 20231215,
      value: 4146.949999999999,
      "Total Amount": 4146.949999999999,
      Customers: 103,
      Transactions: 149,
      Returns: 12.78,
      Discounts: 241.0700000000001,
    },
    {
      day: "2023-12-14",
      datekey: 20231214,
      value: 3087.090000000001,
      "Total Amount": 3087.090000000001,
      Customers: 93,
      Transactions: 144,
      Returns: 13.52,
      Discounts: 222.94,
    },
    {
      day: "2023-12-13",
      datekey: 20231213,
      value: 3762.18,
      "Total Amount": 3762.18,
      Customers: 89,
      Transactions: 134,
      Returns: 8.2,
      Discounts: 370.0799999999999,
    },
    {
      day: "2023-12-12",
      datekey: 20231212,
      value: 4071.199999999998,
      "Total Amount": 4071.199999999998,
      Customers: 98,
      Transactions: 152,
      Returns: 6.5,
      Discounts: 230.0999999999999,
    },
    {
      day: "2023-12-11",
      datekey: 20231211,
      value: 2061.11,
      "Total Amount": 2061.11,
      Customers: 73,
      Transactions: 102,
      Returns: 0,
      Discounts: 113.18,
    },
    {
      day: "2023-12-10",
      datekey: 20231210,
      value: 5269.499999999997,
      "Total Amount": 5269.499999999997,
      Customers: 155,
      Transactions: 222,
      Returns: 7.15,
      Discounts: 315.33,
    },
    {
      day: "2023-12-09",
      datekey: 20231209,
      value: 4857.87,
      "Total Amount": 4857.87,
      Customers: 100,
      Transactions: 167,
      Returns: 2.05,
      Discounts: 278.83,
    },
    {
      day: "2023-12-08",
      datekey: 20231208,
      value: 4889.47,
      "Total Amount": 4889.47,
      Customers: 112,
      Transactions: 154,
      Returns: 10.27,
      Discounts: 439.4200000000001,
    },
    {
      day: "2023-12-07",
      datekey: 20231207,
      value: 4319.51,
      "Total Amount": 4319.51,
      Customers: 98,
      Transactions: 139,
      Returns: 0,
      Discounts: 412.21,
    },
    {
      day: "2023-12-06",
      datekey: 20231206,
      value: 3461.79,
      "Total Amount": 3461.79,
      Customers: 83,
      Transactions: 131,
      Returns: 5.33,
      Discounts: 250.11,
    },
    {
      day: "2023-12-05",
      datekey: 20231205,
      value: 3571.88,
      "Total Amount": 3571.88,
      Customers: 83,
      Transactions: 131,
      Returns: 4.2,
      Discounts: 230.48,
    },
    {
      day: "2023-12-04",
      datekey: 20231204,
      value: 2842.489999999999,
      "Total Amount": 2842.489999999999,
      Customers: 78,
      Transactions: 114,
      Returns: 5.94,
      Discounts: 137.63,
    },
    {
      day: "2023-12-03",
      datekey: 20231203,
      value: 5064.579999999998,
      "Total Amount": 5064.579999999998,
      Customers: 119,
      Transactions: 175,
      Returns: 6.4,
      Discounts: 362.1499999999999,
    },
    {
      day: "2023-12-02",
      datekey: 20231202,
      value: 4110.829999999997,
      "Total Amount": 4110.829999999997,
      Customers: 100,
      Transactions: 161,
      Returns: 0,
      Discounts: 205.58,
    },
    {
      day: "2023-12-01",
      datekey: 20231201,
      value: 4205.669999999999,
      "Total Amount": 4205.669999999999,
      Customers: 104,
      Transactions: 157,
      Returns: 5.66,
      Discounts: 268.46,
    },
    {
      day: "2023-11-30",
      datekey: 20231130,
      value: 3773.599999999998,
      "Total Amount": 3773.599999999998,
      Customers: 75,
      Transactions: 139,
      Returns: 52.07000000000001,
      Discounts: 294.0099999999999,
    },
    {
      day: "2023-11-29",
      datekey: 20231129,
      value: 3602.52,
      "Total Amount": 3602.52,
      Customers: 91,
      Transactions: 131,
      Returns: 0,
      Discounts: 265.18,
    },
    {
      day: "2023-11-28",
      datekey: 20231128,
      value: 3639.68,
      "Total Amount": 3639.68,
      Customers: 84,
      Transactions: 153,
      Returns: 1.75,
      Discounts: 280.92,
    },
    {
      day: "2023-11-27",
      datekey: 20231127,
      value: 2444.84,
      "Total Amount": 2444.84,
      Customers: 82,
      Transactions: 125,
      Returns: 0,
      Discounts: 109.25,
    },
    {
      day: "2023-11-26",
      datekey: 20231126,
      value: 3854.659999999999,
      "Total Amount": 3854.659999999999,
      Customers: 107,
      Transactions: 153,
      Returns: 6.99,
      Discounts: 232.43,
    },
    {
      day: "2023-11-25",
      datekey: 20231125,
      value: 3397.109999999997,
      "Total Amount": 3397.109999999997,
      Customers: 83,
      Transactions: 145,
      Returns: 8.59,
      Discounts: 267.82,
    },
    {
      day: "2023-11-24",
      datekey: 20231124,
      value: 3162.500000000002,
      "Total Amount": 3162.500000000002,
      Customers: 83,
      Transactions: 136,
      Returns: 14.5,
      Discounts: 238.13,
    },
    {
      day: "2023-11-23",
      datekey: 20231123,
      value: 4688.46,
      "Total Amount": 4688.46,
      Customers: 110,
      Transactions: 184,
      Returns: 0,
      Discounts: 326.99,
    },
    {
      day: "2023-11-22",
      datekey: 20231122,
      value: 5076.360000000002,
      "Total Amount": 5076.360000000002,
      Customers: 140,
      Transactions: 211,
      Returns: 0,
      Discounts: 361.5799999999998,
    },
    {
      day: "2023-11-21",
      datekey: 20231121,
      value: 3781.48,
      "Total Amount": 3781.48,
      Customers: 77,
      Transactions: 115,
      Returns: 0,
      Discounts: 270,
    },
    {
      day: "2023-11-20",
      datekey: 20231120,
      value: 3118.200000000001,
      "Total Amount": 3118.200000000001,
      Customers: 103,
      Transactions: 133,
      Returns: 6,
      Discounts: 148.72,
    },
    {
      day: "2023-11-19",
      datekey: 20231119,
      value: 4000.159999999998,
      "Total Amount": 4000.159999999998,
      Customers: 94,
      Transactions: 167,
      Returns: 23.4,
      Discounts: 190.63,
    },
    {
      day: "2023-11-18",
      datekey: 20231118,
      value: 3937.879999999999,
      "Total Amount": 3937.879999999999,
      Customers: 114,
      Transactions: 187,
      Returns: 7.15,
      Discounts: 211.9,
    },
    {
      day: "2023-11-17",
      datekey: 20231117,
      value: 3367.499999999998,
      "Total Amount": 3367.499999999998,
      Customers: 86,
      Transactions: 150,
      Returns: 41.92,
      Discounts: 225.3600000000001,
    },
    {
      day: "2023-11-16",
      datekey: 20231116,
      value: 3754.6,
      "Total Amount": 3754.6,
      Customers: 96,
      Transactions: 150,
      Returns: 8.2,
      Discounts: 248.9399999999999,
    },
    {
      day: "2023-11-15",
      datekey: 20231115,
      value: 4102.179999999999,
      "Total Amount": 4102.179999999999,
      Customers: 90,
      Transactions: 134,
      Returns: 11,
      Discounts: 379.77,
    },
    {
      day: "2023-11-14",
      datekey: 20231114,
      value: 3178.479999999999,
      "Total Amount": 3178.479999999999,
      Customers: 76,
      Transactions: 125,
      Returns: 10.55,
      Discounts: 215.25,
    },
    {
      day: "2023-11-13",
      datekey: 20231113,
      value: 2223.98,
      "Total Amount": 2223.98,
      Customers: 84,
      Transactions: 117,
      Returns: 9.04,
      Discounts: 123.49,
    },
    {
      day: "2023-11-12",
      datekey: 20231112,
      value: 5720.279999999993,
      "Total Amount": 5720.279999999993,
      Customers: 167,
      Transactions: 268,
      Returns: 8.129999999999999,
      Discounts: 278.32,
    },
    {
      day: "2023-11-11",
      datekey: 20231111,
      value: 5936.259999999993,
      "Total Amount": 5936.259999999993,
      Customers: 128,
      Transactions: 232,
      Returns: 53.35,
      Discounts: 320.2899999999997,
    },
    {
      day: "2023-11-10",
      datekey: 20231110,
      value: 4508.819999999997,
      "Total Amount": 4508.819999999997,
      Customers: 120,
      Transactions: 198,
      Returns: 0,
      Discounts: 253.3700000000001,
    },
    {
      day: "2023-11-09",
      datekey: 20231109,
      value: 4194.040000000001,
      "Total Amount": 4194.040000000001,
      Customers: 94,
      Transactions: 148,
      Returns: 5.75,
      Discounts: 268.2699999999999,
    },
    {
      day: "2023-11-08",
      datekey: 20231108,
      value: 3656.98,
      "Total Amount": 3656.98,
      Customers: 94,
      Transactions: 146,
      Returns: 8.2,
      Discounts: 191.6500000000001,
    },
    {
      day: "2023-11-07",
      datekey: 20231107,
      value: 3889.479999999998,
      "Total Amount": 3889.479999999998,
      Customers: 62,
      Transactions: 129,
      Returns: 16.2,
      Discounts: 397.0599999999999,
    },
    {
      day: "2023-11-06",
      datekey: 20231106,
      value: 4564.88,
      "Total Amount": 4564.88,
      Customers: 80,
      Transactions: 116,
      Returns: 3,
      Discounts: 502.6399999999998,
    },
    {
      day: "2023-11-05",
      datekey: 20231105,
      value: 4730.809999999995,
      "Total Amount": 4730.809999999995,
      Customers: 130,
      Transactions: 188,
      Returns: 1.29,
      Discounts: 222.55,
    },
    {
      day: "2023-11-04",
      datekey: 20231104,
      value: 3871.679999999998,
      "Total Amount": 3871.679999999998,
      Customers: 100,
      Transactions: 182,
      Returns: 31.3,
      Discounts: 205.91,
    },
    {
      day: "2023-11-03",
      datekey: 20231103,
      value: 3865.579999999999,
      "Total Amount": 3865.579999999999,
      Customers: 113,
      Transactions: 154,
      Returns: 0,
      Discounts: 227.57,
    },
    {
      day: "2023-11-02",
      datekey: 20231102,
      value: 3525.52,
      "Total Amount": 3525.52,
      Customers: 91,
      Transactions: 135,
      Returns: 0,
      Discounts: 223.58,
    },
    {
      day: "2023-11-01",
      datekey: 20231101,
      value: 5042.070000000002,
      "Total Amount": 5042.070000000002,
      Customers: 103,
      Transactions: 161,
      Returns: 3.2,
      Discounts: 518.0399999999998,
    },
    {
      day: "2023-10-31",
      datekey: 20231031,
      value: 3425.579999999998,
      "Total Amount": 3425.579999999998,
      Customers: 82,
      Transactions: 134,
      Returns: 0,
      Discounts: 246.4,
    },
    {
      day: "2023-10-30",
      datekey: 20231030,
      value: 2457.99,
      "Total Amount": 2457.99,
      Customers: 71,
      Transactions: 110,
      Returns: 5.47,
      Discounts: 147,
    },
    {
      day: "2023-10-29",
      datekey: 20231029,
      value: 4640.029999999996,
      "Total Amount": 4640.029999999996,
      Customers: 136,
      Transactions: 204,
      Returns: 3.5,
      Discounts: 229.34,
    },
    {
      day: "2023-10-28",
      datekey: 20231028,
      value: 4039.3,
      "Total Amount": 4039.3,
      Customers: 109,
      Transactions: 188,
      Returns: 7.48,
      Discounts: 228.41,
    },
    {
      day: "2023-10-27",
      datekey: 20231027,
      value: 6613.529999999998,
      "Total Amount": 6613.529999999998,
      Customers: 111,
      Transactions: 180,
      Returns: 7.97,
      Discounts: 665.5,
    },
    {
      day: "2023-10-26",
      datekey: 20231026,
      value: 5141.699999999998,
      "Total Amount": 5141.699999999998,
      Customers: 107,
      Transactions: 173,
      Returns: 5,
      Discounts: 465.9699999999999,
    },
    {
      day: "2023-10-25",
      datekey: 20231025,
      value: 4055.399999999998,
      "Total Amount": 4055.399999999998,
      Customers: 105,
      Transactions: 165,
      Returns: 7.42,
      Discounts: 309.2099999999999,
    },
    {
      day: "2023-10-24",
      datekey: 20231024,
      value: 3924.599999999999,
      "Total Amount": 3924.599999999999,
      Customers: 110,
      Transactions: 165,
      Returns: 1.7,
      Discounts: 267.4500000000001,
    },
    {
      day: "2023-10-23",
      datekey: 20231023,
      value: 2672.01,
      "Total Amount": 2672.01,
      Customers: 99,
      Transactions: 134,
      Returns: 9.02,
      Discounts: 152.94,
    },
    {
      day: "2023-10-22",
      datekey: 20231022,
      value: 5872.779999999997,
      "Total Amount": 5872.779999999997,
      Customers: 139,
      Transactions: 215,
      Returns: 4.4,
      Discounts: 355.93,
    },
    {
      day: "2023-10-21",
      datekey: 20231021,
      value: 4257.059999999997,
      "Total Amount": 4257.059999999997,
      Customers: 121,
      Transactions: 198,
      Returns: 35.8,
      Discounts: 260.8700000000001,
    },
    {
      day: "2023-10-20",
      datekey: 20231020,
      value: 4818.709999999996,
      "Total Amount": 4818.709999999996,
      Customers: 113,
      Transactions: 177,
      Returns: 9.6,
      Discounts: 388.6800000000001,
    },
    {
      day: "2023-10-19",
      datekey: 20231019,
      value: 4254.069999999997,
      "Total Amount": 4254.069999999997,
      Customers: 98,
      Transactions: 171,
      Returns: 22.46,
      Discounts: 294.21,
    },
    {
      day: "2023-10-18",
      datekey: 20231018,
      value: 3788.979999999998,
      "Total Amount": 3788.979999999998,
      Customers: 109,
      Transactions: 170,
      Returns: 0,
      Discounts: 262.4199999999999,
    },
    {
      day: "2023-10-17",
      datekey: 20231017,
      value: 3370.849999999998,
      "Total Amount": 3370.849999999998,
      Customers: 85,
      Transactions: 138,
      Returns: 2.33,
      Discounts: 216.1700000000001,
    },
    {
      day: "2023-10-16",
      datekey: 20231016,
      value: 2468.990000000001,
      "Total Amount": 2468.990000000001,
      Customers: 70,
      Transactions: 126,
      Returns: 11.98,
      Discounts: 138.8500000000001,
    },
    {
      day: "2023-10-15",
      datekey: 20231015,
      value: 4820.199999999995,
      "Total Amount": 4820.199999999995,
      Customers: 130,
      Transactions: 200,
      Returns: 24.87,
      Discounts: 270.9900000000001,
    },
    {
      day: "2023-10-14",
      datekey: 20231014,
      value: 4812.289999999995,
      "Total Amount": 4812.289999999995,
      Customers: 123,
      Transactions: 214,
      Returns: 13.27,
      Discounts: 272.8399999999999,
    },
    {
      day: "2023-10-13",
      datekey: 20231013,
      value: 4482.969999999999,
      "Total Amount": 4482.969999999999,
      Customers: 109,
      Transactions: 177,
      Returns: 0,
      Discounts: 308.0400000000001,
    },
    {
      day: "2023-10-12",
      datekey: 20231012,
      value: 4891.029999999999,
      "Total Amount": 4891.029999999999,
      Customers: 79,
      Transactions: 141,
      Returns: 4.1,
      Discounts: 501.2600000000001,
    },
    {
      day: "2023-10-11",
      datekey: 20231011,
      value: 3626.449999999997,
      "Total Amount": 3626.449999999997,
      Customers: 103,
      Transactions: 155,
      Returns: 1.9,
      Discounts: 257.2000000000001,
    },
    {
      day: "2023-10-10",
      datekey: 20231010,
      value: 3742.739999999998,
      "Total Amount": 3742.739999999998,
      Customers: 79,
      Transactions: 148,
      Returns: 1.87,
      Discounts: 205.36,
    },
    {
      day: "2023-10-09",
      datekey: 20231009,
      value: 2934.710000000001,
      "Total Amount": 2934.710000000001,
      Customers: 83,
      Transactions: 118,
      Returns: 0,
      Discounts: 155.14,
    },
    {
      day: "2023-10-08",
      datekey: 20231008,
      value: 5238.729999999998,
      "Total Amount": 5238.729999999998,
      Customers: 136,
      Transactions: 199,
      Returns: 7.02,
      Discounts: 287.16,
    },
    {
      day: "2023-10-07",
      datekey: 20231007,
      value: 5586.569999999999,
      "Total Amount": 5586.569999999999,
      Customers: 137,
      Transactions: 212,
      Returns: 0,
      Discounts: 349.43,
    },
    {
      day: "2023-10-06",
      datekey: 20231006,
      value: 4004.279999999997,
      "Total Amount": 4004.279999999997,
      Customers: 102,
      Transactions: 164,
      Returns: 10,
      Discounts: 284.91,
    },
    {
      day: "2023-10-05",
      datekey: 20231005,
      value: 4107.789999999999,
      "Total Amount": 4107.789999999999,
      Customers: 87,
      Transactions: 149,
      Returns: 1.15,
      Discounts: 385.43,
    },
    {
      day: "2023-10-04",
      datekey: 20231004,
      value: 3549.459999999999,
      "Total Amount": 3549.459999999999,
      Customers: 90,
      Transactions: 139,
      Returns: 0,
      Discounts: 222.96,
    },
    {
      day: "2023-10-03",
      datekey: 20231003,
      value: 3536.23,
      "Total Amount": 3536.23,
      Customers: 79,
      Transactions: 145,
      Returns: 43.5,
      Discounts: 198.4500000000001,
    },
    {
      day: "2023-10-02",
      datekey: 20231002,
      value: 3153.189999999999,
      "Total Amount": 3153.189999999999,
      Customers: 87,
      Transactions: 127,
      Returns: 1.75,
      Discounts: 163.33,
    },
    {
      day: "2023-10-01",
      datekey: 20231001,
      value: 5034.969999999994,
      "Total Amount": 5034.969999999994,
      Customers: 136,
      Transactions: 213,
      Returns: 25.99,
      Discounts: 273.9600000000001,
    },
    {
      day: "2023-09-30",
      datekey: 20230930,
      value: 4226.109999999999,
      "Total Amount": 4226.109999999999,
      Customers: 111,
      Transactions: 185,
      Returns: 17.9,
      Discounts: 237.52,
    },
    {
      day: "2023-09-29",
      datekey: 20230929,
      value: 4697.419999999998,
      "Total Amount": 4697.419999999998,
      Customers: 97,
      Transactions: 173,
      Returns: 10.3,
      Discounts: 359.5500000000001,
    },
    {
      day: "2023-09-28",
      datekey: 20230928,
      value: 3999.439999999998,
      "Total Amount": 3999.439999999998,
      Customers: 99,
      Transactions: 162,
      Returns: 3.05,
      Discounts: 308.1399999999999,
    },
    {
      day: "2023-09-27",
      datekey: 20230927,
      value: 3073.51,
      "Total Amount": 3073.51,
      Customers: 61,
      Transactions: 118,
      Returns: 1.75,
      Discounts: 227.1100000000001,
    },
    {
      day: "2023-09-26",
      datekey: 20230926,
      value: 3589.56,
      "Total Amount": 3589.56,
      Customers: 79,
      Transactions: 148,
      Returns: 0,
      Discounts: 209.59,
    },
    {
      day: "2023-09-25",
      datekey: 20230925,
      value: 2728.72,
      "Total Amount": 2728.72,
      Customers: 84,
      Transactions: 129,
      Returns: 5.25,
      Discounts: 143.65,
    },
    {
      day: "2023-09-24",
      datekey: 20230924,
      value: 5151.010000000002,
      "Total Amount": 5151.010000000002,
      Customers: 133,
      Transactions: 215,
      Returns: 34.17,
      Discounts: 321.4799999999999,
    },
    {
      day: "2023-09-23",
      datekey: 20230923,
      value: 4468.279999999994,
      "Total Amount": 4468.279999999994,
      Customers: 119,
      Transactions: 213,
      Returns: 20.09,
      Discounts: 232.87,
    },
    {
      day: "2023-09-22",
      datekey: 20230922,
      value: 4585.479999999997,
      "Total Amount": 4585.479999999997,
      Customers: 92,
      Transactions: 184,
      Returns: 83.2,
      Discounts: 295.56,
    },
    {
      day: "2023-09-21",
      datekey: 20230921,
      value: 4305.939999999998,
      "Total Amount": 4305.939999999998,
      Customers: 65,
      Transactions: 137,
      Returns: 0,
      Discounts: 410.7700000000001,
    },
    {
      day: "2023-09-20",
      datekey: 20230920,
      value: 3240.27,
      "Total Amount": 3240.27,
      Customers: 93,
      Transactions: 147,
      Returns: 0,
      Discounts: 209.7500000000002,
    },
    {
      day: "2023-09-19",
      datekey: 20230919,
      value: 2660.349999999998,
      "Total Amount": 2660.349999999998,
      Customers: 68,
      Transactions: 144,
      Returns: 11,
      Discounts: 187.7700000000002,
    },
    {
      day: "2023-09-18",
      datekey: 20230918,
      value: 2450.379999999999,
      "Total Amount": 2450.379999999999,
      Customers: 74,
      Transactions: 148,
      Returns: 20.48,
      Discounts: 118.86,
    },
    {
      day: "2023-09-17",
      datekey: 20230917,
      value: 5947.769999999998,
      "Total Amount": 5947.769999999998,
      Customers: 142,
      Transactions: 237,
      Returns: 32.59,
      Discounts: 415.1100000000001,
    },
    {
      day: "2023-09-16",
      datekey: 20230916,
      value: 5420.049999999992,
      "Total Amount": 5420.049999999992,
      Customers: 138,
      Transactions: 213,
      Returns: 30.27,
      Discounts: 399.3800000000001,
    },
    {
      day: "2023-09-15",
      datekey: 20230915,
      value: 5069.499999999995,
      "Total Amount": 5069.499999999995,
      Customers: 110,
      Transactions: 176,
      Returns: 71.5,
      Discounts: 343.72,
    },
    {
      day: "2023-09-14",
      datekey: 20230914,
      value: 4068.429999999999,
      "Total Amount": 4068.429999999999,
      Customers: 96,
      Transactions: 147,
      Returns: 0,
      Discounts: 255.87,
    },
    {
      day: "2023-09-13",
      datekey: 20230913,
      value: 4317.190000000001,
      "Total Amount": 4317.190000000001,
      Customers: 95,
      Transactions: 153,
      Returns: 5.65,
      Discounts: 309.35,
    },
    {
      day: "2023-09-12",
      datekey: 20230912,
      value: 3221.719999999998,
      "Total Amount": 3221.719999999998,
      Customers: 70,
      Transactions: 131,
      Returns: 2.3,
      Discounts: 299.6100000000003,
    },
    {
      day: "2023-09-11",
      datekey: 20230911,
      value: 2448.190000000001,
      "Total Amount": 2448.190000000001,
      Customers: 79,
      Transactions: 122,
      Returns: 9,
      Discounts: 131.62,
    },
    {
      day: "2023-09-10",
      datekey: 20230910,
      value: 4627.009999999999,
      "Total Amount": 4627.009999999999,
      Customers: 130,
      Transactions: 211,
      Returns: 8.4,
      Discounts: 267.4,
    },
    {
      day: "2023-09-09",
      datekey: 20230909,
      value: 5641.489999999998,
      "Total Amount": 5641.489999999998,
      Customers: 130,
      Transactions: 198,
      Returns: 6.84,
      Discounts: 393.5799999999998,
    },
    {
      day: "2023-09-08",
      datekey: 20230908,
      value: 3829.509999999999,
      "Total Amount": 3829.509999999999,
      Customers: 108,
      Transactions: 168,
      Returns: 10.89,
      Discounts: 207.3499999999999,
    },
    {
      day: "2023-09-07",
      datekey: 20230907,
      value: 4251.16,
      "Total Amount": 4251.16,
      Customers: 76,
      Transactions: 142,
      Returns: 55.2,
      Discounts: 428.48,
    },
    {
      day: "2023-09-06",
      datekey: 20230906,
      value: 4674.949999999997,
      "Total Amount": 4674.949999999997,
      Customers: 109,
      Transactions: 164,
      Returns: 8.99,
      Discounts: 262.02,
    },
    {
      day: "2023-09-05",
      datekey: 20230905,
      value: 3257.55,
      "Total Amount": 3257.55,
      Customers: 74,
      Transactions: 127,
      Returns: 15.6,
      Discounts: 180.63,
    },
    {
      day: "2023-09-04",
      datekey: 20230904,
      value: 4372.679999999999,
      "Total Amount": 4372.679999999999,
      Customers: 87,
      Transactions: 165,
      Returns: 0,
      Discounts: 300.66,
    },
    {
      day: "2023-09-03",
      datekey: 20230903,
      value: 5241.799999999999,
      "Total Amount": 5241.799999999999,
      Customers: 117,
      Transactions: 223,
      Returns: 21.79,
      Discounts: 295.9400000000001,
    },
    {
      day: "2023-09-02",
      datekey: 20230902,
      value: 5211.760000000002,
      "Total Amount": 5211.760000000002,
      Customers: 128,
      Transactions: 228,
      Returns: 1.83,
      Discounts: 314.8199999999999,
    },
    {
      day: "2023-09-01",
      datekey: 20230901,
      value: 5547.919999999995,
      "Total Amount": 5547.919999999995,
      Customers: 104,
      Transactions: 200,
      Returns: 4.2,
      Discounts: 482.4300000000002,
    },
    {
      day: "2023-08-31",
      datekey: 20230831,
      value: 4056.719999999998,
      "Total Amount": 4056.719999999998,
      Customers: 105,
      Transactions: 184,
      Returns: 32.01000000000001,
      Discounts: 252.52,
    },
    {
      day: "2023-08-30",
      datekey: 20230830,
      value: 4547.349999999997,
      "Total Amount": 4547.349999999997,
      Customers: 94,
      Transactions: 162,
      Returns: 0,
      Discounts: 278.19,
    },
    {
      day: "2023-08-29",
      datekey: 20230829,
      value: 3611.87,
      "Total Amount": 3611.87,
      Customers: 86,
      Transactions: 152,
      Returns: 0,
      Discounts: 193.95,
    },
    {
      day: "2023-08-28",
      datekey: 20230828,
      value: 3586.529999999999,
      "Total Amount": 3586.529999999999,
      Customers: 89,
      Transactions: 135,
      Returns: 0,
      Discounts: 282.68,
    },
    {
      day: "2023-08-27",
      datekey: 20230827,
      value: 5989.529999999997,
      "Total Amount": 5989.529999999997,
      Customers: 118,
      Transactions: 216,
      Returns: 16.87,
      Discounts: 382.1700000000001,
    },
    {
      day: "2023-08-26",
      datekey: 20230826,
      value: 5328.859999999998,
      "Total Amount": 5328.859999999998,
      Customers: 106,
      Transactions: 209,
      Returns: 15.71,
      Discounts: 296.3199999999999,
    },
    {
      day: "2023-08-25",
      datekey: 20230825,
      value: 3683.539999999998,
      "Total Amount": 3683.539999999998,
      Customers: 81,
      Transactions: 166,
      Returns: 1.75,
      Discounts: 278.34,
    },
    {
      day: "2023-08-24",
      datekey: 20230824,
      value: 4299.569999999998,
      "Total Amount": 4299.569999999998,
      Customers: 78,
      Transactions: 162,
      Returns: 26.5,
      Discounts: 336.91,
    },
    {
      day: "2023-08-23",
      datekey: 20230823,
      value: 3149.379999999999,
      "Total Amount": 3149.379999999999,
      Customers: 69,
      Transactions: 142,
      Returns: 2.75,
      Discounts: 158.89,
    },
    {
      day: "2023-08-22",
      datekey: 20230822,
      value: 3807.49,
      "Total Amount": 3807.49,
      Customers: 85,
      Transactions: 143,
      Returns: 4.39,
      Discounts: 325.9299999999997,
    },
    {
      day: "2023-08-21",
      datekey: 20230821,
      value: 2442.409999999999,
      "Total Amount": 2442.409999999999,
      Customers: 70,
      Transactions: 120,
      Returns: 33.75,
      Discounts: 114.43,
    },
    {
      day: "2023-08-20",
      datekey: 20230820,
      value: 4047.779999999998,
      "Total Amount": 4047.779999999998,
      Customers: 108,
      Transactions: 207,
      Returns: 21.75,
      Discounts: 177.1799999999999,
    },
    {
      day: "2023-08-19",
      datekey: 20230819,
      value: 6210.199999999999,
      "Total Amount": 6210.199999999999,
      Customers: 140,
      Transactions: 240,
      Returns: 9.54,
      Discounts: 270.4399999999999,
    },
    {
      day: "2023-08-18",
      datekey: 20230818,
      value: 4860.36,
      "Total Amount": 4860.36,
      Customers: 111,
      Transactions: 193,
      Returns: 53.64999999999999,
      Discounts: 192.21,
    },
    {
      day: "2023-08-17",
      datekey: 20230817,
      value: 4163.79,
      "Total Amount": 4163.79,
      Customers: 109,
      Transactions: 166,
      Returns: 31.97,
      Discounts: 151.79,
    },
    {
      day: "2023-08-16",
      datekey: 20230816,
      value: 3763.429999999997,
      "Total Amount": 3763.429999999997,
      Customers: 94,
      Transactions: 149,
      Returns: 0,
      Discounts: 175.87,
    },
    {
      day: "2023-08-15",
      datekey: 20230815,
      value: 5585.919999999994,
      "Total Amount": 5585.919999999994,
      Customers: 100,
      Transactions: 166,
      Returns: 20,
      Discounts: 187.78,
    },
    {
      day: "2023-08-14",
      datekey: 20230814,
      value: 2730.439999999999,
      "Total Amount": 2730.439999999999,
      Customers: 71,
      Transactions: 145,
      Returns: 15.15,
      Discounts: 102.89,
    },
    {
      day: "2023-08-13",
      datekey: 20230813,
      value: 5784.3,
      "Total Amount": 5784.3,
      Customers: 149,
      Transactions: 238,
      Returns: 18.22,
      Discounts: 265.6900000000001,
    },
    {
      day: "2023-08-12",
      datekey: 20230812,
      value: 5944.909999999997,
      "Total Amount": 5944.909999999997,
      Customers: 129,
      Transactions: 221,
      Returns: 1.26,
      Discounts: 246.9600000000001,
    },
    {
      day: "2023-08-11",
      datekey: 20230811,
      value: 4453.06,
      "Total Amount": 4453.06,
      Customers: 102,
      Transactions: 168,
      Returns: 8.6,
      Discounts: 184.39,
    },
    {
      day: "2023-08-10",
      datekey: 20230810,
      value: 3826.110000000001,
      "Total Amount": 3826.110000000001,
      Customers: 72,
      Transactions: 129,
      Returns: 7.25,
      Discounts: 121.51,
    },
    {
      day: "2023-08-09",
      datekey: 20230809,
      value: 3019.710000000001,
      "Total Amount": 3019.710000000001,
      Customers: 74,
      Transactions: 140,
      Returns: 15.69,
      Discounts: 113.43,
    },
    {
      day: "2023-08-08",
      datekey: 20230808,
      value: 3280.809999999999,
      "Total Amount": 3280.809999999999,
      Customers: 79,
      Transactions: 131,
      Returns: 0,
      Discounts: 128.76,
    },
    {
      day: "2023-08-07",
      datekey: 20230807,
      value: 3639.169999999996,
      "Total Amount": 3639.169999999996,
      Customers: 88,
      Transactions: 147,
      Returns: 2,
      Discounts: 174.75,
    },
    {
      day: "2023-08-06",
      datekey: 20230806,
      value: 5567.559999999994,
      "Total Amount": 5567.559999999994,
      Customers: 113,
      Transactions: 213,
      Returns: 27.86,
      Discounts: 203.5299999999999,
    },
    {
      day: "2023-08-05",
      datekey: 20230805,
      value: 5866.569999999998,
      "Total Amount": 5866.569999999998,
      Customers: 121,
      Transactions: 198,
      Returns: 3.09,
      Discounts: 253.1599999999999,
    },
    {
      day: "2023-08-04",
      datekey: 20230804,
      value: 4459.219999999998,
      "Total Amount": 4459.219999999998,
      Customers: 88,
      Transactions: 144,
      Returns: 18.26,
      Discounts: 182.17,
    },
    {
      day: "2023-08-03",
      datekey: 20230803,
      value: 3314.409999999999,
      "Total Amount": 3314.409999999999,
      Customers: 78,
      Transactions: 143,
      Returns: 5.5,
      Discounts: 124.1599999999999,
    },
    {
      day: "2023-08-02",
      datekey: 20230802,
      value: 3849.409999999999,
      "Total Amount": 3849.409999999999,
      Customers: 82,
      Transactions: 157,
      Returns: 40,
      Discounts: 172.57,
    },
    {
      day: "2023-08-01",
      datekey: 20230801,
      value: 2828.97,
      "Total Amount": 2828.97,
      Customers: 65,
      Transactions: 122,
      Returns: 2.13,
      Discounts: 125.66,
    },
    {
      day: "2023-07-31",
      datekey: 20230731,
      value: 3833.71,
      "Total Amount": 3833.71,
      Customers: 91,
      Transactions: 153,
      Returns: 3.6,
      Discounts: 168.09,
    },
    {
      day: "2023-07-30",
      datekey: 20230730,
      value: 4634.819999999997,
      "Total Amount": 4634.819999999997,
      Customers: 105,
      Transactions: 191,
      Returns: 0,
      Discounts: 172.21,
    },
    {
      day: "2023-07-29",
      datekey: 20230729,
      value: 4856.239999999999,
      "Total Amount": 4856.239999999999,
      Customers: 95,
      Transactions: 172,
      Returns: 0,
      Discounts: 210.7699999999999,
    },
    {
      day: "2023-07-28",
      datekey: 20230728,
      value: 4381.639999999999,
      "Total Amount": 4381.639999999999,
      Customers: 84,
      Transactions: 161,
      Returns: 4.6,
      Discounts: 134.3200000000001,
    },
    {
      day: "2023-07-27",
      datekey: 20230727,
      value: 3876.869999999998,
      "Total Amount": 3876.869999999998,
      Customers: 72,
      Transactions: 129,
      Returns: 0,
      Discounts: 141.9400000000001,
    },
    {
      day: "2023-07-26",
      datekey: 20230726,
      value: 4206.800000000001,
      "Total Amount": 4206.800000000001,
      Customers: 74,
      Transactions: 162,
      Returns: 8.899999999999999,
      Discounts: 120.09,
    },
    {
      day: "2023-07-25",
      datekey: 20230725,
      value: 4431.549999999999,
      "Total Amount": 4431.549999999999,
      Customers: 75,
      Transactions: 145,
      Returns: 8.2,
      Discounts: 157.5,
    },
    {
      day: "2023-07-24",
      datekey: 20230724,
      value: 2962.17,
      "Total Amount": 2962.17,
      Customers: 56,
      Transactions: 130,
      Returns: 2.8,
      Discounts: 114.4600000000001,
    },
    {
      day: "2023-07-23",
      datekey: 20230723,
      value: 6559.439999999991,
      "Total Amount": 6559.439999999991,
      Customers: 89,
      Transactions: 221,
      Returns: 3.9,
      Discounts: 162.7100000000001,
    },
    {
      day: "2023-07-22",
      datekey: 20230722,
      value: 6459.409999999994,
      "Total Amount": 6459.409999999994,
      Customers: 100,
      Transactions: 230,
      Returns: 127.3,
      Discounts: 172.1,
    },
    {
      day: "2023-07-21",
      datekey: 20230721,
      value: 10745.63999999998,
      "Total Amount": 10745.63999999998,
      Customers: 93,
      Transactions: 299,
      Returns: 75.47999999999999,
      Discounts: 230.75,
    },
    {
      day: "2023-07-20",
      datekey: 20230720,
      value: 4280.5,
      "Total Amount": 4280.5,
      Customers: 78,
      Transactions: 159,
      Returns: 2.5,
      Discounts: 129.16,
    },
    {
      day: "2023-07-19",
      datekey: 20230719,
      value: 2465.48,
      "Total Amount": 2465.48,
      Customers: 82,
      Transactions: 117,
      Returns: 19.43,
      Discounts: 118.6,
    },
    {
      day: "2023-07-18",
      datekey: 20230718,
      value: 3209.329999999999,
      "Total Amount": 3209.329999999999,
      Customers: 89,
      Transactions: 121,
      Returns: 0,
      Discounts: 141.84,
    },
    {
      day: "2023-07-17",
      datekey: 20230717,
      value: 2928.899999999998,
      "Total Amount": 2928.899999999998,
      Customers: 74,
      Transactions: 137,
      Returns: 0,
      Discounts: 121.09,
    },
    {
      day: "2023-07-16",
      datekey: 20230716,
      value: 4061.159999999999,
      "Total Amount": 4061.159999999999,
      Customers: 103,
      Transactions: 185,
      Returns: 0,
      Discounts: 195.08,
    },
    {
      day: "2023-07-15",
      datekey: 20230715,
      value: 3978.57,
      "Total Amount": 3978.57,
      Customers: 112,
      Transactions: 202,
      Returns: 42.85,
      Discounts: 180.7400000000001,
    },
    {
      day: "2023-07-14",
      datekey: 20230714,
      value: 4370.009999999999,
      "Total Amount": 4370.009999999999,
      Customers: 92,
      Transactions: 167,
      Returns: 0,
      Discounts: 144.41,
    },
    {
      day: "2023-07-13",
      datekey: 20230713,
      value: 4529.549999999995,
      "Total Amount": 4529.549999999995,
      Customers: 72,
      Transactions: 139,
      Returns: 11.57,
      Discounts: 127.13,
    },
    {
      day: "2023-07-12",
      datekey: 20230712,
      value: 4524.459999999999,
      "Total Amount": 4524.459999999999,
      Customers: 78,
      Transactions: 153,
      Returns: 8.6,
      Discounts: 122.22,
    },
    {
      day: "2023-07-11",
      datekey: 20230711,
      value: 2631.19,
      "Total Amount": 2631.19,
      Customers: 49,
      Transactions: 112,
      Returns: 0,
      Discounts: 82.06000000000004,
    },
    {
      day: "2023-07-10",
      datekey: 20230710,
      value: 3567.189999999999,
      "Total Amount": 3567.189999999999,
      Customers: 82,
      Transactions: 171,
      Returns: 4.17,
      Discounts: 132.27,
    },
    {
      day: "2023-07-09",
      datekey: 20230709,
      value: 4770.229999999998,
      "Total Amount": 4770.229999999998,
      Customers: 120,
      Transactions: 190,
      Returns: 34.61,
      Discounts: 180.33,
    },
    {
      day: "2023-07-08",
      datekey: 20230708,
      value: 4187.799999999998,
      "Total Amount": 4187.799999999998,
      Customers: 90,
      Transactions: 165,
      Returns: 2.32,
      Discounts: 161.95,
    },
    {
      day: "2023-07-07",
      datekey: 20230707,
      value: 3964.969999999997,
      "Total Amount": 3964.969999999997,
      Customers: 101,
      Transactions: 175,
      Returns: 6.34,
      Discounts: 189.6900000000001,
    },
    {
      day: "2023-07-06",
      datekey: 20230706,
      value: 2709.08,
      "Total Amount": 2709.08,
      Customers: 58,
      Transactions: 125,
      Returns: 0,
      Discounts: 119.44,
    },
    {
      day: "2023-07-05",
      datekey: 20230705,
      value: 2614.17,
      "Total Amount": 2614.17,
      Customers: 60,
      Transactions: 127,
      Returns: 6.199999999999999,
      Discounts: 89.95,
    },
    {
      day: "2023-07-04",
      datekey: 20230704,
      value: 2277.230000000001,
      "Total Amount": 2277.230000000001,
      Customers: 42,
      Transactions: 96,
      Returns: 0,
      Discounts: 68.48999999999997,
    },
    {
      day: "2023-07-03",
      datekey: 20230703,
      value: 3050.450000000001,
      "Total Amount": 3050.450000000001,
      Customers: 78,
      Transactions: 144,
      Returns: 1.78,
      Discounts: 132.68,
    },
    {
      day: "2023-07-02",
      datekey: 20230702,
      value: 4811.309999999996,
      "Total Amount": 4811.309999999996,
      Customers: 92,
      Transactions: 188,
      Returns: 6.71,
      Discounts: 157.84,
    },
    {
      day: "2023-07-01",
      datekey: 20230701,
      value: 3704.899999999998,
      "Total Amount": 3704.899999999998,
      Customers: 88,
      Transactions: 158,
      Returns: 9.77,
      Discounts: 139.15,
    },
    {
      day: "2023-06-30",
      datekey: 20230630,
      value: 3208.599999999998,
      "Total Amount": 3208.599999999998,
      Customers: 68,
      Transactions: 125,
      Returns: 12.28,
      Discounts: 96.27,
    },
    {
      day: "2023-06-29",
      datekey: 20230629,
      value: 3932.549999999998,
      "Total Amount": 3932.549999999998,
      Customers: 81,
      Transactions: 150,
      Returns: 12.7,
      Discounts: 127.56,
    },
    {
      day: "2023-06-28",
      datekey: 20230628,
      value: 2466.320000000001,
      "Total Amount": 2466.320000000001,
      Customers: 60,
      Transactions: 109,
      Returns: 44.14,
      Discounts: 101.42,
    },
    {
      day: "2023-06-27",
      datekey: 20230627,
      value: 3708.779999999999,
      "Total Amount": 3708.779999999999,
      Customers: 88,
      Transactions: 148,
      Returns: 4.5,
      Discounts: 227.79,
    },
    {
      day: "2023-06-26",
      datekey: 20230626,
      value: 3782.069999999997,
      "Total Amount": 3782.069999999997,
      Customers: 84,
      Transactions: 127,
      Returns: 14,
      Discounts: 207.21,
    },
    {
      day: "2023-06-25",
      datekey: 20230625,
      value: 4529.339999999997,
      "Total Amount": 4529.339999999997,
      Customers: 103,
      Transactions: 183,
      Returns: 28,
      Discounts: 191.62,
    },
    {
      day: "2023-06-24",
      datekey: 20230624,
      value: 4764.199999999996,
      "Total Amount": 4764.199999999996,
      Customers: 105,
      Transactions: 173,
      Returns: 63.83,
      Discounts: 201.55,
    },
    {
      day: "2023-06-23",
      datekey: 20230623,
      value: 3453.05,
      "Total Amount": 3453.05,
      Customers: 78,
      Transactions: 134,
      Returns: 0,
      Discounts: 116.86,
    },
    {
      day: "2023-06-22",
      datekey: 20230622,
      value: 3662.83,
      "Total Amount": 3662.83,
      Customers: 83,
      Transactions: 134,
      Returns: 0.5,
      Discounts: 163.35,
    },
    {
      day: "2023-06-21",
      datekey: 20230621,
      value: 2800.909999999999,
      "Total Amount": 2800.909999999999,
      Customers: 73,
      Transactions: 112,
      Returns: 2.31,
      Discounts: 115.54,
    },
    {
      day: "2023-06-20",
      datekey: 20230620,
      value: 2267.46,
      "Total Amount": 2267.46,
      Customers: 70,
      Transactions: 126,
      Returns: 23.66,
      Discounts: 116.14,
    },
    {
      day: "2023-06-19",
      datekey: 20230619,
      value: 2550.679999999999,
      "Total Amount": 2550.679999999999,
      Customers: 71,
      Transactions: 120,
      Returns: 0,
      Discounts: 99.90000000000002,
    },
    {
      day: "2023-06-18",
      datekey: 20230618,
      value: 4191.85,
      "Total Amount": 4191.85,
      Customers: 114,
      Transactions: 162,
      Returns: 1.95,
      Discounts: 180.3099999999999,
    },
    {
      day: "2023-06-17",
      datekey: 20230617,
      value: 4478.489999999996,
      "Total Amount": 4478.489999999996,
      Customers: 91,
      Transactions: 170,
      Returns: 42.01,
      Discounts: 177.7600000000001,
    },
    {
      day: "2023-06-16",
      datekey: 20230616,
      value: 4078.439999999998,
      "Total Amount": 4078.439999999998,
      Customers: 89,
      Transactions: 151,
      Returns: 20.95,
      Discounts: 169.2,
    },
    {
      day: "2023-06-15",
      datekey: 20230615,
      value: 3249,
      "Total Amount": 3249,
      Customers: 76,
      Transactions: 135,
      Returns: 0,
      Discounts: 136.15,
    },
    {
      day: "2023-06-14",
      datekey: 20230614,
      value: 2774.52,
      "Total Amount": 2774.52,
      Customers: 51,
      Transactions: 114,
      Returns: 3,
      Discounts: 109.45,
    },
    {
      day: "2023-06-13",
      datekey: 20230613,
      value: 3206.809999999999,
      "Total Amount": 3206.809999999999,
      Customers: 65,
      Transactions: 116,
      Returns: 11.15,
      Discounts: 114.58,
    },
    {
      day: "2023-06-12",
      datekey: 20230612,
      value: 2797.570000000001,
      "Total Amount": 2797.570000000001,
      Customers: 71,
      Transactions: 120,
      Returns: 0.55,
      Discounts: 133.41,
    },
    {
      day: "2023-06-11",
      datekey: 20230611,
      value: 3668.800000000002,
      "Total Amount": 3668.800000000002,
      Customers: 120,
      Transactions: 168,
      Returns: 9.39,
      Discounts: 176.2900000000001,
    },
    {
      day: "2023-06-10",
      datekey: 20230610,
      value: 4108.459999999999,
      "Total Amount": 4108.459999999999,
      Customers: 82,
      Transactions: 185,
      Returns: 4.45,
      Discounts: 141.57,
    },
    {
      day: "2023-06-09",
      datekey: 20230609,
      value: 3521.199999999997,
      "Total Amount": 3521.199999999997,
      Customers: 71,
      Transactions: 156,
      Returns: 4.97,
      Discounts: 133.25,
    },
    {
      day: "2023-06-08",
      datekey: 20230608,
      value: 3338.510000000001,
      "Total Amount": 3338.510000000001,
      Customers: 61,
      Transactions: 138,
      Returns: 0,
      Discounts: 121.36,
    },
    {
      day: "2023-06-07",
      datekey: 20230607,
      value: 3084.560000000001,
      "Total Amount": 3084.560000000001,
      Customers: 85,
      Transactions: 145,
      Returns: 27.71,
      Discounts: 112.15,
    },
    {
      day: "2023-06-06",
      datekey: 20230606,
      value: 3218.469999999999,
      "Total Amount": 3218.469999999999,
      Customers: 63,
      Transactions: 131,
      Returns: 2.75,
      Discounts: 154.08,
    },
    {
      day: "2023-06-05",
      datekey: 20230605,
      value: 2839.48,
      "Total Amount": 2839.48,
      Customers: 59,
      Transactions: 121,
      Returns: 8.1,
      Discounts: 107.31,
    },
    {
      day: "2023-06-04",
      datekey: 20230604,
      value: 3971.909999999999,
      "Total Amount": 3971.909999999999,
      Customers: 86,
      Transactions: 168,
      Returns: 35.17,
      Discounts: 140.64,
    },
    {
      day: "2023-06-03",
      datekey: 20230603,
      value: 4271.119999999998,
      "Total Amount": 4271.119999999998,
      Customers: 92,
      Transactions: 159,
      Returns: 8.25,
      Discounts: 156.7000000000001,
    },
    {
      day: "2023-06-02",
      datekey: 20230602,
      value: 3756.930000000001,
      "Total Amount": 3756.930000000001,
      Customers: 75,
      Transactions: 144,
      Returns: 7.5,
      Discounts: 130.38,
    },
    {
      day: "2023-06-01",
      datekey: 20230601,
      value: 2991.319999999997,
      "Total Amount": 2991.319999999997,
      Customers: 65,
      Transactions: 131,
      Returns: 19.66,
      Discounts: 111.06,
    },
    {
      day: "2023-05-31",
      datekey: 20230531,
      value: 3823.969999999999,
      "Total Amount": 3823.969999999999,
      Customers: 77,
      Transactions: 142,
      Returns: 6.109999999999999,
      Discounts: 176.0599999999999,
    },
    {
      day: "2023-05-30",
      datekey: 20230530,
      value: 2729.240000000001,
      "Total Amount": 2729.240000000001,
      Customers: 73,
      Transactions: 137,
      Returns: 0,
      Discounts: 132.27,
    },
    {
      day: "2023-05-29",
      datekey: 20230529,
      value: 2988.97,
      "Total Amount": 2988.97,
      Customers: 69,
      Transactions: 127,
      Returns: 5.2,
      Discounts: 106.78,
    },
    {
      day: "2023-05-28",
      datekey: 20230528,
      value: 4097.359999999997,
      "Total Amount": 4097.359999999997,
      Customers: 100,
      Transactions: 176,
      Returns: 0,
      Discounts: 155.57,
    },
    {
      day: "2023-05-27",
      datekey: 20230527,
      value: 4649.289999999999,
      "Total Amount": 4649.289999999999,
      Customers: 92,
      Transactions: 191,
      Returns: 18.6,
      Discounts: 194.21,
    },
    {
      day: "2023-05-26",
      datekey: 20230526,
      value: 4930.809999999999,
      "Total Amount": 4930.809999999999,
      Customers: 102,
      Transactions: 192,
      Returns: 20.79,
      Discounts: 176.0300000000001,
    },
    {
      day: "2023-05-25",
      datekey: 20230525,
      value: 4383.919999999999,
      "Total Amount": 4383.919999999999,
      Customers: 110,
      Transactions: 189,
      Returns: 16.85,
      Discounts: 188.7900000000001,
    },
    {
      day: "2023-05-24",
      datekey: 20230524,
      value: 3343.979999999998,
      "Total Amount": 3343.979999999998,
      Customers: 65,
      Transactions: 138,
      Returns: 22.08,
      Discounts: 115.21,
    },
    {
      day: "2023-05-23",
      datekey: 20230523,
      value: 3628.649999999999,
      "Total Amount": 3628.649999999999,
      Customers: 70,
      Transactions: 143,
      Returns: 1.73,
      Discounts: 129.72,
    },
    {
      day: "2023-05-22",
      datekey: 20230522,
      value: 3491.010000000001,
      "Total Amount": 3491.010000000001,
      Customers: 78,
      Transactions: 156,
      Returns: 2.5,
      Discounts: 113.63,
    },
    {
      day: "2023-05-21",
      datekey: 20230521,
      value: 7024.989999999998,
      "Total Amount": 7024.989999999998,
      Customers: 124,
      Transactions: 244,
      Returns: 4.45,
      Discounts: 262.96,
    },
    {
      day: "2023-05-20",
      datekey: 20230520,
      value: 5924.619999999994,
      "Total Amount": 5924.619999999994,
      Customers: 88,
      Transactions: 221,
      Returns: 5.7,
      Discounts: 180.6500000000001,
    },
    {
      day: "2023-05-19",
      datekey: 20230519,
      value: 4862.669999999997,
      "Total Amount": 4862.669999999997,
      Customers: 85,
      Transactions: 192,
      Returns: 21.17,
      Discounts: 145.25,
    },
    {
      day: "2023-05-18",
      datekey: 20230518,
      value: 3762.08,
      "Total Amount": 3762.08,
      Customers: 55,
      Transactions: 161,
      Returns: 0,
      Discounts: 113.33,
    },
    {
      day: "2023-05-17",
      datekey: 20230517,
      value: 4080.979999999998,
      "Total Amount": 4080.979999999998,
      Customers: 75,
      Transactions: 166,
      Returns: 21.08,
      Discounts: 129.21,
    },
    {
      day: "2023-05-16",
      datekey: 20230516,
      value: 3736.990000000001,
      "Total Amount": 3736.990000000001,
      Customers: 68,
      Transactions: 148,
      Returns: 26.65,
      Discounts: 129.42,
    },
    {
      day: "2023-05-15",
      datekey: 20230515,
      value: 2855.99,
      "Total Amount": 2855.99,
      Customers: 44,
      Transactions: 132,
      Returns: 5.38,
      Discounts: 85.40999999999998,
    },
    {
      day: "2023-05-14",
      datekey: 20230514,
      value: 5877.829999999996,
      "Total Amount": 5877.829999999996,
      Customers: 105,
      Transactions: 213,
      Returns: 3.5,
      Discounts: 205.5299999999999,
    },
    {
      day: "2023-05-13",
      datekey: 20230513,
      value: 5877.969999999996,
      "Total Amount": 5877.969999999996,
      Customers: 91,
      Transactions: 219,
      Returns: 65.33,
      Discounts: 184.8899999999999,
    },
    {
      day: "2023-05-12",
      datekey: 20230512,
      value: 4890.319999999997,
      "Total Amount": 4890.319999999997,
      Customers: 86,
      Transactions: 189,
      Returns: 0,
      Discounts: 141.83,
    },
    {
      day: "2023-05-11",
      datekey: 20230511,
      value: 5270.209999999996,
      "Total Amount": 5270.209999999996,
      Customers: 72,
      Transactions: 186,
      Returns: 1.74,
      Discounts: 156.5900000000001,
    },
    {
      day: "2023-05-10",
      datekey: 20230510,
      value: 4271.380000000001,
      "Total Amount": 4271.380000000001,
      Customers: 81,
      Transactions: 165,
      Returns: 4.09,
      Discounts: 121.86,
    },
    {
      day: "2023-05-09",
      datekey: 20230509,
      value: 4091.059999999998,
      "Total Amount": 4091.059999999998,
      Customers: 83,
      Transactions: 152,
      Returns: 0,
      Discounts: 148.0700000000001,
    },
    {
      day: "2023-05-08",
      datekey: 20230508,
      value: 3811.939999999998,
      "Total Amount": 3811.939999999998,
      Customers: 94,
      Transactions: 166,
      Returns: 7.45,
      Discounts: 146.5,
    },
    {
      day: "2023-05-07",
      datekey: 20230507,
      value: 5688.579999999996,
      "Total Amount": 5688.579999999996,
      Customers: 107,
      Transactions: 235,
      Returns: 78.25,
      Discounts: 199.04,
    },
    {
      day: "2023-05-06",
      datekey: 20230506,
      value: 6266.209999999996,
      "Total Amount": 6266.209999999996,
      Customers: 113,
      Transactions: 243,
      Returns: 37.57,
      Discounts: 265.8200000000001,
    },
    {
      day: "2023-05-05",
      datekey: 20230505,
      value: 5284.06,
      "Total Amount": 5284.06,
      Customers: 108,
      Transactions: 219,
      Returns: 44.14,
      Discounts: 210.8899999999999,
    },
    {
      day: "2023-05-04",
      datekey: 20230504,
      value: 4714.389999999996,
      "Total Amount": 4714.389999999996,
      Customers: 93,
      Transactions: 197,
      Returns: 13.6,
      Discounts: 180.13,
    },
    {
      day: "2023-05-03",
      datekey: 20230503,
      value: 3873.739999999998,
      "Total Amount": 3873.739999999998,
      Customers: 86,
      Transactions: 155,
      Returns: 0,
      Discounts: 153.14,
    },
    {
      day: "2023-05-02",
      datekey: 20230502,
      value: 4111.929999999999,
      "Total Amount": 4111.929999999999,
      Customers: 82,
      Transactions: 139,
      Returns: 0,
      Discounts: 187.31,
    },
    {
      day: "2023-05-01",
      datekey: 20230501,
      value: 2928.460000000001,
      "Total Amount": 2928.460000000001,
      Customers: 58,
      Transactions: 127,
      Returns: 6.6,
      Discounts: 91.94000000000004,
    },
    {
      day: "2023-04-30",
      datekey: 20230430,
      value: 5990.809999999998,
      "Total Amount": 5990.809999999998,
      Customers: 107,
      Transactions: 222,
      Returns: 5.46,
      Discounts: 207.06,
    },
    {
      day: "2023-04-29",
      datekey: 20230429,
      value: 5565.259999999997,
      "Total Amount": 5565.259999999997,
      Customers: 106,
      Transactions: 234,
      Returns: 33.05,
      Discounts: 181.3900000000001,
    },
    {
      day: "2023-04-28",
      datekey: 20230428,
      value: 4553.809999999999,
      "Total Amount": 4553.809999999999,
      Customers: 101,
      Transactions: 174,
      Returns: 8.2,
      Discounts: 216.6000000000001,
    },
    {
      day: "2023-04-27",
      datekey: 20230427,
      value: 4367.629999999997,
      "Total Amount": 4367.629999999997,
      Customers: 93,
      Transactions: 179,
      Returns: 97.17,
      Discounts: 191.06,
    },
    {
      day: "2023-04-26",
      datekey: 20230426,
      value: 3767.429999999999,
      "Total Amount": 3767.429999999999,
      Customers: 84,
      Transactions: 151,
      Returns: 2.45,
      Discounts: 136.4,
    },
    {
      day: "2023-04-25",
      datekey: 20230425,
      value: 3850.599999999998,
      "Total Amount": 3850.599999999998,
      Customers: 72,
      Transactions: 154,
      Returns: 4.26,
      Discounts: 148.7,
    },
    {
      day: "2023-04-24",
      datekey: 20230424,
      value: 3131.729999999998,
      "Total Amount": 3131.729999999998,
      Customers: 81,
      Transactions: 151,
      Returns: 39.58000000000001,
      Discounts: 143.09,
    },
    {
      day: "2023-04-23",
      datekey: 20230423,
      value: 5596.109999999998,
      "Total Amount": 5596.109999999998,
      Customers: 103,
      Transactions: 217,
      Returns: 20.9,
      Discounts: 211.6299999999998,
    },
    {
      day: "2023-04-22",
      datekey: 20230422,
      value: 6564.269999999993,
      "Total Amount": 6564.269999999993,
      Customers: 101,
      Transactions: 245,
      Returns: 9,
      Discounts: 207.66,
    },
    {
      day: "2023-04-21",
      datekey: 20230421,
      value: 4374.269999999998,
      "Total Amount": 4374.269999999998,
      Customers: 75,
      Transactions: 189,
      Returns: 0,
      Discounts: 173.45,
    },
    {
      day: "2023-04-20",
      datekey: 20230420,
      value: 6677.539999999995,
      "Total Amount": 6677.539999999995,
      Customers: 110,
      Transactions: 223,
      Returns: 10.8,
      Discounts: 283.6,
    },
    {
      day: "2023-04-19",
      datekey: 20230419,
      value: 4633.449999999997,
      "Total Amount": 4633.449999999997,
      Customers: 107,
      Transactions: 176,
      Returns: 1.85,
      Discounts: 199.59,
    },
    {
      day: "2023-04-18",
      datekey: 20230418,
      value: 3883.580000000002,
      "Total Amount": 3883.580000000002,
      Customers: 87,
      Transactions: 158,
      Returns: 6.17,
      Discounts: 142.25,
    },
    {
      day: "2023-04-17",
      datekey: 20230417,
      value: 3839.339999999999,
      "Total Amount": 3839.339999999999,
      Customers: 93,
      Transactions: 163,
      Returns: 0,
      Discounts: 164.89,
    },
    {
      day: "2023-04-16",
      datekey: 20230416,
      value: 6665.929999999997,
      "Total Amount": 6665.929999999997,
      Customers: 121,
      Transactions: 263,
      Returns: 12.69,
      Discounts: 222.09,
    },
    {
      day: "2023-04-15",
      datekey: 20230415,
      value: 5853.330000000001,
      "Total Amount": 5853.330000000001,
      Customers: 105,
      Transactions: 217,
      Returns: 42.93,
      Discounts: 282.53,
    },
    {
      day: "2023-04-14",
      datekey: 20230414,
      value: 5578.959999999996,
      "Total Amount": 5578.959999999996,
      Customers: 108,
      Transactions: 205,
      Returns: 21.91,
      Discounts: 214.8500000000001,
    },
    {
      day: "2023-04-13",
      datekey: 20230413,
      value: 3490.949999999999,
      "Total Amount": 3490.949999999999,
      Customers: 93,
      Transactions: 158,
      Returns: 1.55,
      Discounts: 139.15,
    },
    {
      day: "2023-04-12",
      datekey: 20230412,
      value: 3576.09,
      "Total Amount": 3576.09,
      Customers: 86,
      Transactions: 153,
      Returns: 14.63,
      Discounts: 124.34,
    },
    {
      day: "2023-04-11",
      datekey: 20230411,
      value: 4307.830000000001,
      "Total Amount": 4307.830000000001,
      Customers: 89,
      Transactions: 167,
      Returns: 7.5,
      Discounts: 172.1,
    },
    {
      day: "2023-04-10",
      datekey: 20230410,
      value: 3312.819999999999,
      "Total Amount": 3312.819999999999,
      Customers: 93,
      Transactions: 170,
      Returns: 9.1,
      Discounts: 140.63,
    },
    {
      day: "2023-04-09",
      datekey: 20230409,
      value: 6652.359999999993,
      "Total Amount": 6652.359999999993,
      Customers: 133,
      Transactions: 266,
      Returns: 0,
      Discounts: 228.62,
    },
    {
      day: "2023-04-08",
      datekey: 20230408,
      value: 7298.299999999997,
      "Total Amount": 7298.299999999997,
      Customers: 151,
      Transactions: 260,
      Returns: 18.95,
      Discounts: 339.1999999999999,
    },
    {
      day: "2023-04-07",
      datekey: 20230407,
      value: 5605.299999999998,
      "Total Amount": 5605.299999999998,
      Customers: 112,
      Transactions: 226,
      Returns: 14.45,
      Discounts: 229.39,
    },
    {
      day: "2023-04-06",
      datekey: 20230406,
      value: 4845.279999999995,
      "Total Amount": 4845.279999999995,
      Customers: 96,
      Transactions: 186,
      Returns: 20.15,
      Discounts: 203.25,
    },
    {
      day: "2023-04-05",
      datekey: 20230405,
      value: 3239.049999999998,
      "Total Amount": 3239.049999999998,
      Customers: 78,
      Transactions: 144,
      Returns: 9.19,
      Discounts: 131.23,
    },
    {
      day: "2023-04-04",
      datekey: 20230404,
      value: 3963.249999999995,
      "Total Amount": 3963.249999999995,
      Customers: 63,
      Transactions: 186,
      Returns: 2.53,
      Discounts: 101.05,
    },
    {
      day: "2023-04-03",
      datekey: 20230403,
      value: 3181.239999999998,
      "Total Amount": 3181.239999999998,
      Customers: 79,
      Transactions: 152,
      Returns: 31.64,
      Discounts: 126.14,
    },
    {
      day: "2023-04-02",
      datekey: 20230402,
      value: 6543.489999999996,
      "Total Amount": 6543.489999999996,
      Customers: 123,
      Transactions: 261,
      Returns: 31.81,
      Discounts: 253.6800000000001,
    },
    {
      day: "2023-04-01",
      datekey: 20230401,
      value: 5809.589999999997,
      "Total Amount": 5809.589999999997,
      Customers: 98,
      Transactions: 225,
      Returns: 17.85,
      Discounts: 223.2400000000001,
    },
    {
      day: "2023-03-31",
      datekey: 20230331,
      value: 4680.469999999997,
      "Total Amount": 4680.469999999997,
      Customers: 118,
      Transactions: 182,
      Returns: 5.75,
      Discounts: 222.51,
    },
    {
      day: "2023-03-30",
      datekey: 20230330,
      value: 4222.91,
      "Total Amount": 4222.91,
      Customers: 84,
      Transactions: 176,
      Returns: 16.95,
      Discounts: 179.62,
    },
    {
      day: "2023-03-29",
      datekey: 20230329,
      value: 4195.949999999998,
      "Total Amount": 4195.949999999998,
      Customers: 86,
      Transactions: 182,
      Returns: 3.4,
      Discounts: 153.7200000000001,
    },
    {
      day: "2023-03-28",
      datekey: 20230328,
      value: 3597.6,
      "Total Amount": 3597.6,
      Customers: 84,
      Transactions: 154,
      Returns: 12.33,
      Discounts: 132.03,
    },
    {
      day: "2023-03-27",
      datekey: 20230327,
      value: 2891.669999999998,
      "Total Amount": 2891.669999999998,
      Customers: 57,
      Transactions: 131,
      Returns: 0,
      Discounts: 119.2799999999999,
    },
    {
      day: "2023-03-26",
      datekey: 20230326,
      value: 5600.459999999997,
      "Total Amount": 5600.459999999997,
      Customers: 108,
      Transactions: 223,
      Returns: 29.4,
      Discounts: 234.4100000000001,
    },
    {
      day: "2023-03-25",
      datekey: 20230325,
      value: 6258.569999999996,
      "Total Amount": 6258.569999999996,
      Customers: 117,
      Transactions: 221,
      Returns: 18.98,
      Discounts: 221.92,
    },
    {
      day: "2023-03-24",
      datekey: 20230324,
      value: 5439.779999999999,
      "Total Amount": 5439.779999999999,
      Customers: 74,
      Transactions: 217,
      Returns: 18.71,
      Discounts: 169.6000000000001,
    },
    {
      day: "2023-03-23",
      datekey: 20230323,
      value: 5484.429999999997,
      "Total Amount": 5484.429999999997,
      Customers: 56,
      Transactions: 206,
      Returns: 37.4,
      Discounts: 130.33,
    },
    {
      day: "2023-03-22",
      datekey: 20230322,
      value: 4689.949999999995,
      "Total Amount": 4689.949999999995,
      Customers: 71,
      Transactions: 161,
      Returns: 3,
      Discounts: 181.03,
    },
    {
      day: "2023-03-21",
      datekey: 20230321,
      value: 4679.429999999997,
      "Total Amount": 4679.429999999997,
      Customers: 62,
      Transactions: 230,
      Returns: 4.55,
      Discounts: 85.77,
    },
    {
      day: "2023-03-20",
      datekey: 20230320,
      value: 3942.719999999998,
      "Total Amount": 3942.719999999998,
      Customers: 50,
      Transactions: 148,
      Returns: 0,
      Discounts: 110.43,
    },
    {
      day: "2023-03-19",
      datekey: 20230319,
      value: 5344.339999999996,
      "Total Amount": 5344.339999999996,
      Customers: 64,
      Transactions: 205,
      Returns: 12.39,
      Discounts: 121.47,
    },
    {
      day: "2023-03-18",
      datekey: 20230318,
      value: 4816.479999999996,
      "Total Amount": 4816.479999999996,
      Customers: 69,
      Transactions: 212,
      Returns: 23.16,
      Discounts: 126.72,
    },
    {
      day: "2023-03-17",
      datekey: 20230317,
      value: 5005.539999999996,
      "Total Amount": 5005.539999999996,
      Customers: 54,
      Transactions: 188,
      Returns: 0,
      Discounts: 110.63,
    },
    {
      day: "2023-03-16",
      datekey: 20230316,
      value: 4824.570000000001,
      "Total Amount": 4824.570000000001,
      Customers: 64,
      Transactions: 169,
      Returns: 16,
      Discounts: 149.03,
    },
    {
      day: "2023-03-15",
      datekey: 20230315,
      value: 3953.479999999998,
      "Total Amount": 3953.479999999998,
      Customers: 47,
      Transactions: 133,
      Returns: 0,
      Discounts: 109.32,
    },
    {
      day: "2023-03-14",
      datekey: 20230314,
      value: 3883.909999999997,
      "Total Amount": 3883.909999999997,
      Customers: 47,
      Transactions: 149,
      Returns: 16.18,
      Discounts: 78.74000000000001,
    },
    {
      day: "2023-03-13",
      datekey: 20230313,
      value: 236.84,
      "Total Amount": 236.84,
      Customers: 5,
      Transactions: 10,
      Returns: 1.13,
      Discounts: 11.94,
    },
    {
      day: "2023-03-12",
      datekey: 20230312,
      value: 5082.659999999994,
      "Total Amount": 5082.659999999994,
      Customers: 47,
      Transactions: 192,
      Returns: 4.21,
      Discounts: 104.59,
    },
    {
      day: "2023-03-11",
      datekey: 20230311,
      value: 6313.419999999997,
      "Total Amount": 6313.419999999997,
      Customers: 54,
      Transactions: 221,
      Returns: 4.85,
      Discounts: 101.99,
    },
    {
      day: "2023-03-10",
      datekey: 20230310,
      value: 5266.629999999992,
      "Total Amount": 5266.629999999992,
      Customers: 50,
      Transactions: 189,
      Returns: 4.3,
      Discounts: 110.04,
    },
    {
      day: "2023-03-09",
      datekey: 20230309,
      value: 4176.239999999999,
      "Total Amount": 4176.239999999999,
      Customers: 42,
      Transactions: 131,
      Returns: 14,
      Discounts: 103.46,
    },
    {
      day: "2023-03-08",
      datekey: 20230308,
      value: 4138.600000000001,
      "Total Amount": 4138.600000000001,
      Customers: 38,
      Transactions: 154,
      Returns: 4.09,
      Discounts: 74.21000000000002,
    },
    {
      day: "2023-03-07",
      datekey: 20230307,
      value: 3453.77,
      "Total Amount": 3453.77,
      Customers: 40,
      Transactions: 146,
      Returns: 3.7,
      Discounts: 72.55,
    },
    {
      day: "2023-03-06",
      datekey: 20230306,
      value: 3897.819999999998,
      "Total Amount": 3897.819999999998,
      Customers: 41,
      Transactions: 167,
      Returns: 2.97,
      Discounts: 94.74000000000004,
    },
    {
      day: "2023-03-05",
      datekey: 20230305,
      value: 5723.079999999998,
      "Total Amount": 5723.079999999998,
      Customers: 52,
      Transactions: 232,
      Returns: 20.84,
      Discounts: 137.9900000000001,
    },
    {
      day: "2023-03-04",
      datekey: 20230304,
      value: 5882.649999999995,
      "Total Amount": 5882.649999999995,
      Customers: 50,
      Transactions: 220,
      Returns: 0,
      Discounts: 101.14,
    },
    {
      day: "2023-03-03",
      datekey: 20230303,
      value: 5387.759999999996,
      "Total Amount": 5387.759999999996,
      Customers: 61,
      Transactions: 209,
      Returns: 6,
      Discounts: 141.34,
    },
    {
      day: "2023-03-02",
      datekey: 20230302,
      value: 4530.489999999998,
      "Total Amount": 4530.489999999998,
      Customers: 60,
      Transactions: 176,
      Returns: 13.3,
      Discounts: 120.54,
    },
    {
      day: "2023-03-01",
      datekey: 20230301,
      value: 4400.32,
      "Total Amount": 4400.32,
      Customers: 49,
      Transactions: 151,
      Returns: 16.49,
      Discounts: 127.83,
    },
    {
      day: "2023-02-28",
      datekey: 20230228,
      value: 2979.399999999999,
      "Total Amount": 2979.399999999999,
      Customers: 35,
      Transactions: 133,
      Returns: 15.49,
      Discounts: 70.79,
    },
    {
      day: "2023-02-27",
      datekey: 20230227,
      value: 3338.29,
      "Total Amount": 3338.29,
      Customers: 35,
      Transactions: 147,
      Returns: 16.58,
      Discounts: 53.93999999999999,
    },
    {
      day: "2023-02-26",
      datekey: 20230226,
      value: 5473.86,
      "Total Amount": 5473.86,
      Customers: 78,
      Transactions: 230,
      Returns: 17.95,
      Discounts: 139.02,
    },
    {
      day: "2023-02-25",
      datekey: 20230225,
      value: 6760.150000000001,
      "Total Amount": 6760.150000000001,
      Customers: 78,
      Transactions: 249,
      Returns: 33.2,
      Discounts: 162.06,
    },
    {
      day: "2023-02-24",
      datekey: 20230224,
      value: 4925.569999999996,
      "Total Amount": 4925.569999999996,
      Customers: 53,
      Transactions: 189,
      Returns: 4.99,
      Discounts: 138.52,
    },
    {
      day: "2023-02-23",
      datekey: 20230223,
      value: 4789.29,
      "Total Amount": 4789.29,
      Customers: 58,
      Transactions: 150,
      Returns: 34.19,
      Discounts: 150.37,
    },
    {
      day: "2023-02-22",
      datekey: 20230222,
      value: 2527.909999999999,
      "Total Amount": 2527.909999999999,
      Customers: 51,
      Transactions: 113,
      Returns: 11.3,
      Discounts: 80.27000000000001,
    },
    {
      day: "2023-02-21",
      datekey: 20230221,
      value: 4135.479999999996,
      "Total Amount": 4135.479999999996,
      Customers: 61,
      Transactions: 158,
      Returns: 21.35,
      Discounts: 110.08,
    },
    {
      day: "2023-02-20",
      datekey: 20230220,
      value: 3786.489999999999,
      "Total Amount": 3786.489999999999,
      Customers: 53,
      Transactions: 179,
      Returns: 9.61,
      Discounts: 89.80999999999999,
    },
    {
      day: "2023-02-19",
      datekey: 20230219,
      value: 5367.639999999996,
      "Total Amount": 5367.639999999996,
      Customers: 34,
      Transactions: 205,
      Returns: 46.11,
      Discounts: 105.81,
    },
    {
      day: "2023-02-18",
      datekey: 20230218,
      value: 6609.499999999993,
      "Total Amount": 6609.499999999993,
      Customers: 38,
      Transactions: 241,
      Returns: 16.63,
      Discounts: 125.45,
    },
    {
      day: "2023-02-17",
      datekey: 20230217,
      value: 6278.299999999997,
      "Total Amount": 6278.299999999997,
      Customers: 31,
      Transactions: 204,
      Returns: 0,
      Discounts: 74.89,
    },
    {
      day: "2023-02-16",
      datekey: 20230216,
      value: 3897.809999999999,
      "Total Amount": 3897.809999999999,
      Customers: 20,
      Transactions: 135,
      Returns: 45,
      Discounts: 34.83,
    },
    {
      day: "2023-02-15",
      datekey: 20230215,
      value: 3207.759999999998,
      "Total Amount": 3207.759999999998,
      Customers: 21,
      Transactions: 138,
      Returns: 18.15,
      Discounts: 32.03,
    },
    {
      day: "2023-02-14",
      datekey: 20230214,
      value: 3555.12,
      "Total Amount": 3555.12,
      Customers: 24,
      Transactions: 126,
      Returns: 75.80999999999999,
      Discounts: 76.52000000000004,
    },
    {
      day: "2023-02-13",
      datekey: 20230213,
      value: 3145.439999999998,
      "Total Amount": 3145.439999999998,
      Customers: 18,
      Transactions: 138,
      Returns: 0,
      Discounts: 43.80000000000001,
    },
    {
      day: "2023-02-12",
      datekey: 20230212,
      value: 5418.139999999993,
      "Total Amount": 5418.139999999993,
      Customers: 24,
      Transactions: 216,
      Returns: 5.68,
      Discounts: 92.58,
    },
    {
      day: "2023-02-11",
      datekey: 20230211,
      value: 7163.269999999997,
      "Total Amount": 7163.269999999997,
      Customers: 22,
      Transactions: 240,
      Returns: 55.47,
      Discounts: 64.19,
    },
    {
      day: "2023-02-10",
      datekey: 20230210,
      value: 4638.789999999999,
      "Total Amount": 4638.789999999999,
      Customers: 17,
      Transactions: 171,
      Returns: 23.64,
      Discounts: 52.26000000000001,
    },
    {
      day: "2023-02-09",
      datekey: 20230209,
      value: 3427.090000000001,
      "Total Amount": 3427.090000000001,
      Customers: 18,
      Transactions: 132,
      Returns: 5.55,
      Discounts: 29.36,
    },
    {
      day: "2023-02-08",
      datekey: 20230208,
      value: 3232.429999999998,
      "Total Amount": 3232.429999999998,
      Customers: 13,
      Transactions: 135,
      Returns: 9,
      Discounts: 46.37,
    },
    {
      day: "2023-02-07",
      datekey: 20230207,
      value: 3516.460000000001,
      "Total Amount": 3516.460000000001,
      Customers: 17,
      Transactions: 144,
      Returns: 16.95,
      Discounts: 35.18,
    },
    {
      day: "2023-02-06",
      datekey: 20230206,
      value: 2768.29,
      "Total Amount": 2768.29,
      Customers: 18,
      Transactions: 124,
      Returns: 13.05,
      Discounts: 41.69000000000001,
    },
    {
      day: "2023-02-05",
      datekey: 20230205,
      value: 6335.499999999997,
      "Total Amount": 6335.499999999997,
      Customers: 22,
      Transactions: 233,
      Returns: 5.69,
      Discounts: 50.52,
    },
    {
      day: "2023-02-04",
      datekey: 20230204,
      value: 6161.499999999996,
      "Total Amount": 6161.499999999996,
      Customers: 16,
      Transactions: 207,
      Returns: 16.18,
      Discounts: 41.77999999999999,
    },
    {
      day: "2023-02-03",
      datekey: 20230203,
      value: 5415.709999999995,
      "Total Amount": 5415.709999999995,
      Customers: 19,
      Transactions: 183,
      Returns: 24.71,
      Discounts: 47.43000000000001,
    },
    {
      day: "2023-02-02",
      datekey: 20230202,
      value: 3938.399999999999,
      "Total Amount": 3938.399999999999,
      Customers: 16,
      Transactions: 128,
      Returns: 0,
      Discounts: 39.26,
    },
    {
      day: "2023-02-01",
      datekey: 20230201,
      value: 3744.619999999998,
      "Total Amount": 3744.619999999998,
      Customers: 8,
      Transactions: 139,
      Returns: 40.25,
      Discounts: 6.44,
    },
    {
      day: "2023-01-31",
      datekey: 20230131,
      value: 3930.529999999998,
      "Total Amount": 3930.529999999998,
      Customers: 20,
      Transactions: 146,
      Returns: 0,
      Discounts: 70.14,
    },
    {
      day: "2023-01-30",
      datekey: 20230130,
      value: 2541.249999999999,
      "Total Amount": 2541.249999999999,
      Customers: 14,
      Transactions: 111,
      Returns: 8.8,
      Discounts: 36.73,
    },
    {
      day: "2023-01-29",
      datekey: 20230129,
      value: 5802.849999999996,
      "Total Amount": 5802.849999999996,
      Customers: 14,
      Transactions: 202,
      Returns: 5,
      Discounts: 16.71,
    },
    {
      day: "2023-01-28",
      datekey: 20230128,
      value: 6441.749999999991,
      "Total Amount": 6441.749999999991,
      Customers: 23,
      Transactions: 254,
      Returns: 32.09,
      Discounts: 49.72000000000001,
    },
    {
      day: "2023-01-27",
      datekey: 20230127,
      value: 4965.729999999995,
      "Total Amount": 4965.729999999995,
      Customers: 16,
      Transactions: 177,
      Returns: 10.9,
      Discounts: 23.54,
    },
    {
      day: "2023-01-26",
      datekey: 20230126,
      value: 3228.360000000001,
      "Total Amount": 3228.360000000001,
      Customers: 11,
      Transactions: 147,
      Returns: 23.34999999999999,
      Discounts: 13.87,
    },
    {
      day: "2023-01-25",
      datekey: 20230125,
      value: 3727.979999999999,
      "Total Amount": 3727.979999999999,
      Customers: 12,
      Transactions: 141,
      Returns: 13.6,
      Discounts: 30.01,
    },
    {
      day: "2023-01-24",
      datekey: 20230124,
      value: 3188.65,
      "Total Amount": 3188.65,
      Customers: 13,
      Transactions: 103,
      Returns: 5.27,
      Discounts: 51.65,
    },
    {
      day: "2023-01-23",
      datekey: 20230123,
      value: 3211.97,
      "Total Amount": 3211.97,
      Customers: 10,
      Transactions: 110,
      Returns: 0,
      Discounts: 33.1,
    },
    {
      day: "2023-01-22",
      datekey: 20230122,
      value: 6556.449999999997,
      "Total Amount": 6556.449999999997,
      Customers: 22,
      Transactions: 236,
      Returns: 17.46,
      Discounts: 95.53999999999999,
    },
    {
      day: "2023-01-21",
      datekey: 20230121,
      value: 5930.419999999997,
      "Total Amount": 5930.419999999997,
      Customers: 16,
      Transactions: 221,
      Returns: 11.5,
      Discounts: 46.79,
    },
    {
      day: "2023-01-20",
      datekey: 20230120,
      value: 4267.009999999998,
      "Total Amount": 4267.009999999998,
      Customers: 14,
      Transactions: 157,
      Returns: 3,
      Discounts: 32.84999999999999,
    },
    {
      day: "2023-01-19",
      datekey: 20230119,
      value: 3894.759999999998,
      "Total Amount": 3894.759999999998,
      Customers: 10,
      Transactions: 149,
      Returns: 0,
      Discounts: 20.67,
    },
    {
      day: "2023-01-18",
      datekey: 20230118,
      value: 3343.839999999997,
      "Total Amount": 3343.839999999997,
      Customers: 10,
      Transactions: 140,
      Returns: 1.9,
      Discounts: 29.05,
    },
    {
      day: "2023-01-17",
      datekey: 20230117,
      value: 3220.78,
      "Total Amount": 3220.78,
      Customers: 7,
      Transactions: 132,
      Returns: 15.77,
      Discounts: 4.73,
    },
    {
      day: "2023-01-16",
      datekey: 20230116,
      value: 4866.709999999996,
      "Total Amount": 4866.709999999996,
      Customers: 7,
      Transactions: 182,
      Returns: 5.62,
      Discounts: 7.279999999999999,
    },
    {
      day: "2023-01-15",
      datekey: 20230115,
      value: 6011.119999999996,
      "Total Amount": 6011.119999999996,
      Customers: 14,
      Transactions: 238,
      Returns: 7.1,
      Discounts: 26.74,
    },
    {
      day: "2023-01-14",
      datekey: 20230114,
      value: 7150.789999999985,
      "Total Amount": 7150.789999999985,
      Customers: 15,
      Transactions: 255,
      Returns: 37.12,
      Discounts: 57.95,
    },
    {
      day: "2023-01-13",
      datekey: 20230113,
      value: 4763.259999999998,
      "Total Amount": 4763.259999999998,
      Customers: 11,
      Transactions: 216,
      Returns: 6.6,
      Discounts: 18.55,
    },
    {
      day: "2023-01-12",
      datekey: 20230112,
      value: 4102.739999999997,
      "Total Amount": 4102.739999999997,
      Customers: 12,
      Transactions: 165,
      Returns: 7.310000000000001,
      Discounts: 19.39,
    },
    {
      day: "2023-01-11",
      datekey: 20230111,
      value: 2807.739999999999,
      "Total Amount": 2807.739999999999,
      Customers: 9,
      Transactions: 118,
      Returns: 20.02,
      Discounts: 22.13,
    },
    {
      day: "2023-01-10",
      datekey: 20230110,
      value: 4305.879999999997,
      "Total Amount": 4305.879999999997,
      Customers: 10,
      Transactions: 133,
      Returns: 0,
      Discounts: 41.35,
    },
    {
      day: "2023-01-09",
      datekey: 20230109,
      value: 3175.659999999999,
      "Total Amount": 3175.659999999999,
      Customers: 5,
      Transactions: 134,
      Returns: 4.8,
      Discounts: 12.44,
    },
    {
      day: "2023-01-08",
      datekey: 20230108,
      value: 6672.519999999992,
      "Total Amount": 6672.519999999992,
      Customers: 20,
      Transactions: 226,
      Returns: 7.69,
      Discounts: 53.25000000000001,
    },
    {
      day: "2023-01-07",
      datekey: 20230107,
      value: 6485.009999999997,
      "Total Amount": 6485.009999999997,
      Customers: 20,
      Transactions: 229,
      Returns: 49.76,
      Discounts: 46.70999999999999,
    },
    {
      day: "2023-01-06",
      datekey: 20230106,
      value: 3780.489999999998,
      "Total Amount": 3780.489999999998,
      Customers: 10,
      Transactions: 176,
      Returns: 8.57,
      Discounts: 16.51,
    },
    {
      day: "2023-01-05",
      datekey: 20230105,
      value: 4362.479999999994,
      "Total Amount": 4362.479999999994,
      Customers: 21,
      Transactions: 185,
      Returns: 11.45,
      Discounts: 32.98,
    },
    {
      day: "2023-01-04",
      datekey: 20230104,
      value: 3553.729999999999,
      "Total Amount": 3553.729999999999,
      Customers: 9,
      Transactions: 128,
      Returns: 0,
      Discounts: 67.66,
    },
    {
      day: "2023-01-03",
      datekey: 20230103,
      value: 4394.889999999997,
      "Total Amount": 4394.889999999997,
      Customers: 14,
      Transactions: 156,
      Returns: 0,
      Discounts: 39.92000000000001,
    },
    {
      day: "2023-01-02",
      datekey: 20230102,
      value: 4529.559999999999,
      "Total Amount": 4529.559999999999,
      Customers: 16,
      Transactions: 161,
      Returns: 5,
      Discounts: 19.39,
    },
    {
      day: "2023-01-01",
      datekey: 20230101,
      value: 2269.19,
      "Total Amount": 2269.19,
      Customers: 4,
      Transactions: 76,
      Returns: 15.5,
      Discounts: 1.04,
    },
    {
      day: "2022-12-31",
      datekey: 20221231,
      value: 5886.669999999996,
      "Total Amount": 5886.669999999996,
      Customers: 11,
      Transactions: 226,
      Returns: 5.58,
      Discounts: 59.67000000000001,
    },
    {
      day: "2022-12-30",
      datekey: 20221230,
      value: 5485.479999999992,
      "Total Amount": 5485.479999999992,
      Customers: 7,
      Transactions: 188,
      Returns: 6.54,
      Discounts: 34.29,
    },
    {
      day: "2022-12-29",
      datekey: 20221229,
      value: 4813.999999999996,
      "Total Amount": 4813.999999999996,
      Customers: 8,
      Transactions: 155,
      Returns: 0,
      Discounts: 39.91,
    },
    {
      day: "2022-12-28",
      datekey: 20221228,
      value: 3670.759999999999,
      "Total Amount": 3670.759999999999,
      Customers: 4,
      Transactions: 148,
      Returns: 49.17,
      Discounts: 0,
    },
    {
      day: "2022-12-27",
      datekey: 20221227,
      value: 3771.91,
      "Total Amount": 3771.91,
      Customers: 3,
      Transactions: 142,
      Returns: 5.78,
      Discounts: 28.14,
    },
    {
      day: "2022-12-26",
      datekey: 20221226,
      value: 4626.279999999998,
      "Total Amount": 4626.279999999998,
      Customers: 5,
      Transactions: 160,
      Returns: 8.25,
      Discounts: 5.38,
    },
    {
      day: "2022-12-25",
      datekey: 20221225,
      value: 2972.43,
      "Total Amount": 2972.43,
      Customers: 3,
      Transactions: 146,
      Returns: 18.89,
      Discounts: 16.29,
    },
    {
      day: "2022-12-24",
      datekey: 20221224,
      value: 6120.939999999999,
      "Total Amount": 6120.939999999999,
      Customers: 6,
      Transactions: 221,
      Returns: 15.99,
      Discounts: 31.36,
    },
    {
      day: "2022-12-23",
      datekey: 20221223,
      value: 3443.359999999998,
      "Total Amount": 3443.359999999998,
      Customers: 4,
      Transactions: 132,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-12-22",
      datekey: 20221222,
      value: 7282.069999999997,
      "Total Amount": 7282.069999999997,
      Customers: 6,
      Transactions: 217,
      Returns: 0,
      Discounts: 25.47,
    },
    {
      day: "2022-12-21",
      datekey: 20221221,
      value: 5705.099999999994,
      "Total Amount": 5705.099999999994,
      Customers: 8,
      Transactions: 183,
      Returns: 24.4,
      Discounts: 0.5,
    },
    {
      day: "2022-12-20",
      datekey: 20221220,
      value: 4251,
      "Total Amount": 4251,
      Customers: 3,
      Transactions: 128,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-12-19",
      datekey: 20221219,
      value: 3622.029999999999,
      "Total Amount": 3622.029999999999,
      Customers: 3,
      Transactions: 137,
      Returns: 5.73,
      Discounts: 0,
    },
    {
      day: "2022-12-18",
      datekey: 20221218,
      value: 5068.289999999995,
      "Total Amount": 5068.289999999995,
      Customers: 6,
      Transactions: 201,
      Returns: 3.6,
      Discounts: 0,
    },
    {
      day: "2022-12-17",
      datekey: 20221217,
      value: 5777.199999999995,
      "Total Amount": 5777.199999999995,
      Customers: 4,
      Transactions: 187,
      Returns: 15.46,
      Discounts: 17.45,
    },
    {
      day: "2022-12-16",
      datekey: 20221216,
      value: 4842.459999999996,
      "Total Amount": 4842.459999999996,
      Customers: 4,
      Transactions: 180,
      Returns: 4,
      Discounts: 1,
    },
    {
      day: "2022-12-15",
      datekey: 20221215,
      value: 3633.369999999998,
      "Total Amount": 3633.369999999998,
      Customers: 7,
      Transactions: 137,
      Returns: 15,
      Discounts: 27.2,
    },
    {
      day: "2022-12-14",
      datekey: 20221214,
      value: 3097.800000000001,
      "Total Amount": 3097.800000000001,
      Customers: 3,
      Transactions: 124,
      Returns: 0,
      Discounts: 8.75,
    },
    {
      day: "2022-12-13",
      datekey: 20221213,
      value: 3533.29,
      "Total Amount": 3533.29,
      Customers: 4,
      Transactions: 128,
      Returns: 14.91,
      Discounts: 4.19,
    },
    {
      day: "2022-12-12",
      datekey: 20221212,
      value: 3267.159999999996,
      "Total Amount": 3267.159999999996,
      Customers: 4,
      Transactions: 132,
      Returns: 12.5,
      Discounts: 17.66,
    },
    {
      day: "2022-12-11",
      datekey: 20221211,
      value: 6188.189999999998,
      "Total Amount": 6188.189999999998,
      Customers: 5,
      Transactions: 210,
      Returns: 1.85,
      Discounts: 11.24,
    },
    {
      day: "2022-12-10",
      datekey: 20221210,
      value: 4622.849999999998,
      "Total Amount": 4622.849999999998,
      Customers: 6,
      Transactions: 163,
      Returns: 9.4,
      Discounts: 7.66,
    },
    {
      day: "2022-12-09",
      datekey: 20221209,
      value: 5014.449999999996,
      "Total Amount": 5014.449999999996,
      Customers: 5,
      Transactions: 163,
      Returns: 10,
      Discounts: 0,
    },
    {
      day: "2022-12-08",
      datekey: 20221208,
      value: 3292.799999999999,
      "Total Amount": 3292.799999999999,
      Customers: 6,
      Transactions: 136,
      Returns: 2.25,
      Discounts: 5.4,
    },
    {
      day: "2022-12-07",
      datekey: 20221207,
      value: 3673.619999999998,
      "Total Amount": 3673.619999999998,
      Customers: 5,
      Transactions: 132,
      Returns: 0,
      Discounts: 3.3,
    },
    {
      day: "2022-12-06",
      datekey: 20221206,
      value: 2973.4,
      "Total Amount": 2973.4,
      Customers: 7,
      Transactions: 120,
      Returns: 11,
      Discounts: 6.779999999999999,
    },
    {
      day: "2022-12-05",
      datekey: 20221205,
      value: 2545.109999999999,
      "Total Amount": 2545.109999999999,
      Customers: 5,
      Transactions: 105,
      Returns: 0,
      Discounts: 5.21,
    },
    {
      day: "2022-12-04",
      datekey: 20221204,
      value: 5195.450000000001,
      "Total Amount": 5195.450000000001,
      Customers: 5,
      Transactions: 183,
      Returns: 3.93,
      Discounts: 6.310000000000001,
    },
    {
      day: "2022-12-03",
      datekey: 20221203,
      value: 4710.859999999996,
      "Total Amount": 4710.859999999996,
      Customers: 7,
      Transactions: 177,
      Returns: 30.59,
      Discounts: 1.4,
    },
    {
      day: "2022-12-02",
      datekey: 20221202,
      value: 4246.569999999998,
      "Total Amount": 4246.569999999998,
      Customers: 6,
      Transactions: 170,
      Returns: 4.07,
      Discounts: 4.44,
    },
    {
      day: "2022-12-01",
      datekey: 20221201,
      value: 3716.129999999998,
      "Total Amount": 3716.129999999998,
      Customers: 7,
      Transactions: 126,
      Returns: 1.9,
      Discounts: 9.36,
    },
    {
      day: "2022-11-30",
      datekey: 20221130,
      value: 2854.250000000001,
      "Total Amount": 2854.250000000001,
      Customers: 3,
      Transactions: 135,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-29",
      datekey: 20221129,
      value: 3363.799999999999,
      "Total Amount": 3363.799999999999,
      Customers: 4,
      Transactions: 133,
      Returns: 9.3,
      Discounts: 4.42,
    },
    {
      day: "2022-11-28",
      datekey: 20221128,
      value: 2920.499999999998,
      "Total Amount": 2920.499999999998,
      Customers: 4,
      Transactions: 120,
      Returns: 20.84,
      Discounts: 5,
    },
    {
      day: "2022-11-27",
      datekey: 20221127,
      value: 4247.86,
      "Total Amount": 4247.86,
      Customers: 7,
      Transactions: 173,
      Returns: 2.42,
      Discounts: 27.95,
    },
    {
      day: "2022-11-26",
      datekey: 20221126,
      value: 4243.469999999998,
      "Total Amount": 4243.469999999998,
      Customers: 5,
      Transactions: 167,
      Returns: 3.3,
      Discounts: 2.7,
    },
    {
      day: "2022-11-25",
      datekey: 20221125,
      value: 4147.479999999999,
      "Total Amount": 4147.479999999999,
      Customers: 7,
      Transactions: 148,
      Returns: 3.5,
      Discounts: 10.24,
    },
    {
      day: "2022-11-24",
      datekey: 20221124,
      value: 4051.399999999997,
      "Total Amount": 4051.399999999997,
      Customers: 5,
      Transactions: 178,
      Returns: 0,
      Discounts: 6.08,
    },
    {
      day: "2022-11-23",
      datekey: 20221123,
      value: 7653.379999999999,
      "Total Amount": 7653.379999999999,
      Customers: 5,
      Transactions: 253,
      Returns: 2.16,
      Discounts: 35.34999999999999,
    },
    {
      day: "2022-11-22",
      datekey: 20221122,
      value: 5104.769999999993,
      "Total Amount": 5104.769999999993,
      Customers: 5,
      Transactions: 167,
      Returns: 0,
      Discounts: 11.94,
    },
    {
      day: "2022-11-21",
      datekey: 20221121,
      value: 3206.249999999997,
      "Total Amount": 3206.249999999997,
      Customers: 3,
      Transactions: 129,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-20",
      datekey: 20221120,
      value: 4614.2,
      "Total Amount": 4614.2,
      Customers: 8,
      Transactions: 187,
      Returns: 32.05,
      Discounts: 12.7,
    },
    {
      day: "2022-11-19",
      datekey: 20221119,
      value: 4705.219999999997,
      "Total Amount": 4705.219999999997,
      Customers: 7,
      Transactions: 164,
      Returns: 2.5,
      Discounts: 10.47,
    },
    {
      day: "2022-11-18",
      datekey: 20221118,
      value: 4568.879999999998,
      "Total Amount": 4568.879999999998,
      Customers: 4,
      Transactions: 175,
      Returns: 22.23,
      Discounts: 2.6,
    },
    {
      day: "2022-11-17",
      datekey: 20221117,
      value: 3221.639999999999,
      "Total Amount": 3221.639999999999,
      Customers: 4,
      Transactions: 125,
      Returns: 47.98,
      Discounts: 13.83,
    },
    {
      day: "2022-11-16",
      datekey: 20221116,
      value: 2921.499999999999,
      "Total Amount": 2921.499999999999,
      Customers: 5,
      Transactions: 124,
      Returns: 2.2,
      Discounts: 0.5,
    },
    {
      day: "2022-11-15",
      datekey: 20221115,
      value: 3491.849999999999,
      "Total Amount": 3491.849999999999,
      Customers: 6,
      Transactions: 139,
      Returns: 0,
      Discounts: 11.16,
    },
    {
      day: "2022-11-14",
      datekey: 20221114,
      value: 2902.479999999998,
      "Total Amount": 2902.479999999998,
      Customers: 2,
      Transactions: 126,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-11-13",
      datekey: 20221113,
      value: 5969.119999999999,
      "Total Amount": 5969.119999999999,
      Customers: 3,
      Transactions: 212,
      Returns: 30.75,
      Discounts: 0,
    },
    {
      day: "2022-11-12",
      datekey: 20221112,
      value: 5633.819999999993,
      "Total Amount": 5633.819999999993,
      Customers: 7,
      Transactions: 170,
      Returns: 10.17,
      Discounts: 0,
    },
    {
      day: "2022-11-11",
      datekey: 20221111,
      value: 5212.119999999997,
      "Total Amount": 5212.119999999997,
      Customers: 6,
      Transactions: 188,
      Returns: 11,
      Discounts: 6.61,
    },
    {
      day: "2022-11-10",
      datekey: 20221110,
      value: 4005.209999999998,
      "Total Amount": 4005.209999999998,
      Customers: 6,
      Transactions: 140,
      Returns: 0,
      Discounts: 9.83,
    },
    {
      day: "2022-11-09",
      datekey: 20221109,
      value: 3719.299999999999,
      "Total Amount": 3719.299999999999,
      Customers: 5,
      Transactions: 140,
      Returns: 0,
      Discounts: 13.52,
    },
    {
      day: "2022-11-08",
      datekey: 20221108,
      value: 3503.029999999999,
      "Total Amount": 3503.029999999999,
      Customers: 7,
      Transactions: 141,
      Returns: 9.75,
      Discounts: 5.33,
    },
    {
      day: "2022-11-07",
      datekey: 20221107,
      value: 2788.329999999998,
      "Total Amount": 2788.329999999998,
      Customers: 5,
      Transactions: 122,
      Returns: 10.81,
      Discounts: 0.3,
    },
    {
      day: "2022-11-06",
      datekey: 20221106,
      value: 5585.609999999997,
      "Total Amount": 5585.609999999997,
      Customers: 4,
      Transactions: 203,
      Returns: 0,
      Discounts: 6.99,
    },
    {
      day: "2022-11-05",
      datekey: 20221105,
      value: 4912.199999999998,
      "Total Amount": 4912.199999999998,
      Customers: 5,
      Transactions: 192,
      Returns: 0,
      Discounts: 5.13,
    },
    {
      day: "2022-11-04",
      datekey: 20221104,
      value: 4568.029999999997,
      "Total Amount": 4568.029999999997,
      Customers: 7,
      Transactions: 178,
      Returns: 5.75,
      Discounts: 6.71,
    },
    {
      day: "2022-11-03",
      datekey: 20221103,
      value: 3828.9,
      "Total Amount": 3828.9,
      Customers: 8,
      Transactions: 138,
      Returns: 0,
      Discounts: 9.39,
    },
    {
      day: "2022-11-02",
      datekey: 20221102,
      value: 3458.769999999998,
      "Total Amount": 3458.769999999998,
      Customers: 4,
      Transactions: 126,
      Returns: 55.11,
      Discounts: 15.24,
    },
    {
      day: "2022-11-01",
      datekey: 20221101,
      value: 2673.94,
      "Total Amount": 2673.94,
      Customers: 4,
      Transactions: 124,
      Returns: 0,
      Discounts: 5.8,
    },
    {
      day: "2022-10-31",
      datekey: 20221031,
      value: 2549.450000000001,
      "Total Amount": 2549.450000000001,
      Customers: 3,
      Transactions: 103,
      Returns: 8,
      Discounts: 0,
    },
    {
      day: "2022-10-30",
      datekey: 20221030,
      value: 5251.709999999999,
      "Total Amount": 5251.709999999999,
      Customers: 10,
      Transactions: 211,
      Returns: 5.96,
      Discounts: 18.56,
    },
    {
      day: "2022-10-29",
      datekey: 20221029,
      value: 4863.109999999997,
      "Total Amount": 4863.109999999997,
      Customers: 4,
      Transactions: 165,
      Returns: 33.09,
      Discounts: 1.2,
    },
    {
      day: "2022-10-28",
      datekey: 20221028,
      value: 3953.589999999998,
      "Total Amount": 3953.589999999998,
      Customers: 6,
      Transactions: 147,
      Returns: 2.95,
      Discounts: 7,
    },
    {
      day: "2022-10-27",
      datekey: 20221027,
      value: 3268.719999999997,
      "Total Amount": 3268.719999999997,
      Customers: 5,
      Transactions: 122,
      Returns: 14.7,
      Discounts: 12.18,
    },
    {
      day: "2022-10-26",
      datekey: 20221026,
      value: 3222.439999999999,
      "Total Amount": 3222.439999999999,
      Customers: 7,
      Transactions: 135,
      Returns: 3.5,
      Discounts: 4.3,
    },
    {
      day: "2022-10-25",
      datekey: 20221025,
      value: 2780.35,
      "Total Amount": 2780.35,
      Customers: 6,
      Transactions: 117,
      Returns: 8.06,
      Discounts: 6.08,
    },
    {
      day: "2022-10-24",
      datekey: 20221024,
      value: 5347.509999999996,
      "Total Amount": 5347.509999999996,
      Customers: 5,
      Transactions: 246,
      Returns: 0,
      Discounts: 6.28,
    },
    {
      day: "2022-10-23",
      datekey: 20221023,
      value: 6992.249999999993,
      "Total Amount": 6992.249999999993,
      Customers: 6,
      Transactions: 263,
      Returns: 1.85,
      Discounts: 17.69,
    },
    {
      day: "2022-10-22",
      datekey: 20221022,
      value: 6432.709999999992,
      "Total Amount": 6432.709999999992,
      Customers: 8,
      Transactions: 241,
      Returns: 37.26000000000001,
      Discounts: 18.11,
    },
    {
      day: "2022-10-21",
      datekey: 20221021,
      value: 5110.839999999994,
      "Total Amount": 5110.839999999994,
      Customers: 8,
      Transactions: 201,
      Returns: 15.91,
      Discounts: 8.629999999999999,
    },
    {
      day: "2022-10-20",
      datekey: 20221020,
      value: 3988.659999999999,
      "Total Amount": 3988.659999999999,
      Customers: 5,
      Transactions: 123,
      Returns: 16.9,
      Discounts: 0.89,
    },
    {
      day: "2022-10-19",
      datekey: 20221019,
      value: 3602.819999999997,
      "Total Amount": 3602.819999999997,
      Customers: 5,
      Transactions: 137,
      Returns: 7.75,
      Discounts: 3.5,
    },
    {
      day: "2022-10-18",
      datekey: 20221018,
      value: 4174.279999999998,
      "Total Amount": 4174.279999999998,
      Customers: 6,
      Transactions: 134,
      Returns: 0,
      Discounts: 2.69,
    },
    {
      day: "2022-10-17",
      datekey: 20221017,
      value: 3418.499999999999,
      "Total Amount": 3418.499999999999,
      Customers: 5,
      Transactions: 123,
      Returns: 0,
      Discounts: 9.81,
    },
    {
      day: "2022-10-16",
      datekey: 20221016,
      value: 4944.539999999999,
      "Total Amount": 4944.539999999999,
      Customers: 6,
      Transactions: 203,
      Returns: 0,
      Discounts: 3.88,
    },
    {
      day: "2022-10-15",
      datekey: 20221015,
      value: 4062.349999999997,
      "Total Amount": 4062.349999999997,
      Customers: 6,
      Transactions: 155,
      Returns: 6.27,
      Discounts: 13.39,
    },
    {
      day: "2022-10-14",
      datekey: 20221014,
      value: 4400.239999999999,
      "Total Amount": 4400.239999999999,
      Customers: 8,
      Transactions: 164,
      Returns: 22.5,
      Discounts: 6.510000000000001,
    },
    {
      day: "2022-10-13",
      datekey: 20221013,
      value: 2914.259999999998,
      "Total Amount": 2914.259999999998,
      Customers: 5,
      Transactions: 132,
      Returns: 5.63,
      Discounts: 3.89,
    },
    {
      day: "2022-10-12",
      datekey: 20221012,
      value: 3651.659999999999,
      "Total Amount": 3651.659999999999,
      Customers: 9,
      Transactions: 149,
      Returns: 7.380000000000001,
      Discounts: 3.56,
    },
    {
      day: "2022-10-11",
      datekey: 20221011,
      value: 3649.22,
      "Total Amount": 3649.22,
      Customers: 7,
      Transactions: 154,
      Returns: 16,
      Discounts: 0,
    },
    {
      day: "2022-10-10",
      datekey: 20221010,
      value: 2815.59,
      "Total Amount": 2815.59,
      Customers: 2,
      Transactions: 113,
      Returns: 4.5,
      Discounts: 0,
    },
    {
      day: "2022-10-09",
      datekey: 20221009,
      value: 4947.079999999999,
      "Total Amount": 4947.079999999999,
      Customers: 7,
      Transactions: 195,
      Returns: 14.76,
      Discounts: 1.6,
    },
    {
      day: "2022-10-08",
      datekey: 20221008,
      value: 5297.339999999997,
      "Total Amount": 5297.339999999997,
      Customers: 8,
      Transactions: 169,
      Returns: 20.08,
      Discounts: 0.51,
    },
    {
      day: "2022-10-07",
      datekey: 20221007,
      value: 4882.439999999995,
      "Total Amount": 4882.439999999995,
      Customers: 6,
      Transactions: 171,
      Returns: 8.98,
      Discounts: 0,
    },
    {
      day: "2022-10-06",
      datekey: 20221006,
      value: 3175.039999999998,
      "Total Amount": 3175.039999999998,
      Customers: 5,
      Transactions: 125,
      Returns: 10.2,
      Discounts: 19.53,
    },
    {
      day: "2022-10-05",
      datekey: 20221005,
      value: 3656.92,
      "Total Amount": 3656.92,
      Customers: 5,
      Transactions: 150,
      Returns: 1.77,
      Discounts: 0.58,
    },
    {
      day: "2022-10-04",
      datekey: 20221004,
      value: 4293.929999999999,
      "Total Amount": 4293.929999999999,
      Customers: 5,
      Transactions: 159,
      Returns: 19.14,
      Discounts: 0,
    },
    {
      day: "2022-10-03",
      datekey: 20221003,
      value: 3610.119999999995,
      "Total Amount": 3610.119999999995,
      Customers: 6,
      Transactions: 139,
      Returns: 3.99,
      Discounts: 0,
    },
    {
      day: "2022-10-02",
      datekey: 20221002,
      value: 5571.509999999991,
      "Total Amount": 5571.509999999991,
      Customers: 6,
      Transactions: 236,
      Returns: 17.05,
      Discounts: 0,
    },
    {
      day: "2022-10-01",
      datekey: 20221001,
      value: 4587.91,
      "Total Amount": 4587.91,
      Customers: 4,
      Transactions: 163,
      Returns: 3.05,
      Discounts: 0,
    },
    {
      day: "2022-09-30",
      datekey: 20220930,
      value: 4137.499999999999,
      "Total Amount": 4137.499999999999,
      Customers: 7,
      Transactions: 161,
      Returns: 14.33,
      Discounts: 0,
    },
    {
      day: "2022-09-29",
      datekey: 20220929,
      value: 3490.239999999997,
      "Total Amount": 3490.239999999997,
      Customers: 6,
      Transactions: 136,
      Returns: 6.560000000000001,
      Discounts: 23.05,
    },
    {
      day: "2022-09-28",
      datekey: 20220928,
      value: 2489.14,
      "Total Amount": 2489.14,
      Customers: 4,
      Transactions: 112,
      Returns: 5.98,
      Discounts: 0,
    },
    {
      day: "2022-09-27",
      datekey: 20220927,
      value: 3015.21,
      "Total Amount": 3015.21,
      Customers: 2,
      Transactions: 135,
      Returns: 3.5,
      Discounts: 0,
    },
    {
      day: "2022-09-26",
      datekey: 20220926,
      value: 2393.25,
      "Total Amount": 2393.25,
      Customers: 4,
      Transactions: 105,
      Returns: 3.95,
      Discounts: 0,
    },
    {
      day: "2022-09-25",
      datekey: 20220925,
      value: 6603.209999999994,
      "Total Amount": 6603.209999999994,
      Customers: 9,
      Transactions: 216,
      Returns: 6.4,
      Discounts: 0,
    },
    {
      day: "2022-09-24",
      datekey: 20220924,
      value: 5693.779999999997,
      "Total Amount": 5693.779999999997,
      Customers: 6,
      Transactions: 198,
      Returns: 15.4,
      Discounts: 0,
    },
    {
      day: "2022-09-23",
      datekey: 20220923,
      value: 5157.499999999998,
      "Total Amount": 5157.499999999998,
      Customers: 10,
      Transactions: 147,
      Returns: 66.6,
      Discounts: 18.97,
    },
    {
      day: "2022-09-22",
      datekey: 20220922,
      value: 3385.139999999999,
      "Total Amount": 3385.139999999999,
      Customers: 3,
      Transactions: 131,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-21",
      datekey: 20220921,
      value: 2998.429999999998,
      "Total Amount": 2998.429999999998,
      Customers: 3,
      Transactions: 123,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-20",
      datekey: 20220920,
      value: 3048.979999999999,
      "Total Amount": 3048.979999999999,
      Customers: 4,
      Transactions: 129,
      Returns: 11.6,
      Discounts: 0,
    },
    {
      day: "2022-09-19",
      datekey: 20220919,
      value: 2778.63,
      "Total Amount": 2778.63,
      Customers: 4,
      Transactions: 131,
      Returns: 1.9,
      Discounts: 0,
    },
    {
      day: "2022-09-18",
      datekey: 20220918,
      value: 4356.209999999996,
      "Total Amount": 4356.209999999996,
      Customers: 7,
      Transactions: 181,
      Returns: 5.09,
      Discounts: 0,
    },
    {
      day: "2022-09-17",
      datekey: 20220917,
      value: 5358.349999999992,
      "Total Amount": 5358.349999999992,
      Customers: 5,
      Transactions: 192,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-16",
      datekey: 20220916,
      value: 4255.489999999999,
      "Total Amount": 4255.489999999999,
      Customers: 5,
      Transactions: 157,
      Returns: 21.59,
      Discounts: 0,
    },
    {
      day: "2022-09-15",
      datekey: 20220915,
      value: 2933.05,
      "Total Amount": 2933.05,
      Customers: 2,
      Transactions: 121,
      Returns: 22.49,
      Discounts: 0,
    },
    {
      day: "2022-09-14",
      datekey: 20220914,
      value: 3525.049999999998,
      "Total Amount": 3525.049999999998,
      Customers: 5,
      Transactions: 140,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-13",
      datekey: 20220913,
      value: 3409.609999999999,
      "Total Amount": 3409.609999999999,
      Customers: 4,
      Transactions: 123,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-12",
      datekey: 20220912,
      value: 2416.839999999999,
      "Total Amount": 2416.839999999999,
      Customers: 1,
      Transactions: 117,
      Returns: 1.85,
      Discounts: 0,
    },
    {
      day: "2022-09-11",
      datekey: 20220911,
      value: 4988.449999999996,
      "Total Amount": 4988.449999999996,
      Customers: 3,
      Transactions: 192,
      Returns: 3.1,
      Discounts: 0,
    },
    {
      day: "2022-09-10",
      datekey: 20220910,
      value: 5468.189999999998,
      "Total Amount": 5468.189999999998,
      Customers: 4,
      Transactions: 195,
      Returns: 0.9,
      Discounts: 0,
    },
    {
      day: "2022-09-09",
      datekey: 20220909,
      value: 4099.040000000002,
      "Total Amount": 4099.040000000002,
      Customers: 6,
      Transactions: 144,
      Returns: 7.7,
      Discounts: 0,
    },
    {
      day: "2022-09-08",
      datekey: 20220908,
      value: 3117.54,
      "Total Amount": 3117.54,
      Customers: 4,
      Transactions: 129,
      Returns: 8,
      Discounts: 0,
    },
    {
      day: "2022-09-07",
      datekey: 20220907,
      value: 3177.01,
      "Total Amount": 3177.01,
      Customers: 5,
      Transactions: 124,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-09-06",
      datekey: 20220906,
      value: 3345.75,
      "Total Amount": 3345.75,
      Customers: 4,
      Transactions: 123,
      Returns: 5.7,
      Discounts: 0,
    },
    {
      day: "2022-09-05",
      datekey: 20220905,
      value: 3801.79,
      "Total Amount": 3801.79,
      Customers: 3,
      Transactions: 164,
      Returns: 34.18,
      Discounts: 0,
    },
    {
      day: "2022-09-04",
      datekey: 20220904,
      value: 4081.449999999999,
      "Total Amount": 4081.449999999999,
      Customers: 4,
      Transactions: 172,
      Returns: 21.81,
      Discounts: 0,
    },
    {
      day: "2022-09-03",
      datekey: 20220903,
      value: 5421.589999999994,
      "Total Amount": 5421.589999999994,
      Customers: 6,
      Transactions: 197,
      Returns: 4.48,
      Discounts: 0,
    },
    {
      day: "2022-09-02",
      datekey: 20220902,
      value: 5431.159999999996,
      "Total Amount": 5431.159999999996,
      Customers: 5,
      Transactions: 171,
      Returns: 13.5,
      Discounts: 0,
    },
    {
      day: "2022-09-01",
      datekey: 20220901,
      value: 3594.039999999998,
      "Total Amount": 3594.039999999998,
      Customers: 2,
      Transactions: 138,
      Returns: 22.99,
      Discounts: 0,
    },
    {
      day: "2022-08-31",
      datekey: 20220831,
      value: 3388.129999999998,
      "Total Amount": 3388.129999999998,
      Customers: 6,
      Transactions: 131,
      Returns: 15,
      Discounts: 0,
    },
    {
      day: "2022-08-30",
      datekey: 20220830,
      value: 2880.359999999997,
      "Total Amount": 2880.359999999997,
      Customers: 5,
      Transactions: 131,
      Returns: 23.91,
      Discounts: 0,
    },
    {
      day: "2022-08-29",
      datekey: 20220829,
      value: 2591.76,
      "Total Amount": 2591.76,
      Customers: 2,
      Transactions: 120,
      Returns: 13.6,
      Discounts: 0,
    },
    {
      day: "2022-08-28",
      datekey: 20220828,
      value: 4736.449999999998,
      "Total Amount": 4736.449999999998,
      Customers: 2,
      Transactions: 175,
      Returns: 10.3,
      Discounts: 0,
    },
    {
      day: "2022-08-27",
      datekey: 20220827,
      value: 6234.689999999996,
      "Total Amount": 6234.689999999996,
      Customers: 9,
      Transactions: 192,
      Returns: 1.95,
      Discounts: 0,
    },
    {
      day: "2022-08-26",
      datekey: 20220826,
      value: 3613.749999999998,
      "Total Amount": 3613.749999999998,
      Customers: 4,
      Transactions: 140,
      Returns: 10.51,
      Discounts: 0,
    },
    {
      day: "2022-08-25",
      datekey: 20220825,
      value: 4076.119999999998,
      "Total Amount": 4076.119999999998,
      Customers: 5,
      Transactions: 128,
      Returns: 12.75,
      Discounts: 0,
    },
    {
      day: "2022-08-24",
      datekey: 20220824,
      value: 2665.179999999999,
      "Total Amount": 2665.179999999999,
      Customers: 4,
      Transactions: 98,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-23",
      datekey: 20220823,
      value: 2715.039999999999,
      "Total Amount": 2715.039999999999,
      Customers: 6,
      Transactions: 111,
      Returns: 3.08,
      Discounts: 0,
    },
    {
      day: "2022-08-22",
      datekey: 20220822,
      value: 3243.559999999999,
      "Total Amount": 3243.559999999999,
      Customers: 3,
      Transactions: 123,
      Returns: 33.15,
      Discounts: 0,
    },
    {
      day: "2022-08-21",
      datekey: 20220821,
      value: 5687.360000000001,
      "Total Amount": 5687.360000000001,
      Customers: 6,
      Transactions: 202,
      Returns: 21.25,
      Discounts: 0,
    },
    {
      day: "2022-08-20",
      datekey: 20220820,
      value: 6047.899999999999,
      "Total Amount": 6047.899999999999,
      Customers: 9,
      Transactions: 212,
      Returns: 20.58,
      Discounts: 4.51,
    },
    {
      day: "2022-08-19",
      datekey: 20220819,
      value: 4145.089999999997,
      "Total Amount": 4145.089999999997,
      Customers: 3,
      Transactions: 157,
      Returns: 6.1,
      Discounts: 0,
    },
    {
      day: "2022-08-18",
      datekey: 20220818,
      value: 3315.189999999999,
      "Total Amount": 3315.189999999999,
      Customers: 6,
      Transactions: 131,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-17",
      datekey: 20220817,
      value: 3249.269999999999,
      "Total Amount": 3249.269999999999,
      Customers: 2,
      Transactions: 116,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-16",
      datekey: 20220816,
      value: 3006.449999999999,
      "Total Amount": 3006.449999999999,
      Customers: 2,
      Transactions: 116,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-08-15",
      datekey: 20220815,
      value: 3076.339999999997,
      "Total Amount": 3076.339999999997,
      Customers: 1,
      Transactions: 131,
      Returns: 2.97,
      Discounts: 0,
    },
    {
      day: "2022-08-14",
      datekey: 20220814,
      value: 6753.929999999992,
      "Total Amount": 6753.929999999992,
      Customers: 4,
      Transactions: 181,
      Returns: 53.02,
      Discounts: 0,
    },
    {
      day: "2022-08-13",
      datekey: 20220813,
      value: 4099.189999999998,
      "Total Amount": 4099.189999999998,
      Customers: 6,
      Transactions: 153,
      Returns: 22.42,
      Discounts: 0,
    },
    {
      day: "2022-08-12",
      datekey: 20220812,
      value: 4011.129999999999,
      "Total Amount": 4011.129999999999,
      Customers: 6,
      Transactions: 176,
      Returns: 27.84,
      Discounts: 0,
    },
    {
      day: "2022-08-11",
      datekey: 20220811,
      value: 4422.829999999997,
      "Total Amount": 4422.829999999997,
      Customers: 6,
      Transactions: 173,
      Returns: 10.38,
      Discounts: 0,
    },
    {
      day: "2022-08-10",
      datekey: 20220810,
      value: 3209.429999999999,
      "Total Amount": 3209.429999999999,
      Customers: 5,
      Transactions: 124,
      Returns: 18.25,
      Discounts: 0,
    },
    {
      day: "2022-08-09",
      datekey: 20220809,
      value: 3178.109999999997,
      "Total Amount": 3178.109999999997,
      Customers: 6,
      Transactions: 115,
      Returns: 7,
      Discounts: 0,
    },
    {
      day: "2022-08-08",
      datekey: 20220808,
      value: 2728.399999999998,
      "Total Amount": 2728.399999999998,
      Customers: 7,
      Transactions: 120,
      Returns: 6,
      Discounts: 0,
    },
    {
      day: "2022-08-07",
      datekey: 20220807,
      value: 4748.739999999999,
      "Total Amount": 4748.739999999999,
      Customers: 3,
      Transactions: 170,
      Returns: 10.61,
      Discounts: 0,
    },
    {
      day: "2022-08-06",
      datekey: 20220806,
      value: 4738.1,
      "Total Amount": 4738.1,
      Customers: 4,
      Transactions: 151,
      Returns: 0.25,
      Discounts: 0,
    },
    {
      day: "2022-08-05",
      datekey: 20220805,
      value: 2640.28,
      "Total Amount": 2640.28,
      Customers: 2,
      Transactions: 127,
      Returns: 32.38,
      Discounts: 0,
    },
    {
      day: "2022-08-04",
      datekey: 20220804,
      value: 3812.909999999997,
      "Total Amount": 3812.909999999997,
      Customers: 5,
      Transactions: 139,
      Returns: 10.29,
      Discounts: 0,
    },
    {
      day: "2022-08-03",
      datekey: 20220803,
      value: 3637.299999999999,
      "Total Amount": 3637.299999999999,
      Customers: 4,
      Transactions: 122,
      Returns: 20.44,
      Discounts: 0,
    },
    {
      day: "2022-08-02",
      datekey: 20220802,
      value: 3288.58,
      "Total Amount": 3288.58,
      Customers: 4,
      Transactions: 121,
      Returns: 26.53,
      Discounts: 0,
    },
    {
      day: "2022-08-01",
      datekey: 20220801,
      value: 2119.219999999999,
      "Total Amount": 2119.219999999999,
      Customers: 5,
      Transactions: 94,
      Returns: 14.49,
      Discounts: 0,
    },
    {
      day: "2022-07-31",
      datekey: 20220731,
      value: 4482.209999999997,
      "Total Amount": 4482.209999999997,
      Customers: 6,
      Transactions: 163,
      Returns: 26.38,
      Discounts: 0,
    },
    {
      day: "2022-07-30",
      datekey: 20220730,
      value: 4411.85,
      "Total Amount": 4411.85,
      Customers: 5,
      Transactions: 164,
      Returns: 1.75,
      Discounts: 0,
    },
    {
      day: "2022-07-29",
      datekey: 20220729,
      value: 4234.919999999998,
      "Total Amount": 4234.919999999998,
      Customers: 5,
      Transactions: 150,
      Returns: 16.7,
      Discounts: 0,
    },
    {
      day: "2022-07-28",
      datekey: 20220728,
      value: 3995.549999999998,
      "Total Amount": 3995.549999999998,
      Customers: 2,
      Transactions: 135,
      Returns: 4.49,
      Discounts: 0,
    },
    {
      day: "2022-07-27",
      datekey: 20220727,
      value: 2774.510000000001,
      "Total Amount": 2774.510000000001,
      Customers: 3,
      Transactions: 110,
      Returns: 5.84,
      Discounts: 0,
    },
    {
      day: "2022-07-26",
      datekey: 20220726,
      value: 2843.189999999999,
      "Total Amount": 2843.189999999999,
      Customers: 3,
      Transactions: 105,
      Returns: 31.79,
      Discounts: 0,
    },
    {
      day: "2022-07-25",
      datekey: 20220725,
      value: 2306.92,
      "Total Amount": 2306.92,
      Customers: 2,
      Transactions: 99,
      Returns: 12.62,
      Discounts: 0,
    },
    {
      day: "2022-07-24",
      datekey: 20220724,
      value: 3898.489999999999,
      "Total Amount": 3898.489999999999,
      Customers: 4,
      Transactions: 149,
      Returns: 21.97,
      Discounts: 0,
    },
    {
      day: "2022-07-23",
      datekey: 20220723,
      value: 4066.219999999998,
      "Total Amount": 4066.219999999998,
      Customers: 7,
      Transactions: 155,
      Returns: 28.65,
      Discounts: 0,
    },
    {
      day: "2022-07-22",
      datekey: 20220722,
      value: 3149.419999999998,
      "Total Amount": 3149.419999999998,
      Customers: 4,
      Transactions: 128,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-21",
      datekey: 20220721,
      value: 3441.8,
      "Total Amount": 3441.8,
      Customers: 3,
      Transactions: 121,
      Returns: 17.25,
      Discounts: 0,
    },
    {
      day: "2022-07-20",
      datekey: 20220720,
      value: 2125.7,
      "Total Amount": 2125.7,
      Customers: 6,
      Transactions: 96,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-19",
      datekey: 20220719,
      value: 2634.6,
      "Total Amount": 2634.6,
      Customers: 3,
      Transactions: 102,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-18",
      datekey: 20220718,
      value: 2233.190000000001,
      "Total Amount": 2233.190000000001,
      Customers: 1,
      Transactions: 97,
      Returns: 12.34,
      Discounts: 0,
    },
    {
      day: "2022-07-17",
      datekey: 20220717,
      value: 4185.869999999996,
      "Total Amount": 4185.869999999996,
      Customers: 4,
      Transactions: 165,
      Returns: 14.08,
      Discounts: 0,
    },
    {
      day: "2022-07-16",
      datekey: 20220716,
      value: 5006.899999999999,
      "Total Amount": 5006.899999999999,
      Customers: 3,
      Transactions: 167,
      Returns: 16.07,
      Discounts: 0,
    },
    {
      day: "2022-07-15",
      datekey: 20220715,
      value: 3750.759999999999,
      "Total Amount": 3750.759999999999,
      Customers: 2,
      Transactions: 144,
      Returns: 0,
      Discounts: 39.45,
    },
    {
      day: "2022-07-14",
      datekey: 20220714,
      value: 2700.089999999998,
      "Total Amount": 2700.089999999998,
      Customers: 3,
      Transactions: 105,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-13",
      datekey: 20220713,
      value: 3049.91,
      "Total Amount": 3049.91,
      Customers: 2,
      Transactions: 114,
      Returns: 7.5,
      Discounts: 0,
    },
    {
      day: "2022-07-12",
      datekey: 20220712,
      value: 2382.210000000001,
      "Total Amount": 2382.210000000001,
      Customers: 5,
      Transactions: 99,
      Returns: 3.2,
      Discounts: 0,
    },
    {
      day: "2022-07-11",
      datekey: 20220711,
      value: 1787.830000000001,
      "Total Amount": 1787.830000000001,
      Customers: 3,
      Transactions: 94,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-10",
      datekey: 20220710,
      value: 3951.449999999997,
      "Total Amount": 3951.449999999997,
      Customers: 4,
      Transactions: 152,
      Returns: 4.02,
      Discounts: 0,
    },
    {
      day: "2022-07-09",
      datekey: 20220709,
      value: 3826.239999999999,
      "Total Amount": 3826.239999999999,
      Customers: 5,
      Transactions: 128,
      Returns: 5.75,
      Discounts: 0,
    },
    {
      day: "2022-07-08",
      datekey: 20220708,
      value: 4835.469999999998,
      "Total Amount": 4835.469999999998,
      Customers: 3,
      Transactions: 154,
      Returns: 5.65,
      Discounts: 0,
    },
    {
      day: "2022-07-07",
      datekey: 20220707,
      value: 3336.669999999998,
      "Total Amount": 3336.669999999998,
      Customers: 4,
      Transactions: 119,
      Returns: 15,
      Discounts: 0,
    },
    {
      day: "2022-07-06",
      datekey: 20220706,
      value: 2372.93,
      "Total Amount": 2372.93,
      Customers: 4,
      Transactions: 95,
      Returns: 6.01,
      Discounts: 0,
    },
    {
      day: "2022-07-05",
      datekey: 20220705,
      value: 2805.739999999999,
      "Total Amount": 2805.739999999999,
      Customers: 1,
      Transactions: 105,
      Returns: 16.29,
      Discounts: 0,
    },
    {
      day: "2022-07-04",
      datekey: 20220704,
      value: 3468.319999999998,
      "Total Amount": 3468.319999999998,
      Customers: 3,
      Transactions: 134,
      Returns: 45.6,
      Discounts: 0,
    },
    {
      day: "2022-07-03",
      datekey: 20220703,
      value: 3323.679999999999,
      "Total Amount": 3323.679999999999,
      Customers: 4,
      Transactions: 121,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-07-02",
      datekey: 20220702,
      value: 4421.629999999997,
      "Total Amount": 4421.629999999997,
      Customers: 4,
      Transactions: 149,
      Returns: 15.6,
      Discounts: 0,
    },
    {
      day: "2022-07-01",
      datekey: 20220701,
      value: 3910.529999999998,
      "Total Amount": 3910.529999999998,
      Customers: 4,
      Transactions: 133,
      Returns: 7.5,
      Discounts: 0,
    },
    {
      day: "2022-06-30",
      datekey: 20220630,
      value: 3308.349999999999,
      "Total Amount": 3308.349999999999,
      Customers: 2,
      Transactions: 134,
      Returns: 8.43,
      Discounts: 0.4,
    },
    {
      day: "2022-06-29",
      datekey: 20220629,
      value: 3242.510000000001,
      "Total Amount": 3242.510000000001,
      Customers: 2,
      Transactions: 105,
      Returns: 10.5,
      Discounts: 0,
    },
    {
      day: "2022-06-28",
      datekey: 20220628,
      value: 3467.6,
      "Total Amount": 3467.6,
      Customers: 3,
      Transactions: 107,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-27",
      datekey: 20220627,
      value: 2212.96,
      "Total Amount": 2212.96,
      Customers: 1,
      Transactions: 90,
      Returns: 22.2,
      Discounts: 0,
    },
    {
      day: "2022-06-26",
      datekey: 20220626,
      value: 3960.229999999998,
      "Total Amount": 3960.229999999998,
      Customers: 2,
      Transactions: 144,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-25",
      datekey: 20220625,
      value: 4047.849999999998,
      "Total Amount": 4047.849999999998,
      Customers: 6,
      Transactions: 143,
      Returns: 8.5,
      Discounts: 0,
    },
    {
      day: "2022-06-24",
      datekey: 20220624,
      value: 3678.019999999999,
      "Total Amount": 3678.019999999999,
      Customers: 4,
      Transactions: 127,
      Returns: 1.75,
      Discounts: 0,
    },
    {
      day: "2022-06-23",
      datekey: 20220623,
      value: 2616.659999999999,
      "Total Amount": 2616.659999999999,
      Customers: 2,
      Transactions: 93,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-22",
      datekey: 20220622,
      value: 2712.679999999999,
      "Total Amount": 2712.679999999999,
      Customers: 5,
      Transactions: 106,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-06-21",
      datekey: 20220621,
      value: 2223.180000000001,
      "Total Amount": 2223.180000000001,
      Customers: 3,
      Transactions: 94,
      Returns: 1.89,
      Discounts: 2.77,
    },
    {
      day: "2022-06-20",
      datekey: 20220620,
      value: 2363.08,
      "Total Amount": 2363.08,
      Customers: 1,
      Transactions: 87,
      Returns: 3.9,
      Discounts: 10.14,
    },
    {
      day: "2022-06-19",
      datekey: 20220619,
      value: 3579.069999999997,
      "Total Amount": 3579.069999999997,
      Customers: 3,
      Transactions: 118,
      Returns: 0,
      Discounts: 1.44,
    },
    {
      day: "2022-06-18",
      datekey: 20220618,
      value: 4002.609999999999,
      "Total Amount": 4002.609999999999,
      Customers: 4,
      Transactions: 146,
      Returns: 19.5,
      Discounts: 2.92,
    },
    {
      day: "2022-06-17",
      datekey: 20220617,
      value: 3508.69,
      "Total Amount": 3508.69,
      Customers: 5,
      Transactions: 124,
      Returns: 14.61,
      Discounts: 5.83,
    },
    {
      day: "2022-06-16",
      datekey: 20220616,
      value: 2732.909999999999,
      "Total Amount": 2732.909999999999,
      Customers: 4,
      Transactions: 104,
      Returns: 0,
      Discounts: 6.54,
    },
    {
      day: "2022-06-15",
      datekey: 20220615,
      value: 2781.819999999999,
      "Total Amount": 2781.819999999999,
      Customers: 5,
      Transactions: 94,
      Returns: 1.35,
      Discounts: 0.75,
    },
    {
      day: "2022-06-14",
      datekey: 20220614,
      value: 2064.979999999999,
      "Total Amount": 2064.979999999999,
      Customers: 2,
      Transactions: 93,
      Returns: 9.4,
      Discounts: 7.13,
    },
    {
      day: "2022-06-13",
      datekey: 20220613,
      value: 2204.43,
      "Total Amount": 2204.43,
      Customers: 3,
      Transactions: 85,
      Returns: 14.15,
      Discounts: 4.48,
    },
    {
      day: "2022-06-12",
      datekey: 20220612,
      value: 3312.17,
      "Total Amount": 3312.17,
      Customers: 4,
      Transactions: 125,
      Returns: 0,
      Discounts: 9.780000000000001,
    },
    {
      day: "2022-06-11",
      datekey: 20220611,
      value: 3331.259999999997,
      "Total Amount": 3331.259999999997,
      Customers: 4,
      Transactions: 141,
      Returns: 40.24,
      Discounts: 5.21,
    },
    {
      day: "2022-06-10",
      datekey: 20220610,
      value: 3790.269999999998,
      "Total Amount": 3790.269999999998,
      Customers: 7,
      Transactions: 135,
      Returns: 0,
      Discounts: 10.74,
    },
    {
      day: "2022-06-09",
      datekey: 20220609,
      value: -306.1600000000016,
      "Total Amount": -306.1600000000016,
      Customers: 3,
      Transactions: 98,
      Returns: 3.88,
      Discounts: 6.8,
    },
    {
      day: "2022-06-08",
      datekey: 20220608,
      value: 6635.249999999997,
      "Total Amount": 6635.249999999997,
      Customers: 5,
      Transactions: 89,
      Returns: 0,
      Discounts: 3.7,
    },
    {
      day: "2022-06-07",
      datekey: 20220607,
      value: 2719.7,
      "Total Amount": 2719.7,
      Customers: 2,
      Transactions: 103,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-06-06",
      datekey: 20220606,
      value: 2675.96,
      "Total Amount": 2675.96,
      Customers: 2,
      Transactions: 88,
      Returns: 16.7,
      Discounts: 8.2,
    },
    {
      day: "2022-06-05",
      datekey: 20220605,
      value: 3414.699999999999,
      "Total Amount": 3414.699999999999,
      Customers: 2,
      Transactions: 151,
      Returns: 3.83,
      Discounts: 11.38,
    },
    {
      day: "2022-06-04",
      datekey: 20220604,
      value: 3193.839999999998,
      "Total Amount": 3193.839999999998,
      Customers: 2,
      Transactions: 121,
      Returns: 7.6,
      Discounts: 15.74,
    },
    {
      day: "2022-06-03",
      datekey: 20220603,
      value: 3480.079999999997,
      "Total Amount": 3480.079999999997,
      Customers: 5,
      Transactions: 122,
      Returns: 3.4,
      Discounts: 7.24,
    },
    {
      day: "2022-06-02",
      datekey: 20220602,
      value: 3110.369999999999,
      "Total Amount": 3110.369999999999,
      Customers: 2,
      Transactions: 119,
      Returns: 0,
      Discounts: 15.45,
    },
    {
      day: "2022-06-01",
      datekey: 20220601,
      value: 2712.7,
      "Total Amount": 2712.7,
      Customers: 2,
      Transactions: 112,
      Returns: 0,
      Discounts: 0.62,
    },
    {
      day: "2022-05-31",
      datekey: 20220531,
      value: 2535.55,
      "Total Amount": 2535.55,
      Customers: 3,
      Transactions: 101,
      Returns: 14.13,
      Discounts: 1.1,
    },
    {
      day: "2022-05-30",
      datekey: 20220530,
      value: 3424.519999999999,
      "Total Amount": 3424.519999999999,
      Customers: 3,
      Transactions: 141,
      Returns: 0,
      Discounts: 3.63,
    },
    {
      day: "2022-05-29",
      datekey: 20220529,
      value: 3841.290000000001,
      "Total Amount": 3841.290000000001,
      Customers: 7,
      Transactions: 149,
      Returns: 20.27,
      Discounts: 13.44,
    },
    {
      day: "2022-05-28",
      datekey: 20220528,
      value: 3211.799999999999,
      "Total Amount": 3211.799999999999,
      Customers: 12,
      Transactions: 130,
      Returns: 18.47,
      Discounts: 6.250000000000001,
    },
    {
      day: "2022-05-27",
      datekey: 20220527,
      value: 3862.689999999998,
      "Total Amount": 3862.689999999998,
      Customers: 13,
      Transactions: 131,
      Returns: 9.85,
      Discounts: 22.83,
    },
    {
      day: "2022-05-26",
      datekey: 20220526,
      value: 3775.109999999998,
      "Total Amount": 3775.109999999998,
      Customers: 11,
      Transactions: 126,
      Returns: 3.34,
      Discounts: 2.78,
    },
    {
      day: "2022-05-25",
      datekey: 20220525,
      value: 1997.98,
      "Total Amount": 1997.98,
      Customers: 22,
      Transactions: 96,
      Returns: 0,
      Discounts: 7.99,
    },
    {
      day: "2022-05-24",
      datekey: 20220524,
      value: 2633.28,
      "Total Amount": 2633.28,
      Customers: 23,
      Transactions: 111,
      Returns: 0,
      Discounts: 6.78,
    },
    {
      day: "2022-05-23",
      datekey: 20220523,
      value: 2209.76,
      "Total Amount": 2209.76,
      Customers: 2,
      Transactions: 111,
      Returns: 21.99,
      Discounts: 5.08,
    },
    {
      day: "2022-05-22",
      datekey: 20220522,
      value: 5054.689999999996,
      "Total Amount": 5054.689999999996,
      Customers: 4,
      Transactions: 185,
      Returns: 6.770000000000001,
      Discounts: 8.42,
    },
    {
      day: "2022-05-21",
      datekey: 20220521,
      value: 3842.609999999997,
      "Total Amount": 3842.609999999997,
      Customers: 4,
      Transactions: 156,
      Returns: 10.4,
      Discounts: 10.86,
    },
    {
      day: "2022-05-20",
      datekey: 20220520,
      value: 2571.29,
      "Total Amount": 2571.29,
      Customers: 5,
      Transactions: 121,
      Returns: 0,
      Discounts: 0.66,
    },
    {
      day: "2022-05-19",
      datekey: 20220519,
      value: 3238.489999999999,
      "Total Amount": 3238.489999999999,
      Customers: 2,
      Transactions: 108,
      Returns: 11.72,
      Discounts: 0,
    },
    {
      day: "2022-05-18",
      datekey: 20220518,
      value: 3309.370000000001,
      "Total Amount": 3309.370000000001,
      Customers: 2,
      Transactions: 106,
      Returns: 1.2,
      Discounts: 2.41,
    },
    {
      day: "2022-05-17",
      datekey: 20220517,
      value: 2262.2,
      "Total Amount": 2262.2,
      Customers: 3,
      Transactions: 92,
      Returns: 0,
      Discounts: 3.03,
    },
    {
      day: "2022-05-16",
      datekey: 20220516,
      value: 2747,
      "Total Amount": 2747,
      Customers: 1,
      Transactions: 99,
      Returns: 14,
      Discounts: 0.46,
    },
    {
      day: "2022-05-15",
      datekey: 20220515,
      value: 11015.78,
      "Total Amount": 11015.78,
      Customers: 1,
      Transactions: 276,
      Returns: 16.44,
      Discounts: 78.24000000000001,
    },
    {
      day: "2022-05-14",
      datekey: 20220514,
      value: 4026.189999999997,
      "Total Amount": 4026.189999999997,
      Customers: 2,
      Transactions: 158,
      Returns: 3.08,
      Discounts: 4.17,
    },
    {
      day: "2022-05-13",
      datekey: 20220513,
      value: 3816.25,
      "Total Amount": 3816.25,
      Customers: 3,
      Transactions: 121,
      Returns: 1.8,
      Discounts: 17.83,
    },
    {
      day: "2022-05-12",
      datekey: 20220512,
      value: 2887.28,
      "Total Amount": 2887.28,
      Customers: 4,
      Transactions: 98,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-05-11",
      datekey: 20220511,
      value: 2861.63,
      "Total Amount": 2861.63,
      Customers: 1,
      Transactions: 118,
      Returns: 0,
      Discounts: 21.3,
    },
    {
      day: "2022-05-10",
      datekey: 20220510,
      value: 2713.38,
      "Total Amount": 2713.38,
      Customers: 3,
      Transactions: 95,
      Returns: 3.34,
      Discounts: 0.7,
    },
    {
      day: "2022-05-09",
      datekey: 20220509,
      value: 1903.630000000001,
      "Total Amount": 1903.630000000001,
      Customers: 1,
      Transactions: 71,
      Returns: 0,
      Discounts: 0.13,
    },
    {
      day: "2022-05-08",
      datekey: 20220508,
      value: 4092.079999999999,
      "Total Amount": 4092.079999999999,
      Customers: 3,
      Transactions: 141,
      Returns: 0,
      Discounts: 3.39,
    },
    {
      day: "2022-05-07",
      datekey: 20220507,
      value: 3778.309999999998,
      "Total Amount": 3778.309999999998,
      Customers: 4,
      Transactions: 124,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-05-06",
      datekey: 20220506,
      value: 4182.83,
      "Total Amount": 4182.83,
      Customers: 3,
      Transactions: 137,
      Returns: 6.4,
      Discounts: 1.24,
    },
    {
      day: "2022-05-05",
      datekey: 20220505,
      value: 2962.789999999999,
      "Total Amount": 2962.789999999999,
      Customers: 3,
      Transactions: 92,
      Returns: 0,
      Discounts: 2.21,
    },
    {
      day: "2022-05-04",
      datekey: 20220504,
      value: 2118.19,
      "Total Amount": 2118.19,
      Customers: 2,
      Transactions: 100,
      Returns: 3.19,
      Discounts: 11.2,
    },
    {
      day: "2022-05-03",
      datekey: 20220503,
      value: 2034.950000000001,
      "Total Amount": 2034.950000000001,
      Customers: 2,
      Transactions: 96,
      Returns: 0,
      Discounts: 0.85,
    },
    {
      day: "2022-05-02",
      datekey: 20220502,
      value: 2097.84,
      "Total Amount": 2097.84,
      Customers: 1,
      Transactions: 91,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-05-01",
      datekey: 20220501,
      value: 4214.459999999999,
      "Total Amount": 4214.459999999999,
      Customers: 2,
      Transactions: 177,
      Returns: 88.4,
      Discounts: 29.63,
    },
    {
      day: "2022-04-30",
      datekey: 20220430,
      value: 4711.629999999997,
      "Total Amount": 4711.629999999997,
      Customers: 3,
      Transactions: 155,
      Returns: 10.25,
      Discounts: 5.3,
    },
    {
      day: "2022-04-29",
      datekey: 20220429,
      value: 3518.929999999999,
      "Total Amount": 3518.929999999999,
      Customers: 3,
      Transactions: 114,
      Returns: 2.5,
      Discounts: 8.309999999999999,
    },
    {
      day: "2022-04-28",
      datekey: 20220428,
      value: 2961.999999999999,
      "Total Amount": 2961.999999999999,
      Customers: 2,
      Transactions: 105,
      Returns: 0,
      Discounts: 1.6,
    },
    {
      day: "2022-04-27",
      datekey: 20220427,
      value: 3048.029999999999,
      "Total Amount": 3048.029999999999,
      Customers: 2,
      Transactions: 139,
      Returns: 12.5,
      Discounts: 1.57,
    },
    {
      day: "2022-04-26",
      datekey: 20220426,
      value: 2850.279999999999,
      "Total Amount": 2850.279999999999,
      Customers: 1,
      Transactions: 128,
      Returns: 7.49,
      Discounts: 2.26,
    },
    {
      day: "2022-04-25",
      datekey: 20220425,
      value: 1645.020000000001,
      "Total Amount": 1645.020000000001,
      Customers: 1,
      Transactions: 83,
      Returns: 0,
      Discounts: 1.8,
    },
    {
      day: "2022-04-24",
      datekey: 20220424,
      value: 4496.510000000001,
      "Total Amount": 4496.510000000001,
      Customers: 1,
      Transactions: 173,
      Returns: 3.5,
      Discounts: 29.97,
    },
    {
      day: "2022-04-23",
      datekey: 20220423,
      value: 3168.1,
      "Total Amount": 3168.1,
      Customers: 1,
      Transactions: 146,
      Returns: 0,
      Discounts: 0.45,
    },
    {
      day: "2022-04-22",
      datekey: 20220422,
      value: 3295.33,
      "Total Amount": 3295.33,
      Customers: 2,
      Transactions: 130,
      Returns: 28.34,
      Discounts: 0.2,
    },
    {
      day: "2022-04-21",
      datekey: 20220421,
      value: 2295.09,
      "Total Amount": 2295.09,
      Customers: 2,
      Transactions: 93,
      Returns: 0,
      Discounts: 10,
    },
    {
      day: "2022-04-20",
      datekey: 20220420,
      value: 1740.950000000001,
      "Total Amount": 1740.950000000001,
      Customers: 2,
      Transactions: 80,
      Returns: 0,
      Discounts: 1.96,
    },
    {
      day: "2022-04-19",
      datekey: 20220419,
      value: 1977.620000000001,
      "Total Amount": 1977.620000000001,
      Customers: 2,
      Transactions: 96,
      Returns: 12,
      Discounts: 16.85,
    },
    {
      day: "2022-04-18",
      datekey: 20220418,
      value: 1215.710000000001,
      "Total Amount": 1215.710000000001,
      Customers: 1,
      Transactions: 83,
      Returns: 5.75,
      Discounts: 2.07,
    },
    {
      day: "2022-04-17",
      datekey: 20220417,
      value: 3632.459999999999,
      "Total Amount": 3632.459999999999,
      Customers: 1,
      Transactions: 149,
      Returns: 0,
      Discounts: 1.2,
    },
    {
      day: "2022-04-16",
      datekey: 20220416,
      value: 3014.17,
      "Total Amount": 3014.17,
      Customers: 2,
      Transactions: 141,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-04-15",
      datekey: 20220415,
      value: 3131.730000000001,
      "Total Amount": 3131.730000000001,
      Customers: 3,
      Transactions: 119,
      Returns: 0,
      Discounts: 17.06,
    },
    {
      day: "2022-04-14",
      datekey: 20220414,
      value: 2150.420000000001,
      "Total Amount": 2150.420000000001,
      Customers: 2,
      Transactions: 111,
      Returns: 0,
      Discounts: 3.73,
    },
    {
      day: "2022-04-13",
      datekey: 20220413,
      value: 2511.03,
      "Total Amount": 2511.03,
      Customers: 1,
      Transactions: 85,
      Returns: 0,
      Discounts: 65.81,
    },
    {
      day: "2022-04-12",
      datekey: 20220412,
      value: 1727.140000000001,
      "Total Amount": 1727.140000000001,
      Customers: 1,
      Transactions: 87,
      Returns: 0,
      Discounts: 15.65,
    },
    {
      day: "2022-04-11",
      datekey: 20220411,
      value: 2553.32,
      "Total Amount": 2553.32,
      Customers: 1,
      Transactions: 95,
      Returns: 0,
      Discounts: 0.2,
    },
    {
      day: "2022-04-10",
      datekey: 20220410,
      value: 2930.4,
      "Total Amount": 2930.4,
      Customers: 1,
      Transactions: 114,
      Returns: 13.35,
      Discounts: 10.13,
    },
    {
      day: "2022-04-09",
      datekey: 20220409,
      value: 2980.55,
      "Total Amount": 2980.55,
      Customers: 1,
      Transactions: 127,
      Returns: 0,
      Discounts: 1.23,
    },
    {
      day: "2022-04-08",
      datekey: 20220408,
      value: 2489.06,
      "Total Amount": 2489.06,
      Customers: 1,
      Transactions: 101,
      Returns: 0,
      Discounts: 13.35,
    },
    {
      day: "2022-04-07",
      datekey: 20220407,
      value: 2749.24,
      "Total Amount": 2749.24,
      Customers: 2,
      Transactions: 103,
      Returns: 8,
      Discounts: 6.13,
    },
    {
      day: "2022-04-06",
      datekey: 20220406,
      value: 2047.46,
      "Total Amount": 2047.46,
      Customers: 1,
      Transactions: 88,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-04-05",
      datekey: 20220405,
      value: 1878.800000000001,
      "Total Amount": 1878.800000000001,
      Customers: 1,
      Transactions: 95,
      Returns: 4.05,
      Discounts: 7.180000000000001,
    },
    {
      day: "2022-04-04",
      datekey: 20220404,
      value: 2283.23,
      "Total Amount": 2283.23,
      Customers: 1,
      Transactions: 95,
      Returns: 0,
      Discounts: 7.709999999999999,
    },
    {
      day: "2022-04-03",
      datekey: 20220403,
      value: 4246.720000000001,
      "Total Amount": 4246.720000000001,
      Customers: 1,
      Transactions: 162,
      Returns: 27.49,
      Discounts: 474.4099999999997,
    },
    {
      day: "2022-04-02",
      datekey: 20220402,
      value: 3458.85,
      "Total Amount": 3458.85,
      Customers: 2,
      Transactions: 144,
      Returns: 8.85,
      Discounts: 383.1799999999997,
    },
    {
      day: "2022-04-01",
      datekey: 20220401,
      value: 3860.329999999999,
      "Total Amount": 3860.329999999999,
      Customers: 1,
      Transactions: 167,
      Returns: 5,
      Discounts: 381.17,
    },
    {
      day: "2022-03-31",
      datekey: 20220331,
      value: 2478.399999999998,
      "Total Amount": 2478.399999999998,
      Customers: 1,
      Transactions: 112,
      Returns: 10.96,
      Discounts: 12.8,
    },
    {
      day: "2022-03-30",
      datekey: 20220330,
      value: 1506.76,
      "Total Amount": 1506.76,
      Customers: 1,
      Transactions: 64,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-29",
      datekey: 20220329,
      value: 1875.620000000001,
      "Total Amount": 1875.620000000001,
      Customers: 1,
      Transactions: 88,
      Returns: 0,
      Discounts: 6.29,
    },
    {
      day: "2022-03-28",
      datekey: 20220328,
      value: 2016.5,
      "Total Amount": 2016.5,
      Customers: 1,
      Transactions: 81,
      Returns: 6.5,
      Discounts: 8.41,
    },
    {
      day: "2022-03-27",
      datekey: 20220327,
      value: 5782.699999999998,
      "Total Amount": 5782.699999999998,
      Customers: 1,
      Transactions: 200,
      Returns: 0,
      Discounts: 626.5200000000003,
    },
    {
      day: "2022-03-26",
      datekey: 20220326,
      value: 7393.009999999991,
      "Total Amount": 7393.009999999991,
      Customers: 1,
      Transactions: 229,
      Returns: 0,
      Discounts: 801.4999999999994,
    },
    {
      day: "2022-03-25",
      datekey: 20220325,
      value: 4.49,
      "Total Amount": 4.49,
      Customers: 1,
      Transactions: 1,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-24",
      datekey: 20220324,
      value: 2.75,
      "Total Amount": 2.75,
      Customers: 1,
      Transactions: 2,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-23",
      datekey: 20220323,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-22",
      datekey: 20220322,
      value: 4.49,
      "Total Amount": 4.49,
      Customers: 1,
      Transactions: 1,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-21",
      datekey: 20220321,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-20",
      datekey: 20220320,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-19",
      datekey: 20220319,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-18",
      datekey: 20220318,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-17",
      datekey: 20220317,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-16",
      datekey: 20220316,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-15",
      datekey: 20220315,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-14",
      datekey: 20220314,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-13",
      datekey: 20220313,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-12",
      datekey: 20220312,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-11",
      datekey: 20220311,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-10",
      datekey: 20220310,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-09",
      datekey: 20220309,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-08",
      datekey: 20220308,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-07",
      datekey: 20220307,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-06",
      datekey: 20220306,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-05",
      datekey: 20220305,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-04",
      datekey: 20220304,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-03",
      datekey: 20220303,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-02",
      datekey: 20220302,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-03-01",
      datekey: 20220301,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-28",
      datekey: 20220228,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-27",
      datekey: 20220227,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-26",
      datekey: 20220226,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-25",
      datekey: 20220225,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-24",
      datekey: 20220224,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-23",
      datekey: 20220223,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-22",
      datekey: 20220222,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-21",
      datekey: 20220221,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-20",
      datekey: 20220220,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-19",
      datekey: 20220219,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-18",
      datekey: 20220218,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-17",
      datekey: 20220217,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-16",
      datekey: 20220216,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-15",
      datekey: 20220215,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-14",
      datekey: 20220214,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-13",
      datekey: 20220213,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-12",
      datekey: 20220212,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-11",
      datekey: 20220211,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-10",
      datekey: 20220210,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-09",
      datekey: 20220209,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-08",
      datekey: 20220208,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-07",
      datekey: 20220207,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-06",
      datekey: 20220206,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-05",
      datekey: 20220205,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-04",
      datekey: 20220204,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-03",
      datekey: 20220203,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-02",
      datekey: 20220202,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-02-01",
      datekey: 20220201,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-31",
      datekey: 20220131,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-30",
      datekey: 20220130,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-29",
      datekey: 20220129,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-28",
      datekey: 20220128,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-27",
      datekey: 20220127,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-26",
      datekey: 20220126,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-25",
      datekey: 20220125,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-24",
      datekey: 20220124,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-23",
      datekey: 20220123,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-22",
      datekey: 20220122,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-21",
      datekey: 20220121,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-20",
      datekey: 20220120,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-19",
      datekey: 20220119,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-18",
      datekey: 20220118,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-17",
      datekey: 20220117,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-16",
      datekey: 20220116,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-15",
      datekey: 20220115,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-14",
      datekey: 20220114,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-13",
      datekey: 20220113,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-12",
      datekey: 20220112,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-11",
      datekey: 20220111,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-10",
      datekey: 20220110,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-09",
      datekey: 20220109,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-08",
      datekey: 20220108,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-07",
      datekey: 20220107,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-06",
      datekey: 20220106,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-05",
      datekey: 20220105,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-04",
      datekey: 20220104,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-03",
      datekey: 20220103,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-02",
      datekey: 20220102,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
    {
      day: "2022-01-01",
      datekey: 20220101,
      value: 0,
      "Total Amount": 0,
      Customers: 0,
      Transactions: 0,
      Returns: 0,
      Discounts: 0,
    },
  ],
}
