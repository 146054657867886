export const ScatterPlotChartData = [
  {
    id: "Min",
    data: [
      { x: 99, y: 66 },
      { x: 51, y: 39 },
      { x: 29, y: 88 },
      { x: 20, y: 37 },
      { x: 69, y: 7 },
      { x: 80, y: 60 },
      { x: 97, y: 65 },
      { x: 56, y: 38 },
      { x: 26, y: 89 },
      { x: 25, y: 38 },
      { x: 63, y: 75 },
      { x: 92, y: 92 },
      { x: 92, y: 96 },
      { x: 54, y: 89 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
      { x: 69, y: 77 },
      { x: 91, y: 68 },
    ],
  },
  {
    id: "Max",
    data: [
      { x: 61, y: 94 },
      { x: 12, y: 10 },
      { x: 33, y: 68 },
      { x: 11, y: 85 },
      { x: 38, y: 42 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
    ],
  },
]

export const ScatterPlotChartParameters = {
  margin: { top: 30, right: 30, bottom: 70, left: 10 },
  xScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  yScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: [0, 15, 45, 75],
    tickFormat: "formatXAxisLabel",
    legendPosition: "middle",
    legendOffset: 46,
    tickSize: 8,
    tickPadding: 10,
    tickRotation: 0,
  },
  gridXValues: [15, 45, 75],
  gridYValues: [50],
  nodeSize: 8,
  layers: ["grid", "axes", "nodes", "CustomGridBackground"],
  colors: (node) =>
    node.serieId === "Min" ? "rgba(92, 41, 199, 1)" : "rgba(240, 134, 76, 1)",
}
