import { postData } from "../../../../api/api"
import { transfromChartData } from "./transformServices"
export const fetchData = async (
  postUrl,
  reportUid,
  tooltip_data,
  dispatch,
  setApiResponse,
  setError,
  setChartData,
  setLoading,
  cacheKey
) => {
  try {
    const response = await postData(postUrl, {
      entityDb: localStorage.getItem("entityDb"),
      reportInstanceId: reportUid,
      fromDate: "20220101",
      toDate: "20241231",
      comparedBy: {
        previousFromDate: "20220101",
        previousToDate: "20231231",
      },
    })
    setApiResponse(response)
    if (!!response === false || Object.keys(response).length === 0) {
      setError("Inadequate data points to generate a report")
    } else {
      dispatch(
        setChartData({
          reportUid,
          response: transfromChartData(response, tooltip_data),
          cacheKey: cacheKey,
          apiResponse: response,
        })
      )
    }
  } catch (error) {
    setError("Inadequate data points to generate a report")
  } finally {
    setLoading(false)
  }
}
