export const FunnelTooltip = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Profit Percent",
    format: "Percentage",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
]

export const chartContainerDimentionsFallback = {
  height: "600px",
  minHeight: "600px",
  maxHeight: "unset",
}
