import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { formatDate } from "../../utils/dateUtils"

const today = dayjs()
const startOfMonth = today.startOf("month")
const isBefore7th = today.date() <= 7
// Check if the current time is before 2:00 PM
const isBefore2PM =
  today.hour() < 14 || (today.hour() === 14 && today.minute() < 0)

let fromDate, toDate, label

if (isBefore7th) {
  // If today is between the 1st and 7th of the month, take the last 30 days
  fromDate = today.subtract(30, "days")
  toDate = isBefore2PM ? today.subtract(2, "day") : today.subtract(1, "day")
  label = {
    shortcutLabel: "Last 30 Days",
    formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
      toDate,
      "MMM D"
    )}`,
  }
} else {
  // If today is after the 7th, take the range from 1st of this month to today
  fromDate = startOfMonth
  toDate = isBefore2PM ? today.subtract(2, "day") : today.subtract(1, "day")
  label = {
    shortcutLabel: "This Month",
    formattedRange: `${formatDate(fromDate, "MMM D")} - ${formatDate(
      toDate,
      "MMM D"
    )}`,
  }
}

const initialState = {
  fromDate: formatDate(fromDate, "YYYYMMDD"),
  toDate: formatDate(toDate, "YYYYMMDD"),
  label,
}

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setDateRangeLabel: (state, action) => {
      state.fromDate = formatDate(action.payload.fromDate, "YYYYMMDD")
      // intentional null to force user to pick end date
      state.toDate =
        action.payload.toDate === null
          ? null
          : formatDate(action.payload.toDate, "YYYYMMDD")
      state.label = action.payload.label
    },
  },
})

export const { setDateRangeLabel } = calendarSlice.actions

export default calendarSlice.reducer
