export const BarTooltipData = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimentionsFallback = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const colorScheme = ["#8dd3c7", "#ffffb3", "#bebada", "#fb8072"]

// colorPalette.js
export const colorPalette = [
  "#7282FF",
  "#D084E4",
  "#A17BC8",
  "#2271B4",
  "#6E8B3D",
  "#C75D8A",
  "#DB7093",
  "#5C73F2",
  "#3D50F5",
  "#A17BC8",
  "#007BFF",
  "#17A2B8",
  "#8B93D1",
  "#E900EF",
  "#343AFF",
  "#8067E3",
  "#A96FFF",
  "#FF85E1",
  "#95C75B",
  "#BBC954",
]

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
