import { ResponsivePie } from "@nivo/pie"
import Box from "@mui/material/Box"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"

const customTheme = {
  tooltip: {
    container: {
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
    },
  },
}

const PieChartPresentation = ({
  chartInput,
  chartConfig,
  colorScheme,
  showPrevious,
}) => {
  let {
    startAngle,
    innerRadius,
    padAngle,
    borderWidth,
    borderColor,
    arcLabelsRadiusOffset,
    arcLabelsSkipAngle,
    arcLabelsTextColor,
    animate,
  } = chartConfig
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  return (
    <Box
      display="flex"
      sx={{
        height: "270px",
        width: "270px",
        marginBlock: 1,
      }}
      flex={1}
    >
      <ResponsivePie
        data={chartInput}
        theme={customTheme}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        startAngle={startAngle}
        innerRadius={innerRadius}
        padAngle={padAngle}
        cornerRadius={0}
        activeInnerRadiusOffset={4}
        activeOuterRadiusOffset={5}
        colors={colorScheme}
        borderWidth={borderWidth}
        sortByValue={true}
        borderColor={borderColor}
        enableArcLinkLabels={false}
        arcLinkLabel="id"
        arcLinkLabelsTextOffset={9}
        arcLinkLabelsTextColor={itemTextColor}
        arcLinkLabelsOffset={-20}
        arcLinkLabelsDiagonalLength={25}
        arcLinkLabelsStraightLength={3}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLinkLabelsSkipAngle={0}
        enableArcLabels={false}
        arcLabelsRadiusOffset={arcLabelsRadiusOffset}
        arcLabelsSkipAngle={arcLabelsSkipAngle}
        arcLabelsTextColor={arcLabelsTextColor}
        animate={animate}
        tooltip={({ datum }) => (

          <CustomTooltip
            data={datum.data}
            showCurrentPrevDate={true}
            showLegendName={true}
            dataDatum={datum}
            showPrevious={showPrevious}
          />
        )}
      />
    </Box>
  )
}

export default PieChartPresentation
