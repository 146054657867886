import React, { useState, useEffect, useRef } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg.jsx"
import { useTheme } from "../../../contexts/theme.jsx"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs.js"
import InfoToolTip from "../../InfoToolTip.jsx"
import ViewBy from "../../ViewBy.jsx"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import TablePagination from "@mui/material/TablePagination"
import { useDispatch, useSelector } from "react-redux"
import CustomLegend from "../../CustomLegend.jsx"
import ExportChartsData from "../../ExportChartsData.jsx"
import ExportMenuButton from "../../ExportMenuButton.jsx"
import ProgressBarPresentation from "./ProgressBarPresentation.jsx"
import { Array1, Array2, staticExtraDetails } from "./constant/dataFallback.js"
import { transformExportData } from "./services/transformServices.js"
import { fetchData } from "./services/dataServices.js"
import { CustomPaginationActions } from "./services/configServices.js"
import { ProgressBarData } from "./constant/configFallback.js"
import { generateCacheKey } from "../../../utils/utils.js"

const paginationStyles = {
  ".MuiTablePagination-selectLabel": {
    display: "none !important",
  },
  ".MuiTablePagination-displayedRows": {
    display: "none !important",
  },
  ".MuiTablePagination-actions": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}

const ProgressBarContainer = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters = [],
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showPagination = false,
  showAiDropdown = true,
  staticData = ProgressBarData,
  setAnchorEl,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [rowsPerPage, setRowsPerPage] = useState(
    extractedParameters.defaultRowsPerPage || 10
  )
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(0)
  const [apiResponse, setApiResponse] = useState([])
  const [maxSliderValue, setMaxSliderValue] = useState(0)
  const [displayedData, setDisplayedData] = useState([])
  const dispatch = useDispatch()
  const svgColor = theme.palette.mode === "dark" ? "#BCCAF6" : "#1D47CE"
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  const tooltip_data = extractedParameters.tooltip_data || []

  const fieldMap = extractedParameters.fieldMap || {}

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const ProgressBarColor = [
    "Most Popular Brands",
    "Top 10 products bought",
    "Popular Items with Repeat Customers",
  ].includes(title)

  const beforePointerColors = ProgressBarColor ? Array1 : Array2

  const navigationPaths = {
    customer: "/customers/allcustomers/customer/1",
    employee: "/employees/allemployees/employee/1",
    inventory: "/products/allproducts/product/1",
  }

  const navigationPath =
    navigationPaths[route] || "/customers/allcustomers/customer/1"

  const afterPointerColor = "rgba(7, 19, 57, 1)"

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const enableNavigation =
    extractedParameters.enableNavigation === undefined
      ? false
      : extractedParameters.enableNavigation
  const showBubble =
    extractedParameters.showBubble === undefined
      ? true
      : extractedParameters.showBubble

  const roundToNearest10 = (value) => Math.ceil(value / 10) * 10

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      setLoading,
      setError,
      postUrl,
      combinedState,
      reportUid,
      dispatch,
      setChartData,
      setApiResponse,
      setMaxSliderValue,
      roundToNearest10,
      tooltip_data,
      fieldMap,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport ? selectedReport.reportData : null
  })

  useEffect(() => {
    const updateDisplayedData = () => {
      const paginatedData = Array.isArray(response)
        ? response.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : staticData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

      if (paginatedData.length === 0) {
        console.warn("No data to display for current page")
      }

      setDisplayedData(paginatedData)

      if (paginatedData.length > 0) {
        const maxValue = Math.max(
          ...paginatedData.map((item) => item.defaultValue || 0)
        )
        const roundedMaxValue = roundToNearest10(maxValue)

        setMaxSliderValue(roundedMaxValue)
      }
    }

    if (response && response.length > 0) {
      updateDisplayedData()
    } else {
      console.warn("Response data is invalid or empty")
      const staticMaxValue = Math.max(
        ...staticData.map((item) => item.defaultValue || 0)
      )
      setMaxSliderValue(roundToNearest10(staticMaxValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const totalItems = Array.isArray(response)
    ? response.length
    : staticData.length

  const tableData = transformExportData(reduxApiResponse, extractedParameters)
  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef} minHeight="auto">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="auto"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <Stack flexWrap="wrap" marginTop="10px">
            {staticData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <ProgressBarPresentation
                  showTooltipPrevious={showTooltipPrevious}
                  key={index}
                  item={{
                    ...(staticExtraDetails?.[item?.title] || {}),
                    ...item,
                  }}
                  defaultValue={item.defaultValue}
                  maxValue={maxSliderValue}
                  tooltipData={item.tooltipData}
                  beforePointerColor={
                    beforePointerColors[index % beforePointerColors.length]
                  }
                  afterPointerColor={afterPointerColor}
                  enableNavigation={enableNavigation}
                  navigationPath={navigationPath}
                  showBubble={showBubble}
                />
              ))}
            {showPagination && (
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                sx={paginationStyles}
                ActionsComponent={CustomPaginationActions}
              />
            )}
            <CustomLegend
              reportTitle={apiResponse}
              reportData={staticData}
              showAiDropdown={showAiDropdown}
            />
          </Stack>
        ) : (
          <Stack flexWrap="wrap" marginTop="10px">
            {displayedData.map((item, index) => (
              <ProgressBarPresentation
                showTooltipPrevious={showTooltipPrevious}
                key={index}
                item={{
                  ...(staticExtraDetails?.[item?.title] || {}),
                  ...item,
                }}
                defaultValue={item.defaultValue}
                maxValue={maxSliderValue}
                tooltipData={item.tooltipData}
                beforePointerColor={
                  beforePointerColors[index % beforePointerColors.length]
                }
                afterPointerColor={afterPointerColor}
                enableNavigation={enableNavigation}
                navigationPath={navigationPath}
                showBubble={showBubble}
              />
            ))}
            {showPagination && totalItems > 10 && (
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                sx={paginationStyles}
                ActionsComponent={CustomPaginationActions}
              />
            )}
            <CustomLegend
              reportTitle={title}
              reportData={apiResponse}
              showAiDropdown={showAiDropdown}
            />
          </Stack>
        )}
      </Box>
    </>
  )
}

export default ProgressBarContainer
