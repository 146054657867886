import Box from "@mui/material/Box"
import Star from "../../src/assets/svgs/Star"

export const RadarChartData = [
  {
    category: "Quality",
    "Team A": 75,

    tooltipData: {
      current: {
        "Team A": {
          value: 75,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 70,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Accessibility",
    "Team A": 90,

    tooltipData: {
      current: {
        "Team A": {
          value: 90,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Product Range",
    "Team A": 80,

    tooltipData: {
      current: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 78,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Healthy",
    "Team A": 85,

    tooltipData: {
      current: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 80,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Customer Satisfaction",
    "Team A": 88,

    tooltipData: {
      current: {
        "Team A": {
          value: 88,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
  {
    category: "Service",
    "Team A": 28,

    tooltipData: {
      current: {
        "Team A": {
          value: 28,
          format: "Percentage",
        },
      },
      previous: {
        "Team A": {
          value: 85,
          format: "Percentage",
        },
      },
    },
  },
]

export const RadarChartParameters = {
  keys: ["Team A"],
  indexBy: "category",
  maxValue: 100,
  margin: { top: 20, right: 50, bottom: 20, left: 50 },
  curve: "linearClosed",
  gridShape: "linear",
  borderWidth: 2,
  borderColor: { from: "color", modifiers: [["darker", 6]] },
  gridLevels: 2,
  gridLabelOffset: 10,
  enableDots: false,
  dotSize: 10,
  dotColor: { theme: "background" },
  dotBorderWidth: 2,
  dotBorderColor: { from: "color" },
  enableDotLabel: false,
  dotLabel: "value",
  dotLabelYOffset: -12,
  colors: "#438B53",
  fillOpacity: 0.25,
  blendMode: "multiply",
  animate: true,
  motionConfig: "wobbly",
  isInteractive: true,
  legends: [
    {
      anchor: "top-left",
      direction: "column",
      translateX: -50,
      translateY: -40,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
}

export const Bumpchartdata = [
  {
    id: "KC GONZALEZ",
    data: [
      {
        x: "Jan",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4461.25, format: "Currency" } },
          previous: { Sales: { value: 3350.77, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 2875.5, format: "Currency" } },
          previous: { Sales: { value: 2650.8, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "BREE RUBENZER",
    data: [
      {
        x: "Jan",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1200.45, format: "Currency" } },
          previous: { Sales: { value: 1000.33, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.9, format: "Currency" } },
          previous: { Sales: { value: 4900.77, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.1, format: "Currency" } },
          previous: { Sales: { value: 4200.45, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5300.75, format: "Currency" } },
          previous: { Sales: { value: 5000.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 3000.4, format: "Currency" } },
          previous: { Sales: { value: 2750.33, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "CHRISTINE SMOCZYK",
    data: [
      {
        x: "Jan",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3300.75, format: "Currency" } },
          previous: { Sales: { value: 3100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.2, format: "Currency" } },
          previous: { Sales: { value: 4700.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2200.55, format: "Currency" } },
          previous: { Sales: { value: 2000.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4850.3, format: "Currency" } },
          previous: { Sales: { value: 4600.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5100.45, format: "Currency" } },
          previous: { Sales: { value: 4900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3750.9, format: "Currency" } },
          previous: { Sales: { value: 3500.55, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.33, format: "Currency" } },
          previous: { Sales: { value: 3800.22, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2500.75, format: "Currency" } },
          previous: { Sales: { value: 2300.44, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "KIM POPP",
    data: [
      {
        x: "Jan",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.7, format: "Currency" } },
          previous: { Sales: { value: 4200.9, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.1, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "Maria Silva",
    data: [
      {
        x: "Jan",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3600.45, format: "Currency" } },
          previous: { Sales: { value: 3400.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4200.5, format: "Currency" } },
          previous: { Sales: { value: 4000.45, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.75, format: "Currency" } },
          previous: { Sales: { value: 2900.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 2100.9, format: "Currency" } },
          previous: { Sales: { value: 1900.4, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3300.2, format: "Currency" } },
          previous: { Sales: { value: 3100.55, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1050.2, format: "Currency" } },
          previous: { Sales: { value: 950.6, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1800.75, format: "Currency" } },
          previous: { Sales: { value: 1600.5, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4500.6, format: "Currency" } },
          previous: { Sales: { value: 4200.85, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MARIA RAMOS",
    data: [
      {
        x: "Jan",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.75, format: "Currency" } },
          previous: { Sales: { value: 3900.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4100.3, format: "Currency" } },
          previous: { Sales: { value: 3900.1, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.9, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 10,
        tooltipData: {
          current: { Sales: { value: 4400.2, format: "Currency" } },
          previous: { Sales: { value: 4200.75, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.5, format: "Currency" } },
          previous: { Sales: { value: 3500.3, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "PAMELA M",
    data: [
      {
        x: "Jan",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.6, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.3, format: "Currency" } },
          previous: { Sales: { value: 2700.2, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.9, format: "Currency" } },
          previous: { Sales: { value: 3300.7, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3500.75, format: "Currency" } },
          previous: { Sales: { value: 3300.55, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 3800.6, format: "Currency" } },
          previous: { Sales: { value: 3600.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "MAKENA E",
    data: [
      {
        x: "Jan",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5000.25, format: "Currency" } },
          previous: { Sales: { value: 4800.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 1500.5, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 9,
        tooltipData: {
          current: { Sales: { value: 4000.8, format: "Currency" } },
          previous: { Sales: { value: 3800.6, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.3, format: "Currency" } },
          previous: { Sales: { value: 1100.2, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 1000.6, format: "Currency" } },
          previous: { Sales: { value: 800.45, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JESSICA K",
    data: [
      {
        x: "Jan",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 800.3, format: "Currency" } },
          previous: { Sales: { value: 600.2, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1000.7, format: "Currency" } },
          previous: { Sales: { value: 800.6, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 12,
        tooltipData: {
          current: { Sales: { value: 5200.1, format: "Currency" } },
          previous: { Sales: { value: 5000.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3500.2, format: "Currency" } },
          previous: { Sales: { value: 3300.1, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4600.8, format: "Currency" } },
          previous: { Sales: { value: 4400.6, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "JOHNA SH",
    data: [
      {
        x: "Jan",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3400.4, format: "Currency" } },
          previous: { Sales: { value: 3200.3, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 8,
        tooltipData: {
          current: { Sales: { value: 3700.9, format: "Currency" } },
          previous: { Sales: { value: 3500.8, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.5, format: "Currency" } },
          previous: { Sales: { value: 2600.4, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2800.6, format: "Currency" } },
          previous: { Sales: { value: 2600.5, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1300.7, format: "Currency" } },
          previous: { Sales: { value: 1100.4, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "ALEXA P",
    data: [
      {
        x: "Jan",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 2900.75, format: "Currency" } },
          previous: { Sales: { value: 2700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 3,
        tooltipData: {
          current: { Sales: { value: 2000.8, format: "Currency" } },
          previous: { Sales: { value: 1800.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
    ],
  },
  {
    id: "AVA ARONSON",
    data: [
      {
        x: "Jan",
        y: 5,
        tooltipData: {
          current: { Sales: { value: 2700.5, format: "Currency" } },
          previous: { Sales: { value: 2500.4, format: "Currency" } },
        },
      },
      {
        x: "Feb",
        y: 7,
        tooltipData: {
          current: { Sales: { value: 3100.8, format: "Currency" } },
          previous: { Sales: { value: 2900.7, format: "Currency" } },
        },
      },
      {
        x: "Mar",
        y: 11,
        tooltipData: {
          current: { Sales: { value: 4900.1, format: "Currency" } },
          previous: { Sales: { value: 4700.9, format: "Currency" } },
        },
      },
      {
        x: "Apr",
        y: 1,
        tooltipData: {
          current: { Sales: { value: 900.7, format: "Currency" } },
          previous: { Sales: { value: 700.6, format: "Currency" } },
        },
      },
      {
        x: "May",
        y: 6,
        tooltipData: {
          current: { Sales: { value: 3100.6, format: "Currency" } },
          previous: { Sales: { value: 2900.5, format: "Currency" } },
        },
      },
      {
        x: "Jun",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1500.6, format: "Currency" } },
          previous: { Sales: { value: 1300.4, format: "Currency" } },
        },
      },
      {
        x: "Jul",
        y: 4,
        tooltipData: {
          current: { Sales: { value: 2300.8, format: "Currency" } },
          previous: { Sales: { value: 2100.7, format: "Currency" } },
        },
      },
      {
        x: "Aug",
        y: 2,
        tooltipData: {
          current: { Sales: { value: 1200.9, format: "Currency" } },
          previous: { Sales: { value: 1000.75, format: "Currency" } },
        },
      },
    ],
  },
]

export const BumpChartParameters = {
  margin: {
    top: 40,
    right: 100,
    bottom: 40,
    left: 60,
  },
  lineWidth: 3,
  activeLineWidth: 6,
  inactiveLineWidth: 3,
  inactiveOpacity: 0.15,
  pointSize: 10,
  activePointSize: 16,
  inactivePointSize: 0,
  pointColor: { theme: "background" },
  pointBorderWidth: 3,
  activePointBorderWidth: 3,
  pointBorderColor: { from: "serie.color" },
  axisTop: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -36,
    truncateTickAt: 0,
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: 32,
    truncateTickAt: 0,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Ranking",
    legendPosition: "middle",
    legendOffset: -40,
    truncateTickAt: 0,
  },
  axisRight: null,
}

export const WordCloudData = [
  {
    text: "Fatty",
    value: 1845,
  },
  {
    text: "Organic",
    value: 1213,
  },
  {
    text: "Greasy",
    value: 1575,
  },
  {
    text: "Overcooked",
    value: 987,
  },
  {
    text: "Gluten-free",
    value: 362,
  },
  {
    text: "Vegan",
    value: 798,
  },
  {
    text: "Expired",
    value: 914,
  },
  {
    text: "Artificial Flowers",
    value: 671,
  },
  {
    text: "Delicious",
    value: 192,
  },
  {
    text: "Processed",
    value: 497,
  },
  {
    text: "Gourment",
    value: 1569,
  },
  {
    text: "Stale",
    value: 948,
  },
  {
    text: "Fresh",
    value: 716,
  },
  {
    text: "Bland",
    value: 831,
  },
  {
    text: "Homemade",
    value: 1082,
  },
  {
    text: "Sustainable",
    value: 994,
  },
  {
    text: "Healthy",
    value: 733,
  },
]

export const DashLineChartData = [
  {
    id: "Actual",
    data: [
      {
        x: 1,
        y: 3283.68,
        tooltipData: {
          current: {
            Sales: {
              value: 3283.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3772.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3327.99,
        tooltipData: {
          current: {
            Sales: {
              value: 3327.99,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4199.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 4899.86,
        tooltipData: {
          current: {
            Sales: {
              value: 4899.86,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3814.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3773.12,
        tooltipData: {
          current: {
            Sales: {
              value: 3773.12,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3911.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3015.48,
        tooltipData: {
          current: {
            Sales: {
              value: 3015.48,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4715.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 2325.96,
        tooltipData: {
          current: {
            Sales: {
              value: 2325.96,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 3783.66,
        tooltipData: {
          current: {
            Sales: {
              value: 3783.66,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 3047.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3047.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 333.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3449.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3449.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4238.91,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 5128.68,
        tooltipData: {
          current: {
            Sales: {
              value: 5128.68,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3369.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3541.7,
        tooltipData: {
          current: {
            Sales: {
              value: 3541.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4078,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3417.72,
        tooltipData: {
          current: {
            Sales: {
              value: 3417.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3412.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3497.3,
        tooltipData: {
          current: {
            Sales: {
              value: 3497.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2718.32,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 5293.04,
        tooltipData: {
          current: {
            Sales: {
              value: 5293.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3471.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 3807.56,
        tooltipData: {
          current: {
            Sales: {
              value: 3807.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3920.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3173.44,
        tooltipData: {
          current: {
            Sales: {
              value: 3173.44,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3797.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3562.6,
        tooltipData: {
          current: {
            Sales: {
              value: 3562.6,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3941.54,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 4804,
        tooltipData: {
          current: {
            Sales: {
              value: 4804,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3977.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 2375.58,
        tooltipData: {
          current: {
            Sales: {
              value: 2375.58,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3767.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 2670.94,
        tooltipData: {
          current: {
            Sales: {
              value: 2670.94,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2795.23,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 3953.26,
        tooltipData: {
          current: {
            Sales: {
              value: 3953.26,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2854.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 3804.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3804.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3719.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 4098.72,
        tooltipData: {
          current: {
            Sales: {
              value: 4098.72,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2455.66,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3621.33,
        tooltipData: {
          current: {
            Sales: {
              value: 3621.33,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3815.25,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 4160.49,
        tooltipData: {
          current: {
            Sales: {
              value: 4160.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4496.89,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 2718.7,
        tooltipData: {
          current: {
            Sales: {
              value: 2718.7,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4459.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 2485.69,
        tooltipData: {
          current: {
            Sales: {
              value: 2485.69,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3166.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 3350.77,
        tooltipData: {
          current: {
            Sales: {
              value: 3350.77,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2405.79,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 3300.89,
        tooltipData: {
          current: {
            Sales: {
              value: 3300.89,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3600.15,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Forecast",
    data: [
      {
        x: 1,
        y: 2628.32,
        tooltipData: {
          current: {
            Sales: {
              value: 2628.32,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3731.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 2,
        y: 3596.17,
        tooltipData: {
          current: {
            Sales: {
              value: 3596.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3405.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 3,
        y: 3563.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3563.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3668.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 4,
        y: 3664.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3664.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4169.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 5,
        y: 3817.15,
        tooltipData: {
          current: {
            Sales: {
              value: 3817.15,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4272.24,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 6,
        y: 3820.46,
        tooltipData: {
          current: {
            Sales: {
              value: 3820.46,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2521.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 7,
        y: 2531.42,
        tooltipData: {
          current: {
            Sales: {
              value: 2531.42,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2624.17,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 8,
        y: 2636.97,
        tooltipData: {
          current: {
            Sales: {
              value: 2636.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3636.37,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 9,
        y: 3561.18,
        tooltipData: {
          current: {
            Sales: {
              value: 3561.18,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3404.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 10,
        y: 3477.93,
        tooltipData: {
          current: {
            Sales: {
              value: 3477.93,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3652.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 11,
        y: 3586.8,
        tooltipData: {
          current: {
            Sales: {
              value: 3586.8,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4200.31,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 12,
        y: 3842.28,
        tooltipData: {
          current: {
            Sales: {
              value: 3842.28,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4252.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 13,
        y: 3901.04,
        tooltipData: {
          current: {
            Sales: {
              value: 3901.04,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2500.98,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 14,
        y: 2514.56,
        tooltipData: {
          current: {
            Sales: {
              value: 2514.56,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2603.51,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 15,
        y: 2616.3,
        tooltipData: {
          current: {
            Sales: {
              value: 2616.3,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 16,
        y: 3537.4,
        tooltipData: {
          current: {
            Sales: {
              value: 3537.4,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3385.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 17,
        y: 3450.54,
        tooltipData: {
          current: {
            Sales: {
              value: 3450.54,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3632.11,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 18,
        y: 3559.97,
        tooltipData: {
          current: {
            Sales: {
              value: 3559.97,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4182.85,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 19,
        y: 3822.79,
        tooltipData: {
          current: {
            Sales: {
              value: 3822.79,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4232.44,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 20,
        y: 3885.49,
        tooltipData: {
          current: {
            Sales: {
              value: 3885.49,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2480.64,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 21,
        y: 2492.07,
        tooltipData: {
          current: {
            Sales: {
              value: 2492.07,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2583.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 22,
        y: 2593.53,
        tooltipData: {
          current: {
            Sales: {
              value: 2593.53,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3591.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 23,
        y: 3514.41,
        tooltipData: {
          current: {
            Sales: {
              value: 3514.41,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3365.46,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 24,
        y: 3427.29,
        tooltipData: {
          current: {
            Sales: {
              value: 3427.29,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 3611.82,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 25,
        y: 3536.76,
        tooltipData: {
          current: {
            Sales: {
              value: 3536.76,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4162.71,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 26,
        y: 3800.1,
        tooltipData: {
          current: {
            Sales: {
              value: 3800.1,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 4212.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 27,
        y: 3863.09,
        tooltipData: {
          current: {
            Sales: {
              value: 3863.09,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2460.33,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 28,
        y: 2469.17,
        tooltipData: {
          current: {
            Sales: {
              value: 2469.17,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2562.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: 29,
        y: 2570.62,
        tooltipData: {
          current: {
            Sales: {
              value: 2570.62,
              format: "Currency",
            },
          },
          previous: {
            Sales: {
              value: 2469.26,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]

export const DashLineChartParameters = {
  margin: { top: 40, right: 20, bottom: 50, left: 60 },
  xScale: {
    type: "point",
    min: 0,
    max: "auto",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  lineWidth: 3,

  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Value",
    legendOffset: -55,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: true,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgb(251, 101, 29)", "rgb(0, 155, 230)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}

export const LineBarChartData = [
  {
    day: 1,
    date: "2024-8-1",
    barValue: 3283.68,
    lineValue: 3740.12,
    tooltipData: {
      currentDate: "2024-8-1",
      previousDate: "2024-7-3",
      current: {
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 2,
    date: "2024-8-2",
    barValue: 3321.99,
    lineValue: 4188.27,
    tooltipData: {
      currentDate: "2024-8-2",
      previousDate: "2024-7-4",
      current: {
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 3,
    date: "2024-8-3",
    barValue: 4900.36,
    lineValue: 3676.93,
    tooltipData: {
      currentDate: "2024-8-3",
      previousDate: "2024-7-5",
      current: {
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 4,
    date: "2024-8-4",
    barValue: 3773.12,
    lineValue: 3900.37,
    tooltipData: {
      currentDate: "2024-8-4",
      previousDate: "2024-7-6",
      current: {
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 5,
    date: "2024-8-5",
    barValue: 2973.24,
    lineValue: 4715.62,
    tooltipData: {
      currentDate: "2024-8-5",
      previousDate: "2024-7-7",
      current: {
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 6,
    date: "2024-8-6",
    barValue: 2325.96,
    lineValue: 0,
    tooltipData: {
      currentDate: "2024-8-6",
      previousDate: "2024-7-8",
      current: {
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 7,
    date: "2024-8-7",
    barValue: 3768.57,
    lineValue: 0,
    tooltipData: {
      currentDate: "2024-8-7",
      previousDate: "2024-7-9",
      current: {
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 8,
    date: "2024-8-8",
    barValue: 3047.64,
    lineValue: 333.37,
    tooltipData: {
      currentDate: "2024-8-8",
      previousDate: "2024-7-10",
      current: {
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 9,
    date: "2024-8-9",
    barValue: 3449.76,
    lineValue: 4238.91,
    tooltipData: {
      currentDate: "2024-8-9",
      previousDate: "2024-7-11",
      current: {
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 10,
    date: "2024-8-10",
    barValue: 5123.1,
    lineValue: 3275.42,
    tooltipData: {
      currentDate: "2024-8-10",
      previousDate: "2024-7-12",
      current: {
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 11,
    date: "2024-8-11",
    barValue: 3534.91,
    lineValue: 4077.01,
    tooltipData: {
      currentDate: "2024-8-11",
      previousDate: "2024-7-13",
      current: {
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 12,
    date: "2024-8-12",
    barValue: 3417.72,
    lineValue: 3412.24,
    tooltipData: {
      currentDate: "2024-8-12",
      previousDate: "2024-7-14",
      current: {
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 13,
    date: "2024-8-13",
    barValue: 3443.92,
    lineValue: 2718.32,
    tooltipData: {
      currentDate: "2024-8-13",
      previousDate: "2024-7-15",
      current: {
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 0,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 14,
    date: "2024-8-14",
    barValue: 5222.11,
    lineValue: 3471.69,
    tooltipData: {
      currentDate: "2024-8-14",
      previousDate: "2024-7-16",
      current: {
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 15,
    date: "2024-8-15",
    barValue: 3807.56,
    lineValue: 3920.59,
    tooltipData: {
      currentDate: "2024-8-15",
      previousDate: "2024-7-17",
      current: {
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 16,
    date: "2024-8-16",
    barValue: 3173.44,
    lineValue: 3796.93,
    tooltipData: {
      currentDate: "2024-8-16",
      previousDate: "2024-7-18",
      current: {
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 17,
    date: "2024-8-17",
    barValue: 3562.6,
    lineValue: 3922.97,
    tooltipData: {
      currentDate: "2024-8-17",
      previousDate: "2024-7-19",
      current: {
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71000000000001,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 18,
    date: "2024-8-18",
    barValue: 4795.91,
    lineValue: 3977.17,
    tooltipData: {
      currentDate: "2024-8-18",
      previousDate: "2024-7-20",
      current: {
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 19,
    date: "2024-8-19",
    barValue: 2375.58,
    lineValue: 3763.96,
    tooltipData: {
      currentDate: "2024-8-19",
      previousDate: "2024-7-21",
      current: {
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 20,
    date: "2024-8-20",
    barValue: 2664.94,
    lineValue: 2797.87,
    tooltipData: {
      currentDate: "2024-8-20",
      previousDate: "2024-7-22",
      current: {
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 21,
    date: "2024-8-21",
    barValue: 3953.26,
    lineValue: 2854.63,
    tooltipData: {
      currentDate: "2024-8-21",
      previousDate: "2024-7-23",
      current: {
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 22,
    date: "2024-8-22",
    barValue: 3804.29,
    lineValue: 3643.13,
    tooltipData: {
      currentDate: "2024-8-22",
      previousDate: "2024-7-24",
      current: {
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 23,
    date: "2024-8-23",
    barValue: 4092.72,
    lineValue: 2455.66,
    tooltipData: {
      currentDate: "2024-8-23",
      previousDate: "2024-7-25",
      current: {
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 24,
    date: "2024-8-24",
    barValue: 3617.33,
    lineValue: 3815.25,
    tooltipData: {
      currentDate: "2024-8-24",
      previousDate: "2024-7-26",
      current: {
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 25,
    date: "2024-8-25",
    barValue: 4175.49,
    lineValue: 4482.68,
    tooltipData: {
      currentDate: "2024-8-25",
      previousDate: "2024-7-27",
      current: {
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 26,
    date: "2024-8-26",
    barValue: 2718.7,
    lineValue: 4457.98,
    tooltipData: {
      currentDate: "2024-8-26",
      previousDate: "2024-7-28",
      current: {
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57000000000001,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 27,
    date: "2024-8-27",
    barValue: 2485.69,
    lineValue: 3166.1,
    tooltipData: {
      currentDate: "2024-8-27",
      previousDate: "2024-7-29",
      current: {
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 28,
    date: "2024-8-28",
    barValue: 3342.88,
    lineValue: 2398.79,
    tooltipData: {
      currentDate: "2024-8-28",
      previousDate: "2024-7-30",
      current: {
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    day: 29,
    date: "2024-8-29",
    barValue: 3300.89,
    lineValue: 3568.35,
    tooltipData: {
      currentDate: "2024-8-29",
      previousDate: "2024-7-31",
      current: {
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const LineBarChartParameters = {
  keys: "Net Sales",
  indexBy: "day",
  margin: {
    top: "20",
    right: "10",
    bottom: "60",
    left: "60",
  },
  padding: 0.6,
  borderRadius: 6,
  enableLabel: false,
  axisBottom: {
    enableTicks: true,
    enableLabel: false,
    tickSize: 5,
    tickPadding: 5,
    tickValues: "every 4 day",
    legendOffset: 36,
    legendPosition: "middle",
    tickRotation: 45,
  },
  axisLeft: {
    tickSize: "5",
    tickPadding: "5",
    tickRotation: "0",
  },
  colors: "rgba(0, 155, 230, 1)",
  theme: "{customTheme}",
  labelTextColor: {
    from: "color",
    modifiers: "[[darker, 1.6]]",
  },
  layers: ["grid", "axes", "bars"],
  legends: {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: "false",
    translateX: "120",
    translateY: "0",
    itemsSpacing: "5",
    itemDirection: "left-to-right",
    itemWidth: "100",
    itemHeight: "20",
    itemOpacity: "0.85",
    symbolSize: "20",
  },
  view_by_period: ["Day", "Week", "Month", "Quarter", "Year"],
  meta_info: {
    format: "Currency",
  },
  compared_by_period: ["Previous Period", "Previous Year"],
  columns: [
    "Gross Sales",
    "New  Customers",
    "Repeat Customers",
    "Total Transactions",
    "Average Ticket Size",
    "Returns",
    "Discounts",
  ],
  focus_mode: true,
  tooltip_fields:
    "'Gross Sales','Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
  tooltip_format: "Currency,Number,Number,Currency,Currency,Currency",
  tooltip_data: [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
    {
      name: "Return Amount",
      format: "Currency",
      color: "",
    },
    {
      name: "Discount Amount",
      format: "Currency",
      color: "",
    },
  ],
  chart_colors: {
    line: {
      dark: "#fb651d",
      light: "#fb651d",
    },
    lineCircle: {
      dark: {
        fill: "#fb651d",
        stroke: "#ffffff",
      },
      light: {
        fill: "#ffffff",
        stroke: "#fb651d",
      },
    },
    bar: {
      dark: "#2647d9",
      light: "#2647d9",
    },
  },
  viewBy: "Day",
}

export const PopularProductList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      SNo: 1,
      Name: "Sukiyaki",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 2,
      Name: "Donburi",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 3,
      Name: "Okonomiyaki",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 4,
      Name: "Temura",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 5,
      Name: "Yakitari",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 6,
      Name: "Onigiri",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 7,
      Name: "Cheesebusrger Deluxe",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 8,
      Name: "Ceasar Salad",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 9,
      Name: "Apple Pie",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
    {
      SNo: 10,
      Name: "Guacamole and Chips",
      "Item Investement": 10,
      "Item Sold": 20,
      Profit: "$10",
      "Profit %": "100%",
    },
  ],
}

export const PopularProductParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'SNo', headerName: 'S.No', flex: 1, minWidth: 50, headerClassName: 'datagrid-header' },{ field: 'Name', headerName: 'Name', flex: 1, minWidth: 150, headerClassName: 'datagrid-header' },{ field: 'Item Investement', headerName: 'Item Investement', flex: 1, minWidth: 110, headerClassName: 'datagrid-header' },{ field: 'Item Sold', headerName: 'Item Sold', flex: 1, minWidth: 70, headerClassName: 'datagrid-header' },{ field: 'Profit', headerName: 'Profit', flex: 1, minWidth: 70, headerClassName: 'datagrid-header',renderCell: (params) => <div style={{ color: '#50DC89' }} },{ field: 'Profit %', headerName: 'Profit %', flex: 1, minWidth: 70, headerClassName: 'datagrid-header',renderCell: (params) => <div style={{ color: '#50DC89' }} }, ]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 1,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "Item Investement",
      headerName: "Item Investement",
      flex: 1,
      minWidth: 110,
      headerClassName: "datagrid-header",
    },
    {
      field: "Item Sold",
      headerName: "Item Sold",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
    },
    {
      field: "Profit",
      headerName: "Profit",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div style={{ color: "#50DC89" }}>{params.value}</div>
      ),
    },
    {
      field: "Profit %",
      headerName: "Profit %",
      flex: 1,
      minWidth: 70,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div style={{ color: "#50DC89" }}>{params.value}</div>
      ),
    },
  ],
}

export const TopEmployeeList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      EmpID: 14997,
      Name: "Theressa Webb",
      Role: "Chef",
      "No. of Leaves": 1,
      Salary: "$2500",
    },
    {
      EmpID: 12118,
      Name: "Ronalds Rechards",
      Role: "Manager",
      "No. of Leaves": 1,
      Salary: "$2500",
    },
    {
      EmpID: 14461,
      Name: "Bessie Cooper",
      Role: "Ass. MAnager",
      "No. of Leaves": 6,
      Salary: "$1500",
    },
    {
      EmpID: 11407,
      Name: "Brroklys Symons",
      Role: "Cassier",
      "No. of Leaves": 2,
      Salary: "$1200",
    },
    {
      EmpID: 14907,
      Name: "Savannah Nguyan",
      Role: "Catering MAnager",
      "No. of Leaves": 11,
      Salary: "$3200",
    },
    {
      EmpID: 13897,
      Name: "Floyad Mines",
      Role: "Event Planners",
      "No. of Leaves": 4,
      Salary: "$1200",
    },
    {
      EmpID: 10997,
      Name: "Eleonar Pena",
      Role: "Maintainance Worker",
      "No. of Leaves": 7,
      Salary: "$2900",
    },
    {
      EmpID: 12097,
      Name: "Jennys wison",
      Role: "Hoistess",
      "No. of Leaves": 9,
      Salary: "$2100",
    },
    {
      EmpID: 10697,
      Name: "Kathryn Murphy",
      Role: "Server",
      "No. of Leaves": 3,
      Salary: "$4500",
    },
    {
      EmpID: 15757,
      Name: "Jakeb jones",
      Role: "Saous chef",
      "No. of Leaves": 5,
      Salary: "$1500",
    },
  ],
}

export const TopEmployeeListParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'EmpID', headerName: 'Emp ID', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' } ,{ field: 'Name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Role', headerName: 'Role', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' }, { field: 'No. of Leaves', headerName: 'No. of Leaves', flex: 1, minWidth: 50, headerClassName: 'datagrid-header' },{ field: 'Salary', headerName: 'Salary', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' },]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "EmpID",
      headerName: "Emp ID",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Role",
      headerName: "Role",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "No. of Leaves",
      headerName: "No. of Leaves",
      flex: 1,
      minWidth: 50,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Salary",
      headerName: "Salary",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "#50DC89",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ],
}

export const SentimentReviewsList = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      Sentiment: "Negative",
      Category: "Food Quality",
      Comment: "The main course was overcooked and tasteless.",
    },
    {
      Sentiment: "Positive",
      Category: " Ambiance",
      Comment: "The restaurant had a cozy and inviting atmosphere.",
    },
    {
      Sentiment: "Negative",
      Category: "Ambiance",
      Comment: "Unpleasant odor in the dining area.",
    },
    {
      Sentiment: "Positive",
      Category: "Food Quality",
      Comment: "The food was absolutely delicious! ",
    },
    {
      Sentiment: "Positive",
      Category: "Service",
      Comment: "The staff was attentive and friendly.",
    },
    {
      Sentiment: "Negative",
      Category: "Value for Money",
      Comment: "Prices too high for the quality of the food.",
    },
    {
      Sentiment: "Negative",
      Category: "Communication",
      Comment: "Confusing menu descriptions; misleading.",
    },
  ],
}

export const SentimentReviewsParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[{ field: 'Sentiment', headerName: 'Sentiment', flex: 1, minWidth: 100, headerClassName: 'datagrid-header' } ,{ field: 'Category', headerName: 'Category', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Comment', headerName: 'Comment', flex: 1, minWidth: 100, headerClassName: 'datagrid-header'},]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "Sentiment",
      headerName: "Sentiment",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        let color = params.value === "Positive" ? "green" : "red"
        return <span style={{ color: color }}>{params.value}</span>
      },
    },
    {
      field: "Category",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Comment",
      headerName: "Comment",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
  ],
}

export const ListData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      test: true,
      ProductName: "Chicken Whole",
      "Total Sales Quantity": 1217.17,
    },
    { ProductName: "Chicken Leg Qrt Cut Up", "Total Sales Quantity": 665.66 },
    { ProductName: "Chicken BLS Thigh", "Total Sales Quantity": 612.81 },
    { ProductName: "Cilantro Bunch", "Total Sales Quantity": 565 },
    { ProductName: "Goat Cut Up", "Total Sales Quantity": 337.69 },
    { ProductName: "Curry Leaves (Pack)", "Total Sales Quantity": 312 },
    { ProductName: "Betel Leaf / Paan", "Total Sales Quantity": 303 },
    { ProductName: "Mint Leaves - (Bunch)", "Total Sales Quantity": 300 },
    { ProductName: "Chicken Breast Boti", "Total Sales Quantity": 245.22 },
    { ProductName: "Onion (Red) (lb)", "Total Sales Quantity": 219.32 },
  ],
}

export const ListParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            "[ { field: 'ProductName', headerName: 'Product Name', flex: 1, minWidth: 200, headerClassName: 'datagrid-header' }, { field: 'Total Sales Quantity', headerName: 'Total Sales Quantity', flex: 1, minWidth: 150, headerClassName: 'datagrid-header' } ]",
        },
      ],
    },
  ],
  columns: [
    {
      field: "ProductName",
      headerName: "Product Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Total Sales Quantity",
      headerName: "Total Sales Quantity",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}

export const DraftsData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      SNo: 1,
      campaign: "Beat the heat with our summer specials",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 2,
      campaign: "SocialMediaAds",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 3,
      campaign: "EventRegistration",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 4,
      campaign: "InfluencerCollab",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 5,
      campaign: "ContentMarketing",
      recipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 6,
      campaign: "ReferralProgram",
      recipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 7,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 8,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 9,
      campaign: "FreeTrialOffer",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 10,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 11,
      campaign: "FreeTrialOffer",
      recipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
    {
      SNo: 12,
      campaign: "FreeTrialOffer",
      recipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      savedDate: "10th Oct 2024",
    },
  ],
}
export const TargetedCustomersData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      SNo: 1,
      Customers: "Dianne Russell",
      EmailAddress: "diannerussell@example.com",
      PhoneNumber: "(307) 555-0133",
      CustomerRating: "4.3",
      AmtSpent: "$3,054.43",
    },
    {
      id: 2,
      SNo: 2,
      Customers: "Jerome Bell",
      EmailAddress: "jeromebell@example.com",
      PhoneNumber: "(205) 555-0100",
      CustomerRating: "4.3",
      AmtSpent: "$2,700.86",
    },
    {
      id: 3,
      SNo: 3,
      Customers: "Kristin Watson",
      EmailAddress: "kristinwatson@example.com",
      PhoneNumber: "(302) 555-0107",
      CustomerRating: "4.3",
      AmtSpent: "$2,543.64",
    },
    {
      id: 4,
      SNo: 4,
      Customers: "Ralph Edwards",
      EmailAddress: "ralphedwards@example.com",
      PhoneNumber: "(201) 555-0124",
      CustomerRating: "4.3",
      AmtSpent: "$2,285.54",
    },
    {
      id: 5,
      SNo: 5,
      Customers: "Wade Warren",
      EmailAddress: "wadewarren@example.com",
      PhoneNumber: "(480) 555-0103",
      CustomerRating: "4.3",
      AmtSpent: "$2,135.75",
    },
    {
      id: 6,
      SNo: 6,
      Customers: "Marketing",
      EmailAddress: "marketing@example.com",
      PhoneNumber: "(252) 555-0126",
      CustomerRating: "4.3",
      AmtSpent: "$1,956.76",
    },
    {
      id: 7,
      SNo: 7,
      Customers: "Robert Fox",
      EmailAddress: "robertfox@example.com",
      PhoneNumber: "(808) 555-0111",
      CustomerRating: "4.3",
      AmtSpent: "$1,906.76",
    },
    {
      id: 8,
      SNo: 8,
      Customers: "Jenny Wilson",
      EmailAddress: "jennywilson@example.com",
      PhoneNumber: "(505) 555-0125",
      CustomerRating: "4.3",
      AmtSpent: "$1,754.86",
    },
    {
      id: 9,
      SNo: 9,
      Customers: "Cody Fisher",
      EmailAddress: "codfisher@example.com",
      PhoneNumber: "(239) 555-0108",
      CustomerRating: "4.3",
      AmtSpent: "$1,643.87",
    },
    {
      id: 10,
      SNo: 10,
      Customers: "Eleanor Pena",
      EmailAddress: "eleanorpena@example.com",
      PhoneNumber: "(217) 555-0113",
      CustomerRating: "4.3",
      AmtSpent: "$1,765.87",
    },
    {
      id: 11,
      SNo: 11,
      Customers: "Kathryn Murohy",
      EmailAddress: "kathrynmurohy@example.com",
      PhoneNumber: "(312) 555-0114",
      CustomerRating: "4.3",
      AmtSpent: "$2,265.87",
    },
    {
      id: 12,
      SNo: 12,
      Customers: "Guy Hawkins",
      EmailAddress: "guyhawkins@example.com",
      PhoneNumber: "(414) 555-0115",
      CustomerRating: "4.3",
      AmtSpent: "$2,765.87",
    },
  ],
}

export const TargetedCustomersParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"S.No","headerName":"S.No","flex":0.5,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"Customers","headerName":"Customers","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Email Address","headerName":"Email Address","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Phone Number","headerName":"Phone Number","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Customer Rating","headerName":"Customer Rating","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Amt Spent","headerName":"Amt Spent","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "Customers",
      headerName: "Customers",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "EmailAddress",
      headerName: "Email Address",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "CustomerRating",
      headerName: "Customer Rating",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <Box display="flex" justifyContent="left">
          <Box display={"felx"} justifyContent={"center"}>
            {params.value} {""}
            <Star />
          </Box>
        </Box>
      ),
    },
    {
      field: "AmtSpent",
      headerName: "Amt Spent",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <Box display="flex" justifyContent="left" sx={{ color: "#50DC89" }}>
          {params.value}
        </Box>
      ),
    },
  ],
}
export const DraftsParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"S.No","headerName":"S.No","flex":0.5,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"Campaign","headerName":"Campaign","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Recipients Type","headerName":"Recipients Type","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"QR/Coupon Type","headerName":"QR/Coupon Type","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"QR/Coupon Code","headerName":"QR/Coupon Code","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Saved Date","headerName":"Saved Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 50,
      headerClassName: "datagrid-header",
    },
    {
      field: "campaign",
      headerName: "Campaign",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "recipientsType",
      headerName: "Recipients Type",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponType",
      headerName: "QR/Coupon Type",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponCode",
      headerName: "QR/Coupon Code",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "savedDate",
      headerName: "Saved Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}

export const PastCampaignData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      campaign: "HolidayPromo",
      RecipientsType: "Email",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 2,
      campaign: "SocialMediaAds",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 3,
      campaign: "EventRegistration",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 4,
      campaign: "PromoDiscount",
      RecipientsType: "Email",
      QRCouponType: "Discount",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 5,
      campaign: "FlyerCampaign",
      RecipientsType: "Email",
      QRCouponType: "Flyer",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 6,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 7,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 8,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 9,
      campaign: "FreeTrialOffer",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 10,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 11,
      campaign: "FreeTrialOffer",
      RecipientsType: "Email",
      QRCouponType: "QR",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Not Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
    {
      id: 12,
      campaign: "FreeTrialOffer",
      RecipientsType: "SMS",
      QRCouponType: "Coupon",
      QRCouponCode: "SUMMER200",
      attendedMembers: 50,
      RespondStatus: "Responded",
      createdDate: "10th Oct 2024",
      AmountSpent: "$500",
    },
  ],
}

export const PastCampaignParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"id","headerName":"S.No","flex":0.5, "minWidth":50,"headerClassName":"datagrid-header"},{"field":"campaign","headerName":"campaign","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"RecipientsType","headerName":"Recipients Type","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"QRCouponType","headerName":"QR/Coupon Type","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"QRCouponCode","headerName":"QR/Coupon Code","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"attendedMembers","headerName":"Attended Members","flex":1,"minWidth":50,"headerClassName":"datagrid-header"},{"field":"RespondStatus","headerName":"Respond Status","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"createdDate","headerName":"created Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"AmountSpent","headerName":"Amount Spent","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],

  columns: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "campaign",
      headerName: "campaign",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "RecipientsType",
      headerName: "Recipients Type",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponType",
      headerName: "QR/Coupon Type",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "QRCouponCode",
      headerName: "QR/Coupon Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "attendedMembers",
      headerName: "Attended Members",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "RespondStatus",
      headerName: "Respond Status",
      // flex: 1,
      minWidth: 175,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        const styles = {
          display: "flex",
          padding: "8px",
          marginTop: "20px",
          lineHeight: "1",
          justifyContent: "center",
          alignItems: "center",
          gap: "1px",
          borderRadius: "10px",
          border:
            params.value === "Responded"
              ? "1px solid rgba(80, 205, 137, 0.50)"
              : "1px solid rgba(238, 0, 12, 0.40)",
          background:
            params.value === "Responded"
              ? "rgba(80, 205, 137, 0.30)"
              : "rgba(238, 0, 12, 0.20)",
          color: params.value === "Responded" ? "#50cd89" : "#ee000c",
        }
        return <div style={styles}>{params.value}</div>
      },
    },
    {
      field: "createdDate",
      headerName: "created Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "AmountSpent",
      headerName: "Amount Spent",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        const styles = {
          color: "#50cd89",
        }
        return <div style={styles}>{params.value}</div>
      },
    },
  ],
}

export const HeatmapChartData = [
  {
    id: "Sunday",
    data: [
      {
        x: "9AM",
        y: 25,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 446.66,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.5,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 382.81,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.15,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.76,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 48,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 949.7,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.97,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1168.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 71,
        previousY: 71,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1963.86,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.66,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2040.91,
              format: "Currency",
            },
            Customers: {
              value: 57,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.75,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 91,
        previousY: 66,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2617.65,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.77,
              format: "Currency",
            },
            "Return Amount": {
              value: 2,
              format: "Currency",
            },
            "Discount Amount": {
              value: 27.45,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1637.54,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 62,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1773.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1570.15,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.67,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1386.95,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2274.08,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 44.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 156.48,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 44,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.48,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.12,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1416.17,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 36.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 37,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 710.38,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1026.23,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 63,
        previousY: 53,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1216.48,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 25,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1288.87,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 13.53,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 46,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.28,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.4,
              format: "Currency",
            },
            "Return Amount": {
              value: 19,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1404.09,
              format: "Currency",
            },
            Customers: {
              value: 52,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.97,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 59,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1278.77,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 39.35,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1119.02,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.9,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 61,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1457.24,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.89,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.16,
              format: "Currency",
            },
            "Discount Amount": {
              value: 31.94,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1021.3,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.84,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.45,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 7,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 121.29,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Monday",
    data: [
      {
        x: "9AM",
        y: 5,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 101.02,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 18,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 413.76,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 376.06,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 28,
        previousY: 26,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.71,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.35,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 973.4,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.44,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 656.77,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.77,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 484.7,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 28,
        previousY: 29,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 752.51,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 674.67,
              format: "Currency",
            },
            Customers: {
              value: 20,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.77,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 24,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1176.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.34,
              format: "Currency",
            },
            "Discount Amount": {
              value: 18.11,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 701.74,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.24,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 28,
        previousY: 32,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1130.77,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 520.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1043.14,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 389.77,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.73,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 37,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1079.03,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.7,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1186.84,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.08,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.68,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 46,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1188.11,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1222.7,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 61,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1853.49,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.37,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 847.77,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.2,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 55,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1125.64,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.87,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1219.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 9,
        previousY: 4,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 366.37,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 40.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 84.18,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Tuesday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 9,
        previousY: 7,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 157.81,
              format: "Currency",
            },
            Customers: {
              value: 7,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 85.25,
              format: "Currency",
            },
            Customers: {
              value: 4,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 12.18,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 19,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 946.71,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.58,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.84,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 619.41,
              format: "Currency",
            },
            Customers: {
              value: 16,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.6,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.41,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 890.4,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 32,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 970.13,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.32,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.63,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1069.31,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.73,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.08,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 33,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 980.4,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.71,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.54,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.26,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 388.62,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.8,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 27,
        previousY: 25,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 736.13,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.13,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 672.07,
              format: "Currency",
            },
            Customers: {
              value: 19,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 26,
        previousY: 40,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 617.17,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1099.29,
              format: "Currency",
            },
            Customers: {
              value: 25,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 40,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 982.33,
              format: "Currency",
            },
            Customers: {
              value: 32,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 827.98,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.79,
              format: "Currency",
            },
            "Return Amount": {
              value: 32.58,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 62,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1778.5,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.69,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.19,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1304.23,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.64,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 63,
        previousY: 34,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1695.61,
              format: "Currency",
            },
            Customers: {
              value: 51,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.91,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 14.78,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 11.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 41,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 875.63,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.33,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1058.21,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 5,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 232.68,
              format: "Currency",
            },
            Customers: {
              value: 5,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.54,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 7.47,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 7.47,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Wednesday",
    data: [
      {
        x: "9AM",
        y: 2,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 12.52,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 6.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 15.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 17,
        previousY: 16,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 274.77,
              format: "Currency",
            },
            Customers: {
              value: 14,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 16.16,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 443.19,
              format: "Currency",
            },
            Customers: {
              value: 15,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 32,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 763.56,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1733.73,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 45.62,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 166.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 36,
        previousY: 39,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2335.66,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 64.88,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.1,
              format: "Currency",
            },
            "Discount Amount": {
              value: 222.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 913.12,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 47,
        previousY: 47,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 3941.84,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 83.87,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 480.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1037.14,
              format: "Currency",
            },
            Customers: {
              value: 33,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 52,
        previousY: 33,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1049.7,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.19,
              format: "Currency",
            },
            "Return Amount": {
              value: 16.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 561.52,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.02,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.92,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 31,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.38,
              format: "Currency",
            },
            Customers: {
              value: 22,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1071.58,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 21.95,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 41,
        previousY: 36,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2096.79,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 51.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 225.86,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 3175.25,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 88.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 392.87,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 48,
        previousY: 58,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1178.81,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.33,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1502.4,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.06,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 67,
        previousY: 79,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1473.83,
              format: "Currency",
            },
            Customers: {
              value: 53,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 13.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1891.12,
              format: "Currency",
            },
            Customers: {
              value: 56,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 23.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.99,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 54,
        previousY: 63,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1387.99,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 10.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.48,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1612.32,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 20.88,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 56,
        previousY: 65,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1123.73,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 18,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.73,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1234.01,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.6,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 92.24,
              format: "Currency",
            },
            Customers: {
              value: 8,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 8.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.91,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 30.18,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Thursday",
    data: [
      {
        x: "9AM",
        y: 1,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 43.98,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 43.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 35,
        previousY: 18,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 725.21,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.72,
              format: "Currency",
            },
            "Return Amount": {
              value: 5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.96,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 527.27,
              format: "Currency",
            },
            Customers: {
              value: 17,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.29,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.15,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 44,
        previousY: 35,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1011.15,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 787.88,
              format: "Currency",
            },
            Customers: {
              value: 27,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.51,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.55,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 41,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1455.48,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1243.98,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.09,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.42,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 42,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1766.87,
              format: "Currency",
            },
            Customers: {
              value: 28,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 42.07,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 26.43,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2063.6,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.9,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 138.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 49,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1377.13,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.38,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1328.67,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.75,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 39,
        previousY: 41,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1544.7,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.79,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2611.77,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 63.7,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 305.65,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 51,
        previousY: 45,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1708.63,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 60.4,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 946.2,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.03,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.59,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 70,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1428.37,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.41,
              format: "Currency",
            },
            "Return Amount": {
              value: 44,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.75,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1346.7,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.49,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.35,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 81,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2109.23,
              format: "Currency",
            },
            Customers: {
              value: 62,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 50.08,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.26,
              format: "Currency",
            },
            Customers: {
              value: 37,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.1,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 88,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1947.88,
              format: "Currency",
            },
            Customers: {
              value: 64,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.13,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.99,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1672.93,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.42,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.43,
              format: "Currency",
            },
            "Discount Amount": {
              value: 55.14,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 105,
        previousY: 55,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 2095.95,
              format: "Currency",
            },
            Customers: {
              value: 75,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 22.44,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 879.22,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.99,
              format: "Currency",
            },
            "Return Amount": {
              value: 9.42,
              format: "Currency",
            },
            "Discount Amount": {
              value: 5.99,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 3,
        previousY: 2,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 29.48,
              format: "Currency",
            },
            Customers: {
              value: 3,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 9.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 20.29,
              format: "Currency",
            },
            Customers: {
              value: 2,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 10.14,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.65,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Friday",
    data: [
      {
        x: "9AM",
        y: 0,
        previousY: 0,
        tooltipData: {
          current: {},
          previous: {},
        },
      },
      {
        x: "10AM",
        y: 33,
        previousY: 22,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 597.97,
              format: "Currency",
            },
            Customers: {
              value: 24,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.12,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.1,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 310.38,
              format: "Currency",
            },
            Customers: {
              value: 21,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 14.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 35,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 896.96,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.31,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1376.01,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.52,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 35.93,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 46,
        previousY: 44,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 921.77,
              format: "Currency",
            },
            Customers: {
              value: 36,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 20.04,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1421.68,
              format: "Currency",
            },
            Customers: {
              value: 38,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 32.31,
              format: "Currency",
            },
            "Return Amount": {
              value: 18.9,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.05,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 48,
        previousY: 38,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1417.32,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.53,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 7.03,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1163.21,
              format: "Currency",
            },
            Customers: {
              value: 31,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.61,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.43,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 43,
        previousY: 56,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1032.4,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.01,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.34,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1853.27,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.09,
              format: "Currency",
            },
            "Return Amount": {
              value: 29.49,
              format: "Currency",
            },
            "Discount Amount": {
              value: 33.7,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 37,
        previousY: 50,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1168.92,
              format: "Currency",
            },
            Customers: {
              value: 26,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.59,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.16,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1449.25,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 0.63,
              format: "Currency",
            },
            "Discount Amount": {
              value: 76.63,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 54,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1472.99,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.28,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 24.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1709.78,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 38.38,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 58,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1246.04,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.48,
              format: "Currency",
            },
            "Return Amount": {
              value: 1.39,
              format: "Currency",
            },
            "Discount Amount": {
              value: 6.55,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1802.9,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.63,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.84,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 61,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1600.25,
              format: "Currency",
            },
            Customers: {
              value: 44,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.23,
              format: "Currency",
            },
            "Return Amount": {
              value: 24.8,
              format: "Currency",
            },
            "Discount Amount": {
              value: 34.18,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1285.98,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.56,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 55,
        previousY: 68,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1725.81,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.14,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1064.07,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 15.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.5,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 63,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1839.42,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.2,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 52.83,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1249.3,
              format: "Currency",
            },
            Customers: {
              value: 45,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 21.92,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 4.52,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 6,
        previousY: 1,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 118.06,
              format: "Currency",
            },
            Customers: {
              value: 6,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 19.68,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.9,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 4.74,
              format: "Currency",
            },
            Customers: {
              value: 1,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 4.74,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
  {
    id: "Saturday",
    data: [
      {
        x: "9AM",
        y: 22,
        previousY: 15,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 555.8,
              format: "Currency",
            },
            Customers: {
              value: 18,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 25.26,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 702.21,
              format: "Currency",
            },
            Customers: {
              value: 13,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 46.81,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.86,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "10AM",
        y: 36,
        previousY: 27,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 947.98,
              format: "Currency",
            },
            Customers: {
              value: 34,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.33,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.75,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 970.1,
              format: "Currency",
            },
            Customers: {
              value: 23,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.93,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 2.58,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "11AM",
        y: 56,
        previousY: 54,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1574.19,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1818.14,
              format: "Currency",
            },
            Customers: {
              value: 42,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 33.67,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 10.03,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "12PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1919.24,
              format: "Currency",
            },
            Customers: {
              value: 50,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 30.96,
              format: "Currency",
            },
            "Return Amount": {
              value: 6.55,
              format: "Currency",
            },
            "Discount Amount": {
              value: 23.67,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1260.28,
              format: "Currency",
            },
            Customers: {
              value: 41,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 22.11,
              format: "Currency",
            },
            "Return Amount": {
              value: 5.95,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.16,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "1PM",
        y: 39,
        previousY: 67,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1084.25,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 27.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.72,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 2383.16,
              format: "Currency",
            },
            Customers: {
              value: 55,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 35.57,
              format: "Currency",
            },
            "Return Amount": {
              value: 4,
              format: "Currency",
            },
            "Discount Amount": {
              value: 162.61,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "2PM",
        y: 40,
        previousY: 59,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1594.25,
              format: "Currency",
            },
            Customers: {
              value: 35,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 39.86,
              format: "Currency",
            },
            "Return Amount": {
              value: 2.25,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.52,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1851.9,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.39,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.06,
              format: "Currency",
            },
            "Discount Amount": {
              value: 11.78,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "3PM",
        y: 35,
        previousY: 43,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1027.46,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.36,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 1.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1625.48,
              format: "Currency",
            },
            Customers: {
              value: 30,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.8,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 3.3,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "4PM",
        y: 55,
        previousY: 51,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1740.51,
              format: "Currency",
            },
            Customers: {
              value: 48,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 31.65,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 9.24,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1251.98,
              format: "Currency",
            },
            Customers: {
              value: 39,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.55,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 28.62,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "5PM",
        y: 57,
        previousY: 42,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1624.56,
              format: "Currency",
            },
            Customers: {
              value: 46,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 28.5,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 16.56,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 580.64,
              format: "Currency",
            },
            Customers: {
              value: 29,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 13.82,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.18,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "6PM",
        y: 68,
        previousY: 49,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1786.36,
              format: "Currency",
            },
            Customers: {
              value: 49,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.27,
              format: "Currency",
            },
            "Return Amount": {
              value: 15,
              format: "Currency",
            },
            "Discount Amount": {
              value: 32.05,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1443.66,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 29.46,
              format: "Currency",
            },
            "Return Amount": {
              value: 3.7,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "7PM",
        y: 68,
        previousY: 60,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1831.97,
              format: "Currency",
            },
            Customers: {
              value: 47,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 26.94,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.68,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1498.97,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 24.98,
              format: "Currency",
            },
            "Return Amount": {
              value: 4.3,
              format: "Currency",
            },
            "Discount Amount": {
              value: 12.53,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "8PM",
        y: 62,
        previousY: 57,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 1105.69,
              format: "Currency",
            },
            Customers: {
              value: 43,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 17.83,
              format: "Currency",
            },
            "Return Amount": {
              value: 8.5,
              format: "Currency",
            },
            "Discount Amount": {
              value: 17.27,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 1050.71,
              format: "Currency",
            },
            Customers: {
              value: 40,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 18.43,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 8.69,
              format: "Currency",
            },
          },
        },
      },
      {
        x: "9PM",
        y: 11,
        previousY: 0,
        tooltipData: {
          current: {
            "Gross Sales": {
              value: 411.13,
              format: "Currency",
            },
            Customers: {
              value: 10,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 37.38,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 15.32,
              format: "Currency",
            },
          },
          previous: {
            "Gross Sales": {
              value: 0,
              format: "Currency",
            },
            Customers: {
              value: 0,
              format: "Number",
            },
            Transactions: {
              value: 0,
              format: "Number",
            },
            "Average Ticket": {
              value: 0,
              format: "Currency",
            },
            "Return Amount": {
              value: 0,
              format: "Currency",
            },
            "Discount Amount": {
              value: 0,
              format: "Currency",
            },
          },
        },
      },
    ],
  },
]

export const HeatmapChartParameters = {
  meta_info: {
    format: "Currency",
  },
  axisRight: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Day of Week",
    legendPosition: "middle",
    legendOffset: 80,
    truncateTickAt: 0,
  },
  axisTop: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Time of Day",
    legendOffset: -40,
    legendPosition: "middle",
    truncateTickAt: 10,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Day of Week",
    legendPosition: "middle",
    legendOffset: -80,
    truncateTickAt: 0,
  },
  theme: "customTheme",
  colors: {
    type: "diverging",
    scheme: "blues",
    divergeAt: 0.5,
  },
  emptyColor: "#555555",
  legends: [
    {
      anchor: "bottom",
      translateX: 0,
      translateY: 30,
      length: 400,
      thickness: 8,
      direction: "row",
      tickPosition: "after",
      tickSize: 3,
      tickSpacing: 4,
      tickOverlap: false,
      tickFormat: ">-.2s",
      title: "Average Sale($)",
      titleAlign: "start",
      titleOffset: 4,
    },
  ],
  margin: {
    top: 60,
    right: 90,
    bottom: 60,
    left: 90,
  },
  tooltip_fields:
    "'Gross Sales','Customers','Transactions','Average Ticket','Return Amount','Discount Amount'",
  performerFlag: {
    icon: "false",
    legend: "true",
  },
}

export const ProgressBarData = [
  {
    title: "GB Foods",
    defaultValue: 220730,
    tooltipData: {
      current: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220730,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4162,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 220730,
          format: "Currency",
        },
      },
    },
  },
]

export const ScatterPlotChartData = [
  {
    id: "Min",
    data: [
      { x: 99, y: 66 },
      { x: 51, y: 39 },
      { x: 29, y: 88 },
      { x: 20, y: 37 },
      { x: 69, y: 7 },
      { x: 80, y: 60 },
      { x: 97, y: 65 },
      { x: 56, y: 38 },
      { x: 26, y: 89 },
      { x: 25, y: 38 },
      { x: 63, y: 75 },
      { x: 92, y: 92 },
      { x: 92, y: 96 },
      { x: 54, y: 89 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
      { x: 69, y: 77 },
      { x: 91, y: 68 },
    ],
  },
  {
    id: "Max",
    data: [
      { x: 61, y: 94 },
      { x: 12, y: 10 },
      { x: 33, y: 68 },
      { x: 11, y: 85 },
      { x: 38, y: 42 },
      { x: 21, y: 78 },
      { x: 28, y: 37 },
      { x: 63, y: 9 },
      { x: 87, y: 62 },
      { x: 92, y: 67 },
      { x: 55, y: 33 },
      { x: 27, y: 86 },
      { x: 28, y: 37 },
    ],
  },
]

export const ScatterPlotChartParameters = {
  margin: { top: 30, right: 30, bottom: 70, left: 10 },
  xScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  yScale: {
    type: "linear",
    min: 0,
    max: 100,
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: [0, 15, 45, 75],
    tickFormat: "formatXAxisLabel",
    legendPosition: "middle",
    legendOffset: 46,
    tickSize: 8,
    tickPadding: 10,
    tickRotation: 0,
  },
  gridXValues: [15, 45, 75],
  gridYValues: [50],
  nodeSize: 8,
  layers: ["grid", "axes", "nodes", "CustomGridBackground"],
  colors: (node) =>
    node.serieId === "Min" ? "rgba(92, 41, 199, 1)" : "rgba(240, 134, 76, 1)",
}
export const StreamChartData = [
  {
    "Theressa Webb": 176,
    "Ronalds Rechards": 132,
    "Bessie Cooper": 109,
    "Brroklys Symons": 119,
    "Savannah Nguyan": 157,
    "Floyad Mines": 80,
  },
  {
    "Theressa Webb": 155,
    "Ronalds Rechards": 56,
    "Bessie Cooper": 91,
    "Brroklys Symons": 45,
    "Savannah Nguyan": 59,
    "Floyad Mines": 147,
  },
  {
    "Theressa Webb": 116,
    "Ronalds Rechards": 114,
    "Bessie Cooper": 176,
    "Brroklys Symons": 12,
    "Savannah Nguyan": 53,
    "Floyad Mines": 127,
  },
  {
    "Theressa Webb": 135,
    "Ronalds Rechards": 196,
    "Bessie Cooper": 131,
    "Brroklys Symons": 125,
    "Savannah Nguyan": 107,
    "Floyad Mines": 57,
  },
  {
    "Theressa Webb": 66,
    "Ronalds Rechards": 62,
    "Bessie Cooper": 153,
    "Brroklys Symons": 135,
    "Savannah Nguyan": 158,
    "Floyad Mines": 140,
  },
  {
    "Theressa Webb": 60,
    "Ronalds Rechards": 113,
    "Bessie Cooper": 140,
    "Brroklys Symons": 88,
    "Savannah Nguyan": 167,
    "Floyad Mines": 111,
  },
  {
    "Theressa Webb": 0,
    "Ronalds Rechards": 107,
    "Bessie Cooper": 142,
    "Brroklys Symons": 78,
    "Savannah Nguyan": 162,
    "Floyad Mines": 199,
  },
  {
    "Theressa Webb": 161,
    "Ronalds Rechards": 157,
    "Bessie Cooper": 101,
    "Brroklys Symons": 124,
    "Savannah Nguyan": 118,
    "Floyad Mines": 158,
  },
  {
    "Theressa Webb": 128,
    "Ronalds Rechards": 147,
    "Bessie Cooper": 60,
    "Brroklys Symons": 165,
    "Savannah Nguyan": 71,
    "Floyad Mines": 89,
  },
]

export const StreamChartParameters = {
  keys: [
    "Theressa Webb",
    "Ronalds Rechards",
    "Bessie Cooper",
    "Brroklys Symons",
    "Savannah Nguyan",
    "Floyad Mines",
  ],
  margin: {
    top: 50,
    right: 30,
    bottom: 50,
    left: 30,
  },
  axisTop: "",
  axisRight: "",
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 36,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: -40,
    truncateTickAt: 0,
    tickTextColor: "#ffffff",
  },
  enableGridX: "true",
  enableGridY: "false",
  offsetType: "silhouette",
  colors: {
    scheme: "set3",
  },
  fillOpacity: [0.85],
  borderColor: {
    theme: "background",
  },
  defs: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "#2c998f",
      size: 4,
      padding: 2,
      stagger: true,
    },
    {
      id: "squares",
      type: "patternSquares",
      background: "inherit",
      color: "#e4c912",
      size: 6,
      padding: 2,
      stagger: true,
    },
  ],
  fill: [
    {
      match: {
        id: "Eggs",
      },
      id: "dots",
    },
    {
      match: {
        id: "Carrots",
      },
      id: "squares",
    },
  ],
  dotSize: [8],
  dotColor: {
    from: "color",
  },
  dotBorderWidth: [2],
  dotBorderColor: {
    from: "color",
    modifiers: [["darker", 0.7]],
  },
  legends: [
    {
      anchor: "bottom",
      direction: "row",
      translateY: 50,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#fff",
          },
        },
      ],
    },
  ],
}
export const HorizontalBarChartData = [
  {
    idMonth: "08-01-2024",
    orderCount: -122,
    tooltipData: {
      current: {
        "Order Count": {
          value: 122,
          format: "Number",
        },
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 140,
          format: "Number",
        },
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-02-2024",
    orderCount: -119,
    tooltipData: {
      current: {
        "Order Count": {
          value: 119,
          format: "Number",
        },
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-03-2024",
    orderCount: -164,
    tooltipData: {
      current: {
        "Order Count": {
          value: 164,
          format: "Number",
        },
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 138,
          format: "Number",
        },
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-04-2024",
    orderCount: -172,
    tooltipData: {
      current: {
        "Order Count": {
          value: 172,
          format: "Number",
        },
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 148,
          format: "Number",
        },
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-05-2024",
    orderCount: -113,
    tooltipData: {
      current: {
        "Order Count": {
          value: 113,
          format: "Number",
        },
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 180,
          format: "Number",
        },
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-06-2024",
    orderCount: -98,
    tooltipData: {
      current: {
        "Order Count": {
          value: 98,
          format: "Number",
        },
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-07-2024",
    orderCount: -127,
    tooltipData: {
      current: {
        "Order Count": {
          value: 127,
          format: "Number",
        },
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-08-2024",
    orderCount: -120,
    tooltipData: {
      current: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 24,
          format: "Number",
        },
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-09-2024",
    orderCount: -136,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-10-2024",
    orderCount: -167,
    tooltipData: {
      current: {
        "Order Count": {
          value: 167,
          format: "Number",
        },
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-11-2024",
    orderCount: -161,
    tooltipData: {
      current: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-12-2024",
    orderCount: -118,
    tooltipData: {
      current: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 157,
          format: "Number",
        },
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-13-2024",
    orderCount: -115,
    tooltipData: {
      current: {
        "Order Count": {
          value: 115,
          format: "Number",
        },
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-14-2024",
    orderCount: -136,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-15-2024",
    orderCount: -145,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-16-2024",
    orderCount: -131,
    tooltipData: {
      current: {
        "Order Count": {
          value: 131,
          format: "Number",
        },
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-17-2024",
    orderCount: -135,
    tooltipData: {
      current: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-18-2024",
    orderCount: -174,
    tooltipData: {
      current: {
        "Order Count": {
          value: 174,
          format: "Number",
        },
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 137,
          format: "Number",
        },
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-19-2024",
    orderCount: -108,
    tooltipData: {
      current: {
        "Order Count": {
          value: 108,
          format: "Number",
        },
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-20-2024",
    orderCount: -93,
    tooltipData: {
      current: {
        "Order Count": {
          value: 93,
          format: "Number",
        },
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 112,
          format: "Number",
        },
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-21-2024",
    orderCount: -130,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-22-2024",
    orderCount: -132,
    tooltipData: {
      current: {
        "Order Count": {
          value: 132,
          format: "Number",
        },
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-23-2024",
    orderCount: -153,
    tooltipData: {
      current: {
        "Order Count": {
          value: 153,
          format: "Number",
        },
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 99,
          format: "Number",
        },
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-24-2024",
    orderCount: -145,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 143,
          format: "Number",
        },
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-25-2024",
    orderCount: -159,
    tooltipData: {
      current: {
        "Order Count": {
          value: 159,
          format: "Number",
        },
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 152,
          format: "Number",
        },
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-26-2024",
    orderCount: -103,
    tooltipData: {
      current: {
        "Order Count": {
          value: 103,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-27-2024",
    orderCount: -100,
    tooltipData: {
      current: {
        "Order Count": {
          value: 100,
          format: "Number",
        },
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 106,
          format: "Number",
        },
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-28-2024",
    orderCount: -101,
    tooltipData: {
      current: {
        "Order Count": {
          value: 101,
          format: "Number",
        },
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 107,
          format: "Number",
        },
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "08-29-2024",
    orderCount: -130,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 109,
          format: "Number",
        },
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const HorizontalBarChartsecondData = [
  {
    idMonth: "2024-8-1",
    grossSale: 3283.68,
    tooltipData: {
      current: {
        "Order Count": {
          value: 122,
          format: "Number",
        },
        "Gross Sales": {
          value: 3283.68,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 122,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 44,
          format: "Currency",
        },
        "Discount Amount": {
          value: 72.45,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 140,
          format: "Number",
        },
        "Gross Sales": {
          value: 3740.12,
          format: "Currency",
        },
        Customers: {
          value: 111,
          format: "Number",
        },
        Transactions: {
          value: 140,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.72,
          format: "Currency",
        },
        "Return Amount": {
          value: 3,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-2",
    grossSale: 3321.99,
    tooltipData: {
      current: {
        "Order Count": {
          value: 119,
          format: "Number",
        },
        "Gross Sales": {
          value: 3321.99,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 119,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.92,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.41,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 4188.27,
          format: "Currency",
        },
        Customers: {
          value: 99,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 31.02,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.43,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-3",
    grossSale: 4900.36,
    tooltipData: {
      current: {
        "Order Count": {
          value: 164,
          format: "Number",
        },
        "Gross Sales": {
          value: 4900.36,
          format: "Currency",
        },
        Customers: {
          value: 131,
          format: "Number",
        },
        Transactions: {
          value: 164,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.88,
          format: "Currency",
        },
        "Return Amount": {
          value: 23.5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.28,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 138,
          format: "Number",
        },
        "Gross Sales": {
          value: 3676.93,
          format: "Currency",
        },
        Customers: {
          value: 94,
          format: "Number",
        },
        Transactions: {
          value: 138,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.64,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-4",
    grossSale: 3773.12,
    tooltipData: {
      current: {
        "Order Count": {
          value: 172,
          format: "Number",
        },
        "Gross Sales": {
          value: 3773.12,
          format: "Currency",
        },
        Customers: {
          value: 127,
          format: "Number",
        },
        Transactions: {
          value: 172,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.94,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 23.14,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 148,
          format: "Number",
        },
        "Gross Sales": {
          value: 3900.37,
          format: "Currency",
        },
        Customers: {
          value: 112,
          format: "Number",
        },
        Transactions: {
          value: 148,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.35,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.06,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-5",
    grossSale: 2973.24,
    tooltipData: {
      current: {
        "Order Count": {
          value: 113,
          format: "Number",
        },
        "Gross Sales": {
          value: 2973.24,
          format: "Currency",
        },
        Customers: {
          value: 77,
          format: "Number",
        },
        Transactions: {
          value: 113,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 74.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 180,
          format: "Number",
        },
        "Gross Sales": {
          value: 4715.62,
          format: "Currency",
        },
        Customers: {
          value: 149,
          format: "Number",
        },
        Transactions: {
          value: 180,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.2,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.03,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-6",
    grossSale: 2325.96,
    tooltipData: {
      current: {
        "Order Count": {
          value: 98,
          format: "Number",
        },
        "Gross Sales": {
          value: 2325.96,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 98,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 12.37,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-7",
    grossSale: 3768.57,
    tooltipData: {
      current: {
        "Order Count": {
          value: 127,
          format: "Number",
        },
        "Gross Sales": {
          value: 3768.57,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 127,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.67,
          format: "Currency",
        },
        "Return Amount": {
          value: 7.05,
          format: "Currency",
        },
        "Discount Amount": {
          value: 233.82,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 0,
          format: "Number",
        },
        "Gross Sales": {
          value: 0,
          format: "Currency",
        },
        Customers: {
          value: 0,
          format: "Number",
        },
        Transactions: {
          value: 0,
          format: "Number",
        },
        "Average Ticket": {
          value: null,
          format: "Currency",
        },
        "Return Amount": {
          value: null,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-8",
    grossSale: 3047.64,
    tooltipData: {
      current: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 3047.64,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 24,
          format: "Number",
        },
        "Gross Sales": {
          value: 333.37,
          format: "Currency",
        },
        Customers: {
          value: 19,
          format: "Number",
        },
        Transactions: {
          value: 24,
          format: "Number",
        },
        "Average Ticket": {
          value: 13.89,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-9",
    grossSale: 3449.76,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 3449.76,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.37,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.39,
          format: "Currency",
        },
        "Discount Amount": {
          value: 78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 4238.91,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.19,
          format: "Currency",
        },
        "Return Amount": {
          value: 14.86,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-10",
    grossSale: 5123.1,
    tooltipData: {
      current: {
        "Order Count": {
          value: 167,
          format: "Number",
        },
        "Gross Sales": {
          value: 5123.1,
          format: "Currency",
        },
        Customers: {
          value: 130,
          format: "Number",
        },
        Transactions: {
          value: 167,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 6.55,
          format: "Currency",
        },
        "Discount Amount": {
          value: 44.26,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3275.42,
          format: "Currency",
        },
        Customers: {
          value: 89,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 47.49,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-11",
    grossSale: 3534.91,
    tooltipData: {
      current: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3534.91,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.16,
          format: "Currency",
        },
        "Discount Amount": {
          value: 64.64,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 4077.01,
          format: "Currency",
        },
        Customers: {
          value: 109,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.31,
          format: "Currency",
        },
        "Return Amount": {
          value: 4,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-12",
    grossSale: 3417.72,
    tooltipData: {
      current: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3417.72,
          format: "Currency",
        },
        Customers: {
          value: 81,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.96,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.87,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.78,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 157,
          format: "Number",
        },
        "Gross Sales": {
          value: 3412.24,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 157,
          format: "Number",
        },
        "Average Ticket": {
          value: 21.73,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-13",
    grossSale: 3443.92,
    tooltipData: {
      current: {
        "Order Count": {
          value: 115,
          format: "Number",
        },
        "Gross Sales": {
          value: 3443.92,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 115,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 10.93,
          format: "Currency",
        },
        "Discount Amount": {
          value: 2.58,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.32,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: null,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-14",
    grossSale: 5222.11,
    tooltipData: {
      current: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 5222.11,
          format: "Currency",
        },
        Customers: {
          value: 98,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 38.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 21.09,
          format: "Currency",
        },
        "Discount Amount": {
          value: 321.6,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 118,
          format: "Number",
        },
        "Gross Sales": {
          value: 3471.69,
          format: "Currency",
        },
        Customers: {
          value: 91,
          format: "Number",
        },
        Transactions: {
          value: 118,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.04,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-15",
    grossSale: 3807.56,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3807.56,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 47.25,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 111,
          format: "Number",
        },
        "Gross Sales": {
          value: 3920.59,
          format: "Currency",
        },
        Customers: {
          value: 84,
          format: "Number",
        },
        Transactions: {
          value: 111,
          format: "Number",
        },
        "Average Ticket": {
          value: 35.32,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 319.33,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-16",
    grossSale: 3173.44,
    tooltipData: {
      current: {
        "Order Count": {
          value: 131,
          format: "Number",
        },
        "Gross Sales": {
          value: 3173.44,
          format: "Currency",
        },
        Customers: {
          value: 85,
          format: "Number",
        },
        Transactions: {
          value: 131,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 25.91,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3796.93,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.21,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.2,
          format: "Currency",
        },
        "Discount Amount": {
          value: 35.08,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-17",
    grossSale: 3562.6,
    tooltipData: {
      current: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3562.6,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 91.12,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 144,
          format: "Number",
        },
        "Gross Sales": {
          value: 3922.97,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 144,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.24,
          format: "Currency",
        },
        "Return Amount": {
          value: 0.63,
          format: "Currency",
        },
        "Discount Amount": {
          value: 96.71,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-18",
    grossSale: 4795.91,
    tooltipData: {
      current: {
        "Order Count": {
          value: 174,
          format: "Number",
        },
        "Gross Sales": {
          value: 4795.91,
          format: "Currency",
        },
        Customers: {
          value: 133,
          format: "Number",
        },
        Transactions: {
          value: 174,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.56,
          format: "Currency",
        },
        "Return Amount": {
          value: 11,
          format: "Currency",
        },
        "Discount Amount": {
          value: 103.35,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 137,
          format: "Number",
        },
        "Gross Sales": {
          value: 3977.17,
          format: "Currency",
        },
        Customers: {
          value: 100,
          format: "Number",
        },
        Transactions: {
          value: 137,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.03,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.8,
          format: "Currency",
        },
        "Discount Amount": {
          value: 189.65,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-19",
    grossSale: 2375.58,
    tooltipData: {
      current: {
        "Order Count": {
          value: 108,
          format: "Number",
        },
        "Gross Sales": {
          value: 2375.58,
          format: "Currency",
        },
        Customers: {
          value: 76,
          format: "Number",
        },
        Transactions: {
          value: 108,
          format: "Number",
        },
        "Average Ticket": {
          value: 22,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.48,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 161,
          format: "Number",
        },
        "Gross Sales": {
          value: 3763.96,
          format: "Currency",
        },
        Customers: {
          value: 107,
          format: "Number",
        },
        Transactions: {
          value: 161,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.38,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.02,
          format: "Currency",
        },
        "Discount Amount": {
          value: 57,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-20",
    grossSale: 2664.94,
    tooltipData: {
      current: {
        "Order Count": {
          value: 93,
          format: "Number",
        },
        "Gross Sales": {
          value: 2664.94,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 93,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.66,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.75,
          format: "Currency",
        },
        "Discount Amount": {
          value: 19.95,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 112,
          format: "Number",
        },
        "Gross Sales": {
          value: 2797.87,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 112,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.98,
          format: "Currency",
        },
        "Return Amount": {
          value: 9.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 11.3,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-21",
    grossSale: 3953.26,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3953.26,
          format: "Currency",
        },
        Customers: {
          value: 92,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 30.41,
          format: "Currency",
        },
        "Return Amount": {
          value: 33.1,
          format: "Currency",
        },
        "Discount Amount": {
          value: 230.44,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 120,
          format: "Number",
        },
        "Gross Sales": {
          value: 2854.63,
          format: "Currency",
        },
        Customers: {
          value: 78,
          format: "Number",
        },
        Transactions: {
          value: 120,
          format: "Number",
        },
        "Average Ticket": {
          value: 23.79,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 1.79,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-22",
    grossSale: 3804.29,
    tooltipData: {
      current: {
        "Order Count": {
          value: 132,
          format: "Number",
        },
        "Gross Sales": {
          value: 3804.29,
          format: "Currency",
        },
        Customers: {
          value: 97,
          format: "Number",
        },
        Transactions: {
          value: 132,
          format: "Number",
        },
        "Average Ticket": {
          value: 28.82,
          format: "Currency",
        },
        "Return Amount": {
          value: 2.4,
          format: "Currency",
        },
        "Discount Amount": {
          value: 86.62,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 135,
          format: "Number",
        },
        "Gross Sales": {
          value: 3643.13,
          format: "Currency",
        },
        Customers: {
          value: 93,
          format: "Number",
        },
        Transactions: {
          value: 135,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.99,
          format: "Currency",
        },
        "Return Amount": {
          value: 8.69,
          format: "Currency",
        },
        "Discount Amount": {
          value: 142.18,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-23",
    grossSale: 4092.72,
    tooltipData: {
      current: {
        "Order Count": {
          value: 153,
          format: "Number",
        },
        "Gross Sales": {
          value: 4092.72,
          format: "Currency",
        },
        Customers: {
          value: 106,
          format: "Number",
        },
        Transactions: {
          value: 153,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.75,
          format: "Currency",
        },
        "Return Amount": {
          value: 20.3,
          format: "Currency",
        },
        "Discount Amount": {
          value: 32.81,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 99,
          format: "Number",
        },
        "Gross Sales": {
          value: 2455.66,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 99,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.8,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 43.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-24",
    grossSale: 3617.33,
    tooltipData: {
      current: {
        "Order Count": {
          value: 145,
          format: "Number",
        },
        "Gross Sales": {
          value: 3617.33,
          format: "Currency",
        },
        Customers: {
          value: 102,
          format: "Number",
        },
        Transactions: {
          value: 145,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.95,
          format: "Currency",
        },
        "Return Amount": {
          value: 5,
          format: "Currency",
        },
        "Discount Amount": {
          value: 14.1,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 143,
          format: "Number",
        },
        "Gross Sales": {
          value: 3815.25,
          format: "Currency",
        },
        Customers: {
          value: 108,
          format: "Number",
        },
        Transactions: {
          value: 143,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.68,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 87.17,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-25",
    grossSale: 4175.49,
    tooltipData: {
      current: {
        "Order Count": {
          value: 159,
          format: "Number",
        },
        "Gross Sales": {
          value: 4175.49,
          format: "Currency",
        },
        Customers: {
          value: 116,
          format: "Number",
        },
        Transactions: {
          value: 159,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.26,
          format: "Currency",
        },
        "Return Amount": {
          value: 19,
          format: "Currency",
        },
        "Discount Amount": {
          value: 34.71,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 152,
          format: "Number",
        },
        "Gross Sales": {
          value: 4482.68,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 152,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.49,
          format: "Currency",
        },
        "Return Amount": {
          value: 12.15,
          format: "Currency",
        },
        "Discount Amount": {
          value: 22.64,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-26",
    grossSale: 2718.7,
    tooltipData: {
      current: {
        "Order Count": {
          value: 103,
          format: "Number",
        },
        "Gross Sales": {
          value: 2718.7,
          format: "Currency",
        },
        Customers: {
          value: 83,
          format: "Number",
        },
        Transactions: {
          value: 103,
          format: "Number",
        },
        "Average Ticket": {
          value: 26.4,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.46,
          format: "Currency",
        },
        "Discount Amount": {
          value: 63.57,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 136,
          format: "Number",
        },
        "Gross Sales": {
          value: 4457.98,
          format: "Currency",
        },
        Customers: {
          value: 96,
          format: "Number",
        },
        Transactions: {
          value: 136,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.78,
          format: "Currency",
        },
        "Return Amount": {
          value: 5.45,
          format: "Currency",
        },
        "Discount Amount": {
          value: 163.11,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-27",
    grossSale: 2485.69,
    tooltipData: {
      current: {
        "Order Count": {
          value: 100,
          format: "Number",
        },
        "Gross Sales": {
          value: 2485.69,
          format: "Currency",
        },
        Customers: {
          value: 80,
          format: "Number",
        },
        Transactions: {
          value: 100,
          format: "Number",
        },
        "Average Ticket": {
          value: 24.86,
          format: "Currency",
        },
        "Return Amount": {
          value: 1.54,
          format: "Currency",
        },
        "Discount Amount": {
          value: 17.18,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 106,
          format: "Number",
        },
        "Gross Sales": {
          value: 3166.1,
          format: "Currency",
        },
        Customers: {
          value: 74,
          format: "Number",
        },
        Transactions: {
          value: 106,
          format: "Number",
        },
        "Average Ticket": {
          value: 29.87,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 30.95,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-28",
    grossSale: 3342.88,
    tooltipData: {
      current: {
        "Order Count": {
          value: 101,
          format: "Number",
        },
        "Gross Sales": {
          value: 3342.88,
          format: "Currency",
        },
        Customers: {
          value: 68,
          format: "Number",
        },
        Transactions: {
          value: 101,
          format: "Number",
        },
        "Average Ticket": {
          value: 33.1,
          format: "Currency",
        },
        "Return Amount": {
          value: 16.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 247.86,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 107,
          format: "Number",
        },
        "Gross Sales": {
          value: 2398.79,
          format: "Currency",
        },
        Customers: {
          value: 72,
          format: "Number",
        },
        Transactions: {
          value: 107,
          format: "Number",
        },
        "Average Ticket": {
          value: 22.42,
          format: "Currency",
        },
        "Return Amount": {
          value: 44.08,
          format: "Currency",
        },
        "Discount Amount": {
          value: 3.53,
          format: "Currency",
        },
      },
    },
  },
  {
    idMonth: "2024-8-29",
    grossSale: 3300.89,
    tooltipData: {
      current: {
        "Order Count": {
          value: 130,
          format: "Number",
        },
        "Gross Sales": {
          value: 3300.89,
          format: "Currency",
        },
        Customers: {
          value: 101,
          format: "Number",
        },
        Transactions: {
          value: 130,
          format: "Number",
        },
        "Average Ticket": {
          value: 25.39,
          format: "Currency",
        },
        "Return Amount": {
          value: 0,
          format: "Currency",
        },
        "Discount Amount": {
          value: 39.85,
          format: "Currency",
        },
      },
      previous: {
        "Order Count": {
          value: 109,
          format: "Number",
        },
        "Gross Sales": {
          value: 3568.35,
          format: "Currency",
        },
        Customers: {
          value: 71,
          format: "Number",
        },
        Transactions: {
          value: 109,
          format: "Number",
        },
        "Average Ticket": {
          value: 32.74,
          format: "Currency",
        },
        "Return Amount": {
          value: 3.6,
          format: "Currency",
        },
        "Discount Amount": {
          value: 200.43,
          format: "Currency",
        },
      },
    },
  },
]

export const HorizontalBarChartParameters = {
  margin: {
    top: 10,
    right: 10,
    bottom: 30,
    left: 40,
  },
  padding: 0.2,
  borderRadius: 3,
  layout: "horizontal",
  keys: ["revenue", "orders"],
  indexBy: "id",
  colors: {
    scheme: "nivo",
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    labelTextColor: "red",
    tickRotation: -42,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
  },
  enableLabel: false,
  animate: {
    motionStiffness: 80,
    motionDamping: 15,
  },
}

export const sunburstData = {
  name: "nivo",
  color: "hsl(215, 70%, 50%)",
  children: [
    {
      name: "viz",
      color: "hsl(86, 70%, 50%)",
      children: [
        {
          name: "stack",
          color: "hsl(209, 70%, 50%)",
          children: [
            {
              name: "cchart",
              color: "hsl(78, 70%, 50%)",
              loc: 8562,
            },
            {
              name: "xAxis",
              color: "hsl(280, 70%, 50%)",
              loc: 176586,
            },
            {
              name: "yAxis",
              color: "hsl(213, 70%, 50%)",
              loc: 39214,
            },
            {
              name: "layers",
              color: "hsl(279, 70%, 50%)",
              loc: 43788,
            },
          ],
        },
        {
          name: "ppie",
          color: "hsl(20, 70%, 50%)",
          children: [
            {
              name: "chart",
              color: "hsl(242, 70%, 50%)",
              children: [
                {
                  name: "pie",
                  color: "hsl(202, 70%, 50%)",
                  children: [
                    {
                      name: "outline",
                      color: "hsl(80, 70%, 50%)",
                      loc: 156210,
                    },
                    {
                      name: "slices",
                      color: "hsl(80, 70%, 50%)",
                      loc: 192060,
                    },
                    {
                      name: "bbox",
                      color: "hsl(281, 70%, 50%)",
                      loc: 98825,
                    },
                  ],
                },
                {
                  name: "donut",
                  color: "hsl(94, 70%, 50%)",
                  loc: 5421,
                },
                {
                  name: "gauge",
                  color: "hsl(317, 70%, 50%)",
                  loc: 165074,
                },
              ],
            },
            {
              name: "legends",
              color: "hsl(334, 70%, 50%)",
              loc: 19528,
            },
          ],
        },
      ],
    },
    {
      name: "colors",
      color: "hsl(31, 70%, 50%)",
      children: [
        {
          name: "rgb",
          color: "hsl(266, 70%, 50%)",
          loc: 3817,
        },
        {
          name: "hsl",
          color: "hsl(254, 70%, 50%)",
          loc: 129091,
        },
      ],
    },
    {
      name: "utils",
      color: "hsl(298, 70%, 50%)",
      children: [
        {
          name: "randomize",
          color: "hsl(226, 70%, 50%)",
          loc: 1402,
        },
        {
          name: "resetClock",
          color: "hsl(23, 70%, 50%)",
          loc: 167611,
        },
        {
          name: "noop",
          color: "hsl(224, 70%, 50%)",
          loc: 178268,
        },
        {
          name: "tick",
          color: "hsl(303, 70%, 50%)",
          loc: 178983,
        },
        {
          name: "forceGC",
          color: "hsl(23, 70%, 50%)",
          loc: 169552,
        },
        {
          name: "stackTrace",
          color: "hsl(321, 70%, 50%)",
          loc: 181511,
        },
        {
          name: "dbg",
          color: "hsl(177, 70%, 50%)",
          loc: 92905,
        },
      ],
    },
    {
      name: "generators",
      color: "hsl(234, 70%, 50%)",
      children: [
        {
          name: "address",
          color: "hsl(288, 70%, 50%)",
          loc: 64130,
        },
        {
          name: "city",
          color: "hsl(221, 70%, 50%)",
          loc: 91558,
        },
        {
          name: "animal",
          color: "hsl(5, 70%, 50%)",
          loc: 17932,
        },
        {
          name: "movie",
          color: "hsl(343, 70%, 50%)",
          loc: 38926,
        },
        {
          name: "user",
          color: "hsl(316, 70%, 50%)",
          loc: 138682,
        },
      ],
    },
    {
      name: "set",
      color: "hsl(193, 70%, 50%)",
      children: [
        {
          name: "clone",
          color: "hsl(306, 70%, 50%)",
          loc: 8378,
        },
        {
          name: "intersect",
          color: "hsl(343, 70%, 50%)",
          loc: 11604,
        },
        {
          name: "merge",
          color: "hsl(343, 70%, 50%)",
          loc: 183297,
        },
        {
          name: "reverse",
          color: "hsl(240, 70%, 50%)",
          loc: 103688,
        },
        {
          name: "toArray",
          color: "hsl(330, 70%, 50%)",
          loc: 31694,
        },
        {
          name: "toObject",
          color: "hsl(186, 70%, 50%)",
          loc: 14587,
        },
        {
          name: "fromCSV",
          color: "hsl(346, 70%, 50%)",
          loc: 177751,
        },
        {
          name: "slice",
          color: "hsl(154, 70%, 50%)",
          loc: 34799,
        },
        {
          name: "append",
          color: "hsl(201, 70%, 50%)",
          loc: 130616,
        },
        {
          name: "prepend",
          color: "hsl(271, 70%, 50%)",
          loc: 127947,
        },
        {
          name: "shuffle",
          color: "hsl(139, 70%, 50%)",
          loc: 118678,
        },
        {
          name: "pick",
          color: "hsl(116, 70%, 50%)",
          loc: 27926,
        },
        {
          name: "plouc",
          color: "hsl(280, 70%, 50%)",
          loc: 56071,
        },
      ],
    },
    {
      name: "text",
      color: "hsl(32, 70%, 50%)",
      children: [
        {
          name: "trim",
          color: "hsl(300, 70%, 50%)",
          loc: 127513,
        },
        {
          name: "slugify",
          color: "hsl(257, 70%, 50%)",
          loc: 191574,
        },
        {
          name: "snakeCase",
          color: "hsl(193, 70%, 50%)",
          loc: 66189,
        },
        {
          name: "camelCase",
          color: "hsl(285, 70%, 50%)",
          loc: 73309,
        },
        {
          name: "repeat",
          color: "hsl(111, 70%, 50%)",
          loc: 170123,
        },
        {
          name: "padLeft",
          color: "hsl(321, 70%, 50%)",
          loc: 197209,
        },
        {
          name: "padRight",
          color: "hsl(265, 70%, 50%)",
          loc: 125236,
        },
        {
          name: "sanitize",
          color: "hsl(276, 70%, 50%)",
          loc: 174268,
        },
        {
          name: "ploucify",
          color: "hsl(223, 70%, 50%)",
          loc: 194304,
        },
      ],
    },
    {
      name: "misc",
      color: "hsl(126, 70%, 50%)",
      children: [
        {
          name: "greetings",
          color: "hsl(300, 70%, 50%)",
          loc: 79690,
        },
        {
          name: "other",
          color: "hsl(150, 70%, 50%)",
          loc: 16906,
        },
        {
          name: "path",
          color: "hsl(147, 70%, 50%)",
          loc: 56412,
        },
      ],
    },
  ],
}

export const sunburstParameters = {
  margin: { top: 10, right: 10, bottom: 10, left: 10 },
  id: "name",
  value: "loc",
  cornerRadius: 2,
  borderWidth: 3,
  borderColor: {
    theme: "background",
  },
  colors: { scheme: "blues" },
  childColor: {
    from: "color",
    modifiers: [["brighter", 0.1]],
  },
  enableArcLabels: true,
  arcLabelsSkipAngle: 14,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
}
