import React from "react"
import Paper from "@mui/material/Paper"
import WatermarkSVG from "../assets/svgs/CardVectorDark.svg"
import WatermarkSVGlight from "../assets/svgs/CardVectorLight.svg"
import { useTheme } from "../contexts/theme"

const CustomPaper = React.forwardRef((props, ref) => {
  const {
    children,
    customStyles = {},
    innerCustomStyles = {},
    className = "",
    watermarkBg = false,
    elevation = 0,
    onClick,
  } = props
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode

  return (
    <Paper
      ref={ref} // Attach ref here
      className={className}
      elevation={elevation}
      sx={{
        borderRadius: "10px",
        background: watermarkBg
          ? ThemeMode === "dark"
            ? "linear-gradient(76deg, #153393 0%, #0D2266 100%) !important"
            : "#ffffff"
          : "",
        backgroundSize: "contain",
        backgroundPosition: "calc(100% - 2px) center",
        boxShadow: "0px 2px 16px rgba(29, 71, 206, 0.06) !important",
        ...customStyles,
      }}
      onClick={onClick}
    >
      <Paper
        className={className}
        elevation={elevation}
        sx={{
          padding: "1.2rem",
          borderRadius: "10px",
          textAlign: "left",
          height: "100%",
          backgroundColor: watermarkBg ? "transparent" : "unset",
          backgroundImage: watermarkBg
            ? `url(${ThemeMode === "dark" ? WatermarkSVG : WatermarkSVGlight})`
            : "unset",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "calc(100% - 2px) center",
          overflow: "hidden",
          boxShadow: "0px 0.5px 0px rgba(0, 0, 0, 0.1)",
          ...customStyles,
          ...innerCustomStyles,
        }}
      >
        {children}
      </Paper>
    </Paper>
  )
})

export default CustomPaper
