import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import BarChartPresentation from "./BarChartPresentation"
import {
  BarTooltipData,
  chartContainerDimentionsFallback,
} from "./constant/configFallback"
import {
  BarChartData,
  BarChartParameters,
  chartDataMap,
} from "./constant/dataFallback"
import { transformExportData } from "./services/transformService"
import { fetchData } from "./services/dataService"
import { convertValues, legendsData } from "./services/configService"
import { generateCacheKey } from "../../../utils/utils"

const BarChartContainer = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  performerFlagIcon,
  showAiDropdown = true,
  colorFlag,
  setAnchorEl,
}) => {
  const [loading, setLoading] = useState(true)
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("")
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const extractedPara = extractKeyValuePairs(parameters)
  const chartRef = useRef(null)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [aiData, setAiData] = useState({
    Analysis: "",
    Recommendations: "",
    Anomalies: "",
  })
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = chartContainerDimentionsFallback)

  const tooltip_data = extractedParameters.tooltip_data || BarTooltipData

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const params = convertValues(extractedParameters)

  useEffect(() => {
    if (reportState?.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      setLoading,
      setChartData,
      postUrl,
      reportUid,
      combinedState,
      setApiResponse,
      setError,
      tooltip_data,
      dispatch,
      comparisonPeriod,
      extractedParameters,
      viewBy,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const config = parameters.length === 1 ? BarChartParameters : params

  const chartInput = Array.isArray(response)
    ? response
    : chartDataMap[title] || BarChartData

  const tableData = transformExportData(
    reduxApiResponse,
    reportUid,
    extractedParameters
  )

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
        headerType="ID"
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap={"wrap"}
          gap={"10px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box>
            {!loading && (
              <ExportMenuButton
                apiResponse={apiResponse}
                title={title}
                setAiData={setAiData}
                setPdfOpen={setPdfOpen}
                setAnchorEl={setAnchorEl}
                tableData={tableData}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <BarChartPresentation
            chartInput={chartInput}
            parameters={config}
            performerFlagIcon={performerFlagIcon}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            colorFlag={colorFlag}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <BarChartPresentation
            chartInput={chartInput}
            parameters={config}
            performerFlagIcon={performerFlagIcon}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            colorFlag={colorFlag}
            showTooltipPrevious={showTooltipPrevious}
            legendsData={legendsData()}
          />
        )}
      </Box>
    </>
  )
}

export default BarChartContainer
