import Box from "@mui/material/Box"
import WordCloud from "react-d3-cloud"

const BodySection = ({ data, config }) => {
  return (
    <>
      <Box height="350px">
        <WordCloud
          data={data}
          font="Comfortaa"
          width={500}
          height={275}
          fontWeight="bold"
          padding={5}
        />
      </Box>
    </>
  )
}

export default BodySection
