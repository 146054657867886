import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const Loading = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    alignContent="center"
    gap="10px"
    minHeight="350px"
    width="100%"
  >
    <CircularProgress disableShrink size={45} color="info" />
    <Typography variant="body2" color="inherit">
      Fetching data, please wait...
    </Typography>
  </Box>
)

export default Loading
