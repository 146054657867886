import { useState } from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "../../../../contexts/theme"
import AnalyticaInfoIcon from "../../../../assets/svgs/AnalyticaInfoIcon"

const Searchbar = ({
  setSearchOpen,
  handleSideBarOpen,
  handleSideBarClose,
  handleSideBarToggle,
  handleSideBarUpdate,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { theme } = useTheme()

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSideBarUpdate("AnalyticaInfoIcon", {
        type: "globalSearch",
        data: {
          searchTerm,
        },
      })
      handleSideBarOpen()
    }
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const searchEnabled = localStorage.getItem("dataStatus")?.trim() === "Ready"

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {!searchEnabled && (
        <Box
          width="150%"
          height="110%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            background:
              theme.palette.mode === "dark" ? "#00000085" : "#FFFFFFA0",
            position: "absolute",
            zIndex: 1,
            top: "-2px",
            left: "-2px",
            borderRadius: "8px",
          }}
        />
      )}
      <OutlinedInput
        id="search-bar"
        value={searchTerm}
        fullWidth
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Ask AI or Search here..."
        inputProps={{
          style: {
            fontSize: 13,
            border: "none",
            outline: "none",
            height: "0px",
          },
        }}
        sx={{
          "& fieldset": { border: "none" },
          outline: "1px solid #6382E9",
          borderRadius: "8px",
          height: "33px",
          maxWidth: "900px",
          width: "100%",
          minWidth: "300px",
          backgroundColor:
            theme.palette.mode === "dark" ? "#0D2266" : "#1b4cf00a",
          ":hover": {
            outline: `1px solid ${theme.palette.accent}`,
          },
          // TODO: this is not getting overrider, find a fix for it.
          // "& .MuiOutlinedInput-root input:-webkit-autofill": {
          //   WebkitBoxShadow: "0 0 0 100px #FF0000 inset !important",
          //   WebkitTextFillColor: "#ffffff !important",
          //   caretColor: "#ffffff !important",
          // },
        }}
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ position: "relative", left: "-5px" }}
          >
            <Box spacing={1} mr="-5px">
              <AnalyticaInfoIcon
                mode={theme.palette.mode}
                height={24}
                width={24}
              />
            </Box>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ position: "relative", left: "5px" }}
          >
            <Box spacing={1} mr="-5px">
              <IconButton
                onClick={() => {
                  if (searchTerm) {
                    handleSideBarUpdate("AnalyticaInfoIcon", {
                      type: "globalSearch",
                      data: {
                        searchTerm,
                      },
                    })
                    handleSideBarOpen()
                  }
                }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z"
                    fill={theme.palette.accent}
                  />
                </svg>
              </IconButton>
              {/* <IconButton
                onClick={() => {
                  // setSearchOpen(false)
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00015 9.33203C9.10682 9.33203 10.0002 8.4387 10.0002 7.33203V3.33203C10.0002 2.22536 9.10682 1.33203 8.00015 1.33203C6.89349 1.33203 6.00015 2.22536 6.00015 3.33203V7.33203C6.00015 8.4387 6.89349 9.33203 8.00015 9.33203ZM11.9402 7.33203C11.6135 7.33203 11.3402 7.57203 11.2868 7.8987C11.0135 9.46537 9.64682 10.6654 8.00015 10.6654C6.35349 10.6654 4.98682 9.46537 4.71349 7.8987C4.66015 7.57203 4.38682 7.33203 4.06015 7.33203C3.65349 7.33203 3.33349 7.69203 3.39349 8.09203C3.72015 10.092 5.32015 11.6587 7.33349 11.9454V13.332C7.33349 13.6987 7.63349 13.9987 8.00015 13.9987C8.36682 13.9987 8.66682 13.6987 8.66682 13.332V11.9454C10.6802 11.6587 12.2802 10.092 12.6068 8.09203C12.6735 7.69203 12.3468 7.33203 11.9402 7.33203Z"
                    fill={theme.palette.accent}
                  />
                </svg>
              </IconButton> */}
              <IconButton
                onClick={() => {
                  // setSearchOpen(false)
                  setSearchTerm("")
                }}
              >
                <CloseIcon
                  sx={{ color: theme.palette.accent, fontSize: "1rem" }}
                />
              </IconButton>
            </Box>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default Searchbar
