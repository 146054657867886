import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import ExportChartsData from "../../ExportChartsData"
import ExportMenuButton from "../../ExportMenuButton"
import CalendarPresentation from "./CalendarPresentation"
import { postData } from "../../../api/api"
import {
  chartContainerDimensionsFallBack,
  tooltip_dataFallBack,
} from "./constants/configFallBack"
import {
  transformExportData,
  transfromChartData,
} from "./services/transformServices"
import {
  CalendarChartData,
  CalendarChartParameters,
} from "./constants/dataFallBack"
import { fetchData } from "./services/dataServices"
import { generateCacheKey } from "../../../utils/utils"

const CalendarContainer = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showLegend = true,
  showAiDropdown = true,
  defaultColor = ["#eeeeee", "#61cdbb", "#e8c1a0", "#f47560"],
  setAnchorEl,
}) => {
  const { theme } = useTheme()
  const chartRef = useRef(null)
  const dispatch = useDispatch()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const [pdfOpen, setPdfOpen] = useState(false)
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  const [aiData, setAiData] = useState({
    Analysis: "Test",
    Recommendations: "Test",
    Anomalies: "Test",
  })

  let reportState = useSelector((state) => {
    return state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
  })
  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate
  )

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions =
      chartContainerDimensionsFallBack)

  const tooltip_data = extractedParameters.tooltip_data || tooltip_dataFallBack

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) {
      setLoading(false)
      return
    }
    setLoading(true)
    fetchData(
      postUrl,
      reportUid,
      tooltip_data,
      dispatch,
      setApiResponse,
      setError,
      setChartData,
      setLoading,
      cacheKey
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBy])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const reduxApiResponse = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.apiResponse
  })

  const chartInput = response || CalendarChartData
  const tableData = transformExportData(reduxApiResponse, extractedParameters)

  return (
    <>
      <ExportChartsData
        open={pdfOpen}
        setOpen={setPdfOpen}
        title={title}
        description={description}
        chartRef={chartRef}
        apiResponse={apiResponse}
        combinedState={combinedState}
        aiData={aiData}
        tableData={tableData}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  showLegend,
                  apiResponse,
                }}
              />
            )}
          </Box>
          {!loading && (
            <ExportMenuButton
              apiResponse={apiResponse}
              title={title}
              setAiData={setAiData}
              setPdfOpen={setPdfOpen}
              setAnchorEl={setAnchorEl}
              tableData={tableData}
            />
          )}
        </Box>
      </Box>

      <Box ref={chartRef}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="550px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <CalendarPresentation
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <CalendarPresentation
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default CalendarContainer
