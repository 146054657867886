export const tooltipDataFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
]
export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const colorScheme = ["#8dd3c7", "#ffffb3", "#bebada", "#fb8072"]

// colorPalette.js
export const colorPalette = [
  "#7282FF",
  "#D084E4",
  "#A17BC8",
  "#2271B4",
  "#6E8B3D",
  "#C75D8A",
  "#DB7093",
  "#5C73F2",
  "#3D50F5",
  "#A17BC8",
  "#007BFF",
  "#17A2B8",
  "#8B93D1",
  "#E900EF",
  "#343AFF",
  "#8067E3",
  "#A96FFF",
  "#FF85E1",
  "#95C75B",
  "#BBC954",
]

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
export const macroMetricsFallbackConfig = (reportUid) => {
  const excludedReportUids = [
    "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0",
    "C3C052D5-73B7-4BCF-8CEF-F52B0823DAA8",
    "7AEA1930-BFAE-450D-962D-1B315020F0F6",
    "7D277C9B-9535-4F45-8B5F-27C1E8B389FE",
    "206F9265-DDD2-4306-9B1D-30FEFEF35228",
  ]
  if (excludedReportUids.includes(reportUid)) {
    return null
  }
  return [
    {
      name: "highest",
      helpText: "Highest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      thirdRow: { value: "Star Performer", operation: null },
      aggregateFunc: "MAX",
      targetField: "Gross Sales",
      format: "Currency",
      color: "#23BF6F",
      icon: "VerifiedIcon",
    },
    {
      name: "lowest",
      helpText: "Lowest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      thirdRow: { value: "Low Performer", operation: null },
      aggregateFunc: "MIN",
      targetField: "Gross Sales",
      format: "Currency",
      color: "#EC2D30",
      icon: "NewReleasesIcon",
    },
  ]
}

export const tooltipDataFallbackConfigCustomers = [
  {
    name: "CustomerProfiles",
    format: false,
    color: "",
  },
  {
    name: "PaymentMethod",
    format: false,
    color: "",
  },
  {
    name: "TotalCustomers",
    format: "Number",
    color: "",
  },
]
