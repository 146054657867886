export const tooltip_dataFallBack = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]
export const chartContainerDimensionsFallBack = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}
