import { useEffect, useRef, useState } from "react"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs.js"
import { useDispatch, useSelector } from "react-redux"
import { fetchData } from "./services/dataServices.js"
import {
  LegendData,
  transformChartData,
  transformDataPDFExport,
  transformDataSheetExport,
} from "./services/transformServices.js"
import {
  chartContainerDimentionsFallbackConfig,
  PDFFieldsFallbackConfig,
  tooltipDataFallbackConfig,
} from "./constant/configFallBack.js"
import { generateCacheKey } from "../../../utils/utils.js"
import BodySection from "./components/widget/BodySection.jsx"
import { Stack } from "@mui/material"
import HeaderSection from "./components/widget/HeaderSection.jsx"
import FooterSection from "./components/widget/FooterSection.jsx"
import Loading from "./components/Loading.jsx"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils.js"

const RadialBarChartContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
}) => {
  const dispatch = useDispatch()
  const chartRef = useRef(null)

  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  let transformedConfigs = extractKeyValuePairs(configurations)

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.viewBy
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Override / calculated configs
  const configOverrides = [
    {
      key: "chartContainerDimensions",
      fallback: chartContainerDimentionsFallbackConfig,
    },
    { key: "tooltip_data", fallback: tooltipDataFallbackConfig },
    // TODO: comment this out, as each report has different fallback
    // { key: "macro-metrics", fallback: macroMetricsFallbackConfig },
    { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
    {
      key: "showTooltipPrevious",
      fallback:
        transformedConfigs.showTooltipPrevious === undefined
          ? true
          : transformedConfigs.showTooltipPrevious,
    },
    { key: "colorFlag", fallback: "multi" },
  ]

  configOverrides.forEach(({ key, fallback }) => {
    transformedConfigs[key] = transformedConfigs[key] || fallback
  })

  const daysInRange = dateRangeData
    ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
    : 0

  const visibleViewByOptions =
    rangeOptions.find(
      (range) => daysInRange >= range.min && daysInRange <= range.max
    )?.options || []

  const defaultViewByOption = getDefaultOption(daysInRange)

  const viewBy = visibleViewByOptions.includes(reportState.viewBy)
    ? reportState.viewBy
    : defaultViewByOption

  useEffect(() => {
    if (reportState.cacheKey === cacheKey) return
    // const isComparedBy = comparisonPeriod.selectedValue === "Compared By"
    const payload = {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: dateRangeData.fromDate,
      toDate: dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: comparisonPeriod.previousFromDate,
        previousToDate: comparisonPeriod.previousToDate,
      },
    }
    if (!!transformedConfigs.view_by_period) {
      payload.viewBy = viewBy
    }
    setLoading(true)
    fetchData(postUrl, payload)
      .then((apiResponse) => {
        dispatch(
          setChartData({
            reportUid,
            response: apiResponse,
            apiResponse,
            transformedChartData: transformChartData(apiResponse),
            viewBy,
            cacheKey,
          })
        )
        setLoading(false)
      })
      .catch((err) => {
        console.warn(err)
        setError("Failed to fetch data")
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.cacheKey,
    reportState.viewBy,
  ])

  return (
    <Stack spacing={1} ref={chartRef}>
      <HeaderSection
        route={route}
        reportUid={reportUid}
        title={title}
        description={description}
        setChartData={setChartData}
        children={null}
        config={transformedConfigs}
        reportStateViewBy={reportState.viewBy}
        dateRangeData={dateRangeData}
        parentLoading={loading}
        parentError={error}
        transformedChartData={reportState.apiResponse}
        apiResponse={reportState.apiResponse}
        chartRef={chartRef}
        pdfFields={transformedConfigs.pdfFields}
        pdfTransformFunction={transformDataPDFExport}
        transformDataSheetExport={transformDataSheetExport}
        visibleViewByOptions={visibleViewByOptions}
        defaultViewByOption={defaultViewByOption}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <BodySection
            chartConfig={transformedConfigs}
            data={reportState.transformedChartData}
          />
          <FooterSection
            legendsData={LegendData(reportState)}
            title={title}
            apiResponse={reportState.apiResponse}
          />
        </>
      )}
    </Stack>
  )
}

export default RadialBarChartContainer
