import dayjs from "dayjs"

const calculatePreviousPeriod = (fromDate, toDate) => {
  if (fromDate && toDate) {
    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    // Check if the selected range spans from the 1st to the last day of the month
    const isFullMonth = from.date() === 1 && to.date() === to.daysInMonth()

    // Check if the range spans multiple months
    const isMultipleMonths =
      from.month() !== to.month() || from.year() !== to.year()

    if (isFullMonth && !isMultipleMonths) {
      // If the range covers the entire month, calculate the entire previous month range
      const prevMonthStart = from
        .subtract(1, "month")
        .startOf("month")
        .format("YYYYMMDD")
      const prevMonthEnd = from
        .subtract(1, "month")
        .endOf("month")
        .format("YYYYMMDD")

      return {
        selectedValue: "Previous Period",
        previousFromDate: prevMonthStart,
        previousToDate: prevMonthEnd,
      }
    } else {
      const daysInRange = to.diff(from, "day") + 1

      const prevTo = from.subtract(1, "day")
      const prevFrom = prevTo.subtract(daysInRange - 1, "day")

      // Return the calculated previous range
      return {
        selectedValue: "Previous Period",
        previousFromDate: prevFrom.format("YYYYMMDD"),
        previousToDate: prevTo.format("YYYYMMDD"),
      }
    }
  } else {
    return null
  }
}

const calculatePreviousYear = (fromDate, toDate) => {
  if (fromDate && toDate) {
    // Parse the fromDate and toDate strings into dayjs objects
    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    if (!from.isValid() || !to.isValid()) {
      return null
    }

    const prevFrom = from.subtract(1, "year")
    const prevTo = to.subtract(1, "year")

    // Handle leap year case (e.g., Feb 29 to Feb 28/29)
    if (prevFrom.date() !== from.date()) {
      prevFrom.date(from.date())
    }
    if (prevTo.date() !== to.date()) {
      prevTo.date(to.date())
    }

    // Ensure that fromDate is not after toDate
    if (prevFrom.isAfter(prevTo)) {
      return null
    }

    return {
      selectedValue: "Previous Year",
      previousFromDate: prevFrom.format("YYYYMMDD"),
      previousToDate: prevTo.format("YYYYMMDD"),
    }
  } else {
    return null
  }
}

const formatDate = (date, format = getDateFormatFromLocalStorage()) => {
  let dayjsDate = dayjs(date)

  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date.getTime())
  }

  return dayjsDate.format(format)
}

const getDateFormatFromLocalStorage = () => {
  return localStorage.getItem("dateFormat") || "MM-DD-YYYY"
}

export {
  calculatePreviousPeriod,
  calculatePreviousYear,
  formatDate,
  getDateFormatFromLocalStorage,
}
