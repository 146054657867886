import React, { forwardRef } from "react"
import Box from "@mui/material/Box"
import InfoToolTip from "../../../../InfoToolTip"
import { useTheme } from "../../../../../contexts/theme"
import InfoLogoSvg from "../../../../../assets/svgs/InfoLogoSvg"
import ViewBy from "../../../../viewBy_re/ViewBy_re"
import ExportMenu from "../../../../export/ExportMenu_re"
import Typography from "@mui/material/Typography"

const HeaderSection = forwardRef(
  (
    {
      route,
      reportUid,
      title,
      description,
      setChartData,
      children,
      config,
      viewByValue,
      dateRangeData,
      parentLoading,
      transformedChartData,
      apiResponse,
      chartRef,
      pdfFields,
      pdfTransformFunction,
    },
    ref
  ) => {
    const { theme } = useTheme()

    return (
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={theme.palette.accentSecondary} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="20px"
        >
          {!!config.view_by_period && (
            <ViewBy
              config={config.view_by_period}
              viewByValue={viewByValue}
              setChartData={setChartData}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {!parentLoading && (
            <ExportMenu
              route={route}
              title={title}
              description={description}
              reportUid={reportUid}
              setChartData={setChartData}
              transformedChartData={transformedChartData}
              apiResponse={apiResponse}
              chartRef={chartRef}
              pdfFields={pdfFields}
              pdfTransformFunction={pdfTransformFunction}
            />
          )}
        </Box>
        {children}
      </Box>
    )
  }
)

export default HeaderSection
