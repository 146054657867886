import { Backdrop, Box, Typography } from "@mui/material"
import React, { useEffect } from "react"
import Logo from "../assets/svgs/Logo"

const BlurEffect = ({ showBlur }) => {
  useEffect(() => {
    if (showBlur) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [showBlur])

  return (
    <Box
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        zIndex: 6,
      }}
    >
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#000000B0",
          backdropFilter: "blur(4px)",
        }}
        open={showBlur}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          height="100vh"
        >
          <Typography variant="h5" color="white" fontWeight="fontWeightBold">
            Coming Soon...
          </Typography>
          <Logo width="177" height="80" />
        </Box>
      </Backdrop>
    </Box>
  )
}

export default BlurEffect
