const PDFIcon = ({ strokeColor }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.12">
        <path
          d="M3.33301 19.6667C3.33301 16.8665 3.33301 15.4664 3.87798 14.3968C4.35734 13.456 5.12225 12.6911 6.06306 12.2117C7.13261 11.6667 8.53275 11.6667 11.333 11.6667H28.6663C31.4666 11.6667 32.8667 11.6667 33.9363 12.2117C34.8771 12.6911 35.642 13.456 36.1214 14.3968C36.6663 15.4664 36.6663 16.8665 36.6663 19.6667V23.3334C36.6663 24.8834 36.6663 25.6583 36.496 26.2942C36.0336 28.0196 34.6859 29.3674 32.9604 29.8297C32.3246 30.0001 31.5496 30.0001 29.9997 30.0001V28.6667C29.9997 26.7999 29.9997 25.8665 29.6364 25.1534C29.3168 24.5262 28.8069 24.0163 28.1796 23.6967C27.4666 23.3334 26.5332 23.3334 24.6663 23.3334H15.333C13.4662 23.3334 12.5327 23.3334 11.8197 23.6967C11.1925 24.0163 10.6826 24.5262 10.363 25.1534C9.99967 25.8665 9.99967 26.7999 9.99967 28.6667V30.0001C8.44972 30.0001 7.67475 30.0001 7.03891 29.8297C5.31345 29.3674 3.96571 28.0196 3.50338 26.2942C3.33301 25.6583 3.33301 24.8834 3.33301 23.3334V19.6667Z"
          fill={strokeColor}
        />
      </g>
      <path
        d="M29.9997 11.6666V8.66659C29.9997 6.79974 29.9997 5.86632 29.6364 5.15328C29.3168 4.52608 28.8069 4.01614 28.1796 3.69656C27.4666 3.33325 26.5332 3.33325 24.6663 3.33325H15.333C13.4662 3.33325 12.5327 3.33325 11.8197 3.69656C11.1925 4.01614 10.6826 4.52608 10.363 5.15328C9.99967 5.86632 9.99967 6.79974 9.99967 8.66659V11.6666M9.99967 29.9999C8.44972 29.9999 7.67475 29.9999 7.03891 29.8295C5.31345 29.3672 3.96571 28.0195 3.50338 26.294C3.33301 25.6582 3.33301 24.8832 3.33301 23.3333V19.6666C3.33301 16.8663 3.33301 15.4662 3.87798 14.3966C4.35734 13.4558 5.12224 12.6909 6.06306 12.2116C7.13261 11.6666 8.53275 11.6666 11.333 11.6666H28.6663C31.4666 11.6666 32.8667 11.6666 33.9363 12.2116C34.8771 12.6909 35.642 13.4558 36.1214 14.3966C36.6663 15.4662 36.6663 16.8663 36.6663 19.6666V23.3333C36.6663 24.8832 36.6663 25.6582 36.496 26.294C36.0336 28.0195 34.6859 29.3672 32.9604 29.8295C32.3246 29.9999 31.5496 29.9999 29.9997 29.9999M24.9997 17.4999H29.9997M15.333 36.6666H24.6663C26.5332 36.6666 27.4666 36.6666 28.1796 36.3033C28.8069 35.9837 29.3168 35.4738 29.6364 34.8466C29.9997 34.1335 29.9997 33.2001 29.9997 31.3333V28.6666C29.9997 26.7997 29.9997 25.8663 29.6364 25.1533C29.3168 24.5261 28.8069 24.0161 28.1796 23.6966C27.4666 23.3333 26.5332 23.3333 24.6663 23.3333H15.333C13.4662 23.3333 12.5327 23.3333 11.8197 23.6966C11.1925 24.0161 10.6826 24.5261 10.363 25.1533C9.99967 25.8663 9.99967 26.7997 9.99967 28.6666V31.3333C9.99967 33.2001 9.99967 34.1335 10.363 34.8466C10.6826 35.4738 11.1925 35.9837 11.8197 36.3033C12.5327 36.6666 13.4662 36.6666 15.333 36.6666Z"
        stroke={strokeColor}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PDFIcon
