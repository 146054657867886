export const tooltipDataFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const chartColorsFallbackConfig = {
  line: {
    dark: "#fb651d",
    light: "#fb651d",
  },
  lineCircle: {
    dark: {
      fill: "#fb651d",
      stroke: "#ffffff",
    },
    light: {
      fill: "#ffffff",
      stroke: "#fb651d",
    },
  },
  bar: {
    dark: "#2647d9",
    light: "#2647d9",
  },
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const macroMetricsFallbackConfig = (reportUid) => {
  let defaultConfig = [
    {
      name: "highest",
      helpText: "Highest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      secondRow: { value: "Star Performer", operation: null },
      thirdRow: null,
      aggregateFunc: "MAX",
      targetField: "Gross Sales",
      format: "Currency",
      color: "#23BF6F",
      icon: "VerifiedIcon",
    },
    {
      name: "lowest",
      helpText: "Lowest datapoint",
      firstRow: { value: "", operation: "aggregate" },
      secondRow: { value: "Low Performer", operation: null },
      thirdRow: null,
      aggregateFunc: "MIN",
      targetField: "Gross Sales",
      format: "Currency",
      color: "#EC2D30",
      icon: "NewReleasesIcon",
    },
  ]

  if (reportUid === "08856963-D5CC-4B39-962E-EDBE77E0C3F3") {
    defaultConfig = defaultConfig.map((config) => ({
      ...config,
      targetField: "Customers",
      format: "Number",
    }))
  }

  return defaultConfig
}

export const getCustomTheme = (themeMode) => ({
  text: {
    fill: themeMode === "dark" ? "#fff" : "#333333",
  },
  tooltip: {
    container: {
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
    },
  },
  grid: {
    line: {
      stroke: "rgba(29, 71, 206, 0.3)",
      strokeWidth: 0.93,
      top: 359.59,
    },
  },
  axis: {
    ticks: {
      text: {
        fontSize: 10,
        fill: themeMode === "dark" ? "#fff" : "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
})
