const parseNumber = (value) => {
  if (typeof value === "number") return value
  if (typeof value === "string")
    return parseFloat(value.replace(/[^0-9.]/g, "")) || 0
  return 0
}

export const transfromChartData = (input, tooltip_data) => {
  if (
    !input ||
    !input.reportDataCurrent ||
    !Array.isArray(input.reportDataCurrent)
  ) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []
  input.reportDataCurrent.forEach((currentItem) => {
    const previousItem =
      input.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

    let eachElement = {
      id: currentItem.id,
      value: parseNumber(currentItem.value),

      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }

      eachElement.tooltipData.previous[tooltipDataItem.name] = {
        value: parseNumber(previousItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformDataComparedBy = (input, tooltip_data) => {
  if (!input || !Array.isArray(input)) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  input?.forEach((currentItem) => {
    let eachElement = {
      id: currentItem.id,
      ...currentItem,
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformExportData = (apiResponse, extractedParameters = {}) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse || []
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const tooltip_data = extractedParameters?.tooltip_data || [
    { name: "Gross Sales", format: "Currency", color: "" },
    { name: "Customers", format: "Number", color: "" },
    { name: "Transactions", format: "Number", color: "" },
    { name: "Average Ticket", format: "Currency", color: "" },
    { name: "Return Amount", format: "Currency", color: "" },
    { name: "Discount Amount", format: "Currency", color: "" },
  ]

  if (previousData.length === 0) {
    currentData.forEach((current) => {
      if (!current || !current.id) {
        console.warn("Invalid data structure in current data")
        return
      }

      const transformedWeek = {
        date: `${current.id}`,
      }

      tooltip_data.forEach((tooltip) => {
        const name = tooltip?.name
        if (!name) return

        const format = tooltip?.format
        if (!format) {
          console.warn("Tooltip data is missing or improperly structured")
          return
        }

        const currentVal = current[name] ?? 0

        transformedWeek[name] = {
          current:
            format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      })

      transformedData.push(transformedWeek)
    })
    return transformedData
  }

  currentData.forEach((current) => {
    if (!current || !current.id) {
      console.warn("Invalid data structure in current data")
      return
    }

    const previous = previousData.find((p) => p?.id === current.id) || {}

    const transformedWeek = {
      date: `${current.id}`,
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  })

  return transformedData
}
