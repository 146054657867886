import dayjs from "dayjs"

export const calculatePreviousPeriod = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return null
  }

  const from = dayjs(fromDate, "YYYYMMDD")
  const to = dayjs(toDate, "YYYYMMDD")

  if (!from.isValid() || !to.isValid() || to.isBefore(from)) {
    return null
  }

  // Check if the range spans almost the entire year
  const isFullYear =
    from.month() === 0 &&
    from.date() === 1 && // Starts on Jan 1
    to.month() === 11

  if (isFullYear) {
    // Calculate the same range for the previous year
    const prevFrom = from.subtract(1, "year").format("YYYYMMDD")
    const prevTo = to.subtract(1, "year").format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevFrom,
      previousToDate: prevTo,
    }
  }

  // Check if the range spans multiple months
  const isFullQuarter =
    from.date() === 1 &&
    to.date() === to.daysInMonth() &&
    to.diff(from, "month") === 2

  if (isFullQuarter) {
    // Handle full quarter ranges
    const prevFrom = from
      .subtract(3, "month")
      .startOf("month")
      .format("YYYYMMDD")
    const prevTo = to.subtract(3, "month").endOf("month").format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevFrom,
      previousToDate: prevTo,
    }
  }

  const isFullMonth = from.date() === 1 && to.date() === to.daysInMonth()

  if (isFullMonth) {
    // Handle full month ranges
    const prevMonthStart = from
      .subtract(1, "month")
      .startOf("month")
      .format("YYYYMMDD")
    const prevMonthEnd = to
      .subtract(1, "month")
      .endOf("month")
      .format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevMonthStart,
      previousToDate: prevMonthEnd,
    }
  }

  // Handle partial ranges
  const prevFrom = from.subtract(1, "month").format("YYYYMMDD")
  const prevTo = to.subtract(1, "month").format("YYYYMMDD")

  return {
    selectedValue: "Previous Period",
    previousFromDate: prevFrom,
    previousToDate: prevTo,
  }
}

export const calculatePreviousYear = (fromDate, toDate) => {
  if (fromDate && toDate) {
    // Parse the fromDate and toDate strings into dayjs objects
    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    if (!from.isValid() || !to.isValid()) {
      return null
    }

    const prevFrom = from.subtract(1, "year")
    const prevTo = to.subtract(1, "year")

    // Handle leap year case (e.g., Feb 29 to Feb 28/29)
    if (prevFrom.date() !== from.date()) {
      prevFrom.date(from.date())
    }
    if (prevTo.date() !== to.date()) {
      prevTo.date(to.date())
    }

    // Ensure that fromDate is not after toDate
    if (prevFrom.isAfter(prevTo)) {
      return null
    }

    return {
      selectedValue: "Previous Year",
      previousFromDate: prevFrom.format("YYYYMMDD"),
      previousToDate: prevTo.format("YYYYMMDD"),
    }
  } else {
    return null
  }
}

export const formatDate = (date, format = getDateFormatFromLocalStorage()) => {
  let dayjsDate = dayjs(date)

  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date.getTime())
  }

  return dayjsDate.format(format)
}

export const getDateFormatFromLocalStorage = () => {
  return localStorage.getItem("dateFormat") || "MM-DD-YYYY"
}

const dateFormat = getDateFormatFromLocalStorage()
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
const weekOfYear = require("dayjs/plugin/weekOfYear")
const customParseFormat = require("dayjs/plugin/customParseFormat")

// Extend dayjs with necessary plugins
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)

/**
 * Gets the date range for a given week number, month number, and year number,
 * where the week starts on Sunday and ends on Saturday.
 *
 * @param {number} week - The week number (1-5) in the given month.
 * @param {number} month - The month number (1-12) for the target month.
 * @param {number} year - The year in which the target week and month reside.
 *
 * @returns {string} - The formatted date range in the "MM-DD-YYYY" to "MM-DD-YYYY" format.
 *
 * @example
 * // If we input:
 * getWeekDateRange(1, 1, 2025);
 *
 * // The output will be:
 * "12-29-2024 to 01-04-2025"
 */
export const getWeekDateRange = (week, month, year) => {
  // Get the first day of the specified month and year
  const firstDayOfMonth = dayjs(`${year}-${month}-01`)

  // Calculate the first Sunday of the month
  const firstSunday = firstDayOfMonth.startOf("month").day(0)

  // Calculate the start date of the specified week
  const startDate = firstSunday.add(week - 1, "week")

  // Calculate the end date of the specified week (Saturday)
  const endDate = startDate.add(6, "day")

  // Format the start and end dates in "MM-DD-YYYY"
  const formattedStartDate = startDate.format("MM-DD-YYYY")
  const formattedEndDate = endDate.format("MM-DD-YYYY")

  // Return the formatted date range
  return `${formattedStartDate} to ${formattedEndDate}`
}

export const getMonthDateRange = (month, year) => {
  // Get the first day of the specified month and year
  const firstDayOfMonth = dayjs(`${year}-${month}-01`)

  // Get the last day of the specified month and year
  const lastDayOfMonth = firstDayOfMonth.endOf("month")

  // Format the start and end dates in "MM-DD-YYYY"
  const formattedStartDate = firstDayOfMonth.format("MM-DD-YYYY")
  const formattedEndDate = lastDayOfMonth.format("MM-DD-YYYY")

  // Return the formatted date range
  return `${formattedStartDate} to ${formattedEndDate}`
}

export const getQuarterDateRange = (quarter, year) => {
  let startMonth, endMonth

  // Determine the start and end months based on the quarter
  switch (quarter) {
    case 1:
      startMonth = 1 // January
      endMonth = 3 // March
      break
    case 2:
      startMonth = 4 // April
      endMonth = 6 // June
      break
    case 3:
      startMonth = 7 // July
      endMonth = 9 // September
      break
    case 4:
      startMonth = 10 // October
      endMonth = 12 // December
      break
    default:
      throw new Error("Invalid quarter number. It must be between 1 and 4.")
  }

  // Get the first day of the quarter
  const firstDayOfQuarter = dayjs(`${year}-${startMonth}-01`)

  // Get the last day of the quarter
  const lastDayOfQuarter = dayjs(`${year}-${endMonth}-01`).endOf("month")

  // Format the start and end dates in "MM-DD-YYYY"
  const formattedStartDate = firstDayOfQuarter.format("MM-DD-YYYY")
  const formattedEndDate = lastDayOfQuarter.format("MM-DD-YYYY")

  // Return the formatted date range
  return `${formattedStartDate} to ${formattedEndDate}`
}

export const getYearDateRange = (year) => {
  // Get the first day of the specified year
  const firstDayOfYear = dayjs(`${year}-01-01`)

  // Get the last day of the specified year
  const lastDayOfYear = firstDayOfYear.endOf("year")

  // Format the start and end dates in "MM-DD-YYYY"
  const formattedStartDate = firstDayOfYear.format("MM-DD-YYYY")
  const formattedEndDate = lastDayOfYear.format("MM-DD-YYYY")

  // Return the formatted date range
  return `${formattedStartDate} to ${formattedEndDate}`
}

export const monthString = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
}

export const getFormattedDayString = (
  Day,
  Month,
  Year,
  format = dateFormat
) => {
  try {
    if (Day < 1 || Day > 31) {
      return "Invalid Day"
    }

    if (Month < 1 || Month > 12) {
      return "Invalid month"
    }

    return dayjs(`${Year}-${Month}-${Day}`).format(format)
  } catch (error) {
    return "Invalid date"
  }
}

export const getFormattedWeekString = (Week, Month, Year) => {
  try {
    if (Week < 1 || Week > 5) {
      return "Invalid week"
    }

    if (Month < 1 || Month > 12) {
      return "Invalid month"
    }

    return `${Week}-${monthString[Month]}-${Year}`
  } catch (error) {
    return "Invalid date"
  }
}

export const getFormattedMonthString = (Month, Year) => {
  try {
    if (Month < 1 || Month > 12) {
      return "Invalid month"
    }

    return `${monthString[Month]}-${Year}`
  } catch (error) {
    return "Invalid date"
  }
}

export const getFormattedQuarterString = (Quarter, Year) => {
  try {
    if (Quarter < 1 || Quarter > 4) {
      return "Invalid Quarter"
    }

    return `${Quarter}-${Year}`
  } catch (error) {
    return "Invalid date"
  }
}
