export const LineChartData = [
  {
    id: "Forecasted",
    color: "rgba(0, 158, 247, 1)",
    data: [
      { x: "Jan-01", y: 1000 },
      { x: "Feb-01", y: 2200 },
      { x: "Mar-01", y: 4400 },
      { x: "Apr-01", y: 2600 },
      { x: "May-01", y: 3300 },
      { x: "Jun-01", y: 1100 },
      { x: "Jul-01", y: 2500 },
      { x: "Aug-01", y: 1700 },
      { x: "Sep-01", y: 2000 },
      { x: "Oct-01", y: 1300 },
      { x: "Nov-01", y: 4500 },
      { x: "Dec-01", y: 2700 },
    ],
  },
  {
    id: "Actual",
    color: "rgba(80, 205, 137, 1)",
    data: [
      { x: "Jan-01", y: 1200 },
      { x: "Feb-01", y: 5300 },
      { x: "Mar-01", y: 3500 },
      { x: "Apr-01", y: 2400 },
      { x: "May-01", y: 6200 },
      { x: "Jun-01", y: 7600 },
      { x: "Jul-01", y: 5800 },
      { x: "Aug-01", y: 2100 },
      { x: "Sep-01", y: 2400 },
      { x: "Oct-01", y: 5600 },
      { x: "Nov-01", y: 4800 },
      { x: "Dec-01", y: 3000 },
    ],
  },
]

export const LineChartParameters = {
  margin: {
    top: 30,
    right: 50,
    bottom: 30,
    left: 50,
  },
  xScale: {
    type: "point",
    format: "%m-%d",
    precision: "day",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: 12000,
    stacked: false,
    reverse: false,
    spacing: 0,
  },
  layers: [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "slices",
    "points",
    "legends",
    "mesh",
    "annotations",
  ],
  curve: "cardinal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Date",
    legendOffset: 36,
    legendPosition: "middle",
  },
  axisLeft: {
    tickValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
    legendOffset: -40,
    legendPosition: "middle",
    format: (value) => (value >= 0 ? value : ""),
  },
  enablePoints: false,
  enableGridX: false,
  enableGridY: true,
  gridYValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
  enableArea: false,
  colors: ["rgba(80, 205, 137, 1)", "rgba(0, 158, 247, 1)"],
  pointSize: 4,
  pointBorderWidth: 2,
  pointBorderColor: {
    from: "serieColor",
  },
  useMesh: true,
}
