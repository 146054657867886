import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import LinearProgressWithLabel from "../../../../ui/LinearProgressWithLabel"

const PaymentFailure = () => {
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timer)
          navigate("/subscription/packages")
          return 100
        }
        return prev + 20
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [navigate])

  return (
    <Stack
      padding="15px 30px 15px 30px"
      gap="20px"
      height="80vh"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="inherit" variant="h4">
        Payment Failed!
      </Typography>
      <Typography color="inherit" variant="body1">
        Unfortunately, your payment could not be processed.
      </Typography>
      <Box sx={{ minWidth: "300px", width: "40%", maxWidth: "700px" }}>
        <LinearProgressWithLabel
          color="inherit"
          variant="determinate"
          value={progress}
        />
      </Box>
      <Typography color="inherit" variant="body1">
        You’ll be redirected shortly
      </Typography>
    </Stack>
  )
}

export default PaymentFailure
