export const tooltipDataFallbackConfig = [
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Customers", format: "Number", color: "" },
  { name: "Transactions", format: "Number", color: "" },
  { name: "Average Ticket", format: "Currency", color: "" },
]
export const PDFFieldsFallbackConfig = [
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Customers", format: "Number", color: "" },
  { name: "Transactions", format: "Number", color: "" },
  { name: "Average Ticket", format: "Currency", color: "" },
]

export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}