import dayjs from "dayjs"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"
import { PieTooltipData } from "../constant/configFallback"

const parseNumber = (value) => {
  try {
    return typeof value === "number"
      ? value
      : parseFloat(value.replace(/[^0-9.]/g, ""))
  } catch (error) {
    return undefined
  }
}

export const transfromChartData = (input, tooltip_data) => {
  // TODO: Combine compared by case here itself
  if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  input.reportDataCurrent?.forEach((currentItem) => {
    const previousItem =
      input?.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

    let eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      tooltipData: {
        current: {},
        previous: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]),
        format: tooltipDataItem.format,
      }

      eachElement.tooltipData.previous[tooltipDataItem.name] = {
        value: parseNumber(previousItem[tooltipDataItem.name]),
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformDataComparedBy = (input, tooltip_data) => {
  if (!input) {
    console.error("Invalid input data structure:", input)
    return []
  }

  let data = []

  input?.forEach((currentItem) => {
    let eachElement = {
      id: currentItem.id,
      value: currentItem.value,
      tooltipData: {
        current: {},
      },
    }

    tooltip_data?.forEach((tooltipDataItem) => {
      eachElement.tooltipData.current[tooltipDataItem.name] = {
        value: parseNumber(currentItem[tooltipDataItem.name]),
        format: tooltipDataItem.format,
      }
    })

    data.push(eachElement)
  })

  return data
}

export const transformExportData = (apiResponse, extractedParameters = {}) => {
  const transformedExportData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedExportData
  }

  const currentLength = currentData.length

  const tooltip_data = extractedParameters.tooltip_data || PieTooltipData

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"]), // Updated line
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedExportData.push(transformedWeek)
  }

  return transformedExportData
}
