import { useState } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MoreIcon from "@mui/icons-material/MoreVert"
import Container from "@mui/material/Container"
import SearchBar from "./search_bar/Searchbar"
import Insights from "./insights/Insights"
import Profile from "./profile/Profile"
import { useTheme } from "../../../contexts/theme"
import Logo from "../../../assets/svgs/Logo"
import useMediaQuery from "@mui/material/useMediaQuery"
import Button from "@mui/material/Button"
import Badge from "@mui/material/Badge"
import { useDispatch } from "react-redux"

export default function Primary({
  handleLogout,
  handleSideBarOpen,
  handleSideBarClose,
  handleSideBarToggle,
  handleSideBarUpdate,
}) {
  const { theme } = useTheme()
  const dispatch = useDispatch()

  const svgStrokeColor =
    theme.palette.mode === "light" ? theme.palette.accent : "white"

  const xlBreakpoint = useMediaQuery(theme.breakpoints.down("xl"))

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const [searchOpen, setSearchOpen] = useState(false)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{ top: "55px" }}
      PaperProps={{
        sx: {
          backgroundColor:
            theme.palette.mode === "light" ? "#FFFFFF" : "#0D2266",
          paddingInline: "5px",
          alignItems: "center",
          justifyItems: "center",
        },
      }}
    >
      {/* <ThemeToggleButton /> */}
      <Box display="flex" alignItems="center" justifyContent="space-around">
        <Insights />
        <Button
          size="large"
          aria-label={`show ${0} new notifications`}
          color="inherit"
          onClick={() => {
            handleSideBarUpdate("Notifictions", {
              type: "comingSoon",
              data: null,
            })
            handleSideBarToggle("Notifictions")
          }}
        >
          <Badge badgeContent={0} color="error">
            <svg
              width="21"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M6.16699 18C6.82202 19.7478 8.58945 21 10.667 21C12.7445 21 14.512 19.7478 15.167 18"
                stroke={svgStrokeColor}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                stroke={svgStrokeColor}
                d="M17.4161 8.70957V8.00497C17.4161 4.13623 14.3944 1 10.667 1C6.93955 1 3.91786 4.13623 3.91786 8.00497V8.70957C3.91786 9.5552 3.67671 10.3818 3.22479 11.0854L2.11735 12.8095C1.10581 14.3843 1.87804 16.5249 3.63735 17.0229C8.23973 18.3257 13.0943 18.3257 17.6966 17.0229C19.456 16.5249 20.2282 14.3843 19.2166 12.8095L18.1092 11.0854C17.6573 10.3818 17.4161 9.5552 17.4161 8.70957Z"
                strokeWidth="2"
              />
            </svg>
          </Badge>
        </Button>
        {/* <Notification /> */}
      </Box>
    </Menu>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          background:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 1)"
              : "linear-gradient(89.79deg, #071339 26.38%, #153393 82.56%)",
        }}
      >
        <Container maxWidth="xl">
          {theme.palette.mode === "light" ? (
            <svg
              style={{
                position: "absolute",
                right: xlBreakpoint
                  ? "280px"
                  : "calc((100vw - 1115px) / 2 + 70px)",
              }}
              width="142"
              height="88"
              viewBox="0 0 142 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1506_9)">
                <path
                  opacity="0.3"
                  d="M203.486 -233.228C203.207 -232.773 174.413 -174.673 139.536 -104.08C104.66 -33.4868 75.9216 24.4776 75.6984 24.7956C75.531 25.0681 64.0915 9.3051 50.3642 -10.3192L25.3647 -45.9337H-3.31763H-32L-26.8104 -38.5746C-23.9645 -34.4862 -5.1033 -7.59361 15.0413 21.2069L51.7592 73.5838L50.8106 75.4917C49.4155 78.2627 44.3933 84.3499 41.2126 86.9847C37.9203 89.7557 32.34 93.2081 28.4897 94.7526C20.3425 98.1596 13.2556 99.4316 0.867542 99.8404L-8.33983 100.113V118.056V136L3.76926 135.955C16.9944 135.864 24.6951 135.319 32.3958 133.865C48.1879 130.912 61.1898 125.098 73.3547 115.558C85.0174 106.382 94.3922 95.0706 101.87 81.2609C105.162 75.2646 257 -232.955 257 -233.546C257 -233.864 248.518 -234 230.494 -234C205.885 -234 203.988 -233.955 203.486 -233.228Z"
                  fill="#BCCAF6"
                />
              </g>
              <defs>
                <clipPath id="clip0_1506_9">
                  <rect width="142" height="88" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              style={{
                position: "absolute",
                right: xlBreakpoint
                  ? "280px"
                  : "calc((100vw - 1115px) / 2 + 70px)",
              }}
              width="142"
              height="88"
              viewBox="0 0 142 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1506_4)">
                <path
                  d="M203.486 -233.228C203.207 -232.773 174.413 -174.673 139.536 -104.08C104.66 -33.4868 75.922 24.4776 75.698 24.7956C75.531 25.0681 64.0915 9.3051 50.3642 -10.3192L25.3647 -45.9337H-3.3176H-32L-26.8104 -38.5746C-23.9645 -34.4862 -5.1033 -7.59362 15.0413 21.2069L51.7592 73.5838L50.8106 75.4917C49.4155 78.2627 44.3933 84.3499 41.2126 86.9847C37.9203 89.7557 32.34 93.2081 28.4897 94.7526C20.3425 98.1596 13.2556 99.4316 0.8675 99.8404L-8.3398 100.113V118.056V136L3.7693 135.955C16.9944 135.864 24.6951 135.319 32.3958 133.865C48.1879 130.912 61.1898 125.098 73.355 115.558C85.017 106.382 94.392 95.0706 101.87 81.2609C105.162 75.2646 257 -232.955 257 -233.546C257 -233.864 248.518 -234 230.494 -234C205.885 -234 203.988 -233.955 203.486 -233.228Z"
                  fill="url(#paint0_linear_1506_4)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1506_4"
                  x1="-16"
                  y1="149.5"
                  x2="112.5"
                  y2="-234"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#071339" />
                  <stop offset="1" stopColor="#1D47CE" />
                </linearGradient>
                <clipPath id="clip0_1506_4">
                  <rect width="142" height="88" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}

          <Toolbar sx={{ height: "88px" }}>
            <Box display="flex">
              <Logo
                fill={theme.palette.text.primary}
                yfill={
                  theme.palette.mode === "light"
                    ? theme.palette.accent
                    : "white"
                }
                width="190"
                height="80"
              />
            </Box>
            {/* {!searchOpen && (
              <IconButton onClick={toggleSearchOpen} sx={{ p: "1px" }}>
                <AnalyticaInfoIcon mode={theme.palette.mode} />
              </IconButton>
            )} */}
            <Box display="flex" width="100%" gap={1} alignItems="center">
              {/* {searchOpen && <SearchBar setSearchOpen={setSearchOpen} />} */}
              <SearchBar
                setSearchOpen={setSearchOpen}
                handleSideBarOpen={handleSideBarOpen}
                handleSideBarClose={handleSideBarClose}
                handleSideBarToggle={handleSideBarToggle}
                handleSideBarUpdate={handleSideBarUpdate}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              {/* <ThemeToggleButton /> */}
              {/* <Insights count={1} /> */}
              {/* <Notification count={1} /> */}
              <Button
                size="large"
                aria-label={`show ${1} new notifications`}
                color="inherit"
                onClick={() => {
                  handleSideBarUpdate("Notifictions", {
                    type: "comingSoon",
                    data: null,
                  })
                  handleSideBarToggle("Notifictions")
                }}
              >
                <Badge badgeContent={1} color="error">
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M6.16699 18C6.82202 19.7478 8.58945 21 10.667 21C12.7445 21 14.512 19.7478 15.167 18"
                      stroke={svgStrokeColor}
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      stroke={svgStrokeColor}
                      d="M17.4161 8.70957V8.00497C17.4161 4.13623 14.3944 1 10.667 1C6.93955 1 3.91786 4.13623 3.91786 8.00497V8.70957C3.91786 9.5552 3.67671 10.3818 3.22479 11.0854L2.11735 12.8095C1.10581 14.3843 1.87804 16.5249 3.63735 17.0229C8.23973 18.3257 13.0943 18.3257 17.6966 17.0229C19.456 16.5249 20.2282 14.3843 19.2166 12.8095L18.1092 11.0854C17.6573 10.3818 17.4161 9.5552 17.4161 8.70957Z"
                      strokeWidth="2"
                    />
                  </svg>
                </Badge>
              </Button>
              <Profile handleLogout={handleLogout} />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              <Profile />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
    </Box>
  )
}
