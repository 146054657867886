import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import LinearProgressWithLabel from "../../../../ui/LinearProgressWithLabel"

const PaymentSuccess = () => {
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timer)

          const redirectUrl =
            {
              "Business Synopsis": "/businesssynopsis",
              "Sales Dashboard": "/sales",
              "Customers Dashboard": "/customers/dashboard",
              "Customers List": "/customers/allcustomers",
              "Products Dashboard": "/products/dashboard",
              "Products List": "/products/products",
              "Employees Dashboard": "/employees/dashboard",
              "Employees List": "/employees/allemployees",
              undefined: "/businesssynopsis",
              null: "/businesssynopsis",
            }[localStorage.getItem("defaultPage")] || "/businesssynopsis"

          navigate(redirectUrl)
          return 100
        }
        return prev + 20
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [navigate])

  return (
    <Stack
      padding="15px 30px 15px 30px"
      gap="20px"
      height="80vh"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="inherit" variant="h4">
        Payment Successful!
      </Typography>
      <Typography color="inherit" variant="body1">
        Your payment has been processed successfully.
      </Typography>
      <Box sx={{ minWidth: "300px", width: "40%", maxWidth: "700px" }}>
        <LinearProgressWithLabel
          color="inherit"
          variant="determinate"
          value={progress}
        />
      </Box>
      <Typography color="inherit" variant="body1">
        You’ll be redirected shortly
      </Typography>
    </Stack>
  )
}

export default PaymentSuccess
