import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"

export const CustomPaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Typography
        variant="body2"
        color="text.primary"
        sx={{ whiteSpace: "nowrap" }}
      >
        {`${page * rowsPerPage + 1}-${Math.min(
          count,
          (page + 1) * rowsPerPage
        )} of ${count}`}
      </Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  )
}
