import {
  StreamChartData,
  StreamChartParameters,
} from "../../../constants/chartsData"
import Box from "@mui/material/Box"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import InfoToolTip from "../../InfoToolTip"
import { useTheme } from "../../../contexts/theme"
import StreamChartPresentation from "./StreamChartPresentation"
import { legendData } from "./constant/configFallBack"

const StreamChartContainer = ({
  title,
  description,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
      </Box>

      <StreamChartPresentation
        chartInput={StreamChartData}
        parameters={StreamChartParameters}
        legendsData={legendData}
        reportTitle={title}
        reportData={StreamChartData}
        showAiDropdown={showAiDropdown}
      />
    </>
  )
}

export default StreamChartContainer
