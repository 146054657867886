import { createSlice } from "@reduxjs/toolkit"

export const salesSlice = createSlice({
  name: "sales",
  initialState: {
    sales: null,
    sales_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { sales, sales_uid, reports } = action.payload
      state.sales = sales
      state.sales_uid = sales_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const {
        reportUid,
        response,
        apiResponse,
        aiResponse,
        transformedChartData,
        transformedExportData,
        comparisonPeriod,
        viewBy,
        cacheKey,
        transformedPDFExportData,
        transformedDataOne,
        transformedDataTwo,
      } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )

      if (selectedReport) {
        if (response) selectedReport.reportData = response
        if (apiResponse) selectedReport.apiResponse = apiResponse
        if (aiResponse) selectedReport.aiResponse = aiResponse
        if (transformedChartData)
          selectedReport.transformedChartData = transformedChartData
        if (transformedDataOne)
          selectedReport.transformedDataOne = transformedDataOne
        if (transformedDataTwo)
          selectedReport.transformedDataTwo = transformedDataTwo
        if (transformedExportData)
          selectedReport.transformedExportData = transformedExportData
        if (transformedPDFExportData)
          selectedReport.transformedPDFExportData = transformedPDFExportData
        if (cacheKey) selectedReport.cacheKey = cacheKey
        if (comparisonPeriod) {
          selectedReport.comparisonPeriod = comparisonPeriod
        }
        if (viewBy) {
          selectedReport.viewBy = viewBy
        }
      }
    },
    SalesCacheKeyClear: (state) => {
      state.reports.forEach((report) => {
        report.cacheKey = null
      })
    },
  },
})

export const { loadData, setChartData, SalesCacheKeyClear } = salesSlice.actions

export default salesSlice.reducer
