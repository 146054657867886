import { postData } from "../../../../api/api"
import { SwarmPlotData } from "../constants/dataFallBack"

export const fetchData = async (
  postUrl,
  combinedState,
  reportUid,
  dispatch,
  transfromChartData,
  setApiResponse,
  setForpdftabledata,
  setChartData,
  setLoading,
  setError,
  cacheKey
) => {
  try {
    setLoading(true)

    const response = reportUid
      ? await postData(postUrl, {
          entityDb: localStorage.getItem("entityDb"),
          fromDate: combinedState.dateRangeData.fromDate,
          toDate: combinedState.dateRangeData.toDate,
          reportInstanceId: reportUid,
          comparedBy: {
            previousFromDate: combinedState.comparisonPeriod.previousFromDate,
            previousToDate: combinedState.comparisonPeriod.previousToDate,
          },
        })
      : SwarmPlotData

    const updatedData = transfromChartData(response)
    setApiResponse(updatedData)
    setForpdftabledata(response)

    dispatch(
      setChartData({
        reportUid,
        response: updatedData,
        cacheKey: cacheKey,
        apiResponse: response,
      })
    )
  } catch (error) {
    setError("Inadequate data points to generate a report")
    setApiResponse(SwarmPlotData)
    dispatch(
      setChartData({
        reportUid,
        response: SwarmPlotData,
        cacheKey: cacheKey,
      })
    )
  } finally {
    setLoading(false)
  }
}
