import { Box } from "@mui/material"
import { ResponsiveSwarmPlot } from "@nivo/swarmplot"
import React from "react"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"

const SwarmPlotPresentation = ({
  chartInput = [],
  chartConfig,
  reportTitle,
  reportData,
  showAiDropdown,
  showTooltipPrevious,
}) => {
  const {
    groups,
    identity,
    value,
    valueFormat,
    valueScale,
    size,
    spacing,
    gap,
    forceStrength,
    simulationIterations,
    colorBy,
    borderColor,
    margin,
    enableGridY,
    axisTop,
    axisRight,
    axisBottom,
    axisLeft,
  } = chartConfig
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }
  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box sx={{ height: "350px" }}>
        <ResponsiveSwarmPlot
          data={chartInput}
          theme={customTheme}
          groups={groups}
          identity={identity}
          value={value}
          valueFormat={valueFormat}
          valueScale={valueScale}
          size={size}
          spacing={spacing}
          gap={gap}
          forceStrength={forceStrength}
          simulationIterations={simulationIterations}
          colorBy={colorBy}
          borderColor={borderColor}
          margin={margin}
          enableGridY={enableGridY}
          axisTop={axisTop}
          axisRight={axisRight}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          tooltip={(point) => (
            <CustomTooltip
              data={point?.data || {}}
              showTooltipPrevious={showTooltipPrevious}
            />
          )}
        />
      </Box>
      <CustomLegend
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default SwarmPlotPresentation
