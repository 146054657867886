import { useState } from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import OutlinedInput from "@mui/material/OutlinedInput"
import CloseIcon from "@mui/icons-material/Close"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CustomPaper from "../../../CustomPaper"
import { useTheme } from "../../../../contexts/theme"
import AnalyticaInfoIcon from "../../../../assets/svgs/AnalyticaInfoIcon"
import {
  postData,
  aiFeedbackCreate,
  aiFeedbackUpdate,
} from "../../../../api/api"
import CircularProgress from "@mui/material/CircularProgress"
import ThumbsDown from "@mui/icons-material/ThumbDownAlt"
import ThumbsUp from "@mui/icons-material/ThumbUpAlt"
import TextField from "@mui/material/TextField"
import SendIcon from "@mui/icons-material/Send"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"

const Searchbar = ({ setSearchOpen }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { theme } = useTheme()
  const [aiModal, setAiModal] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [analysis, setAnalysis] = useState([])
  const [recommendation, setRecommendation] = useState([])
  const [annomiles, setAnnomiles] = useState([])
  const [loading, setLoading] = useState(true)

  const handleAiModalOpen = () => setAiModal(true)
  const handleAiModalClose = () => {
    setAiModal(false)
    setSearchTerm("")
    setSearchOpen(false)
  }

  const fetchSearchResults = async () => {
    if (searchTerm.trim() !== "") {
      handleAiModalOpen()
      setLoading(true)

      try {
        const response = await postData("/copilot/liveassistant", {
          userPrompt: searchTerm,
          data: [],
          //entityDb: localStorage.getItem("entityDb"),
        })

        if (response?.success && response?.msg) {
          // Replace specific headers and unwanted line breaks
          // const cleanedMsg = response.msg
          //   .replace(/Analysis:/g, "###")
          //   .replace(/Recommendation:/g, "###")
          //   .replace(/Anomalies:/g, "###")
          //   .replace(/[\r\n]+/g, "\n")

          // const finalMsg = cleanedMsg.split("###")

          // const analysis = finalMsg[1]?.trim() || ""
          // const recommendation = finalMsg[2]?.trim() || ""
          // const anomalies = finalMsg[3]?.trim() || ""

          const analysis = response?.msg?.[0].Analysis?.trim() || ""
          const recommendation =
            response?.msg?.[1].Recommendations?.trim() || ""
          const anomalies = response?.msg?.[2].Anomalies?.trim() || ""

          const fname = localStorage.getItem("fname") || ""
          const lname = localStorage.getItem("lname") || ""
          const fullName = `${fname} ${lname}`
          const userData = JSON.parse(localStorage.getItem("user"))
          const userId = userData?.userId || null

          setSearchResults([response.msg])
          setAnalysis(analysis)
          setRecommendation(recommendation)
          setAnnomiles(anomalies)

          let feedbackCreationData = {
            userID: userId || 1,
            entityID: localStorage.getItem("entityId"),
            parentURL: window.location.href,
            question: searchTerm,
            fullAnswer:
              "Analysis: " +
              analysis?.replace(/'/g, "") +
              "/n" +
              "Recommendations: " +
              recommendation?.replace(/'/g, "") +
              "/n" +
              "Anomalies: " +
              anomalies?.replace(/'/g, ""),
            answerInsights: analysis?.replace(/'/g, ""),
            answerRecommendations: recommendation?.replace(/'/g, ""),
            answerAnomalies: anomalies?.replace(/'/g, ""),
            userName: fullName,
          }

          aiFeedbackCreate(feedbackCreationData).then(
            (feedbackCreationResponse) =>
              setCreateFeedbackResponse(feedbackCreationResponse)
          )
        } else {
          console.error("Unexpected response structure:", response)
        }
      } catch (error) {
        if (error.response) {
          console.error("API Error:", error.response.data)
        } else {
          console.error("Error:", error.message)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchSearchResults()
    }
  }

  const handleFaqClick = (question) => {
    setSearchTerm(question)
    fetchSearchResults()
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const [createFeedbackResponse, setCreateFeedbackResponse] = useState(false)

  // analysis-start
  const [showAnalysisText, setAnalysisShowText] = useState(false)
  const [analysisfeedback, setAnalysisFeedback] = useState(null)
  const [analysisfeedbackText, setAnalysisFeedbackText] = useState("")
  const [analysisLoading, setAnalysisLoading] = useState(false)
  const [analysisFeedbackMessage, setAnalysisFeedbackMessage] = useState(false)
  const [analysisAlertOpen, setAnalysisAlertOpen] = useState(false)

  const handleAnalysisFeedback = (event, value) => {
    setAnalysisFeedback(value)
    setAnalysisShowText(!!value)
  }
  // analysis-end

  // recommendation-start
  const [showRecommendationText, setRecommendationShowText] = useState(false)
  const [recommendationfeedback, setRecommendationFeedback] = useState(null)
  const [recommendationfeedbackText, setRecommendationFeedbackText] =
    useState("")
  const [recommendationLoading, setRecommendationLoading] = useState(false)
  const [recommendationFeedbackMessage, setRecommendationFeedbackMessage] =
    useState(false)
  const [recommendationAlertOpen, setRecommendationAlertOpen] = useState(false)

  const handleRecommendationFeedback = (event, value) => {
    setRecommendationFeedback(value)
    setRecommendationShowText(!!value)
  }
  // recommendation-end

  // anomaly-start
  const [showAnomalyText, setAnomalyShowText] = useState(false)
  const [anomalyfeedback, setAnomalyFeedback] = useState(null)
  const [anomalyfeedbackText, setAnomalyFeedbackText] = useState("")
  const [anomalyLoading, setAnomalyLoading] = useState(false)
  const [anomalyFeedbackMessage, setAnomalyFeedbackMessage] = useState(false)
  const [anomalyAlertOpen, setAnomalyAlertOpen] = useState(false)

  const handleAnomalyFeedback = (event, value) => {
    setAnomalyFeedback(value)
    setAnomalyShowText(!!value)
  }
  // anomaly-end

  const handleFeedbackUpdate = (type, thumbs, comment) => {
    const enableLoading = {
      1: () => {
        setAnalysisLoading(true)
        setAnalysisFeedbackMessage(false)
      },
      2: () => {
        setRecommendationLoading(true)
        setRecommendationFeedbackMessage(false)
      },
      3: () => {
        setAnomalyLoading(true)
        setAnomalyFeedbackMessage(false)
      },
    }

    const disbaleLoading = {
      1: (success) => {
        setAnalysisLoading(false)
        setAnalysisShowText(false)
        setAnalysisFeedback(null)
        setAnalysisFeedbackText("")
        setAnalysisFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnalysisAlertOpen(true)
      },
      2: (success) => {
        setRecommendationLoading(false)
        setRecommendationShowText(false)
        setRecommendationFeedback(null)
        setRecommendationFeedbackText("")
        setRecommendationFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setRecommendationAlertOpen(true)
      },
      3: (success) => {
        setAnomalyLoading(false)
        setAnomalyShowText(false)
        setAnomalyFeedback(null)
        setAnomalyFeedbackText("")
        setAnomalyFeedbackMessage(
          success
            ? {
                severity: "success",
                message: "Your Feedback was submitted Successfully! Thankyou!",
              }
            : {
                severity: "warning",
                message:
                  "Something went wrong while submitting this feedback...",
              }
        )
        setAnomalyAlertOpen(true)
      },
    }

    enableLoading[type]()

    const feedbackId = createFeedbackResponse?.msg?.Id
    const like = thumbs === "like"

    if (feedbackId && type) {
      aiFeedbackUpdate(type, feedbackId, like, comment)
        .then(
          (feedbackUpdationResponse) => {
            disbaleLoading[type](true)
          }
          // Todo: Handle after successfull update
        )
        .catch((error) => {
          disbaleLoading[type](false)
        })
    } else {
      console.log("Error while submitting feedback")
    }
  }

  const [searchEnabled, setSearchEnabled] = useState(
    localStorage.getItem("dataStatus")?.trim() === "Ready"
  )

  return (
    <>
      <Modal
        open={aiModal}
        onClose={handleAiModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <CustomPaper
          customStyles={{
            position: "fixed",
            width: "50vw",
            minWidth: "400px",
            minHeight: "100vh",
            overflowY: "auto",
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                : "#FBFBFE",
            boxShadow: 24,
            pt: 0,
          }}
        >
          <Stack spacing={1}>
            <Stack
              spacing={2}
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 5,
                background:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(75.66deg, #153290 1.45%, #0D2266 95.77%)"
                    : "#FBFBFE",
                pt: "1.2rem",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
              >
                <Box>
                  <AnalyticaInfoIcon mode={theme.palette.mode} />
                </Box>
                <IconButton onClick={handleAiModalClose}>
                  <CloseIcon
                    sx={{
                      color: theme.palette.accent,
                    }}
                  />
                </IconButton>
              </Box>
              <OutlinedInput
                name="searchTerm"
                value={searchTerm}
                fullWidth
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder="Ask question to Livelytics AI assistant"
                sx={{
                  outline: "1px solid #6382E9",
                  borderRadius: "12px",
                  height: "40px",
                  minWidth: "240px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#0D2266" : "#1b4cf00a",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Box spacing={1} mr="-5px">
                      <IconButton>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00015 9.33203C9.10682 9.33203 10.0002 8.4387 10.0002 7.33203V3.33203C10.0002 2.22536 9.10682 1.33203 8.00015 1.33203C6.89349 1.33203 6.00015 2.22536 6.00015 3.33203V7.33203C6.00015 8.4387 6.89349 9.33203 8.00015 9.33203ZM11.9402 7.33203C11.6135 7.33203 11.3402 7.57203 11.2868 7.8987C11.0135 9.46537 9.64682 10.6654 8.00015 10.6654C6.35349 10.6654 4.98682 9.46537 4.71349 7.8987C4.66015 7.57203 4.38682 7.33203 4.06015 7.33203C3.65349 7.33203 3.33349 7.69203 3.39349 8.09203C3.72015 10.092 5.32015 11.6587 7.33349 11.9454V13.332C7.33349 13.6987 7.63349 13.9987 8.00015 13.9987C8.36682 13.9987 8.66682 13.6987 8.66682 13.332V11.9454C10.6802 11.6587 12.2802 10.092 12.6068 8.09203C12.6735 7.69203 12.3468 7.33203 11.9402 7.33203Z"
                            fill={theme.palette.accent}
                          />
                        </svg>
                      </IconButton>
                      <IconButton onClick={fetchSearchResults}>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z"
                            fill={theme.palette.accent}
                          />
                        </svg>
                      </IconButton>
                    </Box>
                  </InputAdornment>
                }
              />
            </Stack>
            <Accordion
              elevation={0}
              sx={{
                "&:before": { backgroundColor: "transparent" },
                borderRadius: "10px",
                boxShadow: "unset",
                bgcolor:
                  theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#A4B5E9" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography color="inherit" variant="body1">
                  Frequently Asked Questions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection={{ sm: "row", md: "column" }}
                  gap={1}
                  flexWrap="wrap"
                  marginTop="-9px"
                >
                  {[
                    "What were my top-selling items last week compared to this week?",
                    "How did the weather affect my sales this week?",
                    "Are there any significant changes in customer demographics between this week and last week?",
                    "How effective were the marketing campaigns run this week compared to last week?",
                    "What are the peak hours of sales this week versus last week?",
                  ].map((question, idx) => (
                    <CustomPaper
                      key={idx}
                      customStyles={{
                        cursor: "pointer",
                        flex: 1,
                        outline: "1px solid #1C45C9",
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? "#071339"
                            : "#1b4cf00a",
                        "&:hover": { outline: 1, outlineColor: "#6382E9" },
                      }}
                      onClick={() => handleFaqClick(question)}
                    >
                      <Typography variant="body2" color="inherit">
                        {question}
                      </Typography>
                    </CustomPaper>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            <CustomPaper
              customStyles={{
                bgcolor:
                  theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
              }}
              innerCustomStyles={{ bgcolor: "unset" }}
            >
              <Typography mb={1} color="inherit" variant="body1">
                Results
              </Typography>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                >
                  <CircularProgress disableShrink size={45} color="info" />
                </Box>
              ) : searchResults.length > 0 ? (
                searchResults.map((msg, idx) => (
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background: "transparent",
                      border: "unset",
                      boxShadow: "unset",
                    }}
                    innerCustomStyles={{
                      border: "none",
                      borderRadius: "10px",
                    }}
                  >
                    <Accordion
                      defaultExpanded
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        background: "unset",
                        boxShadow: "unset",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        display="flex"
                        flexDirection="row"
                        alignContent="center"
                        sx={{
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          },
                          m: 0,
                          p: 0,
                        }}
                      >
                        <Box
                          height="12px"
                          width="12px"
                          borderRadius="4px"
                          bgcolor="#FCBF49"
                        />
                        <Typography variant="body1" color="inherit">
                          Analysis
                        </Typography>
                        <Box
                          height="1px"
                          flexGrow={1}
                          sx={{ bgcolor: theme.palette.divider }}
                        />
                      </AccordionSummary>
                      <AccordionDetails sx={{ m: 0, p: 0 }}>
                        <Alert
                          severity="warning"
                          sx={{
                            borderLeft: `4px solid #FCBF49`,
                            borderRadius: "10px",
                          }}
                          icon={false}
                        >
                          <Stack display="flex" flexDirection="column" gap={1}>
                            <Typography
                              variant="body2"
                              color="inherit"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {analysis}
                            </Typography>
                            {createFeedbackResponse && (
                              <>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {!!analysisFeedbackMessage ? (
                                    <Collapse in={analysisAlertOpen}>
                                      <Alert
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setAnalysisAlertOpen(false)
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                        variant="outlined"
                                        severity={
                                          analysisFeedbackMessage?.severity
                                        }
                                      >
                                        {analysisFeedbackMessage?.message}
                                      </Alert>
                                    </Collapse>
                                  ) : (
                                    <div />
                                  )}
                                  <ToggleButtonGroup
                                    value={analysisfeedback}
                                    exclusive
                                    onChange={handleAnalysisFeedback}
                                    aria-label="feedback buttons"
                                  >
                                    <ToggleButton
                                      value="like"
                                      aria-label="good feedback"
                                    >
                                      <ThumbsUp fontSize="small" />
                                    </ToggleButton>
                                    <ToggleButton
                                      value="dislike"
                                      aria-label="bad feedback"
                                    >
                                      <ThumbsDown fontSize="small" />
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                                {showAnalysisText && (
                                  <TextField
                                    disabled={analysisLoading}
                                    hiddenLabel
                                    size="small"
                                    multiline
                                    rows={2}
                                    value={analysisfeedbackText}
                                    onChange={(e) =>
                                      setAnalysisFeedbackText(e.target.value)
                                    }
                                    placeholder="Please let us know your feedback on this response."
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: "0.8rem",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "0.8rem",
                                      },
                                      minWidth: "300px",
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            disabled={
                                              analysisfeedback === "dislike" &&
                                              ["", null, undefined].includes(
                                                analysisfeedbackText
                                              )
                                            }
                                            onClick={() =>
                                              handleFeedbackUpdate(
                                                1,
                                                analysisfeedback,
                                                analysisfeedbackText
                                              )
                                            }
                                            sx={{ borderRadius: "8px" }}
                                          >
                                            {analysisLoading ? (
                                              <CircularProgress
                                                disableShrink
                                                size="20px"
                                                sx={{
                                                  color:
                                                    theme.palette.mode ===
                                                    "dark"
                                                      ? "#FFFFFF"
                                                      : "#707070",
                                                }}
                                              />
                                            ) : (
                                              <SendIcon fontSize="small" />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Stack>
                        </Alert>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        background: "unset",
                        boxShadow: "unset",
                        borderColor: "#1C45C9",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          px: 0,
                          mx: "20px",
                          borderTop: 1,
                          border: "unset",
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          },
                          m: 0,
                          p: 0,
                        }}
                      >
                        <Box
                          height="12px"
                          width="12px"
                          borderRadius="4px"
                          bgcolor="#50CD89"
                        />
                        <Typography variant="body1" color="inherit">
                          Recommendation
                        </Typography>
                        <Box
                          height="1px"
                          flexGrow={1}
                          sx={{ bgcolor: theme.palette.divider }}
                        />
                      </AccordionSummary>
                      <AccordionDetails sx={{ m: 0, p: 0 }}>
                        <Alert
                          severity="success"
                          sx={{
                            borderLeft: `4px solid #50CD89`,
                            borderRadius: "10px",
                          }}
                          icon={false}
                        >
                          <Stack display="flex" flexDirection="column" gap={1}>
                            <Typography
                              variant="body2"
                              color="inherit"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {recommendation}
                            </Typography>
                            {createFeedbackResponse && (
                              <>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {!!recommendationFeedbackMessage ? (
                                    <Collapse in={recommendationAlertOpen}>
                                      <Alert
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setRecommendationAlertOpen(false)
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                        variant="outlined"
                                        severity={
                                          recommendationFeedbackMessage.severity
                                        }
                                      >
                                        {recommendationFeedbackMessage.message}
                                      </Alert>
                                    </Collapse>
                                  ) : (
                                    <div />
                                  )}
                                  <ToggleButtonGroup
                                    value={recommendationfeedback}
                                    exclusive
                                    onChange={handleRecommendationFeedback}
                                    aria-label="feedback buttons"
                                  >
                                    <ToggleButton
                                      value="like"
                                      aria-label="good feedback"
                                    >
                                      <ThumbsUp fontSize="small" />
                                    </ToggleButton>
                                    <ToggleButton
                                      value="dislike"
                                      aria-label="bad feedback"
                                    >
                                      <ThumbsDown fontSize="small" />
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                                {showRecommendationText && (
                                  <TextField
                                    disabled={recommendationLoading}
                                    hiddenLabel
                                    size="small"
                                    multiline
                                    rows={2}
                                    value={recommendationfeedbackText}
                                    onChange={(e) =>
                                      setRecommendationFeedbackText(
                                        e.target.value
                                      )
                                    }
                                    placeholder="Please let us know your feedback on this response."
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: "0.8rem",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "0.8rem",
                                      },
                                      minWidth: "300px",
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            disabled={
                                              recommendationfeedback ===
                                                "dislike" &&
                                              ["", null, undefined].includes(
                                                recommendationfeedbackText
                                              )
                                            }
                                            onClick={() =>
                                              handleFeedbackUpdate(
                                                2,
                                                recommendationfeedback,
                                                recommendationfeedbackText
                                              )
                                            }
                                            sx={{ borderRadius: "8px" }}
                                          >
                                            {recommendationLoading ? (
                                              <CircularProgress
                                                disableShrink
                                                size="20px"
                                                sx={{
                                                  color:
                                                    theme.palette.mode ===
                                                    "dark"
                                                      ? "#FFFFFF"
                                                      : "#707070",
                                                }}
                                              />
                                            ) : (
                                              <SendIcon fontSize="small" />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Stack>
                        </Alert>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      sx={{
                        "&:before": { backgroundColor: "transparent" },
                        background: "unset",
                        boxShadow: "unset",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          px: 0,
                          mx: "20px",
                          borderTop: 1,
                          border: "unset",
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          },
                          m: 0,
                          p: 0,
                        }}
                      >
                        <Box
                          height="12px"
                          width="12px"
                          borderRadius="4px"
                          bgcolor="#FF0000"
                        />
                        <Typography variant="body1" color="inherit">
                          Anomalies
                        </Typography>
                        <Box
                          height="1px"
                          flexGrow={1}
                          sx={{ bgcolor: theme.palette.divider }}
                        />
                      </AccordionSummary>
                      <AccordionDetails sx={{ m: 0, p: 0 }}>
                        <Alert
                          severity="error"
                          sx={{
                            borderLeft: `4px solid #FF0000`,
                            borderRadius: "10px",
                          }}
                          icon={false}
                        >
                          <Stack display="flex" flexDirection="column" gap={1}>
                            <Typography
                              variant="body2"
                              color="inherit"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {annomiles}
                            </Typography>
                            {createFeedbackResponse && (
                              <>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {!!anomalyFeedbackMessage ? (
                                    <Collapse in={anomalyAlertOpen}>
                                      <Alert
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setAnomalyAlertOpen(false)
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                        variant="outlined"
                                        severity={
                                          anomalyFeedbackMessage.severity
                                        }
                                      >
                                        {anomalyFeedbackMessage.message}
                                      </Alert>
                                    </Collapse>
                                  ) : (
                                    <div />
                                  )}
                                  <ToggleButtonGroup
                                    value={anomalyfeedback}
                                    exclusive
                                    onChange={handleAnomalyFeedback}
                                    aria-label="feedback buttons"
                                  >
                                    <ToggleButton
                                      value="like"
                                      aria-label="good feedback"
                                    >
                                      <ThumbsUp fontSize="small" />
                                    </ToggleButton>
                                    <ToggleButton
                                      value="dislike"
                                      aria-label="bad feedback"
                                    >
                                      <ThumbsDown fontSize="small" />
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                                {showAnomalyText && (
                                  <TextField
                                    disabled={anomalyLoading}
                                    hiddenLabel
                                    size="small"
                                    multiline
                                    rows={2}
                                    value={anomalyfeedbackText}
                                    onChange={(e) =>
                                      setAnomalyFeedbackText(e.target.value)
                                    }
                                    placeholder="Please let us know your feedback on this response."
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: "0.8rem",
                                      },
                                      "& .MuiFormLabel-root": {
                                        fontSize: "0.8rem",
                                      },
                                      minWidth: "300px",
                                      "& label.Mui-focused": {
                                        color: "none",
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            disabled={
                                              anomalyfeedback === "dislike" &&
                                              ["", null, undefined].includes(
                                                anomalyfeedbackText
                                              )
                                            }
                                            onClick={() =>
                                              handleFeedbackUpdate(
                                                3,
                                                anomalyfeedback,
                                                anomalyfeedbackText
                                              )
                                            }
                                            sx={{ borderRadius: "8px" }}
                                          >
                                            {anomalyLoading ? (
                                              <CircularProgress
                                                disableShrink
                                                size="20px"
                                                sx={{
                                                  color:
                                                    theme.palette.mode ===
                                                    "dark"
                                                      ? "#FFFFFF"
                                                      : "#707070",
                                                }}
                                              />
                                            ) : (
                                              <SendIcon fontSize="small" />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Stack>
                        </Alert>
                      </AccordionDetails>
                    </Accordion>
                  </CustomPaper>
                ))
              ) : (
                <Typography color="inherit">No results found</Typography>
              )}
            </CustomPaper>
          </Stack>
        </CustomPaper>
      </Modal>

      <Box
        position="relative"
        sx={{
          width: {
            xs: "200%", 
            sm: "200%", 
            md: "1000%", 
          },
          maxWidth: "1600px", 
          margin: "0 auto",
        }}
      >
        {!searchEnabled && (
          <Box
            width="150%"
            height="110%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                theme.palette.mode === "dark" ? "#00000085" : "#FFFFFFA0",
              position: "absolute",
              zIndex: 1,
              top: "-2px",
              left: "-2px",
              borderRadius: "8px",
            }}
          />
        )}
        <OutlinedInput
          id="search-bar"
          // id="minValue"
          // name="minValue"
          value={searchTerm}
          fullWidth
          disableUnderline
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="Ask AI or Search here..."
          inputProps={{
            disableUnderline: true,
            style: {
              fontSize: 13,
              border: "none",
              outline: "none",
              height: "0px",
            },
          }}
          sx={{
            "& fieldset": { border: "none" },
            outline: "1px solid #6382E9",
            borderRadius: "8px",
            height: "33px",
            maxWidth: "800px",
            width: "100%",
            minWidth: "300px",
            backgroundColor:
              theme.palette.mode === "dark" ? "#0D2266" : "#1b4cf00a",
            ":hover": {
              outline: `1px solid ${theme.palette.accent}`,
            },
          }}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{ position: "relative", left: "5px" }}
            >
              <Box spacing={1} mr="-5px">
                <IconButton onClick={() => setSearchOpen(false)}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00015 9.33203C9.10682 9.33203 10.0002 8.4387 10.0002 7.33203V3.33203C10.0002 2.22536 9.10682 1.33203 8.00015 1.33203C6.89349 1.33203 6.00015 2.22536 6.00015 3.33203V7.33203C6.00015 8.4387 6.89349 9.33203 8.00015 9.33203ZM11.9402 7.33203C11.6135 7.33203 11.3402 7.57203 11.2868 7.8987C11.0135 9.46537 9.64682 10.6654 8.00015 10.6654C6.35349 10.6654 4.98682 9.46537 4.71349 7.8987C4.66015 7.57203 4.38682 7.33203 4.06015 7.33203C3.65349 7.33203 3.33349 7.69203 3.39349 8.09203C3.72015 10.092 5.32015 11.6587 7.33349 11.9454V13.332C7.33349 13.6987 7.63349 13.9987 8.00015 13.9987C8.36682 13.9987 8.66682 13.6987 8.66682 13.332V11.9454C10.6802 11.6587 12.2802 10.092 12.6068 8.09203C12.6735 7.69203 12.3468 7.33203 11.9402 7.33203Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                </IconButton>
                <IconButton onClick={() => setSearchOpen(false)}>
                  <CloseIcon
                    sx={{ color: theme.palette.accent, fontSize: "1rem" }}
                  />
                  {/* <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z"
                  fill={theme.palette.accent}
                />
              </svg> */}
                </IconButton>
              </Box>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment
              position="start"
              sx={{ position: "relative", left: "-5px" }}
            >
              <Box spacing={1} mr="-5px">
                <AnalyticaInfoIcon
                  mode={theme.palette.mode}
                  height={24}
                  width={24}
                />
              </Box>
            </InputAdornment>
          }
        />
      </Box>
    </>
  )
}

export default Searchbar
