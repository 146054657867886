import dayjs from "dayjs"
import { getFormattedValue } from "../../../../utils/utils"
import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"

export const transformChartData = (
  apiResponse,
  isComparedBy,
  viewBy,
  config
) => {
  // TODO: remove static dateFormat
  const dateFormat = getDateFormatFromLocalStorage()
  const data = []

  if (["Day", "", undefined].includes(viewBy)) {
    isComparedBy
      ? apiResponse?.forEach((element) => {
          let eachElement = {
            day: element["Day"] || 0,
            barValue: element[config.keys]?.toFixed(2) || 0,
            date: element["DayMonth"] || 0,
            tooltipData: {
              currentDate: dayjs(element["DayMonth"]).format(dateFormat),
              current: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      : apiResponse.reportDataPrevious?.forEach((element, index) => {
          let eachElement = {
            day: apiResponse.reportDataCurrent[index]?.["Day"] || 0,
            date: apiResponse.reportDataCurrent[index]?.["DayMonth"],
            barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
            lineValue: element[config.keys] || 0,
            tooltipData: {
              currentDate: dayjs(
                apiResponse.reportDataCurrent[index]?.["DayMonth"]
              ).format(dateFormat),
              previousDate: dayjs(element["DayMonth"]).format(dateFormat),
              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
  } else if (viewBy === "Week") {
    if (isComparedBy) {
      apiResponse?.forEach((element) => {
        let startOfWeek = dayjs(
          element["WeekMonthYear"].split(" to ")[0]
        ).format(dateFormat)
        let endOfWeek = dayjs(element["WeekMonthYear"].split(" to ")[1]).format(
          dateFormat
        )

        let eachElement = {
          day: `${startOfWeek} to ${endOfWeek}` || 0,
          barValue: element[config.keys]?.toFixed(2) || 0,
          date: element["WeekMonthYear"] || 0,
          tooltipData: {
            currentDate: `${startOfWeek} to ${endOfWeek}`,
            current: {},
          },
        }

        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    } else {
      apiResponse.reportDataPrevious?.forEach((element, index) => {
        let currentStart = dayjs(
          apiResponse.reportDataCurrent[index]?.["WeekMonthYear"].split(
            " to "
          )[0]
        ).format(dateFormat)
        let currentEnd = dayjs(
          apiResponse.reportDataCurrent[index]?.["WeekMonthYear"].split(
            " to "
          )[1]
        ).format(dateFormat)

        let previousStart = dayjs(
          element?.["WeekMonthYear"].split(" to ")[0]
        ).format(dateFormat)
        let previousEnd = dayjs(
          element?.["WeekMonthYear"].split(" to ")[1]
        ).format(dateFormat)

        let eachElement = {
          day: apiResponse.reportDataCurrent[index]?.["WeekMonthYear1"] || 0,
          starDay: `${currentStart} to ${currentEnd}` || 0,
          currentDay: element?.["WeekMonthYear"],
          barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
          lineValue: element[config.keys] || 0,
          tooltipData: {
            currentDate: `${currentStart} to ${currentEnd}`,
            previousDate: `${previousStart} to ${previousEnd}`,
            current: {},
            previous: {},
          },
        }

        // Populate tooltip data for both current and previous periods
        config.tooltip_data?.forEach((tooltipDataItem) => {
          eachElement.tooltipData.current[tooltipDataItem.name] = {
            value: apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }

          eachElement.tooltipData.previous[tooltipDataItem.name] = {
            value: element[tooltipDataItem.name],
            format: tooltipDataItem.format,
          }
        })

        data.push(eachElement)
      })
    }
  } else if (viewBy === "Month") {
    isComparedBy
      ? apiResponse?.forEach((element) => {
          let eachElement = {
            day: element["MonthYear"] || 0,
            barValue: element[config.keys]?.toFixed(2) || 0,
            date: element["MonthYear"] || 0,
            tooltipData: {
              currentDate: dayjs(element["MonthYear"]).format(dateFormat),
              current: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      : apiResponse.reportDataPrevious.length > 1
      ? apiResponse.reportDataPrevious?.forEach((element, index) => {
          let eachElement = {
            day: apiResponse.reportDataCurrent[index]?.["MonthYear"] || 0,
            currentDay: element?.["MonthYear"],
            barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
            lineValue: element[config.keys] || 0,
            tooltipData: {
              currentDate: dayjs(
                apiResponse.reportDataCurrent[index]?.["MonthYear"]
              ).format(dateFormat),
              previousDate: dayjs(element["MonthYear"]).format(dateFormat),
              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      : apiResponse.reportDataPrevious?.forEach((element, index) => {
          let eachElement = {
            day: element?.["MonthYear"] || 0,
            date: element?.["MonthYear"] || 0,
            barValue: element?.[config.keys] || 0,
            tooltipData: {
              currentDate: dayjs(
                apiResponse.reportDataCurrent[index]?.["MonthYear"]
              ).format(dateFormat),
              previousDate: dayjs(element["MonthYear"]).format(dateFormat),
              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)

          let eachCurrentElement = {
            day: apiResponse.reportDataCurrent[index]?.["MonthYear"] || 0,
            date: apiResponse?.reportDataCurrent[index]?.["MonthYear"] || 0,
            barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
            tooltipData: {
              currentDate: dayjs(
                apiResponse.reportDataCurrent[index]?.["MonthYear"]
              ).format(dateFormat),
              previousDate: dayjs(element["MonthYear"]).format(dateFormat),
              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachCurrentElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachCurrentElement)
        })
  } else if (viewBy === "Quarter") {
    isComparedBy
      ? apiResponse?.forEach((element) => {
          let eachElement = {
            day: element["QuarterYear"] || 0,
            barValue: element[config.keys]?.toFixed(2) || 0,
            date: element["QuarterYear"] || 0,
            tooltipData: {
              currentDate: element["QuarterYear"],
              current: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      : apiResponse.reportDataPrevious?.forEach((element, index) => {
          let eachElement = {
            day: element?.["QuarterYear"] || 0,
            date: element?.["QuarterYear"] || 0,
            barValue: element?.[config.keys] || 0,
            tooltipData: {
              currentDate:
                apiResponse.reportDataCurrent[index]?.["QuarterYear"],
              previousDate: element["QuarterYear"],

              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)

          let eachCurrentElement = {
            day: apiResponse.reportDataCurrent[index]?.["QuarterYear"] || 0,
            // todo check QuarterYear
            date: apiResponse.reportDataCurrent[index]?.["QuarterYear"] || 0,
            barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
            tooltipData: {
              currentDate:
                apiResponse.reportDataCurrent[index]?.["QuarterYear"],
              previousDate: element["QuarterYear"],

              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachCurrentElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachCurrentElement)
        })
  } else if (viewBy === "Year") {
    isComparedBy
      ? apiResponse?.forEach((element) => {
          let eachElement = {
            day: element["Year"] || 0,
            barValue: element[config.keys] || 0,
            date: element["Year"] || 0,
            tooltipData: {
              currentDate: element["Year"],
              current: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      : apiResponse.reportDataPrevious?.forEach((element, index) => {
          let eachElement = {
            day: element?.["Year"] || 0,
            date: element?.["Year"] || 0,
            barValue: element?.[config.keys] || 0,
            tooltipData: {
              currentDate: apiResponse.reportDataCurrent[index]?.["Year"],
              previousDate: element["Year"],
              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)

          let eachCurrentElement = {
            day: apiResponse.reportDataCurrent[index]?.["Year"] || 0,
            date: apiResponse?.reportDataCurrent[index]?.["Year"] || 0,
            barValue: apiResponse.reportDataCurrent[index]?.[config.keys] || 0,
            tooltipData: {
              currentDate: apiResponse.reportDataCurrent[index]?.["Year"],
              previousDate: element["Year"],

              current: {},
              previous: {},
            },
          }

          config.tooltip_data?.forEach((tooltipDataItem) => {
            eachCurrentElement.tooltipData.current[tooltipDataItem.name] = {
              value:
                apiResponse.reportDataCurrent[index]?.[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }

            eachCurrentElement.tooltipData.previous[tooltipDataItem.name] = {
              value: element[tooltipDataItem.name],
              format: tooltipDataItem.format,
            }
          })

          data.push(eachCurrentElement)
        })
  }

  return data
}

export const transformDataPDFExport = (apiResponse, pdfFields) => {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const dateFormat = localStorage.getItem("dateFormat") || "MM-DD-YYYY"

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        dayjs(current["DayMonth"]).format(dateFormat),
    }

    pdfFields.forEach((pdfField) => {
      const name = pdfField.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            pdfField.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? pdfField.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : pdfField.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            pdfField.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const transformDataSheetExport = (data) => {
  // IMP-NOTE: It takes the output of transformChartData and not the raw API Data
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    const flatItem = {}

    Object.keys(item).forEach((key) => {
      const value = item[key]

      if (key === "tooltipData" && typeof value === "object") {
        Object.keys(value.current).forEach((subKey) => {
          const format = value?.current[subKey]?.format || null

          flatItem[`current ${subKey}`] = getFormattedValue(
            value.current[subKey]?.value,
            format
          )

          if (value.previous && value.previous[subKey]) {
            flatItem[`previous ${subKey}`] = getFormattedValue(
              value.previous[subKey]?.value,
              format
            )
          }
        })
      } else if (["undefined", "number", "string"].includes(typeof value)) {
        flatItem[key] = value
      }
    })

    return flatItem
  })
}
