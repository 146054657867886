import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Modal from "@mui/material/Modal"
import IconButton from "@mui/material/IconButton"
import CustomPaper from "../../../CustomPaper"
import CloseIconSubscription from "../../../../assets/svgs/CloseIconSubscription"
import { useTheme } from "../../../../contexts/theme"
import HeatmapPresentation from "../HeatmapPresentation"

const HeatMapChartDetails = ({
  open,
  handleClose,
  error,
  loading,
  chartInput,
  staticParameters,
}) => {
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const svgColor = theme.palette.accent

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "visible",
        }}
      >
        <CustomPaper
          customStyles={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 600,
              sm: 600,
              md: 600,
              lg: 500,
              xl: 1000,
            },
            minHeight: "65vh",
            minWidth: "65vw",
            overflow: "visible",
            background:
              ThemeMode === "dark"
                ? "var(--grade, linear-gradient(76deg, #153393 1.45%, #0D2266 95.77%))"
                : "#ffffff",
            boxShadow: "none",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="left"
            alignItems="left"
          >
            <IconButton onClick={handleClose}>
              <CloseIconSubscription svgColor={svgColor} />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              component="div"
              sx={{
                Weight: "700px",
                fontSize: "24px",
              }}
            >
              Total Transactions by Hour
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: "14px",
              }}
            >
              Please check the Total Transactions by Hour of selected Day
            </Typography>
          </Box>
          <>
            <Box minHeight="350px">
              {loading ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  alignContent="center"
                  gap="10px"
                  minHeight="350px"
                  width="100%"
                >
                  <CircularProgress disableShrink size={45} color="info" />
                  <Typography variant="body2" color="inherit">
                    Fetching data, please wait...
                  </Typography>
                </Box>
              ) : error ? (
                <HeatmapPresentation
                  chartConfig={staticParameters}
                  chartInput={chartInput}
                />
              ) : (
                <HeatmapPresentation
                  chartConfig={staticParameters}
                  chartInput={chartInput}
                />
              )}
            </Box>
          </>
        </CustomPaper>
      </Modal>
    </>
  )
}

export default HeatMapChartDetails
