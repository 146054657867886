import { ResponsiveBar } from "@nivo/bar"
import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { useTheme } from "../../../../../contexts/theme"
import VerifiedIcon from "@mui/icons-material/Verified"
import { line, curveCatmullRom } from "d3-shape"
import { useTooltip } from "@nivo/tooltip"
import NewReleasesIcon from "@mui/icons-material/NewReleases"

const BodySection = ({ config, data = [], maxValue }) => {
  const { theme } = useTheme()

  let {
    margin,
    padding,
    borderRadius,
    enableLabel,
    labelTextColor,
    indexBy,
    chart_colors,

    customTheme,
    performerFlag = { icon: false, legend: false },
    chartContainerDimensions = {
      height: "345px",
    },
    showTooltipPrevious = true,
  } = config

  const LowestPoint = ({ bars, yScale }) => {
    if (!bars) return null
    const lowestValue = Math.min(...data.map((d) => d.barValue))
    const lowestBar = bars.find((bar) => bar.data.value === lowestValue)

    if (!lowestBar) return null

    const x = lowestBar.x + lowestBar.width / 2
    const y = yScale(lowestValue)

    const colors = {
      dark: {
        fill: "#EC2D30",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#EC2D30",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <NewReleasesIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  const Line = ({ bars, yScale }) => {
    const { theme } = useTheme()

    const lineGenerator = line()
      .x((bar) => bar.x + bar.width / 2)
      .y((bar) => yScale(bar.data.data.lineValue || 0))
      .curve(curveCatmullRom.alpha(0.05))

    const tip = useTooltip()

    function renderTip(e, idx) {
      return tip.showTooltipFromEvent(
        <CustomTooltip
          data={data[idx]}
          showCurrentPrevDate={true}
          showCurrentPrevColors={{
            current: chart_colors?.bar?.[theme.palette.mode || "dark"],
            previous: chart_colors?.line?.[theme.palette.mode || "dark"],
          }}
          showDateRange={false}
          showTooltipPrevious={showTooltipPrevious}
        />,
        e
      )
    }

    return (
      <>
        {bars.some((bar) => data.find((it) => it.lineValue !== undefined)) && (
          <path
            d={lineGenerator(bars)}
            fill="none"
            stroke={chart_colors?.line?.[theme.palette.mode || "dark"]}
            strokeWidth={3}
            style={{ pointerEvents: "none" }}
          />
        )}

        {bars.map((bar, idx) => {
          const circleX = bar.x + bar.width / 2
          const circleY = yScale(data[idx].lineValue)

          return (
            data[idx].lineValue !== undefined && (
              <circle
                key={`circle-${bar.key}`}
                cx={circleX}
                cy={circleY}
                r={5}
                fill={
                  chart_colors?.lineCircle?.[theme.palette.mode || "dark"]?.fill
                }
                stroke={
                  chart_colors?.lineCircle?.[theme.palette.mode || "dark"]
                    ?.stroke
                }
                strokeWidth={3}
                onMouseEnter={(e) => renderTip(e, idx)}
                onMouseMove={(e) => renderTip(e, idx)}
                onMouseLeave={tip.hideTooltip}
              />
            )
          )
        })}
      </>
    )
  }

  const HighestPoint = ({ bars, yScale }) => {
    if (!bars) return null
    const highestValue = Math.max(...data.map((d) => d.barValue))
    const highestBar = bars.find((bar) => bar.data.value === highestValue)

    if (!highestBar) return null

    const x = highestBar.x + highestBar.width / 2
    const y = yScale(highestValue)

    const colors = {
      dark: {
        fill: "#23BF6F",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#23BF6F",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <VerifiedIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveBar
        data={data}
        theme={customTheme}
        keys={["barValue"]}
        indexBy={indexBy}
        margin={margin}
        tooltip={(point) => {
          return (
            <CustomTooltip
              data={point.data}
              showCurrentPrevDate={true}
              showCurrentPrevColors={{
                current: chart_colors?.bar?.[theme.palette.mode || "dark"],
                previous: chart_colors?.line?.[theme.palette.mode || "dark"],
              }}
              showDateRange={false}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }}
        padding={padding}
        borderRadius={borderRadius}
        enableLabel={enableLabel}
        colors={chart_colors?.bar?.[theme.palette.mode || "dark"]}
        maxValue={maxValue}
        labelTextColor={labelTextColor}
        layers={["grid", "axes", "bars", Line, HighestPoint, LowestPoint]}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sales",
          legendOffset: -50,
          legendPosition: "middle",
        }}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendOffset: 50,
          legendPosition: "middle",
        }}
      />
    </Box>
  )
}

export default BodySection
