import { forwardRef, useEffect, useState } from "react"
import html2canvas from "html2canvas"

import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import {
  Font,
  Page,
  Text,
  View,
  Document,
  Image,
  PDFViewer,
  StyleSheet,
  Svg,
} from "@react-pdf/renderer"
import Comfortaa from "../assets/fonts/Comfortaa-VariableFont_wght.ttf"
import dayjs from "dayjs"
import { Button } from "@mui/material"
import { useTheme } from "../contexts/theme"
import { getDateFormatFromLocalStorage } from "../utils/dateUtils"
import CustomPaper from "./CustomPaper"
import Logo from "../assets/svgs/logopng.png"

Font.register({
  family: "Comfortaa",
  format: "truetype",
  src: Comfortaa,
})

const ExportChartsData = forwardRef(
  (
    {
      open,
      setOpen,
      title,
      description,
      chartRef,
      tableData,
      combinedState,
      aiData,
      headerType,
      reportType = "",
    },
    ref
  ) => {
    const handleClose = () => setOpen(false)
    const { theme } = useTheme()
    const dateFormat = getDateFormatFromLocalStorage()
    const formattedPreviousFromDate = dayjs(
      combinedState.comparisonPeriod.previousFromDate
    ).format(dateFormat)
    const formattedPreviousToDate = dayjs(
      combinedState.comparisonPeriod.previousToDate
    ).format(dateFormat)
    const formattedCurrentFromDate = dayjs(
      combinedState.dateRangeData.fromDate
    ).format(dateFormat)
    const formattedCurrentToDate = dayjs(
      combinedState.dateRangeData.toDate
    ).format(dateFormat)

    const dynamicKeys =
      tableData.length > 0
        ? Object.keys(tableData[0]).filter((key) => key !== "date")
        : []

    const dateRange =
      dynamicKeys.length > 0 && tableData[0][dynamicKeys[0]]?.previous
        ? `Previous: ${formattedPreviousFromDate} - ${formattedPreviousToDate} Current: ${formattedCurrentFromDate} - ${formattedCurrentToDate}`
        : ` ${formattedCurrentFromDate} - ${formattedCurrentToDate}`

    const [imgData, setImgData] = useState("")

    const [isLoading, setIsLoading] = useState(true)
    const [orientation, setOrientation] = useState("portrait")

    const styles = StyleSheet.create({
      svgImage: {
        position: "absolute",
        right: 8,
        top: 6,
        width: 70,
        height: 27,
      },
      page: {
        flexDirection: "column",
        padding: 20,
        backgroundColor: "#E4E4E4",
      },
      section: {
        Analysis: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 10,
          borderLeft: "4px solid #FCBF49",
          width: "100%",
          fontSize: 10,
        },
        Recommendations: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 10,
          borderLeft: "4px solid #50CD89",
          width: "100%",
          fontSize: 10,
        },
        Anomalies: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 10,
          borderLeft: "4px solid #FF0000",
          width: "100%",
          fontSize: 10,
        },
      },
      table: {
        display: "table",
        width: "100%",
        borderWidth: 1,
        borderColor: "#ddd",
        borderStyle: "solid",
        borderRadius: 5,
        marginTop: 30,
        overflow: "hidden",
      },
      tableHeader: {
        flexDirection: "row",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
        fontSize: 10,
        borderColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
        borderBottomStyle: "solid",
      },
      tableRow: {
        flexDirection: "row",
        fontSize: 5,
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
        borderBottomStyle: "solid",
      },
      tableCell: {
        padding: 5,
        borderColor: "#dddddd",
        fontSize: orientation === "portrait" ? 5 : 8,
        textAlign: "center",
        flex: 1,
        borderRightWidth: 1,
        borderRightColor: "#ddd",
        borderRightStyle: "solid",
      },
      footer: {
        position: "absolute",
        bottom: 5,
        paddingBottom: 6,
        left: 20,
        right: 0,
        textAlign: "left",
        fontSize: 8,
        color: "grey",
      },
    })

    useEffect(() => {
      setIsLoading(true)
      const captureChart = async () => {
        if (chartRef.current) {
          try {
            const styles = {
              initial: {
                backgroundColor: chartRef.current.style.backgroundColor,
                padding: chartRef.current.style.padding,
                borderRadius: chartRef.current.style.borderRadius,
              },
              override: {
                backgroundColor: theme.palette.background.paper,
                padding: "30px",
                borderRadius: "15px",
              },
            }

            // Apply style overrides
            Object.entries(styles.override).forEach(([key, value]) => {
              chartRef.current.style[key] = value
            })

            // Hide specific children
            const childrenToHide =
              chartRef.current.querySelectorAll(".pdf-export-hidden")

            const childrenToHideInitialDisplay = []
            childrenToHide.forEach((child, idx) => {
              childrenToHideInitialDisplay[idx] = child.style.display
              child.style.display = "none"
            })

            // Capture the chart
            const canvas = await html2canvas(chartRef.current)
            const dataUrl = canvas.toDataURL("image/png")
            setImgData(dataUrl)

            // Restore original styles of the children
            childrenToHide.forEach((child, idx) => {
              child.style.display = childrenToHideInitialDisplay[idx]
            })

            // Restore original styles
            Object.entries(styles.initial).forEach(([key, value]) => {
              chartRef.current.style[key] = value
            })
            setIsLoading(false)
          } catch (error) {
            console.error("Error capturing the chart:", error)
            setIsLoading(false)
          }
          setIsLoading(false)
        }
      }

      if (open) {
        captureChart()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, chartRef])

    const [pageContent, setPageContent] = useState([])

    const PAGE_HEIGHT = 842
    const PAGE_MARGIN = 20
    const TABLE_ROW_HEIGHT = 10

    useEffect(() => {
      const calculatePageContent = () => {
        let totalHeight = PAGE_MARGIN
        const newPages = []
        let currentPageRows = []

        for (const row of tableData) {
          const rowHeight = TABLE_ROW_HEIGHT

          if (totalHeight + rowHeight > PAGE_HEIGHT) {
            newPages.push(currentPageRows)
            currentPageRows = []
            totalHeight = PAGE_MARGIN
          }
          currentPageRows.push(row)
          totalHeight += rowHeight
        }

        if (currentPageRows.length > 0) {
          newPages.push(currentPageRows)
        }

        setPageContent(newPages)
      }

      if (open) {
        calculatePageContent()
      }
    }, [open, tableData])
    const toggleOrientation = () => {
      setOrientation((prev) => (prev === "portrait" ? "landscape" : "portrait"))
    }
    return (
      <Modal
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <CustomPaper
            customStyles={{
              position: "absolute",
              width: "80vw",
              minHeight: {
                xs: "75vh",
                sm: "75vh",
                md: "80vh",
                lg: "80vh",
                xl: "65vh",
              },
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                  : "#FBFBFE",
              boxShadow: 24,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography variant="h6" color="inherit">
                Loading...
              </Typography>
            </Box>
          </CustomPaper>
        ) : (
          <CustomPaper
            customStyles={{
              position: "absolute",
              width: "80vw",
              minHeight: {
                xs: "75vh",
                sm: "75vh",
                md: "80vh ",
                lg: "80vh",
                xl: "65vh",
              },
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                  : "#FBFBFE",
              boxShadow: 24,
            }}
          >
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="h6" color="inherit">
                PDF Preview
              </Typography>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Button
                  sx={{
                    color: theme.palette.accent,
                  }}
                  onClick={toggleOrientation}
                >
                  {orientation === "portrait" ? "Landscape" : "Portrait"}
                </Button>
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon
                    sx={{
                      color: theme.palette.accent,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                iframe: {
                  borderRadius: "8px",
                  width: "100%",
                  height: "100%",
                  border: "none",
                },
              }}
            >
              <PDFViewer>
                <Document>
                  {pageContent.map((pageRows, pageIndex) => (
                    <Page
                      key={pageIndex}
                      size="A4"
                      orientation={orientation}
                      style={{
                        fontFamily: "Comfortaa",
                        fontWeight: "medium",
                        flexDirection: "column",
                        width: "100%",
                        padding: 20,
                        rowGap: 10,
                      }}
                    >
                      <Image
                        src={Logo}
                        style={styles.svgImage}
                        alt="Logo"
                        fixed
                      />

                      <View
                        style={{
                          width: "100%",
                          fontSize: 14,
                        }}
                      >
                        <Text>{title}</Text>
                      </View>
                      <View style={{ width: "100%", fontSize: 10 }}>
                        <Text>{`${
                          description ? description : ""
                        } (${dateRange})`}</Text>
                      </View>
                      {imgData && (
                        <Image
                          src={imgData}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      )}

                      <View style={styles.table}>
                        {/* Table Headers */}
                        <View
                          style={{ ...styles.tableHeader, fontsize: 15 }}
                          fixed
                        >
                          <Text
                            key={`date-header`}
                            style={{
                              ...styles.tableCell,
                              flex: 0.272,
                            }}
                          >
                            {headerType === "ID" ? "Name" : "Date"}
                          </Text>

                          {dynamicKeys.map((key) => (
                            <Text
                              key={`${key}-header`}
                              style={styles.tableCell}
                            >
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </Text>
                          ))}
                        </View>
                        {tableData[0][dynamicKeys[0]]?.previous && (
                          <View
                            style={{
                              ...styles.tableHeader,
                              fontsize: 8,
                            }}
                            fixed
                          >
                            <Text style={styles.tableCell}></Text>
                            {dynamicKeys.map((key) => (
                              <>
                                <Text
                                  key={`${key}-current`}
                                  style={styles.tableCell}
                                >
                                  {reportType === "dashline"
                                    ? "Actual"
                                    : "Current"}
                                </Text>
                                <Text
                                  key={`${key}-previous`}
                                  style={styles.tableCell}
                                >
                                  {reportType === "dashline"
                                    ? "Forecasted"
                                    : "Previous"}
                                </Text>
                                <Text
                                  key={`${key}-change`}
                                  style={styles.tableCell}
                                >
                                  % Change
                                </Text>
                              </>
                            ))}
                          </View>
                        )}

                        {/* Table Rows */}
                        {tableData.map((row, index) => (
                          <View
                            key={index}
                            style={{
                              ...styles.tableRow,
                              borderBottomWidth:
                                index === pageRows.length - 1 ? 0 : 1,
                              overflow: "hidden",
                            }}
                          >
                            <Text
                              style={{
                                ...styles.tableCell,
                                flex: pageRows[0][dynamicKeys[0]]?.previous
                                  ? 0.99
                                  : 0.272,
                              }}
                              wrap={false}
                            >
                              {row.date}
                            </Text>
                            {dynamicKeys.map((key) => (
                              <>
                                <Text
                                  key={`${index}-${key}-current`}
                                  style={{ ...styles.tableCell, wrap: false }}
                                >
                                  {row[key]?.current
                                    ? row[key].current.replace(/^-+/, "")
                                    : ""}
                                </Text>

                                {row[key]?.previous && (
                                  <Text
                                    key={`${index}-${key}-previous`}
                                    style={{ ...styles.tableCell, wrap: false }}
                                  >
                                    {row[key]?.previous}
                                  </Text>
                                )}
                                {row[key]?.changePercent && (
                                  <Text
                                    key={`${index}-${key}-change`}
                                    style={[
                                      styles.tableCell,
                                      {
                                        color:
                                          parseFloat(row[key]?.changePercent) <
                                          0
                                            ? "red"
                                            : parseFloat(
                                                row[key]?.changePercent
                                              ) === 0 ||
                                              row[key]?.changePercent === "-"
                                            ? "black"
                                            : "green",
                                        wrap: false,
                                      },
                                    ]}
                                  >
                                    {row[key]?.changePercent}
                                  </Text>
                                )}
                              </>
                            ))}
                          </View>
                        ))}
                      </View>
                      <View break />
                      <View
                        style={{
                          width: "100%",
                          fontSize: 10,
                        }}
                      >
                        <Text>Analysis</Text>
                      </View>
                      <View style={styles.section.Analysis}>
                        <Text>{aiData.Analysis}</Text>
                      </View>
                      <View
                        style={{
                          width: "100%",
                          fontSize: 10,
                        }}
                      >
                        <Text>Recommendations</Text>
                      </View>
                      <View style={styles.section.Recommendations}>
                        <Text>{aiData.Recommendations}</Text>
                      </View>
                      <View
                        style={{
                          width: "100%",
                          fontSize: 10,
                        }}
                      >
                        <Text>Anomalies</Text>
                      </View>
                      <View style={styles.section.Anomalies}>
                        <Text>{aiData.Anomalies}</Text>
                      </View>
                      <Text
                        style={styles.footer}
                        render={({ pageNumber, totalPages }) =>
                          `Page ${pageNumber} of ${totalPages}`
                        }
                        fixed
                      />
                    </Page>
                  ))}
                </Document>
              </PDFViewer>
            </Box>
          </CustomPaper>
        )}
      </Modal>
    )
  }
)

export default ExportChartsData
