export const ColorsArray1 = [
  "#95C75B", // Light Green
  "#BBC954", // Yellow Green
  "#D2CB50", // Yellow
  "#D2B250", // Light Gold
  "#D2A250", // Gold
  "#D28F50", // Amber
  "#D27C50", // Orange
  "#D26750", // Light Orange
  "#D25067", // Coral
  "#D2508B", // Light Pink
  "#D250A1", // Pink
]

export const ColorsArray2 = [
  "#3D50F5",
  "#A17BC8",
  "#007BFF",
  "#17A2B8",
  "#E900EF",
  "#343AFF",
  "#8067E3",
  "#A96FFF",
  "#7282FF",
  "#D084E4",
]
