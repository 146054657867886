// fetch.js
import { postData } from "../../../../api/api"

export const fetchData = async (postUrl, payload) => {
  try {
    return await postData(postUrl, payload)
  } catch (error) {
    console.warn(error)
    return []
  }
}
