import { createSlice } from "@reduxjs/toolkit"

export const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    dashboard: null,
    dashboard_uid: null,
    reports: [],
  },
  reducers: {
    loadData: (state, action) => {
      const { dashboard, dashboard_uid, reports } = action.payload
      state.dashboard = dashboard
      state.dashboard_uid = dashboard_uid
      state.reports = reports
    },
    setChartData: (state, action) => {
      const {
        reportUid,
        response,
        apiResponse,
        aiResponse,
        transformedChartData,
        transformedExportData,
        comparisonPeriod,
        viewBy,
        cacheKey,
        transformedPDFExportData,
      } = action.payload
      const selectedReport = state.reports.find(
        (report) => report.report_uid === reportUid
      )

      if (selectedReport) {
        if (response) selectedReport.reportData = response
        if (apiResponse) selectedReport.apiResponse = apiResponse
        if (aiResponse) selectedReport.aiResponse = aiResponse
        if (transformedChartData)
          selectedReport.transformedChartData = transformedChartData
        if (transformedExportData)
          selectedReport.transformedExportData = transformedExportData
        if (transformedPDFExportData)
          selectedReport.transformedPDFExportData = transformedPDFExportData
        if (cacheKey) selectedReport.cacheKey = cacheKey
        if (comparisonPeriod) {
          selectedReport.comparisonPeriod = comparisonPeriod
        }
        if (viewBy) {
          selectedReport.viewBy = viewBy
        }
      }
    },
  },
})

export const { loadData, setChartData } = overviewSlice.actions

export default overviewSlice.reducer
