export const staticExtraDetails = {
  "Jenna Nguyen": {
    email: "jenna.nguyen@livelytics.ai",
    phoneNumber: "555-123-4567",
    streetAddress: "123 Elm Street",
    aptSuitNo: "Apt 4B",
    city: "Springfield",
    state: "IL",
    zip: "62704",
    totalNoOfItemsBought: "35",
    allTimeSpend: "$3,546.43",
    allTimeAverageSpend: "$101.32",
    allTimeVisits: "45",
    address: "123 Elm Street, Apt 4B, Springfield, IL, 62704",
    amountSpent: "$3,546.43",
    mostBoughtItem: "Sukiyaki",
    comment:
      "The service was impeccable. The staff was attentive, friendly, and made us feel welcome.",
    totalVisits: "45",
    totalSales: "$4,567.89",
    averageSales: "$101.32",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "16th August 2024",
  },
  "Erika Sanchez": {
    email: "erika.sanchez@livelytics.ai",
    phoneNumber: "555-234-5678",
    streetAddress: "456 Oak Avenue",
    aptSuitNo: "Suite 301",
    city: "Lincoln",
    state: "NE",
    zip: "68508",
    totalNoOfItemsBought: "20",
    allTimeSpend: "$1,234.56",
    allTimeAverageSpend: "$61.73",
    allTimeVisits: "25",
    address: "456 Oak Avenue, Suite 301, Lincoln, NE, 68508",
    amountSpent: "$1,234.56",
    mostBoughtItem: "Tempura",
    comment: "Great atmosphere and delicious food. Will definitely return!",
    totalVisits: "25",
    totalSales: "$1,678.90",
    averageSales: "$67.16",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "28th July 2024",
  },
  "Devon Scott": {
    email: "devon.scott@livelytics.ai",
    phoneNumber: "555-345-6789",
    streetAddress: "789 Pine Road",
    aptSuitNo: "",
    city: "Madison",
    state: "WI",
    zip: "53703",
    totalNoOfItemsBought: "15",
    allTimeSpend: "$654.32",
    allTimeAverageSpend: "$43.62",
    allTimeVisits: "18",
    address: "789 Pine Road, Madison, WI, 53703",
    amountSpent: "$654.32",
    mostBoughtItem: "Ramen",
    comment:
      "Food is consistently good and staff is friendly. A solid choice for a meal.",
    totalVisits: "18",
    totalSales: "$872.45",
    averageSales: "$48.47",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "25th August 2024",
  },
  "Tiffany Bryan": {
    email: "tiffany.bryan@livelytics.ai",
    phoneNumber: "555-456-7890",
    streetAddress: "321 Maple Street",
    aptSuitNo: "Apt 2A",
    city: "Columbus",
    state: "OH",
    zip: "43215",
    totalNoOfItemsBought: "10",
    allTimeSpend: "$789.10",
    allTimeAverageSpend: "$78.91",
    allTimeVisits: "12",
    address: "321 Maple Street, Apt 2A, Columbus, OH, 43215",
    amountSpent: "$789.10",
    mostBoughtItem: "Sushi",
    comment: "Fresh and tasty sushi every time. Highly recommended!",
    totalVisits: "12",
    totalSales: "$945.25",
    averageSales: "$78.77",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "16th August 2024",
  },
  "Michael Patrick": {
    email: "michael.patrick@livelytics.ai",
    phoneNumber: "555-567-8901",
    streetAddress: "654 Birch Lane",
    aptSuitNo: "Unit 5",
    city: "Denver",
    state: "CO",
    zip: "80203",
    totalNoOfItemsBought: "22",
    allTimeSpend: "$2,345.67",
    allTimeAverageSpend: "$106.63",
    allTimeVisits: "30",
    address: "654 Birch Lane, Unit 5, Denver, CO, 80203",
    amountSpent: "$2,345.67",
    mostBoughtItem: "Yakitori",
    comment:
      "A must-visit place for amazing Japanese cuisine. Friendly staff and great service.",
    totalVisits: "30",
    totalSales: "$3,210.89",
    averageSales: "$107.03",
    rating: "4.3",
    rate: "+11.01%",
    lastVisitedOn: "12th July 2024",
  },
}

// Define color arrays as constants
export const Array1 = [
  "#95C75B",
  "#BBC954",
  "#D2CB50",
  "#A17BC8",
  "#5C73F2",
  "#D084E4",
  "#8B93D1",
  "#FF85E1",
  "#8067E3",
  "#7282FF",
  "#A96FFF",
]

export const Array2 = [
  "#3D50F5",
  "#A17BC8",
  "#007BFF",
  "#17A2B8",
  "#E900EF",
  "#343AFF",
  "#8067E3",
  "#A96FFF",
  "#7282FF",
  "#D084E4",
]

export const staticTransformedChartData = [
  {
    title: "GB Foods Static",
    defaultValue: 240049,
    tooltipData: {
      current: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4598,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240049,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "GB Foods",
          format: false,
        },
        TotalOrders: {
          value: 4598,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 240049,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rasoi Restaurant",
    defaultValue: 82391,
    tooltipData: {
      current: {
        Name: {
          value: "Rasoi Restaurant",
          format: false,
        },
        TotalOrders: {
          value: 1289,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82391,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rasoi Restaurant",
          format: false,
        },
        TotalOrders: {
          value: 1289,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 82391,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Oms Oms",
    defaultValue: 18038,
    tooltipData: {
      current: {
        Name: {
          value: "Oms Oms",
          format: false,
        },
        TotalOrders: {
          value: 251,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 18038,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Oms Oms",
          format: false,
        },
        TotalOrders: {
          value: 251,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 18038,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "rekha goyal",
    defaultValue: 10133,
    tooltipData: {
      current: {
        Name: {
          value: "rekha goyal",
          format: false,
        },
        TotalOrders: {
          value: 504,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 10133,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "rekha goyal",
          format: false,
        },
        TotalOrders: {
          value: 504,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 10133,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Anusha Vyawahare",
    defaultValue: 9829,
    tooltipData: {
      current: {
        Name: {
          value: "Anusha Vyawahare",
          format: false,
        },
        TotalOrders: {
          value: 376,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 9829,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Anusha Vyawahare",
          format: false,
        },
        TotalOrders: {
          value: 376,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 9829,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Akram Shakir",
    defaultValue: 8332,
    tooltipData: {
      current: {
        Name: {
          value: "Akram Shakir",
          format: false,
        },
        TotalOrders: {
          value: 109,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 8332,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Akram Shakir",
          format: false,
        },
        TotalOrders: {
          value: 109,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 8332,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "pranav v",
    defaultValue: 7922,
    tooltipData: {
      current: {
        Name: {
          value: "pranav v",
          format: false,
        },
        TotalOrders: {
          value: 394,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7922,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "pranav v",
          format: false,
        },
        TotalOrders: {
          value: 394,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7922,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Annie Syed",
    defaultValue: 7840,
    tooltipData: {
      current: {
        Name: {
          value: "Annie Syed",
          format: false,
        },
        TotalOrders: {
          value: 161,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7840,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Annie Syed",
          format: false,
        },
        TotalOrders: {
          value: 161,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7840,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Firdouse Qasin",
    defaultValue: 7562,
    tooltipData: {
      current: {
        Name: {
          value: "Firdouse Qasin",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7562,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Firdouse Qasin",
          format: false,
        },
        TotalOrders: {
          value: 80,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7562,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "syeda naqvi",
    defaultValue: 7540,
    tooltipData: {
      current: {
        Name: {
          value: "syeda naqvi",
          format: false,
        },
        TotalOrders: {
          value: 195,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7540,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "syeda naqvi",
          format: false,
        },
        TotalOrders: {
          value: 195,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 7540,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Syed Ahmed",
    defaultValue: 6892,
    tooltipData: {
      current: {
        Name: {
          value: "Syed Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 87,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6892,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Syed Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 87,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6892,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rizwaan Khaja",
    defaultValue: 6725,
    tooltipData: {
      current: {
        Name: {
          value: "Rizwaan Khaja",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6725,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rizwaan Khaja",
          format: false,
        },
        TotalOrders: {
          value: 115,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6725,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Rabab Rizvi",
    defaultValue: 6555,
    tooltipData: {
      current: {
        Name: {
          value: "Rabab Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 94,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6555,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Rabab Rizvi",
          format: false,
        },
        TotalOrders: {
          value: 94,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6555,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Ased Kanwal",
    defaultValue: 6274,
    tooltipData: {
      current: {
        Name: {
          value: "Ased Kanwal",
          format: false,
        },
        TotalOrders: {
          value: 92,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6274,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Ased Kanwal",
          format: false,
        },
        TotalOrders: {
          value: 92,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6274,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Abbas Naqvi",
    defaultValue: 6139,
    tooltipData: {
      current: {
        Name: {
          value: "Abbas Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 125,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6139,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Abbas Naqvi",
          format: false,
        },
        TotalOrders: {
          value: 125,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6139,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "tuba athar",
    defaultValue: 6117,
    tooltipData: {
      current: {
        Name: {
          value: "tuba athar",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6117,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "tuba athar",
          format: false,
        },
        TotalOrders: {
          value: 49,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 6117,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sadaf Zeeshan",
    defaultValue: 5801,
    tooltipData: {
      current: {
        Name: {
          value: "Sadaf Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5801,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sadaf Zeeshan",
          format: false,
        },
        TotalOrders: {
          value: 81,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5801,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mashaal Dagha",
    defaultValue: 5363,
    tooltipData: {
      current: {
        Name: {
          value: "Mashaal Dagha",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5363,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mashaal Dagha",
          format: false,
        },
        TotalOrders: {
          value: 46,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5363,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Zaki Mohammed",
    defaultValue: 5264,
    tooltipData: {
      current: {
        Name: {
          value: "Zaki Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5264,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Zaki Mohammed",
          format: false,
        },
        TotalOrders: {
          value: 69,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5264,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "naba khan",
    defaultValue: 5166,
    tooltipData: {
      current: {
        Name: {
          value: "naba khan",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5166,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "naba khan",
          format: false,
        },
        TotalOrders: {
          value: 38,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5166,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Mehreen khan",
    defaultValue: 5043,
    tooltipData: {
      current: {
        Name: {
          value: "Mehreen khan",
          format: false,
        },
        TotalOrders: {
          value: 84,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5043,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Mehreen khan",
          format: false,
        },
        TotalOrders: {
          value: 84,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 5043,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Arsala Imam",
    defaultValue: 4867,
    tooltipData: {
      current: {
        Name: {
          value: "Arsala Imam",
          format: false,
        },
        TotalOrders: {
          value: 169,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4867,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Arsala Imam",
          format: false,
        },
        TotalOrders: {
          value: 169,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4867,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Alishba Khan",
    defaultValue: 4840,
    tooltipData: {
      current: {
        Name: {
          value: "Alishba Khan",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4840,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Alishba Khan",
          format: false,
        },
        TotalOrders: {
          value: 98,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4840,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Surya Anakala",
    defaultValue: 4787,
    tooltipData: {
      current: {
        Name: {
          value: "Surya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 145,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4787,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Surya Anakala",
          format: false,
        },
        TotalOrders: {
          value: 145,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4787,
          format: "Currency",
        },
      },
    },
  },
  {
    title: "Sunira Ahmed",
    defaultValue: 4763,
    tooltipData: {
      current: {
        Name: {
          value: "Sunira Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 131,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4763,
          format: "Currency",
        },
      },
      previous: {
        Name: {
          value: "Sunira Ahmed",
          format: false,
        },
        TotalOrders: {
          value: 131,
          format: "Number",
        },
        "Customer Lifetime Value": {
          value: 4763,
          format: "Currency",
        },
      },
    },
  },
]

export const staticApiResponse = {
  reportDataCurrent: [
    {
      CustomerID: "2000080",
      Name: "GB Foods Static",
      Phone: "(832) 437-6358",
      TotalOrders: 4582,
      "Customer Lifetime Value": 239826,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000003",
      Name: "Rasoi Restaurant",
      Phone: "(832) 278-1571",
      TotalOrders: 1284,
      "Customer Lifetime Value": 81909,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000033",
      Name: "Oms Oms",
      Phone: "(123) 456-7890",
      TotalOrders: 249,
      "Customer Lifetime Value": 17912,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000122",
      Name: "rekha goyal",
      Phone: "(346) 435-5715",
      TotalOrders: 504,
      "Customer Lifetime Value": 10133,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "99001077",
      Name: "Anusha Vyawahare",
      Phone: "(323) 794-6024",
      Email: "anusha.vyawahare@outlook.com",
      TotalOrders: 372,
      "Customer Lifetime Value": 9766,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001190",
      Name: "Akram Shakir",
      Phone: "(331) 253-7416",
      TotalOrders: 108,
      "Customer Lifetime Value": 8195,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000004",
      Name: "pranav v",
      Phone: "(669) 238-9321",
      TotalOrders: 394,
      "Customer Lifetime Value": 7922,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000296",
      Name: "Annie Syed",
      Phone: "(214) 502-3223",
      TotalOrders: 160,
      "Customer Lifetime Value": 7773,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000127",
      Name: "Firdouse Qasin",
      Phone: "(240) 643-4319",
      TotalOrders: 79,
      "Customer Lifetime Value": 7515,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000130",
      Name: "syeda naqvi",
      Phone: "(818) 235-8163",
      TotalOrders: 194,
      "Customer Lifetime Value": 7488,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000352",
      Name: "Syed Ahmed",
      Phone: "(832) 795-6552",
      TotalOrders: 85,
      "Customer Lifetime Value": 6855,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "99001079",
      Name: "Rizwaan Khaja",
      Phone: "(408) 981-1625",
      Email: "afzaloddin@gmail.com",
      TotalOrders: 115,
      "Customer Lifetime Value": 6725,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000265",
      Name: "Rabab Rizvi",
      Phone: "(832) 744-7102",
      TotalOrders: 93,
      "Customer Lifetime Value": 6487,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000474",
      Name: "Ased Kanwal",
      Phone: "(641) 451-3980",
      TotalOrders: 90,
      "Customer Lifetime Value": 6200,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000209",
      Name: "Abbas Naqvi",
      Phone: "(281) 901-8870",
      TotalOrders: 125,
      "Customer Lifetime Value": 6139,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000137",
      Name: "tuba athar",
      Phone: "(346) 431-4247",
      TotalOrders: 49,
      "Customer Lifetime Value": 6117,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001387",
      Name: "Sadaf Zeeshan",
      Phone: "(832) 983-5122",
      TotalOrders: 80,
      "Customer Lifetime Value": 5745,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001473",
      Name: "Mashaal Dagha",
      Phone: "(832) 221-4887",
      TotalOrders: 46,
      "Customer Lifetime Value": 5363,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001616",
      Name: "Zaki Mohammed",
      Phone: "(626) 534-7856",
      TotalOrders: 69,
      "Customer Lifetime Value": 5264,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000238",
      Name: "naba khan",
      Phone: "(214) 543-4019",
      TotalOrders: 38,
      "Customer Lifetime Value": 5166,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000378",
      Name: "Mehreen khan",
      Phone: "(832) 989-2881",
      TotalOrders: 84,
      "Customer Lifetime Value": 5043,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000066",
      Name: "Arsala Imam",
      Phone: "(713) 820-7593",
      TotalOrders: 169,
      "Customer Lifetime Value": 4867,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000168",
      Name: "Alishba Khan",
      Phone: "(832) 367-0502",
      TotalOrders: 98,
      "Customer Lifetime Value": 4840,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000071",
      Name: "Surya Anakala",
      Phone: "(732) 447-5886",
      Email: "surya.anakala@gmail.com",
      TotalOrders: 145,
      "Customer Lifetime Value": 4787,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000247",
      Name: "Sunira Ahmed",
      Phone: "(516) 301-7901",
      TotalOrders: 131,
      "Customer Lifetime Value": 4763,
      CustomerType: "Loyal",
    },
  ],
  reportDataPrevious: [
    {
      CustomerID: "2000080",
      Name: "GB Foods",
      Phone: "(832) 437-6358",
      TotalOrders: 4582,
      "Customer Lifetime Value": 239826,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000003",
      Name: "Rasoi Restaurant",
      Phone: "(832) 278-1571",
      TotalOrders: 1284,
      "Customer Lifetime Value": 81909,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000033",
      Name: "Oms Oms",
      Phone: "(123) 456-7890",
      TotalOrders: 249,
      "Customer Lifetime Value": 17912,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000122",
      Name: "rekha goyal",
      Phone: "(346) 435-5715",
      TotalOrders: 504,
      "Customer Lifetime Value": 10133,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "99001077",
      Name: "Anusha Vyawahare",
      Phone: "(323) 794-6024",
      Email: "anusha.vyawahare@outlook.com",
      TotalOrders: 372,
      "Customer Lifetime Value": 9766,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001190",
      Name: "Akram Shakir",
      Phone: "(331) 253-7416",
      TotalOrders: 108,
      "Customer Lifetime Value": 8195,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000004",
      Name: "pranav v",
      Phone: "(669) 238-9321",
      TotalOrders: 394,
      "Customer Lifetime Value": 7922,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000296",
      Name: "Annie Syed",
      Phone: "(214) 502-3223",
      TotalOrders: 160,
      "Customer Lifetime Value": 7773,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000127",
      Name: "Firdouse Qasin",
      Phone: "(240) 643-4319",
      TotalOrders: 79,
      "Customer Lifetime Value": 7515,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000130",
      Name: "syeda naqvi",
      Phone: "(818) 235-8163",
      TotalOrders: 194,
      "Customer Lifetime Value": 7488,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000352",
      Name: "Syed Ahmed",
      Phone: "(832) 795-6552",
      TotalOrders: 85,
      "Customer Lifetime Value": 6855,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "99001079",
      Name: "Rizwaan Khaja",
      Phone: "(408) 981-1625",
      Email: "afzaloddin@gmail.com",
      TotalOrders: 115,
      "Customer Lifetime Value": 6725,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000265",
      Name: "Rabab Rizvi",
      Phone: "(832) 744-7102",
      TotalOrders: 93,
      "Customer Lifetime Value": 6487,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000474",
      Name: "Ased Kanwal",
      Phone: "(641) 451-3980",
      TotalOrders: 90,
      "Customer Lifetime Value": 6200,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000209",
      Name: "Abbas Naqvi",
      Phone: "(281) 901-8870",
      TotalOrders: 125,
      "Customer Lifetime Value": 6139,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000137",
      Name: "tuba athar",
      Phone: "(346) 431-4247",
      TotalOrders: 49,
      "Customer Lifetime Value": 6117,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001387",
      Name: "Sadaf Zeeshan",
      Phone: "(832) 983-5122",
      TotalOrders: 80,
      "Customer Lifetime Value": 5745,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001473",
      Name: "Mashaal Dagha",
      Phone: "(832) 221-4887",
      TotalOrders: 46,
      "Customer Lifetime Value": 5363,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2001616",
      Name: "Zaki Mohammed",
      Phone: "(626) 534-7856",
      TotalOrders: 69,
      "Customer Lifetime Value": 5264,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000238",
      Name: "naba khan",
      Phone: "(214) 543-4019",
      TotalOrders: 38,
      "Customer Lifetime Value": 5166,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000378",
      Name: "Mehreen khan",
      Phone: "(832) 989-2881",
      TotalOrders: 84,
      "Customer Lifetime Value": 5043,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000066",
      Name: "Arsala Imam",
      Phone: "(713) 820-7593",
      TotalOrders: 169,
      "Customer Lifetime Value": 4867,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000168",
      Name: "Alishba Khan",
      Phone: "(832) 367-0502",
      TotalOrders: 98,
      "Customer Lifetime Value": 4840,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "1000071",
      Name: "Surya Anakala",
      Phone: "(732) 447-5886",
      Email: "surya.anakala@gmail.com",
      TotalOrders: 145,
      "Customer Lifetime Value": 4787,
      CustomerType: "Loyal",
    },
    {
      CustomerID: "2000247",
      Name: "Sunira Ahmed",
      Phone: "(516) 301-7901",
      TotalOrders: 131,
      "Customer Lifetime Value": 4763,
      CustomerType: "Loyal",
    },
  ],
}
