import React, { useMemo } from "react"
import { ResponsiveCalendar } from "@nivo/calendar"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"
import Box from "@mui/material/Box"
import { getFormattedValue } from "../../../utils/utils"
import StarLowPerformer from "../../StarLowPerformer"
import {
  dynamicLegendRanges,
  findTopAndLowPerformer,
} from "./services/configServices"

const CalendarPresentation = ({
  chartInput,
  parameters,
  reportTitle,
  reportData,
  showAiDropdown,
  defaultColor,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const {
    performerFlag = { icon: false, legend: true },
    chartContainerDimensions = {
      height: "600px",
    },
    colors,
  } = parameters

  const minValue = Math.min(...chartInput.map((d) => d.value))
  const maxValue = Math.max(...chartInput.map((d) => d.value))

  const legendsData = useMemo(() => {
    return dynamicLegendRanges(minValue, maxValue, 4, defaultColor)
  }, [minValue, maxValue, defaultColor])

  const fromDate = "2022-01-01"
  const toDate = "2024-12-01"

  // useMemo to calculate top/low performers within the date range
  const StarAndLowPerformer = useMemo(
    () => findTopAndLowPerformer(chartInput, fromDate, toDate),
    [chartInput, fromDate, toDate]
  )

  const starPerformer = StarAndLowPerformer.StarPerformer
  const lowPerformer = StarAndLowPerformer.lowPerformer

  const dayjs = require("dayjs")
  function formatDate(date) {
    return dayjs(date).format("MM-DD-YYYY")
  }

  const starPerformerValue = starPerformer
    ? `${getFormattedValue(starPerformer.value.toFixed(2), "Currency")}`
    : "N/A"
  const starPerformerDate = starPerformer
    ? formatDate(starPerformer.day)
    : "N/A"
  const lowPerformerValue = lowPerformer
    ? `${getFormattedValue(lowPerformer.value.toFixed(2), "Currency")}`
    : "N/A"
  const lowPerformerDate = lowPerformer ? formatDate(lowPerformer.day) : "N/A"

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    labels: {
      text: {
        fill: itemTextColor,
      },
    },
    legends: {
      text: {
        fontSize: 12,
      },
    },
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      {performerFlag.legend && (
        <StarLowPerformer
          legendsData={legendsData}
          StarPerformer={starPerformerValue}
          StarPerformerDate={starPerformerDate}
          lowPerformer={lowPerformerValue}
          lowPerformerDate={lowPerformerDate}
        />
      )}

      <Box sx={{ ...chartContainerDimensions }}>
        <ResponsiveCalendar
          data={chartInput}
          margin={{ top: 40, right: 0, bottom: 60, left: 20 }}
          theme={customTheme}
          from={fromDate}
          to={toDate}
          emptyColor="#eeeeee"
          colors={defaultColor ? defaultColor : colors}
          yearSpacing={-350}
          monthSpacing={10}
          yearLegendOffset={10}
          monthLegendOffset={18}
          monthLegendPosition={"after"}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          tooltip={(n) => (
            <CustomTooltip
              data={n.data}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )}
        />
      </Box>
      {performerFlag.legend && (
        <CustomLegend
          legendsData={legendsData}
          StarPerformer={starPerformerValue}
          StarPerformerDate={starPerformerDate}
          lowPerformer={lowPerformerValue}
          lowPerformerDate={lowPerformerDate}
          reportTitle={reportTitle}
          reportData={reportData}
          showAiDropdown={showAiDropdown}
        />
      )}
    </Box>
  )
}

export default CalendarPresentation
