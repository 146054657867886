import { createContext, useContext, useState, useMemo, useEffect } from "react"
import { createTheme } from "@mui/material"
import { grey } from "@mui/material/colors"
import Comfortaa from "../assets/fonts/Comfortaa-VariableFont_wght.ttf"

const getColorPalette = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: { main: "#E9EDFC" },
          accent: "#1D47CE",
          accentSecondary: "#1D47CE",
          background: {
            default: "#1b4cf00a",
            paper: "#FFFFFF",
          },
          text: {
            primary: "#000",
            secondary: grey[800],
          },
        }
      : {
          primary: { main: "#112B7F" },
          accent: "#FF7935",
          accentSecondary: "#BCCAF6",
          background: {
            default: "#071339",
            paper: "#0D2266",
          },
          text: {
            primary: "#fff",
            secondary: grey[500],
          },
        }),
  },
  typography: {
    fontFamily: ["Comfortaa", "Roboto", "Arial", "sans-serif"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    allVariants: {
      fontFamily: "Comfortaa",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Comfortaa';
        font-style: normal;
        font-display: swap;
        font-weight: 100 200 300 400 500 600 700 800;
        src: local('Comfortaa-Regular'), local('Comfortaa-Regular'), url(${Comfortaa}) format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      ::-webkit-scrollbar-track {
        background: #07133900;
      }
      ::-webkit-scrollbar-thumb {
        background: #5555559F;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555555;
      }
    `,
      "@global": {
        "*": {
          fontFamily: "Comfortaa",
        },
      },
    },
  },
  spacing: 10,
})

const ThemeContext = createContext()

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState(localStorage.getItem("themeMode") || "light")

  const resetColorMode = (newMode) => {
    const validMode = newMode === "dark" ? "dark" : "light"
    setMode(validMode)
    localStorage.setItem("themeMode", validMode)
  }

  const toggleColorMode = () => {
    setMode((prevMode) => {
      const newMode = prevMode === "dark" ? "light" : "dark"
      localStorage.setItem("themeMode", newMode)
      return newMode
    })
  }

  const theme = useMemo(() => createTheme(getColorPalette(mode)), [mode])

  return (
    <ThemeContext.Provider
      value={{ theme, mode, toggleColorMode, resetColorMode }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
