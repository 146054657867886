import { forwardRef, useState } from "react"
import { useTheme } from "../../contexts/theme"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Modal from "@mui/material/Modal"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import CropPortraitIcon from "@mui/icons-material/CropPortrait"
import CropLandscapeIcon from "@mui/icons-material/CropLandscape"

import LoadingButton from "@mui/lab/LoadingButton"
import DownloadIcon from "@mui/icons-material/Download"
import PDFIcon from "../../assets/svgs/PDFIcon"
import FileIcon from "../../assets/svgs/FileIcon"
import PDFPreview from "./PDFPreview"
import XLSX from "xlsx"
import { convertToSnakeCase } from "../../utils/utils"
import CustomPaper from "../CustomPaper"

const ExportMenu = forwardRef(
  (
    {
      route,
      title,
      description,
      reportUid,
      setChartData,
      transformedChartData,
      apiResponse,
      chartRef,
      pdfFields,
      pdfTransformFunction,
      transformDataSheetExport,
    },
    ref
  ) => {
    const { theme } = useTheme()

    const [anchorEl, setAnchorEl] = useState(null)
    const menuOpen = Boolean(anchorEl)

    const [loading, setLoading] = useState(false)

    const [PDFModalOpen, setPDFModalOpen] = useState(false)
    const handlePDFModalOpen = () => setPDFModalOpen(true)
    const handlePDFModalClose = () => setPDFModalOpen(false)

    const [orientation, setOrientation] = useState("portrait")

    const handleOrientationChange = (event, newOrientation) => {
      if (newOrientation !== null) {
        setOrientation(newOrientation)
      }
    }

    const handleExportMenuClick = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleExportClick = (title, type, data) => {
      setLoading(true)

      try {
        if (type === "pdf") {
          handlePDFModalOpen()
        } else if (["xlsx", "csv"].includes(type)) {
          let sheetInput = transformDataSheetExport(
            data || [],
            pdfFields,
            reportUid
          )
          const worksheet = XLSX.utils.json_to_sheet(sheetInput)
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data")
          const fileName = convertToSnakeCase(title) || "data"
          XLSX.writeFile(workbook, `${fileName}.${type}`, { bookType: type })
        }
      } catch (error) {
        console.warn(error)
      } finally {
        setAnchorEl(null)
        setLoading(false)
      }
    }

    const exportOptions = [
      {
        label: "PDF",
        value: "pdf",
        icon: <PDFIcon strokeColor={theme.palette.accent} />,
      },
      {
        label: "Excel",
        value: "xlsx",
        icon: <FileIcon strokeColor={theme.palette.accent} />,
      },
      {
        label: "CSV",
        value: "csv",
        icon: <FileIcon strokeColor={theme.palette.accent} />,
      },
    ]

    return (
      <Box className="pdf-export-hidden">
        <Modal
          open={PDFModalOpen}
          onClose={handlePDFModalClose}
          aria-labelledby="modal-PDF-preview"
          aria-describedby="modal-PDF-preview"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomPaper
            customStyles={{
              position: "absolute",
              width: "80vw",
              minHeight: {
                xs: "75vh",
                sm: "75vh",
                md: "80vh",
                lg: "80vh",
                xl: "65vh",
              },
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                  : "#FBFBFE",
              boxShadow: 24,
            }}
          >
            <Stack height="100%">
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="h6" color="inherit">
                  PDF Preview
                </Typography>

                <Box
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <ToggleButtonGroup
                    value={orientation}
                    exclusive
                    onChange={handleOrientationChange}
                    aria-label="layout toggler"
                    size="small"
                  >
                    <ToggleButton
                      value="portrait"
                      aria-label="portrait layout"
                      size="small"
                    >
                      <CropPortraitIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="landscape"
                      aria-label="landscape layout"
                      size="small"
                    >
                      <CropLandscapeIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <IconButton onClick={handlePDFModalClose} size="small">
                    <CloseIcon
                      sx={{
                        color: theme.palette.accent,
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <PDFPreview
                route={route}
                title={title}
                description={description}
                reportUid={reportUid}
                setChartData={setChartData}
                apiResponse={apiResponse}
                chartRef={chartRef}
                orientation={orientation}
                pdfFields={pdfFields}
                transformFunction={pdfTransformFunction}
              />
            </Stack>
          </CustomPaper>
        </Modal>

        <LoadingButton
          sx={{
            minWidth: "104px",
            textTransform: "none",
            borderRadius: "10px",
            border: `1px solid ${theme.palette.accent}`,
            color: theme.palette.accent,
            "&:hover": {
              border: `1px solid ${theme.palette.accent}`,
            },
            "& .MuiCircularProgress-colorInherit": {
              color: theme.palette.accent,
            },
            "& .MuiLoadingButton-label": {
              display: "flex",
              flexDirection: "row",
              gap: 1,
            },
          }}
          color="warning"
          onClick={handleExportMenuClick}
          loading={loading}
        >
          <Typography variant="body2" color="inherit">
            Export
          </Typography>
          <DownloadIcon fontSize="small" />
        </LoadingButton>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "export-button" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {false ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding="10px"
            >
              <CircularProgress disableShrink size={45} color="info" />
              <Typography variant="body2" color="inherit">
                "loading"
              </Typography>
            </Box>
          ) : (
            <Box>
              {exportOptions.map((exportOption) => (
                <MenuItem
                  onClick={() =>
                    handleExportClick(
                      title,
                      exportOption.value,
                      transformedChartData
                    )
                  }
                >
                  <ListItemIcon>{exportOption.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" color="inherit">
                      {exportOption.label}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            </Box>
          )}
        </Menu>
      </Box>
    )
  }
)

export default ExportMenu
