import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { useFormik } from "formik"
import { verificationSchema } from "./Validation"
import NewPassword from "./NewPassword"
import OtpInput from "react-otp-input"

const subscriptions = [
  {
    value: "Free",
    label: "Free",
  },
  {
    value: "Starter",
    label: "Starter - $299",
  },
  {
    value: "Pro",
    label: "Pro - $599",
  },
  {
    value: "Pro-Max",
    label: "Pro Max - $999",
  },
]

const VerificationCode = (isNewUser) => {
  const navigate = useNavigate()
  const [mobileOtp, setMobileOtp] = useState("")
  const [emailOtp, setEmailOtp] = useState("")
  const [timer, setTimer] = useState(60)
  const [countdownInterval, setCountdownInterval] = useState(null)
  const [isNewPassword, setIsNewPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      mobileValidation: Array(6).fill(""),
      emailValidation: Array(6).fill(""),
      subscription: "Free",
    },
    validationSchema: verificationSchema,
    onSubmit: (values) => {
      handleVerifySuccess()
    },
  })

  const handleVerifySuccess = (e) => {
    e.preventDefault()
    sessionStorage.setItem("isLoggedin", "true")
    localStorage.setItem("isLoggedin", "true")
    if (!isNewUser.isNewUser) {
      navigate("/sales")
    } else {
      setIsNewPassword(true)
      navigate("/newpassword")
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const resendCode = () => {
    setTimer(60)
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1)
      }
    }, 1000)
    setCountdownInterval(countdown)
  }

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1)
      }
    }, 1000)

    return () => {
      clearTimeout(countdown)
    }
  }, [timer])

  return (
    <>
      {!isNewPassword && (
        <Stack display="flex" gap={2} alignItems="center">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography variant="h5" color="inherit" textAlign="center">
              Verification Code
            </Typography>
            <Box justifySelf="flex-end">
              <Button
                variant="text"
                sx={{
                  textTransform: "none",
                  color: "#2642a8",
                  borderRadius: 10,
                  "&:hover": {
                    backgroundColor: "#2642a805",
                  },
                }}
                onClick={goBack}
              >
                ← Back
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Mobile Verification Code
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <OtpInput
              className="otp-input"
              value={mobileOtp}
              onChange={setMobileOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              inputType="tel"
              containerStyle={{
                display: "unset",
              }}
              inputStyle={{
                width: "3rem",
                height: "3.5rem",
              }}
              renderInput={(props) => (
                <input {...props} className="otp-input" />
              )}
            />
          </Box>

          <Box>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Email Verification Code
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <OtpInput
              className="otp-input"
              value={emailOtp}
              onChange={setEmailOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              inputType="tel"
              containerStyle={{
                display: "unset",
              }}
              inputStyle={{
                width: "3rem",
                height: "3.5rem",
              }}
              renderInput={(props) => (
                <input {...props} className="otp-input" />
              )}
            />
          </Box>
          <Typography variant="body1" color="inherit" textAlign="center">
            Please enter verification code and verify
          </Typography>
          <Box justifySelf="flex-end">
            <Button
              variant="text"
              disabled={timer > 0}
              sx={{
                textTransform: "none",
                color: "#2642a8",
                borderRadius: 10,
                "&:hover": {
                  backgroundColor: "#2642a805",
                },
              }}
              onClick={resendCode}
            >
              Resend {timer > 0 ? timer : ""}
            </Button>
          </Box>
          <Stack width="100%">
            <Typography variant="h5" color="inherit" textAlign="left">
              Subscription
            </Typography>
            <TextField
              id="outlined-select-currency"
              select
              defaultValue="Free"
              variant="standard"
              name="subscription"
              sx={{
                margin: "0.5rem 0 0.5rem 0",
                width: "100%",
                "& .MuiInputBase-input": { color: "#000" },
              }}
              value={formik.values.subscription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.subscription &&
                Boolean(formik.errors.subscription)
              }
              helperText={
                formik.touched.subscription && formik.errors.subscription
              }
            >
              {subscriptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Box width="100%" display="flex" justifyContent="center">
            <Button
              type="submit"
              disabled={mobileOtp.length !== 6 || formik.isSubmitting}
              onClick={handleVerifySuccess}
              sx={{
                width: "fit-content",
                backgroundColor: "#2642a8",
                padding: "10px 19px",
                borderRadius: 10,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2642a8",
                },
                "&.Mui-disabled": {
                  opacity: "50%",
                  color: "white",
                  backgroundColor: "gray",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      )}

      {isNewPassword && <NewPassword />}
    </>
  )
}

export default VerificationCode
