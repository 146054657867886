import { useState } from "react"
import Box from "@mui/system/Box"
import Stack from "@mui/system/Stack"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { useNavigate } from "react-router"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import DeleteModal from "../../components/DeleteModal"
import { setChartData } from "../../redux/slices/customerSlice"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { CustomerBarChartParameters } from "../../constants/CustomerBarChartParameters"
import ArrowRise from "../../assets/svgs/ArrowRise"
import BlurEffect from "../../components/BlurEffect"
import BarChartContainer from "../../components/charts/bar/BarChartContainer"
import ProgressBarContainer from "../../components/charts/progressBar_re/ProgressBarContainer"

const CustomerDetails = () => {
  const { theme } = useTheme()
  const location = useLocation()
  const { item } = location.state || {}
  const ThemeMode = theme.palette.mode
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const deleteRecord = (id = 0) => {
    setOpen(false)
  }

  const handleDelete = (event, id) => {
    event.stopPropagation()
    setOpen(true)
  }
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const handleEdit = () => {
    navigate(``)
  }
  const handleToggle = (value) => {
    setSelectedOption(value)
  }
  const [selectedOption, setSelectedOption] = useState("revenue")

  return (
    <Box position="relative">
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={() => deleteRecord()}
      />
      <BlurEffect showBlur={true} />
      <Box padding="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Customers",
              // isActive: false,
              clickHandler: () => {
                navigate("/customers/allcustomers")
              },
            },
            {
              text: "Customers Spending",
              isActive: true,
            },
          ]}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingY="15px"
        >
          <Typography component="div" variant="subtitle1">
            Customers Spending
          </Typography>
          <Box>
            <IconButton size="small">
              <EditIcon sx={{ color: "inherit" }} onClick={handleEdit} />
            </IconButton>
            <IconButton size="small" onClick={handleDelete}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>

        <CustomPaper
          className="user-grid-content1 grid-col-span-4 "
          customStyles={{
            mb: "20px",
          }}
        >
          <Box>
            {/* Personal Information */}
            <Typography
              component="div"
              variant="subtitle2"
              sx={{
                textAlign: "left",
                pb: "10px",
              }}
            >
              Personal Information
            </Typography>
            <Divider
              style={{
                width: "100%",
                border: "1px solid rgba(9, 23, 67, 1)",
              }}
            />

            {/* Personal Information Details */}
            <Stack
              direction="row"
              justifyContent="space-between"
              className="custom-stack"
            >
              {/* Left Column */}
              <Stack
                className="personal-info-column"
                style={{ textAlign: "left" }}
              >
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Name
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.title || "Dianne Russle"}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Appartment / Suite Number
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.aptSuitNo || "Apt 4B"}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Last Visited On
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                    color: " rgba(80, 205, 137, 1)",
                  }}
                >
                  {item?.lastVisitedOn || "16th March 2024"}
                </Typography>
              </Stack>

              {/* Center Column */}
              <Stack
                className="personal-info-column"
                style={{ textAlign: "left" }}
              >
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Phone Number
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.phoneNumber || "97399321"}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  City
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.city || "Springfield"}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Total No. of items bought
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.totalNoOfItemsBought || "37"}
                </Typography>
              </Stack>

              {/* center2 Column */}
              <Stack
                className="personal-info-column"
                style={{ textAlign: "left" }}
              >
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Email Address
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.email || "abc.qqqq@livelytics.ai"}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  State
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.state || "IL"}
                </Typography>
              </Stack>
              {/* Right Column */}
              <Stack className="address-column" style={{ textAlign: "left" }}>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Street Address
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.streetAddress || "123 Main Street"}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{ color: "gray", mt: 2 }}
                >
                  Zip Code
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    mt: 0.5,
                    fontSize: "18px",
                  }}
                >
                  {item?.zip || "440012"}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </CustomPaper>

        <CustomPaper
          customStyles={{
            backgroundColor: "transparent",
            border:
              ThemeMode === "dark" ? "2px solid rgba(13, 34, 102, 1)" : "none",
          }}
          innerCustomStyles={{
            border: 0,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="flex-start"
                gap={2}
              >
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Over all Rating
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        color:
                          ThemeMode === "dark"
                            ? "rgba(255, 255, 255, 1)"
                            : " rgba(0, 0, 0, 1)",
                        textAlign: "left",
                      }}
                    >
                      {item?.rating || "4.3"}
                    </Typography>
                    <svg
                      width="28"
                      height="26"
                      viewBox="0 0 28 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <path
                        d="M13.0489 0.927047C13.3483 0.00573683 14.6517 0.00573993 14.9511 0.927051L17.3677 8.36475C17.5016 8.77677 17.8855 9.05573 18.3188 9.05573H26.1392C27.1079 9.05573 27.5107 10.2953 26.727 10.8647L20.4001 15.4615C20.0496 15.7161 19.903 16.1675 20.0369 16.5795L22.4535 24.0172C22.7529 24.9385 21.6984 25.7047 20.9147 25.1353L14.5878 20.5385C14.2373 20.2839 13.7627 20.2839 13.4122 20.5385L7.08533 25.1353C6.30162 25.7047 5.24714 24.9385 5.54649 24.0172L7.96315 16.5795C8.09702 16.1675 7.95036 15.7161 7.59987 15.4615L1.27299 10.8647C0.489277 10.2953 0.892056 9.05573 1.86078 9.05573H9.68123C10.1145 9.05573 10.4984 8.77677 10.6323 8.36475L13.0489 0.927047Z"
                        fill="#F2CD4A"
                      />
                    </svg>
                  </Box>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Amount Spent
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color: " rgba(80, 205, 137, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.amountSpent || "$3,0546.43"}
                    <span
                      style={{
                        marginLeft: "10px",
                        fontSize: "18px",
                        color:
                          ThemeMode === "dark"
                            ? "rgba(255, 255, 255, 1)"
                            : " rgba(0, 0, 0, 1)",
                      }}
                    >
                      {item?.rate || "+11.01%"}
                    </span>{" "}
                    <ArrowRise />
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Most bought item
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.mostBoughtItem || "Coffee"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Total Visits
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.totalVisits || "6"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Total Sales
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.totalSales || "$1,678.90"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    Average Sales
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.averageSales || "$70"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    All time spend
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.allTimeSpend || "$1,234.56"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    All time average spend
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.allTimeAverageSpend || "$1,234.56"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",
                      textAlign: "left",
                    }}
                  >
                    All time visits
                  </Typography>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    {item?.allTimeVisits || "26"}
                  </Typography>
                </Box>
                <Box minWidth="300px">
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(179, 179, 179, 1)"
                          : " rgba(115, 115, 115, 1)",

                      textAlign: "left",
                    }}
                  >
                    Comments
                  </Typography>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                      mt: 0.5,
                    }}
                  >
                    {item?.comment
                      ? `"${item?.comment}"`
                      : item?.title == "Charles Ross"
                      ? "The dining experience was exceptional! The ambiance was elegant, service impeccable, and every dish a culinary masterpiece. From appetizers to dessert, each bite was packed with rich flavors. A perfect blend of sophistication and comfort. Highly recommend for a memorable fine dining experience!"
                      : item?.title == "Anthony Perry"
                      ? "Outstanding service and delicious food! Every dish was full of flavor, and the staff made the experience truly special. Highly recommend for anyone seeking excellent dining with top-notch hospitality."
                      : item?.title == "Brian Jenkins"
                      ? "The food was good, and the service was friendly and attentive. While not extraordinary, it was a pleasant dining experience overall. Worth a visit if you're looking for solid food and great service."
                      : "The Service was impeccable. The staff was attentive, friendly, and made us feel welcome"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <ProgressBarContainer
                title="Top 10 products bought"
                description="Top 10 products bought by this customer."
                route="customer"
                setChartData={setChartData}
                postUrl="customer/data"
                reportUid="static"
                configurations={[
                  {
                    parameterType: "POINTS",
                    reportParameters: [
                      {
                        parameterName: "enableNavigation",
                        parameterValue: "false",
                      },
                      {
                        parameterName: "showBubble",
                        parameterValue: "false",
                      },
                      {
                        parameterName: "showTooltipPrevious",
                        parameterValue: "false",
                      },
                    ],
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CustomPaper>

        <CustomPaper key={1} customStyles={{ overflow: "visible", mt: "1rem" }}>
          <BarChartContainer
            route="customerDetails"
            showLegend={true}
            setChartData={setChartData}
            comparisonPeriod={comparisonPeriod}
            title={"Customer-Details-Page"}
            description={"Spend Trend"}
            postUrl="customer/data"
            reportUid={"static-barchart"}
            parameters={CustomerBarChartParameters}
          />
        </CustomPaper>
      </Box>
    </Box>
  )
}

export default CustomerDetails
