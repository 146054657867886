export const tooltipDataFallbackConfig = [
  {
    name: "Order Count",
    format: "Number",
    color: "",
  },
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]
export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Order Count",
    format: "Number",
    color: "",
  },
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Discount Amount",
    format: "Currency",
    color: "",
  },
]

export const legendData = [
   { label: "Orders", color: "rgb(252, 151,72)" },
   { label: "Gross Sale", color: "rgba(0, 158, 247, 1)" },
 ]