export const PieChartData = [
  {
    id: "Walk In",
    value: 9457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Dine In",
    value: 3457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "To Go",
    value: 1457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
]

export const PieChartParameters = {
  margin: {
    top: 20,
    bottom: 15,
    left: 50,
  },
  startAngle: -5,
  innerRadius: 0.6,
  padAngle: 0,
  cornerRadius: 0,
  activeInnerRadiusOffset: 26,
  activeOuterRadiusOffset: 19,
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["darker", 0.2]],
  },
  enableSliceLabels: false,
  enableSlicesLabels: false,
  enableArcLinkLabels: false,
  arcLinkLabel: "value",
  arcLinkLabelsTextOffset: 0,
  arcLinkLabelsTextColor: "#333333",
  arcLinkLabelsOffset: -24,
  arcLinkLabelsDiagonalLength: 0,
  arcLinkLabelsStraightLength: 0,
  arcLinkLabelsThickness: 0,
  arcLinkLabelsColor: {
    from: "color",
  },
  enableArcLabels: false,
  arcLabelsRadiusOffset: 0.25,
  arcLabelsSkipAngle: 14,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
  animate: false,
  meta_info: {
    format: "Currency",
  },
  tooltip_fields: "'Gross Sales','Customers','Transactions','Average Ticket'",
}
